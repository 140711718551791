angular.module('app.login', [
  'ui.router',
  'toastr',
  'app.authService'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          //////////////
          // Login //
          //////////////
          .state('login', {

            url: '/login',

            templateUrl: 'app/login/login.tpl.html',
            resolve: {
            },
            controller: ['$scope', '$state', '$timeout', 'toastr', 'authService', '$base64', 'ngDialog', 'personaService', 'deviceDetector',
              function ($scope, $state, $timeout, toastr, authService, $base64, ngDialog, personaService, deviceDetector) {
                var version = authService.getLocalData('appVersionManejo');                
                if (version != appSettings.appVersion) {
                  authService.saveLocalData('appVersionManejo', appSettings.appVersion);
                  $state.reload();
                }
                $scope.appVersion = appSettings.appVersion;
                $scope.permitidoLogin = true;
                $scope.deviceDetector = deviceDetector;
                if ($scope.deviceDetector.raw.browser.chrome || $scope.deviceDetector.raw.browser.firefox) {
                  $scope.permitidoLogin = true;
                } else {
                  $scope.permitidoLogin = false;
                }

                // dateOptions
                $scope.dateOptions = {
                  formatYear: 'yy',
                  startingDay: 0,
                  format: 'dd/MM/yyyy',
                  formatDateTime: 'dd/MM/yyyy HH:mm',
                  showMeridian: false
                };
                // objeto fechas que contiene todas las fechas de los forms
                $scope.openedDates = {};
                // funcion que muestra el datepicker
                $scope.openDate = function ($event, field) {
                  $event.preventDefault();
                  $event.stopPropagation();
                  $scope.openedDates[field] = true;
                };

                //$scope.claveCaptcha = "6Le-kW4UAAAAALz3TXXUMJDM_oNvP_1a73QhOPyz"; //192.168.20.181
                //$scope.claveCaptcha = "6LfaPl0UAAAAAMM88rbSSdZ75EOdnZMaVExQH4y4"; //localhost
                $scope.claveCaptcha = "6LdDb0gaAAAAALuChSS9r-nDfNjm6THNpncIB7WH"; //manejoforestal.inab.gob.gt


                $scope.recuperarPassword = function () {
                  if (!$scope.permitidoLogin) {
                    swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome');
                    return;
                  }
                  ngDialog.open({
                    template: 'app/login/recuperar.password.tpl.html',
                    className: 'ngdialog-theme-plain',
                    appendClassName: 'ngdialog-custom',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'vcRecaptchaService',
                      function ($scope, $state, toastr, vcRecaptchaService) {

                        $scope.setResponse = function (response) {
                          $scope.respuestaCaptcha = response;
                        };
                        $scope.setWidgetId = function (widgetId) {
                          $scope.widgetId = widgetId;
                        };
                        $scope.cbExpiration = function () {
                          vcRecaptchaService.reload($scope.widgetId);
                          $scope.response = null;
                        };

                        $scope.submitFormRecuperar = function (isValid) {
                          if (vcRecaptchaService.getResponse() == "") {
                            toastr.error('Debe resolver el captcha');
                            return;
                          }
                          if (isValid) {
                            var data = {
                              tipoBusquedaId: 0,
                              filtro: $scope.recuperar.correo
                            };
                            authService.reiniciarClaveSolicitud(data).then(function (response) {
                              if (response.status == 'OK') {
                                toastr.success(response.message);
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                            ngDialog.close();
                          }
                        }
                      }]
                  })
                }

                $scope.recuperarUsuario = function () {
                  if (!$scope.permitidoLogin) {
                    swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome');
                    return;
                  }
                  ngDialog.open({
                    template: 'app/login/recuperar.usuario.tpl.html',
                    className: 'ngdialog-theme-plain',
                    appendClassName: 'ngdialog-custom',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$filter', 'toastr', 'vcRecaptchaService',
                      function ($scope, $filter, toastr, vcRecaptchaService) {

                        $scope.recuperarUsuario = {};

                        $scope.setResponse = function (response) {
                          $scope.respuestaCaptcha = response;
                        };
                        $scope.setWidgetId = function (widgetId) {
                          $scope.widgetId = widgetId;
                        };
                        $scope.cbExpiration = function () {
                          vcRecaptchaService.reload($scope.widgetId);
                          $scope.response = null;
                        };

                        $scope.submitFormRecuperarUsuario = function (isValid) {
                          if (vcRecaptchaService.getResponse() == "") {
                            toastr.error('Debe resolver el captcha');
                            return;
                          }
                          if (isValid) {
                            var cui = $scope.recuperarUsuario.cui;
                            var fechaNacimiento = $filter('date')($scope.recuperarUsuario.fechaNacimiento, 'yyyy-MM-dd');
                            authService.recuperarUsuario(cui, fechaNacimiento).then(function (response) {
                              if (response.status == 1) {
                                toastr.success(response.message);
                                ngDialog.close();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });                            
                          }
                        }
                      }]
                  })
                }

                $scope.abrirAyuda = function () {
                  ngDialog.open({
                    template: 'app/login/ayuda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.dataModuloAyuda = [
                          {moduloId: 1, moduloDesc: 'Consumos Familiares Municipales', telefono: '(+502) 23214615', correo: 'fortalecimiento.forestal@inab.gob.gt'},
                          {moduloId: 2, moduloDesc: 'Exentos de Licencia de Aprovechamiento Forestal', telefono: '(+502) 23214534', correo: 'soporte.exentos@inab.gob.gt'},
                          {moduloId: 3, moduloDesc: 'Licencias de Aprovechamiento Forestal', telefono: '(+502) 23214534', correo: 'licencias.manejo@inab.gob.gt'},
                          {moduloId: 4, moduloDesc: 'Consumos Familiares y Notas de Envío', telefono: '(+502) 23214534', correo: 'consumos.familiares@inab.gob.gt'}
                        ];
    
                        $scope.setModuloAyuda = function ( item ) {
                          $scope.moduloAyuda = item;
                        }
                        
                      }]
                  })
                }

                $scope.abrirAnexo = function (manualId) {
                  manualService.getFile(manualId);
                }

                $scope.manualCuenta = function () {
                  authService.getFile(3);
                }

                $scope.manualRecuperar = function () {
                  authService.getFile(4);
                }

                $scope.manualCache = function () {
                  authService.getFile(5);
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                var ignore = ['de', 'la', 'del', 'el', 'los', 'las', 'que', 'a', 'en', 's.a.', 'sa', 's.a'];
                $scope.capitalizeText = function (text) {
                  var capitalizedText = '';
                  var words = text.toLowerCase().split(' ');
                  for (var i = 0; i < words.length; i++) {
                    var found = false;
                    for (var j = 0; j < ignore.length; j++) {
                      if (ignore[j] == words[i]) {
                        found = true;
                        break;
                      }
                    }
                    if (found) {
                      capitalizedText += words[i] + ' ';
                    } else {
                      capitalizedText += words[i].charAt(0).toUpperCase() + words[i].slice(1) + ' ';
                    }
                  }
                  return capitalizedText.trim();
                }

                $scope.crearCuenta = function () {
                  if (!$scope.permitidoLogin) {
                    swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome');
                    return;
                  }
                  $scope.dataIdioma = null;
                  $scope.dataCultura = null;
                  $scope.dataEstadoCivil = null;
                  $scope.dataOcupacion = null;
                  ngDialog.open({
                    template: 'app/login/usuario.add.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataPais: ['paisService',
                        function (paisService) {
                          return paisService.listaActiva().then(function (data) {
                            return data;
                          });
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataPais', 'departamentoService', 'municipioService', 'personaService', 'vcRecaptchaService', '$filter',
                      'Upload', 'ocupacionService', 'estadocivilService', 'culturaService', 'idiomaService',
                      function ($scope, $state, toastr, dataPais, departamentoService, municipioService, personaService, vcRecaptchaService, $filter,
                        Upload, ocupacionService, estadocivilService, culturaService, idiomaService) {
                        $scope.inputType = 'password';
                        $scope.iconEye = 'fa fa-eye';
                        if ($scope.dataIdioma == null) {
                          idiomaService.listaActiva().then(function (res) {
                            if (res.status == 'OK') {
                              $scope.dataIdioma = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                        if ($scope.dataOcupacion == null) {
                          ocupacionService.listaActiva().then(function (res) {
                            if (res.status == 'OK') {
                              $scope.dataOcupacion = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                        if ($scope.dataCultura == null) {
                          culturaService.listaActiva().then(function (res) {
                            if (res.status == 'OK') {
                              $scope.dataCultura = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                        if ($scope.dataEstadoCivil == null) {
                          estadocivilService.listaActiva().then(function (res) {
                            if (res.status == 'OK') {
                              $scope.dataEstadoCivil = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                        $scope.aceptar = 0;

                        $scope.mostrarPassword = function () {
                          if ($scope.inputType == 'password') {
                            $scope.inputType = 'text';
                            $scope.iconEye = 'fa fa-eye-slash';
                          } else {
                            $scope.iconEye = 'fa fa-eye';
                            $scope.inputType = 'password';
                          }
                        }

                        $scope.dataSexo = [
                          { sexoId: 1, sexoDesc: 'Femenino' },
                          { sexoId: 2, sexoDesc: 'Masculino' }
                        ];

                        var now = new Date();

                        $scope.minDate = now;

                        $scope.usuario = {
                          estadoId: 1,
                          foto: null,
                          fechaVencimiento: new Date(),
                          usuario: {
                            usuario: null,
                            claveUsuario: null,
                            sistemaId: 0
                          },
                          tcMunicipio: {
                            municipioId: null
                          },
                          tcSexo: {
                            sexoId: null
                          },
                          tcOcupacion: {
                            ocupacionId: null
                          },
                          tcIdioma: {
                            idiomaId: null
                          },
                          tcEstadoCivil: {
                            estadoCivilId: null
                          },
                          tcCultura: {
                            culturaId: null
                          }
                        };

                        $scope.direccion = {};
                        $scope.dataPais = dataPais.data;

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.usuario.municipioId = null;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.usuario.municipioId = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.formarUsuario = function () {
                          if ($scope.usuario.correo != null) {
                            $scope.usuario.usuario.usuario = $scope.usuario.correo.substring(0, $scope.usuario.correo.lastIndexOf("@"));
                          }
                        }

                        $scope.setResponse = function (response) {
                          $scope.respuestaCaptcha = response;
                        };
                        $scope.setWidgetId = function (widgetId) {
                          $scope.widgetId = widgetId;
                        };
                        $scope.cbExpiration = function () {
                          vcRecaptchaService.reload($scope.widgetId);
                          $scope.response = null;
                        };

                        $scope.submitFormCrear = function (isValid) {
                          if (vcRecaptchaService.getResponse() == "") {
                            toastr.error('Debe resolver el captcha');
                            return;
                          }
                          if (isValid) {
                            if ($scope.usuario.foto == null) {
                              isValid = false;
                              toastr.error('Debe indicar una foto');
                            }
                            if ($scope.aceptar == 0 && isValid) {
                              isValid = false;
                              toastr.error('Debe aceptar los términos y condiciones');
                            }
                            if ($scope.validoFoto == 0 && isValid) {
                              isValid = false;
                              toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                            }
                          } else {
                            toastr.error('Verifique los campos faltantes, que sea el tipo de dato correcto, fecha, numérico, etc.');
                          }
                          if (isValid) {
                            var usuario = angular.copy($scope.usuario);
                            usuario.personaDesc = $scope.capitalizeText(usuario.personaDesc);
                            usuario.direccion = $scope.capitalizeText(usuario.direccion);
                            usuario.usuario.claveUsuario = $base64.encode(usuario.usuario.claveUsuario);
                            usuario.fechaNacimiento = $filter('date')(new Date(usuario.fechaNacimiento), 'yyyy-MM-dd');
                            usuario.fechaVencimiento = $filter('date')(new Date(usuario.fechaVencimiento), 'yyyy-MM-dd');
                            personaService.registrar(usuario).then(function (res) {
                              if (res.status == "OK") {
                                ngDialog.close();
                                swal(res.message);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                        $scope.config = {
                          width: 100,
                          height: 200,
                          quality: 1
                        };

                        $scope.abrirTerminos = function () {
                          ngDialog.open({
                            template: 'app/login/terminos.condiciones.tpl.html',
                            className: 'ngdialog-theme-flat',
                            appendClassName: 'ngdialog-flat',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueTerminos',
                            name: 'dialogTerminos',
                            resolve: {
                            },
                            controller: ['$scope', '$state', 'toastr',
                              function ($scope, $state, toastr) {

                              }]
                          })
                        }

                        $scope.cerrarVentanaHija = function () {
                          //https://stackoverflow.com/questions/32706452/ng-dialog-close-child-modal
                          var windowIDs = ngDialog.getOpenDialogs();
                          ngDialog.close(windowIDs[1]);
                        }

                        $scope.onFileSelect = function (file, tipoAnexoId) {
                          Upload.upload({
                            url: appSettings.urlServiceBase + 'file/base64/imagen',
                            method: 'POST',
                            file: file,
                            sendFieldsAs: 'form',
                            fields: {
                              data: []
                            }
                          }).then(function (resp) {
                            var data = resp.data;
                            if (data.status == 'OK') {
                              var obj = data.data[0];
                              $scope.image = obj.data;
                              $scope.usuario.foto = obj.data;
                            } else {
                              toastr.error(resp.data.message)
                            }
                          }, function (resp) {
                            toastr.error('Error status: ' + resp.status);
                          }, function (evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.avance = progressPercentage;
                          });
                        }


                      }]
                  })
                }

                $scope.goCefe = function () {
                  $state.go('cefe');
                }

                $scope.inputType = 'password';
                $scope.iconEye = 'fa fa-eye';

                $scope.mostrarPassword = function () {
                  if ($scope.inputType == 'password') {
                    $scope.inputType = 'text';
                    $scope.iconEye = 'fa fa-eye-slash';
                  } else {
                    $scope.iconEye = 'fa fa-eye';
                    $scope.inputType = 'password';
                  }
                }

                $scope.loginData = {
                  usuario: null,
                  claveUsuario: null,
                  estadoId: 1,
                  sistemaId: appSettings.sistemaId
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    if (!$scope.permitidoLogin) {
                      swal('Su navegador no es compatible, use Google Chrome o Mozilla Firefox');
                      return;
                    }
                    $scope.tmpPassword = $scope.loginData.password.trim();
                    $scope.loginData.claveUsuario = $base64.encode($scope.loginData.password);
                    $scope.loginData.password = null;
                    var loginData = angular.copy($scope.loginData);
                    var usuario = loginData.usuario.trim();
                    loginData.claveUsuario = loginData.claveUsuario.trim();
                    if (usuario.indexOf('@') != -1) {
                      usuario = usuario.substring(0, usuario.indexOf('@'));
                      loginData.usuario = usuario;
                    }
                    authService.login(loginData).then(function (response) {
                      if (response.status == 'OK') {
                        $state.go('index.home');
                      } else {
                        $scope.loginData.password = $scope.tmpPassword;
                        $scope.message = response.message;
                        $scope.mostrarMensaje = true;
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });

                  } else {
                    toastr.error('Debe indicar el usuario y la contraseña');
                  }
                }

                $scope.abrirCodigo = function () {
                  if (!$scope.permitidoLogin) {
                    swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome');
                    return;
                  }
                  ngDialog.open({
                    template: 'app/login/ingreso.codigo.tpl.html',
                    className: 'ngdialog-theme-plain',
                    appendClassName: 'ngdialog-custom',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'authService', '$base64',
                      function ($scope, $state, toastr, authService, $base64) {
                        $scope.confirmacion = 1;
                        $scope.reinicio = 0;
                        $scope.tituloCodigo = "Confirmación de cuenta";

                        $scope.marcaCodigo = function () {
                          if ($scope.confirmacion == 1) {
                            $scope.tituloCodigo = "Confirmación de cuenta";
                            $scope.reinicio = 0;
                          } else {
                            $scope.tituloCodigo = "Reinicio de contraseña";
                            $scope.reinicio = 1;
                          }
                        }

                        $scope.marcaReinicio = function () {
                          if ($scope.reinicio == 1) {
                            $scope.tituloCodigo = "Reinicio de contraseña";
                            $scope.confirmacion = 0;
                          } else {
                            $scope.tituloCodigo = "Confirmación de cuenta";
                            $scope.confirmacion = 1;
                          }
                        }


                        $scope.submitFormConfirmar = function (isValid) {
                          if (isValid) {
                            var codigo = angular.copy($scope.codigo.codigo.trim());
                            if (codigo.substring(0, 3) == 'C|-') {
                              codigo = codigo.replace('C|-', '');
                              authService.reiniciarClave(codigo).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              authService.validarCodigo(codigo).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                            ngDialog.close();
                          }
                        }
                      }]
                  })
                }

                $scope.estadoConservacionBosque = function () {
                  ngDialog.open({
                    template: 'app/login/estado.conservacion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-custom',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'authService', '$base64',
                      function ($scope, $state, toastr, authService, $base64) {
                        $scope.confirmacion = 1;
                        $scope.reinicio = 0;
                        $scope.tituloCodigo = "Confirmación de cuenta";

                        $scope.marcaCodigo = function () {
                          if ($scope.confirmacion == 1) {
                            $scope.tituloCodigo = "Confirmación de cuenta";
                            $scope.reinicio = 0;
                          } else {
                            $scope.tituloCodigo = "Reinicio de contraseña";
                            $scope.reinicio = 1;
                          }
                        }

                        $scope.marcaReinicio = function () {
                          if ($scope.reinicio == 1) {
                            $scope.tituloCodigo = "Reinicio de contraseña";
                            $scope.confirmacion = 0;
                          } else {
                            $scope.tituloCodigo = "Confirmación de cuenta";
                            $scope.confirmacion = 1;
                          }
                        }


                        $scope.submitFormConfirmar = function (isValid) {
                          if (isValid) {
                            var codigo = angular.copy($scope.codigo.codigo.trim());
                            if (codigo.substring(0, 3) == 'C|-') {
                              codigo = codigo.replace('C|-', '');
                              authService.reiniciarClave(codigo).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              authService.validarCodigo(codigo).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                            ngDialog.close();
                          }
                        }
                      }]
                  })
                }

              }] //end controller
          })
          .state('key', {

            url: '/key/{key}',

            templateUrl: 'app/login/login.tpl.html',
            resolve: {
              dataKey: ['authService', '$stateParams',
                function (authService, $stateParams) {
                  if ($stateParams.key == null || $stateParams.key == '') {
                    return res = {
                      status: '-1',
                      message: 'No hay código para validar',
                      data: null
                    };
                  } else {
                    var codigo = angular.copy($stateParams.key);
                    if (codigo.substring(0, 3) == 'C|-') {
                      codigo = codigo.replace('C|-', '');
                      return authService.reiniciarClave(codigo);
                    } else {
                      return authService.validarCodigo(codigo);
                    }
                  }
                }]
            },
            controller: ['$scope', '$state', '$timeout', 'toastr', 'authService', '$base64', 'ngDialog', 'personaService', 'deviceDetector', 'dataKey',
              function ($scope, $state, $timeout, toastr, authService, $base64, ngDialog, personaService, deviceDetector, dataKey) {
                var version = authService.getLocalData('appVersionManejo');
                if (version != appSettings.appVersion) {
                  authService.saveLocalData('appVersionManejo', appSettings.appVersion);
                  $state.reload();
                }
                $scope.deviceDetector = deviceDetector;
                if ($scope.deviceDetector.raw.browser.ie) {
                  //swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome en sus más recientes versiones');
                }
                if (dataKey.status != '-1') {
                  swal(dataKey.message);
                }
                $state.go('login');
              }] //end controller
          })
          .state('validatex', {

            url: '/validatex/{key}',

            templateUrl: 'app/login/login.tpl.html',
            resolve: {
            },
            controller: ['$scope', '$state', '$timeout', 'toastr', 'authService', '$base64', 'ngDialog', '$stateParams', 'deviceDetector', 'catalogosService', 'utilsService',
              function ($scope, $state, $timeout, toastr, authService, $base64, ngDialog, $stateParams, deviceDetector, catalogosService, utilsService) {
                var version = authService.getLocalData('appVersionManejo');
                if (version != appSettings.appVersion) {
                  authService.saveLocalData('appVersionManejo', appSettings.appVersion);
                  $state.reload();
                }
                $scope.deviceDetector = deviceDetector;
                if ($scope.deviceDetector.raw.browser.ie) {
                  //swal('Navegador incompatible', 'Posiblemente tenga inconvenientes al trabajar en la aplicación, se recomienda usar los navegadores Mozilla Firefox o Google Chrome en sus más recientes versiones');
                }
                if ($stateParams.key != null) {
                  var decode = utilsService.decifrarTexto(appSettings.seed, $stateParams.key);
                  if (!isNaN(Number(decode))) {
                    catalogosService.getResolucionVenta(decode);
                  }
                }
                $state.go('login');
              }] //end controller
          })
      }
    ]
  );
