angular.module('app.solicitud.service', [

])

  .factory('solicitudService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'solicitud/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + 'solicitud/marcar/enmienda', data).
          then(function (res) {
            return res.data;
          });
      }
    }

  }]);
