angular.module('app.clave', [
  'ui.router',
  'toastr',
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.clave', {
          abstract: true,
          url: 'clave',
          params: {
          },
          views: {
            '': {
              templateUrl: 'app/clave/clave.tpl.html',
              resolve: {
              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {
                }]
            }
          }
        })
        .state( 'index.clave.show', {
          url: '',
          views: {
            '': {
              templateUrl: 'app/clave/clave.show.tpl.html',
              resolve: {
                dataPersona: ['personaService', 'authService', 'appSettings',
                  function ( personaService, authService, appSettings ){
                    var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                    return personaService.get(loginData.personaId);
                  }],
                dataPais: ['paisService',
                  function ( paisService ){
                    return paisService.listaActiva().then(function(data){
                      return data;
                    });
                  }],
                dataMunicipio: ['municipioService', 'dataPersona',
                  function ( municipioService, dataPersona ){
                    var data = dataPersona.data[0];
                    if (data.tcMunicipio != null) {
                      return municipioService.listaPorDepartamento(data.tcMunicipio.tcDepartamento.departamentoId).then(function(data){
                        return data;
                      });
                    } else {
                      return { data : []}
                    }
                  }],
                dataDepartamento: ['departamentoService', 'dataPersona',
                  function ( departamentoService, dataPersona ){
                    var data = dataPersona.data[0];
                    if (data.tcMunicipio != null) {
                      return departamentoService.listaPorPais( data.tcMunicipio.tcDepartamento.tcPais.paisId ).then(function(data){
                        return data;
                      });
                    } else {
                      return { data : []}
                    }
                  }],
                dataTipoDocumento: ['tipodocumentoService',
                  function ( tipodocumentoService ){
                    return tipodocumentoService.listaActiva();
                  }],
                dataOcupacion: ['ocupacionService',
                  function ( ocupacionService ){
                    return ocupacionService.listaActiva();
                  }],
                dataCultura: ['culturaService',
                  function ( culturaService ){
                    return culturaService.listaActiva().then(function(data){
                      return data;
                    });
                  }],
                dataIdioma: ['idiomaService',
                  function ( idiomaService ){
                    return idiomaService.listaActiva().then(function(data){
                      return data;
                    });
                  }],
                dataEstadoCivil: ['estadocivilService',
                  function ( estadocivilService ){
                    return estadocivilService.listaActiva().then(function(data){
                      return data;
                    });
                  }]
              },
              controller: ['$scope', '$state', 'toastr', '$base64', 'authService', 'dataPersona', 'dataPais', 'dataTipoDocumento', 'dataMunicipio',
              'dataDepartamento', 'municipioService', 'departamentoService', 'personaService', '$filter', 'Upload', 'dataOcupacion',
              'dataCultura', 'dataIdioma', 'dataEstadoCivil', 'utilsService', 'institucionService', 'institucionDependenciaService',
                function ($scope, $state, toastr, $base64, authService, dataPersona, dataPais, dataTipoDocumento, dataMunicipio,
                  dataDepartamento, municipioService, departamentoService, personaService, $filter, Upload, dataOcupacion,
                  dataCultura, dataIdioma, dataEstadoCivil, utilsService, institucionService, institucionDependenciaService) {
                  $scope.dataSexo = [
                    {sexoId : 1, sexoDesc : 'Femenino'},
                    {sexoId : 2, sexoDesc : 'Masculino'}
                  ];
                  $scope.dataPerfil = [
                    { perfilId: 3, perfilDesc: 'Elaborador de planes de manejo', 
                      actividades: 'Elabora planes de manejo para solicitud de licencias de aprovechamiento'},
                    { perfilId: 27, perfilDesc: 'Alcalde municipal',
                      actividades: 'Para resolver trámites municipales'},
                    { perfilId: 28, perfilDesc: 'Técnico municipal',
                      actividades: 'Para realizar procesos sobre trámites municipales'},
                    { perfilId: 30, perfilDesc: 'Ministerio Público / Organismo Judicial',
                      actividades: 'Para realizar solicitudes sobre avalúos'},
                    { perfilId: 32, perfilDesc: 'Concejal municipal',
                      actividades: 'Para realizar dictamen sobre consumo familiar'},
                    { perfilId: 35, perfilDesc: 'Técnico Municipal y Elaborador de Planes de Manejo Forestal',
                      actividades: 'Para realizar dictamen sobre consumo familiar y elaborar planes de manejo para solicitud de licencias de aprovechamiento'},
                    { perfilId: 37, perfilDesc: 'Síndico Municipal',
                      actividades: 'Para realizar dictamen sobre consumo familiar'},
                    { perfilId: 40, perfilDesc: 'Secretaria o recepción CONAP',
                      actividades: 'Para recibir solicitudes sobre avalúos'},
                    { perfilId: 41, perfilDesc: 'Técnico CONAP',
                      actividades: 'Para realizar dictamen sobre avalúos'},
                    { perfilId: 42, perfilDesc: 'Jefatura CONAP',
                      actividades: 'Para realizar dictamen sobre avalúos'}
                  ];
                  $scope.dataTipoDocumento = dataTipoDocumento.data;
                  $scope.dataPais = dataPais.data;
                  $scope.dataMunicipio = dataMunicipio.data;
                  $scope.dataDepartamento = dataDepartamento.data;
                  $scope.dataOcupacion = dataOcupacion.data;
                  $scope.dataEstadoCivil = dataEstadoCivil.data;
                  $scope.dataCultura = dataCultura.data;
                  $scope.dataIdioma = dataIdioma.data;
                  $scope.usuario = dataPersona.data[0];
                  $scope.municipalidad = {
                    tcDepartamento: {},
                    tcMunicipio: {},
                    tcPerfil: {},
                    archivoNombre: 'Nombramiento o archivo que lo respalde'
                  };
                  $scope.entidad = {
                    tcInstitucion: {},
                    tcPerfil: {},
                    archivoNombre: 'Nombramiento o archivo que lo respalde',
                    tcInstitucionDependencia: {}
                  };
                  $scope.conap = {
                    tcPerfil: {},
                    archivoNombre: 'Nombramiento o archivo que lo respalde'
                  }
                  $scope.dataDepartamentoMunicipalidad = [];
                  $scope.dataInstitucion = [];
                  if ($scope.usuario.foto != null) {
                    $scope.image = $scope.usuario.foto;
                  }
                  if ($scope.usuario.fechaNacimiento != null) {
                    $scope.usuario.fechaNacimiento = new Date($scope.usuario.fechaNacimiento);
                  }
                  if ($scope.usuario.fechaVencimiento != null) {
                    $scope.usuario.fechaVencimiento = new Date($scope.usuario.fechaVencimiento);
                  }
                  $scope.placeholderRNF = "Número de Registro en el RFN (Incluya EPMF-)";
                  $scope.enableRegente = function () {
                    if (parseInt($scope.loginData.perfilId) != 3) {
                      return false;
                    }
                    $scope.placeholderRNF = "Usted ya cuenta con el perfil de elaborador";
                    return true;
                  }

                  $scope.direccion = {
                    paisId : $scope.usuario.tcMunicipio.tcDepartamento.tcPais.paisId,
                    departamentoId : $scope.usuario.tcMunicipio.tcDepartamento.departamentoId
                  };

                  $scope.data = {
                    usuario : $scope.loginData.usuario,
                    usuarioId : $scope.loginData.usuarioId
                  }

                  $scope.perfil = {
                    nit : $scope.usuario.nit
                  }

                  $scope.cargarListaDepartamento = function (item, tipo) {                    
                    switch (tipo) {
                      case 1:
                        $scope.direccion.departamentoId = null;
                        $scope.usuario.municipioId = null;   
                        break;
                      case 2:
                        $scope.municipalidad.tcDepartamento.departamentoId = null;
                        $scope.municipalidad.tcMunicipio.municipioId = null;
                        break;
                    }                    
                    departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        switch (tipo) {
                          case 1:
                            $scope.dataDepartamento = res.data;
                            break;
                          case 2:
                            $scope.dataDepartamentoMunicipalidad = res.data;
                            break;
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }

                  $scope.cargarListaMunicipio = function (item, tipo) {
                    switch (tipo) {
                      case 1:
                        $scope.usuario.municipioId = null;
                        break;
                      case 2:
                        $scope.municipalidad.tcMunicipio.municipioId = null;
                        break;
                    }
                    municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        switch (tipo) {
                          case 1:
                            $scope.dataMunicipio = res.data;
                            break;
                          case 2:
                            $scope.dataMunicipalidad = res.data;
                            break;
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }

                  $scope.setPerfil = function ( item ) {
                    if (item == null) {
                      $scope.data.tcPerfil = {};
                      return;
                    }
                    if (item.perfilId == 27 || item.perfilId == 28 || item.perfilId == 32 || item.perfilId == 35 || item.perfilId == 37) {
                      if ($scope.dataDepartamentoMunicipalidad.length <= 0) {
                        $scope.cargarListaDepartamento({paisId: 1}, 2);
                      }
                      $scope.municipalidad.tcPerfil.perfilId = item.perfilId;
                    }
                    if (item.perfilId == 30) {
                      if ($scope.dataInstitucion.length <= 0) {
                        institucionService.listaActiva().then( function ( res ) {
                          if ( res.status == 1 ) {
                            $scope.dataInstitucion = res.data;
                          } else {
                            toastr.error( res.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }
                      $scope.entidad.tcPerfil.perfilId = item.perfilId;
                    }
                    if (item.perfilId == 36 || item.perfilId == 38) {
                      $scope.conap.tcPerfil.perfilId = item.perfilId;
                    }
                  }

                  $scope.inputType = 'password';
                  $scope.iconEye = 'fa fa-eye';

                  $scope.mostrarPassword = function () {
                    if ($scope.inputType == 'password') {
                      $scope.inputType = 'text';
                      $scope.iconEye = 'fa fa-eye-slash';
                    } else {
                      $scope.iconEye = 'fa fa-eye';
                      $scope.inputType = 'password';
                    }
                  }

                  $scope.submitForm = function ( isValid ) {
                    $scope.data.claveUsuario = $scope.data.claveUsuario.trim();
                    $scope.data.confirmacion = $scope.data.confirmacion.trim();
                    $scope.data.oldPassword = $scope.data.oldPassword.trim();
                    if ( isValid ) {
                      if ($scope.data.claveUsuario != $scope.data.confirmacion) {
                        isValid = false;
                        toastr.error('Las contraseñas no coinciden');
                      }
                    }
                    if (isValid) {
                      var data = angular.copy($scope.data);
                      data.oldPassword = $base64.encode(data.oldPassword);
                      data.claveUsuario = $base64.encode(data.claveUsuario);
                      authService.cambiarClave(data).then(function (response) {
                        if (response.status == 'OK') {
                          $scope.data = {
                            usuario : $scope.loginData.usuario,
                            usuarioId : $scope.loginData.usuarioId
                          }
                          toastr.success(response.message);
                          $scope.logout();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }

                  $scope.submitFormElaborador = function ( isValid) {
                    if (isValid) {
                      var data = angular.copy($scope.perfil);
                      data.nit = data.nit.trim();
                      data.rnf = data.rnf.trim();
                      iniciales = data.rnf.substring(0, 4);
                      if (iniciales != 'EPMF') {
                        toastr.error('Debe indicar su identificador de elaborador de plan de manejo, ejemplo: EPMF-123');
                        return;
                      }
                      authService.validarRegente(data).then(function (response) {
                        if (response.status == 'OK') {
                          var item = {
                            usuarioPerfilSistemaId: 0,
                            tcUsuario: {
                              usuarioId : $scope.loginData.usuarioId
                            },
                            tcPerfil: {
                              perfilId : $scope.loginData.perfilId
                            },
                            tcSistema: {
                              sistemaId : appSettings.sistemaId
                            },
                            rfn: data.rnf
                          }
                          authService.setPerfilRegente(item).then(function (response) {
                            if (response.status == 'OK') {
                              toastr.success('Cambio exitoso, cierre sesión y vuelva a ingresar para cargar las nuevas opciones');
                              $scope.logout();
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                          item = angular.copy($scope.usuario);
                          item.rfn = $scope.perfil.rfn;
                          personaService.edit(item);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }

                  $scope.submitFormDatos = function ( isValid ) {
                    if (isValid) {
                      var usuario = angular.copy($scope.usuario);
                      usuario.personaDesc = $scope.capitalizeText(usuario.personaDesc);
                      usuario.direccion = $scope.capitalizeText(usuario.direccion);
                      personaService.edit(usuario).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success(response.message);
                          $scope.logout();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }

                  $scope.submitFormMunicipal = function ( isValid ) {
                    if (isValid) {
                      swal({
                        title: "¿Está seguro de realizar la solicitud?",
                        text: "Este proceso no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                      function(isConfirm) {
                        if (isConfirm) {
                          var municipalidad = angular.copy($scope.municipalidad);
                          if (municipalidad.rutaArchivo == null) {
                            toastr.error('Debe indicar el archivo de respaldo');
                            return;
                          }
                          personaService.solicitarCambioPerfil(municipalidad).then(function (response) {
                            if (response.status == 'OK') {
                              swal('Solicitud realizada', response.message);
                              $scope.setPerfil();
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });                          
                        }
                      });
                    }
                  }

                  $scope.setInstitucion = function ( item ) {
                    $scope.entidad.tcInstitucionDependencia.institucionDependenciaId = null;
                    institucionDependenciaService.getByInstitucion(item.institucionId).then(function (response) {
                      if (response.status == 1) {
                        $scope.dataInstitucionDependencia = response.data;
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }

                  $scope.submitFormEntidad = function ( isValid ) {
                    if (isValid) {
                      var entidad = angular.copy($scope.entidad);
                      if (entidad.rutaArchivo == null) {
                        toastr.error('Debe indicar el archivo de respaldo');
                        return;
                      }
                      personaService.solicitarCambioPerfil(entidad).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success('Solicitud realizada', response.message);
                          $scope.setPerfil();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }

                  $scope.submitFormConap = function ( isValid ) {
                    if (isValid) {
                      var conap = angular.copy($scope.conap);
                      if (conap.rutaArchivo == null) {
                        toastr.error('Debe indicar el archivo de respaldo');
                        return;
                      }
                      personaService.solicitarCambioPerfil(conap).then(function (response) {
                        if (response.status == 'OK') {
                          toastr.success('Solicitud realizada', response.message);
                          $scope.setPerfil();
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }

                  $scope.onFileSelect = function (file, tipoAnexoId) {
                    var url = 'file/base64/imagen';
                    if (tipoAnexoId == null) {
                      tipoAnexoId = 1;
                    }
                    if (tipoAnexoId != 1) {
                      url = 'file/upload';
                    }
                    Upload.upload({
                      url: appSettings.urlServiceBase + url,
                      method: 'POST',
                      file: file,
                      sendFieldsAs: 'form',
                      fields: {
                        data: []
                      }
                    }).then(function (resp) {
                      var data = resp.data;
                      if (data.status == 'OK') {
                        var obj = data.data[0];
                        switch (parseInt(tipoAnexoId)) {
                          case 1:
                            $scope.image = obj.data;
                            $scope.usuario.foto = obj.data;
                            break;
                          case 2:
                            $scope.municipalidad.archivoNombre = obj.nombreOriginal;
                            $scope.municipalidad.size = obj.size;
                            $scope.municipalidad.extension = obj.extension;
                            $scope.municipalidad.rutaArchivo = obj.rutaArchivo;                            
                            break;
                          case 3:
                            $scope.entidad.archivoNombre = obj.nombreOriginal;
                            $scope.entidad.size = obj.size;
                            $scope.entidad.extension = obj.extension;
                            $scope.entidad.rutaArchivo = obj.rutaArchivo;
                          case 4:
                            $scope.conap.archivoNombre = obj.nombreOriginal;
                            $scope.conap.size = obj.size;
                            $scope.conap.extension = obj.extension;
                            $scope.conap.rutaArchivo = obj.rutaArchivo;
                            break;
                        }
                      } else {
                        toastr.error(resp.data.message)
                      }
                    }, function (resp) {
                      toastr.error('Error status: ' + resp.status);
                    }, function (evt) {
                      var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                      $scope.avance = progressPercentage;
                    });
                  }

                }]
            }
          }
        })
        .state( 'index.clave.manual', {
          url: '/manual',
          views: {
            '': {
              templateUrl: 'app/clave/manual.show.tpl.html',
              resolve: {
                dataManual: ['perfilmanualService', 'authService', 'appSettings',
                  function ( perfilmanualService, authService, appSettings ){
                    var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                    return perfilmanualService.listaByPerfil(loginData.perfilId);
                  }]
              },
              controller: ['$scope', '$state', 'toastr', '$base64', 'authService', 'dataManual', 'manualService', '$window',
                function ($scope, $state, toastr, $base64, authService, dataManual, manualService, $window) {

                  $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
                  $scope.gridOptions.columnDefs = [
                    { field:'tcManual.manualDesc', name: 'manualDesc', displayName: 'Guia' },
                    { name: 'Opciones', enableFiltering: false, width: "10%",
                      cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                        +' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.abrirManual(row.entity)" title="Ver o descargar"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>'
                        + '</span></div>' }
                  ];
                  $scope.gridOptions.data = dataManual.data;

                  $scope.abrirManual = function ( item ) {
                    if (item.tcManual.tipoArchivoId == 2) {
                      $window.open(item.tcManual.rutaArchivo, '_blank');
                    } else {
                      manualService.getFile( item.tcManual.manualId );
                    }
                  }

                }]
            }
          }
        })
    }
  ]
);
