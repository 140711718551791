angular.module('app.usuario', [
  'ui.router',
  'toastr',
  'app.usuario.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.usuario', {
          abstract: true,
          url: 'usuario',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1001);
              }]
          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Usuario';
            }]
        })
        .state('index.usuario.list', {
          url: '',
          templateUrl: 'app/usuario/usuario.list.tpl.html',
          resolve: {
            dataUsuario: ['usuarioService',
              function ( usuarioService ){
                return usuarioService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataUsuario',
            function (  $scope,   $state,   dataUsuario) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'usuarioDesc', name: 'usuarioDesc', displayName: 'Usuario' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataUsuario.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ usuarioId: row.usuarioId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.usuario.add', {
          url: '/add',
          templateUrl: 'app/usuario/usuario.add.tpl.html',
          resolve: {
            dataEstado: ['usuarioService',
              function ( usuarioService ){
                return usuarioService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuarioService', 'dataEstado',
            function (  $scope,   $state,   toastr,   usuarioService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuarioService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.usuario.edit', {
          url: '/:usuarioId/edit',
          templateUrl: 'app/usuario/usuario.add.tpl.html',
          resolve: {
            dataUsuario: ['usuarioService', '$stateParams',
              function ( usuarioService, $stateParams ){
                return usuarioService.get( $stateParams.usuarioId );
              }],
            dataEstado: ['usuarioService',
              function ( usuarioService ){
                return usuarioService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuarioService', 'dataUsuario', 'dataEstado',
            function (  $scope,   $state,   toastr,   usuarioService,   dataUsuario, dataEstado) {
              $scope.data = dataUsuario.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuarioService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.usuario.perfil', {
          url: '/perfil',
          templateUrl: 'app/usuario/usuario.perfil.tpl.html',
          resolve: {
            dataEstado: ['usuarioService',
              function ( usuarioService ){
                return usuarioService.listStatus();
              }],
            dataPerfil: ['perfilService',
              function ( perfilService ){
                return perfilService.listaActiva();
              }],
            dataSubregion: ['subregionService',
              function ( subregionService ){
                return subregionService.listaActiva();
              }],
            dataPuesto: ['puestoService',
              function ( puestoService ){
                return puestoService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'personaService', 'dataEstado', 'dataPerfil', 'dataSubregion', 'tareaService', '$base64', 'ngDialog', 'dataPuesto',
            function (  $scope,   $state,   toastr,   personaService, dataEstado, dataPerfil, dataSubregion, tareaService, $base64, ngDialog, dataPuesto) {

              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.dataPuesto = dataPuesto.data;
              $scope.data.estadoId = 1;
              $scope.tcUsuario = {};
              $scope.tcPersona = {};
              $scope.tcOtraPersona = {};
              $scope.disabled = true;
              $scope.traslate = false;
              $scope.dataPerfil = dataPerfil.data;
              $scope.dataSubregion = dataSubregion.data;
              $scope.mostrarPerfil = false;
              $scope.mostrarSubregion = false;
              $scope.perfilId = null;
              $scope.tcPerfil = null;
              $scope.subregionId = null;
              $scope.tcSubregion = null;
              $scope.cui = null;

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }

              $scope.mostrarQuitar = function ( estadoId ) {
                if (estadoId == 1) {
                  return true;
                }
                return false;
              }

              $scope.gridPerfil = angular.copy( $scope.gridOptionsSelection );
              $scope.gridPerfil.columnDefs = [
                { field:'tcPerfil.perfilDesc', name: 'perfilDesc', displayName: 'Perfil' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarQuitar(row.entity.estadoId)" ng-click="grid.appScope.quitarPerfil(row.entity)" title="Quitar perfil">Quitar <i class="fa fa-close" aria-hidden="true"></i></button></span></div>' }
              ];
              $scope.gridPerfil.data = [];

              $scope.gridSubregion = angular.copy( $scope.gridOptionsSelection );
              $scope.gridSubregion.columnDefs = [
                { field:'tcSubregion.subregionDesc', name: 'subregionDesc', displayName: 'Subregion' },
                { field:'tcSubregion.alias', name: 'alias', displayName: 'Subregion' },
                { field:'tcPerfil.perfilDesc', name: 'perfilDesc', displayName: 'Perfil' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '15%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarQuitar(row.entity.estadoId)" ng-click="grid.appScope.quitarSubregion(row.entity)" title="Quitar subregion">Quitar <i class="fa fa-close" aria-hidden="true"></i> </button></span></div>' }
              ];
              $scope.gridSubregion.data = [];

              $scope.limpiarDatos = function () {
                $scope.tcUsuario = {};
                $scope.tcPersona = {};
                $scope.disabled = true;
                $scope.gridSubregion.data = [];
                $scope.gridPerfil.data = [];
                $scope.usuario = null;
                $scope.tcOtraPersona = {};
                $scope.traslate = false;
                $scope.otroUsuario = null;
                $scope.mostrarPerfil = false;
                $scope.mostrarSubregion = false;
                $scope.perfilId = null;
                $scope.tcPerfil = null;
                $scope.subregionId = null;
                $scope.tcSubregion = null;
              }

              $scope.buscarUsuario = function ( isValid ) {
                if ( isValid ) {
                  personaService.buscarUsuario( $scope.usuario ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      if (res.data == null || res.data.length <= 0) {
                        $scope.limpiarDatos();
                      } else {
                        var data = res.data[0];
                        $scope.gridPerfil.data = data.perfiles;
                        $scope.gridSubregion.data = data.subregiones;
                        $scope.tcUsuario = data.tcUsuario;
                        $scope.tcPersona = data.tcPersona;
                        $scope.tcPersona.fechaNacimiento = new Date($scope.tcPersona.fechaNacimiento);
                        toastr.success( res.message );
                      }
                    } else {
                      $scope.limpiarDatos();
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.activarSubregion = function () {
                if ($scope.tcPersona.personaId == null) {
                  return;
                }
                $scope.mostrarSubregion = true;
              }

              $scope.activarPerfil = function () {
                if ($scope.tcPersona.personaId == null) {
                  return;
                }
                $scope.mostrarPerfil = true;
              }

              $scope.buscarOtroUsuario = function ( isValid ) {
                if ( isValid ) {
                  personaService.buscarUsuario( $scope.otroUsuario ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var data = res.data[0];
                      $scope.tcOtraPersona = data.tcPersona;
                      $scope.traslate = true;
                      toastr.success( res.message );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.editarDatos = function () {
                if ($scope.tcPersona.personaId == null) {
                  return;
                }
                $scope.disabled = !$scope.disabled;
              }

              $scope.guardarDatos = function ( isValid ) {
                if (isValid) {
                  var persona = angular.copy($scope.tcPersona);
                  persona.personaDesc = $scope.capitalizeText(persona.personaDesc);
                  personaService.editPersona( persona ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $scope.disabled = !$scope.disabled;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.reenviarValidacion = function () {
                var item = angular.copy($scope.tcPersona);
                if (item.personaId == null) {
                  toastr.error('Debe buscar al usuario primero');
                  return;
                }
                swal({
                  title: "¿Está seguro de enviar de nuevo el código de Confirmación a la persona " + item.personaDesc + "?",
                  text: "La notificación se enviará solo si la cuenta no se ha confirmado aún",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.reenviarValidacion( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.quitarPerfil = function ( item ) {
                swal({
                  title: "¿Está seguro (a) de quitar el perfil al usuario?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.quitarPerfil( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        item.estadoId = 0;
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.setPerfil = function ( item ) {
                $scope.tcPerfil = item;
              }

              $scope.agregarPerfil = function () {
                if ($scope.tcPerfil == null) {
                  toastr.error('Debe indicar el perfil a agregar');
                  return;
                }
                swal({
                  title: "¿Está seguro (a) de agregar el perfil al usuario?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var item = {
                      tcUsuario: $scope.tcUsuario,
                      tcPerfil: $scope.tcPerfil,
                      tcSistema: {
                        sistemaId: 3
                      }
                    }
                    personaService.agregarPerfil( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        $scope.gridPerfil.data = res.data;
                        $scope.mostrarPerfil = false;
                        $scope.tcPerfil = null;
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.quitarSubregion = function ( item ) {
                swal({
                  title: "¿Está seguro (a) de quitar el perfil al usuario?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.quitarSubregion( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        item.estadoId = 0;
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.setSubregion = function ( item ) {
                $scope.tcSubregion = item;
              }

              $scope.agregarSubregion = function () {
                if ($scope.tcSubregion == null) {
                  toastr.error('Debe indicar la subregion a agregar');
                  return;
                }
                swal({
                  title: "¿Está seguro (a) de agregar la subregion al usuario?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var data = $scope.gridPerfil.data;
                    var tcPerfil = null;
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].estadoId == 1) {
                        tcPerfil = data[i].tcPerfil;
                        break;
                      }
                    }
                    var item = {
                      tcPersona: $scope.tcPersona,
                      tcSubregion: $scope.tcSubregion,
                      tcPerfil: tcPerfil
                    }
                    personaService.agregarSubregion( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        item = res.data[0];
                        $scope.gridSubregion.data.push(item);
                        $scope.mostrarSubregion = false;
                        $scope.tcSubregion = null;
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.trasladarTareas = function () {
                if ($scope.tcOtraPersona.personaId == null || $scope.tcPersona.personaId == null) {
                  toastr.error('Debe indicar la persona que quiere trasladar y a la que se trasladará');
                  return;
                }
                var item = {
                  personaId: $scope.tcPersona.personaId,
                  elaboradorId: $scope.tcOtraPersona.personaId
                }
                swal({
                  title: "¿Está seguro (a) de trasladar las tareas de " + $scope.tcPersona.personaDesc + " a " + $scope.tcOtraPersona.personaDesc + "?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    tareaService.trasladarTareas( item ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.reiniciarContrasenia = function ( isValid ) {
                if (isValid) {
                  swal({
                    title: "¿Está seguro (a) de reiniciar la contraseña de " + $scope.tcPersona.personaDesc + "?",
                    text: "Esta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                  function(isConfirm) {
                    if (isConfirm) {
                      var usuario = angular.copy($scope.tcUsuario);
                      usuario.claveUsuario = $base64.encode(usuario.claveUsuario);
                      personaService.reiniciarContrasenia( usuario ).then( function ( res ) {
                        if ( res.status == "OK" ) {
                          toastr.success( res.message );
                        } else {
                          toastr.error( res.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  });
                }
              }

              $scope.buscarCui = function ( isValid ) {
                if ( isValid ) {
                  personaService.buscarCui( $scope.cui ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var tcPersona = res.data[0];
                      if (tcPersona.tcUsuario == null) {
                        tcPersona.tcUsuario = {};
                      }
                      $scope.tcPersonaDos = tcPersona;
                      toastr.success( res.message );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.crearUsuario = function () {
                if ($scope.tcPersonaDos == null) {
                  toastr.error('Debe buscar a la persona primero');
                  return;
                }
                swal({
                  title: "¿Está seguro (a) de crear el usuario a " + $scope.tcPersonaDos.personaDesc + "?",
                  text: "Esta acción no se podrá reversar",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    var persona = angular.copy($scope.tcPersonaDos);
                    personaService.crearUsuario( persona ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success( res.message );
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.limpiarPersona = function () {
                $scope.cui = null;
                $scope.tcPersonaDos = null;
              }

              $scope.buscarPersona = function () {
                var continuar = true;
                if (continuar) {
                  $scope.dataTipoBusqueda = [
                    { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                    { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                    { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                    { tipoBusquedaId: 4, tipoBusquedaDesc: 'Correo electrónico' }
                  ]
                  $scope.busqueda = {
                    tipoBusquedaId: 3,
                    tituloBusqueda: 'Ingrese el nombre de la persona',
                    placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                  }

                  ngDialog.open({
                    template: 'app/gestion/buscar.persona.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                        $scope.gridPersona.columnDefs = [
                          { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                          { field: 'cui', name: 'cui', displayName: 'Identificación' },
                          { field: 'correo', name: 'correo', displayName: 'Correo' },
                          { field: 'nit', name: 'nit', displayName: 'NIT' },
                          {
                            name: 'Seleccionar', enableFiltering: false,
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                          }
                        ];
                        $scope.gridPersona.data = [];

                        $scope.setTipoBusqueda = function (item) {
                          switch (parseInt(item.tipoBusquedaId)) {
                            case 1:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                              $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                              break;
                            case 2:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                              $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                              break;
                            case 3:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                              $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                              break;
                            case 4:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el correo electrónico';
                                $scope.busqueda.placeholderBusqueda = 'Escriba el correo electrónico';
                                break;
                            default:
                              $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                          }
                        }

                        $scope.keyupFiltro = function (event) {
                          if (event.keyCode == 13) {
                            $scope.submitBuscarPersona();
                          }
                        }

                        $scope.submitBuscarPersona = function () {
                          var isValid = true;
                          if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                            isValid = null;
                            toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                          }
                          if (isValid) {
                            personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                              if (response.status == 'OK') {
                                toastr.success(response.message);
                                $scope.gridPersona.data = response.data;
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  })

                }
              }

            }]
        })
        .state('index.usuario.cambio', {
          url: '/cambio',
          templateUrl: 'app/usuario/usuario.cambio.tpl.html',
          resolve: {
            dataUsuario: ['personaService',
              function ( personaService ){
                return personaService.getListaSolicitudPerfil();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'personaService', 'dataUsuario', 'dataDepartamento', 'municipioService',
            function (  $scope,   $state,   toastr,   personaService,   dataUsuario, dataDepartamento, municipioService) {
              $scope.data = dataUsuario.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.listarSolicitudes = true;
              $scope.cambio = {
                departamentoId: null,
                municipioId: null
              };
              $scope.etiquetaEstado = function ( estadoId ) {
                switch (estadoId) {
                  case 1: return "Activo";
                  case 99: return "Denegado";
                  default: return "Anulado";
                }
              }

              $scope.textoDependencia = function ( row ) {
                switch (row.tcPerfil.perfilId) {
                  case 27:
                  case 28:
                  case 32:
                  case 35: 
                  case 37: return 'Municipalidad de ' + row.tcMunicipio.municipioDesc;
                  case 30: return row.tcInstitucionDependencia.institucionDependenciaDesc;
                  default: return 'No aplica';
                }
              }

              $scope.esSolicitud = function ( estadoId ) {
                return $scope.listarSolicitudes;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcPersona.personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                { field:'tcPerfil.perfilDesc', name: 'perfilDesc', displayName: 'Perfil solicitado' },
                { name: 'dependenciaDesc', displayName: "Dependencia (si aplica)",
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.textoDependencia(row.entity)}}</div>' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '10%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-md btn-default" ng-show="grid.appScope.esSolicitud(row.entity.estadoId)" ng-click="grid.appScope.autorizarSolicitud(row.entity)" title="Autorizar"><i class="fa fa-lg fa-check text-success" aria-hidden="true"></i></button>'
                    +'<button type="button" class="btn btn-md btn-default" ng-show="grid.appScope.esSolicitud(row.entity.estadoId)" ng-click="grid.appScope.denegarSolicitud(row.entity)" title="Denegar"><i class="fa fa-lg fa-close text-danger" aria-hidden="true"></i></button>'
                    +'<button type="button" class="btn btn-md btn-default" ng-click="grid.appScope.documentoSolicitud(row.entity)" title="Ver documento de respaldo"><i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i></button>'
                    +'<button type="button" class="btn btn-md btn-default" ng-show="!row.entity.principal" ng-click="grid.appScope.setDefaultMunicipalidad(row.entity)" title="Establecer como principal"><i class="fa fa-lg fa-check-circle-o text-primary" aria-hidden="true"></i></button>'
                    +'<button type="button" class="btn btn-md btn-default" ng-show="!grid.appScope.esSolicitud(row.entity.estadoId)" ng-click="grid.appScope.quitarPersonaDeMunicipalidad(row.entity)" title="Cancelar perfil"><i class="fa fa-lg fa-close text-danger" aria-hidden="true"></i></button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = [];

              if (dataUsuario.status == 1) {
                $scope.gridOptions.data = dataUsuario.data;
              }

              $scope.documentoSolicitud = function ( item ) {
                personaService.getDocumentoSolicitudPerfil( item.solicitudPerfilId );
              }

              $scope.setDepartamento = function ( item ) {
                $scope.gridOptions.data = [];
                $scope.listarSolicitudes = false;
                $scope.cambio.municipioId = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarDatosMunicipalidad = function ( municipioId ) {
                personaService.getByMunicipalidad(municipioId).then( function ( res ) {
                  if ( res.status == 1 ) {
                    $scope.gridOptions.data = res.data;
                    if (res.data == null) {
                      res.data = [];
                    }
                    if (res.data.length <= 0) {
                      toastr.error('No se encontraron personas asociadas a ésta municipalidad');
                    }
                  } else {
                    $scope.gridOptions.data = [];
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  $scope.gridOptions.data = [];
                  toastr.error( error );
                });
              }

              $scope.setMunicipio = function ( item ) {
                $scope.cargarDatosMunicipalidad(item.municipioId);
              }

              $scope.actualizar = function () {
                $scope.listarSolicitudes = true;
                $scope.gridOptions.data = [];
                $scope.cambio = {
                  departamentoId: null,
                  municipioId: null
                }
                personaService.getListaSolicitudPerfil().then( function ( res ) {
                  if ( res.status == 1 ) {
                    $scope.gridOptions.data = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setDefaultMunicipalidad = function ( item ) {
                swal({
                  title: "¿Está seguro de dejar como principal a " + item.tcPersona.personaDesc + "?",
                  text: "",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.setDefaultMunicipalidad( item.personaMunicipalidadId ) .then( function ( res ) {
                      if ( res.status == 1 ) {
                        toastr.success( res.message );
                        $scope.cargarDatosMunicipalidad($scope.cambio.municipioId);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }

              $scope.quitarPersonaDeMunicipalidad = function ( item ) {
                swal({
                  title: "¿Está seguro de cancelar el perfil de " + item.tcPersona.personaDesc + "?",
                  text: "Esta acción no se podrá reversar, es decir es definitiva",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.quitarPersonaDeMunicipalidad( item.personaMunicipalidadId ) .then( function ( res ) {
                      if ( res.status == 1 ) {
                        toastr.success( res.message );
                        $scope.cargarDatosMunicipalidad($scope.cambio.municipioId);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });
              }
              
              $scope.autorizarSolicitud = function ( item ) {
                swal({
                  title: "¿Está seguro de autorizar la solicitud de " + item.tcPersona.personaDesc + "?",
                  text: "Se le enviará una notificación a la persona",
                  icon: "warning",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true
                },
                function(isConfirm) {
                  if (isConfirm) {
                    personaService.autorizarCambioPerfil( item.solicitudPerfilId ) .then( function ( res ) {
                      if ( res.status == 1 ) {
                        toastr.success( res.message );
                        $scope.actualizar();
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                });                
              }

              $scope.denegarSolicitud = function ( item ) {
                swal({
                  title: "¿Está seguro de denegar la solicitud de: " + item.tcPersona.personaDesc + "?",
                  text: "Se le enviará una notificación a la persona",
                  type: "input",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  inputPlaceholder: "Indique la razón"
                },
                function(inputValue) {
                  if (inputValue === false) return false;
                  if (inputValue === "") {
                    toastr.error('Es necesario indicar la razón de la negación');
                    return false;
                  }
                  item.observaciones = inputValue;
                  personaService.denegarCambioPerfil( item ) .then( function ( res ) {
                    if ( res.status == 1 ) {
                      toastr.success( res.message );
                      $scope.actualizar();
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                });
              }

            }]
        })
    }
  ]
);
