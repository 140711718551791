angular.module('app.usuarioperfilsistema', [
  'ui.router',
  'toastr',
  'app.usuarioperfilsistema.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.usuarioperfilsistema', {
          abstract: true,
          url: 'usuarioperfilsistema',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1006);
              }],
            dataPerfil: ['perfilService',
              function ( perfilService ){
                return perfilService.listaActiva();
              }],
            dataSistema: ['sistemaService',
              function ( sistemaService ){
                return sistemaService.listaActiva();
              }],
            dataUsuario: ['usuarioService',
              function ( usuarioService ){
                return usuarioService.listaActiva();
              }]
          },
          controller: ['$scope', 'dataPerfil', 'dataSistema', 'dataUsuario',
            function ($scope, dataPerfil, dataSistema, dataUsuario) {
              $scope.dataPerfil = dataPerfil.data;
              $scope.dataSistema = dataSistema.data;
              $scope.dataUsuario = dataUsuario.data;
              $scope.module = 'Usuario Perfil y Sistema';
            }]
        })
        .state('index.usuarioperfilsistema.list', {
          url: '',
          templateUrl: 'app/usuarioperfilsistema/usuarioperfilsistema.list.tpl.html',
          resolve: {
          },
          controller: ['$scope', '$state', 'usuarioperfilsistemaService', 'toastr',
            function ($scope, $state, usuarioperfilsistemaService, toastr) {

              $scope.data = {
                tcSistema: null,
                tcPerfil: null,
                tcUsuario: null
              };

              $scope.visibleUsuario = false;
              $scope.isEdit = false;

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'tcSistema.sistemaDesc', name: 'sistemaDesc', displayName: 'Sistema' },
                { field:'tcPerfil.perfilDesc', name: 'perfilDesc', displayName: 'Perfil' },
                { field:'tcUsuario.usuarioDesc', name: 'usuarioDesc', displayName: 'Nombre' },
                { field:'tcUsuario.correo', name: 'correo', displayName: 'Correo' },
                { field:'tcUsuario.usuario', name: 'usuario', displayName: 'Usuario' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = [];

              $scope.refrescarDatos = function (tcSistema, tcPerfil) {
                if (tcSistema == null || tcPerfil == null) {
                  return;
                }
                if (tcSistema.sistemaId == null || tcPerfil.perfilId == null) {
                  return;
                }
                usuarioperfilsistemaService.usuarioBySistemaPerfil(tcSistema.sistemaId, tcPerfil.perfilId).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.gridOptions.data = res.data;
                    toastr.success( res.message );
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setPerfil = function (item) {
                $scope.data.tcPerfil = item;
                if (!$scope.isEdit) {
                  $scope.refrescarDatos($scope.data.tcSistema, item);
                }
              }

              $scope.setSistema = function (item) {
                $scope.data.tcSistema = item;
                if (!$scope.isEdit) {
                  $scope.refrescarDatos(item, $scope.data.tcPerfil);
                }
              }

              $scope.agregarRegistro = function () {
                $scope.isEdit = false;
                $scope.visibleUsuario = true;
              }

              $scope.setUsuario = function ( item ) {
                if ($scope.isEdit) {
                  $scope.isEdit = false;
                  $scope.data.usuarioPerfilSistemaId = 0;
                }
                var data = $scope.gridOptions.data;
                var encontrado = false;
                for (var i = 0; i < data.length; i++) {
                  if (data[i].tcUsuario.usuarioId == item.usuarioId) {
                    encontrado = true;
                    break;
                  }
                }
                if (encontrado) {
                  $scope.data.tcUsuario = null;
                  $scope.usuarioId = null;
                  toastr.error('El usuario seleccionado ya contiene este perfil');
                } else {
                  $scope.data.tcUsuario = item;
                }
              }

              $scope.cancelarRegistro = function () {
                $scope.visibleUsuario = false;
                $scope.isEdit = false;
                $scope.data.tcUsuario = null;
                $scope.data = {
                  tcPerfil: null,
                  tcUsuario: null,
                  tcSistema: null
                };
                $scope.gridOptions.data = [];
              }

              $scope.guardarDatos = function () {
                if($scope.data.tcPerfil == null || $scope.data.tcUsuario == null) {
                  toastr.error('Debe seleccionar el perfil y el usuario para continuar');
                  return;
                }
                if ($scope.isEdit) {
                  usuarioperfilsistemaService.edit($scope.data).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var tcPerfil = $scope.data.tcPerfil;
                      var tcSistema = $scope.data.tcSistema;
                      $scope.data = {
                        tcSistema: tcSistema,
                        tcPerfil: tcPerfil,
                        tcUsuario: null
                      };
                      $scope.visibleUsuario = false;
                      $scope.isEdit = false;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                } else {
                  usuarioperfilsistemaService.add($scope.data).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var tcPerfil = $scope.data.tcPerfil;
                      var tcSistema = $scope.data.tcSistema;
                      $scope.data = {
                        tcSistema: tcSistema,
                        tcPerfil: tcPerfil,
                        tcUsuario: null
                      };
                      $scope.setPerfil(tcPerfil);
                      $scope.visibleUsuario = false;
                      $scope.isEdit = false;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.editRow = function ( row ) {
                $scope.visibleUsuario = true;
                $scope.isEdit = true;
                $scope.data = row;
              }

              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.usuarioperfilsistema.add', {
          url: '/add',
          templateUrl: 'app/usuarioperfilsistema/usuarioperfilsistema.add.tpl.html',
          resolve: {
            dataEstado: ['usuarioperfilsistemaService',
              function ( usuarioperfilsistemaService ){
                return usuarioperfilsistemaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuarioperfilsistemaService', 'dataEstado',
            function (  $scope,   $state,   toastr,   usuarioperfilsistemaService, dataEstado) {
              $scope.data = {
                tcSistema: $scope.tcSistema,
                tcPerfil: null,
                tcUsuario: null
              };
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuarioperfilsistemaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.usuarioperfilsistema.edit', {
          url: '/:usuarioPerfilSistemaId/edit',
          templateUrl: 'app/usuarioperfilsistema/usuarioperfilsistema.add.tpl.html',
          resolve: {
            dataUsuarioPerfilSistema: ['usuarioperfilsistemaService', '$stateParams',
              function ( usuarioperfilsistemaService, $stateParams ){
                return usuarioperfilsistemaService.get( $stateParams.usuarioPerfilSistemaId );
              }],
            dataEstado: ['usuarioperfilsistemaService',
              function ( usuarioperfilsistemaService ){
                return usuarioperfilsistemaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'usuarioperfilsistemaService', 'dataUsuarioPerfilSistema', 'dataEstado',
            function (  $scope,   $state,   toastr,   usuarioperfilsistemaService,   dataUsuarioPerfilSistema, dataEstado) {
              $scope.data = dataUsuarioPerfilSistema.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  usuarioperfilsistemaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
