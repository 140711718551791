angular.module('app.actividad', [
  'ui.router',
  'toastr',
  'app.actividad.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.actividad', {
          abstract: true,
          url: 'actividad',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Actividad';
            }]
        })
        .state('index.actividad.list', {
          url: '',
          templateUrl: 'app/actividad/actividad.list.tpl.html',
          resolve: {
            dataActividad: ['actividadService',
              function ( actividadService ){
                return actividadService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataActividad',
            function (  $scope,   $state,   dataActividad) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'actividadDesc', name: 'actividadDesc', displayName: 'Actividad' },
                { field:'padreDesc', name: 'padreDesc', displayName: 'Padre' },
                { field:'obligatorio', name: 'obligatorio', displayName: 'Obligatorio' },
                { field:'orden', name: 'orden', displayName: 'Orden' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataActividad.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ actividadId: row.actividadId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.actividad.add', {
          url: '/add',
          templateUrl: 'app/actividad/actividad.add.tpl.html',
          resolve: {
            dataEstado: ['actividadService',
              function ( actividadService ){
                return actividadService.listStatus();
              }],
            dataPadre: ['actividadService',
              function ( actividadService ){
                return actividadService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'actividadService', 'dataEstado', 'dataPadre',
            function (  $scope,   $state,   toastr,   actividadService, dataEstado, dataPadre) {
              $scope.dataActividad = dataPadre.data;
              $scope.classObligatorio = 'btn-success btn-sm glyphicon glyphicon-unchecked';
              $scope.textoObligatorio = ' No';
              $scope.setObligatorio = function () {
                if ($scope.data.esObligatorio == 0) {
                  $scope.data.esObligatorio = 1;
                  $scope.classObligatorio = 'btn-danger btn-sm glyphicon glyphicon-check';
                  $scope.textoObligatorio = ' Si';
                } else {
                  $scope.data.esObligatorio = 0;
                  $scope.classObligatorio = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                  $scope.textoObligatorio = ' No';
                }
              }
              $scope.data = {
                estadoId : 1,
                esObligatorio : 0
              };
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  actividadService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.actividad.edit', {
          url: '/:actividadId/edit',
          templateUrl: 'app/actividad/actividad.add.tpl.html',
          resolve: {
            dataActividad: ['actividadService', '$stateParams',
              function ( actividadService, $stateParams ){
                return actividadService.get( $stateParams.actividadId );
              }],
            dataEstado: ['actividadService',
              function ( actividadService ){
                return actividadService.listStatus();
              }],
            dataPadre: ['actividadService',
              function ( actividadService ){
                return actividadService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'actividadService', 'dataActividad', 'dataEstado', 'dataPadre',
            function (  $scope,   $state,   toastr,   actividadService,   dataActividad, dataEstado, dataPadre) {
              $scope.data = dataActividad.data;
              if ($scope.data.esObligatorio == 1) {
                $scope.classObligatorio = 'btn-danger btn-sm glyphicon glyphicon-check';
                $scope.textoObligatorio = ' Si';
              } else {
                $scope.classObligatorio = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                $scope.textoObligatorio = ' No';
              }
              $scope.dataActividad = dataPadre.data;
              $scope.setObligatorio = function () {
                if ($scope.data.esObligatorio == 0) {
                  $scope.data.esObligatorio = 1;
                  $scope.classObligatorio = 'btn-danger btn-sm glyphicon glyphicon-check';
                  $scope.textoObligatorio = 'Si';
                } else {
                  $scope.data.esObligatorio = 0;
                  $scope.classObligatorio = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                  $scope.textoObligatorio = 'No';
                }
              }
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  actividadService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
