angular.module('app.aval', [
  'ui.router',
  'toastr',
  'app.aval.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.aval', {
          abstract: true,
          url: 'aval',
          template: '<div ui-view></div>',
          resolve: {
          },
          controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
            function (  $scope, toastr, $timeout, gestionService, utilsService) {
              $scope.module = 'Aval de de garantía fiduciaria';
              $scope.globales.totalPaginas = 0;

              $scope.imprimirPlan = function (row) {
                var gestionId = row.gestionId;
                try {
                  gestionService.get( gestionId ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      try {
                        if (res.data.inventario.data != undefined && res.data.inventario.data != null) {
                          res.data.inventario.data = JSON.parse(res.data.inventario.data);
                        }
                        if (res.data.inventario.rodal != undefined && res.data.inventario.rodal != null) {
                          res.data.inventario.rodal = JSON.parse(res.data.inventario.rodal);
                        }
                        if (res.data.inventario.codigoEspecie != undefined && res.data.inventario.codigoEspecie != null) {
                          res.data.inventario.codigoEspecie = JSON.parse(res.data.inventario.codigoEspecie);
                        }
                        if (res.data.inventario.codigoClase != undefined && res.data.inventario.codigoClase != null) {
                          res.data.inventario.codigoClase = JSON.parse(res.data.inventario.codigoClase);
                        }
                        if (res.data.inventario.tratamiento != undefined && res.data.inventario.tratamiento != null) {
                          res.data.inventario.tratamiento = JSON.parse(res.data.inventario.tratamiento);
                        }
                        if (res.data.inventario.repoblacion != undefined && res.data.inventario.repoblacion != null) {
                          res.data.inventario.repoblacion = JSON.parse(res.data.inventario.repoblacion);
                        }
                        if (res.data.inventario.inventarioRodal != undefined && res.data.inventario.inventarioRodal != null) {
                          res.data.inventario.inventarioRodal = JSON.parse(res.data.inventario.inventarioRodal);
                        } else {
                          res.data.inventario.inventarioRodal = [];
                        }
                        if (res.data.inventario.distribucionRodal != undefined && res.data.inventario.distribucionRodal != null) {
                          res.data.inventario.distribucionRodal = JSON.parse(res.data.inventario.distribucionRodal);
                        } else {
                          res.data.inventario.distribucionRodal = [];
                        }
                        if (res.data.inventario.noMaderable != undefined && res.data.inventario.noMaderable != null) {
                          res.data.inventario.noMaderable = JSON.parse(res.data.inventario.noMaderable);
                        } else {
                          res.data.inventario.noMaderable = [];
                        }
                        if (res.data.planificacion.silvicultura != undefined && res.data.planificacion.silvicultura != null) {
                          res.data.planificacion.silvicultura = JSON.parse(res.data.planificacion.silvicultura);
                        }
                        if (res.data.planificacion.planCorta != undefined && res.data.planificacion.planCorta != null) {
                          res.data.planificacion.planCorta = JSON.parse(res.data.planificacion.planCorta);
                        }
                        if (res.data.planificacion.noMaderable != undefined && res.data.planificacion.noMaderable != null) {
                          res.data.planificacion.noMaderable = JSON.parse(res.data.planificacion.noMaderable);
                        }
                        if (res.data.planificacion.repoblacion != undefined && res.data.planificacion.repoblacion != null) {
                          res.data.planificacion.repoblacion = JSON.parse(res.data.planificacion.repoblacion);
                        }
                        res.data.cronograma = JSON.parse(res.data.cronograma);
                        utilsService.generarPlan($scope.dataEmpresa, res.data);
                        $timeout(function() {
                          $scope.imprimirCronograma(res.data);
                        }, 3000);
                      } catch (e) {
                        toastr.error("Error al generar el documento " + e);
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                } catch (e) {
                  toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador " + e);
                }
              }

              $scope.imprimirCronograma = function ( data ) {
                $scope.globales.totalPaginas = utilsService.getLastPage();
                if ($scope.globales.totalPaginas <= 0) {
                  toastr.error("Debe imprimir primero el plan luego el cronograma");
                } else {
                  utilsService.generarCronograma($scope.dataEmpresa, data, $scope.globales.totalPaginas);
                }
              }

            }]
        })
        .state('index.aval.list', {
          url: '',
          templateUrl: 'app/aval/aval.list.tpl.html',
          resolve: {
            dataAval: ['avalService', 'authService', '$filter',
              function ( avalService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 60);
                var fechaIni = $filter( 'date' )(begin, 'yyyy-MM-dd 00:00:00' );
                var fechaFin = $filter( 'date' )(now, 'yyyy-MM-dd 23:59:59' );
                var filtro = {
                  fechaIni : fechaIni,
                  fechaFin : fechaFin,
                  estadoId : 1,
                  usuarioId : loginData.usuarioId,
                  subregionId : loginData.subregionId,
                  perfilId : loginData.perfilId
                }
                return avalService.listado(filtro);
              }]
          },
          controller: ['$scope', '$state', 'dataAval', 'uiGridConstants', 'toastr', '$filter', 'avalService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
            function (  $scope,   $state,   dataAval, uiGridConstants, toastr, $filter, avalService, utilsService, gestionService, $timeout, ngDialog) {
              var now = new Date();
              var begin = $scope.addDays(now, -60);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : 1,
                usuarioId : $scope.loginData.usuarioId,
                subregionId : $scope.loginData.subregionId,
                perfilId : $scope.loginData.perfilId
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'nug', name: 'nug', displayName: 'NUG', width: '10%' } ,
                { field:'personaSolicita', name: 'personaSolicita', displayName: 'Solicitante' },
                { field:'fechaAval', name: 'fechaAval', displayName: 'Fecha aval', width: '10%' },
                { field:'area', name: 'area', displayName: 'Área', width: '5%' },
                { field:'planTipoGestionDesc', name: 'planTipoGestionDesc', displayName: 'Tipo' },
                { field:'tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                { name: 'Opciones', enableFiltering: false, width: '8%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.recibirAval(row.entity)" title="Recibir solicid para iniciar su trámite"> <i class="fa fa-download"></i> </button> '
                    + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.generarEnmienda(row.entity)" title="Marcar como expediente incompleto y generar notificación de completación"> <i class="fa fa-file-pdf-o"></i> </button> '
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataAval.data;

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                  var fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                  var filtro = {
                    fechaIni : fechaIni,
                    fechaFin : fechaFin,
                    estadoId : 1,
                    usuarioId : $scope.loginData.usuarioId,
                    subregionId : $scope.loginData.subregionId,
                    perfilId : $scope.loginData.perfilId
                  }
                  avalService.listado( filtro ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.recibirAval = function (item) {
                $scope.item = item;
                ngDialog.open({
                  template: 'app/aval/requisito.gestion.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataRequisito: ['requisitoService',
                      function ( requisitoService ){
                        return requisitoService.getRequisitoGestion(item.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataRequisito', 'gestionService',
                    function ($scope, $state, toastr, dataRequisito, gestionService) {
                      $scope.mostrarNoAplica = false;
                      $scope.mostrarPersona = true;
                      $scope.titulo = "Marque los requisitos que incluye el expediente";
                      for (var i = 0; i < dataRequisito.data.obligatorio.length; i++) {
                        dataRequisito.data.obligatorio[i].marcado = 0;
                        dataRequisito.data.obligatorio[i].visible = true;
                        dataRequisito.data.obligatorio[i].class = "btn-default btn-xs fa fa-square-o";
                        dataRequisito.data.obligatorio[i].classNo = "btn-default btn-xs fa fa-square-o";
                      }
                      $scope.dataRequisito = dataRequisito.data.obligatorio;

                      for (var i = 0; i < dataRequisito.data.opcional.length; i++) {
                        dataRequisito.data.opcional[i].marcado = 0;
                        dataRequisito.data.opcional[i].visible = true;
                        dataRequisito.data.opcional[i].class = "btn-default btn-xs fa fa-square-o";
                        dataRequisito.data.opcional[i].classNo = "btn-default btn-xs fa fa-square-o";
                      }
                      $scope.dataOtroRequisito = dataRequisito.data.opcional;

                      $scope.marcarRequisito = function ( item ) {
                        if (item.marcado == 1) {
                          item.marcado = 0;
                          item.class = 'btn-default btn-xs fa fa-square-o';
                        } else {
                          item.marcado = 1;
                          item.class = 'btn-success btn-xs fa fa-check-square-o';
                        }
                      }

                      $scope.marcarNoAplica = function ( item ) {
                        if (parseInt(item.obligatorio) == 1) {
                          item.obligatorio = 0;
                          item.classNo = 'btn-danger btn-xs fa fa-check-square-o';
                        } else {
                          item.obligatorio = 1;
                          item.classNo = 'btn-default btn-xs fa fa-square-o';
                        }
                      }

                      $scope.cerrarVentana = function () {
                        ngDialog.close();
                      }

                      $scope.aceptarRequisito = function () {
                        var data = $scope.dataRequisito;
                        var continuar = true;
                        if ($scope.nombreEntregaDesc == null) {
                          continuar = false;
                        } else {
                          if ($scope.nombreEntregaDesc.length <= 0) {
                            continuar = false;
                          }
                        }
                        var dataRequisito = [];
                        if (continuar) {
                          var i;
                          for (i = 0; i < data.length; i++) {
                            if (data[i].obligatorio == 1 && data[i].marcado == 0 && data[i].visible == true) {
                              continuar = false;
                              break;
                            } else {
                              if (data[i].marcado == 1) {
                                dataRequisito.push({requisitoId : data[i].requisitoId, requisitoDesc : data[i].requisitoDesc});
                              }
                            }
                          }
                          for (i = 0; i < $scope.dataOtroRequisito.length; i++) {
                            if ($scope.dataOtroRequisito[i].marcado == 1){
                              dataRequisito.push({requisitoId : $scope.dataOtroRequisito[i].requisitoId, requisitoDesc : $scope.dataOtroRequisito[i].requisitoDesc});
                            }
                          }
                        }
                        if (continuar) {
                          var data = {
                            avalId : $scope.item.avalId,
                            gestionId : $scope.item.gestionId,
                            usuarioRegistraId : $scope.loginData.usuarioId,
                            tcPersonaEntrega: {
                              personaDesc : $scope.nombreEntregaDesc
                            },
                            folios : $scope.folios,
                            subregionId : $scope.loginData.subregionId,
                            usuarioId : $scope.loginData.usuarioId,
                            personaId : $scope.loginData.personaId,
                            requisito : dataRequisito
                          }
                          gestionService.recibirAval( data ) .then( function ( res ) {
                            if ( res.status == "OK" ) {
                              gestionService.getDatosRecepcion( $scope.item.gestionId ) .then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  utilsService.generarConstanciaRecepcion(res.data, $scope.loginData.subregionDesc, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                  $timeout(function() {
                                    utilsService.generarCaratulaRecepcion(res.data, $scope.loginData.subregionDesc, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                    $state.go("index.tarea.list");
                                  }, 3000);
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                              $scope.submitForm(true);
                              ngDialog.close();
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        } else {
                          toastr.error("Todos los requisitos indicados con asterísco son obligatorios");
                        }
                      }

                    }]
                });
              }

              $scope.generarEnmienda = function (item) {
                $scope.item = item;
                ngDialog.open({
                  template: 'app/aval/requisito.gestion.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataRequisito: ['requisitoService',
                      function ( requisitoService ){
                        return requisitoService.getRequisitoGestion(item.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataRequisito', 'gestionService',
                    function ($scope, $state, toastr, dataRequisito, gestionService) {
                      $scope.mostrarNoAplica = false;
                      $scope.mostrarPersona = false;
                      $scope.titulo = "Marque los requisitos que debe completar el solicitante";
                      for (var i = 0; i < dataRequisito.data.obligatorio.length; i++) {
                        dataRequisito.data.obligatorio[i].marcado = 0;
                        dataRequisito.data.obligatorio[i].visible = true;
                        dataRequisito.data.obligatorio[i].class = "btn-default btn-xs fa fa-square-o";
                      }
                      $scope.dataRequisito = dataRequisito.data.obligatorio;

                      for (var i = 0; i < dataRequisito.data.opcional.length; i++) {
                        dataRequisito.data.opcional[i].marcado = 0;
                        dataRequisito.data.opcional[i].visible = true;
                        dataRequisito.data.opcional[i].class = "btn-default btn-xs fa fa-square-o";
                        dataRequisito.data.opcional[i].classNo = "btn-default btn-xs fa fa-square-o";
                      }
                      $scope.dataOtroRequisito = dataRequisito.data.opcional;

                      $scope.marcarRequisito = function ( item ) {
                        if (item.marcado == 1) {
                          item.marcado = 0;
                          item.class = 'btn-default btn-xs fa fa-square-o';
                        } else {
                          item.marcado = 1;
                          item.class = 'btn-success btn-xs fa fa-check-square-o';
                        }
                      }

                      $scope.cerrarVentana = function () {
                        ngDialog.close();
                      }

                      $scope.aceptarRequisito = function () {
                        var data = angular.copy($scope.dataRequisito);
                        var continuar = false;
                        var i;
                        for (i = 0; i < data.length; i++) {
                          if (data[i].marcado == 1) {
                            continuar = true;
                            break;
                          }
                        }
                        if (continuar) {
                          for (i = 0; i < $scope.dataOtroRequisito.length; i++) {
                            if ($scope.dataOtroRequisito[i].marcado == 1) {
                              data.push($scope.dataOtroRequisito[i]);
                            }
                          }
                          var filtro = {
                            avalId : $scope.item.avalId,
                            gestionId : $scope.item.gestionId
                          }
                          avalService.marcarEnmienda( filtro ) .then( function ( res ) {
                            if ( res.status == "OK" ) {
                              utilsService.generarNotificacionCompletacion(data, $scope.loginData.subregionDesc, $scope.item.nug, $scope.item.personaSolicita, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                              ngDialog.close();
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        } else {
                          toastr.error("Debe marcar como mínimo una enmienda");
                        }
                      }

                    }]
                });
              }

              $scope.verAnexos = function ( item ) {
                ngDialog.open({
                  template: 'app/gestion/anexo.gestion.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataAnexo: ['gestionService',
                      function ( gestionService ){
                        return gestionService.getGestionAnexo(item.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                    function ($scope, $state, toastr, dataAnexo, gestionService) {
                      $scope.dataAnexo = dataAnexo.data;
                      $scope.cerrarVentana = function () {
                        ngDialog.close();
                      }

                      $scope.abrirAnexo = function (anexoGestionId) {
                        gestionService.getAnexoGestion( anexoGestionId );
                      }

                    }]
                });
              }

            }]
        })
        .state('index.aval.aceptado', {
          url: '/aceptado',
          templateUrl: 'app/aval/aval.list.tpl.html',
          resolve: {
            dataAval: ['avalService', 'authService', '$filter',
              function ( avalService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 60);
                var fechaIni = $filter( 'date' )(begin, 'yyyy-MM-dd 00:00:00' );
                var fechaFin = $filter( 'date' )(now, 'yyyy-MM-dd 23:59:59' );
                var filtro = {
                  fechaIni : fechaIni,
                  fechaFin : fechaFin,
                  estadoId : 2,
                  usuarioId : loginData.usuarioId,
                  subregionId : loginData.subregionId,
                  perfilId : loginData.perfilId
                }
                return avalService.listado(filtro);
              }]
          },
          controller: ['$scope', '$state', 'dataAval', 'uiGridConstants', 'toastr', '$filter', 'avalService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
            function (  $scope,   $state,   dataAval, uiGridConstants, toastr, $filter, avalService, utilsService, gestionService, $timeout, ngDialog) {
              var now = new Date();
              var begin = $scope.addDays(now, -60);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : 2,
                usuarioId : $scope.loginData.usuarioId,
                subregionId : $scope.loginData.subregionId,
                perfilId : $scope.loginData.perfilId
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'nug', name: 'nug', displayName: 'NUG', width: '10%' } ,
                { field:'personaSolicita', name: 'personaSolicita', displayName: 'Solicitante' },
                { field:'fechaAcepta', name: 'fechaAcepta', displayName: 'Fecha recepción', width: '10%' },
                { field:'area', name: 'area', displayName: 'Área', width: '5%' },
                { field:'planTipoGestionDesc', name: 'planTipoGestionDesc', displayName: 'Tipo' },
                { field:'tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                { name: 'Opciones', enableFiltering: false, width: '8%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                    + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.imprimirRecepcion(row.entity)" title="Imprimir constancia de recepción"> <i class="fa fa-download"></i> </button> '
                    + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataAval.data;

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                  var fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                  var filtro = {
                    fechaIni : fechaIni,
                    fechaFin : fechaFin,
                    estadoId : 2,
                    usuarioId : $scope.loginData.usuarioId,
                    subregionId : $scope.loginData.subregionId,
                    perfilId : $scope.loginData.perfilId
                  }
                  avalService.listado( filtro ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.imprimirRecepcion = function ( item ) {
                gestionService.getDatosRecepcion( item.gestionId ) .then( function ( res ) {
                  if ( res.status == "OK" ) {
                    utilsService.generarConstanciaRecepcion(res.data, $scope.loginData.subregionDesc, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                    $timeout(function() {
                      utilsService.generarCaratulaRecepcion(res.data, $scope.loginData.subregionDesc, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                    }, 3000);
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.verAnexos = function ( item ) {
                ngDialog.open({
                  template: 'app/gestion/anexo.gestion.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataAnexo: ['gestionService',
                      function ( gestionService ){
                        return gestionService.getGestionAnexo(item.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService',
                    function ($scope, $state, toastr, dataAnexo, gestionService) {
                      $scope.dataAnexo = dataAnexo.data;
                      $scope.cerrarVentana = function () {
                        ngDialog.close();
                      }

                      $scope.abrirAnexo = function (anexoGestionId) {
                        gestionService.getAnexoGestion( anexoGestionId );
                      }

                    }]
                });
              }

            }]
        })
    }
  ]
);
