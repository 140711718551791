angular.module('app.institucionDependencia.service', [

])

.factory('institucionDependenciaService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'institucionDependencia/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'institucionDependencia/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'institucionDependencia/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'institucionDependencia/' + data.institucionDependenciaId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( institucionDependenciaId ) {
      return $http.get( appSettings.urlServiceBase + 'institucionDependencia/' + institucionDependenciaId).then(function ( res ) {
         return res.data;
       });
    },
    getByInstitucion : function( institucionId ) {
      return $http.get( appSettings.urlServiceBase + 'institucionDependencia/institucion/' + institucionId).then(function ( res ) {
         return res.data;
       });
    },
    getByInstitucionAndMunicipio : function( institucionId, municipioId ) {
      return $http.get( appSettings.urlServiceBase + 'institucionDependencia/institucion/' + institucionId + '/municipio/' + municipioId).then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
