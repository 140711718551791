angular.module('app.exento', [
  'ui.router',
  'toastr',
  'app.exento.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.exento', {
            abstract: true,
            url: 'exento',
            template: '<div ui-view></div>',
            resolve: {
            },
            controller: ['$scope', 'municipioService', 'fincaService', 'toastr', 'ngDialog',
              function ($scope, municipioService, fincaService, toastr, ngDialog) {
                $scope.module = 'Inscripciones de RNF';
                $scope.direccion = {};
                $scope.dataExento = {
                  data: null,
                  loteSemilla: null
                };
                $scope.dataLoteSemilla = {};
                $scope.cargarListaMunicipioFinca = function (item) {
                  $scope.direccion.municipioId = null;
                  municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                    if (res.status == "OK") {
                      $scope.dataMunicipioFinca = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setMunicipioFinca = function (item) {
                  if (item) {
                    fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataFinca = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.saldoExento = function (item) {
                  ngDialog.open({
                    template: 'app/exento/saldo.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueMunicipio',
                    name: 'dialogMunicipio',
                    resolve: {
                      dataExento: ['exentoService', 'authService', '$filter', 'tareaService',
                        function (exentoService, authService, $filter, tareaService) {
                          return exentoService.getSaldo(item.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataExento',
                      function ($scope, $state, toastr, dataExento) {

                        if (dataExento.status == 1) {
                          $scope.dataSaldo = dataExento.data;
                        } else {
                          toastr.error(dataExento.message);
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                      }]
                  })
                }

              }]
          })
          .state('index.exento.listar', {
            url: '',
            templateUrl: 'app/exento/exento.list.tpl.html',
            resolve: {
              dataExento: ['exentoService', 'authService', '$filter', 'tareaService',
                function (exentoService, authService, $filter, tareaService) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  return exentoService.listaPorPersona(loginData.personaId, -1);
                }]
            },
            controller: ['$scope', '$state', 'dataExento', 'exentoService', '$filter', 'utilsService', 'tareaService', 'toastr',
              'ngDialog', 'personaService', '$base64',
              function ($scope, $state, dataExento, exentoService, $filter, utilsService, tareaService, toastr,
                ngDialog, personaService, $base64) {
                $scope.dataEstado = [
                  { estadoId: -1, estadoDesc: 'Todos' },
                  { estadoId: 0, estadoDesc: 'Anulado' },
                  { estadoId: 1, estadoDesc: 'Activo' },
                  { estadoId: 2, estadoDesc: 'Elaborado' },
                  { estadoId: 3, estadoDesc: 'Solicitado' },
                  { estadoId: 4, estadoDesc: 'En trámite' },
                  { estadoId: 5, estadoDesc: 'Aprobado' },
                  { estadoId: 6, estadoDesc: 'Finalizado' }
                ];

                var now = new Date();
                var begin = $scope.addDays(now, -90);
                $scope.globales.fincaId = null;

                $scope.data = {
                  estadoId: -1,
                  personaId: $scope.loginData.personaId
                }

                $scope.mostrarPedirNotas = function (estadoId) {
                  if (estadoId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEditar = function (estadoId) {
                  if (estadoId == 1 || estadoId == 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEnviar = function (estadoId) {
                  if (estadoId == 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAutorizacion = function (estadoId) {
                  if (estadoId > 4) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarEnmienda = function (item) {
                  if (item.estadoId == 1 && item.expediente != null) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.columnDefs = [
                  { field: 'codigo', name: 'codigo', displayName: 'RNF' },
                  { field: 'tipoRegistroDesc', name: 'tipoRegistroDesc', displayName: 'Categoría' },
                  { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                  { field: 'tcTask.actorDesc', name: 'actorDesc', displayName: 'Cargo' },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarEnviar(row.entity.estadoId)"  ng-click="grid.appScope.enviarDocumento(row.entity)" title="Enviar solicitud al INAB"> <i class="fa fa-paper-plane-o"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-show="grid.appScope.mostrarPedirNotas(row.entity.estadoId)" ng-click="grid.appScope.solicitarNotas(row.entity)" title="Lista de solicitudes o solicitar nueva venta de notas de envío"><i class="fa fa-bars" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 1" ng-click="grid.appScope.imprimirFormato(row.entity)" title="Ver solicitud de carga inicial"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAutorizacion(row.entity.estadoId)" ng-click="grid.appScope.imprimirAutorizacion(row.entity)" title="Ver resolución de carga inicial"><i class="fa fa-file-pdf-o text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarPedirNotas(row.entity.estadoId)" ng-click="grid.appScope.saldoExento(row.entity)" title="Saldo disponible"><i class="fa fa-tree fa-lg text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarEnmienda(row.entity)" ng-click="grid.appScope.verHistorialEnmiendas(row.entity)" title="Ver historial de enmiendas"><i class="fa fa-warning" aria-hidden="true"></i></button>'
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataExento.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    exentoService.listaPorPersona($scope.loginData.personaId, $scope.data.estadoId).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.solicitarNotas = function (row) {
                  $scope.dataExento = row;
                  ngDialog.open({
                    template: 'app/exento/venta.list.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataVenta: ['exentoService', 'authService', '$filter', 'tareaService',
                        function (exentoService, authService, $filter, tareaService) {
                          return exentoService.getListaVenta(row.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataVenta', 'tareaService', 'Upload',
                      function ($scope, $state, toastr, dataVenta, tareaService, Upload) {

                        $scope.etiquetaEstado = function (estadoId, rutaBoleta) {
                          switch (estadoId) {
                            case 1: return 'Solicitado';
                            case 2: return 'En trámite';
                            case 3:
                              if (rutaBoleta == null) {
                                return 'Esperando pago';
                              } else {
                                return 'Pago realizado';
                              }
                            case 4: return 'Autorizado';
                            case 5: return 'Finalizado';
                            case 20: return 'Suspendido';
                            case 99: return 'Denegado';
                            default: return "Inactivo";
                          }
                        }

                        $scope.mostrarReportar = function (estadoId) {
                          if (estadoId > 3) {
                            return true;
                          }
                          return false;
                        }

                        $scope.mostrarPagar = function (row) {
                          if (row.estadoId == 3 && row.rutaBoleta == null) {
                            return true;
                          }
                          return false;
                        }

                        $scope.mostrarImprimirSolicitud = function (row) {
                          if (row.estadoId == 5 && row.autorizadoNotas == 0) {
                            return false;
                          }
                          return true;
                        }

                        $scope.gridOptionsVenta = angular.copy($scope.gridOptionsSelection);
                        $scope.gridOptionsVenta.columnDefs = [
                          { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha solicitud', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                          { field: 'cantidadNotas', name: 'cantidadNotas', displayName: 'Notas solicitada' },
                          { field: 'autorizadoNotas', name: 'autorizadoNotas', displayName: 'Notas autorizadas' },
                          { field: 'porcentajeReportado', name: 'porcentajeReportado', displayName: '% Reportado' },
                          { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución' },
                          { field: 'fechaAutorizacion', name: 'fechaAutorizacion', displayName: 'Fecha autorización', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                          { field: 'ttExento.tcTask.actorDesc', name: 'actorDesc', displayName: 'Puesto', width: '10%' },
                          {
                            name: 'estadoId', displayName: "Estado", field: 'estadoId',
                            cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId, row.entity.rutaBoleta)}}</div>'
                          },
                          {
                            name: 'Opciones', enableFiltering: false,
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarReportar(row.entity.estadoId)" ng-click="grid.appScope.listarInformes(row.entity)" title="Listar informes o generar informe"><i class="fa fa-percent" aria-hidden="true"></i></button> '
                              + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarPagar(row.entity)" ng-click="grid.appScope.adjuntarDocumentoPago(row.entity)" title="Adjuntar comprobante de pago"><i class="fa fa-money text-success" aria-hidden="true"></i></button> '
                              + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarImprimirSolicitud(row.entity)" ng-click="grid.appScope.imprimirSolicitud(row.entity)" title="Imprimir solicitud de venta"><i class="fa fa-file-pdf-o text-red" aria-hidden="true"></i></button> '
                              + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 2 && row.entity.cantidadNotas > 0" ng-click="grid.appScope.imprimirNotificacion(row.entity)" title="Imprimir notificacion"><i class="fa fa-file-pdf-o text-info" aria-hidden="true"></i></button> '
                              + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 2 && row.entity.cantidadNotas > 0" ng-click="grid.appScope.imprimirResolucion(row.entity)" title="Imprimir resolucion de venta"><i class="fa fa-file-pdf-o text-primary" aria-hidden="true"></i></button> '
                              + ' <button type="button" class="btn btn-success btn-xs" ng-show="row.entity.electronica" ng-click="grid.appScope.listarNotasElectronicas(row.entity)" title="Agregar o listar notas electrónicas"><i class="fa fa-leaf fa-lg" aria-hidden="true"></i></button> '
                              + '</span></div>'
                          }
                        ];


                        if (dataVenta.status == "OK") {
                          $scope.gridOptionsVenta.data = dataVenta.data;
                        } else {
                          $scope.gridOptionsVenta.data = [];
                        }

                        $scope.classButton = "btn btn-danger";
                        $scope.adjuntarDocumentoPago = function (item) {
                          $scope.ttVentaExento = item;
                          $scope.mostrarAdjuntar = true;
                        }

                        $scope.onFileSelect = function (file, tipoAnexoId) {
                          Upload.upload({
                            url: appSettings.urlServiceBase + 'file/upload',
                            method: 'POST',
                            file: file,
                            sendFieldsAs: 'form',
                            fields: {
                              data: []
                            }
                          }).then(function (resp) {
                            var result = resp.data;
                            if (result.status == 'OK') {
                              var item = result.data[0];
                              var data = angular.copy($scope.ttVentaExento);
                              data.rutaBoleta = item.rutaArchivo;
                              exentoService.actualizarDatosVenta(data).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.ttVentaExento.rutaBoleta = '-';
                                  $scope.mostrarAdjuntar = false;
                                  swal(res.message);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              toastr.error(resp.data.message)
                            }
                          }, function (resp) {
                            toastr.error('Error status: ' + resp.status);
                          }, function (evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.avance = progressPercentage;
                          });
                        }

                        $scope.imprimirSolicitud = function (item) {
                          exentoService.getDatosVenta(item.ventaExentoId).then(function (res) {
                            if (res.status == "OK") {
                              var data = res.data[0];
                              utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.imprimirNotificacion = function (item) {
                          if (item.fechaRecepcion == null) {
                            toastr.error('Esta venta fue agregada en carga inicial, no se puede imprimir la notificación');
                            return;
                          }
                          tareaService.obtenerNotificacionVenta(item.ventaExentoId).then(function (res) {
                            if (res.status == "OK") {
                              var data = res.data[0];
                              switch (data.aprobado) {
                                case 0:
                                  var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                                  var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                                  var strDia = $scope.numeroALetras(dia);
                                  var strAnio = $scope.numeroALetras(anio, false);
                                  dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                  anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                  var strDiaAdmision = $scope.numeroALetras(dia);
                                  var strAnioAdmision = $scope.numeroALetras(anio, false);
                                  utilsService.generarResolucionDenegacionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  break;
                                case 1:
                                  utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  break;
                                case 2:
                                  utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  break;
                              }
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }


                        $scope.imprimirResolucion = function (item) {
                          if (item.estadoId == 0) {
                            toastr.error('Esta solicitud/resolución se encuentra anulada, no se puede imprimir');
                            return;
                          }
                          if (item.fechaRecepcion == null) {
                            toastr.error('La resoluciones agregadas en carga inicial no pueden ser impresas');
                            return;
                          }
                          tareaService.getResolucionVentaExento(item.ttExento.exentoId, item.ventaExentoId).then(function (res) {
                            if (res.status == 1) {
                              var data = res.data[0];
                              var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                              var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                              var strDia = $scope.numeroALetras(dia);
                              var strAnio = $scope.numeroALetras(anio, false);
                              dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                              anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                              var strDiaFinalizacion = $scope.numeroALetras(dia, false);
                              var strAnioFinalizacion = $scope.numeroALetras(anio, false);
                              utilsService.setEsPrevia(true);
                              if (data.observaciones == null) {
                                data.observaciones = '';
                              }
                              try {
                                data.observaciones = JSON.parse(data.observaciones);
                              } catch (error) {
                                data.observaciones = {
                                  resolucionRevalidacion: data.observaciones
                                }
                              }
                              utilsService.generarResolucionVenta(data, strDia, strAnio, strDiaFinalizacion, strAnioFinalizacion, $scope.loginData.usuario, $scope.dataEmpresa);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.agregarSolicitudNotas = function () {
                          if ($scope.dataExento.estadoId == 5) {
                            $state.go('^.venta', { exentoId: $base64.encode($scope.dataExento.exentoId) });
                          } else {
                            toastr.error('No es posible solicitar notas, verifique que el tenga saldo disponible y esté autorizado');
                          }
                        }

                        $scope.listarInformes = function (item) {
                          $state.go('^.listainforme', { ventaExentoId: $base64.encode(item.ventaExentoId) });
                        }

                        $scope.listarNotasElectronicas = function ( ttVentaExento ) {
                          $state.go('^.notaelectronica', { ventaExentoId: $base64.encode(ttVentaExento.ventaExentoId) });
                        }

                      }]
                  })
                }

                $scope.imprimirAdhesion = function () {
                  personaService.get($scope.loginData.personaId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      var fechaRegistro = new Date(data.fechaRegistro);
                      var dia = fechaRegistro.getDate();
                      var anio = fechaRegistro.getFullYear();
                      var mes = fechaRegistro.getMonth() + 1;
                      var strDia = $scope.numeroALetras(dia, false).toLowerCase();
                      var strAnio = $scope.numeroALetras(anio, false).toLowerCase();
                      var strMes = $scope.getMonthName(mes);
                      utilsService.generarFormularioAdhesion(res.data[0], $scope.dataEmpresa, strDia, strMes, strAnio);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirFormato = function (item) {
                  exentoService.get(item.exentoId).then(function (res) {
                    if (res.status == "OK") {
                      if (item.estadoId == 1 || item.estadoId == 2) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.generarSolicitudExento(res.data[0], $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirAutorizacion = function (item) {
                  tareaService.getAutorizacionExento(item.exentoId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      var especies = data.ttExento.especies;
                      for (var i = 0; i < especies.length; i++) {
                        especies[i].saldo = especies[i].ttEspecieSaldoCarga.saldo;
                        especies[i].reserva = especies[i].ttEspecieSaldoCarga.reserva;
                        especies[i].volumen = especies[i].ttEspecieSaldoCarga.volumen;
                      }
                      if (data.observaciones != null) {
                        try {
                          data.observaciones = JSON.parse(data.observaciones);
                        } catch (error) {

                        }
                      }
                      utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.editRow = function (row) {
                  $state.go('^.edit', { exentoId: $base64.encode(row.exentoId) });
                }

                $scope.etiquetaEstado = function (estadoId) {
                  switch (estadoId) {
                    case 0: return "Anulado";
                    case 1: return "Activo";
                    case 2: return "Elaborado";
                    case 3: return "Solicitado";
                    case 4: return "En trámite";
                    case 5: return "Autorizado";
                    case 6: return "Finalizado";
                    default: return "Inactivo";
                  }
                }

                $scope.enviarDocumento = function (item) {
                  if (item.estadoId < 3) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de enviar la solicitud para el exento " + item.codigo + ", a las oficinas de la subregión " + item.tcSubregion.subregionDesc + " - " + item.tcSubregion.alias + " - INAB? \nEsta acción no se podrá reversar",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          exentoService.solicitarCargaInicial(item).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success('La solicitud fue enviada a la subregión ' + item.tcSubregion.subregionDesc + ' - ' + item.tcSubregion.alias + '. Pronto se comunicarán por éste medio sobre el trámite', 'Solicitud enviada con éxito');
                              $scope.submitForm(true);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  }
                }

                $scope.verHistorialEnmiendas = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/exento/historial.enmienda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['exentoService',
                        function (exentoService) {
                          return exentoService.getListaEnmiendas(item.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'tareaService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, tareaService, $timeout) {
                        $scope.dataHistorial = dataHistorial.data;

                        $scope.verDocumentoEnmienda = function (item) {
                          tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                            if (res.status == "OK") {
                              var data = res.data[0];
                              try {
                                data.observaciones = JSON.parse(data.observaciones);
                              } catch (error) {

                              }
                              utilsService.setEsPrevia(true);
                              utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.exento.propio', {
            url: '',
            templateUrl: 'app/exento/exento.list.tpl.html',
            resolve: {
              dataExento: ['exentoService',
                function (exentoService) {
                  return exentoService.list();
                }]
            },
            controller: ['$scope', '$state', 'dataExento',
              function ($scope, $state, dataExento) {

                $scope.mostrarLotes = function (estadoId) {
                  if (estadoId == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.columnDefs = [
                  { field: 'exentoDesc', name: 'exentoDesc', displayName: 'Fuente semillera' },
                  { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarLotes(row.entity.estadoId)" ng-click="grid.appScope.listarLotes(row.entity)" title="Listar cosechas"><i class="fa fa-shopping-basket" aria-hidden="true"></i></button>'
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataExento.data;

                $scope.editRow = function (row) {
                  $state.go('^.edit', { exentoId: $base64.encode(row.exentoId) });
                }

                $scope.listarLotes = function (row) {
                  $scope.dataExento.data = row;
                  $state.go('^.cosecha', { exentoId: $base64.encode(row.exentoId) });
                }

                $scope.etiquetaEstado = function (estadoId) {
                  if (estadoId == 1) {
                    return "Activo";
                  }
                  return "Inactivo";
                }
              }]
          })
          .state('index.exento.agregar', {
            url: '/agregar',
            templateUrl: 'app/exento/exento.add.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataTipoPropietario: ['tipopropietarioService',
                function (tipopropietarioService) {
                  return tipopropietarioService.listaActiva();
                }],
              dataPersona: ['personaService', 'authService',
                function (personaService, authService) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  return personaService.get(loginData.personaId);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado', 'dataTipoPropietario',
              'ngDialog', 'catalogosService', 'dataPersona', 'Upload',
              function ($scope, $state, toastr, exentoService, dataEstado, dataTipoPropietario,
                ngDialog, catalogosService, dataPersona, Upload) {

                $scope.titulo = 'Nueva solicitud de carga inicial';

                $scope.dataPersona = dataPersona.data[0];
                $scope.data = {
                  estadoId: 1,
                  tcTipoPropietario: {},
                  propietarios: [],
                  representantes: []
                };
                $scope.dataEstado = dataEstado.data;
                $scope.dataTipoPropietario = dataTipoPropietario.data;

                $scope.cancelarAgregar = function () {
                  $state.go('^.listar');
                }

                $scope.approved = false;

                $scope.setSolicitarActualizar = function () {
                  if ($scope.approved) {
                    $scope.data.actualizar = 1;
                  } else {
                    $scope.data.actualizar = 0;
                  }
                }

                $scope.submitFormBuscar = function (isValid) {
                  $scope.data.representantes = [];
                  $scope.data.propietarios = [];
                  if (isValid) {
                    catalogosService.getDatosRegistro($scope.data.codigo.trim().toUpperCase()).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var propietarios = data.propietarios;
                        if (propietarios == null) {
                          propietarios = [];
                        }
                        //cambiar a valor falso en produccion
                        var encontrado = false;
                        for (var i = 0; i < propietarios.length; i++) {
                          if (propietarios[i].noCedula != null) {
                            if ($scope.dataPersona.cui == propietarios[i].noCedula.trim()) {
                              encontrado = true;
                              break;
                            }
                          }
                        }
                        // if ($scope.loginData.personaId == 104 || $scope.loginData.personaId == 435) {
                        //   encontrado = true;
                        // }
                        if (!encontrado) {
                          var representantes = data.representantes;
                          if (representantes == null) {
                            representantes = [];
                          }
                          for (var i = 0; i < representantes.length; i++) {
                            if (representantes[i].noCedula != null) {
                              if ($scope.dataPersona.cui == representantes[i].noCedula.trim()) {
                                encontrado = true;
                                break;
                              }
                            }
                          }
                        }
                        if (encontrado) {
                          $scope.dataFinca = data.finca;
                          $scope.data.area = data.areaAprobada;
                          if (data.esJuridico == null) {
                            data.esJuridico = false;
                          }
                          $scope.data.fechaVencimiento = new Date(data.fechaVencimiento.toString().replace('[UTC]', ''));
                          $scope.data.tipoRegistroDesc = data.tipoRegistroDesc;
                          var tipoPropietarioId = 1;
                          if (data.esJuridico) {
                            tipoPropietarioId = 2;
                          }
                          $scope.data.tcTipoPropietario.tipoPropietarioId = tipoPropietarioId;
                          var tcPersona = angular.copy($scope.dataPersona);
                          tcPersona.foto = null;
                          $scope.nombres = [];
                          if (tipoPropietarioId == 1) {
                            $scope.data.propietarios.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                            $scope.nombres.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                          } else {
                            var row = {
                              personaDesc: propietarios[0].nombre,
                              cui: propietarios[0].nit
                            }
                            $scope.nombres.push(
                              {
                                tcPersona: row
                              }
                            );
                            $scope.data.representantes.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                            $scope.mostrarRepresentantes = true;
                          }
                          $scope.data.tcPersonaCrea = tcPersona;
                          swal('Datos correctos', 'Cierre este mensaje y luego pulse guardar para continuar');
                        } else {
                          swal('Datos inconsistentes', 'Usted no figura como propietario o representante legal en el exento, es probable que no tenga actualizado sus datos (DPI y NIT) en el Registro Nacional Forestal. Favor de verificar');
                        }
                        toastr.success(res.message);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  $scope.data.rutaArchivo = null;
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      $scope.data.rutaArchivo = item.rutaArchivo;
                      $scope.archivo = item;
                    } else {
                      toastr.error(resp.data.message);
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.submitFormAgregar = function () {
                  if ($scope.data.propietarios.length > 0 || $scope.data.representantes.length > 0) {
                    exentoService.add($scope.data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $state.go('^.listar');
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    toastr.error('Debe de existir al menos un propietario o representante legal, si no le aparece es probable que el problema esté en el registro');
                  }
                }

              }]
          })
          .state('index.exento.edit', {
            url: '/:exentoId/elaborar',
            templateUrl: 'app/exento/exento.edit.tpl.html',
            resolve: {
              dataExento: ['exentoService', '$stateParams', 'authService', 'toastr', '$state', '$base64',
                function (exentoService, $stateParams, authService, toastr, $state, $base64) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var exento = exentoService.get($base64.decode($stateParams.exentoId));
                  if (exento.status == 'OK') {
                    var item = exento.data[0];
                    if (item.tcPersonaCrea.personaId != loginData.personaId) {
                      exento = {
                        status: 'error',
                        message: 'Solo las persona quien creo el registro puede editar',
                        data: null
                      }
                      toastr.error('Solo las persona quien creo el registro puede editar');
                      $state.go('index.home');
                      return;
                    }
                  }
                  return exento;
                }],
              dataDepartamento: ['departamentoService',
                function (departamentoService) {
                  return departamentoService.listaPorPais(1);
                }],
              dataTipoPropietario: ['tipopropietarioService', '$stateParams',
                function (tipopropietarioService, $stateParams) {
                  return tipopropietarioService.listaActiva();
                }],
              dataLibro: ['libroService',
                function (libroService) {
                  return libroService.listaActiva();
                }],
              dataTipoPropiedad: ['tipopropiedadService',
                function (tipopropiedadService) {
                  return tipopropiedadService.listaActivaExento();
                }],
              dataRnf: ['catalogosService', 'dataExento',
                function (catalogosService, dataExento) {
                  var data = dataExento.data[0];
                  return catalogosService.getDatosRegistro(data.codigo.trim());
                }],
              dataTipoNotificacion: ['tiponotificacionService', '$stateParams',
                function (tiponotificacionService, $stateParams) {
                  return tiponotificacionService.listaActiva();
                }],
              dataTipoEntidad: ['tipoentidadService', '$stateParams',
                function (tipoentidadService, $stateParams) {
                  return tipoentidadService.listaActiva();
                }],
              dataEspecie: ['especieService',
                function (especieService) {
                  return especieService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataExento', 'dataDepartamento', 'dataTipoPropietario', 'municipioService',
              'dataLibro', 'dataTipoPropiedad', 'ngDialog', 'dataEspecie', 'dataRnf', 'dataTipoNotificacion', 'authService', 'utilsService',
              'dataTipoEntidad', 'Upload', 'personaService', '$base64',
              function ($scope, $state, toastr, exentoService, dataExento, dataDepartamento, dataTipoPropietario, municipioService,
                dataLibro, dataTipoPropiedad, ngDialog, dataEspecie, dataRnf, dataTipoNotificacion, authService, utilsService,
                dataTipoEntidad, Upload, personaService, $base64) {
                authService.removeLocalData('dataEspecie');
                authService.saveLocalData('dataEspecie', dataEspecie.data);
                $scope.isEdit = false;
                $scope.isMostrarArchivo = false;
                authService.removeLocalData('fincaRfn');
                $scope.dataDepartamentoFinca = dataDepartamento.data;
                $scope.dataTipoPropiedad = dataTipoPropiedad.data;
                if (dataRnf.status != 'OK') {
                  swal(dataRnf.message);
                  $state.go('index.exento.listar');
                  return;
                }
                $scope.dataRnf = dataRnf.data[0];
                $scope.data = dataExento.data[0];
                $scope.data.boleta = null;
                if ($scope.dataRnf.esJuridico && $scope.data.tcTipoPropietario.tipoPropietarioId == 1) {
                  $scope.data.tcTipoPropietario = {
                    tipoPropietarioId: 2
                  }
                }
                $scope.dataLibro = dataLibro.data;
                $scope.dataDepartamento = dataDepartamento.data;
                $scope.dataTipoPropietario = dataTipoPropietario.data;
                $scope.dataTipoNotificacion = dataTipoNotificacion.data;
                $scope.dataTipoEntidad = dataTipoEntidad.data;
                $scope.requeridoPropietario = false;
                $scope.activaBoton = false;

                if ($scope.data.rutaArchivo != null) {
                  $scope.isMostrarArchivo = true;
                }

                if ($scope.data.ttInformacionExento == null) {
                  $scope.data.ttInformacionExento = {};
                }

                $scope.opcionesPanel = function (paso) {
                  switch (paso) {
                    case 2:
                      $scope.panel = {
                        openPropietarios: true,
                        propietarios: false,
                        openNotificacion: false,
                        notificacion: true,
                        openFinca: false,
                        finca: true
                      }
                      break;
                    case 3:
                      $scope.panel = {
                        openPropietarios: true,
                        propietarios: false,
                        openNotificacion: true,
                        notificacion: false,
                        openFinca: false,
                        finca: true
                      }
                      break;
                    case 4:
                      $scope.panel = {
                        openPropietarios: true,
                        propietarios: false,
                        openNotificacion: true,
                        notificacion: false,
                        openFinca: true,
                        finca: false
                      }
                      break;
                    default:
                      $scope.panel = {
                        openPropietarios: false,
                        propietarios: true,
                        openNotificacion: false,
                        notificacion: true,
                        openFinca: false,
                        finca: true
                      }
                  }
                }

                $scope.opcionesPanel(1);

                $scope.setTipoEntidad = function (item) {
                  $scope.data.ttJuridicaExento.tcTipoEntidad = item;
                }

                if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                  if ($scope.data.ttJuridicaExento == null) {
                    $scope.data.ttJuridicaExento = {}
                    if ($scope.dataRnf.propietarios.length > 0) {
                      var row = $scope.dataRnf.propietarios[0];
                      $scope.data.ttJuridicaExento = {
                        nitEntidad: row.nit,
                        razonSocial: row.nombre,
                        nombreComercial: row.nombre,
                        tcTipoEntidad: $scope.dataTipoEntidad[0]
                      }
                      $scope.setTipoEntidad($scope.dataTipoEntidad[0]);
                      $scope.requeridoPropietario = true;
                    } else {
                      $scope.opcionesPanel(3);
                    }
                  } else {
                    $scope.opcionesPanel(3);
                  }
                }

                $scope.subirBoleta = function () {
                  ngDialog.open({
                    template: 'app/tarea/boleta.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueSubir',
                    name: 'dialogSubir',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'authService',
                      function ($scope, $state, toastr, authService) {

                        $scope.gridData = angular.copy($scope.gridOptionsSelection);
                        $scope.gridData.enableFiltering = false;

                        $scope.submitFormData = function (isValid) {
                          if (isValid) {
                            var data = $scope.gridData.data;
                            //obtener promedios por rodal
                            var rodales = [];
                            var calculado = false;
                            for (var i = 0; i < data.length; i++) {
                              var row = data[i];
                              var rodal = {
                                correlativo: row.RODAL,
                                area: row.AREA_RODAL,
                                especies: [],
                                parcelas: [],
                                areaMuestreada: 0,
                                tamanioParcela: row.TAMANIO_PARCELA == null ? 0 : row.TAMANIO_PARCELA,
                                enLinea: row.EN_LINEA == null ? 0 : row.EN_LINEA
                              }
                              if (parseInt(row.CALCULADO) == 1) {
                                calculado = true;
                              } else {
                                if (calculado) {
                                  toastr.error('No es posible mezclar datos calculados por el sistema y calculados por el usuario');
                                  return;
                                }
                              }
                              var encontrado = false;
                              for (var j = 0; j < rodales.length; j++) {
                                if (rodales[j].correlativo == rodal.correlativo) {
                                  rodal = rodales[j];
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                rodales.push(rodal);
                              }
                              var tcEspecie = {
                                especieId: row.ESPECIE_ID,
                                nombreCientifico: row.NOMBRE_CIENTIFICO
                              };
                              var especie = {
                                tcEspecie: tcEspecie,
                                arbolesAutorizado: 1,
                                dap: parseFloat(row.DAP),
                                altura: parseFloat(row.ALTURA),
                                volumen: parseFloat(row.VOLUMEN),
                                areaBasal: parseFloat(row.AREA_BASAL),
                                tipoInventarioId: 1
                              }
                              encontrado = false;
                              for (var j = 0; j < rodal.especies.length; j++) {
                                if (rodal.especies[j].tcEspecie.especieId == especie.tcEspecie.especieId) {
                                  especie = rodal.especies[j];
                                  especie.arbolesAutorizado += 1;
                                  especie.dap += parseFloat(row.DAP);
                                  especie.altura += parseFloat(row.ALTURA);
                                  especie.volumen += parseFloat(row.VOLUMEN);
                                  especie.areaBasal += parseFloat(row.AREA_BASAL);
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                rodal.especies.push(especie);
                              }
                              if (row.PARCELA != null && row.TAMANIO_PARCELA != null) {
                                especie.tipoInventarioId = 2;
                                var parcela = {
                                  parcela: row.PARCELA,
                                  tamanioParcela: row.TAMANIO_PARCELA
                                }
                                encontrado = false;
                                for (var j = 0; j < rodal.parcelas.length; j++) {
                                  if (rodal.parcelas[j].parcela == parcela.parcela) {
                                    encontrado = true;
                                    break;
                                  }
                                }
                                if (!encontrado) {
                                  rodal.parcelas.push(parcela);
                                }
                              }
                            }
                            for (var i = 0; i < rodales.length; i++) {
                              var rodal = rodales[i];
                              if (rodal.enLinea == 0) {
                                for (var j = 0; j < rodal.especies.length; j++) {
                                  var especie = rodal.especies[j];
                                  var valor = 0;
                                  if (especie.tipoInventarioId == 2) {
                                    rodal.areaMuestreada = (rodal.parcelas.length * parseFloat(rodal.tamanioParcela)) / 10000;
                                    valor = parseFloat(especie.areaBasal) / parseFloat(rodal.areaMuestreada);
                                    valor = valor * parseFloat(rodal.area);
                                    especie.abr = parseFloat(valor).toFixed(4);
                                    valor = parseFloat(especie.volumen) / parseFloat(rodal.areaMuestreada);
                                    valor = valor * parseFloat(rodal.area);
                                    especie.volr = parseFloat(valor).toFixed(4);
                                    valor = parseFloat(especie.arbolesAutorizado) / parseFloat(rodal.areaMuestreada);
                                    valor = valor * parseFloat(rodal.area);
                                    especie.arbolr = Math.ceil(valor);
                                  } else {
                                    especie.abr = parseFloat(especie.areaBasal).toFixed(4);
                                    especie.volr = parseFloat(especie.volumen).toFixed(4);
                                    especie.arbolr = especie.arbolesAutorizado;
                                  }
                                  valor = parseFloat(especie.altura) / especie.arbolesAutorizado;
                                  especie.alturaPromedio = parseFloat(valor).toFixed(2);
                                  valor = parseFloat(especie.dap) / especie.arbolesAutorizado;
                                  especie.dapPromedio = parseFloat(valor).toFixed(2);
                                  especie.volumen = parseFloat(especie.volr).toFixed(4);
                                  if (rodal.area > 0) {
                                    especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                                  } else {
                                    especie.volumenHectaria = 0;
                                  }
                                  especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(4);
                                }
                              } else {
                                for (var j = 0; j < rodal.especies.length; j++) {
                                  var especie = rodal.especies[j];
                                  especie.volumen = parseFloat(especie.volumen).toFixed(4);
                                  especie.arbolr = especie.arbolesAutorizado;
                                  especie.volr = especie.volumen;
                                  especie.volumenHectaria = 0;
                                  especie.areaBasal = parseFloat(especie.areaBasal).toFixed(4);
                                  especie.abr = especie.areaBasal;
                                }
                              }
                            }
                            $scope.data.ttInformacionExento.calculado = calculado;
                            $scope.data.rodales = rodales;
                            $scope.data.boleta = JSON.stringify(data);
                            ngDialog.close();
                          }
                        } //fin submitFormData

                      }]
                  })
                }

                $scope.agregarRodalesActualizacion = function () {
                  if ($scope.data.rodales == null) {
                    $scope.data.rodales = [];
                  }
                  if ($scope.data.rodales.length <= 0) {
                    toastr.error('Debe agregar al menos un rodal y una especie');
                    return;
                  }
                  if ($scope.data.ttInformacionExento.calculado == true) {
                    if ($scope.data.ttInformacionExento.formula == null || $scope.data.ttInformacionExento.formula.length <= 0 
                    || $scope.data.ttInformacionExento.referenciaFormula == null || $scope.data.ttInformacionExento.referenciaFormula.length <= 0) {
                      toastr.error('Debe agregar las fórmulas utilizadas y las referencias bibliográficas de las mismas');
                      return;
                    }
                  }
                  var tmp = angular.copy($scope.data);
                  var ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    rodales: tmp.rodales,
                    boleta: tmp.boleta,
                    ttInformacionExento: tmp.ttInformacionExento
                  };
                  exentoService.agregarRodalesActualizacion(ttExento).then(function (response) {
                    if (response.status == 1) {
                      $scope.opcionesPanel(3);
                      toastr.success(response.message);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setPropietarios = function () {
                  if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                    var data = [
                      {
                        personaId: 1,
                        personaDesc: $scope.data.ttJuridicaExento.razonSocial
                      }
                    ];
                    $scope.dataPersona = data;
                  } else {
                    if ($scope.data.propietarios != undefined) {
                      if ($scope.data.propietarios.length > 0) {
                        var data = [];
                        for (var i = 0; i < $scope.data.propietarios.length; i++) {
                          if ($scope.data.propietarios[i].representanteLegal == 0 || ($scope.data.propietarios[i].representanteLegal == 1 && $scope.data.propietarios[i].soloRepresenta == 0)) {
                            data.push(angular.copy($scope.data.propietarios[i].tcPersona));
                          }
                        }
                        $scope.dataPersona = data;
                      }
                    }
                  }
                }

                $scope.enableEdit = function () {
                  $scope.isEdit = true;
                }

                $scope.approved = false;
                if ($scope.data.actualizar == 1) {
                  $scope.approved = true;
                }

                $scope.setSolicitarActualizar = function () {
                  if ($scope.approved) {
                    $scope.data.actualizar = 1;
                  } else {
                    $scope.data.actualizar = 0;
                  }
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      $scope.data.rutaArchivo = item.rutaArchivo;
                      $scope.archivo = item;
                      $scope.isMostrarArchivo = false;
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.actualizarExento = function () {
                  if ($scope.data.area == null) {
                    toastr.error('Debe indicar el área');
                    return;
                  }
                  var tmp = angular.copy($scope.data);
                  var ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    tcPersonaCrea: {
                      personaId: tmp.tcPersonaCrea.personaId
                    },
                    area: tmp.area,
                    longitud: tmp.longitud,
                    actualizar: tmp.actualizar,
                    rutaArchivo: tmp.rutaArchivo,
                    tcTipoPropietario: tmp.tcTipoPropietario
                  };
                  exentoService.actualizarDatosExento(ttExento).then(function (response) {
                    if (response.status == 'OK') {
                      $scope.isEdit = false;
                      $scope.isMostrarArchivo = true;
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.verArchivoActualizacion = function () {
                  var item = angular.copy($scope.data);
                  exentoService.getArchivoParaActualizacion(item.exentoId);
                }

                $scope.validarPanelEntidad = function (isValid) {
                  if (isValid) {
                    var tmp = $scope.data;
                    var ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    };
                    ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var item = tmp.ttJuridicaExento;
                    item.ttExento = ttExento;
                    item.juridicaExentoId = 0;
                    exentoService.agregarDatosJuridica(item).then(function (response) {
                      if (response.status == 'OK') {
                        $scope.data.ttJuridicaExento = response.data[0];
                        if ($scope.data.ttJuridicaExento.ttExento != null) {
                          delete $scope.data.ttJuridicaExento.ttExento;
                        }
                        toastr.success(response.message);
                        $scope.setPropietarios();
                        $scope.opcionesPanel(3);
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                      toastr.success('Agregue las personas involucradas al exento');
                    }
                  }
                }

                $scope.buscarPersona = function (tipo) {
                  $scope.tipoPersona = tipo;
                  var continuar = true;
                  if (continuar) {
                    $scope.dataTipoBusqueda = [
                      { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                      { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                      { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                    ]
                    $scope.busqueda = {
                      tipoBusquedaId: 3,
                      tituloBusqueda: 'Ingrese el nombre de la persona',
                      placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                    }

                    ngDialog.open({
                      template: 'app/gestion/buscar.persona.tpl.html',
                      className: 'ngdialog-theme-flat',
                      appendClassName: 'ngdialog-flat',
                      closeByDocument: false,
                      closeByEscape: true,
                      closeByNavigation: true,
                      scope: $scope,
                      id: 'uniqueEdit',
                      name: 'dialogEditar',
                      resolve: {
                      },
                      controller: ['$scope', '$state', 'toastr', 'personaService',
                        function ($scope, $state, toastr, personaService) {

                          $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                          $scope.gridPersona.columnDefs = [
                            { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                            { field: 'cui', name: 'cui', displayName: 'Identificación' },
                            { field: 'correo', name: 'correo', displayName: 'Correo' },
                            { field: 'nit', name: 'nit', displayName: 'NIT' },
                            {
                              name: 'Seleccionar', enableFiltering: false,
                              cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                            }
                          ];
                          $scope.gridPersona.data = [];

                          $scope.setTipoBusqueda = function (item) {
                            switch (parseInt(item.tipoBusquedaId)) {
                              case 1:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                                break;
                              case 2:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                                $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                                break;
                              case 3:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                                $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                                break;
                              default:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                            }
                          }

                          $scope.keyupFiltro = function (event) {
                            if (event.keyCode == 13) {
                              $scope.submitBuscarPersona();
                            }
                          }

                          $scope.submitBuscarPersona = function () {
                            var isValid = true;
                            if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                              isValid = null;
                              toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                            }
                            if (isValid) {
                              personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                  $scope.gridPersona.data = response.data;
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }

                          $scope.setPersona = function (item) {
                            var soloRepresenta = 1;
                            if ($scope.tipoPersona == 0) {
                              for (var i = 0; i < $scope.data.propietarios.length; i++) {
                                if ($scope.data.propietarios[i].tcPersona.personaId == item.personaId) {
                                  ngDialog.close();
                                  return;
                                }
                              }
                              soloRepresenta = 0;
                            } else {
                              for (var i = 0; i < $scope.data.representantes.length; i++) {
                                if ($scope.data.representantes[i].tcPersona.personaId == item.personaId) {
                                  ngDialog.close();
                                  return;
                                }
                              }
                              for (var i = 0; i < $scope.data.propietarios.length; i++) {
                                if ($scope.data.propietarios[i].tcPersona.personaId == item.personaId) {
                                  soloRepresenta = 0;
                                  break;
                                }
                              }
                            }
                            delete item.tcUsuario;
                            var data = {
                              personaExentoId: 0,
                              tcPersona: item,
                              ttExento: {
                                exentoId: tmp.exentoId,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: {
                                  personaId: $scope.loginData.personaId
                                }
                              },
                              representanteLegal: $scope.tipoPersona,
                              soloRepresenta: soloRepresenta
                            }
                            exentoService.agregarPersona(data).then(function (response) {
                              if (response.status == 'OK') {
                                registro = response.data[0];
                                persona = registro.tcPersona;
                                persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                var encontrado = false;
                                if ($scope.tipoPersona == 0) {
                                  for (var i = 0; i < $scope.data.propietarios.length; i++) {
                                    if ($scope.data.propietarios[i].tcPersona.personaId == persona.personaId) {
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    $scope.data.propietarios.push(registro);
                                  }
                                  $scope.setPropietarios();
                                } else {
                                  for (var i = 0; i < $scope.data.representantes.length; i++) {
                                    if ($scope.data.representantes[i].tcPersona.personaId == persona.personaId) {
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    $scope.data.representantes.push(registro);
                                  }
                                }
                                toastr.success(response.message);
                                ngDialog.close();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }

                          $scope.setIdioma = function (item) {

                          }

                          $scope.setSexo = function (item) {

                          }

                          $scope.setEstadoCivil = function (item) {

                          }

                          $scope.setCultura = function (item) {

                          }

                        }]
                    })
                  }
                }

                $scope.validarPropietarioFinca = function (personaId) {
                  var hay = false;
                  for (var i = 0; i < $scope.data.fincas.length; i++) {
                    for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                      if ($scope.data.fincas[i].propietarios[j].tcPersona.personaId == personaId) {
                        hay = true;
                        break;
                      }
                    }
                    if (hay) {
                      break;
                    }
                  }
                  return hay;
                }

                $scope.quitarPersonaExento = function (item, tipo) {
                  if (tipo != 1) {
                    if ($scope.validarPropietarioFinca(item.tcPersona.personaId)) {
                      toastr.error('No es posible quitar a la persona debido a que ya se encuentra como propietario de una finca, debe primero quitarlo');
                      return;
                    }
                  }
                  var data = item;
                  var tmp = $scope.data;
                  data.ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    tcPersonaCrea: tmp.tcPersonaCrea
                  };
                  data.representanteLegal = tipo;
                  data.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  exentoService.quitarPersona(data).then(function (response) {
                    if (response.status == 'OK') {
                      var upd = response.data[0];
                      if (tipo == 0) {
                        $scope.data.propietarios.splice($scope.data.propietarios.indexOf(item), 1);
                      } else {
                        $scope.data.representantes.splice($scope.data.representantes.indexOf(item), 1);
                      }
                      if (tipo == 1) { //se esta quitando como representante
                        item.representanteLegal = 0;
                        item.soloRepresenta = 0;
                        var conteo = 0;
                        for (var i = 0; i < $scope.data.propietarios.length; i++) {
                          if ($scope.data.propietarios[i].representanteLegal == 1) {
                            conteo++;
                            break;
                          }
                        }
                      }
                      toastr.success(response.message);
                      $scope.setPropietarios();
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });

                }

                $scope.setLibroFinca = function (item, finca) {
                  finca.tcLibro = item;
                  finca.tcMunicipio = null;
                }

                $scope.setDepartamentoFinca = function (item, finca, limpiar) {
                  if (limpiar == undefined) {
                    limpiar = true;
                  }
                  if (limpiar) {
                    finca.tcMunicipio = null;
                  }
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId != 3) {
                      finca.tcLibro = null;
                      municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                        if (res.status == "OK") {
                          finca.dataMunicipio = res.data;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }
                }

                $scope.setMunicipioEmite = function (item, finca) {
                  finca.tcMunicipioEmite.municipioDesc = item.municipioDesc;
                  finca.tcMunicipioEmite.tcDepartamento = item.tcDepartamento;
                }

                $scope.setPropietarios();

                //cargar municipio de las fincas ya cargadas
                if ($scope.data.fincas != null) {
                  var encontrado = false;
                  for (var i = 0; i < $scope.data.fincas.length; i++) {
                    if ($scope.data.fincas[i].tcMunicipioEmite != null) {
                      $scope.setDepartamentoFinca({ departamentoId: $scope.data.fincas[i].tcMunicipioEmite.tcDepartamento.departamentoId }, $scope.data.fincas[i], false);
                    } else {
                      $scope.data.fincas[i].tcMunicipioEmite = {
                        municipioId: null,
                        tcDepartamento: {
                          departamentoId: null
                        }
                      }
                    }
                    var documentos = $scope.data.fincas[i].documentos;
                    if (documentos == null) {
                      documentos = [];
                    }
                    for (var j = 0; j < documentos.length; j++) {
                      doc = documentos[j];
                      if (doc.tcMunicipioEmite != null) {
                        $scope.setDepartamentoFinca({ departamentoId: doc.tcMunicipioEmite.tcDepartamento.departamentoId }, doc, false);
                      } else {
                        doc.tcMunicipioEmite = {
                          municipioId: null,
                          tcDepartamento: {
                            departamentoId: null
                          }
                        }
                      }
                      if (doc.fechaEmision == null) {
                        doc.fechaEmision = new Date();
                      } else {
                        doc.fechaEmision = new Date(doc.fechaEmision);
                      }
                    }
                    if ($scope.data.fincas[i].propietarios == null) {
                      $scope.data.fincas[i].propietarios = [];
                    }
                    $scope.data.fincas[i].personas = [];
                    for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                      var tcPersona = $scope.data.fincas[i].propietarios[j].tcPersona;
                      $scope.data.fincas[i].personas.push(tcPersona);
                    }
                    if ($scope.data.fincas[i].fechaEmision != null) {
                      $scope.data.fincas[i].fechaEmision = new Date($scope.data.fincas[i].fechaEmision);
                    }
                  }
                  if ($scope.data.fincas.length > 0) {
                    $scope.opcionesPanel(4);
                  } else {
                    if ($scope.data.notificaciones != null) {
                      if ($scope.data.notificaciones.length > 0) {
                        if ($scope.data.notificaciones[0].notificacionExentoDesc == null) {
                          if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                            $scope.opcionesPanel(1);
                          } else {
                            $scope.opcionesPanel(3);
                          }
                        } else {
                          $scope.opcionesPanel(3);
                        }
                      } else {
                        if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                          $scope.opcionesPanel(1);
                        } else {
                          $scope.opcionesPanel(3);
                        }
                      }
                    } else {
                      if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                        $scope.opcionesPanel(1);
                      } else {
                        $scope.opcionesPanel(3);
                      }
                    }
                  }
                }

                $scope.agregarPersona = function (tipo) {
                  $scope.tipoPersona = tipo;
                  $scope.tituloPersona = "Agregar datos de la persona";
                  delete $scope.persona;
                  ngDialog.open({
                    template: 'app/persona/persona.add.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataIdioma: ['idiomaService', '$stateParams',
                        function (idiomaService, $stateParams) {
                          return idiomaService.listaActiva();
                        }],
                      dataCultura: ['culturaService', '$stateParams',
                        function (culturaService, $stateParams) {
                          return culturaService.listaActiva();
                        }],
                      dataEstadoCivil: ['estadocivilService', '$stateParams',
                        function (estadocivilService, $stateParams) {
                          return estadocivilService.listaActiva();
                        }],
                      dataTipoDocumento: ['tipodocumentoService', '$stateParams',
                        function (tipodocumentoService, $stateParams) {
                          return tipodocumentoService.listaActiva();
                        }],
                      dataOcupacion: ['ocupacionService',
                        function (ocupacionService) {
                          return ocupacionService.listaActiva();
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload',
                      'dataIdioma', 'dataCultura', 'dataEstadoCivil', 'dataTipoDocumento', 'dataOcupacion',
                      function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload,
                        dataIdioma, dataCultura, dataEstadoCivil, dataTipoDocumento, dataOcupacion) {
                        $scope.dataOcupacion = dataOcupacion.data;
                        $scope.dataIdioma = dataIdioma.data;
                        $scope.dataCultura = dataCultura.data;
                        $scope.dataEstadoCivil = dataEstadoCivil.data;
                        $scope.dataTipoDocumento = dataTipoDocumento.data;
                        $scope.dataSexo = [
                          { sexoId: 1, sexoDesc: 'Femenino' },
                          { sexoId: 2, sexoDesc: 'Masculino' }
                        ];

                        $scope.persona = {
                          estadoId: 1
                        }

                        $scope.direccion = {
                          paisId: 1
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.persona.municipioId = null;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.persona.municipioId = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.onFileSelect = function (file, tipoAnexoId) {
                          Upload.upload({
                            url: appSettings.urlServiceBase + 'file/base64/imagen',
                            method: 'POST',
                            file: file,
                            sendFieldsAs: 'form',
                            fields: {
                              data: []
                            }
                          }).then(function (resp) {
                            var data = resp.data;
                            if (data.status == 'OK') {
                              var obj = data.data[0];
                              $scope.image = obj.data;
                              $scope.persona.foto = obj.data;
                            } else {
                              toastr.error(resp.data.message)
                            }
                          }, function (resp) {
                            toastr.error('Error status: ' + resp.status);
                          }, function (evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.avance = progressPercentage;
                          });
                        }

                        $scope.submitFormPersona = function (isValid) {
                          if (isValid) {
                            if ($scope.persona.foto == null) {
                              /*isValid = false;
                              toastr.error('Debe indicar una foto');*/
                              $scope.validoFoto = 1;
                            }
                            if ($scope.validoFoto == 0 && isValid) {
                              isValid = false;
                              toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                            }
                          }
                          if (isValid) {
                            var item = $scope.persona;
                            personaService.add(item).then(function (res) {
                              if (res.status == 'OK') {
                                item = res.data[0];
                                var soloRepresenta = 1;
                                if ($scope.tipoPersona == 0) {
                                  soloRepresenta = 0;
                                } else {
                                  for (var i = 0; i < $scope.data.propietarios.length; i++) {
                                    if ($scope.data.propietarios[i].tcPersona.personaId == item.personaId) {
                                      soloRepresenta = 0;
                                      break;
                                    }
                                  }
                                }
                                var tmp = $scope.data;
                                var data = {
                                  personaExentoId: 0,
                                  tcPersona: item,
                                  ttExento: {
                                    exentoId: tmp.exentoId,
                                    tcTipoExento: tmp.tcTipoExento,
                                    estadoId: tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcTipoBosque: tmp.tcTipoBosque,
                                    tcElaborador: tmp.tcElaborador,
                                    ttTipoPropietarioExento: tmp.ttTipoPropietarioExento,
                                    personaAnulaId: $scope.loginData.personaId
                                  },
                                  representanteLegal: $scope.tipoPersona,
                                  soloRepresenta: soloRepresenta
                                }
                                exentoService.agregarPersona(data).then(function (response) {
                                  if (response.status == 'OK') {
                                    registro = response.data[0];
                                    persona = registro.tcPersona;
                                    registro.tcPersona = item;
                                    if (parseInt($scope.tipoPersona) == 1) {
                                      $scope.data.representantes.push(registro);
                                    } else {
                                      item.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                      $scope.data.propietarios.push(registro);
                                      $scope.setPropietarios();
                                    }
                                    toastr.success(res.message);
                                  } else {
                                    toastr.error(response.message);
                                  }
                                  ngDialog.close();
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                        $scope.setIdioma = function (item) {
                          $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                        }

                        $scope.setSexo = function (item) {
                          $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                        }

                        $scope.setEstadoCivil = function (item) {
                          $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                        }

                        $scope.setCultura = function (item) {
                          $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                        }

                      }]
                  })
                }

                $scope.cargarListaMunicipio = function (item, notificacion, limpiar) {
                  if (limpiar == null) {
                    notificacion.tcMunicipio = null;
                  }
                  municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                    if (res.status == "OK") {
                      notificacion.dataMunicipio = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setMunicipio = function (item, notificacion) {
                  notificacion.tcMunicipio = item;
                }

                var obligatorio = [];
                for (var i = 0; i < $scope.dataTipoNotificacion.length; i++) {
                  if ($scope.dataTipoNotificacion[i].esObligatorio == 1) {
                    obligatorio.push($scope.dataTipoNotificacion[i]);
                  }
                }
                var tmp = $scope.data;
                if ($scope.data.notificaciones != null) {
                  var abrirFinca = false;
                  for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                    notificacion = $scope.data.notificaciones[j];
                    if (notificacion.tcTipoNotificacion.esDireccion == 1) {
                      if (notificacion.tcMunicipio != null) {
                        notificacion.departamentoId = notificacion.tcMunicipio.tcDepartamento.departamentoId;
                        $scope.cargarListaMunicipio({ departamentoId: notificacion.departamentoId }, notificacion, true);
                        abrirFinca = true;
                      }
                    }
                  }
                  if (abrirFinca) {
                    $scope.opcionesPanel(4);
                  }
                  var encontrado = false;
                  for (var i = 0; i < obligatorio.length; i++) {
                    encontrado = false;
                    for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                      if ($scope.data.notificaciones[j].ttExento == null) {
                        $scope.data.notificaciones[j].ttExento = {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        }
                      }
                      if ($scope.data.notificaciones[j].tcTipoNotificacion.tipoNotificacionId == obligatorio[i].tipoNotificacionId) {
                        encontrado = true;
                        break;
                      }
                    }
                    if (!encontrado) {
                      var item = {
                        ttExento: {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        },
                        tcTipoNotificacion: obligatorio[i],
                        notificacionExentoDesc: null,
                        estadoId: 1
                      }
                      $scope.data.notificaciones.push(item);
                    }
                  }
                } else {
                  $scope.data.notificaciones = [];
                  for (var i = 0; i < obligatorio.length; i++) {
                    if (obligatorio[i].esObligatorio == 1) {
                      var item = {
                        ttExento: {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        },
                        tcTipoNotificacion: obligatorio[i],
                        notificacionExentoDesc: null,
                        estadoId: 1
                      }
                      $scope.data.notificaciones.push(item);
                    }
                  }
                }

                /*Panel notificacion*/
                $scope.agregarNotificacion = function () {
                  ngDialog.open({
                    template: 'app/exento/exento.notificacion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        $scope.direccion = {
                          paisId: 1
                        }

                        var tmp = $scope.data;
                        $scope.notificacion = {
                          ttExento: {
                            exentoId: tmp.exentoId,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea
                          },
                          tcTipoNotificacion: null
                        }

                        $scope.notificacion.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.notificacion.municipioId = null;
                          $scope.notificacion.paisDesc = item.paisDesc;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.notificacion.municipioId = null;
                          $scope.notificacion.departamentoDesc = item.departamentoDesc;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.setMunicipio = function (item) {
                          $scope.notificacion.tcMunicipio = item;
                        }

                        $scope.setTipoNotificacion = function (item) {
                          $scope.notificacion.tcTipoNotificacion.tipoNotificacionDesc = item.tipoNotificacionDesc;
                        }

                        $scope.submitFormNotificacionAdicional = function (isValid) {
                          if (isValid) {
                            if ($scope.data.notificaciones == undefined) {
                              $scope.data.notificaciones = [];
                            }
                            var lista = $scope.data.notificaciones;
                            var enviar = true;
                            for (var i = 0; i < lista.length; i++) {
                              if (lista[i].notificacionExentoId == null) {
                                enviar = false;
                                break;
                              }
                              if (lista[i].notificacionExentoId < 1) {
                                enviar = false;
                                break;
                              }
                            }
                            var data = $scope.notificacion;
                            if (enviar) {
                              exentoService.agregarNotificacion(data).then(function (response) {
                                if (response.status == 'OK') {
                                  var row = angular.copy($scope.notificacion);
                                  $scope.data.notificaciones.push(row);
                                  toastr.success(response.message);
                                  ngDialog.close();
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              var row = angular.copy($scope.notificacion);
                              $scope.data.notificaciones.push(row);
                              ngDialog.close();
                            }
                          }
                        }

                      }]
                  })
                }

                $scope.capturarInformacionNotificacion = function (notificacion) {

                }

                $scope.agregarMunicipioNotificacion = function (notificacion) {
                  notificacion.tcMunicipio = {
                    municipioId: null
                  }
                  $scope.notificacion = notificacion;
                  ngDialog.open({
                    template: 'app/gestion/seleccionar.municipio.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueMunicipio',
                    name: 'dialogMunicipio',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        $scope.direccion = {
                          paisId: 1
                        }

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.notificacion.tcMunicipio.municipioId = null;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.submitFormNotificacionMunicipio = function (isValid) {
                          if (isValid) {
                            for (var i = 0; i < $scope.data.notificaciones.length; i++) {
                              if ($scope.data.notificaciones[i].notificacionGestionId == $scope.notificacion.notificacionGestionId) {
                                $scope.data.notificaciones[i] = $scope.notificacion;
                                break;
                              }
                            }
                            ngDialog.close();
                          }
                        }

                      }]
                  })
                }

                $scope.mostrarSeleccionarMunicipio = function (value) {
                  if (value == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.validarPanelNotificacion = function (isValid) {
                  if (isValid) {
                    for (var j = 0; j < $scope.data.notificaciones.length; j++) {
                      if ($scope.data.notificaciones[j].tcTipoNotificacion.esObligatorio == 1) {
                        if ($scope.data.notificaciones[j].tcTipoNotificacion.esDireccion == 1 && $scope.data.notificaciones[j].tcMunicipio == null) {
                          isValid = false;
                          encontrado = false; //para que finalice la verificacion y muestre el mensaje de error
                        }
                        break;
                      }
                    }
                    if (!isValid) {
                      toastr.error("Se debe indicar todas las formas de notificación y seleccionar el municipio a las direcciones");
                    } else {
                      //enviar datos para actualizar o guardar
                      exentoService.actualizarDatosNotificacion($scope.data.notificaciones).then(function (response) {
                        if (response.status == 'OK') {
                          $scope.opcionesPanel(4);
                          toastr.success("Continue seleccionando o agregando la finca");
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    toastr.error("Debe rellenar todos los campos y verificar que el formato (teléfono, correo, etc.) sea el correcto");
                  }
                }

                $scope.borrarDetalleNotificacion = function (item) {
                  var notificaciones = $scope.data.notificaciones;
                  var continuar = true;
                  if (item.tcTipoNotificacion.esObligatorio == 1) {
                    var conteo = 0;
                    for (var i = 0; i < notificaciones.length; i++) {
                      if (notificaciones[i].tcTipoNotificacion.tipoNotificacionId == item.tcTipoNotificacion.tipoNotificacionId) {
                        conteo++;
                      }
                    }
                    if (conteo < 2) {
                      continuar = false;
                      toastr.error('Este tipo de notificación es obligatorio, no es posible continuar');
                    }
                  }
                  if (continuar) {
                    var tmp = $scope.data;
                    item.ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    };
                    item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    if (item.notificacionExentoId == null) {
                      item.notificacionExentoId = 0;
                    }
                    if (item.notificacionExentoId > 0) {
                      exentoService.quitarNotificacion(item).then(function (response) {
                        if (response.status == 'OK') {
                          $scope.data.notificaciones.splice($scope.data.notificaciones.indexOf(item), 1);
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    } else {
                      $scope.data.notificaciones.splice($scope.data.notificaciones.indexOf(item), 1);
                    }
                  }
                }
                /*Fin panel notificacion*/

                /* Panel finca*/

                if ($scope.globales.fincaId != null) {
                  var tmp = $scope.data;
                  var item = {
                    ttExento: {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    },
                    tcFinca: {
                      fincaId: $scope.globales.fincaId
                    },
                    fechaEmision: null
                  }
                  item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  exentoService.agregarFincaExento(item).then(function (response) {
                    if (response.status == 'OK') {
                      $scope.globales.fincaId = null;
                      item = response.data[0];
                      item.ttExento = {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        fechaEmision: null
                      };
                      item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                      $scope.data.fincas.push(item);
                      $scope.opcionesPanel(4);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.onSelectPersona = function (item, finca) {
                  if (finca.propietarios == undefined || finca.propietarios == null) {
                    finca.propietarios = [];
                  }
                  if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                    if (finca.propietarios.length == 0) {
                      finca.propietarios.push(
                        {
                          propietarioFincaId: 1,
                          tcPersona: {
                            personaId: 1,
                            personaDesc: $scope.data.ttJuridicaExento.razonSocial
                          }
                        }
                      );
                    }
                    toastr.success('Propietario agregado');
                    return;
                  } else {
                    for (var i = 0; i < finca.propietarios.length; i++) {
                      if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                        toastr.success('Propietario agregado');
                        return;
                      }
                    }
                    finca.ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    }
                    finca.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var data = {
                      ttFincaExento: finca,
                      tcPersona: item
                    }
                    exentoService.agregarPropietarioFinca(data).then(function (response) {
                      if (response.status == 'OK') {
                        finca.propietarios.push(response.data[0]);
                      } else {
                        toastr.error(response.message);
                        finca.persona = finca.propietario;
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.onRemovePersona = function (item, finca) {
                  if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                    finca.propietarios.splice(finca.propietarios.indexOf(item), 1);
                  } else {
                    var tmp = $scope.data;
                    finca.ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    };
                    finca.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var data = null;
                    for (var i = 0; i < finca.propietarios.length; i++) {
                      if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                        data = angular.copy(finca.propietarios[i]);
                        break;
                      }
                    }
                    data.ttFincaExento = angular.copy(finca);
                    var tmpPersonas = data.personas;
                    delete data.personas;
                    delete data.propietarios;
                    exentoService.quitarPropietarioFinca(data).then(function (response) {
                      if (response.status == 'OK') {
                        finca.propietarios.splice(finca.propietarios.indexOf(data), 1);
                      } else {
                        toastr.error(response.message);
                        finca.personas = tmpPersonas;
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.agregarDocumentoFinca = function (finca) {
                  if (finca.documentos == null) {
                    finca.documentos = [];
                  }
                  finca.documentos.push({ documentoFincaId: 0 });
                }

                $scope.quitarDocumentoFinca = function (finca, item) {
                  if (item.documentoFincaId > 0) {
                    gestionService.quitarDocumentoFinca(item).then(function (res) {
                      if (res.status == "OK") {
                        finca.documentos.splice(finca.documentos.indexOf(item), 1);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    finca.documentos.splice(finca.documentos.indexOf(item), 1);
                  }
                }

                $scope.buscarFinca = function () {
                  ngDialog.open({
                    template: 'app/finca/finca.list.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'fincaService',
                      function ($scope, $state, toastr, fincaService) {
                        $scope.gridOptionsFinca = angular.copy($scope.gridOptionsSelection);
                        $scope.gridOptionsFinca.columnDefs = [
                          { field: 'fincaDesc', name: 'fincaDesc', displayName: 'Finca' },
                          { field: 'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '17%' },
                          { field: 'tcMunicipio.tcDepartamento.departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '12%' },
                          { field: 'direccion', name: 'direccion', displayName: 'Aldea/caserío/cantón' },
                          { field: 'gtmX', name: 'gtmX', displayName: 'GTM X', width: '8%' },
                          { field: 'gtmY', name: 'gtmY', displayName: 'GTM Y', width: '8%' },
                          { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                          {
                            name: 'Opciones', enableFiltering: false, width: '8%',
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.selectRowFinca(row.entity)" title="Seleccionar"> <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </button></span></div>'
                          }
                        ];
                        $scope.gridOptionsFinca.data = $scope.dataFinca;

                        $scope.direccion = {
                          departamentoId: null,
                          municipioId: null
                        }

                        $scope.cargarListaMunicipioFinca = function (item) {
                          $scope.direccion.municipioId = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipioFinca = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.setMunicipioFinca = function (item) {
                          if (item) {
                            fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                              if (res.status == "OK") {
                                $scope.gridOptionsFinca.data = res.data;
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  })
                }

                $scope.selectRowFinca = function (row) {
                  var encontrado = false;
                  for (var i = 0; i < $scope.data.fincas.length; i++) {
                    if ($scope.data.fincas[i].tcFinca.fincaId == row.fincaId) {
                      encontrado = true;
                      break;
                    }
                    if ($scope.data.fincas[i].tcFinca.tcMunicipio.municipioId != row.tcMunicipio.municipioId) {
                      toastr.error('La finca seleccionada pertenece a otro municipio, no es posible continuar');
                      return;
                    }
                  }
                  if (!encontrado) {
                    var item = {
                      ttExento: {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea
                      },
                      tcFinca: row
                    };
                    item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    exentoService.agregarFincaExento(item).then(function (response) {
                      if (response.status == 'OK') {
                        item = response.data[0];
                        item.ttExento = {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          fechaEmision: null
                        };
                        $scope.data.fincas.push(item);
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                  ngDialog.close();
                }

                $scope.activarBoton = function () {
                  $scope.activaBoton = true;
                }

                $scope.validarPanelFinca = function (isValid) {
                  if ($scope.data.fincas.length <= 0) {
                    toastr.error('Debe agregar al menos una finca para continuar');
                    return;
                  }
                  if (!isValid) {
                    toastr.error('Debe indicar todos los campos obligatorios, favor de verificar');
                  } else {
                    var dataFinca = angular.copy($scope.data.fincas);
                    var minimum = new Date();
                    if ($scope.dataSolicitud != undefined) {
                      minimum = new Date($scope.dataSolicitud.fechaRecepcion);
                    }
                    var tmp = $scope.data;
                    minimum.setDate(minimum.getDate() - 121);
                    for (var i = 0; i < dataFinca.length; i++) {
                      if (dataFinca[i].propietarios.length <= 0) {
                        toastr.error('Debe indicar al menos un propietario a la finca ' + dataFinca[i].tcFinca.fincaDesc);
                        return;
                      }
                      dataFinca[i].ttExento = {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea
                      }
                      dataFinca[i].ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                      if (dataFinca[i].tcTipoPropiedad.tipoPropiedadId == 3) {
                        dataFinca[i].tcMunicipioEmite = null;
                        if (dataFinca[i].numeroDocumento.toString().includes(',')) {
                          swal('El número de la finca ' + dataFinca[i].tcFinca.fincaDesc + ' no es correcto, si desea agregar más de una finca use el botón naranja del lado izquierdo');
                          return;
                        }
                      } else {
                        dataFinca[i].tcLibro = null;
                      }
                      delete dataFinca[i].dataMunicipio;
                      delete dataFinca[i].dataLibro;
                      delete dataFinca[i].personas;
                      delete dataFinca[i].propietarios;
                    }
                    exentoService.actualizarFincas(dataFinca).then(function (response) {
                      if (response.status == 'OK') {
                        $scope.data.tcSubregion = response.data[0];
                        toastr.success(response.message);
                        $scope.activaBoton = true;
                      } else {
                        if (response.status == 'warning') {
                          $scope.dataSubregion = response.data;
                          //significa que debe indicar la subregion debido a que el municipio es atendido por varios
                          ngDialog.open({
                            template: 'app/gestion/finca.region.tpl.html',
                            className: 'ngdialog-theme-flat',
                            appendClassName: 'ngdialog-flat',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueRegion',
                            name: 'dialogRegion',
                            resolve: {

                            },
                            controller: ['$scope', '$state', 'toastr', 'exentoService',
                              function ($scope, $state, toastr, exentoService) {

                                $scope.seleccionarSubregion = function (item) {
                                  var tmp = $scope.data;
                                  var ttExento = {
                                    exentoId: tmp.exentoId,
                                    estadoId: tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcSubregion: item
                                  }
                                  ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                                  exentoService.setSubregion(ttExento).then(function (response) {
                                    if (response.status == 'OK') {
                                      ngDialog.close();
                                      $scope.data.tcSubregion = item;
                                      toastr.success(response.message);
                                      $scope.activarBoton();
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }

                              }]
                          })
                        } else {
                          toastr.error(response.message);
                        }
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.limpiarDatosFinca = function (finca) {
                  finca.tcTipoPropiedad = {
                    tipoPropiedadId: null
                  };
                  finca.mostrarNumero = false;
                  finca.mostrarNotario = false;
                }

                $scope.setTipoPropiedadFinca = function (item, finca) {
                  if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 3) {
                    finca.mostrarNumero = true;
                  } else {
                    finca.mostrarNumero = false;
                  }
                  if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 2) {
                    finca.mostrarNotario = true;
                  } else {
                    finca.mostrarNotario = false;
                  }
                  finca.tcTipoPropiedad.tipoPropiedadDesc = item.tipoPropiedadDesc;
                }

                $scope.getMostrarNumero = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getNumeroRequerido = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getMunicipioRequerido = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getMostrarNotario = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                      return true;
                    }
                  }
                  finca.libro = 0;
                  return false;
                }

                $scope.getTituloDocumento = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1) {
                      return "Número de escritura";
                    }
                  }
                  return "No. de finca";
                }

                $scope.agregarRegistroFinca = function () {
                  authService.saveLocalData('fincaRfn', $scope.dataRnf.finca);
                  $scope.guardarFinca = false;
                  $scope.setStateToGo("index.exento.edit", $base64.encode($scope.data.exentoId), true);
                  $state.go('index.finca.add');
                }

                $scope.quitarFinca = function (item) {
                  //verificar si aun no se ha hecho una distribucion del uso actual de la finca
                  if ($scope.data.uso != undefined) {
                    if ($scope.data.uso != null) {
                      if ($scope.data.uso.length > 0) {
                        toastr.error('No es posible eliminar la finca debido a que ya se encuentra distribuido en algún uso actual. Debe eliminar los usos actuales en la pestaña "Características de la finca"');
                        return;
                      }
                    }
                  }
                  item.ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    tcPersonaCrea: tmp.tcPersonaCrea
                  }
                  item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  exentoService.quitarFincaExento(item).then(function (res) {
                    if (res.status == "OK") {
                      $scope.data.fincas.splice($scope.data.fincas.indexOf(item), 1);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }
                /*Fin panel finca*/

                $scope.finalizarSolicitud = function () {
                  if ($scope.data.fincas.length <= 0) {
                    toastr.error('Debe al menos agregar una finca');
                    return;
                  }
                  swal({
                    title: '¿Está seguro de finalizar la elaboración de la solicitud de carga inicial del número de registro ' + $scope.data.codigo
                      + ' y proceder al envío a las oficinas del INAB?',
                    text: "Asegúrese que: \n1. Los datos presentados sean correctos \n2. Los datos coincidan con la certificación del RNF \nEl no hacerlo le ocasionará inconvenientes",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var tmp = $scope.data;
                        var ttExento = {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        }
                        ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                        exentoService.finalizarElaboracion(ttExento).then(function (response) {
                          if (response.status == 'OK') {
                            toastr.success(response.message);
                            $state.go("index.exento.listar");
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.imprimirFormato = function () {
                  if ($scope.data.fincas.length <= 0) {
                    toastr.error('Debe al menos agregar una finca');
                    return;
                  }
                  if ($scope.data.estadoId == 1) {
                    utilsService.setEsPrevia(true);
                  }
                  utilsService.generarSolicitudExento($scope.data, $scope.dataEmpresa);
                }

                $scope.mostrarGuardarMandatarios = false;

                $scope.setMandatario = function (item) {
                  $scope.mostrarGuardarMandatarios = true;
                  toastr.warning('Use el botón correspondiente de esta sección para guardar los cambios de lo contrario puede ocasionarle futuros problemas');
                }

                var representantes = $scope.data.representantes;
                for (var i = 0; i < representantes.length; i++) {
                  var element = representantes[i];
                  if (element.mandatoInicio != null) {
                    var partes = element.mandatoInicio.toString().split('-');
                    element.mandatoInicio = new Date(partes[0], parseInt(partes[1]) - 1, partes[2]);
                  }
                  if (element.mandatoFin != null) {
                    var partes = element.mandatoFin.toString().split('-');
                    element.mandatoFin = new Date(partes[0], parseInt(partes[1]) - 1, partes[2]);
                  }
                }

                $scope.validarPanelSolicitante = function (isValid) {
                  if (isValid) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar y puede ocasionarle problemas si la información proporcionada no es correcta",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          exentoService.setMandatario($scope.data.representantes).then(function (response) {
                            if (response.status == 1) {
                              toastr.success(response.message);
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    toastr.error('Todos los campos de inicio de mandato son obligatorios');
                  }
                }

              }]
          })
          .state('index.exento.venta', {
            url: '/:exentoId/venta',
            templateUrl: 'app/exento/venta.add.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataExento: ['exentoService', 'authService', '$stateParams', '$base64',
                function (exentoService, authService, $stateParams, $base64) {
                  return exentoService.datosParaVenta($base64.decode($stateParams.exentoId));
                }],
              dataTipoTransporte: ['tipotransporteService',
                function (tipotransporteService) {
                  return tipotransporteService.listaActiva();
                }],
              dataTratamientoSilvicultural: ['tratamientosilviculturalService',
                function (tratamientosilviculturalService) {
                  return tratamientosilviculturalService.listaActivaExento();
                }],
              dataRevalidacion: ['tareaService', '$stateParams', '$base64',
                function (tareaService, $stateParams, $base64) {
                  return tareaService.getRavalidacionNotas($base64.decode($stateParams.exentoId));
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado', 'dataTipoTransporte',
              'ngDialog', 'dataRevalidacion', 'dataExento', 'dataTratamientoSilvicultural', 'utilsService',
              function ($scope, $state, toastr, exentoService, dataEstado, dataTipoTransporte,
                ngDialog, dataRevalidacion, dataExento, dataTratamientoSilvicultural, utilsService) {

                if (dataExento.status != 'OK') {
                  swal(dataExento.message);
                  $state.go('index.exento.listar');
                  return;
                }
                $scope.tituloComplemento = '(Revalidación)';
                $scope.complemento = '';
                $scope.esRevalidacion = false;
                if (dataExento.singleValue != null) {
                  if (parseInt(dataExento.singleValue) == 1) {
                    $scope.tituloComplemento = "(Revalidación)";
                    $scope.complemento = '(Indique el volumen de la resolución vencida)';
                    $scope.esRevalidacion = true;
                  }
                }

                $scope.dataFin = [
                  { finComercial: 0, finComercialDesc: 'Autoconsumo' },
                  { finComercial: 1, finComercialDesc: 'Comerciales' }
                ];

                $scope.dataExento = dataExento.data[0];
                $scope.dataTipoTransporte = dataTipoTransporte.data;
                $scope.dataTratamientoSilvicultural = dataTratamientoSilvicultural.data;
                $scope.dataEstado = dataEstado.data;
                var ventas = $scope.dataExento.ventas;
                if (ventas == null) {
                  ventas = [];
                }
                var esPrimeraVenta = 1;
                if (ventas.length > 0) {
                  esPrimeraVenta = 0;
                }

                $scope.dataEspecie = $scope.dataExento.especies;
                $scope.venta = {
                  rodales: [],
                  transporte: [],
                  tratamiento: [],
                  cantidadNotas: 0,
                  rangosRevalidar: [],
                  esPrimeraVenta: esPrimeraVenta
                };

                var tmpRangos = dataRevalidacion.data;
                if (tmpRangos != null) {
                  rangosRevalidar = [];
                  var resolucion = null;
                  for (let i = 0; i < tmpRangos.length; i++) {
                    let tmpRango = tmpRangos[i];
                    rangosRevalidar.push(
                      {
                        inicio: tmpRango.inicio,
                        fin: tmpRango.fin,
                        cantidad: tmpRango.cantidad
                      }
                    );
                    resolucion = tmpRango.resolucion;
                  }
                  $scope.venta.observaciones = resolucion;
                  $scope.venta.rangosRevalidar = rangosRevalidar;
                }

                $scope.dataRodales = [];
                for (var i = 0; i < $scope.dataExento.rodales.length; i++) {
                  var rodal = $scope.dataExento.rodales[i];
                  $scope.dataRodales.push(rodal);
                }

                $scope.agregarRodal = function () {
                  if ($scope.venta.rodales.length >= $scope.dataRodales.length) {
                    toastr.error('No es posible agregar más rodales del que tiene registrado');
                    return;
                  }
                  $scope.venta.rodales.push(
                    {
                      especies: []
                    }
                  );
                }

                $scope.quitarRodal = function (rodal) {
                  $scope.venta.rodales.splice($scope.venta.rodales.indexOf(rodal), 1);
                }

                $scope.agregarEspecieRodal = function (rodal) {
                  if (rodal.correlativo == null) {
                    toastr.error('Seleccione primero el rodal');
                    return;
                  }
                  rodal.especies.push(
                    {
                      tcEspecie: null,
                      arbolesSolicitado: 0,
                      trozaSolitada: 0,
                      leniaSolicitada: 0,
                      totalSolicitado: 0,
                      saldo: 0
                    }
                  );
                }

                $scope.setCorrelativoRodal = function (item, rodal) {
                  var rodales = $scope.venta.rodales;
                  var conteo = 0;
                  for (var i = 0; i < rodales.length; i++) {
                    if (rodales[i].correlativo == item.correlativo) {
                      conteo++;
                    }
                  }
                  if (conteo > 1) {
                    toastr.error('Este correlativo ya fue seleccionado, favor de revisar');
                    rodal.correlativo = null;
                    return;
                  }
                  rodal.area = item.area;
                  rodal.rodalExentoId = item.rodalExentoId;
                  rodal.areaIntervenir = item.area;
                  var especies = item.especies;
                  var dataEspecie = [];
                  for (var j = 0; j < especies.length; j++) {
                    var tcEspecie = angular.copy(especies[j].tcEspecie);
                    tcEspecie.saldo = especies[j].volumen;
                    dataEspecie.push(tcEspecie);
                  }
                  rodal.dataEspecie = dataEspecie;
                }

                $scope.quitarEspecieRodal = function (rodal, especie) {
                  rodal.especies.splice(rodal.especies.indexOf(especie), 1);
                }

                $scope.agregarTransporte = function () {
                  $scope.venta.transporte.push(
                    {
                      tcTipoTransporte: null
                    }
                  );
                }

                $scope.setCantidadNotas = function () {
                  let cantidad = $scope.venta.cantidadNotas;
                  if (cantidad != null) {
                    if (parseInt(cantidad) > 0) {
                      $scope.tituloComplemento = '';
                    } else {
                      $scope.tituloComplemento = '(Revalidación)';
                    }
                  }
                }

                $scope.quitarTransporte = function (transporte) {
                  $scope.venta.transporte.splice($scope.venta.transporte.indexOf(transporte), 1);
                }

                $scope.agregarMetodo = function () {
                  $scope.venta.tratamiento.push(
                    {
                      tcTratamientoSilvicultural: null
                    }
                  );
                }

                $scope.quitarMetodo = function (tratamiento) {
                  $scope.venta.tratamiento.splice($scope.venta.tratamiento.indexOf(tratamiento), 1);
                }

                $scope.setTipoTransporte = function (item, row) {
                  row.tcTipoTransporte = item;
                }

                $scope.setTratamientoSilvicultural = function (item, row) {
                  row.tcTratamientoSilvicultural = item;
                }

                $scope.setVolumenTotal = function (row, rodal) {
                  if (row.trozaSolitada != null && row.leniaSolicitada != null) {
                    row.totalSolicitado = parseFloat(row.trozaSolicitada) + parseFloat(row.leniaSolicitada);
                    row.totalSolicitado = parseFloat(row.totalSolicitado).toFixed(2);
                    if (row.saldo < row.totalSolicitado) {
                      row.arbolesSolicitado = 0;
                      row.trozaSolicitada = 0;
                      row.leniaSolicitada = 0;
                      row.totalSolicitado = 0;
                      swal('El total solicitado es mayor al registrado (' + row.saldo + ')');
                      return;
                    }
                  }
                  var total = 0, totalEspecie = 0;
                  for (var i = 0; i < rodal.especies.length; i++) {
                    total += parseFloat(rodal.especies[i].totalSolicitado);
                    if (row.tcEspecie.especieId == rodal.especies[i].tcEspecie.especieId) {
                      totalEspecie += rodal.especies[i].totalSolicitado;
                    }
                  }
                  //validar total disponible de la especie
                  for (var i = 0; i < $scope.dataEspecie.length; i++) {
                    if ($scope.dataEspecie[i].tcEspecie.especieId == row.tcEspecie.especieId) {
                      var disponible = $scope.dataEspecie[i].saldo;
                      if (disponible < totalEspecie) {
                        row.arbolesSolicitado = 0;
                        row.trozaSolicitada = 0;
                        row.leniaSolicitada = 0;
                        row.totalSolicitado = 0;
                        swal('La sumatoria de volumen solicitado para la especie ' + row.tcEspecie.nombreCientifico + ' es mayor al disponible (disponible: ' + disponible + '), verifique que los rodales contengan el volumen indicado');
                        return;
                      }
                      break;
                    }
                  }
                  rodal.volumen = parseFloat(total).toFixed(2);
                }

                $scope.setEspecieVenta = function (item, rodal, especie) {
                  var data = rodal.especies;
                  var conteo = 0;
                  for (var i = 0; i < data.length; i++) {
                    if (data[i].tcEspecie != null) {
                      if (data[i].tcEspecie.especieId == item.especieId) {
                        conteo++;
                      }
                    }
                  }
                  if (conteo <= 1) {
                    especie.saldo = item.saldo;
                    especie.tcEspecie.especieDesc = item.especieDesc;
                    especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                    especie.tcEspecie.codigo = item.codigo;
                  } else {
                    especie.tcEspecie.especieId = null;
                    especie.tcEspecie = null;
                    toastr.error('Esta especie ya fue seleccionada');
                  }
                }

                $scope.imprimirSolicitud = function () {
                  var data = $scope.dataExento;
                  $scope.venta.tcPersonaCrea = {
                    personaDesc: $scope.loginData.personaDesc,
                    tcUsuario: {
                      usuario: $scope.loginData.usuario
                    }
                  }
                  $scope.venta.fechaRegistro = new Date();
                  var tcPersonaCrea = $scope.venta.tcPersonaCrea;
                  if (tcPersonaCrea == null) {
                    tcPersonaCrea = {};
                  }
                  tcPersonaCrea.personaDesc = $scope.loginData.usuarioDesc;
                  $scope.venta.tcPersonaCrea = tcPersonaCrea;
                  if ($scope.esRevalidacion) {
                    $scope.venta.paraRevalidar = 1;
                  } else {
                    $scope.venta.paraRevalidar = 0;
                  }
                  data.ventas = [$scope.venta];
                  utilsService.setEsPrevia(true);
                  utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                }

                $scope.setCategoriaExento = function (item) {
                  $scope.venta.tcCategoriaExento = item;
                }

                $scope.enviarSolicitudVenta = function (isValid) {
                  if (isValid) {
                    if ($scope.venta.rodales == null) {
                      $scope.venta.rodales = [];
                    }
                    if ($scope.venta.rodales.length <= 0) {
                      toastr.error('Debe indicar al menos un rodal');
                      return;
                    } else {
                      var rodales = $scope.venta.rodales;
                      for (var i = 0; i < rodales.length; i++) {
                        if (rodales[i].especies == null) {
                          rodales[i].especies = [];
                        }
                        if (rodales[i].especies.length <= 0) {
                          toastr.error('Debe indicar al menos una especie por rodal');
                          return;
                        }
                      }
                    }
                    if ($scope.venta.transporte == null) {
                      $scope.venta.transporte = [];
                    }
                    if ($scope.venta.transporte.length <= 0) {
                      toastr.error('Debe indicar al menos un tipo de transporte a utilizar');
                      return;
                    }
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de enviar la solicitud de venta de notas para el exento " + $scope.dataExento.codigo + ", a las oficinas del INAB? \nEsta acción no se podrá reversar",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          var data = angular.copy($scope.venta);
                          data.ttExento = {
                            exentoId: $scope.dataExento.exentoId
                          }
                          if ($scope.esRevalidacion) {
                            data.paraRevalidar = 1;
                          } else {
                            data.paraRevalidar = 0;
                          }
                          if (data.tcPersonaCrea == null) {
                            data.tcPersonaCrea = {};
                          }
                          data.tcPersonaCrea.personaDesc = $scope.loginData.personaDesc;
                          exentoService.solicitarVenta(data).then(function (res) {
                            if (res.status == "OK") {
                              exentoService.getDatosVenta(res.singleValue).then(function (res) {
                                if (res.status == "OK") {
                                  var data = res.data[0];
                                  utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                              toastr.success(res.message);
                              $state.go('^.listar');
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    toastr.error('Debe rellenar todos los campos');
                  }
                }

                $scope.validarAreaIntervenirVentaExento = function (rodal) {
                  if (parseFloat(rodal.area) < parseFloat(rodal.areaIntervenir) || parseFloat(rodal.areaIntervenir) < 0) {
                    rodal.areaIntervenir = null;
                    toastr.error('El área debe ser mayor a cero y menor o igual al área del rodal registrado');
                  }
                }

              }]
          })
          .state('index.exento.listainforme', {
            url: '/:ventaExentoId/listainforme',
            templateUrl: 'app/exento/informe.list.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataListaInformeVenta: ['exentoService', '$stateParams', '$base64',
                function (exentoService, $stateParams, $base64) {
                  return exentoService.dataListaInformeVenta($base64.decode($stateParams.ventaExentoId));
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado',
              'ngDialog', 'catalogosService', 'dataListaInformeVenta', 'utilsService', '$base64', 'tareaService', 'utilsService',
              function ($scope, $state, toastr, exentoService, dataEstado,
                ngDialog, catalogosService, dataListaInformeVenta, utilsService, $base64, tareaService, utilsService) {
                if (dataListaInformeVenta.status != 'OK') {
                  swal(dataListaInformeVenta.message);
                  $state.go('index.exento.listar');
                  return;
                }

                $scope.ttVentaExento = dataListaInformeVenta.data[0];

                $scope.dataEstado = dataEstado.data;

                $scope.mostrarEditar = function (estadoId) {
                  if (estadoId == 1 || estadoId == 20) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarVer = function (estadoId) {
                  if (estadoId == 4 || estadoId == 3 || estadoId == 2) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarDenegacion = function (estadoId) {
                  if (estadoId == 5 || estadoId == 99) {
                    return true;
                  }
                  return false;
                }

                $scope.etiquetaEstado = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'Elaborando';
                    case 2: return 'Solicitado';
                    case 3: return 'En trámite';
                    case 4: return 'Finalizado';
                    case 5: return 'Enmienda realizada';
                    case 99: return 'Denegado';
                    case 20: return 'Enmienda';
                    default: return 'Inactivo';
                  }
                }

                $scope.etiquetaEsFinal = function (esFinal) {
                  if (esFinal == 1) {
                    return 'Si';
                  } else {
                    return 'No';
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.columnDefs = [
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha registro', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  { field: 'fechaElaboracion', name: 'fechaElaboracion', displayName: 'Fecha elaboración', width: '15%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                  {
                    name: 'esFinal', displayName: "Es final", field: 'esFinal',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEsFinal(row.entity.esFinal)}}</div>'
                  },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  { field: 'tcTask.actorDesc', name: 'Puesto', displayName: 'Puesto', width: '10%' },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.editRow(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirInforme(row.entity)" title="Imprimir informe"><i class="fa fa-file-pdf-o text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.enviarInforme(row.entity)" title="Enviar informe al INAB"> <i class="fa fa-paper-plane-o"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarDenegacion(row.entity.estadoId)"  ng-click="grid.appScope.mostrarNotificacion(row.entity)" title="Mostrar notificación"> <i class="fa fa-file-pdf-o text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarVer(row.entity.estadoId)" ng-click="grid.appScope.verAnexosInformeExento(row.entity)" title="Ver Anexos"> <i class="fa fa-eye text-danger"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataListaInformeVenta.data[0].informes;
                $scope.agregarInforme = function () {
                  var ttVentaExento = angular.copy($scope.ttVentaExento);
                  if (ttVentaExento.estadoId == 5 || ttVentaExento.estadoId == 99) {
                    toastr.error('La resolución de venta no se encuentra activa para agregar más informes, revise que no esté finalizado');
                    return;
                  }
                  delete ttVentaExento.informes;
                  var data = {
                    ttVentaExento: ttVentaExento
                  }
                  exentoService.agregarInforme(data).then(function (res) {
                    if (res.status == "OK") {
                      var item = res.data[0];
                      $state.go('^.informe', { ventaExentoId: $base64.encode(ttVentaExento.ventaExentoId), informeVentaId: $base64.encode(item.informeVentaId) });
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.editRow = function (item) {
                  $state.go('^.informe', { ventaExentoId: $base64.encode($scope.ttVentaExento.ventaExentoId), informeVentaId: $base64.encode(item.informeVentaId) });
                }

                $scope.imprimirInforme = function (item) {
                  if (item.fechaInicio == null) {
                    toastr.error('No es posible imprimir informes que se agregaron en la autorización de carga inicial');
                    return;
                  }
                  exentoService.getInformeVenta(item.informeVentaId).then(function (response) {
                    if (response.status == 'OK') {
                      var data = response.data[0];
                      data.conclusiones = JSON.parse(data.conclusiones);
                      utilsService.generarInformeExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.enviarInforme = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro (a) de enviar el informe al INAB? \nEsta acción bloqueará la edición del informe y no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },

                    function (isConfirm) {
                      if (isConfirm) {
                        var data = angular.copy(item);
                        delete data.ttVentaExento;
                        exentoService.enviarInforme(data).then(function (res) {
                          if (res.status == "OK") {
                            item.estadoId = 2;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.mostrarNotificacion = function (item) {
                  tareaService.getNotificacionInformeVenta(item.informeVentaId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      if (data.ttPadre.aprobado == 0) {
                        data.ttPadre.observaciones = JSON.parse(data.ttPadre.observaciones);
                      }
                      utilsService.setEsPrevia(true);
                      utilsService.generarNotificacionInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.verAnexosInformeExento = function (item) {
                  if (item.fechaElaboracion == null) {
                    toastr.error('Los informes agregados en carga inicial o sin finalizar su elaboración no pueden mostrar anexos');
                    return;
                  }
                  ngDialog.open({
                    template: 'app/tarea/tarea.listaanexos.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        exentoService.getListaAnexosInforme(item.informeVentaId).then(function (res) {
                          if (res.status == 1) {
                            $scope.anexos = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });

                        $scope.verAnexoInforme = function (item) {
                          exentoService.getAnexoInforme(item.anexoInformeVentaId);
                        }
                      }]
                  })
                }

              }]
          })
          .state('index.exento.informe', {
            url: '/:ventaExentoId/:informeVentaId/informe',
            templateUrl: 'app/exento/exento.informe.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataVenta: ['exentoService', '$stateParams', '$base64',
                function (exentoService, $stateParams, $base64) {
                  return exentoService.getDataVentaParaInforme($base64.decode($stateParams.ventaExentoId));
                }],
              dataInforme: ['exentoService', '$stateParams', '$base64',
                function (exentoService, $stateParams, $base64) {
                  return exentoService.getInformeVenta($base64.decode($stateParams.informeVentaId));
                }],
              dataMunicipio: ['municipioService', '$stateParams',
                function (municipioService, $stateParams) {
                  return municipioService.getPorPais(1);
                }],
              dataProductoTransporte: ['productotransporteService', '$stateParams',
                function (productotransporteService, $stateParams) {
                  return productotransporteService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado', '$stateParams', 'dataMunicipio', 'Upload',
              'ngDialog', 'catalogosService', 'dataVenta', 'dataInforme', 'utilsService', 'dataProductoTransporte', '$base64',
              function ($scope, $state, toastr, exentoService, dataEstado, $stateParams, dataMunicipio, Upload,
                ngDialog, catalogosService, dataVenta, dataInforme, utilsService, dataProductoTransporte, $base64) {

                if (dataInforme.status != 'OK') {
                  swal(dataInforme.message);
                  $state.go('^.listainforme', { ventaExentoId: $stateParams.ventaExentoId });
                  return;
                }

                $scope.tiene = false;
                $scope.mostrarAprovechamiento = true;
                $scope.pagina = 1;
                $scope.totalPaginas = 1;

                $scope.dataRendimiento = [
                  { factorRendimientoId: 0, factorRendimientoDesc: 'Usar los oficiales' },
                  { factorRendimientoId: 1, factorRendimientoDesc: 'Usar mi factor autorizado' }
                ];

                $scope.dataTipoSierra = [
                  { tipoSierraId: 1, tipoSierraDesc: 'Banda' },
                  { tipoSierraId: 2, tipoSierraDesc: 'Circular' },
                  { tipoSierraId: 3, tipoSierraDesc: 'Motosierra' }
                ];

                $scope.dataSierra = [
                  { otraSierraId: 0, otraSierraDesc: 'Sin sierra secundaria' },
                  { otraSierraId: 1, otraSierraDesc: 'Con sierra secundaria' }
                ];

                $scope.ttVentaExento = dataVenta.data[0];
                $scope.ttInformeVenta = dataInforme.data[0];
                if ($scope.ttInformeVenta.fechaInicio != null) {
                  $scope.ttInformeVenta.fechaInicio = new Date($scope.ttInformeVenta.fechaInicio);
                }
                if ($scope.ttInformeVenta.fechaFinal != null) {
                  $scope.ttInformeVenta.fechaFinal = new Date($scope.ttInformeVenta.fechaFinal);
                }
                $scope.dataMunicipio = dataMunicipio.data;
                $scope.dataProductoTransporte = dataProductoTransporte.data;

                var especies = [];
                var saldos = $scope.ttVentaExento.saldos;
                var lenia = 0, troza = 0;
                for (var i = 0; i < saldos.length; i++) {
                  var fila = saldos[i];
                  lenia += parseFloat(fila.lenia);
                  troza += parseFloat(fila.troza);
                  especies.push(fila.tcEspecie);
                }
                $scope.lenia = lenia;
                $scope.troza = troza;
                $scope.dataEspecie = especies;

                var tmpNotas = [], notas = [];
                var rangos = $scope.ttVentaExento.rangos;
                for (var i = 0; i < rangos.length; i++) {
                  for (var j = rangos[i].inicio; j <= rangos[i].fin; j++) {
                    var item = {
                      numero: j,
                      electronica: rangos[i].electronica
                    }
                    tmpNotas.push(item);
                  }
                }

                $scope.dataEstado = dataEstado.data;
                $scope.dataEsFinal = [
                  { esFinal: 0, esFinalDesc: 'Trimestral' },
                  { esFinal: 1, esFinalDesc: 'Final' },
                  { esFinal: 2, esFinalDesc: 'Avances' }
                ];

                if ($scope.ttInformeVenta.tieneMovimiento == 1) {
                  $scope.tiene = true;
                } else {
                  $scope.tiene = false;
                }

                $scope.setTieneMovimiento = function () {
                  if ($scope.ttInformeVenta.tieneMovimiento == 1) {
                    $scope.ttInformeVenta.tieneMovimiento = 0;
                  } else {
                    $scope.ttInformeVenta.tieneMovimiento = 1;
                  }
                }

                $scope.tabVisible = {
                  cero: true,
                  uno: false,
                  dos: false,
                  tres: false
                }

                $scope.tabActive = {
                  cero: true,
                  uno: false,
                  dos: false,
                  tres: false
                }

                $scope.setPaginaMostar = function () {
                  var notas = $scope.ttInformeVenta.notas;
                  var totalPaginas = (notas.length / 10);
                  totalPaginas = Math.ceil(totalPaginas);
                  var notasMostrar = [];
                  if (totalPaginas >= $scope.pagina) {
                    var fin = $scope.pagina * 10;
                    var inicio = fin - 10;
                    if (fin > notas.length) {
                      fin = notas.length;
                    }
                    for (var i = inicio; i < fin; i++) {
                      if (notas[i].mostrarProducto == undefined) {
                        notas[i].mostrarProducto = false;
                        notas[i].mostrarMotivo = false;
                      }
                      if (notas[i].usoId == undefined) {
                        notas[i].usoId = 1;
                      }
                      notasMostrar.push(notas[i]);
                    }
                    $scope.textoMostrando = $scope.pagina + " de " + totalPaginas;
                    $scope.totalPaginas = totalPaginas;
                    $scope.notas = notasMostrar;
                  }
                }

                $scope.backpage = function () {
                  if ($scope.pagina > 1) {
                    $scope.pagina--;
                    $scope.setPaginaMostar();
                  } else {
                    toastr.error('No hay mas paginas que mostrar');
                  }
                }

                $scope.nextpage = function () {
                  if ($scope.pagina < $scope.totalPaginas) {
                    $scope.pagina++;
                    var notas = $scope.notas;
                    $scope.setPaginaMostar();
                  } else {
                    toastr.error('No hay mas paginas que mostrar');
                  }
                }

                var informes = $scope.ttVentaExento.informes;
                var conteo = 0;
                var correlativo = 0;
                var faltan = [];
                var iNotas = $scope.ttInformeVenta.notas;
                for (var h = 0; h < tmpNotas.length; h++) {
                  var fila = tmpNotas[h];
                  var encontrado = false;
                  for (var j = 0; j < iNotas.length; j++) {
                    var nota = iNotas[j];
                    if (parseInt(nota.numero) == parseInt(fila.numero)) {
                      if (nota.fechaNota != null) {
                        if (nota.fechaNota.toString().length > 10) {
                          nota.fechaNota = new Date(nota.fechaNota);
                        } else {
                          var parts = nota.fechaNota.toString().split('-');
                          var mydate = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
                          nota.fechaNota = new Date(mydate);
                        }
                      }
                      if (nota.usoId == 1) {
                        nota.mostrarProducto = true;
                        nota.mostrarMotivo = false;
                      }
                      if (nota.usoId == 0) {
                        nota.motivoTitulo = "anulación";
                        nota.mostrarProducto = false;
                        nota.mostrarMotivo = true;
                      }
                      if (nota.usoId == 2) {
                        nota.motivoTitulo = "extravío";
                        nota.mostrarProducto = false;
                        nota.mostrarMotivo = true;
                      }
                      if (nota.verificado) {
                        $scope.mostrarAprovechamiento = false;
                        $scope.ttInformeVenta.tieneMovimiento = 1;
                      }
                      //verificar que no este agregado aun
                      encontrado = false;
                      for (var k = 0; k < notas.length; k++) {
                        const element = notas[k];
                        if (element.numero == nota.numero) {
                          encontrado = true;
                          break;
                        }
                      }
                      if (!encontrado) {
                        notas.push(nota);
                      }
                      encontrado = true;
                      break;
                    }
                  }
                  if (!encontrado) {
                    faltan.push(fila);
                  }
                }
                tmpNotas = faltan;
                for (var h = 0; h < tmpNotas.length; h++) {
                  var fila = tmpNotas[h];
                  var encontrado = false;
                  for (var i = 0; i < informes.length; i++) {
                    var iNotas = informes[i].notas;
                    for (var j = 0; j < iNotas.length; j++) {
                      var nota = iNotas[j];
                      if (parseInt(nota.numero) == parseInt(fila.numero)) {
                        encontrado = true;
                        break;
                      }
                    }
                    if (informes[i].correlativo > correlativo) {
                      correlativo = informes[i].correlativo;
                    }
                    if (encontrado) {
                      break;
                    }
                  }
                  if (!encontrado) {
                    notas.push(fila);
                  }
                }
                
                if (notas.length > 1) {
                  notas.sort((a, b) => a.numero - b.numero);
                }
                $scope.ttInformeVenta.notas = notas;
                $scope.setPaginaMostar();

                $scope.obtenerTextoRangos = function (textoRetorno, correlativos) {
                  if (textoRetorno.length > 0) {
                    textoRetorno += ', del ' + correlativos[0] + ' al ';
                  } else {
                    textoRetorno = 'del ' + correlativos[0] + ' al ';
                  }
                  var ultimo = correlativos[0];
                  if (correlativos.length > 1) {
                    for (var j = 1; j < correlativos.length; j++) {
                      ultimo++;
                      if (correlativos[j] != ultimo) {
                        textoRetorno += correlativos[j - 1];
                        textoRetorno += ', del ' + correlativos[j] + ' al ';
                        ultimo = correlativos[j];
                      }
                    }
                    ultimo = correlativos[correlativos.length - 1];
                    textoRetorno += ultimo;
                  } else {
                    textoRetorno += ultimo;
                  }
                  return textoRetorno;
                }

                $scope.setConclusiones = function () {
                  var notas = $scope.ttInformeVenta.notas;
                  var usados = 0, anulados = 0, extraviados = 0, noUsados = 0;
                  var rangos = $scope.ttVentaExento.rangos;
                  for (var j = 0; j < rangos.length; j++) {
                    rangos[j].usados = [];
                    rangos[j].anulados = [];
                    rangos[j].extraviados = [];
                    rangos[j].noUsados = [];
                  }
                  for (var i = 0; i < notas.length; i++) {
                    if (notas[i].mostrarMotivo != undefined && notas[i].mostrarProducto != undefined) {
                      if (notas[i].mostrarMotivo || notas[i].mostrarProducto) {
                        if (notas[i].usoId == 1) { //usados
                          usados++;
                          for (var j = 0; j < rangos.length; j++) {
                            var rango = rangos[j];
                            if (parseInt(notas[i].numero) >= parseInt(rango.inicio) && parseInt(notas[i].numero) <= parseInt(rango.fin)) {
                              rango.usados.push(parseInt(notas[i].numero));
                              break;
                            }
                          }
                        }
                        if (notas[i].usoId == 0) { //anulados
                          anulados++;
                          for (var j = 0; j < rangos.length; j++) {
                            var rango = rangos[j];
                            if (parseInt(notas[i].numero) >= parseInt(rango.inicio) && parseInt(notas[i].numero) <= parseInt(rango.fin)) {
                              rango.anulados.push(parseInt(notas[i].numero));
                              break;
                            }
                          }
                        }
                        if (notas[i].usoId == 2) { //extraviados
                          extraviados++;
                          for (var j = 0; j < rangos.length; j++) {
                            var rango = rangos[j];
                            if (parseInt(notas[i].numero) >= parseInt(rango.inicio) && parseInt(notas[i].numero) <= parseInt(rango.fin)) {
                              rango.extraviados.push(parseInt(notas[i].numero));
                              break;
                            }
                          }
                        }
                        //agregar a usados
                      } else { //no usados
                        noUsados++;
                        for (var j = 0; j < rangos.length; j++) {
                          var rango = rangos[j];
                          if (parseInt(notas[i].numero) >= parseInt(rango.inicio) && parseInt(notas[i].numero) <= parseInt(rango.fin)) {
                            rango.noUsados.push(parseInt(notas[i].numero));
                            break;
                          }
                        }
                      }
                    } else { //no se marcaron, son no usados
                      noUsados++;
                      for (var j = 0; j < rangos.length; j++) {
                        var rango = rangos[j];
                        if (parseInt(notas[i].numero) >= parseInt(rango.inicio) && parseInt(notas[i].numero) <= parseInt(rango.fin)) {
                          rango.noUsados.push(parseInt(notas[i].numero));
                          break;
                        }
                      }
                    }
                  }
                  //ordenar los correlativos para determinar cual es el primero y ultimo segun su grupo
                  var textoUsado = '';
                  var textoAnulados = '';
                  var textoExtraviados = '';
                  var textoNoUsados = '';
                  for (var i = 0; i < rangos.length; i++) {
                    var correlativos = rangos[i].usados;
                    correlativos = $scope.quitarDuplicados(correlativos);
                    correlativos = $scope.ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoUsado = $scope.obtenerTextoRangos(textoUsado, correlativos);
                    }
                    correlativos = rangos[i].anulados;
                    correlativos = $scope.quitarDuplicados(correlativos);
                    correlativos = $scope.ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoAnulados = $scope.obtenerTextoRangos(textoAnulados, correlativos);
                    }
                    correlativos = rangos[i].extraviados;
                    correlativos = $scope.quitarDuplicados(correlativos);
                    correlativos = $scope.ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoExtraviados = $scope.obtenerTextoRangos(textoExtraviados, correlativos);
                    }
                    correlativos = rangos[i].noUsados;
                    correlativos = $scope.quitarDuplicados(correlativos);
                    correlativos = $scope.ordenarBurbuja(correlativos);
                    if (correlativos.length > 0) {
                      textoNoUsados = $scope.obtenerTextoRangos(textoNoUsados, correlativos);
                    }
                  }
                  var conclusiones = null;
                  if ($scope.ttInformeVenta.conclusiones != null) {
                    conclusiones = $scope.ttInformeVenta.conclusiones;
                  }

                  var textoRango = '';
                  for (var i = 0; i < rangos.length; i++) {
                    if (i == 0) {
                      textoRango = 'del ' + rangos[i].inicio + ' al ' + rangos[i].fin;
                    } else {
                      textoRango += ', del ' + rangos[i].inicio + ' al ' + rangos[i].fin;
                    }
                  }

                  $scope.ttInformeVenta.conclusiones = [
                    { conclusion: 'El volumen autorizado de troza (m³): ' + parseFloat($scope.troza).toFixed(2) + ' y leña (m³): ' + parseFloat($scope.lenia).toFixed(2), mostrarQuitar: false },
                    { conclusion: 'Rango de notas de envío autorizadas ' + textoRango, mostrarQuitar: false },
                    { conclusion: 'Rango de notas de envío utilizadas ' + textoUsado, mostrarQuitar: false }
                  ];
                  if (anulados > 0) {
                    $scope.ttInformeVenta.conclusiones.push(
                      { conclusion: 'Rango de notas de envío anuladas ' + textoAnulados, mostrarQuitar: false }
                    );
                  }
                  if (extraviados > 0) {
                    $scope.ttInformeVenta.conclusiones.push(
                      { conclusion: 'Rango de notas de envío extraviadas ' + textoExtraviados, mostrarQuitar: false }
                    );
                  }

                  if (noUsados > 0) {
                    $scope.ttInformeVenta.conclusiones.push(
                      { conclusion: 'Rango de notas de envío sin utilizar ' + textoNoUsados, mostrarQuitar: false }
                    );
                  }

                  if (conclusiones != null) {
                    for (var i = 0; i < conclusiones.length; i++) {
                      var item = conclusiones[i];
                      if (item.mostrarQuitar) {
                        $scope.ttInformeVenta.conclusiones.push(item);
                      }
                    }
                  }
                }

                if ($scope.ttInformeVenta.conclusiones == null) {
                  if (conteo > 0) {
                    $scope.tabVisible.uno = true;
                    $scope.tabActive.uno = true;
                  }
                } else {
                  $scope.tabVisible.uno = true;
                  $scope.tabVisible.dos = true;
                  $scope.tabVisible.tres = true;
                  $scope.tabActive.tres = true;
                  $scope.setConclusiones();
                }

                $scope.setEspecieNota = function (item, nota, producto) {
                  producto.tcEspecie = item;
                }


                $scope.agregarProducto = function (nota) {
                  if (nota.especies == null) {
                    nota.especies = [];
                  }
                  nota.especies.push(
                    { volumenReportar: 0, volumen: 0 }
                  );
                  nota.usoId = 1;
                  nota.mostrarProducto = true;
                  nota.mostrarMotivo = false;
                }

                $scope.quitarProducto = function (nota, item) {
                  nota.especies.splice(nota.especies.indexOf(item), 1);
                  if (nota.especies.length <= 0) {
                    nota.mostrarProducto = false;
                    nota.mostrarMotivo = false;
                  }
                }

                $scope.setProductoTransportado = function (item, producto) {
                  producto.tcProductoTransporte.productoTransporteDesc = item.productoTransporteDesc;
                  producto.tcProductoTransporte.rendimientoConSierrab = item.rendimientoConSierrab;
                  producto.tcProductoTransporte.rendimientoConSierrac = item.rendimientoConSierrac;
                  producto.tcProductoTransporte.rendimientoMotosierra = item.rendimientoMotosierra;
                  producto.tcProductoTransporte.rendimientoSinSierrab = item.rendimientoSinSierrab;
                  producto.tcProductoTransporte.rendimientoSinSierrac = item.rendimientoSinSierrac;
                  producto.tcProductoTransporte.rendimientoSinSierrabLati = item.rendimientoSinSierrabLati;
                  producto.tcProductoTransporte.rendimientoSinSierracLati = item.rendimientoSinSierracLati;
                }

                $scope.setVolumenProducto = function (producto, nota) {
                  if (producto.tcProductoTransporte == null || producto.tcProductoTransporte.productoTransporteId == null) {
                    producto.volumen = null;
                    toastr.error('Debe indicar el producto a trasportar');
                    return;
                  }
                  producto.volumenReportar = producto.volumen;
                  var factor = 0;
                  if (producto.tcProductoTransporte.productoTransporteId == 2) {
                    if ($scope.ttInformeVenta.aplicaFactora == 1) {
                      factor = parseFloat($scope.ttInformeVenta.factorAserrada);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      if ($scope.ttInformeVenta.tipoSierraId == 1) {
                        if ($scope.ttInformeVenta.otraSierraId == 0) {
                          if (producto.tcEspecie.tcTipoEspecie.tipoEspecieId == 1) {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                          } else {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrabLati);
                          }
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoConSierrab);
                        }
                      }
                      if ($scope.ttInformeVenta.tipoSierraId == 2) {
                        if (producto.tcEspecie.tcTipoEspecie.tipoEspecieId == 1) {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrac);
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierracLati);
                        }
                      }
                      if ($scope.ttInformeVenta.tipoSierraId == 3) {
                        factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      }
                      var factorUsado = angular.copy(factor);
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                      $scope.ttInformeVenta.factorAserrada = factorUsado;
                    }
                  }
                  if (producto.tcProductoTransporte.productoTransporteId == 4) {
                    if ($scope.ttInformeVenta.aplicaFactorc == 1) {
                      factor = parseFloat($scope.ttInformeVenta.factorCarbon);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      var factorUsado = angular.copy(factor);
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                      $scope.ttInformeVenta.factorCarbon = factorUsado;
                    }
                  }
                }


                $scope.setTipoInforme = function (item) {
                  if (item.esFinal == 1) {
                    swal(
                      {
                        title: "Al ser informe final, las notas que no reporte como usado, extraviado o anulado quedarán deshabilitadas y no podrá solicitar revalidación",
                        text: "Si desea realizar otro informe posterior a éste use el tipo de informe trimestral o de avances",
                        icon: "warning",
                      }
                    );
                  }
                }

                $scope.marcarAnulado = function (nota) {
                  nota.usoId = 0;
                  nota.motivoTitulo = "anulación";
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = true;
                  nota.especies = [];
                }

                $scope.marcarExtraviado = function (nota) {
                  nota.usoId = 2;
                  nota.motivoTitulo = "extravío";
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = true;
                  nota.especies = [];
                }

                $scope.marcarNoUsado = function (nota) {
                  nota.usoId = 3;
                  nota.mostrarProducto = false;
                  nota.mostrarMotivo = false;
                  nota.especies = [];
                  nota.tcMunicipio = null;
                  nota.destino = null;
                  nota.fechaNota = null;
                }

                $scope.guardarNotas = function (enviar) {
                  var data = angular.copy($scope.ttInformeVenta);
                  if (data.conclusiones != null) {
                    data.conclusiones = JSON.stringify(data.conclusiones);
                  }
                  data.notas = enviar;
                  exentoService.actualizarInformeNotas(data).then(function (res) {
                    if (res.status == "OK") {
                      toastr.success(res.message);
                      swal(
                        {
                          title: 'Debe presionar sobre el botón guardar en todas la pestañas para actualizar la información, de lo contrario puede ser motivo de enmienda y retrasar su trámite',
                          text: "He leído la advertencia y estoy conciente de lo que debo realizar",
                          icon: "info",
                          type: "info",
                          confirmButtonText: "Aceptar"
                        }
                      );
                      $scope.tabActive.uno = true;
                      $scope.tabVisible.uno = true;
                      $scope.onSelectGenerales(enviar.length <= 0);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.submitFormNotas = function (isValid) {
                  isValid = true;
                  if ($scope.ttInformeVenta.fechaFinal == null || $scope.ttInformeVenta.fechaInicio == null) {
                    toastr.error('Debe indicar el rango de fecha del informe');
                    return;
                  }
                  var enviar = [];
                  var bndHayVacios = false;
                  for (var i = 0; i < $scope.ttInformeVenta.notas.length; i++) {
                    var nota = $scope.ttInformeVenta.notas[i];
                    if (nota.mostrarProducto != null) {
                      if (nota.mostrarProducto) {
                        if ((nota.fechaNota == null || nota.destino == null) && !nota.electronica) {
                          toastr.error('Debe indicar los datos corresponndientes a la nota ' + nota.numero);
                          return;
                        }
                        if (nota.tcMunicipio == null) {
                          if (!nota.electronica) {
                            toastr.error('Debe el municipio corresponndiente a la nota ' + nota.numero);
                            return;
                          }
                        } else {
                          if (nota.tcMunicipio.municipioId == null) {
                            toastr.error('Debe el municipio corresponndiente a la nota ' + nota.numero);
                            return;
                          }
                        }
                        var especies = nota.especies;
                        if (especies == null) {
                          especies = [];
                        }
                        for (var j = 0; j < especies.length; j++) {
                          var especie = especies[j];
                          if (especie.tcEspecie == null || parseFloat(especie.volumen) <= 0 || especie.tcProductoTransporte == null) {
                            toastr.error('La información consignada es incorrecta para la nota ' + nota.numero);
                            return;
                          }
                        }
                      }
                      if (nota.mostrarMotivo) {
                        if (nota.motivo == null) {
                          toastr.error('Debe la razón a la nota ' + nota.numero);
                          return;
                        }
                        nota.especies = [];
                      }
                      if (nota.mostrarProducto || nota.mostrarMotivo) {
                        enviar.push(nota);
                      } else {
                        bndHayVacios = true;
                      }
                    }
                  }
                  if ($scope.ttInformeVenta.esFinal == 1 && bndHayVacios) {
                    swal({
                      title: 'Al seleccionar un informe final ¿Está seguro de deshabilitar las notas que no está reportando?',
                      text: "Esta acción no se puede reversar",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          if (isValid) {
                            $scope.guardarNotas(enviar);
                          } else {
                            toastr.error('Asegúrese de rellenar los campos de las notas usadas');
                          }
                        }
                      });
                  } else {
                    if (isValid) {
                      $scope.guardarNotas(enviar);
                    } else {
                      toastr.error('Asegúrese de rellenar los campos de las notas usadas');
                    }
                  }
                }

                $scope.actualizarTotales = function () {
                  var especies = $scope.ttInformeVenta.especies;
                  var totales = {
                    lenia: 0,
                    troza: 0,
                    trozaFinca: 0,
                    leniaFinca: 0,
                    volumen: 0
                  };
                  for (var i = 0; i < especies.length; i++) {
                    var especie = especies[i];
                    especie.volumen = parseFloat(especie.lenia) + parseFloat(especie.troza) + parseFloat(especie.trozaFinca) + parseFloat(especie.leniaFinca);
                    especie.volumen = parseFloat(especie.volumen).toFixed(2);
                    totales.lenia += parseFloat(especie.lenia);
                    totales.troza += parseFloat(especie.troza);
                    totales.leniaFinca += parseFloat(especie.leniaFinca);
                    totales.trozaFinca += parseFloat(especie.trozaFinca);
                    totales.volumen += parseFloat(especie.volumen);
                  }
                  totales.lenia = parseFloat(totales.lenia).toFixed(2);
                  totales.troza = parseFloat(totales.troza).toFixed(2);
                  totales.leniaFinca = parseFloat(totales.leniaFinca).toFixed(2);
                  totales.trozaFinca = parseFloat(totales.trozaFinca).toFixed(2);
                  totales.volumen = parseFloat(totales.volumen).toFixed(2);
                  $scope.totales = totales;
                }

                $scope.onSelectGenerales = function (vaciar) {
                  if (vaciar == null) {
                    vaciar = false;
                  }
                  var notas = $scope.ttInformeVenta.notas;
                  var lista = $scope.ttInformeVenta.especies;
                  if (lista == null) {
                    lista = [];
                  }
                  if (vaciar) {
                    var sinNotas = [];
                    for (var i = 0; i < lista.length; i++) {
                      var especie = lista[i];
                      if (especie.opcional) {
                        sinNotas.push(especie);
                      }
                    }
                    lista = sinNotas;
                  }
                  for (var i = 0; i < lista.length; i++) {
                    var especie = lista[i];
                    especie.lenia = 0;
                    especie.troza = 0;
                  }
                  for (var i = 0; i < notas.length; i++) {
                    var especies = notas[i].especies;
                    if (especies != null) {
                      for (var j = 0; j < especies.length; j++) {
                        var especie = especies[j];
                        var encontrado = false;
                        var item = {};
                        for (var k = 0; k < lista.length; k++) {
                          if (lista[k].tcEspecie == null) {
                            toastr.error('La nota ' + notas[i].numero + ' debe seleccionar la especie o quitar el apartado de volumen');
                            return;
                          }
                          if (especie.tcEspecie.especieId == lista[k].tcEspecie.especieId) {
                            encontrado = true;
                            item = lista[k];
                            break;
                          }
                        }
                        if (!encontrado) {
                          item = {
                            tcEspecie: especie.tcEspecie,
                            volumen: parseFloat(especie.volumenReportar),
                            troza: 0,
                            lenia: 0,
                            trozaFinca: 0,
                            leniaFinca: 0,
                            opcional: false
                          };
                        }
                        if (especie.tcProductoTransporte.productoTransporteId == 1 || especie.tcProductoTransporte.productoTransporteId == 2) {
                          item.troza += parseFloat(especie.volumenReportar);
                        } else {
                          if (especie.tcProductoTransporte.productoTransporteId == 3 || especie.tcProductoTransporte.productoTransporteId == 4) {
                            item.lenia += parseFloat(especie.volumenReportar);
                          }
                        }
                        if (!encontrado) {
                          lista.push(item);
                        }
                      }
                    }
                  }
                  var totales = {
                    lenia: 0,
                    troza: 0,
                    trozaFinca: 0,
                    leniaFinca: 0,
                    volumen: 0
                  };
                  for (var i = 0; i < lista.length; i++) {
                    var item = lista[i];
                    item.volumen = parseFloat(item.lenia) + parseFloat(item.troza) + parseFloat(item.leniaFinca) + parseFloat(item.trozaFinca);
                    item.volumen = parseFloat(item.volumen).toFixed(2);
                    totales.lenia += parseFloat(item.lenia);
                    totales.troza += parseFloat(item.troza);
                    totales.leniaFinca += parseFloat(item.leniaFinca);
                    totales.trozaFinca += parseFloat(item.trozaFinca);
                    totales.volumen += parseFloat(item.volumen);
                  }
                  totales.lenia = parseFloat(totales.lenia).toFixed(2);
                  totales.troza = parseFloat(totales.troza).toFixed(2);
                  totales.leniaFinca = parseFloat(totales.leniaFinca).toFixed(2);
                  totales.trozaFinca = parseFloat(totales.trozaFinca).toFixed(2);
                  totales.volumen = parseFloat(totales.volumen).toFixed(2);
                  $scope.totales = totales;
                  $scope.ttInformeVenta.especies = lista;
                  $scope.setConclusiones();
                }

                $scope.actualizarDatos = function (tab) {
                  var data = angular.copy($scope.ttInformeVenta);
                  data.conclusiones = JSON.stringify(data.conclusiones);
                  delete data.especies;
                  exentoService.actualizarInforme(data).then(function (res) {
                    if (res.status == "OK") {
                      toastr.success(res.message);
                      if (tab == 3) {
                        $scope.tabActive.tres = true;
                        $scope.tabVisible.tres = true;
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.agregarEspecieInforme = function () {
                  if ($scope.ttInformeVenta.especies == null) {
                    $scope.ttInformeVenta.especies = [];
                  }
                  $scope.ttInformeVenta.especies.push(
                    {
                      tcEspecie: null,
                      volumen: 0,
                      troza: 0,
                      lenia: 0,
                      trozaFinca: 0,
                      leniaFinca: 0,
                      opcional: true
                    }
                  );
                }

                $scope.quitarEspecieInforme = function (item) {
                  $scope.ttInformeVenta.especies.splice($scope.ttInformeVenta.especies.indexOf(item), 1);
                }

                $scope.setEspecieInforme = function (item, fila) {
                  var especies = $scope.ttInformeVenta.especies;
                  var veces = 0;
                  for (var i = 0; i < especies.length; i++) {
                    const element = especies[i];
                    if (element.tcEspecie.especieId == item.especieId) {
                      veces++;
                    }
                  }
                  if (veces > 1) {
                    fila.tcEspecie = null;
                    toastr.error('La especie ya se encuentra seleccionada, no es posible repetir');
                  }
                }

                $scope.validarPanelTransporte = function (isValid) {
                  if (isValid) {
                    var data = angular.copy($scope.ttInformeVenta);
                    data.conclusiones = JSON.stringify(data.conclusiones);
                    exentoService.actualizarEspeciesInformeExento(data).then(function (res) {
                      if (res.status == 1) {
                        $scope.tabActive.dos = true;
                        $scope.tabVisible.dos = true;
                        toastr.success(res.message);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.validarPanelConclusiones = function (isValid) {
                  if (isValid) {
                    $scope.actualizarDatos(3);
                  }
                }

                $scope.onSelectRecuperacion = function () {

                }

                $scope.onSelectConclusiones = function () {

                }

                $scope.agregarConclusion = function () {
                  $scope.ttInformeVenta.conclusiones.push(
                    { descripcion: null, mostrarQuitar: true }
                  );
                }

                $scope.borrarConclusion = function (item) {
                  $scope.ttInformeVenta.conclusiones.splice($scope.ttInformeVenta.conclusiones.indexOf(item), 1);
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      //send to database
                      var item = {
                        ruta: item.rutaArchivo,
                        nombre: item.nombreOriginal,
                        size: item.size,
                        extension: item.extension
                      };
                      var data = angular.copy($scope.ttInformeVenta);
                      delete data.conclusiones;
                      item.ttInformeVenta = data;
                      exentoService.agregarAnexoInforme(item).then(function (response) {
                        if (response.status == 'OK') {
                          if ($scope.ttInformeVenta.anexos == null) {
                            $scope.ttInformeVenta.anexos = [];
                          }
                          $scope.ttInformeVenta.anexos.push(response.data[0]);
                          toastr.success(response.message);
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.verAnexoInforme = function (item) {
                  exentoService.getAnexoInforme(item.anexoInformeVentaId);
                }

                $scope.borrarAnexo = function (item) {
                  exentoService.quitarAnexoInforme(item.anexoInformeVentaId).then(function (response) {
                    if (response.status == 'OK') {
                      $scope.ttInformeVenta.anexos.splice($scope.ttInformeVenta.anexos.indexOf(item), 1);
                      toastr.success(response.message);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirInforme = function () {
                  exentoService.getInformeVenta($scope.ttInformeVenta.informeVentaId).then(function (response) {
                    if (response.status == 'OK') {
                      var data = response.data[0];
                      data.conclusiones = JSON.parse(data.conclusiones);
                      utilsService.generarInformeExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.regresarLista = function () {
                  $state.go('^.listainforme', { ventaExentoId: $stateParams.ventaExentoId });
                }

              }]
          })
          .state('index.exento.actualizar', {
            url: '/actualizar',
            templateUrl: 'app/exento/exento.actualizar.tpl.html',
            resolve: {
              dataExento: ['exentoService', 'authService', '$filter', 'tareaService',
                function (exentoService, authService, $filter, tareaService) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  return exentoService.listaPorPersona(loginData.personaId, -99);
                }]
            },
            controller: ['$scope', '$state', 'dataExento', 'exentoService', 'authService', 'utilsService', 'manualService', 'toastr',
              'tareaService', '$base64', '$filter',
              function ($scope, $state, dataExento, exentoService, authService, utilsService, manualService, toastr,
                tareaService, $base64, $filter) {

                $scope.dataTipoActualizacion = [
                  { tipoActualizacionId: 1, tipoActualizacionDesc: 'Actualización del RNF' },
                  { tipoActualizacionId: 2, tipoActualizacionDesc: 'Actualización de volumen' }
                ];

                $scope.mostrarLista = false;
                $scope.etiquetaEstado = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'En edición';
                    case 2: return 'En trámite';
                    case 3: return 'Autorizado';
                    case 99: return 'Denegado';
                    default: return 'Inactivo';
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.columnDefs = [
                  { field: 'codigo', name: 'codigo', displayName: 'RNF' },
                  { field: 'tipoRegistroDesc', name: 'tipoRegistroDesc', displayName: 'Categoría' },
                  { field: 'area', name: 'area', displayName: 'Área aprobada', width: '15%' },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.listarActualizaciones(row.entity)" title="Listar actualizaciones solicitadas"><i class="fa fa-refresh" aria-hidden="true"></i></button> '
                      + '</span></div>'
                  }
                ];

                $scope.mostrarEditar = function (estadoId) {
                  if (estadoId == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptionsSolicitud = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptionsSolicitud.rowHeight = 30;
                $scope.gridOptionsSolicitud.columnDefs = [
                  { field: 'fechaSolicitud', name: 'fechaSolicitud', displayName: 'Fecha solicitud', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'', width: '10%', enableFiltering: false },
                  { field: 'tipoActualizacionDesc', name: 'tipoActualizacionDesc', displayName: 'Tipo de actualización', enableFiltering: false, width: '15%' },
                  { field: 'volumenAnterior', name: 'volumenAnterior', displayName: 'Volumen anterior', enableFiltering: false },
                  { field: 'volumenSolicitado', name: 'volumenSolicitado', displayName: 'Volumen solicitado', enableFiltering: false },
                  { field: 'volumenActualizado', name: 'volumenActualizado', displayName: 'Volumen actualizado', enableFiltering: false },
                  { field: 'fechaAutorizacion', name: 'fechaAutorizacion', displayName: 'Fecha autorización', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'', width: '10%', enableFiltering: false },
                  {
                    name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                  },
                  {
                    name: 'Opciones', enableFiltering: false, width: '10%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.editarActualizacion(row.entity)" title="Editar"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarEditar(row.entity.estadoId)" ng-click="grid.appScope.anularActualizacion(row.entity)" title="Anular"><i class="fa fa-times" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 1" ng-click="grid.appScope.generarSolicitudActualizacionExento(row.entity)" title="Ver solicitud de actualización"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 3 || row.entity.estadoId == 99" ng-click="grid.appScope.resolucionSolicitudActualizacion(row.entity)" title="Ver resolución de la solicitud"><i class="fa fa-file-pdf-o text-success" aria-hidden="true"></i></button>'
                      + '</span></div>'
                  }
                ];
                $scope.gridOptionsSolicitud.data = [];

                if (dataExento.status = 'OK') {
                  $scope.gridOptions.data = dataExento.data;
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    exentoService.listaPorPersona($scope.loginData.personaId, $scope.data.estadoId).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.descargarFormatoActualizar = function () {
                  manualService.getFile(43);
                }

                $scope.editarActualizacion = function (row) {
                  $state.go('^.editar', { actualizacionExentoId: $base64.encode(row.actualizacionExentoId) });
                }

                $scope.listarActualizaciones = function (row) {
                  $scope.ttExento = row;
                  $scope.mostrarLista = true;
                  exentoService.getListaActualizacion(row.exentoId).then(function (res) {
                    if (res.status == 1) {
                      let ttExento = null;
                      var data = res.data;
                      for (let i = 0; i < data.length; i++) {
                        var element = data[i];
                        ttExento = element.ttExento;
                        switch (element.tipoActualizacionId) {
                          case 1:
                            element.tipoActualizacionDesc = 'Actualización del RNF';
                            break;
                          case 2:
                            element.tipoActualizacionDesc = 'Actualización de volumen';
                            break;
                        }
                      }
                      $scope.gridOptionsSolicitud.data = data;
                      if (row.codigo == null && ttExento != null) {
                        $scope.ttExento = ttExento;
                      }
                      toastr.success('La lista de solicitudes de actualización se muestra en la parte inferior de ésta pantalla');
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                let listaActualizacionId = authService.getLocalData("listaActualizacionId");
                if (listaActualizacionId != null) {
                  authService.removeLocalData('listaActualizacionId');
                  $scope.listarActualizaciones({exentoId: listaActualizacionId});
                }

                $scope.anularActualizacion = function (row) {
                  swal({
                    title: '¿Está seguro de anular el registro?',
                    text: "Esta acción no se puede reversar. NO hay regreso de tarea",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        exentoService.anularActualizacion(row.actualizacionExentoId).then(function (response) {
                          if (response.status == 'OK') {
                            $scope.listarActualizaciones($scope.ttExento);
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.mostrarAgregar = false;
                $scope.ayuda = 'Seleccione el tipo de actualización';

                $scope.agregarActualizacion = function () {
                  $scope.data = {
                    ttExento: {
                      exentoId: $scope.ttExento.exentoId
                    }
                  }
                  $scope.mostrarAgregar = true;
                  $scope.ayuda = 'Seleccione el tipo de actualización';
                }

                $scope.cancelarActualizacion = function () {
                  $scope.data = null;
                  $scope.mostrarAgregar = false;
                }

                $scope.setTipoActualizacion = function ( item ) {
                  if (item.tipoActualizacionId == 1) {
                    $scope.ayuda = 'Para actualizar solo volúmen del exento, según una nueva estimación de volumen.<br/>'
                    + '2. La Constancia de Registro debe encontrarse vigente.';
                  } else {
                    $scope.ayuda = '1. Para actualizar todos los datos del exento, según la última Constancia de Registro.\n'
                    + '2. Rodales, áreas y especies deben coincidir con la última Constancia de Registro.'
                    + '3. La Constancia de Registro debe encontrarse vigente.';
                  }
                }

                $scope.submitFormAgregarActualizacion = function () {
                  swal({
                    title: '¿Está seguro de iniciar una gestión de actualización?',
                    text: "Según el tipo de actualización que seleccione así serán las opciones que tendrá disponible",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var data = angular.copy($scope.data);
                        exentoService.agregarActualizacion(data).then(function (res) {
                          if (res.status == 1) {
                            var row = {
                              actualizacionExentoId: res.data
                            }
                            $scope.editarActualizacion(row);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.agregarInvolucramiento = function () {
                  swal({
                    title: '¿Está seguro de iniciar una gestión de actualización?',
                    text: "Para iniciar una actualización debe el registro ya debe tener carga inicial y usted figurar como representante legal o titular del exento en el sistema de SERNAF",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        $state.go('^.buscar');
                      }
                    });
                }

                $scope.generarSolicitudActualizacionExento = function (item) {
                  exentoService.getActualizacion(item.actualizacionExentoId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      data.fechaSolicitud = new Date(data.fechaSolicitud);
                      utilsService.generarSolicitudActualizacionExento(data, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.resolucionSolicitudActualizacion = function (item) {
                  tareaService.resolucionSolicitudActualizacionExento(item.actualizacionExentoId).then(function (res) {
                    if (res.status == 1) {
                      if ( res.status == 1 ) {
                        var data = res.data;
                        if (data.ttActualizacionExento.thActualizacionExento == null) {
                          data.ttActualizacionExento.thActualizacionExento = {
                            ventas: []
                          }
                        }
                        var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                        if (ventas == null) {
                          ventas = [];
                        }
                        for (let i = 0; i < ventas.length; i++) {
                          var element = ventas[i];
                          element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                        }
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        data.observaciones = JSON.parse(data.observaciones);
                        if (data.ttActualizacionExento.tipoActualizacionId == 1) {
                          var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                          var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          if (data.aprobado == 1) {
                            data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                            data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                          } 
                          utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

              }]
          })
          .state('index.exento.buscar', {
            url: '/buscar',
            templateUrl: 'app/exento/exento.add.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataTipoPropietario: ['tipopropietarioService',
                function (tipopropietarioService) {
                  return tipopropietarioService.listaActiva();
                }],
              dataPersona: ['personaService', 'authService',
                function (personaService, authService) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  return personaService.get(loginData.personaId);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado', 'dataTipoPropietario',
              'ngDialog', 'catalogosService', 'dataPersona',
              function ($scope, $state, toastr, exentoService, dataEstado, dataTipoPropietario,
                ngDialog, catalogosService, dataPersona) {

                $scope.titulo = 'Adhesión a exento para solicitar actualización';
                $scope.cancelarAgregar = function () {
                  $state.go('^.actualizar');
                }

                $scope.dataPersona = dataPersona.data[0];
                $scope.data = {
                  estadoId: 1,
                  tcTipoPropietario: {},
                  propietarios: [],
                  representantes: []
                };
                $scope.dataEstado = dataEstado.data;
                $scope.dataTipoPropietario = dataTipoPropietario.data;

                $scope.submitFormBuscar = function (isValid) {
                  $scope.data.representantes = [];
                  $scope.data.propietarios = [];
                  if (isValid) {
                    catalogosService.getDatosRegistro($scope.data.codigo.trim().toUpperCase()).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var propietarios = data.propietarios;
                        if (propietarios == null) {
                          propietarios = [];
                        }
                        //cambiar a valor falso en produccion
                        var encontrado = false;
                        for (var i = 0; i < propietarios.length; i++) {
                          if (propietarios[i].noCedula != null) {
                            if ($scope.dataPersona.cui == propietarios[i].noCedula.trim()) {
                              encontrado = true;
                              break;
                            }
                          }
                        }
                        // if ($scope.loginData.personaId == 104 || $scope.loginData.personaId == 435) {
                        //   encontrado = true;
                        // }
                        if (!encontrado) {
                          var representantes = data.representantes;
                          if (representantes == null) {
                            representantes = [];
                          }
                          for (var i = 0; i < representantes.length; i++) {
                            if (representantes[i].noCedula != null) {
                              if ($scope.dataPersona.cui == representantes[i].noCedula.trim()) {
                                encontrado = true;
                                break;
                              }
                            }
                          }
                        }
                        if (encontrado) {
                          $scope.dataFinca = data.finca;
                          $scope.data.area = data.finca.areaBosque;
                          $scope.data.tipoRegistroDesc = data.tipoRegistroDesc;
                          var tipoPropietarioId = 1;
                          if (data.representantes != null) {
                            if (data.representantes.length > 0) {
                              tipoPropietarioId = 2;
                            }
                          }
                          $scope.data.tcTipoPropietario.tipoPropietarioId = tipoPropietarioId;
                          var tcPersona = angular.copy($scope.dataPersona);
                          tcPersona.foto = null;
                          $scope.nombres = [];
                          if (tipoPropietarioId == 1) {
                            $scope.data.propietarios.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                            $scope.nombres.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                          } else {
                            var row = {
                              personaDesc: propietarios[0].nombre,
                              cui: propietarios[0].nit
                            }
                            $scope.nombres.push(
                              {
                                tcPersona: row
                              }
                            );
                            $scope.data.representantes.push(
                              {
                                tcPersona: tcPersona
                              }
                            );
                            $scope.mostrarRepresentantes = true;
                          }
                          $scope.data.tcPersonaCrea = tcPersona;
                          swal('Datos correctos', 'Cierre este mensaje y luego pulse guardar para continuar');
                        } else {
                          swal('Datos inconsistentes', 'Usted no figura como propietario o representante legal en el exento, es probable que no tenga actualizado sus datos (DPI y NIT) en el Registro Nacional Forestal. Favor de verificar');
                        }
                        toastr.success(res.message);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.submitFormAgregar = function (isValid) {
                  if ($scope.data.propietarios.length > 0 || $scope.data.representantes.length > 0) {
                    exentoService.addParaActualizar($scope.data).then(function (res) {
                      if (res.status == "OK") {
                        toastr.success(res.message);
                        $state.go('^.actualizar');
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.exento.editar', {
            url: '/:actualizacionExentoId/editar',
            templateUrl: 'app/exento/exento.editar.tpl.html',
            resolve: {
              dataExento: ['exentoService', '$stateParams', 'authService', 'toastr', '$state', '$base64',
                function (exentoService, $stateParams, authService, toastr, $state, $base64) {
                  return exentoService.getActualizacion($base64.decode($stateParams.actualizacionExentoId));
                }],
              dataDepartamento: ['departamentoService',
                function (departamentoService) {
                  return departamentoService.listaPorPais(1);
                }],
              dataTipoPropietario: ['tipopropietarioService', '$stateParams',
                function (tipopropietarioService, $stateParams) {
                  return tipopropietarioService.listaActiva();
                }],
              dataLibro: ['libroService',
                function (libroService) {
                  return libroService.listaActiva();
                }],
              dataTipoPropiedad: ['tipopropiedadService',
                function (tipopropiedadService) {
                  return tipopropiedadService.listaActivaExento();
                }],
              dataRnf: ['catalogosService', 'dataExento',
                function (catalogosService, dataExento) {
                  var data = dataExento.data;
                  return catalogosService.getDatosRegistro(data.ttExento.codigo.trim());
                }],
              dataTipoNotificacion: ['tiponotificacionService', '$stateParams',
                function (tiponotificacionService, $stateParams) {
                  return tiponotificacionService.listaActiva();
                }],
              dataTipoEntidad: ['tipoentidadService', '$stateParams',
                function (tipoentidadService, $stateParams) {
                  return tipoentidadService.listaActiva();
                }],
              dataEspecie: ['especieService',
                function (especieService) {
                  return especieService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataExento', 'dataDepartamento', 'dataTipoPropietario', 'municipioService',
              'dataLibro', 'dataTipoPropiedad', 'ngDialog', 'catalogosService', 'dataRnf', 'dataTipoNotificacion', 'authService', 'utilsService',
              'dataTipoEntidad', 'Upload', 'dataEspecie', '$base64',
              function ($scope, $state, toastr, exentoService, dataExento, dataDepartamento, dataTipoPropietario, municipioService,
                dataLibro, dataTipoPropiedad, ngDialog, catalogosService, dataRnf, dataTipoNotificacion, authService, utilsService,
                dataTipoEntidad, Upload, dataEspecie, $base64) {

                authService.removeLocalData('fincaRfn');
                authService.removeLocalData('dataEspecie');
                authService.saveLocalData('dataEspecie', dataEspecie.data);

                $scope.ttActualizacionExento = dataExento.data;
                $scope.dataDepartamentoFinca = dataDepartamento.data;
                $scope.dataTipoPropiedad = dataTipoPropiedad.data;
                if (dataRnf.status == 'OK') {
                  $scope.dataRnf = dataRnf.data[0];
                  if ($scope.dataRnf.esJuridico) {
                    if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario) {
                      if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 1) {
                        $scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId = 2;
                        if ($scope.ttActualizacionExento.ttExento.tcTipoPropietario.tipoPropiedadId == 1) {
                          $scope.ttActualizacionExento.ttExento.tcTipoPropietario.tipoPropiedadId = 2;
                        }
                      }
                    }
                  }
                } else {
                  toastr.error(dataRnf.message);
                }
                if ($scope.ttActualizacionExento.fechaActualizacion == null) {
                  $scope.ttActualizacionExento.fechaActualizacion = new Date();
                } else {
                  $scope.ttActualizacionExento.fechaActualizacion = new Date($scope.ttActualizacionExento.fechaActualizacion);
                }
                if ($scope.ttActualizacionExento.fechaVencimiento == null) {
                  $scope.ttActualizacionExento.fechaVencimiento = new Date();
                } else {
                  $scope.ttActualizacionExento.fechaVencimiento = new Date($scope.ttActualizacionExento.fechaVencimiento);
                }
                $scope.dataLibro = dataLibro.data;
                $scope.dataDepartamento = dataDepartamento.data;
                $scope.dataTipoPropietario = dataTipoPropietario.data;
                $scope.dataTipoNotificacion = dataTipoNotificacion.data;
                $scope.dataTipoEntidad = dataTipoEntidad.data;
                $scope.requeridoPropietario = false;
                $scope.activaBoton = false;
                authService.saveLocalData('listaActualizacionId', $scope.ttActualizacionExento.ttExento.exentoId);
                if ($scope.ttActualizacionExento.calculado == null) {
                  $scope.ttActualizacionExento.calculado = false;
                }

                $scope.mostrarVerCertificado = false;

                if ($scope.ttActualizacionExento.archivoCertificado != null && $scope.ttActualizacionExento.archivoCertificado.length > 0) {
                  $scope.mostrarVerCertificado = true;
                }

                $scope.opcionesPanel = function (paso) {
                  switch (paso) {
                    case 2:
                      if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                        $scope.panel = {
                          openPropietarios: true,
                          propietarios: false,
                          openNotificacion: false,
                          notificacion: true,
                          openFinca: false,
                          finca: true
                        }
                      } else {
                        $scope.panel = {
                          openPropietarios: true,
                          openFinca: true,
                          finca: true
                        }
                      }
                      break;
                    case 3:
                      if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                        $scope.panel = {
                          openPropietarios: true,
                          propietarios: false,
                          openNotificacion: true,
                          notificacion: false,
                          openFinca: false,
                          finca: true
                        }
                      }
                      break;
                    case 4:
                      if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                        $scope.panel = {
                          openPropietarios: true,
                          propietarios: false,
                          openNotificacion: true,
                          notificacion: false,
                          openFinca: true,
                          finca: false
                        }
                      }
                      break;
                    default:
                      $scope.panel = {
                        openPropietarios: false,
                        propietarios: true,
                        openNotificacion: false,
                        notificacion: true,
                        openFinca: false,
                        finca: true
                      }
                  }
                }

                $scope.opcionesPanel(1);

                $scope.setTipoEntidad = function (item) {
                  $scope.ttActualizacionExento.ttExento.ttJuridicaExento.tcTipoEntidad = item;
                }

                if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                  if ($scope.ttActualizacionExento.ttExento.ttJuridicaExento == null) {
                    $scope.ttActualizacionExento.ttExento.ttJuridicaExento = {}
                    if ($scope.dataRnf.propietarios.length > 0) {
                      var row = $scope.dataRnf.propietarios[0];
                      $scope.ttActualizacionExento.ttExento.ttJuridicaExento = {
                        nitEntidad: row.nit,
                        razonSocial: row.nombre,
                        nombreComercial: row.nombre,
                        tcTipoEntidad: $scope.dataTipoEntidad[0]
                      }
                      $scope.setTipoEntidad($scope.dataTipoEntidad[0]);
                      $scope.requeridoPropietario = true;
                    } else {
                      $scope.opcionesPanel(3);
                    }
                  } else {
                    $scope.opcionesPanel(3);
                  }
                }

                $scope.textoVigencia = 'Fecha de vigencia o fórmulas de especies';

                $scope.subirBoleta = function () {
                  ngDialog.open({
                    template: 'app/tarea/boleta.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueSubir',
                    name: 'dialogSubir',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', 'authService',
                      function ($scope, $state, toastr, authService) {

                        $scope.gridData = angular.copy($scope.gridOptionsSelection);
                        $scope.gridData.enableFiltering = false;

                        $scope.submitFormData = function (isValid) {
                          if (isValid) {
                            var data = $scope.gridData.data;
                            //obtener promedios por rodal
                            var rodales = [];
                            let calculado = false;
                            for (var i = 0; i < data.length; i++) {
                              var row = data[i];
                              var rodal = {
                                correlativo: row.RODAL,
                                area: row.AREA_RODAL,
                                especies: [],
                                parcelas: [],
                                areaMuestreada: 0,
                                tamanioParcela: row.TAMANIO_PARCELA == null ? 0 : row.TAMANIO_PARCELA,
                                enLinea: row.EN_LINEA == null ? 0 : row.EN_LINEA
                              }
                              if (parseInt(row.CALCULADO) == 1) {
                                calculado = true;
                              } else {
                                if (calculado) {
                                  toastr.error('No es posible mezclar datos calculados por el sistema y cálculados por el usuario');
                                  return;
                                }
                              }
                              var encontrado = false;
                              for (var j = 0; j < rodales.length; j++) {
                                if (rodales[j].correlativo == rodal.correlativo) {
                                  rodal = rodales[j];
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                rodales.push(rodal);
                              }
                              var tcEspecie = {
                                especieId: row.ESPECIE_ID,
                                nombreCientifico: row.NOMBRE_CIENTIFICO
                              };
                              var especie = {
                                tcEspecie: tcEspecie,
                                arbolesAutorizado: 1,
                                dap: parseFloat(row.DAP),
                                altura: parseFloat(row.ALTURA),
                                volumen: parseFloat(row.VOLUMEN),
                                areaBasal: parseFloat(row.AREA_BASAL),
                                tipoInventarioId: 1
                              }
                              encontrado = false;
                              for (var j = 0; j < rodal.especies.length; j++) {
                                if (rodal.especies[j].tcEspecie.especieId == especie.tcEspecie.especieId) {
                                  especie = rodal.especies[j];
                                  especie.arbolesAutorizado += 1;
                                  especie.dap += parseFloat(row.DAP);
                                  especie.altura += parseFloat(row.ALTURA);
                                  especie.volumen += parseFloat(row.VOLUMEN);
                                  especie.areaBasal += parseFloat(row.AREA_BASAL);
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                rodal.especies.push(especie);
                              }
                              if (row.PARCELA != null && row.TAMANIO_PARCELA != null) {
                                especie.tipoInventarioId = 2;
                                var parcela = {
                                  parcela: row.PARCELA,
                                  tamanioParcela: row.TAMANIO_PARCELA
                                }
                                encontrado = false;
                                for (var j = 0; j < rodal.parcelas.length; j++) {
                                  if (rodal.parcelas[j].parcela == parcela.parcela) {
                                    encontrado = true;
                                    break;
                                  }
                                }
                                if (!encontrado) {
                                  rodal.parcelas.push(parcela);
                                }
                              }
                            }
                            $scope.ttActualizacionExento.calculado = calculado;
                            if ($scope.ttActualizacionExento.calculado) {
                              if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                                $scope.textoVigencia = 'Fecha de vigencia e información de fórmulas';
                              } else {
                                $scope.textoVigencia = 'Información de fórmulas';
                              }
                            } else {
                              $scope.textoVigencia = 'Fecha de vigencia';
                            }
                            for (var i = 0; i < rodales.length; i++) {
                              var rodal = rodales[i];
                              for (var j = 0; j < rodal.especies.length; j++) {
                                var especie = rodal.especies[j];
                                var valor = 0;
                                if (especie.tipoInventarioId == 2) {
                                  if (rodal.enLinea == 1) {
                                    toastr.error('No es posible hacer un muestreo para árboles en línea, favor de revisar la línea ' + rodal.correlativo);
                                    return;
                                  }
                                  rodal.areaMuestreada = (rodal.parcelas.length * parseFloat(rodal.tamanioParcela)) / 10000;
                                  valor = parseFloat(especie.areaBasal) / parseFloat(rodal.areaMuestreada);
                                  valor = valor * parseFloat(rodal.area);
                                  especie.abr = parseFloat(valor).toFixed(4);
                                  valor = parseFloat(especie.volumen) / parseFloat(rodal.areaMuestreada);
                                  valor = valor * parseFloat(rodal.area);
                                  especie.volr = parseFloat(valor).toFixed(4);
                                  valor = parseFloat(especie.arbolesAutorizado) / parseFloat(rodal.areaMuestreada);
                                  valor = valor * parseFloat(rodal.area);
                                  especie.arbolr = Math.ceil(valor);
                                } else {
                                  especie.abr = parseFloat(especie.areaBasal).toFixed(4);
                                  especie.volr = parseFloat(especie.volumen).toFixed(4);
                                  especie.arbolr = especie.arbolesAutorizado;
                                }
                                valor = parseFloat(especie.altura) / especie.arbolesAutorizado;
                                especie.alturaPromedio = parseFloat(valor).toFixed(2);
                                valor = parseFloat(especie.dap) / especie.arbolesAutorizado;
                                especie.dapPromedio = parseFloat(valor).toFixed(2);
                                especie.volumen = parseFloat(especie.volr).toFixed(4);
                                if (rodal.area > 0) {
                                  if (rodal.enLinea == 1) {
                                    especie.volumenHectaria = 0;
                                  } else {
                                    especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                                  }
                                } else {
                                  especie.volumenHectaria = 0;
                                }
                                especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(4);
                              }
                            }
                            $scope.ttActualizacionExento.rodales = rodales;
                            $scope.ttActualizacionExento.boleta = JSON.stringify(data);
                            ngDialog.close();
                          }
                        } //fin submitFormData

                      }]
                  })
                }

                $scope.agregarRodalesActualizacion = function () {
                  if ($scope.ttActualizacionExento.rodales == null) {
                    $scope.ttActualizacionExento.rodales = [];
                  }
                  if ($scope.ttActualizacionExento.rodales.length <= 0) {
                    toastr.error('Debe agregar al menos un rodal y una especie');
                    return;
                  }
                  var tmp = angular.copy($scope.ttActualizacionExento);
                  var ttActualizacionExento = {
                    rodales: tmp.rodales,
                    boleta: tmp.boleta,
                    actualizacionExentoId: tmp.actualizacionExentoId,
                    calculado: tmp.calculado
                  };
                  exentoService.guardarRodalesActualizacion(ttActualizacionExento, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                    if (response.status == 1) {
                      $scope.opcionesPanel(3);
                      toastr.success(response.message);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setPropietarios = function () {
                  if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                    var data = [
                      {
                        personaId: 1,
                        personaDesc: $scope.ttActualizacionExento.ttExento.ttJuridicaExento.razonSocial
                      }
                    ];
                    $scope.dataPersona = data;
                  } else {
                    if ($scope.ttActualizacionExento.ttExento.propietarios != undefined) {
                      if ($scope.ttActualizacionExento.ttExento.propietarios.length > 0) {
                        var data = [];
                        for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                          if ($scope.ttActualizacionExento.ttExento.propietarios[i].representanteLegal == 0 
                            || ($scope.ttActualizacionExento.ttExento.propietarios[i].representanteLegal == 1 && $scope.ttActualizacionExento.propietarios[i].soloRepresenta == 0)) {
                            data.push(angular.copy($scope.ttActualizacionExento.ttExento.propietarios[i].tcPersona));
                          }
                        }
                        $scope.dataPersona = data;
                      }
                    }
                  }
                }

                $scope.validarPanelEntidadEdicion = function (isValid) {
                  if (isValid) {
                    var tmp = $scope.ttActualizacionExento;
                    var ttExento = {
                      exentoId: tmp.ttExento.exentoId,
                      estadoId: tmp.ttExento.estadoId,
                      tcPersonaCrea: tmp.ttExento.tcPersonaCrea,
                      ttInformacionExento: tmp.ttExento.ttInformacionExento
                    };
                    ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var item = tmp.ttExento.ttJuridicaExento;
                    item.ttExento = ttExento;
                    item.registro = tmp.registro;
                    exentoService.agregarDatosJuridica(item).then(function (response) {
                      if (response.status == 'OK') {
                        $scope.ttActualizacionExento.ttExento.ttJuridicaExento = response.data[0];
                        if ($scope.ttActualizacionExento.ttExento.ttJuridicaExento.ttExento != null) {
                          delete $scope.ttActualizacionExento.ttExento.ttJuridicaExento.ttExento;
                        }
                        toastr.success(response.message);
                        $scope.setPropietarios();
                        $scope.opcionesPanel(3);
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    if ($scope.ttActualizacionExento.ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
                      toastr.success('Agregue las personas involucradas al exento');
                    }
                  }
                }

                $scope.buscarPersona = function (tipo) {
                  $scope.tipoPersona = tipo;
                  var continuar = true;
                  if (continuar) {
                    $scope.dataTipoBusqueda = [
                      { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                      { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                      { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                    ]
                    $scope.busqueda = {
                      tipoBusquedaId: 3,
                      tituloBusqueda: 'Ingrese el nombre de la persona',
                      placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                    }

                    ngDialog.open({
                      template: 'app/gestion/buscar.persona.tpl.html',
                      className: 'ngdialog-theme-flat',
                      appendClassName: 'ngdialog-flat',
                      closeByDocument: false,
                      closeByEscape: true,
                      closeByNavigation: true,
                      scope: $scope,
                      id: 'uniqueEdit',
                      name: 'dialogEditar',
                      resolve: {
                      },
                      controller: ['$scope', '$state', 'toastr', 'personaService',
                        function ($scope, $state, toastr, personaService) {

                          $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                          $scope.gridPersona.columnDefs = [
                            { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                            { field: 'cui', name: 'cui', displayName: 'Identificación' },
                            { field: 'correo', name: 'correo', displayName: 'Correo' },
                            { field: 'nit', name: 'nit', displayName: 'NIT' },
                            {
                              name: 'Seleccionar', enableFiltering: false,
                              cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                            }
                          ];
                          $scope.gridPersona.data = [];

                          $scope.setTipoBusqueda = function (item) {
                            switch (parseInt(item.tipoBusquedaId)) {
                              case 1:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                                break;
                              case 2:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                                $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                                break;
                              case 3:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                                $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                                break;
                              default:
                                $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                            }
                          }

                          $scope.keyupFiltro = function (event) {
                            if (event.keyCode == 13) {
                              $scope.submitBuscarPersona();
                            }
                          }

                          $scope.submitBuscarPersona = function () {
                            var isValid = true;
                            if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                              isValid = null;
                              toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                            }
                            if (isValid) {
                              personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                                if (response.status == 'OK') {
                                  toastr.success(response.message);
                                  $scope.gridPersona.data = response.data;
                                } else {
                                  toastr.error(response.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }

                          $scope.setPersona = function (item) {
                            var soloRepresenta = 1;
                            if ($scope.tipoPersona == 0) {
                              for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                                if ($scope.ttActualizacionExento.ttExento.propietarios[i].tcPersona.personaId == item.personaId) {
                                  ngDialog.close();
                                  return;
                                }
                              }
                              soloRepresenta = 0;
                            } else {
                              for (var i = 0; i < $scope.ttActualizacionExento.ttExento.representantes.length; i++) {
                                if ($scope.ttActualizacionExento.ttExento.representantes[i].tcPersona.personaId == item.personaId) {
                                  ngDialog.close();
                                  return;
                                }
                              }
                              for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                                if ($scope.ttActualizacionExento.ttExento.propietarios[i].tcPersona.personaId == item.personaId) {
                                  soloRepresenta = 0;
                                  break;
                                }
                              }
                            }
                            delete item.tcUsuario;
                            var data = {
                              personaExentoId: 0,
                              tcPersona: item,
                              ttExento: {
                                exentoId: $scope.ttActualizacionExento.ttExento.exentoId,
                                estadoId: $scope.ttActualizacionExento.ttExento.estadoId,
                                tcPersonaCrea: {
                                  personaId: $scope.loginData.personaId
                                }
                              },
                              representanteLegal: $scope.tipoPersona,
                              soloRepresenta: soloRepresenta,
                              registro: $scope.ttActualizacionExento.registro
                            }
                            exentoService.agregarPersonaActualizacion(data, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                              if (response.status == 1) {
                                registro = response.data;
                                let persona = registro.tcPersona;
                                persona.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                var encontrado = false;
                                if ($scope.tipoPersona == 0) {
                                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                                    if ($scope.ttActualizacionExento.ttExento.propietarios[i].tcPersona.personaId == persona.personaId) {
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    $scope.ttActualizacionExento.ttExento.propietarios.push(registro);
                                  }
                                  $scope.setPropietarios();
                                } else {
                                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.representantes.length; i++) {
                                    if ($scope.ttActualizacionExento.ttExento.representantes[i].tcPersona.personaId == persona.personaId) {
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    $scope.ttActualizacionExento.ttExento.representantes.push(registro);
                                  }
                                }
                                toastr.success(response.message);
                                ngDialog.close();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }

                          $scope.setIdioma = function (item) {

                          }

                          $scope.setSexo = function (item) {

                          }

                          $scope.setEstadoCivil = function (item) {

                          }

                          $scope.setCultura = function (item) {

                          }

                        }]
                    })
                  }
                }

                $scope.validarPropietarioFinca = function (personaId) {
                  var hay = false;
                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.fincas.length; i++) {
                    for (var j = 0; j < $scope.ttActualizacionExento.ttExento.fincas[i].propietarios.length; j++) {
                      if ($scope.ttActualizacionExento.ttExento.fincas[i].propietarios[j].tcPersona.personaId == personaId) {
                        hay = true;
                        break;
                      }
                    }
                    if (hay) {
                      break;
                    }
                  }
                  return hay;
                }

                $scope.quitarPersonaExento = function (item, tipo) {
                  if (tipo != 1) {
                    if ($scope.validarPropietarioFinca(item.tcPersona.personaId)) {
                      toastr.error('No es posible quitar a la persona debido a que ya se encuentra como propietario de una finca, debe primero quitarlo');
                      return;
                    }
                  }
                  var data = item;
                  var tmp = $scope.ttActualizacionExento.ttExento;
                  data.ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    tcPersonaCrea: tmp.tcPersonaCrea
                  };
                  data.representanteLegal = tipo;
                  data.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  exentoService.quitarPersonaActualizacion(data, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                    if (response.status == 1) {
                      if (tipo == 0) {
                        $scope.ttActualizacionExento.ttExento.propietarios.splice($scope.ttActualizacionExento.ttExento.propietarios.indexOf(item), 1);
                      } else {
                        $scope.ttActualizacionExento.ttExento.representantes.splice($scope.ttActualizacionExento.ttExento.representantes.indexOf(item), 1);
                      }
                      if (tipo == 1) { //se esta quitando como representante
                        item.representanteLegal = 0;
                        item.soloRepresenta = 0;
                        var conteo = 0;
                        for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                          if ($scope.ttActualizacionExento.ttExento.propietarios[i].representanteLegal == 1) {
                            conteo++;
                            break;
                          }
                        }
                      }
                      toastr.success(response.message);
                      $scope.setPropietarios();
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                var representantes = $scope.ttActualizacionExento.ttExento.representantes;
                for (var i = 0; i < representantes.length; i++) {
                  var element = representantes[i];
                  if (element.mandatoInicio != null) {
                    var partes = element.mandatoInicio.toString().split('-');
                    element.mandatoInicio = new Date(partes[0], parseInt(partes[1]) - 1, partes[2]);
                  }
                  if (element.mandatoFin != null) {
                    var partes = element.mandatoFin.toString().split('-');
                    element.mandatoFin = new Date(partes[0], parseInt(partes[1]) - 1, partes[2]);
                  }
                }

                $scope.setMandatarioActualizacion = function (item) {
                  $scope.mostrarGuardarMandatarios = true;
                  toastr.warning('Use el botón correspondiente de esta sección para guardar los cambios de lo contrario puede ocasionarle futuros problemas');
                }

                $scope.validarPanelSolicitanteAcutualizacion = function (isValid) {
                  if (isValid) {
                    swal({
                      title: "Confirmación",
                      text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar y puede ocasionarle problemas si la información proporcionada no es correcta",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          exentoService.setMandatariosActualizacion($scope.ttActualizacionExento.ttExento.representantes).then(function (response) {
                            if (response.status == 1) {
                              toastr.success(response.message);
                            } else {
                              toastr.error(response.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    toastr.error('Todos los campos de inicio y fin de mandato son obligatorios');
                  }
                }

                $scope.setLibroFinca = function (item, finca) {
                  finca.tcLibro = item;
                  finca.tcMunicipio = null;
                }

                $scope.setDepartamentoFinca = function (item, finca, limpiar) {
                  if (limpiar == undefined) {
                    limpiar = true;
                  }
                  if (limpiar) {
                    finca.tcMunicipio = null;
                  }
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId != 3) {
                      finca.tcLibro = null;
                      municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                        if (res.status == "OK") {
                          finca.dataMunicipio = res.data;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }
                }

                $scope.setPropietarios();

                //cargar municipio de las fincas ya cargadas
                if ($scope.ttActualizacionExento.ttExento.fincas != null) {
                  var encontrado = false;
                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.fincas.length; i++) {
                    if ($scope.ttActualizacionExento.ttExento.fincas[i].tcMunicipioEmite != null) {
                      $scope.setDepartamentoFinca({ departamentoId: $scope.ttActualizacionExento.ttExento.fincas[i].tcMunicipioEmite.tcDepartamento.departamentoId }, 
                        $scope.ttActualizacionExento.ttExento.fincas[i], false);
                    } else {
                      $scope.ttActualizacionExento.ttExento.fincas[i].tcMunicipioEmite = {
                        municipioId: null,
                        tcDepartamento: {
                          departamentoId: null
                        }
                      }
                    }
                    if ($scope.ttActualizacionExento.ttExento.fincas[i].propietarios == null) {
                      $scope.ttActualizacionExento.ttExento.fincas[i].propietarios = [];
                    }
                    $scope.ttActualizacionExento.ttExento.fincas[i].personas = [];
                    for (var j = 0; j < $scope.ttActualizacionExento.ttExento.fincas[i].propietarios.length; j++) {
                      var tcPersona = $scope.ttActualizacionExento.ttExento.fincas[i].propietarios[j].tcPersona;
                      $scope.ttActualizacionExento.ttExento.fincas[i].personas.push(tcPersona);
                    }
                    if ($scope.ttActualizacionExento.ttExento.fincas[i].fechaEmision != null) {
                      $scope.ttActualizacionExento.ttExento.fincas[i].fechaEmision = new Date($scope.ttActualizacionExento.ttExento.fincas[i].fechaEmision);
                    }
                  }
                  if ($scope.ttActualizacionExento.ttExento.fincas.length > 0) {
                    $scope.opcionesPanel(4);
                  } else {
                    if ($scope.ttActualizacionExento.ttExento.notificaciones != null) {
                      if ($scope.ttActualizacionExento.ttExento.notificaciones.length > 0) {
                        if ($scope.ttActualizacionExento.ttExento.notificaciones[0].notificacionExentoDesc == null) {
                          if ($scope.ttActualizacionExento.ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
                            $scope.opcionesPanel(1);
                          } else {
                            $scope.opcionesPanel(3);
                          }
                        } else {
                          $scope.opcionesPanel(3);
                        }
                      } else {
                        if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                          $scope.opcionesPanel(1);
                        } else {
                          $scope.opcionesPanel(3);
                        }
                      }
                    } else {
                      if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                        $scope.opcionesPanel(1);
                      } else {
                        $scope.opcionesPanel(3);
                      }
                    }
                  }
                }

                $scope.agregarPersona = function (tipo) {
                  $scope.tipoPersona = tipo;
                  $scope.tituloPersona = "Agregar datos de la persona";
                  delete $scope.persona;
                  ngDialog.open({
                    template: 'app/persona/persona.add.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataOcupacion: ['ocupacionService',
                        function (ocupacionService) {
                          return ocupacionService.listaActiva();
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload', 'dataOcupacion',
                      function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload, dataOcupacion) {
                        $scope.dataOcupacion = dataOcupacion.data;
                        $scope.persona = {
                          estadoId: 1
                        }

                        $scope.direccion = {
                          paisId: 1
                        }

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.persona.municipioId = null;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.persona.municipioId = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.onFileSelect = function (file, tipoAnexoId) {
                          Upload.upload({
                            url: appSettings.urlServiceBase + 'file/base64/imagen',
                            method: 'POST',
                            file: file,
                            sendFieldsAs: 'form',
                            fields: {
                              data: []
                            }
                          }).then(function (resp) {
                            var data = resp.data;
                            if (data.status == 'OK') {
                              var obj = data.data[0];
                              $scope.image = obj.data;
                              $scope.persona.foto = obj.data;
                            } else {
                              toastr.error(resp.data.message)
                            }
                          }, function (resp) {
                            toastr.error('Error status: ' + resp.status);
                          }, function (evt) {
                            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                            $scope.avance = progressPercentage;
                          });
                        }

                        $scope.submitFormPersona = function (isValid) {
                          if (isValid) {
                            if ($scope.persona.foto == null) {
                              /*isValid = false;
                              toastr.error('Debe indicar una foto');*/
                              $scope.validoFoto = 1;
                            }
                            if ($scope.validoFoto == 0 && isValid) {
                              isValid = false;
                              toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                            }
                          }
                          if (isValid) {
                            var item = $scope.persona;
                            personaService.add(item).then(function (res) {
                              if (res.status == 'OK') {
                                item = res.data[0];
                                var soloRepresenta = 1;
                                if ($scope.tipoPersona == 0) {
                                  soloRepresenta = 0;
                                } else {
                                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.propietarios.length; i++) {
                                    if ($scope.ttActualizacionExento.ttExento.propietarios[i].tcPersona.personaId == item.personaId) {
                                      soloRepresenta = 0;
                                      break;
                                    }
                                  }
                                }
                                var tmp = $scope.ttActualizacionExento.ttExento;
                                var data = {
                                  personaExentoId: 0,
                                  tcPersona: item,
                                  ttExento: {
                                    exentoId: tmp.exentoId,
                                    tcTipoExento: tmp.tcTipoExento,
                                    estadoId: tmp.estadoId,
                                    tcPersonaCrea: tmp.tcPersonaCrea,
                                    tcTipoBosque: tmp.tcTipoBosque,
                                    tcElaborador: tmp.tcElaborador,
                                    ttTipoPropietarioExento: tmp.ttTipoPropietarioExento,
                                    personaAnulaId: $scope.loginData.personaId
                                  },
                                  representanteLegal: $scope.tipoPersona,
                                  soloRepresenta: soloRepresenta,
                                  registro: $scope.ttActualizacionExento.registro
                                }
                                exentoService.agregarPersona(data).then(function (response) {
                                  if (response.status == 'OK') {
                                    registro = response.data[0];
                                    persona = registro.tcPersona;
                                    registro.tcPersona = item;
                                    if (parseInt($scope.tipoPersona) == 1) {
                                      $scope.ttActualizacionExento.ttExento.representantes.push(registro);
                                    } else {
                                      item.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                      $scope.ttActualizacionExento.ttExento.propietarios.push(registro);
                                      $scope.setPropietarios();
                                    }
                                    toastr.success(res.message);
                                  } else {
                                    toastr.error(response.message);
                                  }
                                  ngDialog.close();
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                        $scope.setIdioma = function (item) {
                          $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                        }

                        $scope.setSexo = function (item) {
                          $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                        }

                        $scope.setEstadoCivil = function (item) {
                          $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                        }

                        $scope.setCultura = function (item) {
                          $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                        }

                      }]
                  })
                }

                $scope.cargarListaMunicipio = function (item, notificacion, limpiar) {
                  if (limpiar == null) {
                    notificacion.tcMunicipio = null;
                  }
                  municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                    if (res.status == "OK") {
                      notificacion.dataMunicipio = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setMunicipio = function (item, notificacion) {
                  notificacion.tcMunicipio = item;
                }

                var obligatorio = [];
                for (var i = 0; i < $scope.dataTipoNotificacion.length; i++) {
                  if ($scope.dataTipoNotificacion[i].esObligatorio == 1) {
                    obligatorio.push($scope.dataTipoNotificacion[i]);
                  }
                }
                var tmp = $scope.ttActualizacionExento;
                if ($scope.ttActualizacionExento.ttExento.notificaciones != null) {
                  var abrirFinca = false;
                  for (var j = 0; j < $scope.ttActualizacionExento.ttExento.notificaciones.length; j++) {
                    notificacion = $scope.ttActualizacionExento.ttExento.notificaciones[j];
                    if (notificacion.tcTipoNotificacion.esDireccion == 1) {
                      notificacion.departamentoId = notificacion.tcMunicipio.tcDepartamento.departamentoId;
                      $scope.cargarListaMunicipio({ departamentoId: notificacion.departamentoId }, notificacion, true);
                      abrirFinca = true;
                    }
                  }
                  if (abrirFinca) {
                    $scope.opcionesPanel(4);
                  }
                  var encontrado = false;
                  for (var i = 0; i < obligatorio.length; i++) {
                    encontrado = false;
                    for (var j = 0; j < $scope.ttActualizacionExento.ttExento.notificaciones.length; j++) {
                      if ($scope.ttActualizacionExento.ttExento.notificaciones[j].ttExento == null) {
                        $scope.ttActualizacionExento.ttExento.notificaciones[j].ttExento = {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        }
                      }
                      if ($scope.ttActualizacionExento.ttExento.notificaciones[j].tcTipoNotificacion.tipoNotificacionId == obligatorio[i].tipoNotificacionId) {
                        encontrado = true;
                        break;
                      }
                    }
                    if (!encontrado) {
                      var item = {
                        ttExento: {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        },
                        tcTipoNotificacion: obligatorio[i],
                        notificacionExentoDesc: null,
                        estadoId: 1
                      }
                      $scope.ttActualizacionExento.ttExento.notificaciones.push(item);
                    }
                  }
                } else {
                  $scope.ttActualizacionExento.ttExento.notificaciones = [];
                  for (var i = 0; i < obligatorio.length; i++) {
                    if (obligatorio[i].esObligatorio == 1) {
                      var item = {
                        ttExento: {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea
                        },
                        tcTipoNotificacion: obligatorio[i],
                        notificacionExentoDesc: null,
                        estadoId: 1
                      }
                      $scope.ttActualizacionExento.ttExento.notificaciones.push(item);
                    }
                  }
                }

                /*Panel notificacion*/
                $scope.agregarNotificacion = function () {
                  ngDialog.open({
                    template: 'app/exento/exento.notificacion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        $scope.direccion = {
                          paisId: 1
                        }

                        var tmp = $scope.ttActualizacionExento;
                        $scope.notificacion = {
                          ttExento: {
                            exentoId: tmp.exentoId,
                            estadoId: tmp.estadoId,
                            tcPersonaCrea: tmp.tcPersonaCrea
                          },
                          tcTipoNotificacion: null
                        }

                        $scope.notificacion.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.notificacion.municipioId = null;
                          $scope.notificacion.paisDesc = item.paisDesc;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.notificacion.municipioId = null;
                          $scope.notificacion.departamentoDesc = item.departamentoDesc;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipio = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.setMunicipio = function (item) {
                          $scope.notificacion.tcMunicipio = item;
                        }

                        $scope.setTipoNotificacion = function (item) {
                          $scope.notificacion.tcTipoNotificacion = item;
                        }

                        $scope.submitFormNotificacionAdicional = function (isValid) {
                          if (isValid) {
                            if ($scope.ttActualizacionExento.ttExento.notificaciones == undefined) {
                              $scope.ttActualizacionExento.ttExento.notificaciones = [];
                            }
                            var data = $scope.notificacion;
                            data.registro = $scope.ttActualizacionExento.registro;
                            exentoService.agregarNotificacionActualizacion(data, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                              if (response.status == 1) {
                                $scope.notificacion.notificacionExentoId = response.data;
                                $scope.ttActualizacionExento.ttExento.notificaciones.push($scope.notificacion);
                                toastr.success(response.message);
                                ngDialog.close();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  })
                }

                $scope.capturarInformacionNotificacion = function (notificacion) {

                }

                $scope.agregarMunicipioNotificacion = function (notificacion) {
                  notificacion.tcMunicipio = {
                    municipioId: null
                  }
                  $scope.notificacion = notificacion;
                  ngDialog.open({
                    template: 'app/gestion/seleccionar.municipio.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueMunicipio',
                    name: 'dialogMunicipio',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        $scope.direccion = {
                          paisId: 1
                        }

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.direccion.departamentoId = null;
                          $scope.notificacion.tcMunicipio.municipioId = null;
                          departamentoService.listaPorPais(item.paisId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataDepartamento = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.submitFormNotificacionMunicipio = function (isValid) {
                          if (isValid) {
                            for (var i = 0; i < $scope.ttActualizacionExento.notificaciones.length; i++) {
                              if ($scope.ttActualizacionExento.notificaciones[i].notificacionGestionId == $scope.notificacion.notificacionGestionId) {
                                $scope.ttActualizacionExento.notificaciones[i] = $scope.notificacion;
                                break;
                              }
                            }
                            ngDialog.close();
                          }
                        }

                      }]
                  })
                }

                $scope.mostrarSeleccionarMunicipio = function (value) {
                  if (value == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.validarPanelNotificacion = function (isValid) {
                  if (isValid) {
                    var notificaciones = angular.copy($scope.ttActualizacionExento.ttExento.notificaciones);
                    for (var j = 0; j < notificaciones.length; j++) {
                      delete notificaciones[j].dataMunicipio;
                      if (notificaciones[j].tcTipoNotificacion.esObligatorio == 1) {
                        if (notificaciones[j].tcTipoNotificacion.esDireccion == 1 && notificaciones[j].tcMunicipio == null) {
                          isValid = false;
                          encontrado = false; //para que finalice la verificacion y muestre el mensaje de error
                        }
                        break;
                      }
                    }
                    if (!isValid) {
                      toastr.error("Se debe indicar todas las formas de notificación y seleccionar el municipio a las direcciones");
                    } else {
                      //enviar datos para actualizar o guardar
                      exentoService.actualizacionNotificacion(notificaciones, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                        if (response.status == 1) {
                          $scope.opcionesPanel(4);
                          toastr.success("Continue seleccionando o agregando la finca");
                        } else {
                          toastr.error(response.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    toastr.error("Debe rellenar todos los campos y verificar que el formato (teléfono, correo, etc.) sea el correcto");
                  }
                }

                $scope.borrarDetalleNotificacion = function (item) {
                  var notificaciones = $scope.ttActualizacionExento.ttExento.notificaciones;
                  var continuar = true;
                  if (item.tcTipoNotificacion.esObligatorio == 1) {
                    var conteo = 0;
                    for (var i = 0; i < notificaciones.length; i++) {
                      if (notificaciones[i].tcTipoNotificacion.tipoNotificacionId == item.tcTipoNotificacion.tipoNotificacionId) {
                        conteo++;
                      }
                    }
                    if (conteo < 2) {
                      continuar = false;
                      toastr.error('Este tipo de notificación es obligatorio, no es posible continuar');
                    }
                  }
                  if (continuar) {
                    var tmp = $scope.ttActualizacionExento.ttExento;
                    item.ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    };
                    item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    exentoService.quitarNotificacionActualizacion(item, $scope.ttActualizacionExento.ttExento.exentoId).then(function (response) {
                      if (response.status == 1) {
                        $scope.ttActualizacionExento.ttExento.notificaciones.splice($scope.ttActualizacionExento.ttExento.notificaciones.indexOf(item), 1);
                        toastr.success(response.message);
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }
                /*Fin panel notificacion*/

                /* Panel finca*/

                if ($scope.globales.fincaId != null) {
                  var tmp = $scope.ttActualizacionExento.ttExento;
                  var item = {
                    ttExento: {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    },
                    tcFinca: {
                      fincaId: $scope.globales.fincaId
                    },
                    fechaEmision: null
                  }
                  item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  item.registro = $scope.ttActualizacionExento.registro;
                  exentoService.agregarFincaActualizacion(item, tmp.exentoId).then(function (response) {
                    if (response.status == 1) {
                      $scope.globales.fincaId = null;
                      item = response.data;
                      item.ttExento = {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea,
                        fechaEmision: null
                      };
                      item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                      $scope.ttActualizacionExento.ttExento.fincas.push(item);
                      $scope.opcionesPanel(4);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.onSelectPersona = function (item, finca) {
                  if (finca.propietarios == undefined || finca.propietarios == null) {
                    finca.propietarios = [];
                  }
                  if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                    if (finca.propietarios.length == 0) {
                      finca.propietarios.push(
                        {
                          propietarioFincaId: 1,
                          tcPersona: {
                            personaId: 1,
                            personaDesc: $scope.ttActualizacionExento.ttExento.ttJuridicaExento.razonSocial
                          }
                        }
                      );
                    }
                    toastr.success('Propietario agregado');
                    return;
                  } else {
                    for (var i = 0; i < finca.propietarios.length; i++) {
                      if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                        toastr.success('Propietario agregado');
                        return;
                      }
                    }
                    finca.ttExento = {
                      exentoId: $scope.ttActualizacionExento.ttExento.exentoId,
                      estadoId: $scope.ttActualizacionExento.ttExento.estadoId,
                      tcPersonaCrea: $scope.ttActualizacionExento.ttExento.tcPersonaCrea
                    }
                    finca.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var data = {
                      ttFincaExento: finca,
                      tcPersona: item,
                      registro: $scope.ttActualizacionExento.registro
                    }
                    exentoService.agregarPropietarioFinca(data).then(function (response) {
                      if (response.status == 'OK') {
                        finca.propietarios.push(response.data[0]);
                      } else {
                        toastr.error(response.message);
                        finca.persona = finca.propietario;
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.onRemovePersona = function (item, finca) {
                  if ($scope.ttActualizacionExento.ttExento.ttInformacionExento.tcTipoPropietario.tipoPropietarioId == 2) {
                    finca.propietarios.splice(finca.propietarios.indexOf(item), 1);
                  } else {
                    var tmp = $scope.ttActualizacionExento.ttExento;
                    finca.ttExento = {
                      exentoId: tmp.exentoId,
                      estadoId: tmp.estadoId,
                      tcPersonaCrea: tmp.tcPersonaCrea
                    };
                    finca.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    var data = null;
                    for (var i = 0; i < finca.propietarios.length; i++) {
                      if (finca.propietarios[i].tcPersona.personaId == item.personaId) {
                        data = angular.copy(finca.propietarios[i]);
                        break;
                      }
                    }
                    data.ttFincaExento = angular.copy(finca);
                    var tmpPersonas = data.personas;
                    delete data.personas;
                    delete data.propietarios;
                    exentoService.quitarPropietarioFinca(data).then(function (response) {
                      if (response.status == 'OK') {
                        finca.propietarios.splice(finca.propietarios.indexOf(data), 1);
                      } else {
                        toastr.error(response.message);
                        finca.personas = tmpPersonas;
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.buscarFinca = function () {
                  ngDialog.open({
                    template: 'app/finca/finca.list.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'fincaService',
                      function ($scope, $state, toastr, fincaService) {
                        $scope.gridOptionsFinca = angular.copy($scope.gridOptionsSelection);
                        $scope.gridOptionsFinca.columnDefs = [
                          { field: 'fincaDesc', name: 'fincaDesc', displayName: 'Finca' },
                          { field: 'tcMunicipio.municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '17%' },
                          { field: 'tcMunicipio.tcDepartamento.departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '12%' },
                          { field: 'direccion', name: 'direccion', displayName: 'Aldea/caserío/cantón' },
                          { field: 'gtmX', name: 'gtmX', displayName: 'GTM X', width: '8%' },
                          { field: 'gtmY', name: 'gtmY', displayName: 'GTM Y', width: '8%' },
                          { field: 'area', name: 'area', displayName: 'Área', width: '5%' },
                          {
                            name: 'Opciones', enableFiltering: false, width: '8%',
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.selectRowFinca(row.entity)" title="Seleccionar"> <i class="fa fa-hand-pointer-o" aria-hidden="true"></i> </button></span></div>'
                          }
                        ];
                        $scope.gridOptionsFinca.data = $scope.dataFinca;

                        $scope.direccion = {
                          departamentoId: null,
                          municipioId: null
                        }

                        $scope.cargarListaMunicipioFinca = function (item) {
                          $scope.direccion.municipioId = null;
                          municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMunicipioFinca = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.setMunicipioFinca = function (item) {
                          if (item) {
                            fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                              if (res.status == "OK") {
                                $scope.gridOptionsFinca.data = res.data;
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                      }]
                  })
                }

                $scope.selectRowFinca = function (row) {
                  var encontrado = false;
                  for (var i = 0; i < $scope.ttActualizacionExento.ttExento.fincas.length; i++) {
                    if ($scope.ttActualizacionExento.ttExento.fincas[i].tcFinca.fincaId == row.fincaId) {
                      encontrado = true;
                      break;
                    }
                    if ($scope.ttActualizacionExento.ttExento.fincas[i].tcFinca.tcMunicipio.municipioId != row.tcMunicipio.municipioId) {
                      toastr.error('La finca seleccionada pertenece a otro municipio, no es posible continuar');
                      return;
                    }
                  }
                  if (!encontrado) {
                    var tmp = $scope.ttActualizacionExento.ttExento;
                    var item = {
                      ttExento: {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea
                      },
                      tcFinca: row
                    };
                    item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                    item.registro = $scope.ttActualizacionExento.registro;
                    exentoService.agregarFincaActualizacion(item, tmp.exentoId).then(function (response) {
                      if (response.status == 1) {
                        item = response.data;
                        item.ttExento = {
                          exentoId: tmp.exentoId,
                          estadoId: tmp.estadoId,
                          tcPersonaCrea: tmp.tcPersonaCrea,
                          fechaEmision: null
                        };
                        $scope.ttActualizacionExento.ttExento.fincas.push(item);
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                  ngDialog.close();
                }

                $scope.agregarDocumentoFinca = function (finca) {
                  if (finca.documentos == null) {
                    finca.documentos = [];
                  }
                  finca.documentos.push({ documentoFincaId: 0 });
                }

                $scope.validarPanelFinca = function (isValid) {
                  if ($scope.ttActualizacionExento.ttExento.fincas.length <= 0) {
                    toastr.error('Debe agregar al menos una finca para continuar');
                    return;
                  }
                  if (!isValid) {
                    toastr.error('Debe indicar todos los campos obligatorios, favor de verificar');
                  } else {
                    var dataFinca = angular.copy($scope.ttActualizacionExento.ttExento.fincas);
                    var minimum = new Date();
                    if ($scope.dataSolicitud != undefined) {
                      minimum = new Date($scope.dataSolicitud.fechaRecepcion);
                    }
                    var tmp = $scope.ttActualizacionExento.ttExento;
                    minimum.setDate(minimum.getDate() - 121);
                    for (var i = 0; i < dataFinca.length; i++) {
                      if (dataFinca[i].propietarios.length <= 0) {
                        toastr.error('Debe indicar al menos un propietario a la finca ' + dataFinca[i].tcFinca.fincaDesc);
                        return;
                      }
                      dataFinca[i].ttExento = {
                        exentoId: tmp.exentoId,
                        estadoId: tmp.estadoId,
                        tcPersonaCrea: tmp.tcPersonaCrea
                      }
                      dataFinca[i].ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                      if (dataFinca[i].tcTipoPropiedad.tipoPropiedadId == 3) {
                        dataFinca[i].tcMunicipioEmite = null;
                      } else {
                        dataFinca[i].tcLibro = null;
                      }
                      delete dataFinca[i].dataMunicipio;
                      delete dataFinca[i].dataLibro;
                      delete dataFinca[i].personas;
                      delete dataFinca[i].propietarios;
                    }
                    exentoService.actualizarFincas(dataFinca).then(function (response) {
                      if (response.status == 'OK') {
                        $scope.ttActualizacionExento.tcSubregion = response.data[0];
                        toastr.success(response.message);
                        $scope.activaBoton = true;
                      } else {
                        toastr.error(response.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.limpiarDatosFinca = function (finca) {
                  finca.tcTipoPropiedad = {
                    tipoPropiedadId: null
                  };
                  finca.mostrarNumero = false;
                  finca.mostrarNotario = false;
                }

                $scope.setTipoPropiedadFinca = function (item, finca) {
                  if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 3) {
                    finca.mostrarNumero = true;
                  } else {
                    finca.mostrarNumero = false;
                  }
                  if (item.tipoPropiedadId == 1 || item.tipoPropiedadId == 2) {
                    finca.mostrarNotario = true;
                  } else {
                    finca.mostrarNotario = false;
                  }
                  finca.tcTipoPropiedad = item;
                }

                $scope.getMostrarNumero = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getNumeroRequerido = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 3) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getMunicipioRequerido = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                      return true;
                    }
                  }
                  return false;
                }

                $scope.getMostrarNotario = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1 || finca.tcTipoPropiedad.tipoPropiedadId == 2) {
                      return true;
                    }
                  }
                  finca.libro = 0;
                  return false;
                }

                $scope.getTituloDocumento = function (finca) {
                  if (finca.tcTipoPropiedad != null) {
                    if (finca.tcTipoPropiedad.tipoPropiedadId == 1) {
                      return "Número de escritura";
                    }
                  }
                  return "No. de finca";
                }

                $scope.agregarRegistroFinca = function () {
                  authService.saveLocalData('fincaRfn', $scope.dataRnf.finca);
                  $scope.guardarFinca = false;
                  $scope.setStateToGo("index.exento.editar", $base64.encode($scope.ttActualizacionExento.actualizacionExentoId), false, true);
                  $state.go('index.finca.add');
                }

                $scope.quitarFinca = function (item) {
                  //verificar si aun no se ha hecho una distribucion del uso actual de la finca
                  if ($scope.ttActualizacionExento.uso != undefined) {
                    if ($scope.ttActualizacionExento.uso != null) {
                      if ($scope.ttActualizacionExento.uso.length > 0) {
                        toastr.error('No es posible eliminar la finca debido a que ya se encuentra distribuido en algún uso actual. Debe eliminar los usos actuales en la pestaña "Características de la finca"');
                        return;
                      }
                    }
                  }
                  item.ttExento = {
                    exentoId: tmp.exentoId,
                    estadoId: tmp.estadoId,
                    tcPersonaCrea: tmp.tcPersonaCrea
                  }
                  item.ttExento.tcPersonaCrea.personaId = $scope.loginData.personaId;
                  exentoService.quitarFincaActualizacion(item, tmp.ttExento.exentoId).then(function (res) {
                    if (res.status == 1) {
                      $scope.ttActualizacionExento.ttExento.fincas.splice($scope.ttActualizacionExento.ttExento.fincas.indexOf(item), 1);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }
                /*Fin panel finca*/

                $scope.finalizarSolicitudActualizacion = function () {
                  if ($scope.ttActualizacionExento.ttExento.fincas.length <= 0) {
                    toastr.error('Debe al menos agregar una finca');
                    return;
                  }
                  let asegurese = '1. Rodales, áreas y especies de la estimación de volumen, deben coincidir con la última Constancia de Registro.\n2. Su Constancia de registro debe estar vigente.\n3. De lo contrario podrían denegar su solicitud.';
                  if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                    asegurese = 'Asegúrese que: \n1. Los datos presentados sean correctos \n2. Los datos coincidan con la certificación del RNF \n3. La constancia de registro debe de estar vigente.\nEl no hacerlo le ocasionará inconvenientes'; 
                  }
                  swal({
                    title: '¿Está seguro de finalizar la elaboración de la solicitud de actualización del número de registro ' + $scope.ttActualizacionExento.ttExento.codigo
                      + ' y proceder al envío a las oficinas del INAB?',
                    text: asegurese,
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var data = angular.copy($scope.ttActualizacionExento);
                        delete data.ttExento;
                        exentoService.finalizarElaboracionActualizacion(data).then(function (response) {
                          if (response.status == 1) {
                            toastr.success(response.message);
                            $state.go("index.exento.actualizar");
                          } else {
                            toastr.error(response.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.onFileSelect = function (file, tipoAnexoId) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var item = result.data[0];
                      var data = angular.copy($scope.ttActualizacionExento);
                      delete data.ttExento;
                      data.archivoCertificado = item.rutaArchivo;
                      exentoService.cargarArchivoCertificado(data).then(function (res) {
                        if (res.status == 1) {
                          $scope.mostrarVerCertificado = true;
                          toastr.success(res.message);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.verCertificadoRnf = function () {
                  exentoService.getCertificadoActualizacion($scope.ttActualizacionExento.actualizacionExentoId);
                }

                $scope.imprimirFormato = function () {
                  if ($scope.ttActualizacionExento.ttExento.fincas.length <= 0) {
                    toastr.error('Debe al menos agregar una finca');
                    return;
                  }
                  $scope.ttActualizacionExento.fechaSolicitud = new Date();
                  utilsService.setEsPrevia(true);
                  utilsService.generarSolicitudActualizacionExento($scope.ttActualizacionExento, $scope.dataEmpresa);
                }

                $scope.validarFechaVigencia = function (isValid) {
                  if (isValid) {
                    var data = angular.copy($scope.ttActualizacionExento);
                    delete data.ttExento;
                    exentoService.actualizarVigenciaSolicitudActualizacion(data).then(function (res) {
                      if (res.status == 1) {                        
                        toastr.success(res.message);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

              }]
          })
          .state('index.exento.notaelectronica', {
            url: '/:ventaExentoId/notaelectronica',
            templateUrl: 'app/exento/notaelectronica.list.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataVenta: ['exentoService', '$stateParams', '$base64',
                function (exentoService, $stateParams, $base64) {
                  return exentoService.getListaNotasVenta($base64.decode($stateParams.ventaExentoId));
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', 'dataEstado',
              'ngDialog', 'dataVenta', 'utilsService', '$base64', 'tareaService', 'utilsService',
              function ($scope, $state, toastr, exentoService, dataEstado,
                ngDialog, dataVenta, utilsService, $base64, tareaService, utilsService) {

                $scope.gridOptionsNotas = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptionsNotas.columnDefs = [
                  { field: 'numero', name: 'numero', displayName: 'Número' },
                  {
                    name: 'estado', displayName: "Tipo", field: 'estado', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.verificado ? "Finalizada" : "En edición"}}</div>'
                  },
                  {
                    name: 'Opciones', enableFiltering: false,
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-show="!row.entity.verificado && row.entity.usoId == 1" ng-click="grid.appScope.llenarNota(row.entity)" title="Llenar nota"><i class="fa fa-edit" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.usoId == 1" ng-click="grid.appScope.imprimirNotaElectronica(row.entity)" title="Imprimir nota"><i class="fa fa-file-pdf-o text-danger" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="row.entity.usoId == 1" ng-click="grid.appScope.anularNotaElectronica(row.entity)" title="Anular nota"><i class="fa fa-close" aria-hidden="true"></i></button>'
                      + '</span></div>'
                  }
                ];

                if ( dataVenta.status == 1 ) {
                  $scope.mostrarLista = true;
                  $scope.ttVentaExento = dataVenta.data;
                  $scope.gridOptionsNotas.data = $scope.ttVentaExento.notas;
                } else {
                  toastr.error( dataVenta.message );
                  $state.go('index.exento.listar');
                }

                $scope.dataEstado = dataEstado.data;

                $scope.agregarNota = function () {
                  swal({
                    title: '¿Está seguro de utilizar un correlativo de nota?',
                    text: "Esta acción no se puede cancelar posterior a su confirmación y la nota deberá ser utilizada o anulada",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        exentoService.agregarNotaElectronicaInforme( $scope.ttVentaExento.ventaExentoId ).then( function ( res ) {
                          if ( res.status == 1 ) {
                            $state.go('^.notaedit', { notaInformeVentaId: $base64.encode(res.data) });
                          } else {
                            toastr.error( res.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }
                    });
                }

                $scope.llenarNota = function ( row ) {
                  $state.go('^.notaedit', { notaInformeVentaId: $base64.encode(row.notaInformeVentaId) });
                }

                $scope.cancelarAgregarNota = function () {
                  $state.go('index.exento.listar');
                }

                $scope.imprimirNotaElectronica = function ( ttNotaInformeVenta ) {
                  exentoService.getNotaInformeVenta( ttNotaInformeVenta.notaInformeVentaId ).then( function ( res ) {
                    if ( res.status == 1 ) {
                      ttNotaInformeVenta = res.data
                      utilsService.setEsPrevia(!ttNotaInformeVenta.verificado);
                      ttNotaInformeVenta.ttInformeVenta = ttNotaInformeVenta.informe;
                      console.log(res.data);
                      utilsService.notaEnvioExento(res.data, $scope.dataEmpresa);
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }

                $scope.anularNotaElectronica = function (item) {
                  swal({
                    title: "¿Está seguro de anular la nota de envío número " + item.numero + "?",
                    text: " Esta acción no se podrá reversar y la nota ya no será válida.\n\nIngrese el motivo de la anulación",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Anular",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Motivo de anulación"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario el motivo de la anulación');
                        return false;
                      }
                      item.motivo = inputValue;
                      exentoService.anularNotaElectronica(item).then(function (res) {
                        if (res.status == 1) {
                          item.usoId = 0;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }
                

              }]
          })
          .state('index.exento.notaedit', {
            url: '/:notaInformeVentaId/notaedit',
            templateUrl: 'app/exento/nota.informe.tpl.html',
            resolve: {
              dataEstado: ['exentoService',
                function (exentoService) {
                  return exentoService.listStatus();
                }],
              dataNota: ['exentoService', '$stateParams', '$base64',
                function (exentoService, $stateParams, $base64) {
                  return exentoService.getNotaInformeVenta($base64.decode($stateParams.notaInformeVentaId));
                }],
              dataMunicipio: ['municipioService', 
                function (municipioService) {
                  return municipioService.getPorPais(1);
                }],
              dataProductoTransporte: ['productotransporteService', 
                function (productotransporteService) {
                  return productotransporteService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'exentoService', '$base64', '$stateParams', 'dataMunicipio', 'Upload',
              'dataNota', 'utilsService', 'dataProductoTransporte', 
              function ($scope, $state, toastr, exentoService, $base64, $stateParams, dataMunicipio, Upload,
                dataNota, utilsService, dataProductoTransporte) {

                if (dataNota.status != 1) {
                  swal(dataNota.message);
                  $state.go('^.listar');
                  return;
                }
                $scope.mostrarAdvertencia = false;

                $scope.dataRendimiento = [
                  { factorRendimientoId: 0, factorRendimientoDesc: 'Usar los oficiales' },
                  { factorRendimientoId: 1, factorRendimientoDesc: 'Usar mi factor autorizado' }
                ];

                $scope.dataTipoSierra = [
                  { tipoSierraId: 1, tipoSierraDesc: 'Banda' },
                  { tipoSierraId: 2, tipoSierraDesc: 'Circular' },
                  { tipoSierraId: 3, tipoSierraDesc: 'Motosierra' }
                ];

                $scope.dataSierra = [
                  { otraSierraId: 0, otraSierraDesc: 'Sin sierra secundaria' },
                  { otraSierraId: 1, otraSierraDesc: 'Con sierra secundaria' }
                ];

                $scope.ttNotaInformeVenta = dataNota.data;
                $scope.esPreimpresa = true;
                $scope.mostrarGuardar = true;
                $scope.verificado = $scope.ttNotaInformeVenta.verificado;
                if ($scope.ttNotaInformeVenta.fechaNota == null) {
                  $scope.ttNotaInformeVenta.fechaNota = new Date();
                } else {
                  $scope.ttNotaInformeVenta.fechaNota = new Date($scope.ttNotaInformeVenta.fechaNota);
                }
                $scope.ttInformeVenta = $scope.ttNotaInformeVenta.informe;
                $scope.ttVentaExento = $scope.ttInformeVenta.ttVentaExento;
                $scope.ttNotaInformeVenta.usoId = 1;
                if ($scope.ttNotaInformeVenta.especies.length > 0) {
                  $scope.esPreimpresa = false;
                  $scope.mostrarGuardar = false;
                }

                $scope.dataMunicipio = dataMunicipio.data;
                $scope.dataProductoTransporte = dataProductoTransporte.data;

                var especies = [];
                var saldos = $scope.ttVentaExento.saldos;
                var lenia = 0, troza = 0;
                for (var i = 0; i < saldos.length; i++) {
                  var fila = saldos[i];
                  lenia += parseFloat(fila.lenia);
                  troza += parseFloat(fila.troza);
                  especies.push(fila.tcEspecie);
                }
                $scope.lenia = lenia;
                $scope.troza = troza;
                $scope.dataEspecie = especies;

                $scope.setMunicipioNota = function (item) {
                  $scope.ttNotaInformeVenta.tcMunicipio.municipioDesc = item.municipioDesc;
                  $scope.ttNotaInformeVenta.tcMunicipio.tcDepartamento = item.tcDepartamento;
                }

                $scope.setEspecieNota = function (item, producto) {
                  producto.tcEspecie = item;
                  $scope.ttNotaInformeVenta.verificado = false;
                }

                $scope.onChange = function () {
                  $scope.mostrarAdvertencia = true;
                }

                $scope.agregarProducto = function () {
                  if ($scope.ttNotaInformeVenta.especies == null) {
                    $scope.ttNotaInformeVenta.especies = [];
                  }
                  $scope.ttNotaInformeVenta.especies.push(
                    { volumenReportar: 0, volumen: 0 }
                  );
                  $scope.esPreimpresa = false;
                  $scope.ttNotaInformeVenta.verificado = false;
                  $scope.mostrarAdvertencia = true;
                }

                $scope.quitarProducto = function (item) {
                  $scope.ttNotaInformeVenta.especies.splice($scope.ttNotaInformeVenta.especies.indexOf(item), 1);
                  if ($scope.ttNotaInformeVenta.especies.length <= 0) {
                    $scope.esPreimpresa = true;
                  }
                  $scope.mostrarAdvertencia = true;
                }

                $scope.setProductoTransportado = function (item, producto) {
                  producto.tcProductoTransporte.productoTransporteDesc = item.productoTransporteDesc;
                  producto.tcProductoTransporte.rendimientoConSierrab = item.rendimientoConSierrab;
                  producto.tcProductoTransporte.rendimientoConSierrac = item.rendimientoConSierrac;
                  producto.tcProductoTransporte.rendimientoMotosierra = item.rendimientoMotosierra;
                  producto.tcProductoTransporte.rendimientoSinSierrab = item.rendimientoSinSierrab;
                  producto.tcProductoTransporte.rendimientoSinSierrac = item.rendimientoSinSierrac;
                  producto.tcProductoTransporte.rendimientoSinSierrabLati = item.rendimientoSinSierrabLati;
                  producto.tcProductoTransporte.rendimientoSinSierracLati = item.rendimientoSinSierracLati;
                }

                $scope.setTotalEnLetras = function () {
                  var especies = $scope.ttNotaInformeVenta.especies;
                  var total = 0;
                  for (let i = 0; i < especies.length; i++) {
                    const element = especies[i];
                    volumenReportar = parseFloat(element.volumenReportar);
                    total += volumenReportar;
                  }
                  total = parseFloat(total).toFixed(4);
                  var strPartesTotal = total.toString().split('.');
                  var entero = parseInt(strPartesTotal[0]);
                  var decimal = parseInt(strPartesTotal[1]);
                  var strEntero = $scope.numeroALetras(entero, true);
                  var strDecimal = $scope.numeroALetras(decimal, true);
                  var strTotal = strEntero;
                  if (decimal > 0) {
                    if (entero == 1) {
                      strTotal = 'uno';
                    }
                    strTotal = strTotal + ' punto ' + strDecimal;
                  }
                  strTotal = strTotal.toLowerCase() + ' metros cúbicos ';
                  $scope.ttNotaInformeVenta.textoNumero = strTotal;
                }

                $scope.setVolumenProducto = function (producto) {
                  if (producto.tcProductoTransporte == null || producto.tcProductoTransporte.productoTransporteId == null) {
                    producto.volumen = null;
                    toastr.error('Debe indicar el producto a trasportar');
                    return;
                  }
                  producto.volumenReportar = producto.volumen;
                  var factor = 0, factorUsado = 0;
                  if (producto.tcProductoTransporte.productoTransporteId == 2) {
                    if ($scope.ttInformeVenta.aplicaFactora == 1) {
                      factor = parseFloat($scope.ttInformeVenta.factorAserrada);
                      factorUsado = angular.copy(factor);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      if ($scope.ttInformeVenta.tipoSierraId == 1) {
                        if ($scope.ttInformeVenta.otraSierraId == 0) {
                          if (producto.tcEspecie.tcTipoEspecie.tipoEspecieId == 1) {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                          } else {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrabLati);
                          }
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoConSierrab);
                        }
                      }
                      if ($scope.ttInformeVenta.tipoSierraId == 2) {
                        if (producto.tcEspecie.tcTipoEspecie.tipoEspecieId == 1) {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrac);
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierracLati);
                        }
                      }
                      if ($scope.ttInformeVenta.tipoSierraId == 3) {
                        factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      }
                      factorUsado = angular.copy(factor);
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                      $scope.ttInformeVenta.factorAserrada = factorUsado;
                    }
                  }
                  if (producto.tcProductoTransporte.productoTransporteId == 4) {
                    if ($scope.ttInformeVenta.aplicaFactorc == 1) {
                      factor = parseFloat($scope.ttInformeVenta.factorCarbon);
                      factorUsado = angular.copy(factor);
                      if (factor > 1) {
                        factor = factor / 100;
                      }
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                    } else {
                      factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                      factorUsado = angular.copy(factor);
                      if (factor == 0) {
                        factor = parseFloat(producto.volumen);
                      } else {
                        factor = parseFloat(producto.volumen) / factor;
                      }
                      producto.volumenReportar = parseFloat(factor).toFixed(2);
                      $scope.ttInformeVenta.factorCarbon = factorUsado;
                    }
                  }
                  $scope.ttNotaInformeVenta.factor = factorUsado;
                  $scope.setTotalEnLetras();
                }

                $scope.submitFormNotas = function (isValid) {
                  if (isValid && !$scope.mostrarGuardar) {
                    return;
                  }
                  if (isValid) {
                    let mensaje = 'pre-impresa';
                    if (!$scope.esPreimpresa) {
                      mensaje = '';
                    }
                    swal({
                      title: '¿Está seguro de generar la nota ' + mensaje + '?',
                      text: "Esta acción no se puede cancelar posterior a su confirmación y la nota deberá ser anulada si la información asignada es incorrecta",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                      function (isConfirm) {
                        if (isConfirm) {
                          var data = angular.copy($scope.ttNotaInformeVenta);
                          data.ttInformeVenta = angular.copy($scope.ttInformeVenta);
                          delete data.ttInformeVenta.ttVentaExento;
                          exentoService.actualizarNotaInformeVenta(data).then(function (res) {
                            if (res.status == 1) {
                              $scope.ttNotaInformeVenta.verificado = true;
                              $scope.mostrarAdvertencia = false;
                              toastr.success(res.message);
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      });
                  } else {
                    toastr.error('Asegúrese de rellenar todos los campos');
                  }
                }

                $scope.regresarLista = function () {
                  $state.go('^.notaelectronica', { ventaExentoId: $base64.encode($scope.ttVentaExento.ventaExentoId) });
                }

                $scope.imprimirNotaElectronica = function () {
                  if ($scope.ttNotaInformeVenta.fechaNota == null || $scope.ttNotaInformeVenta.fechaNota.length <= 0) {
                    toastr.error('Debe indicar la fecha de salida');
                    return;
                  }
                  var data = angular.copy($scope.ttNotaInformeVenta);
                  data.ttInformeVenta = data.informe;
                  utilsService.setEsPrevia(!$scope.ttNotaInformeVenta.verificado);
                  utilsService.notaEnvioExento(data, $scope.dataEmpresa);
                }

              }]
          })
      }
    ]
  );
