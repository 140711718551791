angular.module('app.especieTalaIlicita', [
  'ui.router',
  'toastr',
  'app.especieTalaIlicita.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.especieTalaIlicita', {
          abstract: true,
          url: 'especieTalaIlicita',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Fórmula para el cálculo de altura en tala ilícita';
            }]
        })
        .state('index.especieTalaIlicita.list', {
          url: '',
          templateUrl: 'app/especieTalaIlicita/especieTalaIlicita.list.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(101);
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataEspecie: ['especieService',
              function ( especieService ){
                return especieService.listaActiva();
              }],
            dataEstado: ['especieTalaIlicitaService',
              function ( especieTalaIlicitaService ){
                return especieTalaIlicitaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'dataRegion', 'dataEspecie', 'especieTalaIlicitaService', 'ngDialog', 'dataEstado',
            function ( $scope, $state, dataRegion, dataEspecie, especieTalaIlicitaService, ngDialog, dataEstado ) {
              $scope.dataEspecie = dataEspecie.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataEstado = dataEstado.data;
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.filtro = {};
              $scope.gridOptions.columnDefs = [
                { field:'tcEspecie.nombreCientifico', name: 'nombreCientifico', displayName: 'Nombre científico' },
                { field:'tcRegion.regionDesc', name: 'regionDesc', displayName: 'Región' },
                { field:'formula', name: 'formula', displayName: 'Fórmula' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '8%', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '8%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.agregarRegistro(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = [];
              
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }

              $scope.setRegion = function ( item ) {
                if (item) {
                  especieTalaIlicitaService.getListaByRegion( item.regionId ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.cerrarVentana = function () {
                ngDialog.close();
              }

              $scope.agregarRegistro = function ( item ) {
                $scope.isEdit = false;
                if (item) {
                  $scope.data = item;
                  $scope.isEdit = true;
                } else {
                  $scope.data = {
                    estadoId: 1,
                    variable: '[{"variable":"DAT"}]',
                    variableDap: '[{"variable":"DAT"}]'
                  };
                  if ($scope.filtro.regionId != null) {
                    $scope.data.tcRegion = {
                      regionId: $scope.filtro.regionId
                    }
                  }
                }
                ngDialog.open({
                  template: 'app/especieTalaIlicita/especieTalaIlicita.add.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniquePoligono',
                  name: 'uniquePoligono',
                  resolve: {
                  },
                  controller: ['$scope', '$state', 'toastr',
                    function ($scope, $state, toastr) {

                      $scope.submitForm = function ( isValid ) {
                        if (isValid) {
                          if ($scope.isEdit) {
                            especieTalaIlicitaService.edit( $scope.data ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                $scope.setRegion($scope.filtro);
                                toastr.success( res.message );
                                $scope.cerrarVentana();
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                          } else {
                            especieTalaIlicitaService.add( $scope.data ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                $scope.setRegion($scope.filtro);
                                toastr.success( res.message );
                                $scope.cerrarVentana();
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                          }
                        }
                      }

                    }]
                })
              }

            }]

        })
        .state('index.especieTalaIlicita.calcular', {
          url: '/calcular',
          templateUrl: 'app/especieTalaIlicita/especieTalaIlicita.calc.tpl.html',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(102);
              }],
            dataEspecie: ['especieTalaIlicitaService', 'authService',
              function ( especieTalaIlicitaService, authService ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                if (loginData.regionId == null) {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                } else {
                  return especieTalaIlicitaService.getListaByRegion(loginData.regionId);
                }
              }],
            dataRegion: ['regionService', 'authService',
              function ( regionService, authService ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                if (loginData.regionId == null) {
                  return regionService.listaActiva();
                } else {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                }
              }]
          },
          controller: ['$scope', '$state', 'dataEspecie', 'dataRegion', 'especieTalaIlicitaService', 'toastr', 'manualService', 'authService',
            function (  $scope, $state, dataEspecie, dataRegion, especieTalaIlicitaService, toastr, manualService, authService) {
              $scope.dataRegion = dataRegion.data;

              $scope.descargarFormato = function () {
                manualService.getFile( 42 ); //ver codigo en bd
              }

              $scope.setDataEspecie = function ( data ) {
                for (var i = 0; i < data.length; i++) {
                  var row = data[i];
                  row.especieId = row.tcEspecie.especieId;
                  row.nombreCientifico = row.tcEspecie.nombreCientifico;
                }
                $scope.dataEspecie = data;
                if (data.length > 0) {
                  authService.removeLocalData('dataEspecieIlicita');
                  authService.saveLocalData('dataEspecieIlicita', data);
                } else {
                  authService.removeLocalData('dataEspecieIlicita');
                }
              }
              $scope.setDataEspecie(dataEspecie.data);
              $scope.mostrarRegion = false;
              if ($scope.loginData.regionId == null) {
                $scope.mostrarRegion = true;
              }

              $scope.data = {
                especies: []
              }

              $scope.filtro = {};

              $scope.gridOptionsExcel = angular.copy($scope.gridOptionsSelection);
              $scope.gridOptionsExcel.columnDefs = [
                { field: 'no', name: 'no', displayName: 'No. Árbol' },
                { field: 'nombre_cientifico', name: 'nombre_cientifico', displayName: 'Nombre científico' },
                { field: 'dat', name: 'dat', displayName: 'DAT' },
                { field: 'DAP', name: 'DAP', displayName: 'DAP', width: '15%' },
                { field: 'ALTURA', name: 'ALTURA', displayName: 'Altura', width: '15%' },
                { field: 'VOLUMEN', name: 'VOLUMEN', displayName: 'Volumen' }
              ];
              $scope.gridOptionsExcel.data = [];

              $scope.setRegion = function ( item ) {
                if (item) {
                  especieTalaIlicitaService.getListaByRegion( item.regionId ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.setDataEspecie(res.data);
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.agregarRegistro = function () {
                if ($scope.filtro.regionId == null) {
                  toastr.error('Debe seleccionar la región');
                  return;
                }
                $scope.data.especies.push({});
              }

              var valor = function ( json, columna ) {
                for (var key in json) {
                   if (key == columna) return json[key];
                }
                return null;
              }

              $scope.calcularAltura = function ( especie ) { 
                if (especie.especieId == null) {
                  return;
                }
                if (especie.dat == null) {
                  return;
                }
                var formula = angular.copy(especie.data.formula);
                var variable = JSON.parse(especie.data.variable);
                for (var i = 0; i < 5; i++) {
                  for (var j = 0; j < variable.length; j++) {
                    var row = variable[j];
                    formula = formula.replace(row.variable, especie.dat);
                  }
                }
                var altura = new Function('return ' + formula)();
                especie.altura = parseFloat(altura).toFixed(2);
                formula = angular.copy(especie.data.formulaDap);
                variable = JSON.parse(especie.data.variableDap);
                for (var i = 0; i < 5; i++) {
                  for (var j = 0; j < variable.length; j++) {
                    var row = variable[j];
                    formula = formula.replace(row.variable, especie.dat);
                  }
                }
                var dap = new Function('return ' + formula)();
                especie.dap = parseFloat(dap).toFixed(2);
                formula = especie.data.tcEspecie.ecuacion;
                var variables = JSON.parse(angular.copy(especie.data.tcEspecie.formula))
                for (j = 0; j < variables.length; j++) {
                  variable = variables[j].variable;
                  value = valor(especie, variable.toLowerCase());
                  for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                    formula = formula.replace(variable, value);
                  }
                }
                var volumen = new Function('return ' + formula)();
                especie.volumen = parseFloat(volumen).toFixed(4);
              }

              $scope.setEspecieTalaIlicita = function ( item, especie ) {
                especie.data = item;
                $scope.calcularAltura(especie);
              }

              $scope.descargarDatos = function () {
                var xlsxData = [];
                var xlsxHeader = ['No. Álbol', 'Nombre científico', 'DAP', 'Altura', 'Volumen (m³)'];
                xlsxData.push(xlsxHeader);
                var data = $scope.gridOptionsExcel.data;
                for (var i = 0; i < data.length; i++) {
                  var xlsxRow = [];
                  xlsxRow.push(data[i].no);
                  xlsxRow.push(data[i].nombre_cientifico);
                  xlsxRow.push(data[i].dap);
                  xlsxRow.push(data[i].altura);
                  xlsxRow.push(data[i].volumen);
                  xlsxData.push(xlsxRow);
                }
                var filename = "sabana.xlsx";
                var sheetName = "data";
                var wb = XLSX.utils.book_new();
                var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
                XLSX.writeFile(wb, filename);
              }
              
            }]
        })
    }
  ]
);
