angular.module('app.forbidden', [
  'ui.router',
  'toastr',
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.forbidden', {
          abstract: true,
          url: 'forbidden',
          params: {
          },
          views: {
            '': {
              templateUrl: 'app/forbidden/forbidden.tpl.html',
              resolve: {
              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {
                }]
            }
          }
        })
        .state( 'index.forbidden.show', {
          url: '',
          views: {
            '': {
              templateUrl: 'app/forbidden/forbidden.show.tpl.html',
              resolve: {

              },
              controller: ['$scope', '$state', 'toastr',
                function (  $scope,   $state,   toastr) {
                }]
            }
          }
        })
    }
  ]
);
