angular.module('app.reporte', [
  'ui.router',
  'toastr',
  'app.reporte.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.reporte', {
            abstract: true,
            url: 'reporte',
            template: '<div ui-view></div>',
            resolve: {
            },
            controller: ['$scope', 'toastr', '$timeout',
              function ($scope, toastr, $timeout) {
                $scope.module = 'Reporte';
              }]
          })
          .state('index.reporte.exento', {
            url: '/exento',
            templateUrl: 'app/reporte/reporte.exento.tpl.html',
            resolve: {
              dataReporte: ['reporteService',
                function (reporteService) {
                  return reporteService.listaActiva();
                }],
              dataRegion: ['regionService',
              function (regionService) {
                return regionService.listaActiva();
              }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataReporte', 'exentoService', 'dataRegion', 'subregionService', '$filter',
              function ($scope, $state, toastr, dataReporte, exentoService, dataRegion, subregionService, $filter) {

                $scope.data = {};
                $scope.dataMes = [
                  { mesId: 1, mesDesc: 'Enero' },
                  { mesId: 2, mesDesc: 'Febrero' },
                  { mesId: 3, mesDesc: 'Marzo' },
                  { mesId: 4, mesDesc: 'Abril' },
                  { mesId: 5, mesDesc: 'Mayo' },
                  { mesId: 6, mesDesc: 'Junio' },
                  { mesId: 7, mesDesc: 'Julio' },
                  { mesId: 8, mesDesc: 'Agosto' },
                  { mesId: 9, mesDesc: 'Septiembre' },
                  { mesId: 10, mesDesc: 'Octubre' },
                  { mesId: 11, mesDesc: 'Noviembre' },
                  { mesId: 12, mesDesc: 'Diciembre' }
                ];

                $scope.mostrarSubregion = false;
                $scope.mostrarRegion = false;

                $scope.dataAnio = [];
                var inicio = 2021;
                var year = (new Date()).getFullYear();
                for (var i = inicio; i <= year; i++) {
                  $scope.dataAnio.push({ anio: i });
                }

                $scope.dataReporte = dataReporte.data;
                $scope.dataRegion = dataRegion.data;
                $scope.dataRegion.unshift({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.data = {
                  fechaIni: $scope.addDays(new Date, -30),
                  fechaFin: new Date()
                };
                $scope.filtrarFecha = false;
                $scope.mostrarActualizar = false;

                $scope.setReporte = function (item) {
                  $scope.mostrarActualizar = false;
                  switch (item.reporteId) {
                    case 1:
                      $scope.filtrarFecha = true;
                      if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 21 || $scope.loginData.perfilId == 36) {
                        $scope.mostrarActualizar = true;
                      }
                      break;
                    case 2:
                    case 5:
                      $scope.filtrarFecha = true;
                      break;
                    case 3:
                    case 4:
                    case 6:
                    case 7:
                      $scope.filtrarFecha = false;
                      break;
                    default: 
                      $scope.filtrarFecha = false;
                      break;
                  }
                }

                $scope.setRegion = function (item) {
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      $scope.data.subregionId = 0;
                    } else {
                      subregionService.listaPorRegion(item.regionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataSubregion = res.data;
                          $scope.dataSubregion.unshift({ subregionId: 0, subregionDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  $scope.data.regionId = $scope.loginData.regionId;
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.mostrarSubregion = true;
                  }
                } else {
                  $scope.mostrarSubregion = true;
                  $scope.mostrarRegion = true;
                }

                $scope.reporteGeneralExento = function () {
                  var fechaIni = $filter('date')($scope.data.fechaIni, 'yyyy-MM-dd');
                  var fechaFin = $filter('date')($scope.data.fechaFin, 'yyyy-MM-dd');
                  exentoService.getReporteGeneralVentaNota(fechaIni, fechaFin, $scope.data.regionId, $scope.data.subregionId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      try {
                        var xlsxData = [];
                        var xlsxHeader = ["Resolución", "Fecha resolución", "Fecha vencimiento", "Región", "Subregión",
                          "Año", "Mes", "Categoría de inscripción", "Tratamiento silvicultural", "Departamento",
                          "Municipio", "Dirección", "Nombre del titular", "CUI (DPI)", "Área registrada", "Área intervenida", 
                          "Volumen total", "Notas aprobadas", "Correlatvios", "Revalidados", "Volumen transportado", "Informes aprobados",
                          "Avance", "Estado"
                        ];
                        xlsxData.push(xlsxHeader);
                        for (var i = 0; i < data.length; i++) {
                          var xlsxRow = [];
                          const row = data[i];
                          xlsxRow.push(row.resolucion);                          
                          xlsxRow.push($filter('date')(new Date(row.fechaResolucion), 'dd/MM/yyyy'));
                          xlsxRow.push($filter('date')(new Date(row.fechaVencimiento), 'dd/MM/yyyy'));
                          xlsxRow.push(row.regionDesc);
                          xlsxRow.push(row.subregionDesc);
                          xlsxRow.push(row.anio);
                          xlsxRow.push(row.codigo);
                          xlsxRow.push(row.actividadForestalDesc);
                          xlsxRow.push(row.tratamientoSilviculturalDesc);
                          xlsxRow.push(row.departamentoDesc);
                          xlsxRow.push(row.municipioDesc);
                          xlsxRow.push(row.direccion);
                          xlsxRow.push(row.propietarioDesc);
                          xlsxRow.push(row.identificacionDesc);
                          xlsxRow.push(row.area);
                          xlsxRow.push(parseFloat(row.areaIntervenir).toFixed(4));
                          xlsxRow.push(row.volumen);
                          xlsxRow.push(row.autorizadoNotas);
                          xlsxRow.push(row.correlativos);
                          xlsxRow.push(row.revalidadas);
                          xlsxRow.push(row.volumenTransportado);
                          xlsxRow.push(row.informes);
                          xlsxRow.push(row.avance);
                          xlsxRow.push(row.estado);
                          xlsxData.push(xlsxRow);
                        }
                        var filename = "data.xlsx";
                        var ws_name = "data";
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(xlsxData);
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.writeFile(wb, filename);
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.getReporteDetalleEspecie = function () {
                  var fechaIni = $filter('date')($scope.data.fechaIni, 'yyyy-MM-dd');
                  var fechaFin = $filter('date')($scope.data.fechaFin, 'yyyy-MM-dd');
                  exentoService.getReporteDetalleEspecie(fechaIni, fechaFin, $scope.data.regionId, $scope.data.subregionId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      try {
                        var createXLSLFormatObj = [];
                        var xlsHeader = ["RESOLUCION", "CODIGO_RNF", "ANIO", "FECHA_RESOLUCION",
                          "CATEGORIA_INSCRIPCION", "REGION", "SUBREGION", "DEPARTAMENTO", "MUNICIPIO",
                          "VOLUMEN_APROBADO", "VOLUMEN_TROZA", "VOLUMEN_LENIA", "CODIGO", "ESPECIE"];
                        var xlsRows = [];
                        for (var i = 0; i < data.length; i++) {
                          var item = {
                            RESOLUCION: data[i].resolucion,
                            CODIGO_RNF: data[i].codigo,
                            ANIO: data[i].anio,
                            FECHA_RESOLUCION: data[i].fechaResolucion,
                            CATEGORIA_INSCRIPCION: data[i].actividadForestalDesc,
                            REGION: data[i].regionDesc,
                            SUBREGION: data[i].subregionDesc,
                            DEPARTAMENTO: data[i].departamentoDesc,
                            MUNICIPIO: data[i].municipioDesc,
                            VOLUMEN_APROBADO: data[i].volumen,
                            VOLUMEN_TROZA: data[i].volumenTroza,
                            VOLUMEN_LENIA: data[i].volumenLenia,
                            CODIGO: data[i].codigo,
                            ESPECIE: data[i].nombreCientifico
                          }
                          xlsRows.push(item);
                        }
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(xlsRows, function (index, value) {
                          var innerRowData = [];
                          $("tbody").append('<tr><td>' + value.RESOLUCION + '</td><td>' + value.CODIGO_RNF
                            + '</td><td>' + value.ANIO + '</td><td>' + value.FECHA_RESOLUCION + '</td><td>' + value.CATEGORIA_INSCRIPCION
                            + '</td><td>' + value.REGION + '</td><td>' + value.SUBREGION
                            + '</td><td>' + value.DEPARTAMENTO + '</td><td>' + value.MUNICIPIO
                            + '</td><td>' + value.VOLUMEN_APROBADO + '</td><td>' + value.VOLUMEN_TROZA
                            + '</td><td>' + value.VOLUMEN_LENIA + '</td><td>' + value.CODIGO
                            + '</td><td>' + value.ESPECIE
                            + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });
                        var filename = "sabana.xlsx";
                        var ws_name = "data";
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.writeFile(wb, filename);
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.reporteHistoricoPoligono = function (data) {
                  if (data.length <= 0) {
                    toastr.warning('No se encontraron registros con el filtro indicado');
                    return;
                  }
                  try {
                    var createXLSLFormatObj = [];
                    var xlsHeader = ["Código RNF", "Mes", "Año", "Fecha de autorización", "Resolución", "Municipio", "Departamento", 
                    "No. Polígono", "GTM X", "GTM Y", "Región", "Subregión", 'Área registrada', 'Área polígono'];
                    createXLSLFormatObj.push(xlsHeader);
                    for (let i = 0; i < data.length; i++) {
                      var innerRowData = [];
                      var value = data[i];
                      innerRowData.push(value.codigo);
                      innerRowData.push(value.mes);
                      innerRowData.push(value.anio);
                      innerRowData.push(value.fecha);
                      innerRowData.push(value.resolucion);
                      innerRowData.push(value.municipio);
                      innerRowData.push(value.departamento);
                      innerRowData.push(value.noPoligono);
                      innerRowData.push(value.gtmX);
                      innerRowData.push(value.gtmY);
                      innerRowData.push(value.region);
                      innerRowData.push(value.subregion);
                      innerRowData.push(parseFloat(value.areaRegistrada).toFixed(4));
                      innerRowData.push(parseFloat(value.area).toFixed(4));
                      createXLSLFormatObj.push(innerRowData);
                    }
                    var filename = "data.xlsx";
                    var ws_name = "data";
                    var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.getReporteBalanceGeneral = function ( codigo ) {
                  exentoService.getReporteBalanceGeneral(codigo).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      try {
                        var createXLSLFormatObj = [];
                        /* XLS Head Columns */
                        var xlsHeader = ['Código RNF', 'Categoría de inscripción', 'Región', 'Subregión', 'Departamento', 'Municipio', 'Nombre del titular', 
                        'Gestión', 'Fecha notificación', 'Año', 'Mes', 'Nombre mes', 'Área registrada (ha)', 'Saldo (m³)'];
                        /* XLS Rows Data */
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(data, function (index, value) {
                          var innerRowData = [];
                          value.fechaNotificacion = new Date(value.fechaNotificacion);
                          $("tbody").append('<tr><td>' + value.codigo + '</td><td>' + value.categoriaInscripcion + '</td><td>' + value.regionDesc + '</td><td>' 
                          + value.subregionDesc + '</td><td>' + value.departamentoDesc + '</td><td>' + value.municipioDesc + '</td><td>' + value.titular + '</td><td>' 
                          + value.gestionDesc + '</td><td>' + $filter('date')(value.fechaNotificacion, 'dd/MM/yyyy') + '</td><td>' 
                          + value.anio + '</td><td>' + value.nombreMes + '</td><td>' + value.areaRegistrada + '</td><td>' + value.saldo + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });
                        var filename = "data.xlsx";
                        var ws_name = "data";
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.writeFile(wb, filename);                        
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.getReporteBalanceExentoEspecie = function ( codigo ) {
                  exentoService.getReporteBalanceEspecie(codigo).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      try {
                        var createXLSLFormatObj = [];
                        /* XLS Head Columns */
                        var xlsHeader = ['Código RNF', 'Categoría de inscripción', 'Región', 'Subregión', 'Departamento', 'Municipio', 'Nombre del titular', 
                        'Gestión', 'Fecha notificación', 'Año', 'Mes', 'Nombre mes', 'Rodal', 'Especie', 'Área rodal (ha)', 'Volumen (m³) / ha', 'Volumen (m³) / rodal'];
                        /* XLS Rows Data */
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(data, function (index, value) {
                          var innerRowData = [];
                          value.fechaNotificacion = new Date(value.fechaNotificacion);
                          $("tbody").append('<tr><td>' + value.codigo + '</td><td>' + value.categoriaInscripcion + '</td><td>' + value.regionDesc + '</td><td>' 
                          + value.subregionDesc + '</td><td>' + value.departamentoDesc + '</td><td>' + value.municipioDesc + '</td><td>' + value.titular + '</td><td>' 
                          + value.gestionDesc + '</td><td>' + $filter('date')(value.fechaNotificacion, 'dd/MM/yyyy') + '</td><td>' 
                          + value.anio + '</td><td>' + value.nombreMes + '</td><td>' + value.rodal + '</td><td>' + value.nombreCientifico + '</td><td>' + value.areaRodal 
                          + '</td><td>' + value.volumenHectaria + '</td><td>' + value.volumenRodal + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });
                        var filename = "data.xlsx";
                        var ws_name = "data";
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.writeFile(wb, filename);
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.getReporteNotasCodigo = function (codigo) {
                  exentoService.getReporteNotasCodigo(codigo).then(function (res) {
                    if (res.status == 1) {
                      try {
                        var data = res.data;
                        var xlsxData = [];
                        var xlsxHeader = [];
                        xlsxHeader = ['Notas aprobadas', 'Correlativo', 'Resolución', 'Fecha resolución', 'Vigencia resolución', 'Región',
                          'Subregión', 'Año', 'Código RNF', 'Categoría inscripción', 'Tratamiento silvicultural', 'Departamento', 'Muncipio',
                          'Nombre titular', 'Estado', 'No. Informe', 'Volumen transportado', 'Tipo de producto', 'Especies'
                        ];
                        xlsxData.push(xlsxHeader);
                        for (var i = 0; i < data.length; i++) {
                          var xlsxRow = [];
                          const row = data[i];
                          xlsxRow.push(row.total);
                          xlsxRow.push(row.correlativo);
                          xlsxRow.push(row.resolucion);                          
                          xlsxRow.push($filter('date')(new Date(row.fechaResolucion), 'dd/MM/yyyy'));
                          xlsxRow.push($filter('date')(new Date(row.fechaVigencia), 'dd/MM/yyyy'));
                          xlsxRow.push(row.regionDesc);
                          xlsxRow.push(row.subregionDesc);
                          xlsxRow.push(row.anio);
                          xlsxRow.push(row.codigo);
                          xlsxRow.push(row.categoriaInscripcion);
                          xlsxRow.push(row.tratamientoSilviculturalDesc);
                          xlsxRow.push(row.departamentoDesc);
                          xlsxRow.push(row.municipioDesc);
                          xlsxRow.push(row.personaDesc);
                          xlsxRow.push(row.estado);
                          xlsxRow.push(row.noInforme);
                          xlsxRow.push(row.volumen);
                          xlsxRow.push(row.tipoProductoDesc);
                          xlsxRow.push(row.especies);
                          xlsxData.push(xlsxRow);
                        }
                        var filename = "data.xlsx";
                        var sheetName = "data";
                        var wb = XLSX.utils.book_new();
                        var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                        XLSX.utils.book_append_sheet(wb, ws, sheetName);
                        XLSX.writeFile(wb, filename);
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    switch ($scope.data.reporteId) {
                      case 1:
                        $scope.reporteGeneralExento();
                        break;
                      case 2:
                        $scope.getReporteDetalleEspecie();
                        break;
                      case 3:
                        $scope.getReporteBalanceGeneral($scope.data.codigo.trim().toUpperCase());
                        break;
                      case 4:
                        $scope.getReporteBalanceExentoEspecie($scope.data.codigo.trim().toUpperCase());
                        break;
                      case 5:
                        var fechaIni = $filter('date')($scope.data.fechaIni, 'yyyy-MM-dd');
                        var fechaFin = $filter('date')($scope.data.fechaFin, 'yyyy-MM-dd');
                        exentoService.getHistoricoPoligono(fechaIni, fechaFin, $scope.data.regionId, $scope.data.subregionId).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            $scope.reporteHistoricoPoligono(data);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 6:
                        $scope.getReporteNotasCodigo($scope.data.codigo.trim().toUpperCase());
                        break;
                      case 7:
                        exentoService.getPoligonoByCodigo($scope.data.codigo.trim().toUpperCase()).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            $scope.reporteHistoricoPoligono(data);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      default:
                        break;
                    }
                  }
                }

                $scope.actualizarReporte = function () {
                  switch ($scope.data.reporteId) {
                    case 1:
                      swal({
                        title: "Ésta opción probocará lentitud en el sistema, es decir para realizar una venta, carga inicial, informes, etc. por lo que se recomienda usarlo con moderación. La información está actualizada hasta las 00:00 horas de hoy",
                        text: "¿Está seguro de continuar?",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            exentoService.actualizarReporteVenta().then(function (res) {
                              if (res.status == 1) {
                                toastr.success('Datos actualizados, ahora puede volver a generar el reporte con los datos actualizados');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });                      
                      break;
                    default:
                      break;
                  }
                }

                $scope.entregarReporte = function (item) {
                  if (item.estadoId == 2) {
                    toastr.error('La resolución ya fue entregada');
                    return;
                  }
                  swal({
                    title: "Confirmación",
                    text: "¿Está segura (o) de marcar como: resolución entregada al solicitante? \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        item.ttGestion.personaAnulaId = $scope.loginData.personaId;
                        gestionService.marcarEntregaReporte(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.submitForm(true);
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

              }]
          })
          .state('index.reporte.nota', {
            url: '/nota',
            templateUrl: 'app/reporte/reporte.nota.tpl.html',
            resolve: {
              dataRegion: ['regionService',
                function (regionService) {
                  return regionService.listaActiva();
                }],
              dataTipoNotaEnvio: ['tiponotaenvioService',
                function (tiponotaenvioService) {
                  return tiponotaenvioService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataRegion', 'dataTipoNotaEnvio', 'notaenvioService', 'subregionService', '$filter', 'utilsService',
              function ($scope, $state, toastr, dataRegion, dataTipoNotaEnvio, notaenvioService, subregionService, $filter, utilsService) {

                $scope.dataRegion = dataRegion.data;
                $scope.dataRegion.unshift({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.dataTipoNotaEnvio = dataTipoNotaEnvio.data;
                $scope.dataTipoNotaEnvio.unshift({ tipoNotaEnvioId: 0, tipoNotaEnvioDesc: 'Todos' });

                $scope.dataTipoReporte = [
                  { tipoReporteId: 1, tipoReporteDesc: 'Reporte de ventas' },
                  { tipoReporteId: 2, tipoReporteDesc: 'Reporte de distribución de notas' }                  
                ];
                $scope.mostrarTipoReporte = true;

                $scope.dataNivel = [
                  { nivel: 1, nivelDesc: 'Subregión' },
                  { nivel: 2, nivelDesc: 'Región' }
                ];
                $scope.mostrarNivelReporte = false;

                let now = new Date();
                $scope.data = {
                  fechaInicio: $scope.addDays(now, -30),
                  fechaFinal: now
                };

                if ($scope.loginData.perfilId == 5) {
                  $scope.data.nivel = 1;
                } else {
                  $scope.data.nivel = 2;
                }
                $scope.tituloReporte = 'ventas';

                $scope.setTipoReporte = function (item) {
                  $scope.gridOptions.columnDefs = [
                    { field: 'regionDesc', name: 'regionDesc', displayName: 'Región' },
                    { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                    { field: 'tipoNotaEnvioDesc', name: 'tipoNotaEnvioDesc', displayName: 'Tipo de nota' },
                    { field: 'cantidad', name: 'cantidad', displayName: 'Cantidad' }
                  ];
                  if (item.tipoReporteId == 1) {
                    $scope.tituloReporte = 'ventas';
                  } else {
                    $scope.tituloReporte = 'distribución';
                    $scope.gridOptions.columnDefs = [
                      { field: 'regionDesc', name: 'regionDesc', displayName: 'Región' },
                      { field: 'tipoNotaEnvioDesc', name: 'tipoNotaEnvioDesc', displayName: 'Tipo de nota' },
                      { field: 'cantidadIngresada', name: 'cantidadIngresada', displayName: 'Cantidad ingresada' }
                    ];
                    $scope.gridOptions.data = [];
                  }
                  if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.data.regionId = $scope.loginData.regionId;
                    if ($scope.loginData.perfilId == 5) {
                      $scope.data.subregionId = $scope.loginData.subregionId;
                    }
                  } else {
                    $scope.data.regionId = null;
                    $scope.data.subregionId = null;
                    if ($scope.data.tipoReporteId == 1) {
                      $scope.mostrarNivelReporte = false;
                    } else {
                      $scope.mostrarNivelReporte = true;
                    }
                  }
                }

                $scope.setNivelDistribucion = function (item) {
                  $scope.data.regionId = null;
                  $scope.data.subregionId = null;
                }

                $scope.setRegion = function (item) {
                  if ($scope.data.tipoReporteId == null || $scope.data.nivel == null) {
                    if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                      toastr.error('Seleccione el tipo de reporte');
                    } else {
                      $scope.data.regionId = null;                      
                      toastr.error('Seleccione el tipo de reporte y el nivel');
                    }
                    return;
                  }
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      if ($scope.data.tipoReporteId == 2 && $scope.data.nivel == 2) {
                        $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'No aplica' });
                      } else {
                        $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      }
                      $scope.data.subregionId = 0;
                    } else {
                      if ($scope.data.tipoReporteId == 2 && $scope.data.nivel == 2) {
                        $scope.dataSubregion = [];
                        $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'No aplica' });
                        $scope.data.subregionId = 0;
                      } else {
                        subregionService.listaPorRegion(item.regionId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataSubregion = res.data;
                            $scope.dataSubregion.unshift({ subregionId: 0, subregionDesc: 'Todos' });
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {                  
                  $scope.data.regionId = $scope.loginData.regionId;
                  $scope.data.tipoReporteId = 1;
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.mostrarSubregion = true;
                  }
                } else {
                  $scope.mostrarSubregion = true;
                  $scope.mostrarRegion = true;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.exporterOlderExcelCompatibility = true;
                $scope.gridOptions.enableGridMenu = true;
                $scope.gridOptions.exporterCsvFilename = 'reporte.csv';
                $scope.gridOptions.exporterPdfDefaultStyle = { fontSize: 9 };
                $scope.gridOptions.exporterPdfTableStyle = { margin: [30, 30, 30, 30] };
                $scope.gridOptions.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, italics: true, color: 'blue' };
                $scope.gridOptions.exporterPdfHeader = { text: 'Lista de Notas de Envío', style: 'headerStyle' };
                $scope.gridOptions.exporterPdfFooter = function (currentPage, pageCount) {
                  return { text: currentPage.toString() + ' de ' + pageCount.toString() + ' - Manejo Forestal - ', style: 'footerStyle' };
                };
                $scope.gridOptions.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                  docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                  return docDefinition;
                };
                $scope.gridOptions.exporterPdfOrientation = 'landscape';
                $scope.gridOptions.exporterPdfPageSize = 'LETTER';
                $scope.gridOptions.exporterPdfMaxGridWidth = 600;
                $scope.gridOptions.exporterCsvLinkElement = angular.element(document.querySelectorAll('.custom-csv-link-location'));

                $scope.gridOptions.onRegisterApi = function (gridApi) {
                  $scope.gridApi = gridApi;
                }
                $scope.gridOptions.columnDefs = [
                  { field: 'regionDesc', name: 'regionDesc', displayName: 'Región' },
                  { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión' },
                  { field: 'tipoNotaEnvioDesc', name: 'tipoNotaEnvioDesc', displayName: 'Tipo de nota' },
                  { field: 'cantidad', name: 'cantidad', displayName: 'Cantidad' }
                ];
                $scope.gridOptions.data = [];

                $scope.submitForm = function (isValid) {                  
                  if (isValid) {
                    var fechaIni = $filter('date')($scope.data.fechaInicio, 'yyyy-MM-dd');
                    var fechaFin = $filter('date')($scope.data.fechaFinal, 'yyyy-MM-dd');
                    switch ($scope.data.tipoReporteId) {
                      case 1:
                        notaenvioService.getReporteNotaByFecha($scope.data.tipoNotaEnvioId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.tipoReporteId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.gridOptions.data = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 2:
                        notaenvioService.getReporteDistribucionExistencia($scope.data.regionId, $scope.data.subregionId, $scope.data.tipoNotaEnvioId, $scope.data.nivel, fechaIni, fechaFin).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaIngreso = $filter('date')(new Date(data[i].fechaIngreso), 'dd/MM/yyyy');
                            }
                            $scope.gridOptions.data = data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                    }
                  }
                }

                $scope.exportarPdf = function () {
                  if ($scope.gridOptions.data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  var columns = [
                    { title: "Región", dataKey: "regionDesc" },
                    { title: "Subregión", dataKey: "subregionDesc" },
                    { title: "Tipo de nota", dataKey: "tipoNotaEnvioDesc" },
                    { title: "Cantidad", dataKey: "cantidad" }
                  ];
                  var alinearDerecha = [
                    { columna: 3 }
                  ];
                  columnStyles = {
                    regionDesc: { columnWidth: 100 },
                    subregionDesc: { columnWidth: 100 },
                    tipoNotaEnvioDesc: { columnWidth: 200 },
                    cantidad: { columnWidth: 100 }
                  }
                  if ($scope.data.tipoReporteId == 2) {
                    var columns = [
                      { title: "Región", dataKey: "regionDesc" },
                      { title: "Tipo de nota", dataKey: "tipoNotaEnvioDesc" },
                      { title: "Cantidad", dataKey: "cantidad" }
                    ];
                    alinearDerecha = [
                      { columna: 2 }
                    ];
                    columnStyles = {
                      regionDesc: { columnWidth: 150 },
                      tipoNotaEnvioDesc: { columnWidth: 250 },
                      cantidad: { columnWidth: 100 }
                    }
                  }
                  var fechaIni = $filter('date')($scope.data.fechaInicio, 'dd/MM/yyyy');
                  var fechaFin = $filter('date')($scope.data.fechaFinal, 'dd/MM/yyyy');
                  var data = $scope.gridOptions.data;
                  var titulo = 'Reporte de ventas de notas de envío';
                  if ($scope.data.tipoReporteId == 2) {
                    titulo = 'Reporte de distribución de notas de envío de Almacen Central a regiones';
                  }
                  if ($scope.data.tipoReporteId == 3) {
                    titulo = 'Reporte de distribución de notas de envío de región a subregiones';
                  }
                  var subtitulo = 'Rango de fecha del ' + fechaIni + ' al ' + fechaFin;
                  utilsService.reporteTabla(columns, data, 'p', 'letter', titulo, subtitulo, $scope.loginData.usuario, $scope.dataEmpresa, alinearDerecha, null, null, columnStyles, null, null, null);
                }

                $scope.exportarExcelVentaNotas = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = ["Región", "Subregión", "Tipo de nota", "Cantidad"];
                    if ($scope.data.tipoReporteId == 2) {
                      xlsxHeader = ["Región", "Tipo de nota", "Cantidad"];
                    }
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].regionDesc);
                      if ($scope.data.tipoReporteId == 2) {
                        xlsxRow.push(data[i].subregionDesc);
                      }
                      xlsxRow.push(data[i].tipoNotaEnvioDesc);
                      xlsxRow.push(data[i].cantidad);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "sabana.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcelDistribucionNota = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Fecha ingreso', 'Correlativos (distribución)', 
                      'Cantidad (distribución)', 'Correlativos (existencia)', 'Cantidad (existencia)'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      const row = data[i];
                      xlsxRow.push(row.regionDesc);
                      xlsxRow.push(row.subregionDesc);
                      xlsxRow.push(row.departamentoDesc);
                      xlsxRow.push(row.municipioDesc);
                      xlsxRow.push(row.fechaIngreso);
                      xlsxRow.push(row.correlativos);
                      xlsxRow.push(row.cantidadIngresada);
                      xlsxRow.push(row.correlativosExistentes);
                      xlsxRow.push(row.cantidadExistente);
                      xlsxRow.push(row.tipoNotaEnvioDesc);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcel = function () {
                  var data = $scope.gridOptions.data;
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  switch ($scope.data.tipoReporteId) {
                    case 1:
                      $scope.exportarExcelVentaNotas();
                      break;
                    case 2:
                      $scope.exportarExcelDistribucionNota();
                      break;
                    default:
                      break;
                  }
                }

              }]
          })
          .state('index.reporte.licencia', {
            url: '/licencia',
            templateUrl: 'app/reporte/reporte.licencia.tpl.html',
            resolve: {
              dataRegion: ['regionService',
                function (regionService) {
                  return regionService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataRegion', 'tareaService', 'subregionService', '$filter', 'utilsService',
              function ($scope, $state, toastr, dataRegion, tareaService, subregionService, $filter, utilsService) {

                $scope.dataRegion = dataRegion.data;
                $scope.dataRegion.unshift({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.dataNivel = [
                  { nivel: 1, nivelDesc: 'Licencias con información general' },
                  { nivel: 2, nivelDesc: 'Licencias incluyendo especies' },
                  { nivel: 3, nivelDesc: 'Licencias incluyendo POA' },
                  { nivel: 4, nivelDesc: 'Licencias incluyendo POA y especies' },
                  { nivel: 5, nivelDesc: 'Solicitudes de trámites de licencias' },
                  { nivel: 6, nivelDesc: 'Solicitudes de trámites de licencias relacionadas con la VAC' }
                ];

                var hoy = new Date();
                $scope.data = {
                  fechaInicio: $scope.addDays(hoy, -30),
                  fechaFinal: hoy
                };
                $scope.tituloFecha = '';

                $scope.setRegion = function (item) {
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      $scope.data.subregionId = 0;
                    } else {
                      subregionService.listaPorRegion(item.regionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataSubregion = res.data;
                          $scope.dataSubregion.unshift({ subregionId: 0, subregionDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                  $scope.data.regionId = $scope.loginData.regionId;
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.mostrarSubregion = true;
                  }
                } else {
                  $scope.mostrarSubregion = true;
                  $scope.mostrarRegion = true;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.exporterOlderExcelCompatibility = true;
                $scope.gridOptions.enableGridMenu = true;
                $scope.gridOptions.exporterCsvFilename = 'reporte.csv';
                $scope.gridOptions.exporterPdfDefaultStyle = { fontSize: 9 };
                $scope.gridOptions.exporterPdfTableStyle = { margin: [30, 30, 30, 30] };
                $scope.gridOptions.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, italics: true, color: 'blue' };
                $scope.gridOptions.exporterPdfHeader = { text: 'Lista de Notas de Envío', style: 'headerStyle' };
                $scope.gridOptions.exporterPdfFooter = function (currentPage, pageCount) {
                  return { text: currentPage.toString() + ' de ' + pageCount.toString() + ' - Manejo Forestal - ', style: 'footerStyle' };
                };
                $scope.gridOptions.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                  docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                  return docDefinition;
                };
                $scope.gridOptions.exporterPdfOrientation = 'landscape';
                $scope.gridOptions.exporterPdfPageSize = 'LETTER';
                $scope.gridOptions.exporterPdfMaxGridWidth = 600;
                $scope.gridOptions.exporterCsvLinkElement = angular.element(document.querySelectorAll('.custom-csv-link-location'));

                $scope.gridOptions.onRegisterApi = function (gridApi) {
                  $scope.gridApi = gridApi;
                }
                $scope.gridOptions.columnDefs = [
                  { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                  { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                  { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '25%' },
                  { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '25%' },
                  { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '20%' },
                  { field: 'fechaAprobacion', name: 'fechaAprobacion', displayName: 'Fecha', width: '10%' },
                  { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                  { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                  { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                  { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                  { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                  { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                  { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                  { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                  { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                  { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                  { field: 'volumen', name: 'volumen', displayName: 'Volúmen', width: '10%' },
                  { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' }
                ];
                $scope.gridOptions.data = [];

                $scope.setTipoReporte = function (item) {
                  switch (item.nivel) {
                    case 1:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '25%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '25%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '20%' },
                        { field: 'fechaAprobacion', name: 'fechaAprobacion', displayName: 'Fecha', width: '10%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                        { field: 'volumen', name: 'volumen', displayName: 'Volúmen', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' },
                        { field: 'edadRotacion', name: 'edadRotacion', displayName: 'Edad de rotación', width: '10%' }
                      ];
                      $scope.tituloFecha = ' de la licencia';
                      break;
                    case 2:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '15%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '15%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '20%' },
                        { field: 'fechaAprobacion', name: 'fechaAprobacion', displayName: 'Fecha', width: '10%' },
                        { field: 'especie', name: 'especie', displayName: 'Especies', width: '25%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                        { field: 'volumen', name: 'volumen', displayName: 'Volúmen', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' },
                        { field: 'especie', name: 'especie', displayName: 'Especies', width: '20%' },
                        { field: 'tratamiento', name: 'tratamiento', displayName: 'Tratamiento', width: '20%' },
                        { field: 'edadRotacion', name: 'edadRotacion', displayName: 'Edad de rotación', width: '10%' }
                      ];
                      $scope.tituloFecha = ' de la licencia';
                      break;
                    case 3:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '15%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '20%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '15%' },
                        { field: 'codigoPoa', name: 'codigoPoa', displayName: 'Código POA', width: '20%' },
                        { field: 'fechaPoa', name: 'fechaPoa', displayName: 'Fecha', width: '10%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                        { field: 'volumen', name: 'volumen', displayName: 'Volúmen POA', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' },
                        { field: 'fechaVigencia', name: 'fechaVigencia', displayName: 'Fecha vigencia POA', width: '10%' },
                        { field: 'tipoGarantia', name: 'tipoGarantia', displayName: 'Tipo de garantía', width: '15%' },
                        { field: 'cantidadNotas', name: 'cantidadNotas', displayName: 'Notas', width: '10%' },
                        { field: 'rango', name: 'rango', displayName: 'Rango', width: '15%' },
                        { field: 'edadRotacion', name: 'edadRotacion', displayName: 'Edad de rotación', width: '10%' }
                      ];
                      $scope.tituloFecha = ' del POA';
                      break;
                    case 4:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '15%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '15%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '10%' },
                        { field: 'codigoPoa', name: 'codigoPoa', displayName: 'Código POA', width: '15%' },
                        { field: 'fechaPoa', name: 'fechaPoa', displayName: 'Fecha', width: '10%' },
                        { field: 'especie', name: 'especie', displayName: 'Especies', width: '25%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                        { field: 'volumen', name: 'volumen', displayName: 'Volúmen POA', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' },
                        { field: 'fechaVigencia', name: 'fechaVigencia', displayName: 'Fecha vigencia POA', width: '10%' },
                        { field: 'tipoGarantia', name: 'tipoGarantia', displayName: 'Tipo de garantía', width: '15%' },
                        { field: 'cantidadNotas', name: 'cantidadNotas', displayName: 'Notas', width: '10%' },
                        { field: 'rango', name: 'rango', displayName: 'Rango', width: '15%' },
                        { field: 'tratamiento', name: 'tratamiento', displayName: 'Tratamiento', width: '20%' },
                        { field: 'tipoBosque', name: 'tipoBosque', displayName: 'Tipo de bosque', width: '20%' },
                        { field: 'edadRotacion', name: 'edadRotacion', displayName: 'Edad de rotación', width: '10%' }
                      ];
                      $scope.tituloFecha = ' del POA';
                      break;
                    case 5:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '20%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '20%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha recepción', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' }
                      ];
                      $scope.tituloFecha = ' de la solicitud';
                      break;
                    case 6:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '20%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '20%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Expediente VAC', width: '10%' },
                        { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha recepción', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' }
                      ];
                      $scope.tituloFecha = ' de la solicitud';
                      break;
                    default:
                      $scope.gridOptions.columnDefs = [
                        { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                        { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                        { field: 'departamento', name: 'departamento', displayName: 'Departamento', width: '25%' },
                        { field: 'municipio', name: 'municipio', displayName: 'Municipio', width: '25%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'No. Resolución', width: '20%' },
                        { field: 'fechaAprobacion', name: 'fechaAprobacion', displayName: 'Fecha', width: '10%' },
                        { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                        { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'titular', name: 'titular', displayName: 'Titular', width: '20%' },
                        { field: 'sexo', name: 'sexo', displayName: 'Sexo', width: '10%' },
                        { field: 'representante', name: 'representante', displayName: 'Titular', width: '20%' },
                        { field: 'noPoas', name: 'noPoas', displayName: 'No. POAs', width: '10%' },
                        { field: 'areaManejo', name: 'areaManejo', displayName: 'Área manejo', width: '10%' },
                        { field: 'areaProteccion', name: 'areaProteccion', displayName: 'Área protección', width: '10%' },
                        { field: 'areaProduccion', name: 'areaProduccion', displayName: 'Área producción', width: '10%' },
                        { field: 'estado', name: 'estado', displayName: 'Estado', width: '10%' },
                        { field: 'volumen', name: 'volumen', displayName: 'Volúmen', width: '10%' },
                        { field: 'gtm', name: 'gtm', displayName: 'GTM', width: '10%' }
                      ];
                      break;
                  }
                  $scope.gridOptions.data = [];
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')($scope.data.fechaInicio, 'yyyy-MM-dd');
                    var fechaFin = $filter('date')($scope.data.fechaFinal, 'yyyy-MM-dd');
                    tareaService.getReporteLicenciaByFecha($scope.data.nivel, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.exportarPdf = function () {
                  if ($scope.gridOptions.data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  var columns = [];
                  var columnStyles = [];
                  var alinearDerecha = [];
                  var fechaIni = $filter('date')($scope.data.fechaInicio, 'dd/MM/yyyy');
                  var fechaFin = $filter('date')($scope.data.fechaFinal, 'dd/MM/yyyy');
                  var data = $scope.gridOptions.data;
                  var titulo = '';
                  switch ($scope.data.nivel) {
                    case 1:
                      titulo = 'Reporte de licencias forestales';
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'Resolución de aprobación', dataKey: 'resolucion' },
                        { title: 'Fecha de aprobación', dataKey: 'fechaAprobacion' },
                        { title: 'Fecha de vigencia licencia', dataKey: 'fechaVencimiento' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Representante legal', dataKey: 'representante' },
                        { title: 'Área bajo manejo', dataKey: 'areaManejo' },
                        { title: 'Volumen', dataKey: 'volumen' },
                        { title: 'Estado', dataKey: 'estado' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 40 },
                        departamento: { columnWidth: 60 },
                        municipio: { columnWidth: 80 },
                        expediente: { columnWidth: 75 },
                        tipoLicencia: { columnWidth: 100 },
                        resolucion: { columnWidth: 90 },
                        fechaAprobacion: { columnWidth: 60 },
                        fechaVencimiento: { columnWidth: 60 },
                        titular: { columnWidth: 100 },
                        representante: { columnWidth: 100 },
                        areaManejo: { columnWidth: 60 },
                        volumen: { columnWidth: 60 },
                        estado: { columnWidth: 60 }
                      }
                      alinearDerecha = [
                        { columna: 11 },
                        { columna: 12 }
                      ];
                      break;
                    case 2:
                      titulo = 'Reporte de licencias forestales incluyento especies';
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'Resolución de aprobación', dataKey: 'resolucion' },
                        { title: 'Fecha de aprobación', dataKey: 'fechaAprobacion' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Área bajo manejo', dataKey: 'areaManejo' },
                        { title: 'Volumen', dataKey: 'volumen' },
                        { title: 'Estado', dataKey: 'estado' },
                        { title: 'Especies', dataKey: 'especie' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 40 },
                        departamento: { columnWidth: 60 },
                        municipio: { columnWidth: 80 },
                        expediente: { columnWidth: 75 },
                        tipoLicencia: { columnWidth: 105 },
                        resolucion: { columnWidth: 90 },
                        fechaAprobacion: { columnWidth: 60 },
                        titular: { columnWidth: 120 },
                        areaManejo: { columnWidth: 60 },
                        volumen: { columnWidth: 60 },
                        estado: { columnWidth: 60 },
                        especie: { columnWidth: 130 }
                      }
                      alinearDerecha = [
                        { columna: 9 },
                        { columna: 10 }
                      ];
                      break;
                    case 3:
                      titulo = 'Reporte de licencias forestales incluyendo POA';
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'Fecha de aprobación', dataKey: 'fechaAprobacion' },
                        { title: 'Estado licencia', dataKey: 'estado' },
                        { title: 'POA', dataKey: 'codigoPoa' },
                        { title: 'Fecha de POA', dataKey: 'fechaPoa' },
                        { title: 'Fecha vigencia', dataKey: 'fechaVigencia' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Volumen POA', dataKey: 'volumen' },
                        { title: 'Tipo garantía', dataKey: 'tipoGarantia' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 40 },
                        departamento: { columnWidth: 60 },
                        municipio: { columnWidth: 80 },
                        expediente: { columnWidth: 75 },
                        tipoLicencia: { columnWidth: 100 },
                        fechaAprobacion: { columnWidth: 60 },
                        estado: { columnWidth: 55 },
                        codigoPoa: { columnWidth: 105 },
                        fechaPoa: { columnWidth: 60 },
                        fechaVigencia: { columnWidth: 60 },
                        titular: { columnWidth: 100 },
                        areaManejo: { columnWidth: 60 },
                        volumen: { columnWidth: 60 },
                        tipoGarantia: { columnWidth: 95 }
                      }
                      alinearDerecha = [
                        { columna: 12 }
                      ];
                      break;
                    case 4:
                      titulo = 'Reporte de licencias forestales incluyendo POA y especies por POA';
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'POA', dataKey: 'codigoPoa' },
                        { title: 'Fecha de POA', dataKey: 'fechaPoa' },
                        { title: 'Fecha vigencia', dataKey: 'fechaVigencia' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Volumen POA', dataKey: 'volumen' },
                        { title: 'Tipo garantía', dataKey: 'tipoGarantia' },
                        { title: 'Especies', dataKey: 'especie' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 40 },
                        departamento: { columnWidth: 60 },
                        municipio: { columnWidth: 80 },
                        expediente: { columnWidth: 75 },
                        tipoLicencia: { columnWidth: 100 },
                        codigoPoa: { columnWidth: 105 },
                        fechaPoa: { columnWidth: 60 },
                        fechaVigencia: { columnWidth: 60 },
                        titular: { columnWidth: 100 },
                        areaManejo: { columnWidth: 60 },
                        volumen: { columnWidth: 60 },
                        tipoGarantia: { columnWidth: 95 },
                        tipoGarantia: { columnWidth: 115 }
                      }
                      alinearDerecha = [
                        { columna: 10 }
                      ];
                      break;
                    case 5:
                      titulo = 'Reporte de licencias forestales';
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'Fecha de recepción', dataKey: 'fechaRecepcion' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Representante legal', dataKey: 'representante' },
                        { title: 'Área bajo manejo', dataKey: 'areaManejo' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 50 },
                        departamento: { columnWidth: 70 },
                        municipio: { columnWidth: 90 },
                        expediente: { columnWidth: 80 },
                        tipoLicencia: { columnWidth: 180 },
                        fechaRecepcion: { columnWidth: 60 },
                        titular: { columnWidth: 190 },
                        representante: { columnWidth: 150 },
                        areaManejo: { columnWidth: 60 }
                      }
                      alinearDerecha = [
                        { columna: 9 }
                      ];
                      titulo = 'Reporte de solicitudes licencias forestales';
                      break;
                    case 6:
                      columns = [
                        { title: 'Región', dataKey: 'region' },
                        { title: 'Subregión', dataKey: 'subregion' },
                        { title: 'Departamento', dataKey: 'departamento' },
                        { title: 'Municipio', dataKey: 'municipio' },
                        { title: 'Expediente', dataKey: 'expediente' },
                        { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                        { title: 'Fecha de recepción', dataKey: 'fechaRecepcion' },
                        { title: 'Titular', dataKey: 'titular' },
                        { title: 'Representante legal', dataKey: 'representante' },
                        { title: 'Identificador VAC', dataKey: 'sexo' }
                      ];
                      columnStyles = {
                        region: { columnWidth: 40 },
                        subregion: { columnWidth: 50 },
                        departamento: { columnWidth: 70 },
                        municipio: { columnWidth: 90 },
                        expediente: { columnWidth: 80 },
                        tipoLicencia: { columnWidth: 180 },
                        fechaRecepcion: { columnWidth: 60 },
                        titular: { columnWidth: 190 },
                        representante: { columnWidth: 150 },
                        sexo: { columnWidth: 60 }
                      }
                      alinearDerecha = [
                        { columna: 9 }
                      ];
                      titulo = 'Reporte de solicitudes licencias forestales relacionadas con la VAC';
                      break;
                    default:
                      titulo = 'Reporte de licencias forestales';
                      break;
                  }
                  var subtitulo = 'Rango de fecha del ' + fechaIni + ' al ' + fechaFin;
                  margin = { top: 90, left: 10, right: 10, button: 30 };
                  fontSize = {
                    header: 9,
                    detail: 9
                  }
                  utilsService.reporteTabla(columns, data, 'l', 'legal', titulo, subtitulo, $scope.loginData.usuario, $scope.dataEmpresa, alinearDerecha, null, null, columnStyles, null, margin, fontSize);
                }

                $scope.exportarExcel = function () {
                  var data = $scope.gridOptions.data;
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    switch ($scope.data.nivel) {
                      case 1:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Resolución de aprobación',
                          'Fecha de aprobación', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo', 'Representante', 'No. POAs', 'Área bajo manejo',
                          'Área de protección', 'Área de producción', 'Estado', 'Volumen', 'Ubicación GTM', 'Edad de rotación'
                        ];
                        break;
                      case 2:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Resolución de aprobación',
                          'Fecha de aprobación', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo', 'Representante', 'No. POAs', 'Área bajo manejo',
                          'Área de protección', 'Área de producción', 'Estado', 'Volumen', 'Ubicación GTM', 'Tratamiento silvicultural', 'Especies'
                        ];
                        break;
                      case 3:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Resolución de aprobación',
                          'Fecha de aprobación', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo', 'Representante', 'No. POAs', 'Área bajo manejo',
                          'Área de protección', 'Área de producción', 'Estado', 'Volumen', 'Ubicación GTM', 'Código Plan Operativo', 'Fecha de emisión',
                          'Fecha vigencia POA', 'Tipo de garantía', 'Cantidad de notas de envío', 'Rango de notas de envío', 'Edad de rotación'
                        ];
                        break;
                      case 4:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Resolución de aprobación',
                          'Fecha de aprobación', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo', 'Representante', 'No. POAs', 'Área bajo manejo',
                          'Área de protección', 'Área de producción', 'Estado', 'Volumen POA', 'Ubicación GTM', 'Código Plan Operativo', 'Fecha de emisión',
                          'Fecha vigencia POA', 'Tipo de garantía', 'Cantidad de notas de envío', 'Rango de notas de envío', 'Tratamiento silvicultural',
                          'Especies', 'Tipo de bosque', 'Edad de rotación'
                        ];
                        break;
                      case 5:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Fecha de recepción', 'Titular',
                          'Sexo', 'Representante', 'Área bajo manejo', 'Área de protección', 'Área de producción', 'Ubicación GTM'
                        ];
                        break;
                      case 6:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Expediente VAC', 'Tipo de Licencia', 'Fecha de recepción', 'Titular',
                          'Representante', 'Área bajo manejo', 'Ubicación GTM'
                        ];
                        break;
                      default:
                        xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente', 'Tipo de Licencia', 'Resolución de aprobación',
                          'Fecha de aprobación', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo', 'Representante', 'No. POAs', 'Área bajo manejo',
                          'Área de protección', 'Área de producción', 'Estado', 'Volumen POA', 'Ubicación GTM'
                        ];
                        break;

                    }
                    xlsxData.push(xlsxHeader);
                    if ($scope.data.nivel == 5 || $scope.data.nivel == 6) {
                      for (var i = 0; i < data.length; i++) {
                        var xlsxRow = [];
                        xlsxRow.push(data[i].region);
                        xlsxRow.push(data[i].subregion);
                        xlsxRow.push(data[i].departamento);
                        xlsxRow.push(data[i].municipio);
                        xlsxRow.push(data[i].expediente);
                        if ($scope.data.nivel == 6) {
                          xlsxRow.push(data[i].sexo);
                        }
                        xlsxRow.push(data[i].tipoLicencia);
                        xlsxRow.push(data[i].fechaRecepcion);
                        xlsxRow.push(data[i].titular);
                        if ($scope.data.nivel == 5) {
                          xlsxRow.push(data[i].sexo);
                        }
                        xlsxRow.push(data[i].representante);
                        xlsxRow.push(data[i].areaManejo);
                        if ($scope.data.nivel == 5) {
                          xlsxRow.push(data[i].areaProteccion);
                          xlsxRow.push(data[i].areaProduccion);
                        }
                        xlsxRow.push(data[i].gtm);
                        xlsxData.push(xlsxRow);
                      }
                    } else {
                      for (var i = 0; i < data.length; i++) {
                        var xlsxRow = [];
                        xlsxRow.push(data[i].region);
                        xlsxRow.push(data[i].subregion);
                        xlsxRow.push(data[i].departamento);
                        xlsxRow.push(data[i].municipio);
                        xlsxRow.push(data[i].expediente);
                        xlsxRow.push(data[i].tipoLicencia);
                        xlsxRow.push(data[i].resolucion);
                        xlsxRow.push(data[i].fechaAprobacion);
                        xlsxRow.push(data[i].fechaVencimiento);
                        xlsxRow.push(data[i].titular);
                        xlsxRow.push(data[i].sexo);
                        xlsxRow.push(data[i].representante);
                        xlsxRow.push(data[i].noPoas);
                        xlsxRow.push(data[i].areaManejo);
                        xlsxRow.push(data[i].areaProteccion);
                        xlsxRow.push(data[i].areaProduccion);
                        xlsxRow.push(data[i].estado);
                        xlsxRow.push(data[i].volumen);
                        xlsxRow.push(data[i].gtm);
                        switch ($scope.data.nivel) {
                          case 1:
                            //none
                            break;
                          case 2:
                            xlsxRow.push(data[i].tratamiento);
                            xlsxRow.push(data[i].especie);
                            break;
                          case 3:
                            xlsxRow.push(data[i].codigoPoa);
                            xlsxRow.push(data[i].fechaPoa);
                            xlsxRow.push(data[i].fechaVigencia);
                            xlsxRow.push(data[i].tipoGarantia);
                            xlsxRow.push(data[i].cantidadNotas);
                            xlsxRow.push(data[i].rango);
                            break;
                          case 4:
                            xlsxRow.push(data[i].codigoPoa);
                            xlsxRow.push(data[i].fechaPoa);
                            xlsxRow.push(data[i].fechaVigencia);
                            xlsxRow.push(data[i].tipoGarantia);
                            xlsxRow.push(data[i].cantidadNotas);
                            xlsxRow.push(data[i].rango);
                            xlsxRow.push(data[i].tratamiento);
                            xlsxRow.push(data[i].especie);
                            xlsxRow.push(data[i].tipoBosque);
                            break;
                          default:
                            //none
                            break;
                        }
                        xlsxRow.push(data[i].edadRotacion);
                        xlsxData.push(xlsxRow);
                      }
                    }
                    var filename = "sabana.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

              }]
          })
          .state('index.reporte.tarea', {
            url: '/tarea',
            templateUrl: 'app/reporte/reporte.tarea.tpl.html',
            resolve: {
              dataRegion: ['regionService',
                function (regionService) {
                  return regionService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataRegion', 'tareaService', 'subregionService', '$filter', 'utilsService',
              function ($scope, $state, toastr, dataRegion, tareaService, subregionService, $filter, utilsService) {

                $scope.dataRegion = dataRegion.data;
                $scope.dataRegion.unshift({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.dataTipoReporte = [
                  { tipoReporteId: 1, tipoReporteDesc: 'Tareas en un rango de tiempo' },
                  { tipoReporteId: 2, tipoReporteDesc: 'Tareas en un rando de tiempo incluyendo enmiendas' },
                  { tipoReporteId: 3, tipoReporteDesc: 'Tareas por número de expediente' },
                  { tipoReporteId: 4, tipoReporteDesc: 'Tareas por número de expediente, incluyendo enmiendas' },
                  { tipoReporteId: 5, tipoReporteDesc: 'Tareas activas' }
                ];

                $scope.data = {
                  fechaInicio: null,
                  fechaFinal: null,
                  expediente: null
                };
                $scope.tituloFecha = '';

                $scope.setRegion = function (item) {
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      $scope.data.subregionId = 0;
                    } else {
                      subregionService.listaPorRegion(item.regionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataSubregion = res.data;
                          $scope.dataSubregion.unshift({ subregionId: 0, subregionDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  $scope.data.regionId = $scope.loginData.regionId;
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.mostrarSubregion = true;
                  }
                } else {
                  $scope.mostrarSubregion = true;
                  $scope.mostrarRegion = true;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.exporterOlderExcelCompatibility = true;
                $scope.gridOptions.enableGridMenu = true;
                $scope.gridOptions.exporterCsvFilename = 'reporte.csv';
                $scope.gridOptions.exporterPdfDefaultStyle = { fontSize: 9 };
                $scope.gridOptions.exporterPdfTableStyle = { margin: [30, 30, 30, 30] };
                $scope.gridOptions.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, italics: true, color: 'blue' };
                $scope.gridOptions.exporterPdfHeader = { text: 'Lista de tareas', style: 'headerStyle' };
                $scope.gridOptions.exporterPdfFooter = function (currentPage, pageCount) {
                  return { text: currentPage.toString() + ' de ' + pageCount.toString() + ' - Manejo Forestal - ', style: 'footerStyle' };
                };
                $scope.gridOptions.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                  docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                  return docDefinition;
                };
                $scope.gridOptions.exporterPdfOrientation = 'landscape';
                $scope.gridOptions.exporterPdfPageSize = 'LETTER';
                $scope.gridOptions.exporterPdfMaxGridWidth = 600;
                $scope.gridOptions.exporterCsvLinkElement = angular.element(document.querySelectorAll('.custom-csv-link-location'));

                $scope.gridOptions.onRegisterApi = function (gridApi) {
                  $scope.gridApi = gridApi;
                }
                $scope.gridOptions.columnDefs = [
                  { field: 'region', name: 'region', displayName: 'Región', width: '10%' },
                  { field: 'subregion', name: 'subregion', displayName: 'Subregión', width: '10%' },
                  { field: 'expediente', name: 'expediente', displayName: 'No. Expediente', width: '15%' },
                  { field: 'tipoLicencia', name: 'tipoLicencia', displayName: 'Tipo de Licencia', width: '20%' },
                  { field: 'responsable', name: 'responsable', displayName: 'Responsable', width: '20%' },
                  { field: 'tarea', name: 'tarea', displayName: 'Tarea', width: '20%' },
                  { field: 'fechaAsignacion', name: 'fechaAsignacion', displayName: 'Fecha asignación', width: '15%' },
                  { field: 'fechaRecibe', name: 'fechaRecibe', displayName: 'Fecha recibe', width: '15%' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '15%' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha finalización', width: '15%' },
                  { field: 'estado', name: 'estado', displayName: 'Estado', width: '15%' }
                ];
                $scope.gridOptions.data = [];

                $scope.porFecha = false;
                $scope.porExpediente = false;

                $scope.setTipoReporte = function (item) {
                  switch (item.tipoReporteId) {
                    case 1:
                      $scope.porFecha = true;
                      $scope.porExpediente = false;
                      break;
                    case 2:
                      $scope.porFecha = true;
                      $scope.porExpediente = false;
                      break;
                    case 3:
                      $scope.porFecha = false;
                      $scope.porExpediente = true;
                      break;
                    case 4:
                      $scope.porFecha = false;
                      $scope.porExpediente = true;
                      break;
                    case 5:
                      $scope.porFecha = false;
                      $scope.porExpediente = false;
                      break;
                    default:
                      $scope.porFecha = true;
                      $scope.porExpediente = false;
                      break;
                  }
                  $scope.gridOptions.data = [];
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = null;
                    var fechaFin = null;
                    if ($scope.data.tipoReporteId != 3 && $scope.data.tipoReporteId != 4
                      && $scope.data.tipoReporteId != 5) {
                      fechaIni = $filter('date')($scope.data.fechaInicio, 'yyyy-MM-dd');
                      fechaFin = $filter('date')($scope.data.fechaFinal, 'yyyy-MM-dd');
                      $scope.data.expediente = null;
                    }
                    tareaService.getReporteTarea($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.exportarPdf = function () {
                  if ($scope.gridOptions.data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  var columns = [];
                  var columnStyles = [];
                  var alinearDerecha = [];
                  var fechaIni = null;
                  var fechaFin = null;
                  var expediente = null;
                  var titulo = 'Reporte de tareas';
                  var subtitulo = '';
                  if ($scope.data.tipoReporteId != 3 && $scope.data.tipoReporteId != 4) {
                    fechaIni = $filter('date')($scope.data.fechaInicio, 'dd/MM/yyyy');
                    fechaFin = $filter('date')($scope.data.fechaFinal, 'dd/MM/yyyy');
                    subtitulo = 'Rango de fecha del ' + fechaIni + ' al ' + fechaFin;
                  } else {
                    expediente = $scope.data.expediente;
                    subtitulo = 'Expediente ' + expediente;
                  }
                  var data = $scope.gridOptions.data;
                  columns = [
                    { title: 'Región', dataKey: 'region' },
                    { title: 'Subregión', dataKey: 'subregion' },
                    { title: 'Expediente', dataKey: 'expediente' },
                    { title: 'Tipo de licencia', dataKey: 'tipoLicencia' },
                    { title: 'Responsable', dataKey: 'responsable' },
                    { title: 'Tarea', dataKey: 'tarea' },
                    { title: 'Fecha de asignación', dataKey: 'fechaAsignacion' },
                    { title: 'Fecha que recibe', dataKey: 'fechaRecibe' },
                    { title: 'Fecha de vencimiento', dataKey: 'fechaVencimiento' },
                    { title: 'Fecha de finalización', dataKey: 'fechaFinalizacion' },
                    { title: 'Estado', dataKey: 'estado' }
                  ];
                  columnStyles = {
                    region: { columnWidth: 40 },
                    subregion: { columnWidth: 40 },
                    expediente: { columnWidth: 75 },
                    tipoLicencia: { columnWidth: 230 },
                    responsable: { columnWidth: 150 },
                    tarea: { columnWidth: 100 },
                    fechaAsignacion: { columnWidth: 70 },
                    fechaRecibe: { columnWidth: 70 },
                    fechaVencimiento: { columnWidth: 70 },
                    fechaFinalizacion: { columnWidth: 70 },
                    estado: { columnWidth: 70 }
                  }
                  alinearDerecha = [];
                  switch ($scope.data.tipoReporteId) {
                    case 1:
                      titulo = 'Reporte de tareas';
                      break;
                    case 2:
                      titulo = 'Reporte de tareas incluyendo enmiendas';
                      break;
                    case 3:
                      titulo = 'Reporte de tareas por expediente';
                      break;
                    case 4:
                      titulo = 'Reporte de tareas por expediente incluyendo enmiendas';
                      break;
                    case 4:
                      titulo = 'Reporte de tareas activas';
                      subtitulo = '';
                      break;
                    default:
                      titulo = 'Reporte de tareas';
                      break;
                  }

                  margin = { top: 90, left: 10, right: 10, button: 30 };
                  fontSize = {
                    header: 9,
                    detail: 9
                  }
                  utilsService.reporteTabla(columns, data, 'l', 'legal', titulo, subtitulo, $scope.loginData.usuario, $scope.dataEmpresa, alinearDerecha, null, null, columnStyles, null, margin, fontSize);
                }

                $scope.exportarExcel = function () {
                  var data = $scope.gridOptions.data;
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Expediente', 'Tipo de Licencia', 'Responsable', 'Tarea',
                      'Fecha de asignación', 'Fecha que recibe', 'Fecha de vencimiento', 'Fecha de finalización', 'Estado'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].region);
                      xlsxRow.push(data[i].subregion);
                      xlsxRow.push(data[i].expediente);
                      xlsxRow.push(data[i].tipoLicencia);
                      xlsxRow.push(data[i].responsable);
                      xlsxRow.push(data[i].tarea);
                      xlsxRow.push(data[i].fechaAsignacion);
                      xlsxRow.push(data[i].fechaRecibe);
                      xlsxRow.push(data[i].fechaVencimiento);
                      xlsxRow.push(data[i].fechaFinalizacion);
                      xlsxRow.push(data[i].estado);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "tareas.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

              }]
          })

          .state('index.reporte.tareaexento', {
            url: '/tareaexento',
            templateUrl: 'app/reporte/reporte.tarea.exento.tpl.html',
            resolve: {
              dataRegion: ['regionService',
                function (regionService) {
                  return regionService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataRegion', 'tareaService', 'subregionService', '$filter', 'utilsService',
              function ($scope, $state, toastr, dataRegion, tareaService, subregionService, $filter, utilsService) {

                $scope.dataRegion = dataRegion.data;
                $scope.dataRegion.unshift({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.data = {
                  fechaIni: $scope.addDays(new Date, -30),
                  fechaFin: new Date()
                };
                $scope.tituloFecha = '';
                $scope.mostrarSubregion = false;
                $scope.mostrarRegion = false;

                $scope.dataTipoReporte = [
                  { tipoReporteId: 1, tipoReporteDesc: 'Tareas pendientes' },
                  { tipoReporteId: 2, tipoReporteDesc: 'Tareas con detalle de tiempos' },
                  { tipoReporteId: 3, tipoReporteDesc: 'Tareas con tiempos de gestión (Carga inicial)' },
                  { tipoReporteId: 4, tipoReporteDesc: 'Tareas con tiempos de gestión (Carga ventas)' },
                  { tipoReporteId: 5, tipoReporteDesc: 'Tareas con tiempos de gestión (Carga informes)' }
                ];

                $scope.setRegion = function (item) {
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      $scope.data.subregionId = 0;
                    } else {
                      subregionService.listaPorRegion(item.regionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataSubregion = res.data;
                          $scope.dataSubregion.unshift({ subregionId: 0, subregionDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  $scope.data.regionId = $scope.loginData.regionId;
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                    $scope.mostrarSubregion = true;
                  }
                } else {
                  $scope.mostrarSubregion = true;
                  $scope.mostrarRegion = true;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.exporterOlderExcelCompatibility = true;
                $scope.gridOptions.enableGridMenu = true;
                $scope.gridOptions.exporterCsvFilename = 'reporte.csv';
                $scope.gridOptions.exporterPdfDefaultStyle = { fontSize: 9 };
                $scope.gridOptions.exporterPdfTableStyle = { margin: [30, 30, 30, 30] };
                $scope.gridOptions.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, italics: true, color: 'blue' };
                $scope.gridOptions.exporterPdfHeader = { text: 'Lista de tareas', style: 'headerStyle' };
                $scope.gridOptions.exporterPdfFooter = function (currentPage, pageCount) {
                  return { text: currentPage.toString() + ' de ' + pageCount.toString() + ' - Manejo Forestal - ', style: 'footerStyle' };
                };
                $scope.gridOptions.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                  docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                  return docDefinition;
                };
                $scope.gridOptions.exporterPdfOrientation = 'landscape';
                $scope.gridOptions.exporterPdfPageSize = 'LETTER';
                $scope.gridOptions.exporterPdfMaxGridWidth = 600;
                $scope.gridOptions.exporterCsvLinkElement = angular.element(document.querySelectorAll('.custom-csv-link-location'));

                $scope.gridOptions.onRegisterApi = function (gridApi) {
                  $scope.gridApi = gridApi;
                }
                $scope.gridOptions.columnDefs = [];
                $scope.gridOptions.data = [];

                $scope.filtrarFecha = false;

                $scope.setTipoReporte = function (item) {
                  $scope.gridOptions.data = [];
                  switch (item.tipoReporteId) {
                    case 1:
                      $scope.filtrarFecha = false;
                      $scope.gridOptions.columnDefs = [
                        { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                        { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '15%' },
                        { field: 'personaDesc', name: 'personaDesc', displayName: 'Persona', width: '26%' },
                        { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha de asignación', width: '10%' },
                        { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha de Recepcion', width: '10%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'vencido', name: 'vencido', displayName: 'Vencido', width: '10%' },
                        { field: 'diasHabiles', name: 'diasHabiles', displayName: 'Dias hábiles', width: '10%' },
                        { field: 'taskDesc', name: 'taskDesc', displayName: 'Tarea', width: '20%' },
                        { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                        { field: 'observaciones', name: 'observaciones', displayName: 'Observaciones', width: '25%' }
                      ];
                    break;
                    case 2:
                      $scope.filtrarFecha = true;
                      $scope.gridOptions.columnDefs = [
                        { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                        { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '15%' },
                        { field: 'personaDesc', name: 'personaDesc', displayName: 'Persona', width: '26%' },
                        { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha de asignación', width: '10%' },
                        { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Fecha vencimiento', width: '10%' },
                        { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha finalización', width: '10%' },
                        { field: 'gestionDesc', name: 'gestionDesc', displayName: 'Gestión', width: '10%' },
                        { field: 'dias', name: 'dias', displayName: 'Dias hábiles', width: '10%' },
                        { field: 'taskDesc', name: 'taskDesc', displayName: 'Tarea', width: '20%' },
                        { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' }
                      ];
                      break;
                    case 3:
                      $scope.filtrarFecha = true;
                      $scope.gridOptions.columnDefs = [
                        { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                        { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '15%' },
                        { field: 'departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '15%' },
                        { field: 'municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '15%' },
                        { field: 'personaDesc', name: 'personaDesc', displayName: 'Nombre del titular', width: '15%' },
                        { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                        { field: 'categoriaInscripcion', name: 'categoriaInscripcion', displayName: 'Categoría inscripción', width: '15%' },
                        { field: 'fechaInicio', name: 'fechaInicio', displayName: 'Fecha inicio gestión', width: '10%' },
                        { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha finalización', width: '10%' },
                        { field: 'anio', name: 'anio', displayName: 'Año', width: '10%' },
                        { field: 'mes', name: 'mes', displayName: 'Mes', width: '10%' },
                        { field: 'dias', name: 'dias', displayName: 'Dias hábiles', width: '10%' }
                      ];
                      break;
                    case 4:
                      $scope.filtrarFecha = true;
                      $scope.gridOptions.columnDefs = [
                        { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                        { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '15%' },
                        { field: 'departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '15%' },
                        { field: 'municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '15%' },
                        { field: 'personaDesc', name: 'personaDesc', displayName: 'Nombre del titular', width: '15%' },
                        { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                        { field: 'categoriaInscripcion', name: 'categoriaInscripcion', displayName: 'Categoría inscripción', width: '15%' },
                        { field: 'fechaInicio', name: 'fechaInicio', displayName: 'Fecha inicio gestión', width: '10%' },
                        { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha finalización', width: '10%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'Resolución', width: '10%' },
                        { field: 'anio', name: 'anio', displayName: 'Año', width: '10%' },
                        { field: 'mes', name: 'mes', displayName: 'Mes', width: '10%' },
                        { field: 'dias', name: 'dias', displayName: 'Dias hábiles', width: '10%' }
                      ];
                      break;
                    case 5:
                      $scope.filtrarFecha = true;
                      $scope.gridOptions.columnDefs = [
                        { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                        { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '15%' },
                        { field: 'departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento', width: '15%' },
                        { field: 'municipioDesc', name: 'municipioDesc', displayName: 'Municipio', width: '15%' },
                        { field: 'personaDesc', name: 'personaDesc', displayName: 'Nombre del titular', width: '15%' },
                        { field: 'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                        { field: 'categoriaInscripcion', name: 'categoriaInscripcion', displayName: 'Categoría inscripción', width: '15%' },
                        { field: 'fechaInicio', name: 'fechaInicio', displayName: 'Fecha inicio gestión', width: '10%' },
                        { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha finalización', width: '10%' },
                        { field: 'resolucion', name: 'resolucion', displayName: 'Resolución', width: '10%' },
                        { field: 'noInforme', name: 'noInforme', displayName: 'No. Informe', width: '10%' },
                        { field: 'anio', name: 'anio', displayName: 'Año', width: '10%' },
                        { field: 'mes', name: 'mes', displayName: 'Mes', width: '10%' },
                        { field: 'dias', name: 'dias', displayName: 'Dias hábiles', width: '10%' }
                      ];
                      break;
                  }
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = null;
                    var fechaFin = null;
                    fechaIni = $filter('date')($scope.data.fechaIni, 'yyyy-MM-dd');
                    fechaFin = $filter('date')($scope.data.fechaFin, 'yyyy-MM-dd');
                    switch ($scope.data.tipoReporteId) {
                      case 1:
                        tareaService.getReporteTareaExento($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaRegistro = $filter('date')(new Date(data[i].fechaRegistro), 'dd/MM/yyyy');
                              row.fechaRecepcion = $filter('date')(new Date(data[i].fechaRecepcion), 'dd/MM/yyyy');
                              row.fechaVencimiento = $filter('date')(new Date(data[i].fechaVencimiento), 'dd/MM/yyyy');
                            }
                            $scope.gridOptions.data = data;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 2:
                        tareaService.detalleTiempoTareasExentoFinalizadas($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaRegistro = $filter('date')(new Date(data[i].fechaRegistro), 'dd/MM/yyyy');
                              row.fechaFinalizacion = $filter('date')(new Date(data[i].fechaFinalizacion), 'dd/MM/yyyy');
                              row.fechaVencimiento = $filter('date')(new Date(data[i].fechaVencimiento), 'dd/MM/yyyy');
                            }
                            $scope.gridOptions.data = data;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 3:
                        tareaService.tiempoCargaInicialExento($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaInicio = $filter('date')(new Date(data[i].fechaInicio), 'dd/MM/yyyy');
                              row.fechaFinalizacion = $filter('date')(new Date(data[i].fechaFinalizacion), 'dd/MM/yyyy');                              
                            }
                            $scope.gridOptions.data = data;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 4:
                        tareaService.tiempoVentaNotaExento($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaInicio = $filter('date')(new Date(data[i].fechaInicio), 'dd/MM/yyyy');
                              row.fechaFinalizacion = $filter('date')(new Date(data[i].fechaFinalizacion), 'dd/MM/yyyy');                              
                            }
                            $scope.gridOptions.data = data;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      case 5:
                        tareaService.tiempoInformeNotaExento($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, fechaIni, fechaFin, $scope.data.expediente).then(function (res) {
                          if (res.status == 1) {
                            var data = res.data;
                            for (let i = 0; i < data.length; i++) {
                              var row = data[i];
                              row.fechaInicio = $filter('date')(new Date(data[i].fechaInicio), 'dd/MM/yyyy');
                              row.fechaFinalizacion = $filter('date')(new Date(data[i].fechaFinalizacion), 'dd/MM/yyyy');                              
                            }
                            $scope.gridOptions.data = data;
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        break;
                      default:
                        break;
                    }
                  }
                }

                $scope.exportarPdfTareasExento = function () {
                  var columns = [];
                  var columnStyles = [];
                  var alinearDerecha = [];
                  var fechaIni = null;
                  var fechaFin = null;
                  var titulo = 'Reporte de tareas de exentos';
                  var subtitulo = '';
                  if ($scope.data.tipoReporteId == 1) {
                    subtitulo = 'Hasta el ' + $filter('date')(new Date(), 'dd/MM/yyyy');;
                  } else {
                    fechaIni = $filter('date')($scope.data.fechaInicio, 'dd/MM/yyyy');
                    fechaFin = $filter('date')($scope.data.fechaFinal, 'dd/MM/yyyy');
                    subtitulo = 'Rango de fecha del ' + fechaIni + ' al ' + fechaFin;
                  }
                  var data = $scope.gridOptions.data;
                  columns = [
                    { title: 'Región', dataKey: 'regionDesc' },
                    { title: 'Subregión', dataKey: 'subregionDesc' },
                    { title: 'Persona', dataKey: 'personaDesc' },
                    { title: 'Fecha registro', dataKey: 'fechaRegistro' },
                    { title: 'Fecha recepcion', dataKey: 'fechaRecepcion' },
                    { title: 'Fecha vencimiento', dataKey: 'fechaVencimiento' },
                    { title: 'Vencido', dataKey: 'vencido' },
                    { title: 'Dias hábiles', dataKey: 'diasHabiles' },
                    { title: 'Tarea', dataKey: 'taskDesc' },
                    { title: 'RNF', dataKey: 'codigo' },
                    { title: 'Observaciones', dataKey: 'observaciones' },
                  ];
                  columnStyles = {
                    regionDesc: { columnWidth: 40 },
                    subregionDesc: { columnWidth: 45 },
                    personaDesc: { columnWidth: 140 },
                    fechaRegistro: { columnWidth: 100 },
                    fechaRecepcion: { columnWidth: 100 },
                    fechaVencimiento: { columnWidth: 100 },
                    vencido: { columnWidth: 70 },
                    diasHabiles: { columnWidth: 25 },
                    taskDesc: { columnWidth: 175 },
                    codigo: { columnWidth: 70 },
                    observaciones: { columnWidth: 100 }
                  }
                  alinearDerecha = [];
                  margin = { top: 90, left: 10, right: 10, button: 30 };
                  fontSize = {
                    header: 9,
                    detail: 9
                  }
                  utilsService.reporteTabla(columns, data, 'l', 'legal', titulo, subtitulo, $scope.loginData.usuario, $scope.dataEmpresa, alinearDerecha, null, null, columnStyles, null, margin, fontSize);
                }

                $scope.exportarPdf = function () {
                  if ($scope.gridOptions.data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                }

                $scope.exportarExcelTareasExento = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Persona', 'Fecha registro', 'Fecha Recepión', 'Fecha vencimiento',
                      'Vencido', 'Días ', 'Tarea', 'RNF', 'Observación'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].regionDesc);
                      xlsxRow.push(data[i].subregionDesc);
                      xlsxRow.push(data[i].personaDesc);
                      xlsxRow.push(data[i].fechaRegistro);
                      xlsxRow.push(data[i].fechaRecepcion);
                      xlsxRow.push(data[i].fechaVencimiento);
                      xlsxRow.push(data[i].vencido);
                      xlsxRow.push(data[i].diasHabiles);
                      xlsxRow.push(data[i].taskDesc);
                      xlsxRow.push(data[i].codigo);
                      xlsxRow.push(data[i].observaciones);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcelTareasDetalleExento = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Persona', 'Fecha asignación', 'Fecha vencimiento', 'Fecha finalización',
                      'Días laborales', 'Mes', 'Año', 'Código venta', 'Código gestión', 'Código informe', 'Gestión', 'Tarea',
                      'RNF', 'Resultado', 'Estado', 'Resolución'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].regionDesc);
                      xlsxRow.push(data[i].subregionDesc);
                      xlsxRow.push(data[i].personaDesc);
                      xlsxRow.push(data[i].fechaRegistro);
                      xlsxRow.push(data[i].fechaVencimiento);
                      xlsxRow.push(data[i].fechaFinalizacion);
                      xlsxRow.push(data[i].dias);
                      xlsxRow.push(data[i].mes);
                      xlsxRow.push(data[i].anio);
                      xlsxRow.push(data[i].codigoVenta);
                      xlsxRow.push(data[i].exentoId);
                      xlsxRow.push(data[i].codigoInforme);
                      xlsxRow.push(data[i].gestionDesc);
                      xlsxRow.push(data[i].taskDesc);
                      xlsxRow.push(data[i].codigo);
                      xlsxRow.push(data[i].resultado);
                      xlsxRow.push(data[i].estado);
                      xlsxRow.push(data[i].resolucion);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcelTiempoExentoCargaInicial = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Nombre del titular', 'RNF', 'Categoría de inscripción', 
                    'Fecha de inicio gestión', 'Fecha finalización', 'Mes', 'Año', 'Días laborales'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      const row = data[i];
                      xlsxRow.push(row.regionDesc);
                      xlsxRow.push(row.subregionDesc);
                      xlsxRow.push(row.departamentoDesc);
                      xlsxRow.push(row.municipioDesc);
                      xlsxRow.push(row.personaDesc);
                      xlsxRow.push(row.codigo);
                      xlsxRow.push(row.categoriaInscripcion);
                      xlsxRow.push(row.fechaInicio);
                      xlsxRow.push(row.fechaFinalizacion);
                      xlsxRow.push(row.mes);
                      xlsxRow.push(row.anio);
                      xlsxRow.push(row.dias);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcelTiempoExentoVenta = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Nombre del titular', 'RNF', 'Categoría de inscripción', 
                    'Fecha de inicio gestión', 'Fecha finalización', 'Resolución', 'Mes', 'Año', 'Días laborales'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      const row = data[i];
                      xlsxRow.push(row.regionDesc);
                      xlsxRow.push(row.subregionDesc);
                      xlsxRow.push(row.departamentoDesc);
                      xlsxRow.push(row.municipioDesc);
                      xlsxRow.push(row.personaDesc);
                      xlsxRow.push(row.codigo);
                      xlsxRow.push(row.categoriaInscripcion);
                      xlsxRow.push(row.fechaInicio);
                      xlsxRow.push(row.fechaFinalizacion);
                      xlsxRow.push(row.resolucion);
                      xlsxRow.push(row.mes);
                      xlsxRow.push(row.anio);
                      xlsxRow.push(row.dias);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcelTiempoInformeExento = function () {
                  var data = $scope.gridOptions.data;
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Nombre del titular', 'RNF', 'Categoría de inscripción', 
                    'Resolución', 'No. Informe', 'Fecha de inicio gestión', 'Fecha finalización', 'Mes', 'Año', 'Días laborales'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      const row = data[i];
                      xlsxRow.push(row.regionDesc);
                      xlsxRow.push(row.subregionDesc);
                      xlsxRow.push(row.departamentoDesc);
                      xlsxRow.push(row.municipioDesc);
                      xlsxRow.push(row.personaDesc);
                      xlsxRow.push(row.codigo);
                      xlsxRow.push(row.categoriaInscripcion);
                      xlsxRow.push(row.resolucion);
                      xlsxRow.push(row.noInforme);
                      xlsxRow.push(row.fechaInicio);
                      xlsxRow.push(row.fechaFinalizacion);
                      xlsxRow.push(row.mes);
                      xlsxRow.push(row.anio);
                      xlsxRow.push(row.dias);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "data.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcel = function () {
                  var data = $scope.gridOptions.data;
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  switch ($scope.data.tipoReporteId) {
                    case 1:
                      $scope.exportarExcelTareasExento();
                      break;
                    case 2:
                      $scope.exportarExcelTareasDetalleExento();
                      break;
                    case 3:
                      $scope.exportarExcelTiempoExentoCargaInicial();
                      break;
                    case 4:
                      $scope.exportarExcelTiempoExentoVenta();
                      break;
                    case 5:
                      $scope.exportarExcelTiempoInformeExento();
                      break;
                  }
                }

              }]
          })
          .state('index.reporte.tiempo', {
            url: '/tiempo',
            templateUrl: 'app/reporte/reporte.tiempo.tpl.html',
            resolve: {
            },
            controller: ['$scope', '$state', 'toastr', 'tareaService', '$filter', 'utilsService',
              function ($scope, $state, toastr, tareaService, $filter, utilsService) {

                $scope.dataMes = [
                  { mes: 1, mesDesc: 'Enero' },
                  { mes: 2, mesDesc: 'Febrero' },
                  { mes: 3, mesDesc: 'Marzo' },
                  { mes: 4, mesDesc: 'Abril' },
                  { mes: 5, mesDesc: 'Mayo' },
                  { mes: 6, mesDesc: 'Junio' },
                  { mes: 7, mesDesc: 'Julio' },
                  { mes: 8, mesDesc: 'Agosto' },
                  { mes: 9, mesDesc: 'Septiembre' },
                  { mes: 10, mesDesc: 'Octubre' },
                  { mes: 11, mesDesc: 'Noviembre' },
                  { mes: 12, mesDesc: 'Diciembre' },
                ];

                $scope.data = {
                  mes: null,
                  anio: null
                };



                if ($scope.loginData.perfilId != 1 && $scope.loginData.perfilId != 16 && $scope.loginData.perfilId != 36) {
                  $state.go('index.forbidden.show');
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    tareaService.getReporteTiempo($scope.data.anio, $scope.data.mes).then(function (res) {
                      if (res.status == 1) {
                        $scope.exportarExcel(res.data);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.tiempoTareas = function () {
                  if ($scope.data.mes == null || $scope.data.anio == null) {
                    toastr.error('Seleccione el mes e indique el año a buscar');
                    return;
                  }
                  tareaService.getReporteTiempoTareas($scope.data.anio, $scope.data.mes).then(function (res) {
                    if (res.status == 1) {
                      $scope.exportarExcelTareas(res.data);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.generarTiempoLicencia = function () {
                  if ($scope.data.mes == null || $scope.data.anio == null) {
                    toastr.error('Seleccione el mes e indique el año a buscar');
                    return;
                  }
                  tareaService.generarReporteTiempo($scope.data.anio, $scope.data.mes).then(function (res) {
                    if (res.status == 1) {
                      toastr.success(res.message);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.generarTiempoTareas = function () {
                  if ($scope.data.mes == null || $scope.data.anio == null) {
                    toastr.error('Seleccione el mes e indique el año a buscar');
                    return;
                  }
                  tareaService.generarReporteTiempoTareas($scope.data.anio, $scope.data.mes).then(function (res) {
                    if (res.status == 1) {
                      toastr.success(res.message);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.exportarExcelTareas = function (data) {
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte, use la opción correspondiente para generar los datos');
                    return;
                  }
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Expediente', 'Tipo de Licencia', 'Responsable', 'Tarea', 'Días extras'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].region);
                      xlsxRow.push(data[i].subregion);
                      xlsxRow.push(data[i].expediente);
                      xlsxRow.push(data[i].tipoLicencia);
                      xlsxRow.push(data[i].responsable);
                      xlsxRow.push(data[i].tarea);
                      xlsxRow.push(data[i].extras);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "tareas.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

                $scope.exportarExcel = function (data) {
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte, use la opción correspondiente para generar los datos');
                    return;
                  }
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['Región', 'Subregión', 'Departamento', 'Municipio', 'Expediente',
                      'Tipo de Licencia', 'Resolución de Aprobación', 'Fecha de Ingreso', 'Fecha de Aprobación', 'Tiempo INAB',
                      'Tiempo enmienda', 'Tiempo requisito', 'Tiempo Total', 'Fecha de vigencia de la licencia', 'Titular', 'Sexo',
                      'Representante', 'No. POAs', 'Área bajo manejo', 'Área de protección', 'Área de producción',
                      'Estado', 'No. POAs', 'Volumen', 'Ubicación GTM'
                    ];
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(data[i].region);
                      xlsxRow.push(data[i].subregion);
                      xlsxRow.push(data[i].departamento);
                      xlsxRow.push(data[i].municipio);
                      xlsxRow.push(data[i].expediente);
                      xlsxRow.push(data[i].tipoLicencia);
                      xlsxRow.push(data[i].licencia);
                      xlsxRow.push(data[i].fechaIngreso);
                      xlsxRow.push(data[i].fechaAprobacion);
                      xlsxRow.push(data[i].tiempoInab);
                      xlsxRow.push(data[i].tiempoEnmienda);
                      xlsxRow.push(data[i].tiempoRequisito);
                      xlsxRow.push(data[i].tiempoTotal);
                      xlsxRow.push(data[i].vigenciaLicencia);
                      xlsxRow.push(data[i].titular);
                      xlsxRow.push(data[i].sexo);
                      xlsxRow.push(data[i].representante);
                      xlsxRow.push(data[i].noPoas);
                      xlsxRow.push(data[i].areaManejo);
                      xlsxRow.push(data[i].areaProteccion);
                      xlsxRow.push(data[i].areaProduccion);
                      xlsxRow.push(data[i].estado);
                      xlsxRow.push(data[i].noPoas);
                      xlsxRow.push(data[i].volumen);
                      xlsxRow.push(data[i].gtmX + ', ' + data[i].gtmY);
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "licencias.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

              }]
          })
          .state('index.reporte.consumo', {
            url: '/consumo',
            templateUrl: 'app/reporte/reporte.consumo.tpl.html',
            resolve: {
              dataRegion: ['regionService',
                function (regionService) {
                  return regionService.listaActiva();
                }],
              dataDepartamento: ['departamentoService',
                function (departamentoService) {
                  return departamentoService.listaPorPais(1);
                }]
            },
            controller: ['$scope', '$state', 'toastr', 'dataRegion', 'consumoService', 'subregionService', '$filter', 'utilsService',
              'dataDepartamento', 'municipioService', 'subregionmunicipioService',
              function ($scope, $state, toastr, dataRegion, consumoService, subregionService, $filter, utilsService,
                dataDepartamento, municipioService, subregionmunicipioService) {

                $scope.dataRegion = dataRegion.data;
                $scope.dataDepartamento = dataDepartamento.data;
                $scope.dataRegion.push({ regionId: 0, regionDesc: 'Todos' });
                $scope.dataDepartamento.push({ departamentoId: 0, departamentoDesc: 'Todos' });
                $scope.dataSubregion = [];
                $scope.dataMunicipio = [];
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 30);
                $scope.data = {
                  fechaInicio: begin,
                  fechaFinal: now
                };
                $scope.mostrarTipoReporte = false;
                $scope.mostrarMunicipio = false;
                var dataTipoReporte = [
                  { tipoReporteId: 0, tipoReporteDesc: 'Consumos Familiares Municipales' }
                ];
                $scope.data.tipoReporteId = 0;

                $scope.perteneceInab = function (_perfilId) {
                  var perfilesInab = [5, 8, 12, 16, 18, 20, 33, 36];
                  for (var i = 0; i < perfilesInab.length; i++) {
                    var perfilId = perfilesInab[i];
                    if (_perfilId == perfilId) {
                      return true;
                    }
                  }
                  return false;
                }
                $scope.dataTipoReporte = dataTipoReporte;
                $scope.tituloFecha = '';

                if ($scope.perteneceInab($scope.loginData.perfilId)) {
                  $scope.data.tipoReporteId = null;
                  $scope.mostrarTipoReporte = true;
                  dataTipoReporte.push({ tipoReporteId: 1, tipoReporteDesc: 'Consumos Familiares (INAB)' });
                  dataTipoReporte.push({ tipoReporteId: -1, tipoReporteDesc: 'Todos' });
                }

                $scope.setRegion = function (item) {
                  $scope.data.subregionId = null;
                  if (item) {
                    if (item.regionId == 0) {
                      $scope.dataSubregion = [];
                      $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                      $scope.data.subregionId = 0;
                    } else {
                      subregionService.listaPorRegion(item.regionId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataSubregion = res.data;
                          $scope.dataSubregion.push({ subregionId: 0, subregionDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  } else {
                    $scope.dataSubregion = [];
                  }
                }

                $scope.setDepartamento = function (item) {
                  $scope.dataMunicipio = [];
                  $scope.data.municipioId = null;
                  if (item) {
                    if (item.departamentoId == 0) {
                      if ($scope.loginData.perfilId == 5) {
                        subregionmunicipioService.getMunicipiosBySubregion($scope.loginData.subregionId).then(function (res) {
                          if (res.status == 1) {
                            $scope.dataMunicipio = res.data;
                            $scope.dataMunicipio.push({ municipioId: 0, municipioDesc: 'Todos' });
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        $scope.dataMunicipio.push({ municipioId: 0, municipioDesc: 'Todos' });
                        $scope.data.municipioId = 0;
                      }
                    } else {
                      municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataMunicipio = res.data;
                          $scope.dataMunicipio.push({ municipioId: 0, municipioDesc: 'Todos' });
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                  }
                }

                if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                  var tcRegion = {
                    regionId: $scope.loginData.regionId
                  }
                  $scope.setRegion(tcRegion);
                  $scope.data.regionId = $scope.loginData.regionId;
                  if ($scope.loginData.perfilId == 5) {
                    $scope.data.subregionId = $scope.loginData.subregionId;
                  }
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.exporterOlderExcelCompatibility = true;
                $scope.gridOptions.enableGridMenu = true;
                $scope.gridOptions.exporterCsvFilename = 'reporte.csv';
                $scope.gridOptions.exporterPdfDefaultStyle = { fontSize: 9 };
                $scope.gridOptions.exporterPdfTableStyle = { margin: [30, 30, 30, 30] };
                $scope.gridOptions.exporterPdfTableHeaderStyle = { fontSize: 10, bold: true, italics: true, color: 'blue' };
                $scope.gridOptions.exporterPdfHeader = { text: 'Lista de tareas', style: 'headerStyle' };
                $scope.gridOptions.exporterPdfFooter = function (currentPage, pageCount) {
                  return { text: currentPage.toString() + ' de ' + pageCount.toString() + ' - Manejo Forestal - ', style: 'footerStyle' };
                };
                $scope.gridOptions.exporterPdfCustomFormatter = function (docDefinition) {
                  docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                  docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                  return docDefinition;
                };
                $scope.gridOptions.exporterPdfOrientation = 'landscape';
                $scope.gridOptions.exporterPdfPageSize = 'LETTER';
                $scope.gridOptions.exporterPdfMaxGridWidth = 600;
                $scope.gridOptions.exporterCsvLinkElement = angular.element(document.querySelectorAll('.custom-csv-link-location'));

                $scope.gridOptions.onRegisterApi = function (gridApi) {
                  $scope.gridApi = gridApi;
                }
                $scope.gridOptions.columnDefs = [
                  { field: 'regionDesc', name: 'regionDesc', displayName: 'Región', width: '10%' },
                  { field: 'subregionDesc', name: 'subregionDesc', displayName: 'Subregión', width: '10%' },
                  { field: 'codigo', name: 'codigo', displayName: 'No. Resolución', width: '15%' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Fecha autorización', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'', width: '10%' },
                  { field: 'departamentoDesc', name: 'departamentoDesc', displayName: 'Departamento' },
                  { field: 'municipioDesc', name: 'municipioDesc', displayName: 'Municipio' },
                  { field: 'personaDesc', name: 'personaDesc', displayName: 'Propietario', width: '20%' }
                ];
                $scope.gridOptions.data = [];

                $scope.setTipoReporte = function (item) {
                  $scope.data.departamentoId = null;
                  $scope.data.municipioId = null;
                  $scope.data.regionId = null;
                  $scope.data.subregionId = null;
                  $scope.gridOptions.data = [];
                  switch (item.tipoReporteId) {
                    case -1:
                      if ($scope.perteneceInab($scope.loginData.perfilId)) {
                        $scope.mostrarSubregion = true;
                        $scope.mostrarMunicipio = true;
                        if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                          $scope.mostrarRegion = false;
                          if ($scope.loginData.perfilId == 5) {
                            $scope.mostrarSubregion = false;
                          }
                        } else {
                          $scope.mostrarRegion = true;
                        }
                      }
                      break;
                    case 0:
                      if ($scope.perteneceInab($scope.loginData.perfilId)) {
                        $scope.mostrarMunicipio = true;
                      }
                      $scope.mostrarRegion = false;
                      $scope.mostrarSubregion = false;
                      break;
                    case 1:
                      if ($scope.perteneceInab($scope.loginData.perfilId)) {
                        $scope.mostrarSubregion = true;
                        if ($scope.loginData.perfilId == 5) {
                          $scope.mostrarSubregion = false;
                        }
                        if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                          $scope.mostrarRegion = false;
                        } else {
                          $scope.mostrarRegion = true;
                        }
                      }
                      $scope.mostrarMunicipio = false;
                      break;
                  }
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')($scope.data.fechaInicio, 'yyyy-MM-dd');
                    var fechaFin = $filter('date')($scope.data.fechaFinal, 'yyyy-MM-dd');
                    consumoService.getReporteByPersona($scope.data.tipoReporteId, $scope.data.regionId, $scope.data.subregionId, $scope.data.departamentoId, $scope.data.municipioId, fechaIni, fechaFin).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        if (data == null) {
                          data = [];
                        }
                        $scope.gridOptions.data = data;
                        if (data.length > 0) {
                          toastr.success(res.message);
                        } else {
                          toastr.warning('No se encontraron registros con el filtro indicado');
                        }
                      } else {
                        $scope.gridOptions.data = [];
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      $scope.gridOptions.data = [];
                      toastr.error(error);
                    });
                  }
                }

                $scope.exportarPdf = function () {
                  if ($scope.gridOptions.data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  var columns = [];
                  var columnStyles = [];
                  var alinearDerecha = [];
                  var fechaIni = null;
                  var fechaFin = null;
                  var expediente = null;
                  var titulo = 'Reporte de tareas';
                  var subtitulo = '';
                  fechaIni = $filter('date')($scope.data.fechaInicio, 'dd/MM/yyyy');
                  fechaFin = $filter('date')($scope.data.fechaFinal, 'dd/MM/yyyy');
                  subtitulo = 'Rango de fecha del ' + fechaIni + ' al ' + fechaFin;
                  var data = angular.copy($scope.gridOptions.data);
                  for (var i = 0; i < data.length; i++) {
                    var element = data[i];
                    element.numero = i + 1;
                    element.fechaFinalizacion = $filter('date')(element.fechaFinalizacion, 'dd/MM/yyyy');
                  }
                  columns = [
                    { title: 'No.', dataKey: 'numero' },
                    { title: 'Región', dataKey: 'regionDesc' },
                    { title: 'Subregión', dataKey: 'subregionDesc' },
                    { title: 'Departamento', dataKey: 'departamentoDesc' },
                    { title: 'Municipio', dataKey: 'municipioDesc' },
                    { title: 'Expediente', dataKey: 'expediente' },
                    { title: 'No. Resolución aprobación', dataKey: 'codigo' },
                    { title: 'Fecha Resolución', dataKey: 'fechaFinalizacion' },
                    { title: 'Titular', dataKey: 'personaDesc' },
                    { title: 'Volumen total m³', dataKey: 'volumen' }
                  ];
                  columnStyles = {
                    numero: { columnWidth: 40 },
                    regionDesc: { columnWidth: 40 },
                    subregionDesc: { columnWidth: 50 },
                    departamentoDesc: { columnWidth: 100 },
                    municipioDesc: { columnWidth: 120 },
                    expediente: { columnWidth: 120 },
                    codigo: { columnWidth: 130 },
                    fechaFinalizacion: { columnWidth: 90 },
                    personaDesc: { columnWidth: 200 },
                    volumen: { columnWidth: 90 }
                  }
                  alinearDerecha = [
                    { columna: 7 },
                    { columna: 9 }
                  ];
                  switch ($scope.data.tipoReporteId) {
                    case -1:
                      titulo = 'Reporte de Consumos Familiares';
                      break;
                    case 0:
                      titulo = 'Reporte de Consumos Familiares Municipales';
                      break;
                    case 1:
                      titulo = 'Reporte de Consumos Familiares emitidos por INAB';
                      break;
                    default:
                      titulo = 'Reporte de Consumos Familiares';
                      break;
                  }

                  margin = { top: 90, left: 10, right: 10, button: 30 };
                  fontSize = {
                    header: 9,
                    detail: 9
                  }
                  utilsService.reporteTabla(columns, data, 'l', 'legal', titulo, subtitulo, $scope.loginData.usuario, $scope.dataEmpresa, alinearDerecha, null, null, columnStyles, null, margin, fontSize);
                }

                $scope.exportarExcel = function () {
                  var data = angular.copy($scope.gridOptions.data);
                  for (var i = 0; i < data.length; i++) {
                    var element = data[i];
                    element.numero = i + 1;
                    element.fechaFinalizacion = $filter('date')(element.fechaFinalizacion, 'dd/MM/yyyy');
                    element.fechaSolicitud = $filter('date')(element.fechaSolicitud, 'dd/MM/yyyy');
                    if (element.solicitudNotas != null) {
                      element.solicitudNotas = $filter('date')(element.solicitudNotas, 'dd/MM/yyyy');
                      element.entregaNotas = $filter('date')(element.entregaNotas, 'dd/MM/yyyy');
                    }
                    element.esMunicipal = element.paraSubregion == 1 ? 'No' : 'Si';
                  }
                  if (data.length <= 0) {
                    toastr.error('No hay datos para generar reporte');
                    return;
                  }
                  try {
                    var xlsxData = [];
                    var xlsxHeader = [];
                    xlsxHeader = ['No.', 'Región', 'Subregión', 'Departamento', 'Municipio', 'Es municipal', 'No. Expediente', 'No. Resolución aprobación', 'Fecha resolución',
                      'Titular', 'Sexo del poseedor', 'Número de árboles', 'Volumen Leña m³', 'Volumen Troza m³', 'Volumen total m³', 'Especie(s)', 'No. Notas Envío'
                    ];
                    if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 20 || $scope.loginData.perfilId == 36) {
                      xlsxHeader.push('Fecha solicitud');
                      xlsxHeader.push('Dias utilizados');
                      xlsxHeader.push('Fecha solicitud notas');
                      xlsxHeader.push('Fecha resolucion notas');
                      xlsxHeader.push('Dias utilizados notas');
                    }
                    xlsxData.push(xlsxHeader);
                    for (var i = 0; i < data.length; i++) {
                      var xlsxRow = [];
                      xlsxRow.push(i + 1);
                      xlsxRow.push(data[i].regionDesc);
                      xlsxRow.push(data[i].subregionDesc);
                      xlsxRow.push(data[i].departamentoDesc);
                      xlsxRow.push(data[i].municipioDesc);
                      xlsxRow.push(data[i].esMunicipal);
                      xlsxRow.push(data[i].expediente);
                      xlsxRow.push(data[i].codigo);
                      xlsxRow.push(data[i].fechaFinalizacion);
                      xlsxRow.push(data[i].personaDesc);
                      xlsxRow.push(data[i].sexoDesc);
                      xlsxRow.push(data[i].arboles);
                      xlsxRow.push(data[i].lenia);
                      xlsxRow.push(data[i].troza);
                      xlsxRow.push(data[i].volumen);
                      xlsxRow.push(data[i].especies);
                      xlsxRow.push(data[i].notas);
                      if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 20 || $scope.loginData.perfilId == 36) {
                        xlsxRow.push(data[i].fechaSolicitud);
                        xlsxRow.push(data[i].dias);
                        xlsxRow.push(data[i].solicitudNotas);
                        xlsxRow.push(data[i].entregaNotas);
                        xlsxRow.push(data[i].diasNotas);
                      }
                      xlsxData.push(xlsxRow);
                    }
                    var filename = "consumos.xlsx";
                    var sheetName = "data";
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                    XLSX.utils.book_append_sheet(wb, ws, sheetName);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e.message);
                  }
                }

              }]
          })
      }
    ]
  );
