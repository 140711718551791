angular.module('app.consulta', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.consulta', {
          abstract: true,
          url: 'consulta',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Consulta';
            }]
        })
        .state('index.consulta.list', {
          url: '/nota',
          templateUrl: 'app/consulta/consulta.nota.tpl.html',
          resolve: {
            dataTipoNotaEnvio: ['tiponotaenvioService',
              function ( tiponotaenvioService ){
                return tiponotaenvioService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'notaenvioService', 'dataTipoNotaEnvio',
            function (  $scope, $state, toastr, notaenvioService, dataTipoNotaEnvio) {

              $scope.dataTipoNotaEnvio = dataTipoNotaEnvio.data;
              $scope.data = {};
              $scope.fincaDesc = '';
              $scope.ubicacionFinca = '';

              $scope.submitForm = function ( isValid ) {
                $scope.exentoExpediente = false;
                $scope.licenciaExpediente = false;
                $scope.esGeneral = false;
                $scope.textoExpediente = '';
                $scope.representante = '';
                $scope.mostrarAnulado = false;
                if ( isValid ) {
                  notaenvioService.getInformacionNota( $scope.data.tipoNotaEnvioId, $scope.data.numero ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      var consulta = res.data[0];
                      if (consulta.anulado) {
                        $scope.anulacion = consulta.anulacion;
                        $scope.mostrarAnulado = true;
                      } else {
                        $scope.anulacion = null;
                        $scope.mostrarAnulado = false;
                      }
                      var data = consulta.data;
                      if (!consulta.deSistema) {
                        if (data.tcReferenciaNota.representante == null) {
                          data.tcReferenciaNota.representante = '-';
                        }
                        $scope.thMovimientoNota = data;
                        $scope.propietario = data.tcReferenciaNota.referenciaNombre;
                        $scope.representante = 'Representante: ' + data.tcReferenciaNota.representante;
                        $scope.esGeneral = true;
                        switch ($scope.data.tipoNotaEnvioId) {
                          case 1:
                            $scope.textoExpediente = 'Expediente o licencia';
                            break;
                          case 2:
                            $scope.textoExpediente = 'Código RNF';
                            break;
                          default:
                            $scope.textoExpediente = 'Expediente o licencia';
                            break;
                        }
                      } else {
                        switch ($scope.data.tipoNotaEnvioId) {
                          case 1:
                            $scope.ttRangoNota = data;
                            $scope.ttPoaGestion = data.ttPoaGestion;
                            var ttGestion = data.ttPoaGestion.ttGestion;
                            $scope.ttGestion = ttGestion;
                            $scope.ttTareaPoa = data.ttPoaGestion.ttTarea;
                            $scope.ttTareaLicencia = data.ttPoaGestion.ttTarea.ttPadre;
                            if (ttGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                              $scope.propietario = ttGestion.ttTipoPropietarioGestion.razonSocial;
                            } else {
                              var propietarios = ttGestion.personas;
                              var nombres = '';
                              for (var i = 0; i < propietarios.length; i++) {
                                if (i == 0) {
                                  nombres = propietarios[i].tcPersona.personaDesc;
                                } else {
                                  nombres += ', ' + propietarios[i].tcPersona.personaDesc;
                                }
                              }
                              $scope.propietario = nombres;
                            }
                            var fincas = ttGestion.fincas;
                            var fincaDesc = '', ubicacionFinca = '';
                            for (var i = 0; i < fincas.length; i++) {
                              if (i == 0) {
                                fincaDesc = fincas[i].tcFinca.fincaDesc;
                                ubicacionFinca = fincas[i].tcFinca.tcMunicipio.municipioDesc + ', ' + fincas[i].tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
                              } else {
                                fincaDesc += ', ' + fincas[i].tcFinca.fincaDesc;
                              }
                            }
                            $scope.fincaDesc = fincaDesc;
                            $scope.ubicacionFinca = ubicacionFinca;
                            $scope.licenciaExpediente = true;
                            break;
                          case 2:
                            $scope.ttVentaExento = data;
                            if (data.ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
                              $scope.propietario = data.ttExento.ttJuridicaExento.razonSocial;
                            } else {
                              var propietarios = data.ttExento.propietarios;
                              var nombres = '';
                              for (var i = 0; i < propietarios.length; i++) {
                                if (i == 0) {
                                  nombres = propietarios[i].tcPersona.personaDesc;
                                } else {
                                  nombres += ', ' + propietarios[i].tcPersona.personaDesc;
                                }
                              }
                              $scope.propietario = nombres;
                            }
                            var fincas = data.ttExento.fincas;
                            var fincaDesc = '', ubicacionFinca = '';
                            for (var i = 0; i < fincas.length; i++) {
                              if (i == 0) {
                                fincaDesc = fincas[i].tcFinca.fincaDesc;
                                ubicacionFinca = fincas[i].tcFinca.direccion + ', ' + fincas[i].tcFinca.tcMunicipio.municipioDesc + ', ' + fincas[i].tcFinca.tcMunicipio.tcDepartamento.departamentoDesc;
                              } else {
                                fincaDesc += ', ' + fincas[i].tcFinca.fincaDesc;
                              }
                            }
                            $scope.fincaDesc = fincaDesc;
                            $scope.ubicacionFinca = ubicacionFinca;
                            $scope.exentoExpediente = true;                            
                            break;
                          default:
                            $scope.thMovimientoNota = data;
                            $scope.esGeneral = true;
                            break;
                        }
                      }                      
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.limpiarDatosBusqueda = function () {
                $scope.data.numero = null;
                $scope.exentoExpediente = false;
                $scope.licenciaExpediente = false;
                $scope.esGeneral = false;
                $scope.mostrarAnulado = false;
              }

            }]
        })
    }
  ]
);
