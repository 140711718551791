angular.module('app.menuperfilsistema', [
  'ui.router',
  'toastr',
  'app.menuperfilsistema.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.menuperfilsistema', {
          abstract: true,
          url: 'menuperfilsistema',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1006);
              }],
            dataPerfil: ['perfilService',
              function ( perfilService ){
                return perfilService.listaActiva();
              }],
            dataSistema: ['sistemaService',
              function ( sistemaService ){
                return sistemaService.listaActiva();
              }],
            dataMenu: ['menuService',
              function ( menuService ){
                return menuService.listaActiva();
              }],
            dataEstado: ['menuperfilsistemaService',
              function ( menuperfilsistemaService ){
                return menuperfilsistemaService.listStatus();
              }]
          },
          controller: ['$scope', 'dataPerfil', 'dataSistema', 'dataMenu', 'dataEstado',
            function ($scope, dataPerfil, dataSistema, dataMenu, dataEstado ) {
              $scope.dataPerfil = dataPerfil.data;
              $scope.dataSistema = dataSistema.data;
              $scope.dataMenu = dataMenu.data;
              $scope.dataEstado = dataEstado.data;
              $scope.dataPadre = [];
              $scope.module = 'Menu, perfil y sistema';
            }]
        })
        .state('index.menuperfilsistema.list', {
          url: '',
          templateUrl: 'app/menuperfilsistema/menuperfilsistema.list.tpl.html',
          resolve: {
          },
          controller: ['$scope', '$state', 'menuperfilsistemaService', 'toastr',
            function ($scope, $state, menuperfilsistemaService, toastr) {

              $scope.data = {
                tcPerfil: null
              };
              $scope.dataPadre = [];
              $scope.dataUsuario = null;
              $scope.isEdit = false;

              $scope.getNombrePadre = function ( padreId ) {
                if (padreId <= 0) {
                  return '';
                }
                for (var i = 0; i < $scope.dataPadre.length; i++) {
                  if ($scope.dataPadre[i].menuPerfilSistemaId == padreId) {
                    return $scope.dataPadre[i].tcMenu.menuDesc + '-' + $scope.dataPadre[i].menuPerfilSistemaId;
                  }
                }
                return '';
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'menuPerfilSistemaId', name: 'menuPerfilSistemaId', displayName: 'ID', width: '5%' },
                { field:'tcSistema.sistemaDesc', name: 'sistemaDesc', displayName: 'Sistema', width: '10%' },
                { field:'tcMenu.menuDesc', name: 'menuDesc', displayName: 'Nombre' },
                { field:'urlMenu', name: 'urlMenu', displayName: 'URL' },
                { name: 'padreId', displayName: "Padre", field: 'padreId', width: '10%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.getNombrePadre(row.entity.padreId)}}</div>' },
                { field:'codigoPantalla', name: 'codigoPantalla', displayName: 'Codigo', width: '10%' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', width: '10%',
                cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false, width: '8%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + '<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar"> <i class="fa fa-edit"></i> </button>'
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = [];

              $scope.setPerfil = function (item) {
                menuperfilsistemaService.menuByPerfil( item.perfilId).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.data.tcPerfil = item;
                    var tmp = res.data;
                    for (var i = 0; i < tmp.length; i++) {
                      tmp[i].menuDesc = tmp[i].tcMenu.menuDesc;
                    }
                    $scope.dataPadre = tmp;
                    $scope.gridOptions.data = res.data;
                    toastr.success( res.message );
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.agregarRegistro = function () {
                var tcPerfil = $scope.data.tcPerfil;
                if ($scope.data.tcPerfil == null) {
                  toastr.error('Debe seleccionar primero el perfil');
                  return;
                }
                $scope.data = {
                  tcPerfil: tcPerfil,
                  estadoId: 1
                }
                $scope.visibleUsuario = true;
                $scope.isEdit = false;
              }

              $scope.setMenu = function ( item ) {
                $scope.data.tcMenu = item;
              }

              $scope.setSistema = function ( item ) {
                $scope.data.tcSistema = item;
              }

              $scope.setPadre = function ( item ) {
                $scope.data.tcPadre = item.tcMenu;
              }

              $scope.cancelarRegistro = function () {
                $scope.visibleUsuario = false;
                $scope.isEdit = false;
                $scope.data.tcUsuario = null;
                $scope.usuarioId = null;
              }

              $scope.guardarDatos = function () {
                if($scope.data.tcPerfil == null || $scope.data.tcUsuario) {
                  toastr.error('Debe seleccionar el perfil y el usuario para continuar');
                  return;
                }
                var data = $scope.gridOptions.data;
                var encontrado = false;
                for (var i = 0; i < data.length; i++) {
                  if (data[i].tcPerfil.perfilId == $scope.data.tcPerfil.perfilId
                      && data[i].tcMenu.menuId == $scope.data.tcMenu.menuId
                      && data[i].tcSistema.sistemaId == $scope.data.tcSistema.sistemaId) {
                    encontrado = true;
                    break;
                  }
                }
                if (encontrado) {
                  toastr.error('El menu, perfil y sistema seleccionado ya se encuentra registrado');
                  return;
                }
                var tcPerfil = $scope.data.tcPerfil;
                if ($scope.isEdit) {
                  menuperfilsistemaService.edit($scope.data).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.data = {
                        tcSistema: null,
                        tcPerfil: tcPerfil,
                        tcMenu: null
                      };
                      $scope.setPerfil($scope.data.tcPerfil);
                      $scope.visibleUsuario = false;
                      $scope.isEdit = false;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                } else {
                  menuperfilsistemaService.add($scope.data).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.data = {
                        tcSistema: null,
                        tcPerfil: tcPerfil,
                        tcMenu: null
                      };
                      $scope.setPerfil($scope.data.tcPerfil);
                      $scope.visibleUsuario = false;
                      $scope.isEdit = false;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.editRow = function ( row ) {
                $scope.data = row;
                $scope.visibleUsuario = true;
                $scope.isEdit = true;
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.menuperfilsistema.add', {
          url: '/add',
          templateUrl: 'app/menuperfilsistema/menuperfilsistema.add.tpl.html',
          resolve: {
            dataEstado: ['menuperfilsistemaService',
              function ( menuperfilsistemaService ){
                return menuperfilsistemaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'menuperfilsistemaService', 'dataEstado',
            function (  $scope,   $state,   toastr,   menuperfilsistemaService, dataEstado) {
              $scope.data = {
                tcSistema: null,
                tcPerfil: null,
                tcMenu: null
              };
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;

              $scope.setPerfil = function (item) {
                menuperfilsistemaService.menuByPerfil( item.perfilId).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.data.tcPerfil = item;
                    $scope.dataPadre = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  menuperfilsistemaService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.menuperfilsistema.edit', {
          url: '/:menuPerfilSistemaId/edit',
          templateUrl: 'app/menuperfilsistema/menuperfilsistema.add.tpl.html',
          resolve: {
            dataMenuPerfilSistema: ['menuperfilsistemaService', '$stateParams',
              function ( menuperfilsistemaService, $stateParams ){
                return menuperfilsistemaService.get( $stateParams.menuPerfilSistemaId );
              }],
            dataEstado: ['menuperfilsistemaService',
              function ( menuperfilsistemaService ){
                return menuperfilsistemaService.listStatus();
              }],
            dataPadre: ['menuperfilsistemaService',
              function ( menuperfilsistemaService ){
                return menuperfilsistemaService.menuByPerfil(dataMenuPerfilSistema.data[0].tcPerfil.perfilId);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'menuperfilsistemaService', 'dataMenuPerfilSistema', 'dataEstado', 'dataPadre',
            function (  $scope,   $state,   toastr,   menuperfilsistemaService,   dataMenuPerfilSistema, dataEstado, dataPadre) {
              $scope.data = dataMenuPerfilSistema.data;
              $scope.dataPadre = dataPadre.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  menuperfilsistemaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
