angular.module('app.cargaMaderable.service', [

])

.factory('cargaMaderableService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'cargaMaderable/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'cargaMaderable/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'cargaMaderable/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'cargaMaderable/' + data.cargaMaderableId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( cargaMaderableId ) {
      return $http.get( appSettings.urlServiceBase + 'cargaMaderable/' + cargaMaderableId).then(function ( res ) {
         return res.data;
       });
    },
    getByRegion : function( regionId ) {
      return $http.get( appSettings.urlServiceBase + 'cargaMaderable/region/' + regionId).then(function ( res ) {
         return res.data;
       });
    },
    getByRegionAndTipoBosque : function( regionId, tipoBosqueId ) {
      return $http.get( appSettings.urlServiceBase + 'cargaMaderable/region/' + regionId + '/bosque/' + tipoBosqueId).then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
