angular.module('app.valortipomadera', [
  'ui.router',
  'toastr',
  'app.valortipomadera.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.valortipomadera', {
          abstract: true,
          url: 'valortipomadera',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Especie';
            }]
        })
        .state('index.valortipomadera.list', {
          url: '',
          templateUrl: 'app/valortipomadera/valortipomadera.list.tpl.html',
          resolve: {
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }]
          },
          controller: ['$scope', '$state', 'dataRegion', 'valortipomaderaService', 'toastr', 'ngDialog',
            function (  $scope,   $state,   dataRegion, valortipomaderaService, toastr, ngDialog) {

              $scope.dataRegion = dataRegion.data;
              $scope.filtro = {};
              $scope.dataTipoMadera = [];
              $scope.itemRegion = {};

              $scope.cargarDatos = function (anio, regionId) {
                $scope.dataTipoMadera = [];
                valortipomaderaService.listaAnioRegion( anio, regionId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.data = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setRegion = function ( item ) {
                $scope.itemRegion = item;
                if ($scope.filtro.anio != undefined) {
                  if ($scope.filtro.anio != null) {
                    if ($scope.filtro.anio > 2000) {
                      $scope.cargarDatos($scope.filtro.anio, item.regionId);
                    }
                  }
                }
              }

              $scope.setAnio = function () {
                if ($scope.filtro.regionId != undefined) {
                  if ($scope.filtro.regionId != null) {
                    if ($scope.filtro.regionId > 0) {
                      $scope.cargarDatos($scope.filtro.anio, $scope.filtro.regionId);
                    }
                  }
                }
              }

              $scope.cargarModal = function () {
                if ($scope.filtro.anio != null && $scope.filtro.regionId != null) {
                  ngDialog.open({
                    template: 'app/valortipomadera/agregar.tipomadera.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.nuevo = {
                          regionId : $scope.filtro.regionId,
                          anio : $scope.filtro.anio,
                          usuarioRegistraId : $scope.loginData.usuarioId
                        }

                        $scope.setTipoMadera = function (item) {
                          $scope.item = item;
                        }

                        $scope.submitForm = function ( isValid ) {
                          if (isValid) {
                            valortipomaderaService.add($scope.nuevo).then(function (response) {
                              if (response.status == 'OK') {
                                toastr.success(response.message);
                                $scope.nuevo.valorTipoMaderaId = response.data;
                                $scope.nuevo.tipoMaderaDesc = $scope.item.tipoMaderaDesc;
                                $scope.data.push($scope.nuevo);
                                $scope.dataTipoMadera.splice($scope.dataTipoMadera.indexOf($scope.item), 1);
                                $scope.nuevo = {
                                  regionId : $scope.filtro.regionId,
                                  anio : $scope.filtro.anio,
                                  usuarioRegistraId : $scope.loginData.usuarioId
                                }
                                ngDialog.close();
                              } else {
                                toastr.error(response.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                      }]
                  })
                }
              }

              $scope.agregarTipoMadera = function () {
                if ($scope.dataTipoMadera.length <= 0) {
                  valortipomaderaService.listaRegionTipoMadera($scope.filtro.anio, $scope.filtro.regionId).then(function (response) {
                    if (response.status == 'OK') {
                      $scope.dataTipoMadera = response.data;
                      $scope.cargarModal();
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  $scope.cargarModal();
                }
              }

              $scope.enableChange = function (item) {
                item.actualizar = 1;
                item.usuarioModificaId = $scope.loginData.usuarioId;
              }

              $scope.actualizarMonto = function ( item ) {
                if (item.troza == null || item.lenia == null) {
                  
                }
                if (isValid) {
                  valortipomaderaService.edit($scope.data).then(function (response) {
                    if (response.status == 'OK') {
                      toastr.success(response.message);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                } else {
                  toastr.error("Debe ingresar todos los datos");
                }
              }

              $scope.clonarAnio = function () {
                if ($scope.filtro.anio != null) {
                  if ($scope.filtro.anio > 1900) {
                    swal({
                      title: "¿Está seguro (a) de clonar los datos del último año?",
                      text: "Éste método funciona si y solo sí no se ha agregado algún registro al año, de lo contrario NO funcionará",
                      icon: "warning",
                      type: "warning",
                      showCancelButton: true,
                      confirmButtonClass: "btn-success",
                      cancelButtonClass: "btn-danger",
                      confirmButtonText: "Confirmar",
                      cancelButtonText: "Cancelar",
                      closeOnConfirm: true,
                      closeOnCancel: true
                    },
                    function(isConfirm) {
                      if (isConfirm) {
                        var data = {
                          usuarioRegistraId : $scope.loginData.usuarioId,
                          anio : new Date().getFullYear() - 1,
                          nuevo : $scope.filtro.anio
                        }
                        valortipomaderaService.clonar( data ).then( function ( res ) {
                          if ( res.status == "OK" ) {
                            toastr.success(res.message);
                          } else {
                            toastr.error( res.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      }
                    });
                  } else {
                    toastr.error("Indique el año (mayor a 1900) al que se va a cargar los datos");
                  }
                } else {
                  toastr.error("Indique el año al que se va a cargar los datos");
                }
              }

            }]
        })
    }
  ]
);
