angular.module('app.reporte.service', [

])

  .factory('reporteService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'reporte/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEntrega: function (data) {
        return $http.put(appSettings.urlServiceBase + 'reporte/marcar/entrega', data).
          then(function (res) {
            return res.data;
          });
      },
      listaActiva: function () {
        var data = [];
        data.push({reporteId: 1, reporteDesc: 'Reporte general del exento'});
        data.push({reporteId: 2, reporteDesc: 'Detalle de especies'});
        data.push({reporteId: 3, reporteDesc: 'Balance general del registro'});
        data.push({reporteId: 4, reporteDesc: 'Balance detallado del registro'});
        data.push({reporteId: 5, reporteDesc: 'Histórico de polígonos por venta'});
        data.push({reporteId: 6, reporteDesc: 'Notas del registro'});
        data.push({reporteId: 7, reporteDesc: 'Histórico de polígonos registro'});
        var res = {
          status : 'OK',
          message : 'Datos cargados',
          data : data
        };
        return res;
      }
    }

  }]);
