angular.module('app.realizar', [
  'ui.router',
  'toastr',
  'app.tarea.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.realizar', {
            abstract: true,
            url: 'realizar/:tareaId',
            params: {
            },
            views: {
              '':
              {
                templateUrl: 'app/tarea/tarea.tpl.html',
                resolve: {

                },
                controller: ['$scope', 'ngDialog', '$state', 'toastr', 'utilsService', '$timeout',
                  function ($scope, ngDialog, $state, toastr, utilsService, $timeout) {
                    if ($scope.globales.task == null) {
                      //$state.current.name
                      if ($scope.loginData.perfilId == 28 || $scope.loginData.perfilId == 27) {
                        $state.go('index.tarea.consumo');
                      } else {
                        $state.go('index.tarea.list');
                      }
                    }
                    $scope.dataTask = $scope.globales.task;
                    $scope.globales.task = null;

                    $scope.tabs = [];
                    $scope.setTabs = function (tabs) {
                      $scope.tabs = tabs;
                    }

                    $scope.saldoExento = function (item) {
                      ngDialog.open({
                        template: 'app/exento/saldo.exento.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueMunicipio',
                        name: 'dialogMunicipio',
                        resolve: {
                          dataExento: ['exentoService', 'authService', '$filter', 'tareaService',
                            function (exentoService, authService, $filter, tareaService) {
                              return exentoService.getSaldo(item.exentoId);
                            }]
                        },
                        controller: ['$scope', '$state', 'toastr', 'dataExento',
                          function ($scope, $state, toastr, dataExento) {
    
                            if (dataExento.status == 1) {
                              $scope.dataSaldo = dataExento.data;
                            } else {
                              toastr.error(dataExento.message);
                            }
    
                            $scope.cerrarVentana = function () {
                              ngDialog.close();
                            }
    
                          }]
                      })
                    }

                    $scope.getEspecieByNombreCientífico = function (nombreCientifico, dataEspecie) {
                      if (nombreCientifico == null || nombreCientifico.length <= 0) {
                        return null;
                      }
                      var __nombreCientifico = nombreCientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                      var ncev = __nombreCientifico;
                      var lncev = ncev.length;
                      var i;
                      for (i = 0; i < dataEspecie.length; i++) {
                        var _nombreCientifico = dataEspecie[i].nombreCientifico.trim().toLowerCase().replace(/[^a-zA-Z0-9 ]/g, ' ');
                        if (_nombreCientifico.localeCompare(__nombreCientifico) == 0) {
                          return angular.copy(dataEspecie[i]);
                        }
                        var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                        if (ncev.localeCompare(nce) == 0) {
                          return angular.copy(dataEspecie[i]);
                        }
                      }
                      //compare character per character
                      while (ncev.indexOf(' ') > -1) {
                        ncev = ncev.replace(' ', '_');
                        ncev = ncev.replace('á', '_');
                        ncev = ncev.replace('é', '_');
                        ncev = ncev.replace('í', '_');
                        ncev = ncev.replace('ó', '_');
                        ncev = ncev.replace('ú', '_');
                      }
                      for (i = 0; i < dataEspecie.length; i++) {
                        var nce = dataEspecie[i].nombreCientifico.trim().toLowerCase();
                        var lnce = nce.length;
                        if (lncev == lnce) {
                          while (nce.indexOf(' ') > -1) {
                            nce = nce.replace(' ', '_');
                            nce = nce.replace('á', '_');
                            nce = nce.replace('é', '_');
                            nce = nce.replace('í', '_');
                            nce = nce.replace('ó', '_');
                            nce = nce.replace('ú', '_');
                          }
                          var siEs = true;
                          for (var j = 0; j < lncev; j++) {
                            if (ncev[j] != nce[j]) {
                              siEs = false;
                              break;
                            }
                          }
                          if (siEs) {
                            return angular.copy(dataEspecie[i]);
                          }
                        }
                      }
                      return null;
                    }

                    $scope.getEspecieByCodigo = function (codigo, dataEspecie) {
                      if (codigo == null || codigo.length <= 0) {
                        return null;
                      }
                      for (var i = 0; i < dataEspecie.length; i++) {
                        if (dataEspecie[i].codigo == codigo) {
                          return dataEspecie[i];
                        }
                      }
                      return null;
                    }

                    $scope.getSinonimoEspecie = function (nombreCientifico, dataEspecie) {
                      if (nombreCientifico == null || nombreCientifico.length <= 0) {
                        return null;
                      }
                      for (var i = 0; i < dataEspecie.length; i++) {
                        if (dataEspecie[i].especieDesc.toLowerCase().includes(nombreCientifico)
                          || dataEspecie[i].sinonimo.toLowerCase().includes(nombreCientifico)) {
                          return dataEspecie[i];
                        }
                      }
                      return null;
                    }


                  }
                ]
              }
            }
          })
          .state('index.realizar.secretaria', {
            url: '/secretaria',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.secretaria.tpl.html',
                resolve: {
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService) {

                    $scope.enviarTarea = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de trasladar el expediente a la Dirección Subregional? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            data.ttGestion.personaAnulaId = $scope.loginData.personaId;
                            tareaService.asignarTareaSubregional(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success(res.message);
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }
                  }]
              }
            }
          })
          .state('index.realizar.admitir', {
            url: '/admitir',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.admitir.tpl.html',
                resolve: {
                  dataJuridico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 6);
                    }],
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.get($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', 'dataJuridico', 'dataTecnico', '$timeout', 'catalogosService', 'montogarantiaService', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, dataJuridico, dataTecnico, $timeout, catalogosService, montogarantiaService, dataTarea) {
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataRegente = [];
                    $scope.dataMontoGarantia = [];
                    $scope.bndGarantia = false;
                    $scope.admision = {};
                    $scope.textoBoton = "Vista previa";

                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }

                    $scope.setRegente = function (item) {

                    }

                    $scope.dataJuridico = dataJuridico.data;
                    $scope.dataTecnico = dataTecnico.data;
                    if ($scope.dataTask.ttSeguimientoTarea.seguimientoTareaId > 0) {
                      $scope.textoBoton = "Descargar resolución";
                      $scope.textoPanelUno = "El expediente ya fue admitido, continue en la pestaña de asignación";
                      $scope.mostrarAdmitir = false;
                    } else {
                      $scope.textoBoton = "Vista previa";
                      $scope.textoPanelUno = "El siguiente paso es admitir el expediente. ¿Quiere realizarlo ahora? Presione el botón correspondiente, de lo contrario puede cancelar y regresar a la lista de tareas";
                      $scope.mostrarAdmitir = true;
                    }
                    if ($scope.dataTask.finalizado == 1) {
                      $scope.textoPanelDos = "El expediente ya fue asignado, finalice la tarea.";
                      $scope.mostrarAsignar = false;
                    } else {
                      $scope.textoPanelDos = "Seleccione las personas para asignarles la siguiente tarea";
                      $scope.mostrarAsignar = true;
                    }

                    $scope.vistaPrevia = function () {
                      var data = $scope.dataTask;
                      var dia = $filter('date')(new Date(data.fechaRecepcion), 'dd');
                      var anio = $filter('date')(new Date(data.fechaRecepcion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'dd');
                      anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'yyyy');
                      var strDiaAdmision = $scope.numeroALetras(dia);
                      var strAnioAdmision = $scope.numeroALetras(anio, false);
                      utilsService.setEsPrevia(true);
                      utilsService.generarResolucionAdmision(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                    }

                    $scope.admitirExpediente = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null) {
                        toastr.error('Debe indicar el número de resolución');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                          toastr.error('Debe indicar el número de resolución');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de admitir el expediente? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.dataTask.ttSeguimientoTarea.fechaAdmision = new Date();
                            var data = $scope.dataTask;
                            tareaService.admitirExpediente(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success(res.message);
                                $scope.textoPanelUno = "El expediente ya fue admitido, continue en la pestaña de asignación";
                                $scope.mostrarAdmitir = false;
                                $scope.textoBoton = "Descargar resolución";
                                $scope.active = 1;
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaJuridico = item;
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (isNotPreview) {
                      if ($scope.mostrarAsignar) {
                        if ($scope.dataTask.tcPersonaTraslado == null || $scope.dataTask.tcPersonaJuridico == null || $scope.dataTask.ttSeguimientoTarea.providencia == null) {
                          toastr.error("Debe seleccionar a las personas que asinará la tarea e indicar el número de providencia");
                          return;
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      utilsService.setEsPrevia(true);
                      utilsService.generarProvidenciaTraslado($scope.dataTask, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                    }

                    $scope.asignarExpediente = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de trasladar el expediente al departamento Jurídico y Técnico? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttGestion.rodal;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.asignarProvidencia(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success("La asignación se ha efectuado con éxito");
                                $scope.mostrarAsignar = false;
                                $scope.vistaPreviaAsignacion(true);
                              } else {
                                toastr.error("Error al realizar la asignación " + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.finalizarTarea = function () {
                      $state.go('index.tarea.list');
                    }

                  }]
              }
            }
          })
          .state('index.realizar.providencia', {
            url: '/providencia',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.providencia.enmienda.tpl.html',
                resolve: {
                  dataJuridico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 6);
                    }],
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTareaPadre: ['tareaService', 'authService', '$filter', '$stateParams',
                    function (tareaService, authService, $filter, $stateParams) {
                      var tareaPadreId = authService.getLocalData('tareaPadreId');
                      return tareaService.get(tareaPadreId);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', 'dataJuridico', 'dataTecnico', '$timeout', 'catalogosService', 'montogarantiaService', 'dataTareaPadre',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, dataJuridico, dataTecnico, $timeout, catalogosService, montogarantiaService, dataTareaPadre) {
                    $scope.dataPadre = dataTareaPadre.data[0];
                    var enmienda = JSON.parse($scope.dataPadre.ttSeguimientoTarea.enmienda);
                    $scope.showTecnico = false;
                    $scope.showJuridico = false;
                    $scope.esRegional = false;
                    $scope.textoCancelar = 'Cancelar';
                    $scope.textoNumero = 'No. de providencia';
                    var oficios = null;
                    $scope.dataTask.ttGestion = $scope.dataPadre.ttGestion;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    $scope.dataTask.ttSeguimientoTarea.antecedente = $scope.dataPadre.ttSeguimientoTarea.codigo;
                    $scope.mostrarAsignar = false;
                    if (enmienda[0].codigo == undefined) {

                    } else {
                      if (enmienda[0].taskId == 6 || enmienda[0].taskId == 46) {
                        $scope.textoNumero = 'No. de oficio';
                        $scope.dataTask.tcPersonaTraslado = $scope.dataPadre.tcPersonaMonitoreo;
                        $scope.dataTask.ttSeguimientoTarea.fundamento = enmienda[0].codigo;
                        $scope.dataTask.ttSeguimientoTarea.enmienda = enmienda[0].enmienda;
                        $scope.esRegional = true;
                        $scope.mostrarAsignar = true;
                      } else {
                        for (var i = 0; i < enmienda.length; i++) {
                          if (enmienda[i].taskId == 3) {
                            $scope.dataTask.tcPersonaJuridico = {
                              personaId: enmienda[i].personaId,
                              personaDesc: enmienda[i].personaDesc
                            }
                            $scope.dataTask.ttSeguimientoTarea.enmienda = enmienda[i].codigo;
                            $scope.showJuridico = true;
                            $scope.mostrarAsignar = true;
                          }
                          if (enmienda[i].taskId == 4) {
                            $scope.dataTask.tcPersonaTraslado = {
                              personaId: enmienda[i].personaId,
                              personaDesc: enmienda[i].personaDesc
                            }
                            if (oficios == null) {
                              oficios = enmienda[i].codigo;
                            } else {
                              oficios += ', ' + enmienda[i].codigo;
                            }
                            $scope.dataTask.ttSeguimientoTarea.fundamento = enmienda[i].codigo;
                            $scope.showTecnico = true;
                            $scope.mostrarAsignar = true;
                          }
                          if (enmienda[i].taskId == 14) {
                            $scope.dataTask.tcPersonaMonitoreo = {
                              personaId: enmienda[i].personaId,
                              personaDesc: enmienda[i].personaDesc
                            }
                            if (oficios == null) {
                              oficios = enmienda[i].codigo;
                            } else {
                              oficios += ', ' + enmienda[i].codigo;
                            }
                            $scope.dataTask.ttSeguimientoTarea.analisis = enmienda[i].codigo;
                            $scope.showGarantia = true;
                          }
                        }
                      }
                    }
                    $scope.dataRegente = [];
                    $scope.dataMontoGarantia = [];
                    $scope.bndGarantia = false;
                    $scope.admision = {};
                    $scope.textoBoton = "Vista previa";

                    $scope.validarTipoGarantia = function () {
                      //si es garantia fiduciaria mostrar opciones para enviar a subregional
                      if (($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1 || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7)
                        && $scope.bndGarantia == false) {
                        //Compromiso de repoblacion - b. Estimación del monto del compromiso
                        var dataCompromisoTmp = angular.copy($scope.dataTask.ttGestion.rodal);
                        var dataCompromiso = [];
                        var i = 0;
                        //obtener datos del primer turno
                        for (i = 0; i < dataCompromisoTmp.length; i++) {
                          if (parseInt(dataCompromisoTmp[i].turno) == 1) {
                            dataCompromiso.push(dataCompromisoTmp[i]);
                          }
                        }
                        //si tratamientoSilviculturalId == 2 en todas las especies será corta total de lo contrario corta selectiva
                        var montoUtilizar = 0;
                        for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                          if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                            if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                              montoUtilizar = $scope.dataMontoGarantia[i].monto;
                              break;
                            }
                          }
                        }
                        var volumenTotal = 0;
                        var abrTotal = 0, abrExtraerTotal = 0;
                        var calculoMonto = 'área por área';
                        for (i = 0; i < dataCompromiso.length; i++) { //rodales
                          var abrExtraer = 0;
                          var abr = 0;
                          for (j = 0; j < dataCompromiso[i].especies.length; j++) {//especie por rodal
                            abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                            abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
                            volumenTotal = volumenTotal + parseFloat(dataCompromiso[i].especies[j].volrExtraer);
                          }
                          dataCompromiso[i].abr = abr;
                          dataCompromiso[i].abrExtraer = abrExtraer;
                          abrTotal += abr;
                          abrExtraerTotal += abrExtraer;
                        }
                        var porcentaje = 0;
                        porcentaje = abrExtraerTotal * 100;
                        porcentaje = porcentaje / abrTotal;
                        porcentaje = Math.round(porcentaje);
                        var cortaFinal = true;
                        var areaMonto = 0;
                        var montoCompromiso = 0;
                        var area = 0;
                        var montoArea = 0;
                        var montoGarantia = 0;
                        for (i = 0; i < dataCompromiso.length; i++) {
                          dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                          dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                          areaMonto = parseFloat(dataCompromiso[i].areaGarantia);
                          if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                            calculoMonto = 'área basal ideal';
                          }
                          area = area + areaMonto;
                          montoGarantia = parseFloat(dataCompromiso[i].areaGarantia) * montoUtilizar;
                          montoGarantia = parseFloat(montoGarantia).toFixed(2);
                          dataCompromiso[i].montoCompromiso = montoGarantia;
                          montoArea = montoArea + parseFloat(dataCompromiso[i].montoCompromiso);
                          dataCompromiso[i].metodoCalculoDesc = calculoMonto;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea == null) {
                          $scope.dataTask.ttSeguimientoTarea = {};
                        }
                        $scope.dataTask.ttSeguimientoTarea.area = parseFloat(area).toFixed(4);
                        $scope.dataTask.ttSeguimientoTarea.monto = parseFloat(montoArea).toFixed(2);
                        $scope.dataTask.ttSeguimientoTarea.volumen = parseFloat(volumenTotal).toFixed(4);
                        $scope.bndGarantia = true;
                        $scope.dataTask.solicitarAval = true;
                        $scope.data = {
                          volumenTotal: volumenTotal
                        };
                      }
                    }
                    if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia != null) {
                      if ($scope.dataTask.ttGestion.ttPlanificacionGestion.hayReforestacion == 1) {
                        if (($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
                          || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7)
                          && $scope.showGarantia) {
                          montogarantiaService.listaByTipoGarantia($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId).then(function (res) {
                            if (res.status == "OK") {
                              $scope.dataMontoGarantia = res.data;
                              $scope.validarTipoGarantia();
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }
                      }
                    }
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }

                    $scope.setRegente = function (item) {

                    }

                    $scope.dataJuridico = dataJuridico.data;
                    $scope.dataTecnico = dataTecnico.data;
                    if ($scope.dataTask.ttSeguimientoTarea.seguimientoTareaId > 0) {
                      $scope.textoBoton = "Descargar resolución";
                      $scope.textoPanelUno = "El expediente ya fue admitido, continue en la pestaña de asignación";
                      $scope.mostrarAdmitir = false;
                    } else {
                      $scope.textoBoton = "Vista previa";
                      $scope.textoPanelUno = "El siguiente paso es admitir el expediente. ¿Quiere realizarlo ahora? Presione el botón correspondiente, de lo contrario puede cancelar y regresar a la lista de tareas";
                      $scope.mostrarAdmitir = true;
                    }

                    $scope.vistaPrevia = function () {
                      var data = $scope.dataTask;
                      var dia = $filter('date')(new Date(data.fechaRecepcion), 'dd');
                      var anio = $filter('date')(new Date(data.fechaRecepcion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'dd');
                      anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'yyyy');
                      var strDiaAdmision = $scope.numeroALetras(dia);
                      var strAnioAdmision = $scope.numeroALetras(anio, false);
                      utilsService.generarResolucionAdmision(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaJuridico = item;
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.showGarantia) {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                          toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                          return;
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                            toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                            return;
                          }
                        }
                      }
                      if ($scope.showTecnico || $scope.showJuridico) {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                            toastr.error('Debe indicar el número de providencia');
                            return;
                          }
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      if ($scope.esRegional) {
                        try {
                          utilsService.generarOficioTrasladoEnmienda($scope.dataTask, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } catch (error) {

                        }
                      } else {
                        if ($scope.mostrarAsignar) {
                          var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio);
                          try {
                            utilsService.generarProvidenciaTrasladoEnmienda($scope.dataTask, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          } catch (error) { }
                        }
                        //si es fiduciaria
                        if ($scope.showGarantia) {
                          $timeout(function () {
                            utilsService.generarSolicitudAval($scope.dataTask, 1, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, 1);
                          }, 3000);
                        }
                      }
                      if (refresh) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.asignarExpediente = function () {
                      if ($scope.showGarantia) {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                          toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                          return;
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                            toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                            return;
                          }
                        }
                      }
                      if ($scope.showTecnico || $scope.showJuridico) {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                            toastr.error('Debe indicar el número de providencia');
                            return;
                          }
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de trasladar el expediente para revisar las enmiendas solicitadas? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttGestion.rodal;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if ($scope.esRegional) {
                              data.ttSeguimientoTarea.numeroResolucion = 1;
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(data.ttSeguimientoTarea.enmienda);
                            }
                            if ($scope.esRegional) {
                              data.ttSeguimientoTarea.aprobado = 2;
                            }
                            tareaService.asignarProvidenciaEnmienda(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success("La asignación se ha efectuado con éxito");
                                $scope.mostrarAsignar = false;
                                $scope.vistaPreviaAsignacion(true);
                                $scope.textoCancelar = 'Finalizar';
                              } else {
                                toastr.error("Error al realizar la asignación " + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.finalizarTarea = function () {
                      $state.go('index.tarea.list');
                    }

                  }]
              }
            }
          })
          .state('index.realizar.juridico', {
            url: '/juridico',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.juridico.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.getConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataTarea) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    } else {
                      if ($scope.dataTask.ttSeguimientoTarea.enmienda != null) {
                        $scope.dataTask.ttSeguimientoTarea.enmienda = JSON.parse($scope.dataTask.ttSeguimientoTarea.enmienda);
                      }
                    }
                    $scope.data = {
                      approved: true,
                      amendment: false
                    }

                    $scope.setAprobar = function () {
                      if ($scope.data.approved) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.dataTask.aprobado = 2;
                        $scope.dataTask.esEnmienda = 1;
                      } else {
                        $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                        $scope.dataTask.esEnmienda = 0;
                        if ($scope.data.approved) {
                          $scope.dataTask.aprobado = 1;
                        } else {
                          $scope.dataTask.aprobado = 0;
                        }
                      }
                    }

                    $scope.agregarEnmienda = function () {
                      if ($scope.mostrarGuardar) {
                        if ($scope.dataTask.ttSeguimientoTarea == null) {
                          $scope.dataTask.ttSeguimientoTarea = {
                            enmienda: []
                          }
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.enmienda == null) {
                            $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                          }
                        }
                        $scope.dataTask.ttSeguimientoTarea.enmienda.push({ descripcion: null });
                      }
                    }

                    $scope.borrarDetalle = function (item) {
                      if ($scope.mostrarGuardar) {
                        $scope.dataTask.ttSeguimientoTarea.enmienda.splice($scope.dataTask.ttSeguimientoTarea.enmienda.indexOf(item), 1);
                      }
                    }

                    $scope.vistaPrevia = function () {
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if (data.ttSeguimientoTarea.codigo == null) {
                        data.ttSeguimientoTarea.codigo = '';
                      }
                      if ($scope.data.amendment) {
                        utilsService.generarEnmiendaJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        utilsService.setEsPrevia(true);
                        utilsService.generarOpinionJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                    }

                    $scope.enviarTareaJuridica = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if (data.ttSeguimientoTarea.enmienda) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(data.ttSeguimientoTarea.enmienda);
                            }
                            tareaService.setOpinionJuridica(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                if ($scope.data.amendment) {
                                  utilsService.generarEnmiendaJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                } else {
                                  utilsService.generarOpinionJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                }
                                toastr.success(res.message);
                                $scope.mostrarGuardar = false;
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      $scope.classEnmienda = "btn btn-default";
                      ngDialog.close();
                    }

                    //ventana para enmiendas
                    $scope.generarEnmienda = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista las enmiendas para el jurídico";

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.push({ descripcion: null });
                              }
                            }

                            $scope.borrarEnmienda = function (item) {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                              }
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              tareaService.getDataOpinionJuridica($scope.dataTask.tareaId).then(function (res) {
                                if (res.status == "OK") {
                                  if (res.data.codigo == null) {
                                    res.data.fechaProvidencia = new Date();
                                    res.data.codigo = "AJ-XX-XX-XXXX-XXX-XXXX";
                                    res.data.fundamento = $scope.enmienda;
                                    res.data.aprobado = 2;
                                  } else {
                                    try {
                                      res.data.fundamento = JSON.parse(res.data.fundamento);
                                    } catch (e) {

                                    }
                                  }
                                  utilsService.generarEnmiendaJuridica(res.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                $scope.enviarTarea(true, $scope.enmienda);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.garantia', {
            url: '/garantia',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.garantia.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.getConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataTarea) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.titulo = 'No. de AVAL';
                    $scope.mostrarGuardar = true;
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    } else {
                      if ($scope.dataTask.ttSeguimientoTarea.enmienda != null) {
                        try {
                          $scope.dataTask.ttSeguimientoTarea.enmienda = JSON.parse($scope.dataTask.ttSeguimientoTarea.enmienda);
                        } catch (e) { }
                      }
                    }
                    $scope.data = {
                      solicitante: true,
                      regente: true,
                      fiador: true,
                      patrimonio: true,
                      amendment: false,
                      aprobado: true,
                      representante: true,
                      representantef: true
                    }

                    $scope.setAvalar = function () {
                      if ($scope.data.aprobado) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.dataTask.aprobado = 2;
                        $scope.dataTask.esEnmienda = 1;
                        $scope.titulo = 'No. de oficio';
                      } else {
                        $scope.titulo = 'No. de AVAL';
                        $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                        $scope.dataTask.esEnmienda = 0;
                        if ($scope.data.aprobado) {
                          $scope.dataTask.aprobado = 1;
                        } else {
                          $scope.dataTask.aprobado = 0;
                        }
                      }
                    }

                    $scope.agregarEnmienda = function () {
                      if ($scope.mostrarGuardar) {
                        if ($scope.dataTask.ttSeguimientoTarea == null) {
                          $scope.dataTask.ttSeguimientoTarea = {
                            enmienda: []
                          }
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.enmienda == null) {
                            $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                          }
                        }
                        $scope.dataTask.ttSeguimientoTarea.enmienda.push({ descripcion: null });
                      }
                    }

                    $scope.borrarDetalle = function (item) {
                      if ($scope.mostrarGuardar) {
                        $scope.dataTask.ttSeguimientoTarea.enmienda.splice($scope.dataTask.ttSeguimientoTarea.enmienda.indexOf(item), 1);
                      }
                    }

                    $scope.vistaPrevia = function () {
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de aval");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if (data.ttSeguimientoTarea.codigo == null) {
                        data.ttSeguimientoTarea.codigo = '';
                      }
                      data.ttSeguimientoTarea.fundamento = $scope.data;
                      utilsService.setEsPrevia(true);
                      if ($scope.data.amendment) {
                        utilsService.generarEnmiendaMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1, $scope.loginData.sigla);
                      } else {
                        utilsService.generarOpinionMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1, $scope.loginData.sigla);
                      }
                    }

                    $scope.enviarTareaMonitoreo = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de aval");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if ($scope.data.amendment) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(data.ttSeguimientoTarea.enmienda);
                            } else {
                              delete data.ttSeguimientoTarea.enmienda;
                              data.ttSeguimientoTarea.fundamento = JSON.stringify($scope.data);
                            }
                            tareaService.setOpinionMonitoreo(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                data.ttSeguimientoTarea.fundamento = $scope.data;
                                if ($scope.data.amendment) {
                                  utilsService.generarEnmiendaMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1, $scope.loginData.sigla);
                                } else {
                                  utilsService.generarOpinionMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1, $scope.loginData.sigla);
                                }
                                toastr.success(res.message);
                                $scope.mostrarGuardar = false;
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      $scope.classEnmienda = "btn btn-default";
                      ngDialog.close();
                    }

                    //ventana para enmiendas
                    $scope.generarEnmienda = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista las enmiendas para monitoreo";

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.push({ descripcion: null });
                              }
                            }

                            $scope.borrarEnmienda = function (item) {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                              }
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              tareaService.getDataOpinionJuridica($scope.dataTask.tareaId).then(function (res) {
                                if (res.status == "OK") {
                                  if (res.data.codigo == null) {
                                    res.data.fechaProvidencia = new Date();
                                    res.data.codigo = "AJ-XX-XX-XXXX-XXX-XXXX";
                                    res.data.fundamento = $scope.enmienda;
                                    res.data.aprobado = 2;
                                  } else {
                                    try {
                                      res.data.fundamento = JSON.parse(res.data.fundamento);
                                    } catch (e) {

                                    }
                                  }
                                  utilsService.generarEnmiendaJuridica(res.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                $scope.enviarTarea(true, $scope.enmienda);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.avalpoa', {
            url: '/avalpoa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.garantia.tpl.html',
                resolve: {
                  dataSolicitud: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.getTareaAvalConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataSolicitud',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataSolicitud) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.titulo = 'No. de AVAL';
                    $scope.mostrarGuardar = true;
                    $scope.dataSolicitud = dataSolicitud.data[0];
                    $scope.dataTask = $scope.dataSolicitud.ttTarea;
                    $scope.dataTask.ttGestion = $scope.dataSolicitud.ttGestion;
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }
                    $scope.data = {
                      solicitante: true,
                      regente: true,
                      fiador: true,
                      patrimonio: true,
                      amendment: false,
                      aprobado: true,
                      representante: true,
                      representantef: true
                    }

                    $scope.setAvalar = function () {
                      if ($scope.data.aprobado) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.dataTask.aprobado = 2;
                        $scope.dataTask.esEnmienda = 1;
                        $scope.titulo = 'No. de oficio';
                      } else {
                        $scope.titulo = 'No. de AVAL';
                        $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                        $scope.dataTask.esEnmienda = 0;
                        if ($scope.data.aprobado) {
                          $scope.dataTask.aprobado = 1;
                        } else {
                          $scope.dataTask.aprobado = 0;
                        }
                      }
                    }

                    $scope.agregarEnmienda = function () {
                      if ($scope.mostrarGuardar) {
                        if ($scope.dataTask.ttSeguimientoTarea == null) {
                          $scope.dataTask.ttSeguimientoTarea = {
                            enmienda: []
                          }
                        } else {
                          if ($scope.dataTask.ttSeguimientoTarea.enmienda == null) {
                            $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                          }
                        }
                        $scope.dataTask.ttSeguimientoTarea.enmienda.push({ descripcion: null });
                      }
                    }

                    $scope.borrarDetalle = function (item) {
                      if ($scope.mostrarGuardar) {
                        $scope.dataTask.ttSeguimientoTarea.enmienda.splice($scope.dataTask.ttSeguimientoTarea.enmienda.indexOf(item), 1);
                      }
                    }

                    $scope.vistaPrevia = function () {
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de aval");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if (data.ttSeguimientoTarea.codigo == null) {
                        data.ttSeguimientoTarea.codigo = '';
                      }
                      data.ttSeguimientoTarea.fundamento = $scope.data;
                      var ttSolicitudPoa = $scope.dataSolicitud;
                      ttSolicitudPoa.ttTarea = data;
                      if ($scope.data.amendment) {
                        utilsService.generarEnmiendaMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, $scope.dataSolicitud.numero, $scope.loginData.sigla);
                      } else {
                        utilsService.generarOpinionMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, $scope.dataSolicitud.numero, $scope.loginData.sigla);
                      }
                    }

                    $scope.enviarTareaMonitoreo = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de aval");
                        return;
                      }
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.enmienda.length <= 0) {
                          toastr.error("Debe indicar la (s) enmienda (s)");
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if (data.ttSeguimientoTarea.enmienda) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(data.ttSeguimientoTarea.enmienda);
                            } else {
                              data.ttSeguimientoTarea.fundamento = JSON.stringify($scope.data);
                            }
                            tareaService.setOpinionMonitoreoPoaSiguiente(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                data.ttSeguimientoTarea.fundamento = $scope.data;
                                var ttSolicitudPoa = $scope.dataSolicitud;
                                ttSolicitudPoa.ttTarea = data;
                                if ($scope.data.amendment) {
                                  utilsService.generarEnmiendaMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, $scope.dataSolicitud.numero, $scope.loginData.sigla);
                                } else {
                                  utilsService.generarOpinionMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, $scope.dataSolicitud.numero, $scope.loginData.sigla);
                                }
                                toastr.success(res.message);
                                $scope.mostrarGuardar = false;
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      $scope.classEnmienda = "btn btn-default";
                      ngDialog.close();
                    }

                    //ventana para enmiendas
                    $scope.generarEnmienda = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista las enmiendas para monitoreo";

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.push({ descripcion: null });
                              }
                            }

                            $scope.borrarEnmienda = function (item) {
                              if ($scope.mostrarGuardar) {
                                $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                              }
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              tareaService.getDataOpinionJuridica($scope.dataTask.tareaId).then(function (res) {
                                if (res.status == "OK") {
                                  if (res.data.codigo == null) {
                                    res.data.fechaProvidencia = new Date();
                                    res.data.codigo = "AJ-XX-XX-XXXX-XXX-XXXX";
                                    res.data.fundamento = $scope.enmienda;
                                    res.data.aprobado = 2;
                                  } else {
                                    try {
                                      res.data.fundamento = JSON.parse(res.data.fundamento);
                                    } catch (e) {

                                    }
                                  }
                                  utilsService.generarEnmiendaJuridica(res.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                $scope.enviarTarea(true, $scope.enmienda);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.tecnico', {
            url: '/tecnico',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.tecnico.tpl.html',
                resolve: {
                  dataTarea: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getGestionParaTarea($base64.decode($stateParams.tareaId));
                    }],
                  dataMontoGarantia: ['montogarantiaService', 'dataTarea',
                    function (montogarantiaService, dataTarea) {
                      var data = dataTarea.data[0];
                      var tipoGarantiaId = null;
                      if (data.ttGestion.ttResumenGestion.tcTipoGarantia == null) {
                        if (data.ttGestion.tcTipoGestion.tipoGestionId == 2
                          || data.ttGestion.tcTipoGestion.tipoGestionId == 3
                          || data.ttGestion.tcTipoGestion.tipoGestionId == 8) {
                          tipoGarantiaId = 1;
                        } else {
                          var res = {
                            status: 'OK',
                            message: 'Datos cargados',
                            data: []
                          };
                          return res;
                        }
                      } else {
                        tipoGarantiaId = data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId;
                      }
                      return montogarantiaService.listaByTipoGarantia(tipoGarantiaId);
                    }],
                  dataTStudent: ['tstudentService',
                    function (tstudentService) {
                      return tstudentService.listaActiva();
                    }],
                  dataEspecie: ['dataTarea', 'especieService',
                    function (dataTarea, especieService) {
                      var ttTarea = dataTarea.data[0];
                      return especieService.getListaByBosque(ttTarea.ttGestion.tcTipoBosque.tipoBosqueId);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea', 'dataMontoGarantia',
                  '$filter', 'valortipomaderaService', 'dataTStudent', '$window', 'montogarantiaService', 'authService', 'dataEspecie',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataTarea, dataMontoGarantia,
                    $filter, valortipomaderaService, dataTStudent, $window, montogarantiaService, authService, dataEspecie) {
                    authService.removeLocalData('dataEspecie');
                    authService.saveLocalData('dataEspecie', dataEspecie.data);
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataGestion = $scope.dataTask.ttGestion;
                    var inventario = JSON.parse($scope.dataGestion.ttInventarioGestion.data);
                    var mensajeAlerta = '';
                    for (var i = 0; i < inventario.length; i++) {
                      var arbol = inventario[i];
                      arbol.ALTURA = parseFloat(arbol.ALTURA);
                      if (arbol.ALTURA < 5 || arbol.ALTURA > 35) {
                        if (mensajeAlerta.length > 0) {
                          mensajeAlerta += ', No. ' + arbol.NO + ' rodal ' + arbol.RODAL;
                        } else {
                          mensajeAlerta = 'No. ' + arbol.NO + ' rodal ' + arbol.RODAL;
                        }
                        if (arbol.PARCELA != null) {
                          mensajeAlerta += ' parcela ' + arbol.PARCELA;
                        }
                      }
                    }
                    if (mensajeAlerta.length > 0) {
                      swal('Las alturas de los siguientes números de árboles no son lógicos o proporcionales a su diámetro, considere para su dictamen', mensajeAlerta);
                    }
                    $scope.dataMontoGarantia = dataMontoGarantia.data;
                    $scope.dataTStudent = dataTStudent.data;
                    $scope.classEnmienda = "btn btn-default";
                    $scope.dataValorMadera = [];
                    $scope.tabActive = {
                      uno: false,
                      dos: false,
                      tres: false,
                      cuatro: false,
                      cinco: false,
                      seis: false,
                      siete: false
                    }
                    $scope.tabVisible = {
                      uno: false,
                      dos: false,
                      tres: false,
                      cuatro: false,
                      cinco: false,
                      seis: false,
                      siete: false
                    }

                    $scope.visibleEnmienda = true;

                    $scope.descargarBoletaCampo = function (id) {
                      authService.getFile(id);
                    }

                    $scope.setInicioVigencia = function () {
                      var hoy = new Date();
                      var mes = hoy.getMonth() + 1;
                      var anio = hoy.getFullYear();
                      anio = anio + 1;
                      if (mes > 6) {
                        anio = anio + 1;
                      }
                      //siempre es 31 de octubre
                      var inicio = new Date(anio, 9, 31);
                      var fin = inicio;
                      for (i = 0; i < $scope.data.vigencia.length; i++) {
                        if (i == 0) {
                          $scope.data.vigencia[i].vigencia = "Hasta " + $filter('date')(new Date(inicio), 'dd/MM/yyyy');
                        } else {
                          inicio = $scope.addDays(inicio, 1);
                          fin.setFullYear(fin.getFullYear() + 1);
                          $scope.data.vigencia[i].vigencia = "Del " + $filter('date')(new Date(inicio), 'dd/MM/yyyy') + " al " + $filter('date')(new Date(fin), 'dd/MM/yyyy');
                          inicio = fin;
                        }
                      }
                    }

                    if ($scope.dataGestion.areaProteccion == null) {
                      $scope.dataGestion.areaProteccion = 0;
                    }
                    var rodal = [];
                    var item = {};
                    try {
                      $scope.dataGestion.ttInventarioGestion.data = JSON.parse($scope.dataGestion.ttInventarioGestion.data);
                    } catch (e) {
                      $scope.dataGestion.ttInventarioGestion.data = [];
                    }
                    try {
                      $scope.dataGestion.ttInventarioGestion.brinzal = JSON.parse($scope.dataGestion.ttInventarioGestion.brinzal);
                    } catch (e) {
                      $scope.dataGestion.ttInventarioGestion.brinzal = [];
                    }
                    try {
                      $scope.dataGestion.ttInventarioGestion.latizal = JSON.parse($scope.dataGestion.ttInventarioGestion.latizal);
                    } catch (e) {
                      $scope.dataGestion.ttInventarioGestion.latizal = [];
                    }
                    try {
                      if ($scope.dataGestion.rodal != undefined && $scope.dataGestion.rodal != null) {
                        var dataResumen = $scope.dataGestion.rodal;
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                            }
                            if (dataResumen[i].especies[j].fuste != null) {
                              dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                              dataResumen[i].especies[j].mostrarFuste = 1;
                            }
                          }
                        }
                      }
                    } catch (e) {
                      $scope.dataGestion.rodal = [];
                    }

                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.mostrarGuardar = false;
                    $scope.mostrarValor = true;

                    $scope.establecerMontoCompromiso = function (dataCompromiso) {
                      var dataMontoVigencia = [];
                      //*para el calculo del monto solo se usa el de el año de establecimiento -> tipoMontoId = 1 (Si se cambia en bd, tambien aqui)
                      var montoGarantia = 0, monto;
                      for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                        montoGarantia = 0;
                        monto = 0;
                        for (j = 0; j < dataCompromiso.length; j++) {
                          montoGarantia = dataCompromiso[j].areaMonto * $scope.dataMontoGarantia[i].monto;
                          montoGarantia = parseFloat(montoGarantia).toFixed(2);
                          //al ser establecimiento se calcula para dataCompromiso
                          if ($scope.dataMontoGarantia[i].tipoMontoId == 1) {
                            dataCompromiso[j].montoCompromiso = montoGarantia;
                          }
                          monto = monto + montoGarantia;
                        }
                        monto = parseFloat(monto).toFixed(2);
                        item = {
                          etapaDesc: $scope.dataMontoGarantia[i].tipoMontoDesc,
                          monto: monto,
                          vigencia: null
                        }
                        dataMontoVigencia.push(item);
                      }
                      return dataMontoVigencia;
                    }

                    $scope.setTipoGarantia = function (item) {
                      montogarantiaService.listaByTipoGarantia(item.tipoGarantiaId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataMontoGarantia = res.data;
                          var dataCompromiso = $scope.data.compromiso;
                          var dataMontoGarantia = $scope.establecerMontoCompromiso(dataCompromiso);
                          $scope.data.vigencia = dataMontoGarantia;
                          $scope.setInicioVigencia();
                          $scope.data.tipoGarantiaId = item.tipoGarantiaId;
                          $scope.data.tipoGarantiaDesc = item.tipoGarantiaDesc;
                          var area = 0;
                          var montoArea = 0;
                          for (j = 0; j < dataCompromiso.length; j++) {
                            area = area + dataCompromiso[j].areaMonto;
                            montoArea = montoArea + parseFloat(dataCompromiso[j].montoCompromiso);
                          }
                          $scope.totalAreaCompromiso = parseFloat(area).toFixed(4);
                          $scope.totalMontoCompromiso = parseFloat(montoArea).toFixed(2);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }

                    $scope.validarDatosGeneral = function () {
                      if ($scope.data.general == null) {
                        var finca;
                        var direccion = "";
                        var colindancias = "";
                        var localizacion = "";
                        var nombreFinca = "";
                        var area = 0;
                        if ($scope.dataGestion.fincas.length > 1) {
                          for (i = 0; i < $scope.dataGestion.fincas.length; i++) {
                            finca = $scope.dataGestion.fincas[i];
                            area += parseFloat(finca.tcFinca.area);
                            nombreFinca += "Finca " + (i + 1) + ":\n\t" + finca.tcFinca.fincaDesc + "\n";
                            direccion += "Finca " + (i + 1) + ":\n\t" + finca.tcFinca.direccion + ", " + finca.tcFinca.tcMunicipio.municipioDesc + ", " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "\n";
                            colindancias += "Finca " + (i + 1) + ": \n\tN: " + finca.tcFinca.norte + "\n\tS: " + finca.tcFinca.sur + "\n\tE: " + finca.tcFinca.este + "\n\tO: " + finca.tcFinca.oeste + "\n";
                            localizacion += "Finca " + (i + 1) + ":\n\tX: " + finca.tcFinca.gtmX + ", Y: " + finca.tcFinca.gtmY + " (Coordenadas GTM)\n";
                          }
                        } else {
                          finca = $scope.dataGestion.fincas[0];
                          area = parseFloat(finca.tcFinca.area);
                          nombreFinca = finca.tcFinca.fincaDesc;
                          direccion = finca.tcFinca.direccion + ", " + finca.tcFinca.tcMunicipio.municipioDesc + ", " + finca.tcFinca.tcMunicipio.tcDepartamento.departamentoDesc + "\n";
                          colindancias = "\tN: " + finca.tcFinca.norte + "\n\tS: " + finca.tcFinca.sur + "\n\tE: " + finca.tcFinca.este + "\n\tO: " + finca.tcFinca.oeste + "\n";
                          localizacion = "X: " + finca.tcFinca.gtmX + ", Y: " + finca.tcFinca.gtmY + "\n";
                        }

                        var otros = area - parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaBosque);
                        otros = parseFloat(otros).toFixed(4);
                        var solicitante = "";
                        if ($scope.dataGestion.ttTipoPropietarioGestion.tcTipoPropietario.tipoPropietarioId == 2) {
                          solicitante = $scope.dataGestion.ttTipoPropietarioGestion.razonSocial;
                        } else {
                          for (var i = 0; i < $scope.dataGestion.personas.length; i++) {
                            if (i == 0) {
                              solicitante = $scope.dataGestion.personas[i].tcPersona.personaDesc;
                            } else {
                              solicitante += ", " + $scope.dataGestion.personas[i].tcPersona.personaDesc;
                            }
                          }
                        }
                        $scope.data.general = [
                          { id: 1, tipodesc: "Tipo de plan", descripcion: "Plan de manejo forestal", isDisabled: true },
                          { id: 2, tipodesc: "Nombre (s) del (los) solicitante (s)", descripcion: solicitante, isDisabled: true },
                          { id: 3, tipodesc: "Nombre de la finca", descripcion: nombreFinca, isDisabled: true },
                          { id: 4, tipodesc: "Área (ha) total de la finca", descripcion: area, isDisabled: true },
                          { id: 5, tipodesc: "Área (ha) con bosque", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaBosque).toFixed(4), isDisabled: true },
                          { id: 6, tipodesc: "Área (ha) de producción", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaProduccion).toFixed(4), isDisabled: true },
                          { id: 7, tipodesc: "Área (ha) de protección", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaProteccionBosque).toFixed(4), isDisabled: true },
                          { id: 7, tipodesc: "Área (ha) intervenir con bosque", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaIntervenirBosque).toFixed(4), isDisabled: true },
                          { id: 8, tipodesc: "Área (ha) de otros usos", descripcion: otros, isDisabled: true },
                          { id: 6, tipodesc: "Área (ha) con árboles fuera de bosque", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaFuera).toFixed(4), isDisabled: true },
                          { id: 7, tipodesc: "Área (ha) de protección con árboles fuera de bosque", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaProteccionFuera).toFixed(4), isDisabled: true },
                          { id: 7, tipodesc: "Área (ha) intervenir con árboles fuera de bosque", descripcion: parseFloat($scope.dataGestion.ttDivisionAreaGestion.areaIntervenirFuera).toFixed(4), isDisabled: true },
                          { id: 9, tipodesc: "Localización", descripcion: localizacion, isDisabled: true },
                          { id: 10, tipodesc: "Zona de vida", descripcion: $scope.dataGestion.ttBiofisicaGestion.zonaVida, isDisabled: false },
                          { id: 11, tipodesc: "Fuentes de agua", descripcion: $scope.dataTask.ttSeguimientoTarea.observaciones, isDisabled: false },
                          { id: 12, tipodesc: "Ubicación política", descripcion: direccion, isDisabled: true },
                          { id: 13, tipodesc: "Colindancias", descripcion: colindancias, isDisabled: true }
                        ];
                      }
                    }

                    $scope.validarDatosConclusiones = function () {
                      if ($scope.data.conclusion == null) {
                        $scope.data.conclusion = [
                          { tareaId: $scope.dataTask.tareaId, descripcion: null, placeholder: "Conclusiones sobre las características biofísicas (suelo, agua, pendiente, -detalle todo lo relevante-) de la finca o terreno que se evaluó versus propuesta de manejo.", enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: null, placeholder: "Conclusiones sobre los resultados y veracidad de la información presentada en el inventario forestal, estratificación y/o rodalización del bosque.", enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: null, placeholder: "Conclusiones sobre la propuesta de manejo forestal, versus Corta Anual Permisible.", enableErase: false }
                        ]
                      }
                    }

                    $scope.validarInventario = function () {
                      if ($scope.data.rodal == null) {
                        var ttroza = 0;
                        var tlenia = 0;
                        var tvolumen = 0;
                        var tVolumenPoa = 0;
                        var dataSilvicultura = $scope.dataTask.ttGestion.rodal;
                        for (i = 0; i < dataSilvicultura.length; i++) {
                          var troza = 0;
                          var lenia = 0;
                          var otros = 0;
                          var volumen = 0;
                          for (j = 0; j < dataSilvicultura[i].especies.length; j++) {
                            troza = troza + parseFloat(dataSilvicultura[i].especies[j].troza);
                            lenia = lenia + parseFloat(dataSilvicultura[i].especies[j].lenia);
                            volumen = volumen + parseFloat(dataSilvicultura[i].especies[j].volrExtraer);
                          }
                          dataSilvicultura[i].totalTroza = parseFloat(troza).toFixed(4);
                          dataSilvicultura[i].totalLenia = parseFloat(lenia).toFixed(4);
                          dataSilvicultura[i].totalVolumen = parseFloat(volumen).toFixed(4);
                          ttroza = ttroza + troza;
                          tlenia = tlenia + lenia;
                          tvolumen = tvolumen + volumen;
                          if (parseInt(dataSilvicultura[i].turno) == 1) {
                            tVolumenPoa += dataSilvicultura[i].totalVolumen;
                          }
                        }
                        $scope.data.totalTroza = parseFloat(ttroza).toFixed(4);
                        $scope.data.totalLenia = parseFloat(tlenia).toFixed(4);
                        $scope.data.totalVolumen = parseFloat(tvolumen).toFixed(4);
                        $scope.data.rodal = dataSilvicultura;
                      }
                    }
                    var now = new Date();
                    $scope.dataAnio = [];
                    for (var i = 1; i < 5; i++) {
                      $scope.dataAnio.push(
                        { anio: now.getFullYear() + i }
                      );
                    }

                    $scope.validarDatosCompromiso = function () {
                      if ($scope.data.compromiso == null) {
                        var dataSilvicultura = $scope.dataTask.ttGestion.rodal;
                        var dataCompromiso = [];
                        var anioRepoblacion = 0;
                        for (i = 0; i < dataSilvicultura.length; i++) {
                          if (parseInt(dataSilvicultura[i].turno) == 1) {
                            dataCompromiso.push(dataSilvicultura[i]);
                            anioRepoblacion = dataSilvicultura[i].anioRepoblacion;
                          }
                        }
                        var abrTotal = 0, abrExtraerTotal = 0, sumaPorcentaje = 0, totalRodales = dataCompromiso.length;
                        for (i = 0; i < dataCompromiso.length; i++) {
                          var abrExtraer = 0;
                          var abr = 0;
                          var area = 0;
                          var tcTratamientoSilvicultural = { tratamientoSilviculturalId: 2, tratamientoSilviculturalDesc: 'Tala rasa' };
                          for (j = 0; j < dataCompromiso[i].especies.length; j++) {
                            if (dataCompromiso[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId != 2
                              && dataCompromiso[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId != 3
                              && dataCompromiso[i].especies[j].tcTratamientoSilvicultural.tratamientoSilviculturalId != 6) {
                              tcTratamientoSilvicultural = dataCompromiso[i].especies[j].tcTratamientoSilvicultural;
                            }
                            abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                            abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
                          }
                          dataCompromiso[i].tcTratamientoSilvicultural = tcTratamientoSilvicultural;
                          area = area + parseFloat(dataCompromiso[i].area);
                          dataCompromiso[i].abr = abr;
                          dataCompromiso[i].abrExtraer = abrExtraer;
                          abrTotal += abr;
                          abrExtraerTotal += abrExtraer;
                          //---
                          value = (dataCompromiso[i].abrExtraer * 100) / dataCompromiso[i].abr;
                          value = Math.round(value);
                          sumaPorcentaje += value;
                          dataCompromiso[i].porcentaje = value;
                          dataCompromiso[i].areaGarantia = dataCompromiso[i].area;
                          if (dataCompromiso[i].tcTipoCobertura == null) {
                            dataCompromiso[i].tcTipoCobertura = {
                              tipoCoberturaId: 1
                            };
                          }
                          if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 1) {
                            if (value > 70) {
                              dataCompromiso[i].areaGarantia = dataCompromiso[i].area;
                            } else {
                              value = dataCompromiso[i].abrExtraer / dataCompromiso[i].abr;
                              value = value * dataCompromiso[i].area;
                              dataCompromiso[i].areaGarantia = parseFloat(value).toFixed(4);
                            }
                          } else {
                            var areaIdeal = 20;
                            if ($scope.dataTask.ttGestion.tcTipoBosque.tipoBosqueId == 3) {
                              areaIdeal = 25;
                            }
                            value = dataCompromiso[i].abrExtraer / areaIdeal;
                            dataCompromiso[i].areaGarantia = parseFloat(value).toFixed(4);
                          }
                        }
                        var porcentaje = 0;
                        if (totalRodales > 0) {
                          porcentaje = sumaPorcentaje / totalRodales;
                          porcentaje = parseFloat(porcentaje).toFixed(2);
                        }
                        $scope.porcentajeExtraer = porcentaje;
                        var montoUtilizar = 0;
                        if ($scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 2
                          || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 3
                          || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 8) {
                          montoUtilizar = 0;
                        } else {
                          for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                            if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                              if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                                montoUtilizar = $scope.dataMontoGarantia[i].monto;
                                break;
                              }
                            }
                          }
                        }
                        var hayReforestacion = true;
                        if ($scope.dataTask.ttGestion.ttPlanificacionGestion.hayReforestacion == 0) {
                          montoUtilizar = appSettings.montoFondo;
                          hayReforestacion = false;
                          $scope.porcentajeExtraer = 100;
                        }
                        for (i = 0; i < dataCompromiso.length; i++) {
                          var calculoMonto = 'área por área';
                          if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 1) {
                            if (hayReforestacion) {
                              var porcentaje = 0;
                              porcentaje = parseFloat(dataCompromiso[i].abrExtraer) * 100;
                              porcentaje = porcentaje / parseFloat(dataCompromiso[i].abr);
                              porcentaje = Math.round(porcentaje);
                              if (porcentaje > 70) {
                                dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                                dataCompromiso[i].areaGarantia = dataCompromiso[i].area;
                                dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                              } else {
                                calculoMonto = 'área basal';
                                var areaCompromiso = dataCompromiso[i].abrExtraer / dataCompromiso[i].abr;
                                areaCompromiso = areaCompromiso * dataCompromiso[i].area;
                                areaCompromiso = parseFloat(areaCompromiso).toFixed(4);
                                dataCompromiso[i].areaCompromiso = areaCompromiso;
                                dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                                dataCompromiso[i].areaGarantia = areaCompromiso;
                              }
                            } else {
                              dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                              dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                              dataCompromiso[i].areaGarantia = dataCompromiso[i].area;
                              calculoMonto = 'Pago al Fondo Forestal Privativo';
                            }
                          } else {
                            calculoMonto = 'área basal';
                            var areaIdeal = 20;
                            if ($scope.dataTask.ttGestion.tcTipoBosque.tipoBosqueId == 3) {
                              areaIdeal = 25;
                            }
                            value = dataCompromiso[i].abrExtraer / areaIdeal;
                            dataCompromiso[i].areaGarantia = parseFloat(value).toFixed(4);
                          }
                          dataCompromiso[i].metodoCalculoDesc = calculoMonto;
                          var montoGarantia = parseFloat(dataCompromiso[i].areaGarantia) * montoUtilizar;
                          montoGarantia = parseFloat(montoGarantia).toFixed(2);
                          dataCompromiso[i].montoCompromiso = montoGarantia;
                        }
                        var dataMontoVigencia = [];
                        //*para el calculo del monto solo se usa el de el año de establecimiento -> tipoMontoId = 1 (Si se cambia en bd, tambien aqui)
                        var montoGarantia = 0, monto;
                        for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                          monto = 0;
                          areaCompromiso = 0;
                          for (j = 0; j < dataCompromiso.length; j++) {
                            areaCompromiso += parseFloat(dataCompromiso[j].areaGarantia);
                          }
                          monto = areaCompromiso * parseFloat($scope.dataMontoGarantia[i].monto);
                          monto = parseFloat(monto).toFixed(2);
                          item = {
                            etapaDesc: $scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoDesc,
                            monto: monto,
                            vigencia: null
                          }
                          dataMontoVigencia.push(item);
                        }
                        var totalAreaCompromiso = 0, totalMontoCompromiso = 0;
                        for (i = 0; i < dataCompromiso.length; i++) {
                          totalAreaCompromiso += parseFloat(dataCompromiso[i].areaGarantia);
                          totalMontoCompromiso += parseFloat(dataCompromiso[i].montoCompromiso);
                        }
                        $scope.totalAreaCompromiso = parseFloat(totalAreaCompromiso).toFixed(4);
                        $scope.totalMontoCompromiso = parseFloat(totalMontoCompromiso).toFixed(2);
                        var hoy = new Date();
                        var mes = hoy.getMonth() + 1;
                        var anio = hoy.getFullYear();
                        anio = anio + 1;
                        if (mes > 6) {
                          anio++;
                        }
                        if (anio == anioRepoblacion) {
                          anio++;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.numero == null) {
                          $scope.dataTask.ttSeguimientoTarea.numero = 0;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.numero > 0) {
                          anio = $scope.dataTask.ttSeguimientoTarea.numero;
                        }
                        $scope.dataTask.ttSeguimientoTarea.numero = anio;
                        //siempre es 31 de octubre
                        var inicio = new Date(anio, 9, 31);
                        var fin = inicio;
                        var montoCero = false;
                        if ($scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 2
                          || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 3
                          || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 8) {
                          montoCero = true;
                        }
                        for (i = 0; i < dataMontoVigencia.length; i++) {
                          if (i == 0) {
                            dataMontoVigencia[i].vigencia = "Hasta " + $filter('date')(new Date(inicio), 'dd/MM/yyyy');
                          } else {
                            inicio = $scope.addDays(inicio, 1);
                            fin.setFullYear(fin.getFullYear() + 1);
                            dataMontoVigencia[i].vigencia = "Del " + $filter('date')(new Date(inicio), 'dd/MM/yyyy') + " al " + $filter('date')(new Date(fin), 'dd/MM/yyyy');
                            inicio = fin;
                          }
                          if (montoCero) {
                            dataMontoVigencia[i].monto = 0;
                          }
                        }
                        $scope.data.vigencia = dataMontoVigencia;
                        $scope.dataTask.observaciones = dataMontoVigencia;
                        $scope.data.compromiso = dataCompromiso;
                      }
                    }

                    $scope.setAnioInicio = function (item) {
                      dataMontoVigencia = $scope.data.vigencia;
                      var inicio = new Date(item.anio, 9, 31);
                      var fin = inicio;
                      for (i = 0; i < dataMontoVigencia.length; i++) {
                        if (i == 0) {
                          dataMontoVigencia[i].vigencia = "Hasta " + $filter('date')(new Date(inicio), 'dd/MM/yyyy');
                        } else {
                          inicio = $scope.addDays(inicio, 1);
                          fin.setFullYear(fin.getFullYear() + 1);
                          dataMontoVigencia[i].vigencia = "Del " + $filter('date')(new Date(inicio), 'dd/MM/yyyy') + " al " + $filter('date')(new Date(fin), 'dd/MM/yyyy');
                          inicio = fin;
                        }
                      }
                      $scope.data.vigencia = dataMontoVigencia;
                      $scope.dataTask.observaciones = dataMontoVigencia;
                    }

                    $scope.validarDatosRecomendacion = function () {
                      if ($scope.data.recomendacion == null) {
                        $scope.data.meses = $scope.dataGestion.ttResumenGestion.meses;
                        var recomendacion = [
                          { tareaId: $scope.dataTask.tareaId, descripcion: "Se recomienda que la vigencia del aprovechamiento sea de " + $scope.dataGestion.ttResumenGestion.meses + " meses", placeholder: null, enableErase: false, meses: true },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "El propietario debe aprovechar solo el volumen autorizado", placeholder: null, enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "El propietario debe aprovechar solo las especies autorizadas", placeholder: null, enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "La Licencia Forestal debe identificarse mediante un rotulo que contenga los datos generales de la misma.", placeholder: null, enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "Las modificaciones al Plan de Manejo Forestal aprobado deberán solicitarse por escrito para ser analizadas y resueltas por el INAB.", placeholder: null, enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "Que el solicitante presente las constancias de las inscripciones de las motosierras que se utilizarán en el aprovechamiento forestal.", placeholder: null, enableErase: false },
                          { tareaId: $scope.dataTask.tareaId, descripcion: "En cumplimiento del artículo 15 del Reglamento para el Transporte de Productos Forestales se haga entrega de " + $scope.dataTask.ttSeguimientoTarea.monto + " notas de envío.", placeholder: null, enableErase: false, notas: true }
                        ];
                        var data = $scope.dataTask.ttGestion.rodal;
                        if ($scope.dataTask.ttGestion.ttResumenGestion.tcRegente != null) {
                          recomendacion.push({ tareaId: $scope.dataTask.tareaId, descripcion: "Que el solicitante presente propuesta de Regente Forestal", placeholder: null, enableErase: false });
                        }
                        $scope.data.recomendacion = recomendacion;
                      } else {
                        for (var i = 0; i < $scope.data.recomendacion.length; i++) {
                          var linea = $scope.data.recomendacion[i];
                          if (linea.notas) {
                            linea.descripcion = "En cumplimiento del artículo 15 del Reglamento para el Transporte de Productos Forestales se haga entrega de " + $scope.dataTask.ttSeguimientoTarea.monto + " notas de envío.";
                          }
                        }
                      }
                    }

                    /*Validaciones iniciales*/
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                      $scope.data = {
                        antecedente: []
                      }
                      var rodal = $scope.dataGestion.rodal;
                      var sumaTotalVolumen = 0;
                      var bndEstratificado = false;
                      if ($scope.dataGestion.ttInventarioGestion.tcTipoInventario.tipoInventarioId > 1) {
                        bndEstratificado = ($scope.dataGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3 || $scope.dataGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4);
                      }
                      var mostrarMuestreo = 0;
                      var errorMPorcentaje = 0;
                      var enmiendaPorMuestreo = false;
                      for (var i = 0; i < rodal.length; i++) {
                        rodal[i].ttEvaluacionCampo = {};
                        mostrarMuestreo = 0;
                        for (var j = 0; j < rodal[i].especies.length; j++) {
                          if (rodal[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                            if (mostrarMuestreo == 0) {
                              mostrarMuestreo = 1;
                            }
                          }
                          if (parseInt(rodal[i].turno) == 1) {
                            sumaTotalVolumen += parseFloat(rodal[i].especies[j].volrExtraer);
                          }
                          rodal[i].especies[j].ttEspecieEvaluada = {
                            arboles: 0,
                            dapPromedio: 0,
                            alturaPromedio: 0,
                            dapPromedioVerificado: 0,
                            alturaPromedioVerificado: 0,
                            areaBasal: 0,
                            volumen: 0,
                            areaBasalVerificado: 0,
                            volumenVerificado: 0,
                            volumenDiferencia: 0,
                            volumenPorcentaje: 0
                          };
                        }
                        if (mostrarMuestreo == 1) {
                          rodal[i].ttEvaluacionCampo.mostrarMuestreo = mostrarMuestreo;
                          if (bndEstratificado) {
                            rodal[i].ttEvaluacionCampo.errorp = $scope.dataGestion.ttEstadisticoGestion.errorp;
                          } else {
                            if (rodal[i].ttEstadisticoRodal == null) {
                              enmiendaPorMuestreo = true;
                            } else {
                              rodal[i].ttEvaluacionCampo.errorp = rodal[i].ttEstadisticoRodal.errorp;
                            }
                          }
                        }
                      }
                      var cantidadNotas = sumaTotalVolumen / 12;
                      $scope.dataTask.ttSeguimientoTarea.monto = Math.round(cantidadNotas);
                      $scope.dataTask.aprobado = null;
                      if (enmiendaPorMuestreo) {
                        swal('Error en elaboración de plan de manejo', 'Debe solicitar enmienda para el análisis estadístico debido a que no está completo y le producirá error. Normalmente este problema se dá porque el elaborador indica que realizará un censo pero hace mal uso de la boleta indicando parcelas. Asegúrese de incluir todas las enmiendas debido a que no podrá agregarlo en las siguientes, si solicita de nuevo');
                      }
                    } else {
                      try {
                        //$scope.data = JSON.parse($scope.dataTask.ttSeguimientoTarea.fundamento);
                        if ($scope.dataTask.aprobado == 1) {
                          $scope.classAprobar = "btn btn-success";
                          $scope.visibleEnmienda = false;
                          $scope.mostrarInfo = true;
                        }
                        if ($scope.dataTask.aprobado == 0) {
                          $scope.classNoAprobar = "btn btn-danger";
                          $scope.visibleEnmienda = false;
                        }
                        $scope.data = {};
                        if ($scope.dataTask.ttSeguimientoTarea.antecedente != null) {
                          try {
                            $scope.dataTask.ttSeguimientoTarea.antecedente = JSON.parse($scope.dataTask.ttSeguimientoTarea.antecedente);
                            $scope.data.antecedente = $scope.dataTask.ttSeguimientoTarea.antecedente;
                          } catch (e) {
                            $scope.data.antecedente = [];
                          }
                        } else {
                          $scope.data.antecedente = [];
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.analisis != null) {
                          try {
                            $scope.dataTask.ttSeguimientoTarea.analisis = JSON.parse($scope.dataTask.ttSeguimientoTarea.analisis);
                            $scope.data.conclusion = $scope.dataTask.ttSeguimientoTarea.analisis;
                          } catch (e) { }
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.fundamento != null) {
                          try {
                            $scope.dataTask.ttSeguimientoTarea.fundamento = JSON.parse($scope.dataTask.ttSeguimientoTarea.fundamento);
                            $scope.data.recomendacion = $scope.dataTask.ttSeguimientoTarea.fundamento;
                          } catch (e) { }
                        }
                        switch ($scope.dataTask.ttSeguimientoTarea.tab) {
                          case 2:
                            $scope.validarDatosGeneral();
                            $scope.tabVisible.uno = true;
                            $scope.tabActive.uno = true;
                            break;
                          case 3:
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabActive.dos = true;
                            break;
                          case 4:
                            $scope.validarDatosConclusiones();
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabActive.tres = true;
                            break;
                          case 5:
                            $scope.validarInventario();
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.tabActive.cuatro = true;
                            break;
                          case 6:
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.tabActive.cuatro = true;
                            break;
                          case 7:
                            $scope.validarInventario();
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.tabVisible.cinco = true;
                            $scope.tabVisible.seis = true;
                            $scope.tabVisible.siete = true;
                            $scope.tabActive.siete = true;
                            break;
                          case 8:
                            $scope.validarDatosRecomendacion();
                            $scope.data.meses = $scope.dataTask.ttGestion.ttResumenGestion.meses;
                            $scope.dataTask.ttSeguimientoTarea.area = $scope.data.meses;
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.tabVisible.cinco = true;
                            $scope.tabVisible.seis = true;
                            $scope.tabVisible.siete = true;
                            $scope.tabActive.siete = true;
                            break;
                          case 9:
                            $scope.data.meses = $scope.dataTask.ttSeguimientoTarea.area;
                            $scope.tabVisible.uno = true;
                            $scope.tabVisible.dos = true;
                            $scope.tabVisible.tres = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.tabVisible.cinco = true;
                            $scope.tabVisible.seis = true;
                            $scope.tabVisible.siete = true;
                            $scope.tabActive.siete = true;
                            $scope.mostrarGuardar = true;
                            break;
                        }
                      } catch (e) {
                        toastr.error(e.message);
                      }
                    }

                    if ($scope.dataGestion.ttInventarioGestion.tcTipoInventario.tipoInventarioId == 1) {
                      $scope.obligar = false;
                    } else {
                      $scope.obligar = true;
                    }

                    $scope.aplicarDatos = function (rodal, estado, id) {
                      if (estado == 1) {
                        switch (parseInt(id)) {
                          case 1:
                            rodal.ttEvaluacionCampo.areav = rodal.area;
                            break;
                          case 2:
                            rodal.ttEvaluacionCampo.pendientePromediov = rodal.pendientePromedio;
                            break;
                          case 3:
                            rodal.ttEvaluacionCampo.codigoClasev = rodal.tcClaseDesarrollo.codigoClase;
                            break;
                          case 4:
                            rodal.ttEvaluacionCampo.incrementov = rodal.incremento;
                            break;
                          case 5:
                            rodal.ttEvaluacionCampo.edadv = rodal.edad;
                            break;
                          case 6:
                            var especies = '';
                            for (var i = 0; i < rodal.especies.length; i++) {
                              if (i == 0) {
                                especies = rodal.especies[i].tcEspecie.nombreCientifico;
                              } else {
                                especies += '\n' + rodal.especies[i].tcEspecie.nombreCientifico;
                              }
                            }
                            rodal.ttEvaluacionCampo.especiesv = especies;
                            break;
                          default:
                            break;
                        }
                      } else {
                        switch (parseInt(id)) {
                          case 1:
                            rodal.ttEvaluacionCampo.areav = null;
                            break;
                          case 2:
                            rodal.ttEvaluacionCampo.pendientePromediov = null;
                            break;
                          case 3:
                            rodal.ttEvaluacionCampo.codigoClasev = null;
                            break;
                          case 4:
                            rodal.ttEvaluacionCampo.incrementov = null;
                            break;
                          case 5:
                            rodal.ttEvaluacionCampo.edadv = null;
                            break;
                          case 6:
                            rodal.ttEvaluacionCampo.especiesv = null;
                            break;
                          default:
                            break;
                        }
                      }
                    }

                    $scope.subirBoleta = function () {
                      ngDialog.open({
                        template: 'app/tarea/subir.boleta.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueSubir',
                        name: 'dialogSubir',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr', 'authService',
                          function ($scope, $state, toastr, authService) {
                            authService.removeLocalData('tipoInventarioId');
                            authService.removeLocalData('dataOriginal');
                            authService.saveLocalData('dataOriginal', $scope.dataGestion.ttInventarioGestion.data);
                            authService.saveLocalData('tipoInventarioId', $scope.dataGestion.ttInventarioGestion.tcTipoInventario.tipoInventarioId);
                            authService.saveLocalData('origenCalculoId', $scope.dataGestion.ttInventarioGestion.tcOrigenCalculo.origenCalculoId);
                            authService.saveLocalData('esVerificacion', true);
                            $scope.gridData = angular.copy($scope.gridOptionsSelection);
                            $scope.gridData.enableFiltering = false;

                            $scope.submitFormData = function (isValid) {
                              if (isValid) {
                                var data = $scope.gridData.data;
                                var dataResumen = $scope.dataTask.ttGestion.rodal;
                                for (var i = 0; i < dataResumen.length; i++) {
                                  var especies = dataResumen[i].especies;
                                  for (var j = 0; j < especies.length; j++) {
                                    var especie = especies[j];
                                    var especieEvaluadaId = null;
                                    if (especie.ttEspecieEvaluada != null) {
                                      especieEvaluadaId = especie.ttEspecieEvaluada.especieEvaluadaId;
                                    }
                                    especie.ttEspecieEvaluada = {
                                      arboles: 0,
                                      dapPromedio: 0,
                                      alturaPromedio: 0,
                                      dapPromedioVerificado: 0,
                                      alturaPromedioVerificado: 0,
                                      areaBasal: 0,
                                      volumen: 0,
                                      areaBasalVerificado: 0,
                                      volumenVerificado: 0,
                                      volumenDiferencia: 0,
                                      volumenPorcentaje: 0,
                                      especieEvaluadaId: especieEvaluadaId
                                    }
                                  }
                                }
                                var rodalId = 0;
                                var i = 0;
                                var totalParcelas = 0, parcelaAnt = 0, tamanioParcela;
                                for (i = 0; i < data.length; i++) {
                                  var row = {
                                    correlativo: data[i].RODAL
                                  }
                                  //buscar rodal
                                  var j = 0;
                                  for (j = 0; j < dataResumen.length; j++) {
                                    if (dataResumen[j].correlativo == row.correlativo) {
                                      row = dataResumen[j];
                                      break;
                                    }
                                  }
                                  var tcTipoInventario = {
                                    tipoInventarioId: 2
                                  }
                                  if (data[i].PARCELA == null) {
                                    tcTipoInventario = {
                                      tipoInventarioId: 1
                                    }
                                  }
                                  /*buscar especie dentro del rodal para determinar si suma o agrega el registro */
                                  for (j = 0; j < row.especies.length; j++) {
                                    especie = row.especies[j];
                                    /*Si la especie ya existe solo se actualiza la informacion*/
                                    if (especie.tcEspecie.especieId == data[i].ESPECIE_ID
                                      && especie.tcTipoInventario.tipoInventarioId == tcTipoInventario.tipoInventarioId) {
                                      especie.ttEspecieEvaluada.arboles = parseInt(especie.ttEspecieEvaluada.arboles) + 1;
                                      especie.ttEspecieEvaluada.dapPromedio = parseFloat(especie.ttEspecieEvaluada.dapPromedio) + parseFloat(data[i].DAP);
                                      especie.ttEspecieEvaluada.alturaPromedio = parseFloat(especie.ttEspecieEvaluada.alturaPromedio) + parseFloat(data[i].ALTURA);
                                      especie.ttEspecieEvaluada.dapPromedioVerificado = parseFloat(especie.ttEspecieEvaluada.dapPromedioVerificado) + parseFloat(data[i].DAP_VERIFICADO);
                                      especie.ttEspecieEvaluada.alturaPromedioVerificado = parseFloat(especie.ttEspecieEvaluada.alturaPromedioVerificado) + parseFloat(data[i].ALTURA_VERIFICADO);
                                      especie.ttEspecieEvaluada.areaBasal = parseFloat(especie.ttEspecieEvaluada.areaBasal) + parseFloat(data[i].AREA_BASAL);
                                      especie.ttEspecieEvaluada.volumen = parseFloat(especie.ttEspecieEvaluada.volumen) + parseFloat(data[i].VOLUMEN);
                                      especie.ttEspecieEvaluada.areaBasalVerificado = parseFloat(especie.ttEspecieEvaluada.areaBasalVerificado) + parseFloat(data[i].AREA_BASAL_VERIFICADO);
                                      especie.ttEspecieEvaluada.volumenVerificado = parseFloat(especie.ttEspecieEvaluada.volumenVerificado) + parseFloat(data[i].VOLUMEN_VERIFICADO);
                                      break;
                                    }
                                  }
                                } //fin lectura de informacion
                                //obtener promedios por rodal
                                var valor = 0, pTotal = 0;
                                var totalArboles = 0, totalEspecies;
                                var bndAdvertencia = false;
                                for (i = 0; i < dataResumen.length; i++) {
                                  for (var j = 0; j < dataResumen[i].especies.length; j++) {
                                    dataResumen[i].especies[j].ttEspecieEvaluada.areaBasal = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.areaBasal).toFixed(4);
                                    dataResumen[i].especies[j].ttEspecieEvaluada.volumen = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.volumen).toFixed(4);
                                    if (parseInt(dataResumen[i].especies[j].ttEspecieEvaluada.arboles) > 0) {
                                      valor = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.alturaPromedio) / parseInt(dataResumen[i].especies[j].ttEspecieEvaluada.arboles);
                                      dataResumen[i].especies[j].ttEspecieEvaluada.alturaPromedio = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.dapPromedio) / parseInt(dataResumen[i].especies[j].ttEspecieEvaluada.arboles);
                                      dataResumen[i].especies[j].ttEspecieEvaluada.dapPromedio = parseFloat(valor).toFixed(2);
                                      //verificacion
                                      dataResumen[i].especies[j].ttEspecieEvaluada.areaBasalVerificado = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.areaBasalVerificado).toFixed(4);
                                      dataResumen[i].especies[j].ttEspecieEvaluada.volumenVerificado = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.volumenVerificado).toFixed(4);
                                      valor = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.alturaPromedioVerificado) / parseInt(dataResumen[i].especies[j].ttEspecieEvaluada.arboles);
                                      dataResumen[i].especies[j].ttEspecieEvaluada.alturaPromedioVerificado = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(dataResumen[i].especies[j].ttEspecieEvaluada.dapPromedioVerificado) / parseInt(dataResumen[i].especies[j].ttEspecieEvaluada.arboles);
                                      dataResumen[i].especies[j].ttEspecieEvaluada.dapPromedioVerificado = parseFloat(valor).toFixed(2);
                                      valor = dataResumen[i].especies[j].ttEspecieEvaluada.volumenVerificado - dataResumen[i].especies[j].ttEspecieEvaluada.volumen;
                                      dataResumen[i].especies[j].ttEspecieEvaluada.volumenDiferencia = parseFloat(valor).toFixed(4);
                                      valor = (dataResumen[i].especies[j].ttEspecieEvaluada.volumenDiferencia * 100) / dataResumen[i].especies[j].ttEspecieEvaluada.volumenVerificado;
                                      valor = Math.abs(valor);
                                      pTotal += valor;
                                      absValor = Math.abs(valor);
                                      if (absValor > appSettings.porcentajeMaximo) {
                                        bndAdvertencia = true;
                                      }
                                      dataResumen[i].especies[j].ttEspecieEvaluada.volumenPorcentaje = parseFloat(valor).toFixed(2);
                                    }
                                  }
                                }
                                if (bndAdvertencia) {
                                  swal('Existen datos que sobrepasan el ' + appSettings.porcentajeMaximo + ' % de diferencia');
                                }
                                authService.removeLocalData('dataOriginal');
                                authService.removeLocalData('tipoInventarioId');
                                authService.removeLocalData('origenCalculoId');
                                authService.removeLocalData('esVerificacion');
                                ngDialog.close();
                              }
                            } //fin submitFormData

                          }]
                      })
                    }

                    $scope.cerrarVentana = function () {
                      $scope.classEnmienda = "btn btn-default";
                      ngDialog.close();
                    }

                    $scope.findValorByEspecie = function (especieId) {

                      return item;
                    }

                    $scope.validarDatosValorMadera = function () {
                      var data = $scope.dataTask.ttGestion.rodal;
                      if (data[0].especies[0].ttEspecieEvaluada == null) {
                        swal('Información', 'Es necesario que recargue su tarea, vuelva a presionar sobre el botón de realizar tarea');
                        $state.go('index.tarea.list');
                        return;
                      } else {
                        if (data[0].especies[0].ttEspecieEvaluada.especieEvaluadaId == null) {
                          swal('Información', 'Es necesario que recargue su tarea, vuelva a presionar sobre el botón de realizar tarea');
                          $state.go('index.tarea.list');
                          return;
                        } else {
                          if (data[0].especies[0].ttEspecieEvaluada.especieEvaluadaId <= 0) {
                            swal('Información', 'Es necesario que recargue su tarea, vuelva a presionar sobre el botón de realizar tarea');
                            $state.go('index.tarea.list');
                            return;
                          }
                        }
                      }
                      var continuar = true;
                      var i, j;
                      for (i = 0; i < data.length; i++) {
                        for (j = 0; j < data[i].especies.length; j++) {
                          if (data[i].especies[j].ttEspecieEvaluada.totalValor > 0 || data[i].especies[j].ttEspecieEvaluada.totalValor > 0) {
                            continuar = false;
                            break;
                          }
                        }
                        if (!continuar) {
                          break;
                        }
                      }
                      if (continuar) {
                        var dataTmp = angular.copy($scope.dataTask.ttGestion.rodal);
                        var data = [];
                        //obtener datos del primer turno
                        var strMensaje = '';
                        for (i = 0; i < dataTmp.length; i++) { //rodales
                          if (parseInt(dataTmp[i].turno) == 1) {
                            var especies = dataTmp[i].especies;
                            for (var j = 0; j < especies.length; j++) {
                              var volrExtraerValidar = parseFloat(especies[j].troza) + parseFloat(especies[j].lenia);
                              volrExtraerValidar = parseFloat(volrExtraerValidar).toFixed(4);
                              var volrExtraer = parseFloat(especies[j].volrExtraer).toFixed(4);
                              var diferenciaVolumen = parseFloat(volrExtraerValidar) - parseFloat(volrExtraer);
                              diferenciaVolumen = Math.abs(diferenciaVolumen);
                              diferenciaVolumen = parseFloat(diferenciaVolumen).toFixed(4);
                              if (diferenciaVolumen > 0.5) {
                                strMensaje += 'rodal: ' + dataTmp[i].correlativo + ', especie: ' + especies[j].tcEspecie.nombreCientifico + '; ';
                              }
                            }
                            data.push(dataTmp[i]);
                          }
                        }
                        if (strMensaje.length > 0) {
                          swal('La suma del volumen de troza y leña de las siguientes especies es diferente al total de volumen a extraer, esto se corrige en el plan de manejo. \n' + strMensaje);
                        }
                        var lista = [];
                        //buscar especie por rodal y obtener el precio a través del servicio, segun año y region
                        for (i = 0; i < data.length; i++) { //rodales
                          for (j = 0; j < data[i].especies.length; j++) {
                            lista.push(data[i].especies[j].tcEspecie);
                          }
                        }
                        var fecha = new Date();
                        var anio = fecha.getFullYear();
                        var filtro = {
                          anio: anio,
                          tcRegion: $scope.dataTask.ttGestion.tcSubregion.tcRegion,
                          especies: lista
                        }
                        valortipomaderaService.listaCostoTipoMaderaAnio(filtro).then(function (res) {
                          if (res.status == "OK") {
                            var data = res.data[0];
                            var valor = data.valor;
                            especies = data.especies;
                            for (j = 0; j < especies.length; j++) {
                              for (i = 0; i < valor.length; i++) {
                                if (valor[i].tcTipoMadera.tipoMaderaId == especies[j].tcTipoMadera.tipoMaderaId) {
                                  especies[j].tcValorTipoMadera = valor[i];
                                  break;
                                }
                              }
                            }
                            $scope.dataValorMadera = especies;
                            var valor = 0, valorTurno = 0;
                            var item = null;
                            var row = null;
                            //buscar valor y calcular
                            data = $scope.dataTask.ttGestion.rodal;
                            for (i = 0; i < data.length; i++) { //rodales
                              if (data[i].turno == 1) {
                                for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                                  item = null;
                                  for (var k = 0; k < especies.length; k++) {
                                    row = especies[k];
                                    if (parseInt(row.especieId) == parseInt(data[i].especies[j].tcEspecie.especieId)) {
                                      item = especies[k].tcValorTipoMadera;
                                      break;
                                    }
                                  }
                                  if (item == null) {
                                    swal('La especie ' + data[i].especies[j].tcEspecie.nombreCientifico + ' no tiene valor asignado, favor de indicar al administrador del sistema');
                                    return;
                                  }
                                  valor = parseFloat(data[i].especies[j].troza) * parseFloat(item.troza);
                                  data[i].especies[j].ttEspecieEvaluada.trozaValor = parseFloat(valor).toFixed(2);
                                  valor = parseFloat(data[i].especies[j].lenia) * parseFloat(item.lenia);
                                  data[i].especies[j].ttEspecieEvaluada.leniaValor = parseFloat(valor).toFixed(2);
                                  valor = parseFloat(data[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(data[i].especies[j].ttEspecieEvaluada.leniaValor);
                                  valor = parseFloat(valor).toFixed(2);
                                  data[i].especies[j].totalValor = valor;
                                  valor = valor * 0.1;
                                  valorTurno += valor;
                                  valor = parseFloat(valor).toFixed(2);
                                  data[i].especies[j].totalPagar = valor;
                                }
                              } else {
                                for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                                  data[i].especies[j].totalValor = 0;
                                  data[i].especies[j].totalPagar = 0;
                                }
                              }
                            }
                            $scope.data.valorTurno = parseFloat(valorTurno).toFixed(2);
                            $scope.data.valor = data;

                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        if ($scope.data.valorTurno == null) {
                          data = $scope.dataTask.ttGestion.rodal;
                          var valorTurno = 0;
                          for (i = 0; i < data.length; i++) { //rodales
                            if (data[i].turno == 1) {
                              for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                                var valor = parseFloat(data[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(data[i].especies[j].ttEspecieEvaluada.leniaValor);
                                valor = parseFloat(valor).toFixed(2);
                                data[i].especies[j].totalValor = valor;
                                valor = valor * 0.1;
                                valorTurno += valor;
                                valor = parseFloat(valor).toFixed(2);
                                data[i].especies[j].totalPagar = valor;
                              }
                            } else {
                              for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                                data[i].especies[j].totalValor = 0;
                                data[i].especies[j].totalPagar = 0;
                              }
                            }
                          }
                          $scope.data.valorTurno = parseFloat(valorTurno).toFixed(2);
                          $scope.data.valor = data;
                        }
                      }
                    }

                    $scope.noHayConfirmacion = false;

                    $scope.setAprobarTecnico = function (ok) {
                      $scope.classEnmienda = "btn btn-default";
                      if ($scope.dataTask.aprobado == ok) {
                        $scope.mostrar = false;
                        $scope.mostrarInfo = false;
                        $scope.classNoAprobar = "btn btn-default";
                        $scope.classAprobar = "btn btn-default";
                        $scope.tabActive.cero = true;
                        $scope.visibleEnmienda = true;
                        $scope.dataTask.aprobado = null;
                        return;
                      }
                      $scope.dataTask.aprobado = ok;
                      $scope.mostrar = true;
                      if (ok == 1) {
                        $scope.classAprobar = "btn btn-success";
                        $scope.classNoAprobar = "btn btn-default";
                        $scope.data.aprobadoDesc = "APROBAR";
                        $scope.mostrarInfo = true;
                      } else {
                        $scope.classNoAprobar = "btn btn-danger";
                        $scope.classAprobar = "btn btn-default";
                        $scope.data.aprobadoDesc = "NO APROBAR";
                        $scope.mostrarInfo = false;
                      }
                      $scope.visibleEnmienda = false;
                      $scope.enmienda = [];
                      $scope.noHayConfirmacion = true;
                    }

                    $scope.evaluacionCampo = function (isValid) {
                      if (isValid) {
                        var ok = false;
                        var rodal = $scope.dataTask.ttGestion.rodal;
                        for (var i = 0; i < rodal.length; i++) {
                          for (var j = 0; j < rodal[i].especies.length; j++) {
                            if (rodal[i].especies[j].ttEspecieEvaluada.arboles > 0) {
                              ok = true;
                              break;
                            }
                          }
                          if (ok) {
                            break;
                          }
                        }
                        isValid = ok;
                        if (!ok) {
                          toastr.error("Debe indicar la evaluación dasométrica");
                        }
                      } else {
                        toastr.error("Debe rellenar todos los campos");
                      }
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion,
                          rodal: ttGestion.rodal
                        };
                        for (var i = 0; i < item.ttGestion.rodal.length; i++) {
                          var rodal = item.ttGestion.rodal[i];
                          delete rodal.brinzal;
                          delete rodal.latizal;
                          delete rodal.parcelas;
                          delete rodal.productos;
                          delete rodal.repoblacion;
                          delete rodal.tcClaseDesarrollo;
                          delete rodal.ttEstadisticoRodal;
                          for (var j = 0; j < rodal.especies.length; j++) {
                            delete rodal.especies[j].distribucion;
                            delete rodal.especies[j].fuste;
                          }
                        }
                        item.ttSeguimientoTarea.tab = 2;
                        if (item.ttSeguimientoTarea.analisis != null) {
                          delete item.ttSeguimientoTarea.analisis;
                        }
                        if (item.ttSeguimientoTarea.antecedente != null) {
                          delete item.ttSeguimientoTarea.antecedente;
                        }
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        if (item.observaciones != null) {
                          delete item.observaciones;
                        }
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.tabActive.uno = true;
                            $scope.tabVisible.uno = true;
                            $scope.noHayConfirmacion = false;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.informacionGeneral = function (isValid) {
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.ttSeguimientoTarea.tab = 3;
                        var general = $scope.data.general;
                        for (var i = 0; i < general.length; i++) {
                          if (general[i].id == 11) {
                            item.ttSeguimientoTarea.observaciones = general[i].descripcion;
                          }
                        }
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        if (item.ttSeguimientoTarea.analisis != null) {
                          delete item.ttSeguimientoTarea.analisis;
                        }
                        if (item.ttSeguimientoTarea.antecedente != null) {
                          delete item.ttSeguimientoTarea.antecedente;
                        }
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataTask.ttSeguimientoTarea.observaciones = item.ttSeguimientoTarea.observaciones;
                            $scope.tabActive.dos = true;
                            $scope.tabVisible.dos = true;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe indicar contenido en el apartado de fuentes de agua');
                      }
                    }

                    $scope.enviarAntecedentes = function (isValid) {
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.ttSeguimientoTarea.tab = 4;
                        var antecedente = JSON.stringify($scope.data.antecedente);
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        if (item.ttSeguimientoTarea.analisis != null) {
                          delete item.ttSeguimientoTarea.analisis;
                        }
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        item.ttSeguimientoTarea.antecedente = antecedente;
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.tabActive.tres = true;
                            $scope.tabVisible.tres = true;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos con información');
                      }
                    }

                    $scope.enviarConclusiones = function (isValid) {
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.ttSeguimientoTarea.tab = 5;
                        var analisis = JSON.stringify($scope.data.conclusion);
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        item.ttSeguimientoTarea.analisis = analisis;
                        item.tcPersonaAsignada.foto = null;
                        if (item.ttSeguimientoTarea.antecedente != null) {
                          delete item.ttSeguimientoTarea.antecedente;
                        }
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataTask.ttSeguimientoTarea.analisis = $scope.data.conclusion;
                            $scope.tabActive.cuatro = true;
                            $scope.tabVisible.cuatro = true;
                            $scope.dataTask.ttSeguimientoTarea.tab = 5;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos con información');
                      }
                    }

                    $scope.avanzarTab = function (isValid, index) {
                      if (index == 1) {
                        $scope.validarDatosCompromiso();
                        $scope.tabActive.cinco = true;
                        $scope.tabVisible.cinco = true;
                        $scope.dataTask.ttSeguimientoTarea.tab = 6;
                      } else {
                        var item = angular.copy($scope.dataTask);
                        var rodales = angular.copy($scope.data.compromiso);
                        for (var i = 0; i < rodales.length; i++) {
                          delete rodales[i].brinzal;
                          delete rodales[i].especies;
                          delete rodales[i].latizal;
                          delete rodales[i].parcelas;
                          delete rodales[i].repoblacion;
                        }
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.ttSeguimientoTarea.tab = 6;
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion,
                          rodal: rodales
                        };
                        if (item.ttSeguimientoTarea.analisis != null) {
                          delete item.ttSeguimientoTarea.analisis;
                        }
                        item.tcPersonaAsignada.foto = null;
                        if (item.ttSeguimientoTarea.antecedente != null) {
                          delete item.ttSeguimientoTarea.antecedente;
                        }
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        item.observaciones = JSON.stringify(item.observaciones);
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.tabActive.seis = true;
                            $scope.tabVisible.seis = true;
                            $scope.dataTask.ttSeguimientoTarea.tab = 7;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.enviarValorMadera = function (isValid) {
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.ttSeguimientoTarea.tab = 7;
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion,
                          rodal: ttGestion.rodal
                        };
                        for (var i = 0; i < item.ttGestion.rodal.length; i++) {
                          var rodal = item.ttGestion.rodal[i];
                          delete rodal.brinzal;
                          delete rodal.latizal;
                          delete rodal.parcelas;
                          delete rodal.productos;
                          delete rodal.repoblacion;
                          delete rodal.tcClaseDesarrollo;
                          delete rodal.ttEstadisticoRodal;
                          for (var j = 0; j < rodal.especies.length; j++) {
                            delete rodal.especies[j].distribucion;
                            delete rodal.especies[j].fuste;
                          }
                        }
                        item.tcPersonaAsignada.foto = null;
                        delete item.ttSeguimientoTarea.analisis;
                        delete item.ttSeguimientoTarea.antecedente;
                        if (item.ttSeguimientoTarea.fundamento != null) {
                          delete item.ttSeguimientoTarea.fundamento;
                        }
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        if (item.observaciones != null) {
                          delete item.observaciones;
                        }
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.tabActive.siete = true;
                            $scope.tabVisible.siete = true;
                            $scope.dataTask.ttSeguimientoTarea.tab = 8;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos con información');
                      }
                    }

                    $scope.enviarRecomendaciones = function (isValid) {
                      if (isValid) {
                        var item = angular.copy($scope.dataTask);
                        item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                        item.tcPersonaAsignada.foto = null;
                        item.ttSeguimientoTarea.tab = 8;
                        var recomendacion = JSON.stringify($scope.data.recomendacion);
                        var ttGestion = item.ttGestion;
                        item.ttGestion = {};
                        item.ttGestion = {
                          gestionId: ttGestion.gestionId,
                          tcTipoGestion: ttGestion.tcTipoGestion,
                          estadoId: ttGestion.estadoId,
                          tcPersonaCrea: ttGestion.tcPersonaCrea,
                          tcTipoBosque: ttGestion.tcTipoBosque,
                          tcElaborador: ttGestion.tcElaborador,
                          ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                        };
                        delete item.ttSeguimientoTarea.analisis;
                        delete item.ttSeguimientoTarea.antecedente;
                        item.ttSeguimientoTarea.fundamento = recomendacion;
                        item.ttSeguimientoTarea.area = $scope.data.meses;
                        if (item.ttSeguimientoTarea.enmienda != null) {
                          delete item.ttSeguimientoTarea.enmienda;
                        }
                        if (item.ttSeguimientoTarea.observaciones != null) {
                          delete item.ttSeguimientoTarea.observaciones;
                        }
                        if (item.observaciones != null) {
                          delete item.observaciones;
                        }
                        tareaService.actualizarTareaTecnico(item).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataTask.ttSeguimientoTarea.fundamento = $scope.data.recomendacion;
                            $scope.mostrarGuardar = true;
                            $scope.dataTask.ttSeguimientoTarea.tab = 9;
                            $window.scrollTo(0, 0);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Debe rellenar todos los campos con información');
                      }
                    }

                    $scope.setNotasEnvio = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.monto != null) {
                        if ($scope.dataTask.ttSeguimientoTarea.monto <= 0) {
                          toastr.error("Debe ingresar una cantidad mayor a 0 (cero)");
                        }
                      }
                    }

                    $scope.setMeses = function () {
                      if ($scope.data.meses != null) {
                        if ($scope.data.meses > 0 && $scope.data.meses < 13) {
                          var encontrado = false;
                          var item = { tareaId: $scope.dataTask.tareaId, descripcion: "Se recomienda que la vigencia del aprovechamiento sea de " + $scope.data.meses + " meses", placeholder: null, enableErase: false, meses: true };
                          for (var i = 0; i < $scope.data.recomendacion.length; i++) {
                            if ($scope.data.recomendacion[i].meses == true) {
                              encontrado = true;
                              item = $scope.data.recomendacion[i];
                            }
                          }
                          if (encontrado) {
                            item.descripcion = "Se recomienda que la vigencia del aprovechamiento sea de " + $scope.data.meses + " meses";
                          } else {
                            $scope.data.recomendacion.push(item);
                          }
                          $scope.dataTask.ttSeguimientoTarea.area = $scope.data.meses;
                        } else {
                          toastr.error("Debe ingresar una cantidad mayor a 0 (cero) y menor a trece (13)");
                        }
                      }
                    }

                    $scope.agregarAntecedente = function () {
                      $scope.data.antecedente.push({ tareaId: $scope.dataTask.tareaId, descripcion: null });
                    }

                    $scope.borrarDetalle = function (item) {
                      $scope.data.antecedente.splice($scope.data.antecedente.indexOf(item), 1);
                    }

                    $scope.agregarConclusion = function () {
                      $scope.data.conclusion.push({ tareaId: $scope.dataTask.tareaId, descripcion: null, placeholder: "Descripción de la conclusión", enableErase: true });
                    }

                    $scope.borrarConclusion = function (item) {
                      $scope.data.conclusion.splice($scope.data.conclusion.indexOf(item), 1);
                    }

                    $scope.agregarRecomendacion = function () {
                      $scope.data.recomendacion.push({ tareaId: $scope.dataTask.tareaId, descripcion: null, placeholder: "Descripción de la recomendación", enableErase: true });
                    }

                    $scope.borrarRecomendacion = function (item) {
                      $scope.data.recomendacion.splice($scope.data.recomendacion.indexOf(item), 1);
                    }

                    $scope.variable = {};
                    $scope.approved = false;

                    $scope.vistaPrevia = function () {
                      if ($scope.mostrarGuardar) {
                        if ($scope.dataTask.aprobado == null) {
                          toastr.error("Debe seleccionar su decisión");
                          return;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                          $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                        }
                        if ($scope.noHayConfirmacion) {
                          swal('Se ha realizado cambios en su dictamen (aprobar, no aprobar o enmienda) que no ha guardado, favor de realizarlo');
                          return;
                        }
                        gestionService.getGestionParaTarea($scope.dataTask.tareaId).then(function (res) {
                          if (res.status == "OK") {
                            var data = res.data[0];
                            if (data.ttSeguimientoTarea.numero == 0) {
                              swal('Por favor seleccione un año de inicio (Pestaña: VI. Compromiso Repoblación, no importa si no aplica) y presione sobre el botón de guardar');
                              return;
                            }
                            if (data.ttSeguimientoTarea.antecedente != null) {
                              data.ttSeguimientoTarea.antecedente = JSON.parse(data.ttSeguimientoTarea.antecedente);
                            }
                            data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                            data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                            utilsService.setEsPrevia(true);
                            utilsService.generarOpinionTecnica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.enviarTareaTecnico = function (isAmendment) {
                      if (isAmendment == undefined) {
                        isAmendment = false;
                      } else {
                        if (isAmendment == null) {
                          isAmendment = false;
                        }
                      }
                      if (isAmendment) {
                        $scope.dataTask.aprobado = 2;
                        $scope.dataTask.esEnmienda = 1;
                      }
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      var mensaje = "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar es decir no hay regreso de tarea";
                      if ($scope.dataTask.aprobado < 2) {
                        if ($scope.dataTask.aprobado == 0) {
                          mensaje = "¿Está seguro (a) de guardar su opinión (NO APROBAR EL PLAN DE MANEJO)? \nEsta acción no se podrá reversar";
                          if ($scope.dataTask.ttSeguimientoTarea.numero == null) {
                            swal('Por favor seleccione un año de inicio (Pestaña: VI. Compromiso Repoblación, no importa si no aplica y presione sobre el botón de guardar');
                            return;
                          }
                          if ($scope.dataTask.ttSeguimientoTarea.numero == 0) {
                            swal('Por favor seleccione un año de inicio (Pestaña: VI. Compromiso Repoblación, no importa si no aplica) y presione sobre el botón de guardar');
                            return;
                          }
                        } else {
                          mensaje = "¿Está seguro (a) de guardar su opinión (APROBAR EL PLAN DE MANEJO)? \nEsta acción no se podrá reversar";
                        }
                      }
                      if ($scope.noHayConfirmacion) {
                        swal('Se ha realizado cambios en su dictamen que no ha guardado, favor de realizarlo');
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: mensaje,
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var item = angular.copy($scope.dataTask);
                            var ttGestion = item.ttGestion;
                            item.ttGestion = {};
                            item.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            item.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            item.tcPersonaAsignada.foto = null;
                            $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                            delete item.observaciones;
                            delete item.ttSeguimientoTarea.antecedente;
                            delete item.ttSeguimientoTarea.analisis;
                            delete item.ttSeguimientoTarea.fundamento;
                            if (isAmendment) {
                              item.ttSeguimientoTarea.enmienda = JSON.stringify(item.ttSeguimientoTarea.enmienda);
                              delete item.ttSeguimientoTarea.observaciones;
                            }
                            tareaService.setOpinionTecnica(item).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success(res.message);
                                if (isAmendment) {
                                  utilsService.generarEnmiendaTecnica($scope.dataTask, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarOpinionTecnica($scope.dataTask, $scope.loginData.usuario, $scope.dataEmpresa, $scope.data.sigla, true);
                                }
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.generarAnalisisEstadistico = function () {
                      $scope.precisionId = $scope.dataGestion.ttEstadisticoGestion.precisionId;
                      if ($scope.data == null) {
                        $scope.data = {};
                      }
                      if ($scope.data.dataAnalisis == null) {
                        var dataAnalisis = [];
                        var data = $scope.dataTask.ttGestion.ttInventarioGestion.data;
                        var i = 0, j = 0, k = 0;
                        var row, tmpData, parcela, valor, encontrado = false;
                        //obtener datos por rodal
                        var tmpRodal = angular.copy($scope.dataTask.ttGestion.rodal);
                        var contieneMuestreo = false;
                        var tipoInventarioId = 1;
                        for (i = 0; i < tmpRodal.length; i++) {
                          tipoInventarioId = 1;
                          contieneMuestreo = false;
                          for (j = 0; j < tmpRodal[i].especies.length; j++) {
                            if (tmpRodal[i].especies[j].tcTipoInventario.tipoInventarioId == 2) {
                              tipoInventarioId = 2;
                              contieneMuestreo = true;
                              break;
                            }
                          }
                          if (contieneMuestreo) {
                            row = {
                              rodalGestionId: tmpRodal[i].rodalGestionId,
                              correlativo: tmpRodal[i].correlativo,
                              turno: tmpRodal[i].turno,
                              totalParcelas: tmpRodal[i].totalParcelas,
                              tamanioParcela: tmpRodal[i].tamanioParcela,
                              area: tmpRodal[i].area,
                              tipoInventarioId: tipoInventarioId,
                              parcela: []
                            }
                            tmpData = [];
                            for (j = 0; j < data.length; j++) {
                              if (tmpRodal[i].correlativo == data[j].RODAL) {
                                //buscar primero la parcela
                                if (data[j].TAMANIO_PARCELA != null) {
                                  parcela = {
                                    noParcela: data[j].PARCELA,
                                    volumen: 0,
                                    tamanioParcela: data[j].TAMANIO_PARCELA,
                                    arboles: 0,
                                    volumenCuadrado: 0,
                                    volumenHectaria: 0,
                                    volumenHectaria2: 0
                                  }
                                  encontrado = false;
                                  for (k = 0; k < row.parcela.length; k++) {
                                    if (row.parcela[k].noParcela == parcela.noParcela) {
                                      parcela = row.parcela[k];
                                      encontrado = true;
                                      break;
                                    }
                                  }
                                  valor = parseFloat(parcela.volumen) + parseFloat(data[j].VOLUMEN);
                                  parcela.volumen = parseFloat(valor).toFixed(2);
                                  parcela.arboles = parcela.arboles + 1;
                                  if (!encontrado) {
                                    row.parcela.push(parcela);
                                  }
                                }
                              } else {
                                tmpData.push(data[j]);
                              }
                            }
                            data = tmpData;
                            dataAnalisis.push(row);
                          }
                        }
                        var areaTotal = parseFloat($scope.dataTask.ttGestion.area);
                        var totalParcelas = 0, valorG = 0;
                        if ($scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3
                          || $scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) { //estratificado
                          /*obtener la proporcion del area por estrato*/
                          for (i = 0; i < dataAnalisis.length; i++) {
                            dataAnalisis[i].nhj = (parseFloat(dataAnalisis[i].area) * 10000) / parseFloat(dataAnalisis[i].tamanioParcela);
                            dataAnalisis[i].pj = parseFloat(dataAnalisis[i].area) / areaTotal;
                            totalParcelas += dataAnalisis[i].totalParcelas;
                          }
                          totalParcelas = totalParcelas - 1;
                          for (j = 0; j < $scope.dataTStudent.length; j++) {
                            if ($scope.dataTStudent[j].k == totalParcelas) {
                              valorG = parseFloat($scope.dataTStudent[j].value);
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            toastr.error("No hay un valor dentro de la tabla T-Student para un total de parcelas de: " + totalParcelas);
                            return;
                          }
                        }
                        //realizar calculos por rodal y parcela
                        var dataAnalisisRodal = [];
                        var parcelas = 0, arboles = 0, tamanioParcela = 0, totalH = 0, totalH2 = 0, total = 0, total2 = 0;
                        var desviacionEstandar, volh, volumenPromedio, coeficienteVariacion, errors, errorm, errorp, lcInferior, lcSuperior, vpEstrato;
                        var vXjxNj, vSjxPj, vSj2xPj;
                        var tmp = $scope.data;
                        for (i = 0; i < dataAnalisis.length; i++) {
                          parcelas = dataAnalisis[i].parcela.length;
                          arboles = 0;
                          tamanioParcela = 0;
                          total = 0;
                          total2 = 0;
                          totalH = 0;
                          totalH2 = 0;
                          for (j = 0; j < dataAnalisis[i].parcela.length; j++) {
                            valor = dataAnalisis[i].parcela[j].volumen * dataAnalisis[i].parcela[j].volumen;
                            dataAnalisis[i].parcela[j].volumenCuadrado = parseFloat(valor).toFixed(2);
                            valor = (dataAnalisis[i].parcela[j].volumen * 10000) / dataAnalisis[i].parcela[j].tamanioParcela;
                            dataAnalisis[i].parcela[j].volumenHectaria = parseFloat(valor).toFixed(2);
                            valor = parseFloat(dataAnalisis[i].parcela[j].volumenHectaria) * parseFloat(dataAnalisis[i].parcela[j].volumenHectaria);
                            dataAnalisis[i].parcela[j].volumenHectaria2 = parseFloat(valor).toFixed(2);
                            arboles += parseInt(dataAnalisis[i].parcela[j].arboles);
                            tamanioParcela = dataAnalisis[i].parcela[j].tamanioParcela;
                            total = total + parseFloat(dataAnalisis[i].parcela[j].volumen);
                            total2 = total2 + parseFloat(dataAnalisis[i].parcela[j].volumenCuadrado);
                            totalH = totalH + parseFloat(dataAnalisis[i].parcela[j].volumenHectaria);
                            totalH2 = totalH2 + parseFloat(dataAnalisis[i].parcela[j].volumenHectaria2);
                          }
                          dataAnalisis[i].arboles = arboles;
                          dataAnalisis[i].parcelas = parcelas;
                          dataAnalisis[i].tamanioParcela = tamanioParcela;
                          dataAnalisis[i].areaMuestreada = dataAnalisis[i].parcelas / tamanioParcela;
                          valor = (totalH * totalH) / parcelas;
                          valor = totalH2 - valor;
                          valor = valor / (parcelas - 1);
                          dataAnalisis[i].varianza = parseFloat(valor).toFixed(2);
                          //restar uno a parcelas para buscar el grado de libertad
                          parcelas = parcelas - 1;
                          dataAnalisis[i].valorG = 0;
                          encontrado = false;
                          for (j = 0; j < $scope.dataTStudent.length; j++) {
                            if ($scope.dataTStudent[j].k == parcelas) {
                              dataAnalisis[i].valorG = parseFloat($scope.dataTStudent[j].value);
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            toastr.error("No hay un valor dentro de la tabla T-Student para un total de parcelas de: " + parcelas);
                            return;
                          }
                          desviacionEstandar = Math.pow(dataAnalisis[i].varianza, 0.5);
                          volh = totalH / parseInt(dataAnalisis[i].parcelas);
                          volumenPromedio = total / parseInt(dataAnalisis[i].parcelas);
                          coeficienteVariacion = (desviacionEstandar / volh) * 100;
                          errors = dataAnalisis[i].varianza / dataAnalisis[i].parcelas;
                          errors = Math.pow(errors, 0.5);
                          errorm = errors * dataAnalisis[i].valorG;
                          errorp = errorm / volh;
                          //dejar lo valores con solo dos decimales
                          volh = parseFloat(volh).toFixed(2);
                          volumenPromedio = parseFloat(volumenPromedio).toFixed(2);
                          desviacionEstandar = parseFloat(desviacionEstandar).toFixed(2);
                          coeficienteVariacion = parseFloat(coeficienteVariacion).toFixed(2);
                          errors = parseFloat(errors).toFixed(2);
                          errorm = parseFloat(errorm).toFixed(2);
                          errorp = parseFloat(errorp) * 100;
                          lcInferior = parseFloat(volh) - parseFloat(errorm);
                          lcSuperior = parseFloat(volh) + parseFloat(errorm);
                          lcInferior = parseFloat(lcInferior).toFixed(2);
                          lcSuperior = parseFloat(lcSuperior).toFixed(2);
                          vXjxNj = null;
                          vSjxPj = null;
                          vSj2xPj = null;
                          dataAnalisis[i].nhj = parseFloat(dataAnalisis[i].nhj).toFixed(2);
                          dataAnalisis[i].pj = parseFloat(dataAnalisis[i].pj).toFixed(2);
                          vXjxNj = volh * dataAnalisis[i].nhj;
                          vSjxPj = desviacionEstandar * dataAnalisis[i].pj;
                          vSj2xPj = (desviacionEstandar * desviacionEstandar) * dataAnalisis[i].pj;
                          row = {
                            ttRodalGestion: {
                              ttGestion: {
                                gestionId: tmp.gestionId,
                                tcTipoGestion: tmp.tcTipoGestion,
                                estadoId: tmp.estadoId,
                                tcPersonaCrea: tmp.tcPersonaCrea,
                                tcTipoBosque: tmp.tcTipoBosque,
                                tcElaborador: tmp.tcElaborador,
                                ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                personaAnulaId: $scope.loginData.personaId
                              },
                              rodalGestionId: dataAnalisis[i].rodalGestionId,
                              area: dataAnalisis[i].area,
                              correlativo: dataAnalisis[i].correlativo
                            },
                            nParcelas: dataAnalisis[i].parcelas,
                            volh: volh,
                            vpEstrato: volumenPromedio,
                            desviacionEstandar: desviacionEstandar,
                            coeficienteVariacion: coeficienteVariacion,
                            errors: errors,
                            errorm: errorm,
                            errorp: parseFloat(errorp).toFixed(2),
                            lcInferior: lcInferior,
                            lcSuperior: lcSuperior,
                            nhj: parseFloat(dataAnalisis[i].nhj).toFixed(2),
                            pj: parseFloat(dataAnalisis[i].pj).toFixed(2),
                            vXjxNj: parseFloat(vXjxNj).toFixed(2),
                            vSjxPj: parseFloat(vSjxPj).toFixed(2),
                            vSj2xPj: parseFloat(vSj2xPj).toFixed(2),
                          }
                          dataAnalisisRodal.push(row);
                        } // fin calculos por rodal
                        var totales = {};
                        if ($scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3 || $scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) { //estratificado
                          totales = {
                            area: 0,
                            Xj: 0,
                            Sj: 0,
                            nj: 0,
                            nhj: 0,
                            pj: 0,
                            vXjxNj: 0,
                            vSjxPj: 0,
                            vSj2xPj: 0,
                            segunArea: 0,
                            segunVariabilidad: 0
                          };
                          for (i = 0; i < dataAnalisisRodal.length; i++) {
                            totales.area += parseFloat(dataAnalisisRodal[i].ttRodalGestion.area);
                            totales.Xj += parseFloat(dataAnalisisRodal[i].volh);
                            totales.Sj += parseFloat(dataAnalisisRodal[i].desviacionEstandar);
                            totales.nj += parseFloat(dataAnalisisRodal[i].nParcelas);
                            totales.nhj += parseFloat(dataAnalisisRodal[i].nhj);
                            totales.pj += parseFloat(dataAnalisisRodal[i].pj);
                            totales.vXjxNj += parseFloat(dataAnalisisRodal[i].vXjxNj);
                            totales.vSjxPj += parseFloat(dataAnalisisRodal[i].vSjxPj);
                            totales.vSj2xPj += parseFloat(dataAnalisisRodal[i].vSj2xPj);
                          }
                          totales.area = parseFloat(totales.area).toFixed(2);
                          totales.Xj = parseFloat(totales.Xj).toFixed(2);
                          totales.Sj = parseFloat(totales.Sj).toFixed(2);
                          totales.nj = parseFloat(totales.nj).toFixed(2);
                          totales.nhj = parseFloat(totales.nhj).toFixed(2);
                          totales.pj = parseFloat(totales.pj).toFixed(2);
                          totales.vXjxNj = parseFloat(totales.vXjxNj).toFixed(2);
                          totales.vSjxPj = parseFloat(totales.vSjxPj).toFixed(2);
                          totales.vSj2xPj = parseFloat(totales.vSj2xPj).toFixed(2);
                          //calcular los datos del analisis estadistico estratificados
                          var analisisEstratificado = {
                            vpEstrato: null,
                            desviacionEstandar: null,
                            errors: null,
                            errorm: null,
                            errorp: null,
                            lcSuperior: null,
                            lcInferior: null,
                            nParcelas: null
                          }
                          var value = parseFloat(totales.vXjxNj) / parseFloat(totales.nhj);
                          analisisEstratificado.vpEstrato = parseFloat(value).toFixed(2);
                          value = (parseFloat(totales.vSjxPj) / parseFloat(analisisEstratificado.vpEstrato)) * 100;
                          analisisEstratificado.desviacionEstandar = parseFloat(value).toFixed(2);
                          value = (parseFloat(totales.vSjxPj) * parseFloat(totales.vSjxPj)) / parseFloat(totales.nj);
                          value = value - (parseFloat(totales.vSj2xPj) / parseFloat(totales.nhj));
                          value = Math.pow(value, 0.5);
                          analisisEstratificado.errors = parseFloat(value).toFixed(2);
                          value = parseFloat(analisisEstratificado.errors) * valorG;
                          analisisEstratificado.errorm = parseFloat(value).toFixed(2);
                          value = (parseFloat(analisisEstratificado.errorm) / parseFloat(analisisEstratificado.vpEstrato)) * 100;
                          analisisEstratificado.errorp = parseFloat(value).toFixed(2);
                          value = parseFloat(analisisEstratificado.vpEstrato) + parseFloat(analisisEstratificado.errorm);
                          analisisEstratificado.lcSuperior = parseFloat(value).toFixed(2);
                          value = analisisEstratificado.vpEstrato - analisisEstratificado.errorm;
                          analisisEstratificado.lcInferior = parseFloat(value).toFixed(2);
                          value = parseFloat(analisisEstratificado.vpEstrato) * (parseFloat($scope.precisionId) / 100);
                          value = value * value;
                          var tmp = valorG * valorG;
                          var tmp2 = tmp;
                          tmp = tmp * parseFloat(totales.vSj2xPj);
                          value = tmp / value;
                          tmp = parseFloat(totales.vSj2xPj) / parseFloat(totales.vXjxNj);
                          tmp = tmp2 * tmp;
                          value = value + tmp;
                          analisisEstratificado.nParcelas = Math.round(value);
                          for (i = 0; i < dataAnalisisRodal.length; i++) {
                            value = dataAnalisisRodal[i].pj * analisisEstratificado.nParcelas;
                            value = Math.round(value);
                            totales.segunArea += parseInt(value);
                            dataAnalisisRodal[i].segunArea = value;
                            value = parseFloat(dataAnalisisRodal[i].vSjxPj) / totales.vSjxPj;
                            value = analisisEstratificado.nParcelas * value;
                            value = Math.round(value);
                            dataAnalisisRodal[i].segunVariabilidad = value;
                            totales.segunVariabilidad += parseInt(value);
                          }
                        } else {
                          totales = {
                            area: 0,
                            nParcelas: 0,
                            volh: 0,
                            vpEstrato: 0,
                            desviacionEstandar: 0,
                            coeficienteVariacion: 0,
                            errors: 0,
                            errorm: 0,
                            errorp: 0,
                            lcInferior: 0,
                            lcSuperior: 0
                          }
                          for (i = 0; i < dataAnalisisRodal.length; i++) {
                            totales.area += parseFloat(dataAnalisisRodal[i].ttRodalGestion.area);
                            totales.nParcelas += parseInt(dataAnalisisRodal[i].nParcelas);
                            totales.volh += parseFloat(dataAnalisisRodal[i].volh);
                            totales.vpEstrato += parseFloat(dataAnalisisRodal[i].vpEstrato);
                            totales.desviacionEstandar += parseFloat(dataAnalisisRodal[i].desviacionEstandar);
                            totales.coeficienteVariacion += parseFloat(dataAnalisisRodal[i].coeficienteVariacion);
                            totales.errors += parseFloat(dataAnalisisRodal[i].errors);
                            totales.errorm += parseFloat(dataAnalisisRodal[i].errorm);
                            totales.errorp += parseFloat(dataAnalisisRodal[i].errorp);
                            totales.lcInferior += parseFloat(dataAnalisisRodal[i].lcInferior);
                            totales.lcSuperior += parseFloat(dataAnalisisRodal[i].lcSuperior);
                          }
                          totales.area = parseFloat(totales.area).toFixed(2);
                          totales.nParcelas = parseInt(totales.nParcelas);
                          totales.volh = parseFloat(totales.volh).toFixed(2);
                          totales.vpEstrato = parseFloat(totales.vpEstrato).toFixed(2);
                          totales.desviacionEstandar = parseFloat(totales.desviacionEstandar).toFixed(2);
                          totales.coeficienteVariacion = parseFloat(totales.coeficienteVariacion).toFixed(2);
                          totales.errors = parseFloat(totales.errors).toFixed(2);
                          totales.errorm = parseFloat(totales.errorm).toFixed(2);
                          totales.errorp = parseFloat(totales.errorp).toFixed(2);
                          totales.lcInferior = parseFloat(totales.lcInferior).toFixed(2);
                          totales.lcSuperior = parseFloat(totales.lcSuperior).toFixed(2);
                        }
                        if ($scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 3 || $scope.dataTask.ttGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 4) {
                          //agregar los resumenes a la data a almacenar
                          var ttEstadisticoGestion = {
                            area: totales.area,
                            nhj: totales.nhj,
                            pj: totales.pj,
                            Sj: totales.Sj,
                            Xj: totales.Xj,
                            nj: totales.nj,
                            vSj2xPj: totales.vSj2xPj,
                            vSjxPj: totales.vSjxPj,
                            vXjxNj: totales.vXjxNj,
                            segunArea: totales.segunArea,
                            segunVariabilidad: totales.segunVariabilidad,
                            errorm: analisisEstratificado.errorm,
                            errorp: analisisEstratificado.errorp,
                            errors: analisisEstratificado.errors,
                            lcInferior: analisisEstratificado.lcInferior,
                            lcSuperior: analisisEstratificado.lcSuperior,
                            nParcelas: analisisEstratificado.nParcelas,
                            desviacionEstandar: analisisEstratificado.desviacionEstandar,
                            vpEstrato: analisisEstratificado.vpEstrato,
                            precisionId: $scope.precisionId
                          }
                          $scope.data.ttEstadisticoGestion = ttEstadisticoGestion;
                          for (i = 0; i < dataAnalisis.length; i++) {
                            for (j = 0; j < dataAnalisisRodal.length; j++) {
                              if (dataAnalisis[i].rodalGestionId == dataAnalisisRodal[j].ttRodalGestion.rodalGestionId) {
                                dataAnalisis[i].ttEstadisticoRodal = dataAnalisisRodal[j];
                                break;
                              }
                            }
                          }
                        } else {
                          var ttEstadisticoGestion = {
                            area: totales.area,
                            coeficienteVariacion: totales.coeficienteVariacion,
                            errorm: totales.errorm,
                            errorp: totales.errorp,
                            errors: totales.errors,
                            lcInferior: totales.lcInferior,
                            lcSuperior: totales.lcSuperior,
                            nParcelas: totales.nParcelas,
                            vpEstrato: totales.vpEstrato
                          }
                          $scope.data.ttEstadisticoGestion = ttEstadisticoGestion;
                          for (i = 0; i < dataAnalisisRodal.length; i++) {
                            dataAnalisisRodal[i].nhj = 0;
                            dataAnalisisRodal[i].pj = 0;
                            dataAnalisisRodal[i].vSjxPj = 0;
                            dataAnalisisRodal[i].vSj2xPj = 0;
                            dataAnalisisRodal[i].vXjxNj = 0;
                          }
                          for (i = 0; i < dataAnalisis.length; i++) {
                            for (j = 0; j < dataAnalisisRodal.length; j++) {
                              if (dataAnalisis[i].rodalGestionId == dataAnalisisRodal[j].ttRodalGestion.rodalGestionId) {
                                dataAnalisis[i].ttEstadisticoRodal = dataAnalisisRodal[j];
                                break;
                              }
                            }
                          }
                        }
                        $scope.data.dataAnalisis = dataAnalisis;
                      }
                      //abrir ventana para visualizar los resultados
                      ngDialog.open({
                        template: 'app/tarea/analisis.estadistico.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueAnalisis',
                        name: 'dialogAnalisis',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {

                            $scope.submitFormData = function (isValid) {
                              //poner los valores de error en la tabla de resumen de evaluación
                              var data = $scope.dataTask.ttGestion.rodal;
                              if ($scope.dataGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 1 || $scope.dataGestion.ttInventarioGestion.tcTipoMuestreo.tipoMuestreoId == 2) {
                                var dataAnalisis = $scope.data.dataAnalisis;
                                for (var i = 0; i < data.length; i++) {
                                  for (var j = 0; j < dataAnalisis.length; j++) {
                                    if (dataAnalisis[j].correlativo == data[i].correlativo) {
                                      if (data[i].ttEvaluacionCampo == null) {
                                        data[i].ttEvaluacionCampo = {};
                                      }
                                      data[i].ttEvaluacionCampo.errorpv = dataAnalisis[j].ttEstadisticoRodal.errorp;
                                      break;
                                    }
                                  }
                                }
                              } else {
                                for (var i = 0; i < data.length; i++) {
                                  data[i].ttEvaluacionCampo.errorpv = $scope.dataGestion.ttEstadisticoGestion.errorp;
                                }
                              }
                              for (var i = 0; i < data.length; i++) {
                                if (data[i].ttEvaluacionCampo.errorpv == null) {
                                  data[i].ttEvaluacionCampo.errorpv = 0;
                                }
                              }
                              ngDialog.close();
                            } //fin submitFormData

                          }]
                      }) // fin ventana modal
                    }

                    //ventana para enmiendas
                    $scope.generarEnmiendaTecnica = function () {
                      $scope.classNoAprobar = "btn btn-default";
                      $scope.classAprobar = "btn btn-default";
                      $scope.mostrar = false;
                      $scope.mostrarInfo = false;
                      $scope.classEnmienda = "btn btn-warning";
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista las enmiendas para el informe técnico";
                            $scope.habilitarCambioArea = true;
                            $scope.obligar = true;
                            $scope.mostrarOficio = true;
                            $scope.codigo = null;
                            $scope.approved = false;

                            $scope.setAprobar = function () {
                              if ($scope.approved) {
                                $scope.dataTask.modificarArea = 1;
                              } else {
                                $scope.dataTask.modificarArea = 0;
                              }
                            }

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              $scope.enmienda.push({ descripcion: null });
                            }

                            $scope.borrarEnmienda = function (item) {
                              $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              var data = angular.copy($scope.dataTask);
                              data.ttSeguimientoTarea.enmienda = $scope.enmienda;
                              data.ttSeguimientoTarea.codigo = $scope.codigo;
                              data.ttSeguimientoTarea.fechaProvidencia = new Date();
                              utilsService.generarEnmiendaTecnica(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa, $scope.loginData.sigla);
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                if ($scope.codigo == null) {
                                  toastr.error('Debe indicar el oficio');
                                  return;
                                }
                                if ($scope.codigo.length <= 0) {
                                  toastr.error('Debe indicar el oficio');
                                  return;
                                }
                                $scope.dataTask.ttSeguimientoTarea.enmienda = $scope.enmienda;
                                $scope.dataTask.ttSeguimientoTarea.codigo = $scope.codigo;
                                $scope.enviarTareaTecnico(true);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.enmienda', {
            url: '/enmienda',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.enmienda.tpl.html',
                resolve: {
                  dataEnmienda: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      var tareaPadreId = authService.getLocalData('tareaPadreId');
                      return tareaService.getDataNotificacionEnmienda($base64.decode($stateParams.tareaId), tareaPadreId);
                    }],
                  dataTarea: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.get($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataEnmienda', '$filter', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataEnmienda, $filter, dataTarea) {
                    $scope.textoAccion = 'El siguiente paso es notificar al solicitante las enmiendas. ¿Quiere realizarlo ahora? Presione guardar, de lo contrario puede cancelar y regresar a la lista de tareas';
                    $scope.textoOficio = 'No. Oficio';
                    $scope.mostrarOficio = true;
                    $scope.dataJuridico = [];
                    $scope.dataTecnico = [];
                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    $scope.data = {
                      fundamento: [],
                      aprobado: 2,
                      enmienda: []
                    }

                    $scope.providenciarExpediente = function () {
                      if ($scope.dataTask.tcTask.taskId == 11) {
                        toastr.error('Opción no válida para este tipo de tarea');
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿La tarea es notificar enmiendas, está seguro (a) que lo que quiere es asignar de nuevo el expediente y no notificar las enmiendas? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            if ($scope.mostrarOficio) {
                              $scope.mostrarProvidencia = true;
                              $scope.mostrarOficio = false;
                              $scope.textoAccion = 'El siguiente paso es providenciar expediente para ratificar información';
                              $scope.textoOficio = 'No. Providencia';
                            } else {
                              $scope.mostrarProvidencia = false;
                              $scope.mostrarOficio = true;
                              $scope.textoAccion = 'El siguiente paso es notificar al solicitante las enmiendas. ¿Quiere realizarlo ahora? Presione guardar, de lo contrario puede cancelar y regresar a la lista de tareas';
                              $scope.textoOficio = 'No. Oficio';
                              $scope.dataTask.tcPersonaJuridico = null;
                              $scope.dataTask.tcPersonaTraslado = null;
                            }
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 6).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataJuridico = res.data;
                                } else {
                                  $scope.dataJuridico = [];
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                $scope.dataJuridico = [];
                                toastr.error(error);
                              });
                            }
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  $scope.dataTecnico = [];
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                $scope.dataTecnico = [];
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    //taskId: 3 = juridico, 4 = tecnico, 14 = monitoreo

                    var data = dataEnmienda.data;
                    var codigo = null;
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].ttSeguimientoTarea.codigo == null) {
                        codigo = data[i].ttSeguimientoTarea.numeroResolucion;
                      } else {
                        if (data[i].tcTask.taskId == 3) {
                          codigo = data[i].ttSeguimientoTarea.numeroResolucion + '/' + data[i].ttSeguimientoTarea.codigo;
                        } else {
                          codigo = data[i].ttSeguimientoTarea.codigo;
                        }
                      }
                      var row = {
                        codigo: codigo,
                        taskId: data[i].tcTask.taskId,
                        tareaId: data[i].tareaId,
                        personaId: data[i].tcPersonaAsignada.personaId,
                        personaDesc: data[i].tcPersonaAsignada.personaDesc,
                        enmienda: []
                      };
                      if (data[i].ttSeguimientoTarea.enmienda == null) {
                        if (data[i].tcTask.taskId == 14) {
                          enmienda = JSON.parse(data[i].ttSeguimientoTarea.fundamento);
                          if (!enmienda.aprobado) {
                            if (!enmienda.solicitante) {
                              var item = {};
                              item.descripcion = "El historial del solicitante no es favorable";
                              row.enmienda.push(item);
                            }
                            if (!enmienda.regente) {
                              var item = {};
                              item.descripcion = "El historial del regente propuesto no es favorable";
                              row.enmienda.push(item);
                            }
                            if (!enmienda.fiador) {
                              var item = {};
                              item.descripcion = "El historial del fiador no es favorable";
                              row.enmienda.push(item);
                            }
                            if (!enmienda.patrimonio) {
                              var item = {};
                              item.descripcion = "El estado patrimonial del fiador no cubre el monto de la garantía";
                              row.enmienda.push(item);
                            }
                          }
                        }
                      } else {
                        enmienda = JSON.parse(data[i].ttSeguimientoTarea.enmienda);
                        for (var j = 0; j < enmienda.length; j++) {
                          var item = {
                            descripcion: enmienda[j].descripcion
                          };
                          row.enmienda.push(item);
                        }
                      }
                      $scope.data.enmienda.push(row);
                    }

                    $scope.agregarFundamento = function () {
                      $scope.data.fundamento.push({ descripcion: null });
                    }

                    $scope.borrarDetalle = function (item) {
                      $scope.data.fundamento.splice($scope.data.fundamento.indexOf(item), 1);
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaJuridico = item;
                    }

                    $scope.vistaPrevia = function (fin) {
                      if (fin == null) {
                        fin = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error('Debe indicar el número de oficio');
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      var enmienda = angular.copy($scope.data.enmienda);
                      var fundamento = angular.copy($scope.data.fundamento);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      data.aprobado = $scope.data.aprobado;
                      if ($scope.mostrarOficio) {
                        enmienda.push(
                          {
                            codigo: null,
                            enmienda: fundamento
                          }
                        );
                        data.ttSeguimientoTarea.enmienda = enmienda;
                        data.ttSeguimientoTarea.observaciones = null;
                        utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                        var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        data.ttSeguimientoTarea.providencia = data.ttSeguimientoTarea.codigo;
                        data.ttSeguimientoTarea.codigo = null;
                        if (fundamento.length > 0) {
                          data.ttSeguimientoTarea.observaciones = fundamento;
                        }
                        utilsService.generarProvidenciaRatificacion(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (fin) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isAmendment, tmpData) {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        toastr.error(texto);
                        return;
                      } else {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error(texto);
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var enmienda = angular.copy($scope.data.enmienda);
                            var fundamento = angular.copy($scope.data.fundamento);
                            enmienda.push(
                              {
                                codigo: null,
                                enmienda: fundamento
                              }
                            );
                            var ttGestion = data.ttGestion;
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = $scope.data.aprobado;
                            data.tcPersonaAsignada.foto = null;
                            data.tcPersonaMonitoreo = null;
                            if ($scope.mostrarOficio) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(enmienda);
                            } else {
                              if (data.tcPersonaTraslado != null) {
                                data.tcPersonaTraslado.foto = null;
                              }
                              if (data.tcPersonaJuridico != null) {
                                data.tcPersonaJuridico.foto = null;
                              }
                              data.ttSeguimientoTarea.observaciones = JSON.stringify(fundamento);
                            }
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if ($scope.mostrarOficio) {
                              if (data.tcTask.taskId == 11) {
                                tareaService.setEnmiendaSiguienteTurno(data).then(function (res) {
                                  if (res.status == 1) {
                                    data.ttSeguimientoTarea.enmienda = enmienda;
                                    data.ttGestion = ttGestion;
                                    utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                    toastr.success(res.message);
                                    $state.go('index.tarea.list');
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                gestionService.get(data.ttGestion.gestionId).then(function (resp) {
                                  if (resp.status == "OK") {
                                    data.ttGestion.cronograma = JSON.stringify(resp.data[0]);
                                    //enmienda con oficios
                                    tareaService.setNotificacionEnmienda(data).then(function (res) {
                                      if (res.status == "OK") {
                                        data.ttSeguimientoTarea.enmienda = enmienda;
                                        data.ttGestion = ttGestion;
                                        utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                        toastr.success(res.message);
                                        $state.go('index.tarea.list');
                                      } else {
                                        toastr.error(res.message);
                                      }
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            } else {
                              tareaService.asignarProvidenciaRatificacion(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success("La asignación se ha efectuado con éxito");
                                  $scope.vistaPrevia(true);
                                } else {
                                  toastr.error("Error al realizar la asignación " + res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.analizar', {
            url: '/dicsubregional',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.dictamen.tpl.html',
                resolve: {
                  dataRegional: ['usuariosubregionService', '$stateParams', 'authService',
                    function (usuariosubregionService, $stateParams, authService) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return usuariosubregionService.getRegional(loginData.subregionId);
                    }],
                  dataTarea: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getGestionParaTarea($base64.decode($stateParams.tareaId));
                    }],
                  dataDictamen: ['tareaService', '$stateParams', 'authService',
                    function (tareaService, $stateParams, authService) {
                      var gestionId = authService.getLocalData('gestionId');
                      return tareaService.getGestionDictamen(gestionId);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter',
                  'dataRegional', 'dataTarea', 'dataDictamen', 'montogarantiaService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter,
                    dataRegional, dataTarea, dataDictamen, montogarantiaService) {

                    $scope.dataRegional = dataRegional.data[0];
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataDictamen = dataDictamen.data;
                    $scope.mostrarAdvertencia = false;
                    $scope.mostrarProvidencia = false;
                    $scope.mostrarDictamen = true;
                    $scope.textoAccion = 'Realizar dictamen subregional';
                    $scope.textoNumero = 'No. de dictamen';
                    $scope.textoEnmienda = 'Liste las enmiendas que desea solicitar';
                    var textoAdvertencia = '';
                    var filas = $scope.dataDictamen.length;
                    for (var i = 0; i < filas; i++) {
                      var row = $scope.dataDictamen[i];
                      if (row.aprobado == 0 && row.tcTask.taskId != 1) {
                        $scope.mostrarAdvertencia = true;
                        switch (parseInt(row.tcTask.taskId)) {
                          case 3:
                            if (textoAdvertencia.length > 0) {
                              textoAdvertencia += ' y el dictamen jurídico no fue aprobado';
                            } else {
                              textoAdvertencia = 'El dictamen jurídico no fue aprobado';
                            }
                            break;
                          case 4:
                            if (textoAdvertencia.length > 0) {
                              textoAdvertencia += ' y el dictamen técnico no fue aprobado';
                            } else {
                              textoAdvertencia = 'El dictamen técnico no fue aprobado';
                            }
                            break;
                          case 14:
                            if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
                              || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7) {
                              if (textoAdvertencia.length > 0) {
                                textoAdvertencia += ' y la garantía fiduciaria no fue AVALADA debe solicitar el cambio de datos relacionados o cambio de garantía como enmienda';
                              } else {
                                textoAdvertencia = 'La garantía fiduciaria no fue AVALADA debe solicitar el cambio de datos relacionados o cambio de garantía como enmienda';
                              }
                            }
                            break;
                        }
                        $scope.textoAdvertencia = textoAdvertencia;
                      }
                    }
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.textoNumero = 'No. de dictamen';

                    $scope.setAprobar = function () {
                      if ($scope.data.approved) {
                        $scope.data.aprobado = 1;
                      } else {
                        $scope.data.aprobado = 0;
                      }
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.textoNumero = 'No. de oficio';
                        $scope.textoAccion = 'Realizar oficio de enmiendas (estas enmiendas no podrán ser trasladadas a técnico o jurídico)';
                        $scope.data.aprobado = 2;
                        $scope.textoEnmienda = 'Liste las enmiendas que desea solicitar';
                      } else {
                        $scope.textoNumero = 'No. de dictamen';
                        $scope.textoAccion = 'Realizar dictamen subregional';
                        if ($scope.data.approved) {
                          $scope.data.aprobado = 1;
                        } else {
                          $scope.data.aprobado = 0;
                        }
                      }
                    }

                    $scope.agregarEnmienda = function () {
                      $scope.data.enmienda.push({ descripcion: null });
                    }

                    $scope.borrarEnmienda = function (item) {
                      $scope.data.enmienda.splice($scope.data.enmienda.indexOf(item), 1);
                    }

                    $scope.data = {
                      enmienda: [],
                      fundamento: {},
                      aprobado: 1,
                      approved: true,
                      amendment: false
                    }

                    $scope.dataJuridico = [];
                    $scope.dataTecnico = [];

                    $scope.providenciarExpediente = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿La tarea es realizar el dictamen subregional, está seguro (a) que lo que quiere es asignar de nuevo el expediente? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.mostrarProvidencia = true;
                            $scope.mostrarDictamen = false;
                            $scope.textoAccion = 'El siguiente paso es providenciar expediente para ratificar información';
                            $scope.textoNumero = 'No. Providencia';
                            $scope.textoEnmienda = 'Liste las razones para la ratificación del expediente';
                            $scope.data.amendment = true;
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 6).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataJuridico = res.data;
                                } else {
                                  $scope.dataJuridico = [];
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                $scope.dataJuridico = [];
                                toastr.error(error);
                              });
                            }
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  $scope.dataTecnico = [];
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                $scope.dataTecnico = [];
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setAnioInicio = function () {
                      var monto;
                      var areaCompromiso = 0;
                      var rodales = $scope.dataTask.ttGestion.rodal;
                      for (var i = 0; i < rodales.length; i++) {
                        var rodal = rodales[i];
                        if (rodal.turno == 1) {
                          areaCompromiso += parseFloat(rodal.areaGarantia);
                        }
                      }
                      var dataMontoVigencia = [];
                      for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                        monto = 0;
                        monto = areaCompromiso * parseFloat($scope.dataMontoGarantia[i].monto);
                        monto = parseFloat(monto).toFixed(2);
                        item = {
                          etapaDesc: $scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoDesc,
                          monto: monto,
                          vigencia: null
                        }
                        dataMontoVigencia.push(item);
                      }
                      var inicio = new Date($scope.anio, 9, 31);
                      var fin = inicio;
                      for (i = 0; i < dataMontoVigencia.length; i++) {
                        if (i == 0) {
                          dataMontoVigencia[i].vigencia = "Hasta " + $filter('date')(new Date(inicio), 'dd/MM/yyyy');
                        } else {
                          inicio = $scope.addDays(inicio, 1);
                          fin.setFullYear(fin.getFullYear() + 1);
                          dataMontoVigencia[i].vigencia = "Del " + $filter('date')(new Date(inicio), 'dd/MM/yyyy') + " al " + $filter('date')(new Date(fin), 'dd/MM/yyyy');
                          inicio = fin;
                        }
                      }
                      $scope.dataTask.observaciones = dataMontoVigencia;
                    }

                    for (var i = 0; i < $scope.dataDictamen.length; i++) {
                      var tarea = $scope.dataDictamen[i];
                      switch (tarea.tcTask.taskId) {
                        case 1:
                          $scope.data.fundamento.fechaRecepcion = tarea.fechaRegistro;
                          break;
                        case 3:
                          $scope.data.fundamento.juridico = {
                            fechaFinalizacion: tarea.fechaFinalizacion,
                            personaDesc: tarea.tcPersonaAsignada.personaDesc,
                            codigo: tarea.ttSeguimientoTarea.numeroResolucion + (tarea.ttSeguimientoTarea.codigo == null ? '' : '/' + tarea.ttSeguimientoTarea.codigo),
                            aprobado: tarea.aprobado
                          };
                          break;
                        case 4:
                          $scope.data.fundamento.tecnico = {
                            fechaFinalizacion: tarea.fechaFinalizacion,
                            personaDesc: tarea.tcPersonaAsignada.personaDesc,
                            codigo: tarea.ttSeguimientoTarea.numeroResolucion,
                            aprobado: tarea.aprobado
                          };
                          if (tarea.observaciones != null) {
                            try {
                              $scope.dataTask.observaciones = JSON.parse(tarea.observaciones);
                              $scope.anio = tarea.ttSeguimientoTarea.numero;
                            } catch (e) { }
                          }
                          break;
                        case 14:
                          $scope.data.fundamento.monitoreo = {
                            fechaFinalizacion: tarea.fechaFinalizacion,
                            personaDesc: tarea.tcPersonaAsignada.personaDesc,
                            codigo: tarea.ttSeguimientoTarea.numeroResolucion,
                            aprobado: tarea.aprobado
                          };
                          break;
                      }
                    }
                    var tipoGarantiaId = 1;
                    if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia != null && $scope.dataTask.ttGestion.ttPlanificacionGestion.hayReforestacion == 1) {
                      montogarantiaService.listaByTipoGarantia($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataMontoGarantia = res.data;
                          for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                            if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                              if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                                $scope.data.fundamento.montoUtilizar = $scope.dataMontoGarantia[i].monto;
                                $scope.setAnioInicio();
                                break;
                              }
                            }
                          }
                        } else {
                          toastr.error('Error al obtener datos de garantia: ' + res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    } else {
                      if ($scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 2
                        || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 3
                        || $scope.dataTask.ttGestion.tcTipoGestion.tipoGestionId == 8) {
                        $scope.data.fundamento.montoUtilizar = 0;
                      }
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaJuridico = item;
                    }

                    $scope.vistaPrevia = function (esPrevia) {
                      if (esPrevia == null) {
                        esPrevia = true;
                      }
                      if ($scope.data.numero == null || $scope.data.numero.length <= 0) {
                        toastr.error('Debe indicar el numero de dictamen');
                        return;
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea = {};
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if ($scope.mostrarProvidencia) {
                        var fundamento = angular.copy($scope.data.enmienda);
                        if (fundamento.length <= 0) {
                          swal('Debe indicar las razones de la providencia');
                          return;
                        }
                        var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                        var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        data.ttSeguimientoTarea.providencia = $scope.data.numero;
                        data.ttSeguimientoTarea.codigo = null;
                        if (fundamento.length > 0) {
                          data.ttSeguimientoTarea.observaciones = fundamento;
                        }
                        utilsService.setEsPrevia(esPrevia);
                        utilsService.generarProvidenciaRatificacion(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        if ($scope.data.amendment) {
                          data.ttSeguimientoTarea.codigo = $scope.data.numero;
                          data.aprobado = 2;
                          data.esEnmienda = 1;
                          data.ttSeguimientoTarea.enmienda = $scope.data.enmienda;
                        } else {
                          data.ttSeguimientoTarea.numeroResolucion = $scope.data.numero;
                          data.aprobado = $scope.data.aprobado;
                          data.esEnmienda = 0;
                          data.ttSeguimientoTarea.fundamento = $scope.data.fundamento;
                        }
                        data.tcPersonaTraslado = $scope.dataRegional.tcPersona;
                        var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                        var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        if ($scope.data.amendment) {
                          utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          utilsService.generarDictamenSubregional(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                      }
                    }

                    $scope.confirmarEnvioTarea = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var enmienda = angular.copy($scope.data.enmienda);
                            var fundamento = angular.copy($scope.data.fundamento);
                            var ttGestion = data.ttGestion;
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            if (data.ttSeguimientoTarea == null) {
                              data.ttSeguimientoTarea = {};
                            }
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = $scope.data.aprobado;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            data.observaciones = JSON.stringify(data.observaciones);
                            if ($scope.mostrarProvidencia) {
                              var observaciones = angular.copy($scope.data.enmienda);
                              data.aprobado = 1;
                              data.ttSeguimientoTarea.observaciones = JSON.stringify(observaciones);
                              data.ttSeguimientoTarea.providencia = $scope.data.numero;
                              tareaService.asignarProvidenciaRatificacion(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success("La asignación se ha efectuado con éxito");
                                  $scope.vistaPrevia(false);
                                  $state.go('index.tarea.list');
                                } else {
                                  toastr.error("Error al realizar la asignación " + res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              if ($scope.data.amendment) {
                                data.ttSeguimientoTarea.codigo = $scope.data.numero;
                                data.aprobado = 2;
                                data.esEnmienda = 1;
                                data.ttSeguimientoTarea.enmienda = JSON.stringify(enmienda);
                                gestionService.get(data.ttGestion.gestionId).then(function (resp) {
                                  if (resp.status == "OK") {
                                    data.ttGestion.cronograma = JSON.stringify(resp.data[0]);
                                    //enmienda solo subregional
                                    tareaService.setNotificacionEnmienda(data).then(function (res) {
                                      if (res.status == "OK") {
                                        data.ttSeguimientoTarea.enmienda = enmienda;
                                        data.ttGestion = ttGestion;
                                        utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                        toastr.success(res.message);
                                        $state.go('index.tarea.list');
                                      } else {
                                        toastr.error(res.message);
                                      }
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                data.ttSeguimientoTarea.numeroResolucion = $scope.data.numero;
                                data.aprobado = $scope.data.aprobado;
                                data.esEnmienda = 0;
                                data.ttSeguimientoTarea.fundamento = JSON.stringify(fundamento);
                                tareaService.setDictamenSubregional(data).then(function (res) {
                                  if (res.status == "OK") {
                                    data.ttSeguimientoTarea.fundamento = fundamento;
                                    data.ttGestion = ttGestion;
                                    data.tcPersonaTraslado = $scope.dataRegional.tcPersona;
                                    var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                    var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                    var strDia = $scope.numeroALetras(dia);
                                    var strAnio = $scope.numeroALetras(anio);
                                    data.observaciones = JSON.parse(data.observaciones);
                                    utilsService.generarDictamenSubregional(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                    toastr.success(res.message);
                                    $state.go('index.tarea.list');
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }
                          }
                        });
                    }

                    $scope.enviarTarea = function () {
                      if ($scope.data.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.data.numero == null) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      if ($scope.data.numero.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      if ($scope.mostrarProvidencia) {
                        var observaciones = angular.copy($scope.data.enmienda);
                        if (observaciones.length <= 0) {
                          swal('Debe indicar las razones de la providencia');
                          return;
                        }
                        if ($scope.dataTask.tcPersonaTraslado == null && $scope.dataTask.tcPersonaJuridico == null) {
                          swal('Debe seleccionar la o las personas a providenciar');
                          return;
                        }
                        $scope.confirmarEnvioTarea();
                      } else {
                        if ($scope.data.aprobado == 1) {
                          ngDialog.open({
                            template: 'app/tarea/requisito.dictamen.tpl.html',
                            className: 'ngdialog-theme-flat',
                            appendClassName: 'ngdialog-flat',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniqueRequisito',
                            name: 'uniqueRequisito',
                            resolve: {
                            },
                            controller: ['$scope', '$state', 'toastr',
                              function ($scope, $state, toastr) {
                                $scope.dataRequisito = [
                                  { requisitoDesc: 'Dictamen Jurídico (No. Dictamen, texto, etc. correctamente)', class: 'btn-default btn-xs fa fa-square-o', marcado: 0 },
                                  { requisitoDesc: 'Dictamen Técnico (No. Dictamen, texto, etc. correctamente)', class: 'btn-default btn-xs fa fa-square-o', marcado: 0 },
                                  { requisitoDesc: 'Calculo del valor de la madera', class: 'btn-default btn-xs fa fa-square-o', marcado: 0 },
                                  { requisitoDesc: 'Solicitud de AVAL de garantía fiduciaria (Si aplica)', class: 'btn-default btn-xs fa fa-square-o', marcado: 0 },
                                  { requisitoDesc: 'El expediente está completo para trasladar a la región', class: 'btn-default btn-xs fa fa-square-o', marcado: 0 }
                                ];

                                $scope.marcarRequisito = function (item) {
                                  if (item.marcado == 0) {
                                    item.marcado = 1;
                                    item.class = 'btn-success btn-xs fa fa-check-square-o';
                                  } else {
                                    item.marcado = 0;
                                    item.class = 'btn-default btn-xs fa fa-square-o';
                                  }
                                }

                                $scope.todo = false;

                                $scope.seleccionarTodo = function () {
                                  var check = 0;
                                  var tmpClass = 'btn-default btn-xs fa fa-square-o';
                                  if ($scope.todo) {
                                    check = 1;
                                    tmpClass = 'btn-success btn-xs fa fa-check-square-o';
                                  }
                                  for (var i = 0; i < $scope.dataRequisito.length; i++) {
                                    $scope.dataRequisito[i].marcado = check;
                                    $scope.dataRequisito[i].class = tmpClass;
                                  }
                                }

                                $scope.aceptarRequisito = function () {
                                  for (var i = 0; i < $scope.dataRequisito.length; i++) {
                                    if ($scope.dataRequisito[i].marcado == 0) {
                                      toastr.error('Debe marcar todas las casillas');
                                      return;
                                    }
                                  }
                                  $scope.confirmarEnvioTarea();
                                }

                              }]
                          }) // fin ventana requisitos
                        } else {
                          $scope.confirmarEnvioTarea();
                        }
                      }
                    }

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                      $scope.classEnmienda = "btn btn-default";
                    }

                    //ventana para enmiendas
                    $scope.generarEnmienda = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      $scope.classAprobar = "btn btn-default";
                      $scope.classNoAprobar = "btn btn-default";
                      $scope.data.aprobado = null;
                      $scope.mostrarLista = false;
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista de enmiendas regionales";

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              $scope.enmienda.push({ descripcion: null });
                            }

                            $scope.borrarEnmienda = function (item) {
                              $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              tareaService.getDataOpinionRegional($scope.dataTask.tareaId).then(function (res) {
                                if (res.status == "OK") {
                                  if (res.data.codigo == null) {
                                    res.data.fechaProvidencia = new Date();
                                    res.data.codigo = "XX-XX-XXXX-XXX-XXXX";
                                    res.data.fundamento = $scope.enmienda;
                                    res.data.aprobado = 2;
                                  } else {
                                    try {
                                      res.data.fundamento = JSON.parse(res.data.fundamento);
                                    } catch (e) {

                                    }
                                  }
                                  utilsService.generarEnmiendaSubregional(res.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                $scope.enviarTarea(true, $scope.enmienda);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.region', {
            url: '/dicregional',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.regional.tpl.html',
                resolve: {
                  dataTarea: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getGestionParaTareaConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  '$filter', '$timeout', 'dataTarea', 'montogarantiaService', 'Upload',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    $filter, $timeout, dataTarea, montogarantiaService, Upload) {
                    $scope.dataMontoGarantia = null;
                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }

                    $scope.mostrarAlerta = false;
                    if ($scope.dataTask.ttPadre.aprobado == 0) {
                      $scope.mostrarAlerta = true;
                    }
                    $scope.calcularEtapas = function () {
                      //calculo de garantia por etapa -- Perfil regional
                      var tmpData = $scope.dataTask.ttGestion.rodal;
                      var dataCompromiso = [];
                      //obtener datos del primer turno
                      for (i = 0; i < tmpData.length; i++) {
                        if (parseInt(tmpData[i].turno) == 1) {
                          dataCompromiso.push(tmpData[i]);
                        }
                      }
                      var sumaTotalVolumen = 0;
                      var abrTotal = 0, abrExtraerTotal = 0;
                      for (i = 0; i < dataCompromiso.length; i++) { //rodales
                        var abrExtraer = 0;
                        var abr = 0;
                        for (j = 0; j < dataCompromiso[i].especies.length; j++) {//especie por rodal
                          abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                          abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
                          if (dataCompromiso[i].especies[j].ttEspecieEvaluada == null) {
                            valor = 0;
                          } else {
                            valor = parseFloat(dataCompromiso[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(dataCompromiso[i].especies[j].ttEspecieEvaluada.leniaValor);
                            valor = parseFloat(valor).toFixed(2);
                          }
                          dataCompromiso[i].especies[j].totalValor = valor;
                          valor = valor * 0.1;
                          valor = parseFloat(valor).toFixed(2);
                          dataCompromiso[i].especies[j].totalPagar = valor;
                          sumaTotalVolumen = sumaTotalVolumen + parseFloat(dataCompromiso[i].especies[j].volrExtraer);
                        }
                        dataCompromiso[i].abr = abr;
                        dataCompromiso[i].abrExtraer = abrExtraer;
                        abrTotal += abr;
                        abrExtraerTotal += abrExtraer;
                      }
                      var porcentaje = 0;
                      porcentaje = abrExtraerTotal * 100;
                      porcentaje = porcentaje / abrTotal;
                      porcentaje = Math.round(porcentaje);
                      var totalPagar = 0;
                      var montoTotal = 0;
                      var areaGarantia = 0;
                      var areaTotal = 0;
                      var montoUtilizar = 0;
                      for (i = 0; i < dataCompromiso.length; i++) {
                        if (porcentaje > 70) {
                          dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                          dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                        } else {
                          var areaCompromiso = dataCompromiso[i].abrExtraer / dataCompromiso[i].abr;
                          areaCompromiso = areaCompromiso * dataCompromiso[i].area;
                          areaCompromiso = parseFloat(areaCompromiso).toFixed(2);
                          dataCompromiso[i].areaCompromiso = areaCompromiso;
                          dataCompromiso[i].areaMonto = dataCompromiso[i].area;
                        }
                        areaGarantia += dataCompromiso[i].areaCompromiso;
                      }
                      var hoy = new Date();
                      var mes = hoy.getMonth() + 1;
                      var anio = hoy.getFullYear();
                      anio = anio + 1;
                      if (mes > 6) {
                        anio = anio + 1;
                      }
                      //siempre es 31 de octubre
                      var inicio = new Date(anio, 9, 31);
                      var fin = inicio;
                      var tmpEtapa = [];
                      var vigencia;
                      for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                        if (i == 0) {
                          vigencia = "Hasta " + $filter('date')(new Date(inicio), 'dd/MM/yyyy');
                        } else {
                          inicio = $scope.addDays(inicio, 1);
                          fin.setFullYear(fin.getFullYear() + 1);
                          vigencia = "Del " + $filter('date')(new Date(inicio), 'dd/MM/yyyy') + " al " + $filter('date')(new Date(fin), 'dd/MM/yyyy');
                          inicio = fin;
                        }
                        var monto = $scope.dataMontoGarantia[i].monto;
                        monto = monto * areaGarantia;
                        monto = parseFloat(monto).toFixed(2);
                        tmpEtapa.push(
                          {
                            monto: monto,
                            etapaDesc: $scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoDesc,
                            vigencia: vigencia
                          }
                        );
                        $scope.dataTask.ttSeguimientoTarea.fechaAdmision = fin;
                        $scope.dataTask.ttSeguimientoTarea.analisis = JSON.parse($scope.dataTask.ttPadre.observaciones);
                      }
                    }
                    if ($scope.dataTask.ttGestion.ttPlanificacionGestion.hayReforestacion == 1) {
                      if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia != null) {
                        montogarantiaService.listaByTipoGarantia($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataMontoGarantia = res.data;
                            for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                              if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                                if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                                  $scope.data.montoUtilizar = $scope.dataMontoGarantia[i].monto;
                                  break;
                                }
                              }
                            }
                            $scope.calcularEtapas();
                          } else {
                            toastr.error('Error al obtener datos de garantia: ' + res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;
                    var now = new Date();
                    var end = new Date(now.getFullYear() + parseInt($scope.dataTask.ttGestion.ttResumenGestion.anios), 9, 31);

                    $scope.setAprobar = function () {
                      if ($scope.data.approved) {
                        $scope.data.aprobado = 1;
                        if ($scope.dataTask.tcTask.taskId == 6) {
                          $scope.textoResolucion = 'No. de licencia';
                        } else {
                          $scope.textoResolucion = 'No. de Resolución';
                        }
                      } else {
                        $scope.data.aprobado = 0;
                        $scope.textoResolucion = 'No. de Resolución';
                      }
                      $scope.data.esEnmienda = 0;
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.data.aprobado = 2;
                      } else {
                        if ($scope.data.approved) {
                          $scope.data.aprobado = 1;
                        } else {
                          $scope.data.aprobado = 0;
                        }
                        $scope.data.esEnmienda = 0;
                      }
                    }

                    $scope.agregarEnmienda = function () {
                      $scope.data.enmienda.push({ descripcion: null });
                    }

                    $scope.borrarEnmienda = function (item) {
                      $scope.data.enmienda.splice($scope.data.enmienda.indexOf(item), 1);
                    }

                    if ($scope.dataTask.tcTask.taskId == 6) {
                      $scope.textoResolucion = 'No. de Licencia';
                    } else {
                      $scope.textoResolucion = 'No. de Resolución';
                    }

                    $scope.data = {
                      enmienda: [],
                      fundamento: [],
                      aprobado: 1,
                      approved: true,
                      amendment: false,
                      anios: $scope.dataTask.ttGestion.ttResumenGestion.anios,
                      fechaIni: now,
                      fechaFin: end
                    }

                    $scope.agregarFundamento = function () {
                      if ($scope.mostrarGuardar) {
                        $scope.data.fundamento.push({ descripcion: null });
                      }
                    }

                    $scope.borrarDetalle = function (item) {
                      if ($scope.mostrarGuardar) {
                        $scope.data.fundamento.splice($scope.data.fundamento.indexOf(item), 1);
                      }
                    }

                    $scope.setVencimiento = function () {
                      if ($scope.data.anios != null && $scope.data.fechaIni != null) {
                        var now = new Date($scope.data.fechaIni);
                        var end = new Date(now.getFullYear() + parseInt($scope.data.anios), 9, 31);
                        $scope.data.fechaFin = end;
                      }
                      if ($scope.data.anios != $scope.dataTask.ttGestion.ttResumenGestion.anios) {
                        var data = $scope.dataTask.ttGestion;
                        var ttResumenGestion = {
                          anios: $scope.data.anios,
                          resumenGestionId: data.ttResumenGestion.resumenGestionId,
                          ttGestion: {
                            gestionId: data.gestionId,
                            tcTipoGestion: data.tcTipoGestion,
                            estadoId: data.estadoId,
                            tcPersonaCrea: data.tcPersonaCrea,
                            tcTipoBosque: data.tcTipoBosque,
                            tcElaborador: data.tcElaborador,
                            ttTipoPropietarioGestion: data.ttTipoPropietarioGestion
                          }
                        };
                        gestionService.actualizarAniosGestion(ttResumenGestion).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataTask.ttGestion.ttResumenGestion.anios = $scope.data.anios;
                            toastr.success('Se ha actualizado los años de vigencia correctamente');
                          } else {
                            toastr.error('Error al obtener datos de garantia: ' + res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    $scope.vistaPrevia = function () {
                      if ($scope.data.amendment) {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo == null || $scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                          toastr.error("Debe indicar el número de oficio");
                          return;
                        }
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia == null || $scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                          toastr.error("Debe indicar el número de providencia");
                          return;
                        }
                        if ($scope.data.aprobado == null) {
                          toastr.error("Debe seleccionar su decisión");
                          return;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                          if ($scope.data.approved) {
                            toastr.error("Debe indicar el número de licencia");
                          } else {
                            toastr.error("Debe indicar el número de resolución");
                          }
                          return;
                        }
                        if ($scope.data.aprobado == 0) {
                          if ($scope.data.fundamento.length <= 0) {
                            toastr.error("Debe indicar los fundamentos de su decisión");
                            return;
                          }
                        } else {
                          if ($scope.data.fechaIni == null || $scope.data.fechaFin == null) {
                            toastr.error("Debe indicar todos los datos");
                            return;
                          }
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      var item = angular.copy($scope.data);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      data.aprobado = $scope.data.aprobado;
                      data.esEnmienda = 0;
                      if ($scope.data.aprobado == 1) {
                        delete item.fundamento;
                        data.ttSeguimientoTarea.fundamento = item;
                      } else {
                        data.ttSeguimientoTarea.fundamento = item.fundamento;
                      }
                      var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDiaResolucion = $scope.numeroALetras(dia);
                      var strAnioResolucion = $scope.numeroALetras(anio, false);
                      if ($scope.data.amendment) {
                        data.ttSeguimientoTarea.enmienda = item.enmienda;
                        data.esEnmienda = 0;
                        data.aprobado = 2;
                        utilsService.generarEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                        if ($scope.dataTask.tcTask.taskId == 6) {
                          utilsService.generarOpinionRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          utilsService.setEsPrevia(true);
                          utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                        $timeout(function () {
                          utilsService.generarProvidenciaRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }, 2000);
                      }
                    }

                    $scope.uploadFile = function (file, data) {
                      Upload.upload({
                        url: appSettings.urlServiceBase + 'file/upload',
                        method: 'POST',
                        file: file,
                        sendFieldsAs: 'form',
                        fields: {
                          data: []
                        }
                      }).then(function (resp) {
                        var data = resp.data;
                        if (data.status == 'OK') {

                        } else {
                          toastr.error(resp.data.message)
                        }
                      }, function (resp) {
                        toastr.error('Error status: ' + resp.status);
                      }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.avance = progressPercentage;
                      });
                    }

                    $scope.enviarTarea = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            if ($scope.data.amendment) {
                              if ($scope.dataTask.ttSeguimientoTarea.codigo == null || $scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                                toastr.error("Debe indicar el número de oficio");
                                return;
                              }
                            } else {
                              if ($scope.dataTask.ttSeguimientoTarea.providencia == null || $scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                                toastr.error("Debe indicar el número de providencia");
                                return;
                              }
                              if ($scope.data.aprobado == null) {
                                toastr.error("Debe seleccionar su decisión");
                                return;
                              } else {
                                if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                                  toastr.error("Debe indicar el número de licencia");
                                  return;
                                }
                                if ($scope.data.aprobado == 0) {
                                  if ($scope.data.fundamento.length <= 0) {
                                    toastr.error("Debe indicar los fundamentos de su decisión");
                                    return;
                                  }
                                } else {
                                  if ($scope.data.fechaIni == null || $scope.data.fechaFin == null) {
                                    toastr.error("Debe indicar todos los datos");
                                    return;
                                  }
                                }
                              }
                            }
                            var data = angular.copy($scope.dataTask);
                            var item = angular.copy($scope.data);
                            var ttGestion = data.ttGestion;
                            var ttPadre = data.ttPadre;
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            data.ttPadre = null;
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = $scope.data.aprobado;
                            data.tcPersonaAsignada.foto = null;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            data.tcPersonaMonitoreo = null;
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = $scope.data.aprobado;
                            data.esEnmienda = 0;
                            if ($scope.data.aprobado == 1) {
                              delete item.fundamento;
                              data.ttGestion.fechaVencimiento = item.fechaFin;
                              data.ttSeguimientoTarea.fundamento = JSON.stringify(item);
                              if (data.ttSeguimientoTarea.analisis != null) {
                                data.ttSeguimientoTarea.analisis = JSON.stringify(data.ttSeguimientoTarea.analisis);
                              }
                            } else {
                              data.ttSeguimientoTarea.fundamento = JSON.stringify(item.fundamento);
                              data.ttSeguimientoTarea.analisis = null;
                            }
                            if ($scope.data.amendment) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(item.enmienda);
                              data.esEnmienda = 1;
                              data.aprobado = 2;
                              data.ttSeguimientoTarea.analisis = null;
                            }
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.setOpinionRegional(data).then(function (res) {
                              if (res.status == "OK") {
                                swal('Para imprimir los documentos, busque la tarea en finalizadas y utilice el botón correspondiente');
                                data.ttGestion = ttGestion;
                                data.ttPadre = ttPadre;
                                if ($scope.data.amendment) {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                  data.esEnmienda = 0;
                                  data.aprobado = 2;
                                  utilsService.generarEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, data.tareaId);
                                } else {
                                  data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                  if (data.ttSeguimientoTarea.analisis != null) {
                                    data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                                  }
                                  var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                  var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                  var strDiaResolucion = $scope.numeroALetras(dia);
                                  var strAnioResolucion = $scope.numeroALetras(anio, false);
                                  data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                  if (data.tcTask.taskId == 6) {
                                    utilsService.generarOpinionRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, data.tareaId);
                                  } else {
                                    utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, data.tareaId);
                                  }
                                  utilsService.generarProvidenciaRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, data.tareaId);
                                }
                                $state.go('index.tarea.fin');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                      $scope.classEnmienda = "btn btn-default";
                    }

                    //ventana para enmiendas
                    $scope.generarEnmienda = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      $scope.classAprobar = "btn btn-default";
                      $scope.classNoAprobar = "btn btn-default";
                      $scope.data.aprobado = null;
                      $scope.mostrarLista = false;
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista de enmiendas regionales";

                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              $scope.enmienda.push({ descripcion: null });
                            }

                            $scope.borrarEnmienda = function (item) {
                              $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                            }

                            $scope.vistaPreviaEnmienda = function () {
                              tareaService.getDataOpinionRegional($scope.dataTask.tareaId).then(function (res) {
                                if (res.status == "OK") {
                                  if (res.data.codigo == null) {
                                    res.data.fechaProvidencia = new Date();
                                    res.data.codigo = "XX-XX-XXXX-XXX-XXXX";
                                    res.data.fundamento = $scope.enmienda;
                                    res.data.aprobado = 2;
                                  } else {
                                    try {
                                      res.data.fundamento = JSON.parse(res.data.fundamento);
                                    } catch (e) {

                                    }
                                  }
                                  utilsService.generarEnmiendaRegional(res.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                $scope.enviarTarea(true, $scope.enmienda);
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.licencia', {
            url: '/licencia',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.licencia.tpl.html',
                resolve: {
                  dataTarea: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getGestionParaLicencia($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'tareaService', 'ngDialog', 'tareaService', 'utilsService',
                  '$filter', '$timeout', 'dataTarea', 'Upload',
                  function ($scope, $state, toastr, tareaService, ngDialog, tareaService, utilsService,
                    $filter, $timeout, dataTarea, Upload) {
                    if (dataTarea.status == 0) {
                      toastr.error(dataTarea.message);
                      $state.go('index.tarea.list');
                    }
                    var ttPoaGestion = dataTarea.data;
                    $scope.ttPoaGestion = ttPoaGestion;
                    $scope.dataTask = ttPoaGestion.ttTarea;
                    $scope.ttTareaPadre = ttPoaGestion.ttTarea.ttPadre;

                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }

                    $scope.vistaPreviaLicencia = function (reload) {
                      if (reload == null) {
                        reload = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.providencia == null || $scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                        toastr.error("Debe indicar el número de providencia");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de licencia");
                        return;
                      }
                      var data = angular.copy($scope.ttPoaGestion);
                      delete data.ttTarea;
                      var ttTarea = angular.copy($scope.dataTask);
                      ttTarea.ttSeguimientoTarea.fechaProvidencia = new Date();
                      var dia = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDiaResolucion = $scope.numeroALetras(dia, false);
                      var strAnioResolucion = $scope.numeroALetras(anio, false);
                      ttTarea.ttGestion = data.ttGestion;
                      delete data.ttGestion;
                      data.ttTarea = ttTarea;
                      var tareaId = null;
                      if (reload) {
                        tareaId = ttTarea.tareaId;
                      }
                      utilsService.setEsPrevia(true);
                      utilsService.generarLicenciaForestal(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId);
                      $timeout(function () {
                        utilsService.setEsPrevia(true);
                        utilsService.generarProvidenciaRegional(ttTarea, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId, true);
                      }, 2000);
                    }

                    $scope.enviarTarea = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            if ($scope.dataTask.ttSeguimientoTarea.providencia == null || $scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                              toastr.error("Debe indicar el número de providencia");
                              return;
                            }
                            if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                              toastr.error("Debe indicar el número de licencia");
                              return;
                            }
                            var data = angular.copy($scope.dataTask);
                            tareaService.setLicencia(data).then(function (res) {
                              if (res.status == 1) {
                                $scope.vistaPreviaLicencia(true);
                                swal('Para imprimir los documentos, busque la tarea en finalizadas y utilice el botón correspondiente');
                                $state.go('index.tarea.fin');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                      $scope.classEnmienda = "btn btn-default";
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notilicencia', {
            url: '/notilicencia',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notilicencia.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.getConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data[0];

                    $scope.vistaPreviaNotificacion = function (reload) {
                      if (reload == null) {
                        reload = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      var esLicencia = true;
                      utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                    }

                    $scope.notificarLicencia = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            data.fechaFinalizacion = new Date();
                            delete data.ttPadre;
                            tareaService.notificarLicencia(data).then(function (res) {
                              if (res.status == 1) {
                                toastr.success(res.message);
                                $scope.vistaPreviaNotificacion(true);
                                $state.go('index.tarea.fin');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.resolucion', {
            url: '/resolucion',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.subregional.tpl.html',
                resolve: {
                  dataTarea: ['gestionService', '$stateParams', '$base64',
                    function (gestionService, $stateParams, $base64) {
                      return gestionService.getGestionParaTareaConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$timeout', 'dataTarea', '$filter',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $timeout, dataTarea, $filter) {
                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    if ($scope.dataTask.ttPadre != null) {
                      if ($scope.dataTask.ttPadre.ttSeguimientoTarea != null) {
                        if ($scope.dataTask.ttPadre.ttSeguimientoTarea.fundamento != null) {
                          $scope.dataTask.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse($scope.dataTask.ttPadre.ttSeguimientoTarea.fundamento);
                        }
                        if ($scope.dataTask.ttPadre.ttSeguimientoTarea.analisis != null) {
                          $scope.dataTask.ttPadre.ttSeguimientoTarea.analisis = JSON.parse($scope.dataTask.ttPadre.ttSeguimientoTarea.analisis);
                        }
                      }
                    }

                    $scope.vistaPrevia = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      var esLicencia = true;
                      if (data.tcTask.taskId == 47) {
                        esLicencia = false;
                      }
                      utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                      if (data.ttPadre.aprobado == 1) {
                        if (esLicencia) {
                          $timeout(function () {
                            utilsService.generarNotificacionLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }, 1000);
                        } else {
                          data.aprobado = 1;
                          data.fechaFinalizacion = new Date();
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDiaResolucion = $scope.numeroALetras(dia);
                          var strAnioResolucion = $scope.numeroALetras(anio, false);
                          var esPoa = false;
                          var noTurno = 1;
                          if (data.ttSeguimientoTarea.numero != null) {
                            data.ttSeguimientoTarea.volumen = parseFloat(data.ttSeguimientoTarea.numero) * 15;
                          }
                          utilsService.setEsPrevia(true);
                          utilsService.generarOficioRequisitoPoa(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, esPoa, noTurno);
                        }
                        if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2) { //poliza de fianzas
                          $timeout(function () {
                            var strVigencia = "";
                            var ini = new Date(data.ttPadre.ttSeguimientoTarea.fundamento.fechaIni);
                            dia = $filter('date')(ini, 'dd');
                            anio = $filter('date')(ini, 'yyyy');
                            strVigencia = $scope.numeroALetras(dia) + " de " + utilsService.getMonthName(ini.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                            var analisis = data.ttPadre.ttSeguimientoTarea.analisis;
                            var row = analisis[analisis.length - 1];
                            var vigencia = row.vigencia;
                            var strFecha = vigencia.substring(vigencia.indexOf('al') + 3);
                            var parts = strFecha.split('/');
                            var fin = new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
                            dia = $filter('date')(fin, 'dd');
                            anio = $filter('date')(fin, 'yyyy');
                            strVigencia += " al " + $scope.numeroALetras(dia) + " de " + utilsService.getMonthName(fin.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                            data.observaciones = JSON.parse(data.observaciones);
                            utilsService.setEsPrevia(true);
                            utilsService.generarCartaPoliza(data, strVigencia, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }, 2000);
                        }
                      }
                    }

                    $scope.notificarResolucion = function (isValid) {
                      if (!isValid) {
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                        toastr.error('Debe indicar el número de notificación');
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de notificar la resolución y generar la cedula de notificación? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var ttGestion = data.ttGestion;
                            var ttPadre = data.ttPadre;
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            data.ttPadre = null;
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = 1;
                            data.tcPersonaAsignada.foto = null;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            data.esEnmienda = 0;
                            if (data.ttSeguimientoTarea.numero != null) {
                              data.ttSeguimientoTarea.volumen = parseFloat(data.ttSeguimientoTarea.numero) * 15;
                            }
                            tareaService.notificarResolucion(data).then(function (res) {
                              if (res.status == "OK") {
                                data.ttPadre = ttPadre;
                                data.ttGestion = ttGestion;
                                var esLicencia = true;
                                if (data.tcTask.taskId == 47) {
                                  esLicencia = false;
                                }
                                utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                                if (data.ttPadre.aprobado == 1) {
                                  if (esLicencia) {
                                    $timeout(function () {
                                      utilsService.generarNotificacionLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                    }, 1000);
                                  } else {
                                    data.aprobado = 1;
                                    data.fechaFinalizacion = new Date();
                                    var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                    var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                    var strDiaResolucion = $scope.numeroALetras(dia);
                                    var strAnioResolucion = $scope.numeroALetras(anio, false);
                                    var esPoa = false;
                                    var noTurno = 1;
                                    utilsService.generarOficioRequisitoPoa(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, esPoa, noTurno);
                                  }
                                  if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2) { //poliza de fianzas
                                    $timeout(function () {
                                      var strVigencia = "";
                                      var ini = new Date(data.ttPadre.ttSeguimientoTarea.fundamento.fechaIni);
                                      dia = $filter('date')(ini, 'dd');
                                      anio = $filter('date')(ini, 'yyyy');
                                      strVigencia = $scope.numeroALetras(dia) + " de " + utilsService.getMonthName(ini.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                      var analisis = data.ttPadre.ttSeguimientoTarea.analisis;
                                      var row = analisis[analisis.length - 1];
                                      var vigencia = row.vigencia;
                                      var strFecha = vigencia.substring(vigencia.indexOf('al') + 3);
                                      var parts = strFecha.split('/');
                                      var fin = new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
                                      dia = $filter('date')(fin, 'dd');
                                      anio = $filter('date')(fin, 'yyyy');
                                      strVigencia += " al " + $scope.numeroALetras(dia) + " de " + utilsService.getMonthName(fin.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                      try {
                                        data.observaciones = JSON.parse(data.observaciones);
                                      } catch (error) {

                                      }
                                      utilsService.generarCartaPoliza(data, strVigencia, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                    }, 2000);
                                  }
                                }
                                toastr.success(res.message);
                                $state.go('index.tarea.list');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }
                  }]
              }
            }
          })
          .state('index.realizar.srpoa', {
            url: '/srpoa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.srpoa.tpl.html',
                resolve: {
                  tareaTecnico: ['gestionService', '$stateParams', 'authService',
                    function (gestionService, $stateParams, authService) {
                      var gestionId = authService.getLocalData('gestionId');
                      return gestionService.getGestionParaPoaTarea(gestionId);
                    }],
                  dataPoa: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDataPoa($base64.decode($stateParams.tareaId));
                    }],
                  dataMontoGarantia: ['montogarantiaService', '$stateParams', 'tareaTecnico',
                    function (montogarantiaService, $stateParams, tareaTecnico) {
                      var ttTarea = tareaTecnico.data[0];
                      if (ttTarea.ttGestion.ttPlanificacionGestion.hayReforestacion == 1 && ttTarea.ttGestion.ttResumenGestion.tcTipoGarantia != null) {
                        return montogarantiaService.listaByTipoGarantia(ttTarea.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId);
                      } else {
                        var data = [];
                        var res = {
                          status: 'OK',
                          message: 'Datos cargados',
                          data: data
                        };
                        return res;
                      }
                    }],
                  dataNotas: ['notaenvioService', 'authService',
                    function (notaenvioService, authService) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return notaenvioService.getCorrelativoNota(loginData.subregionId, 1);
                    }],
                  dataAfianzadora: ['afianzadoraService', 'authService',
                    function (afianzadoraService, authService) {
                      return afianzadoraService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', '$timeout',
                  'catalogosService', 'tareaTecnico', 'dataPoa', 'dataMontoGarantia', 'dataNotas', 'dataAfianzadora',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, $timeout,
                    catalogosService, tareaTecnico, dataPoa, dataMontoGarantia, dataNotas, dataAfianzadora) {

                    $scope.esEnmienda = false;
                    $scope.tareaTecnico = tareaTecnico.data[0];
                    $scope.tituloTarea = 'Resolución de Plan Operativo Anual';
                    $scope.tituloResolucion = 'No. Resolución';
                    $scope.mostrarAdvertencia = false;
                    $scope.obligatorioOficios = true;
                    $scope.dataNotas = dataNotas.data;
                    if ($scope.tareaTecnico.aprobado == 0) {
                      $scope.mostrarAdvertencia = true;
                    }
                    $scope.dataTask.ttGestion = $scope.tareaTecnico.ttGestion;
                    $scope.dataTask.tcPersonaTraslado = $scope.tareaTecnico.tcPersonaTraslado;
                    $scope.dataTask.fechaFinalizacion = $scope.tareaTecnico.fechaFinalizacion;
                    $scope.tareaTecnico.ttSeguimientoTarea.fundamento = JSON.parse($scope.tareaTecnico.ttSeguimientoTarea.fundamento);
                    $scope.dataTask.ttPadre = $scope.tareaTecnico.ttPadre;
                    $scope.data = dataPoa.data[0];
                    if ($scope.data.noPoa == null) {
                      $scope.data.noPoa = 1;
                    }
                    if ($scope.data.noPoa <= 0) {
                      $scope.data.noPoa = 1;
                    }
                    if ($scope.data.tcTipoGarantia == null) {
                      $scope.data.fechaGarantia = new Date();
                      $scope.data.fechaVencimiento = new Date();
                    }
                    if ($scope.data.notas == null || $scope.data.notas.length <= 0) {
                      $scope.data.notas = [
                        { inicio: null, fin: null, cantidad: null }
                      ];
                    }
                    $scope.dataRegente = [];
                    $scope.requiereRegente = false;
                    $scope.dataMontoGarantia = dataMontoGarantia.data;
                    $scope.dataAfianzadora = dataAfianzadora.data;
                    $scope.dataTask.ttSeguimientoTarea = {
                      fundamento: {},
                      enmienda: $scope.tareaTecnico.ttSeguimientoTarea.enmienda
                    };
                    $scope.requiereAfianzadora = false;
                    if ($scope.data.tcTipoGarantia != null) {
                      if ($scope.data.tcTipoGarantia.tipoGarantiaId == 2) {
                        $scope.requiereAfianzadora = true;
                      }
                    }

                    //si es nuevo procedimiento cambiar titulos
                    if ($scope.dataTask.tcTask.taskId == 49) {
                      $scope.tituloResolucion = 'No. Oficio';
                      $scope.tituloTarea = 'Oficio para solicitar Licencia de Aprovechamiento';
                      $scope.obligatorioOficios = false;
                    } else {
                      $scope.dataTask.ttSeguimientoTarea.numeroResolucion = $scope.dataTask.ttSeguimientoTarea.enmienda + '-' + $scope.data.noPoa;
                    }

                    var tmpData = $scope.tareaTecnico.ttGestion.rodal;
                    var dataCompromiso = [];
                    //obtener datos del primer turno
                    for (i = 0; i < tmpData.length; i++) {
                      if (parseInt(tmpData[i].turno) == $scope.data.noPoa) {
                        dataCompromiso.push(tmpData[i]);
                      }
                    }
                    var sumaTotalVolumen = 0;
                    var abrTotal = 0, abrExtraerTotal = 0;
                    for (i = 0; i < dataCompromiso.length; i++) { //rodales
                      var abrExtraer = 0;
                      var abr = 0;
                      for (j = 0; j < dataCompromiso[i].especies.length; j++) {//especie por rodal
                        abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                        abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
                        valor = parseFloat(dataCompromiso[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(dataCompromiso[i].especies[j].ttEspecieEvaluada.leniaValor);
                        valor = parseFloat(valor).toFixed(2);
                        dataCompromiso[i].especies[j].totalValor = valor;
                        valor = valor * 0.1;
                        valor = parseFloat(valor).toFixed(2);
                        dataCompromiso[i].especies[j].totalPagar = valor;
                        sumaTotalVolumen = sumaTotalVolumen + parseFloat(dataCompromiso[i].especies[j].volrExtraer);
                      }
                      dataCompromiso[i].abr = abr;
                      dataCompromiso[i].abrExtraer = abrExtraer;
                      abrTotal += abr;
                      abrExtraerTotal += abrExtraer;
                    }
                    var porcentaje = 0;
                    porcentaje = abrExtraerTotal * 100;
                    porcentaje = porcentaje / abrTotal;
                    porcentaje = Math.round(porcentaje);
                    var totalPagar = 0;
                    var calculoMonto = 'área por área';
                    var montoTotal = 0;
                    var areaGarantia = 0;
                    var areaTotal = 0;
                    var montoUtilizar = 0;
                    for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                      if ($scope.tareaTecnico.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                        if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                          montoUtilizar = $scope.dataMontoGarantia[i].monto;
                          break;
                        }
                      }
                    }
                    if ($scope.dataTask.ttGestion.ttPlanificacionGestion.hayReforestacion == 0) {
                      montoUtilizar = appSettings.montoFondo;
                    }
                    var hayFueraBosque = false;
                    for (i = 0; i < dataCompromiso.length; i++) {
                      dataCompromiso[i].areaMonto = dataCompromiso[i].areaGarantia;
                      if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                        dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                        hayFueraBosque = true;
                        calculoMonto = 'área basal ideal';
                      } else {
                        if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 2) {
                          dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                        }
                      }
                      if (!hayFueraBosque) {
                        calculoMonto = 'área basal';
                      }
                      var montoGarantia = parseFloat(dataCompromiso[i].areaGarantia) * montoUtilizar;
                      montoGarantia = parseFloat(montoGarantia).toFixed(2);
                      dataCompromiso[i].montoCompromiso = montoGarantia;
                      montoTotal = parseFloat(montoTotal) + parseFloat(montoGarantia);
                      areaGarantia += dataCompromiso[i].areaMonto;
                      if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                        areaTotal = areaTotal + dataCompromiso[i].areaGarantia;
                      } else {
                        areaTotal = areaTotal + dataCompromiso[i].area;
                      }
                      for (j = 0; j < dataCompromiso[i].especies.length; j++) {
                        totalPagar += parseFloat(dataCompromiso[i].especies[j].totalPagar);
                      }
                    }

                    $scope.data.montoGarantia = parseFloat(montoTotal).toFixed(2);
                    $scope.data.areaGarantia = parseFloat(areaGarantia).toFixed(4);
                    if (sumaTotalVolumen > 100) {
                      $scope.requiereRegente = true;
                      if ($scope.dataRegente.length <= 0) {
                        catalogosService.getListaRegentePorArea(parseFloat($scope.dataTask.ttGestion.area)).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataRegente = res.data;
                            $scope.tcRegente = {};
                            $scope.data.tcRegente = $scope.tareaTecnico.ttGestion.ttResumenGestion.tcRegente;
                            $scope.tcRegente.cui = '' + $scope.tareaTecnico.ttGestion.ttResumenGestion.tcRegente.cui;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    var now = new Date();
                    $scope.data.noMeses = $scope.tareaTecnico.ttSeguimientoTarea.area;
                    $scope.data.totalNotas = $scope.tareaTecnico.ttSeguimientoTarea.monto;
                    $scope.data.cantidadEntregada = $scope.data.totalNotas;
                    $scope.data.fechaIni = now;
                    if ($scope.data.noPoa == null) {
                      $scope.data.noPoa = 1;
                    }
                    if (parseInt($scope.data.noPoa) <= 0) {
                      $scope.data.noPoa = 1;
                    }
                    $scope.data.anioVigencia = 1;
                    $scope.data.anioOperacion = now.getFullYear();
                    $scope.data.monto = parseFloat(totalPagar).toFixed(2);

                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;

                    $scope.setPrimerCorrelativoFinal = function () {
                      if ($scope.data.notas == null) {
                        $scope.data.notas = [
                          { inicio: 0, fin: 0 }
                        ];
                      }
                      if ($scope.data.cantidadEntregada != null && $scope.data.correlativoIni != null) {
                        $scope.data.correlativoFin = parseInt($scope.data.correlativoIni) + (parseInt($scope.data.cantidadEntregada) - 1);
                      }
                    }

                    $scope.setFechaVencimiento = function () {
                      if ($scope.data.noMeses != null && $scope.data.fechaIni != null) {
                        var date = new Date($scope.data.fechaIni);
                        date.setMonth(date.getMonth() + parseInt($scope.data.noMeses));
                        date.setDate(date.getDate() - 1);
                        $scope.data.fechaFin = date;
                      }
                    }

                    $scope.validarCantidad = function () {
                      if ($scope.data.cantidadEntregada != null) {
                        if (parseInt($scope.data.cantidadEntregada) < 1 || parseInt($scope.data.cantidadEntregada) > ($scope.data.totalNotas)) {
                          $scope.data.cantidadEntregada = null;
                          toastr.error('La cantidad no es válida');
                        }
                      }
                    }

                    $scope.setCorrelativoNota = function () {
                      var dataNotas = $scope.dataNotas;
                      if (dataNotas == null || dataNotas.length <= 0) {
                        swal('No hay correlativos disponibles para la subregión, favor de revisar');
                        return;
                      }
                      var totalDisponible = 0;
                      for (var i = 0; i < dataNotas.length; i++) {
                        var row = dataNotas[i];
                        totalDisponible += row.saldo;
                      }
                      var cantidadEntregada = parseInt($scope.data.totalNotas);
                      $scope.data.cantidadEntregada = cantidadEntregada;
                      if (cantidadEntregada > totalDisponible) {
                        swal('No hay correlativos disponibles para la subregión, favor de revisar su inventario');
                        return;
                      }
                      var falta = cantidadEntregada;
                      var i = 0;
                      $scope.data.notas = [];
                      while (falta > 0) {
                        var rango = { inicio: null, fin: null, cantidad: null };
                        var row = dataNotas[i];
                        i++;
                        rango.inicio = row.siguiente;
                        if (falta > row.saldo) {
                          rango.fin = row.siguiente + (parseInt(row.saldo) - 1);
                          rango.cantidad = row.saldo;
                          falta = parseInt(falta) - parseInt(row.saldo);
                        } else {
                          rango.fin = row.siguiente + (parseInt(falta) - 1);
                          rango.cantidad = parseInt(falta);
                          falta = 0;
                        }
                        $scope.data.notas.push(rango);
                      }
                    }

                    $scope.setCorrelativoNota();
                    $scope.setFechaVencimiento();

                    $scope.agregarOtroRango = function () {
                      $scope.data.notas.push({ inicio: null, fin: null, cantidad: null });
                    }

                    $scope.quitarRango = function (item) {
                      $scope.data.notas.splice($scope.data.notas.indexOf(item), 1);
                      var notas = $scope.data.notas;
                      var total = 0;
                      for (var i = 0; i < notas.length; i++) {
                        if (notas[i].cantidad != null) {
                          total += parseInt(notas[i].cantidad);
                        }
                      }
                      $scope.data.cantidadEntregada = total;
                    }

                    $scope.setAprobarPoaSubregional = function (ok) {
                      $scope.classEnmienda = "btn btn-default";
                      $scope.mostrarLista = false;
                      $scope.mostrarFecha = false;
                      if ($scope.data.aprobado == ok) {
                        $scope.classNoAprobar = "btn btn-default";
                        $scope.classAprobar = "btn btn-default";
                        $scope.visibleEnmienda = true;
                        $scope.data.aprobado = null;
                        return;
                      }
                      $scope.data.aprobado = ok;
                      if (ok == 1) {
                        if ($scope.data.rfnRegente == undefined || $scope.data.rfnRegente == null) {
                          if ($scope.dataTask.categoriaProfesionDesc == undefined || $scope.dataTask.categoriaProfesionDesc == null) {
                            /** Obtener categoria del elaborador **/
                            catalogosService.getDatosRegente($scope.dataTask.nitElaborador, 2).then(function (res) {
                              if (res.status == "OK") {
                                var obj = res.data[0];
                                $scope.dataTask.categoriaProfesionDesc = obj.categoriaProfesion;
                                $scope.data.categoriaProfesionDesc = obj.categoriaProfesion;
                                $scope.data.rfnRegente = obj.noRegistro;
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }
                        $scope.classAprobar = "btn btn-success";
                        $scope.classNoAprobar = "btn btn-default";
                        $scope.mostrarFecha = true;
                      } else {
                        $scope.classNoAprobar = "btn btn-success";
                        $scope.classAprobar = "btn btn-default";
                        $scope.data.aprobadoDesc = "NO APROBAR";
                        $scope.mostrarLista = true;
                      }
                      $scope.visibleEnmienda = false;
                      $scope.data.fundamento = [];
                    }

                    $scope.agregarFundamento = function () {
                      if ($scope.mostrarGuardar) {
                        $scope.data.fundamento.push({ descripcion: null });
                      }
                    }

                    $scope.borrarDetalle = function (item) {
                      if ($scope.mostrarGuardar) {
                        $scope.data.fundamento.splice($scope.data.fundamento.indexOf(item), 1);
                      }
                    }

                    $scope.setRegente = function (item) {
                      var tcRegente = {
                        personaDesc: item.nombre,
                        rf: item.noRegistro,
                        cui: item.noCedula
                      }
                      $scope.data.tcRegente = tcRegente;
                    }

                    $scope.setAfianzadora = function (item) {
                      $scope.data.tcAfianzadora.afianzadoraDesc = item.afianzadoraDesc;
                    }

                    $scope.setCorrelativoFinal = function (item) {
                      if (item.cantidad != null && item.inicio != null) {
                        item.fin = parseInt(item.inicio) + (parseInt(item.cantidad) - 1);
                      }
                      var notas = $scope.data.notas;
                      var total = 0;
                      for (var i = 0; i < notas.length; i++) {
                        if (notas[i].cantidad != null) {
                          total += parseInt(notas[i].cantidad);
                        }
                      }
                      $scope.data.cantidadEntregada = total;
                    }

                    $scope.vistaPreviaPoaSubregional = function (isValid, goToList) {
                      if (!isValid) {
                        toastr.error("Debe rellenar todos los campos");
                        return;
                      }
                      if (goToList == undefined) {
                        goToList = false;
                      }
                      utilsService.setEsPrevia(!goToList);
                      var data = angular.copy($scope.data);
                      var ttTarea = angular.copy($scope.dataTask);                      
                      ttTarea.ttSeguimientoTarea.fechaProvidencia = new Date();
                      var dia = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDiaResolucion = $scope.numeroALetras(dia, false);
                      var strAnioResolucion = $scope.numeroALetras(anio, false);
                      data.ttTarea = ttTarea;
                      var esPoa = true;
                      if (ttTarea.tcTask.taskId == 49) {
                        esPoa = false;
                      }
                      if (esPoa) {
                        utilsService.generarPoaSubregional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        $timeout(function () {
                          utilsService.generarCedulaNotificacionPoa(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        }, 1000);
                      } else {
                        utilsService.setEsPrevia(!goToList);
                        utilsService.oficioSolicitudLicencia(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (esPoa) {
                        $timeout(function () {
                          utilsService.generarNotificacionRegionalPoa(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }, 1000);
                      }
                      if (goToList) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTareaPoaSubregional = function (isValid) {
                      if (!isValid) {
                        toastr.error("Debe rellenar todos los campos");
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var item = angular.copy($scope.dataTask);
                            var ttGestion = item.ttGestion;
                            ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            ttGestion.personaAnulaId = $scope.loginData.personaId;
                            ttGestion.tcPersonaCrea.foto = null;
                            ttGestion.tcElaborador.foto = null;
                            item.ttGestion = {};
                            item.tcPersonaAsignada.foto = null;
                            item.tcPersonaTraslado = null;
                            item.ttSeguimientoTarea.fundamento = JSON.stringify(item.ttSeguimientoTarea.fundamento);
                            var ttPoaGestion = angular.copy($scope.data);
                            ttPoaGestion.ttGestion = ttGestion;
                            ttPoaGestion.ttTarea = item;
                            tareaService.setAprobarPoaSubregional(ttPoaGestion).then(function (res) {
                              if (res.status == "OK") {
                                $scope.vistaPreviaPoaSubregional(true, true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                      $scope.classEnmienda = "btn btn-default";
                    }

                    //ventana para enmiendas
                    $scope.generarEnmiendaSubregionalPoa = function () {
                      $scope.classEnmienda = "btn btn-warning";
                      $scope.classAprobar = "btn btn-default";
                      $scope.classNoAprobar = "btn btn-default";
                      $scope.data.aprobado = null;
                      $scope.mostrarLista = false;
                      $scope.mostrarFecha = false;
                      ngDialog.open({
                        template: 'app/tarea/enmienda.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEnmienda',
                        name: 'uniqueEnmienda',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {
                            $scope.titulo = "Lista de enmiendas a requisitos. Ésta opción no es para solicitar modificaciones al Plan de Manejo, es solo para que el propietario reemplace o cambio documentos que adjuntó en los requisitos";

                            $scope.mostrarOficio = true;
                            $scope.enmienda = [];

                            $scope.agregarEnmienda = function () {
                              $scope.enmienda.push({ descripcion: null });
                            }

                            $scope.borrarEnmienda = function (item) {
                              $scope.enmienda.splice($scope.enmienda.indexOf(item), 1);
                            }

                            $scope.codigo = null;

                            $scope.vistaPreviaEnmienda = function () {
                              var data = angular.copy($scope.dataTask);
                              var enmienda = angular.copy($scope.enmienda);
                              if (enmienda == null) {
                                enmienda = [];
                              }
                              if (enmienda.length <= 0) {
                                toastr.error('Debe agregar al menos una enmienda, favor de revisar');
                                return;
                              }
                              var ttSeguimientoTarea = {
                                enmienda: angular.copy($scope.enmienda),
                                codigo: $scope.codigo
                              };
                              data.ttSeguimientoTarea = ttSeguimientoTarea;
                              utilsService.setEsPrevia(true);
                              utilsService.generarEnmiendaTurno(data, $scope.loginData.usuario, $scope.dataEmpresa);                              
                            }

                            $scope.aceptarRequisito = function (isValid) {
                              if (isValid) {
                                var ttTarea = angular.copy($scope.dataTask);
                                var enmienda = angular.copy($scope.enmienda);
                                if (enmienda == null) {
                                  enmienda = [];
                                }
                                if (enmienda.length <= 0) {
                                  toastr.error('Debe agregar al menos una enmienda, favor de revisar');
                                  return;
                                }
                                swal({
                                  title: "Las enmiendas no deben ser modificación al plan de manejo, solo corregir archivos cargados",
                                  text: "¿Está seguro (a) de realizar la solicitu de enmiendas? Esta acción no se podrá reversar",
                                  icon: "warning",
                                  type: "warning",
                                  showCancelButton: true,
                                  confirmButtonClass: "btn-success",
                                  cancelButtonClass: "btn-danger",
                                  confirmButtonText: "Confirmar",
                                  cancelButtonText: "Cancelar",
                                  closeOnConfirm: true,
                                  closeOnCancel: true
                                },
                                  function (isConfirm) {
                                    if (isConfirm) {
                                      var ttSeguimientoTarea = {
                                        enmienda: JSON.stringify(enmienda),
                                        codigo: $scope.codigo
                                      };
                                      ttTarea.ttSeguimientoTarea = ttSeguimientoTarea;
                                      delete ttTarea.ttGestion;
                                      delete ttTarea.ttPadre;
                                      tareaService.solicitarEnmiendaRequisitosLicencia(ttTarea).then(function (res) {
                                        if (res.status == 1) {
                                          ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                                          ttTarea.ttGestion = $scope.dataTask.ttGestion;
                                          utilsService.generarEnmiendaTurno(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                                          ngDialog.close();
                                          $state.go('index.tarea.list');
                                        } else {
                                          toastr.error(res.message);
                                        }
                                      }, function (error) {
                                        toastr.error(error);
                                      });
                                    }
                                  }
                                );
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    } //fin funcion enmienda

                  }]
              }
            }
          })
          .state('index.realizar.solicaval', {
            url: '/solitaraval',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.solicaval.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.get($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', '$timeout', 'catalogosService', 'montogarantiaService', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, $timeout, catalogosService, montogarantiaService, dataTarea) {
                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    $scope.dataRegente = [];
                    $scope.dataMontoGarantia = [];
                    $scope.bndGarantia = false;
                    $scope.admision = {};
                    $scope.textoBoton = "Vista previa";

                    $scope.validarTipoGarantia = function () {
                      //si es garantia fiduciaria mostrar opciones para enviar a subregional
                      if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
                        || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7
                        || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 9) {
                        //Compromiso de repoblacion - b. Estimación del monto del compromiso
                        var dataCompromisoTmp = angular.copy($scope.dataTask.ttGestion.rodal);
                        var dataCompromiso = [];
                        var i = 0;
                        //obtener datos del primer turno
                        for (i = 0; i < dataCompromisoTmp.length; i++) {
                          if (parseInt(dataCompromisoTmp[i].turno) == 1) {
                            dataCompromiso.push(dataCompromisoTmp[i]);
                          }
                        }
                        //si tratamientoSilviculturalId == 2 en todas las especies será corta total de lo contrario corta selectiva
                        var montoUtilizar = 0;
                        for (i = 0; i < $scope.dataMontoGarantia.length; i++) {
                          if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == $scope.dataMontoGarantia[i].tcTipoGarantia.tipoGarantiaId) {
                            if ($scope.dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                              montoUtilizar = $scope.dataMontoGarantia[i].monto;
                              break;
                            }
                          }
                        }
                        var volumenTotal = 0;
                        var abrTotal = 0, abrExtraerTotal = 0;
                        for (i = 0; i < dataCompromiso.length; i++) { //rodales
                          var abrExtraer = 0;
                          var abr = 0;
                          for (j = 0; j < dataCompromiso[i].especies.length; j++) {//especie por rodal
                            abrExtraer = abrExtraer + parseFloat(dataCompromiso[i].especies[j].abrExtraer);
                            abr = abr + parseFloat(dataCompromiso[i].especies[j].abr);
                            volumenTotal = volumenTotal + parseFloat(dataCompromiso[i].especies[j].volrExtraer);
                          }
                          dataCompromiso[i].abr = abr;
                          dataCompromiso[i].abrExtraer = abrExtraer;
                          abrTotal += abr;
                          abrExtraerTotal += abrExtraer;
                        }
                        var porcentaje = 0;
                        porcentaje = abrExtraerTotal * 100;
                        porcentaje = porcentaje / abrTotal;
                        porcentaje = Math.round(porcentaje);
                        var cortaFinal = true;
                        var areaMonto = 0;
                        var montoCompromiso = 0;
                        var area = 0;
                        var montoArea = 0;
                        var montoGarantia = 0;
                        var calculoMonto = 'área por área';
                        var hayFueraBosque = false;
                        for (i = 0; i < dataCompromiso.length; i++) {
                          areaMonto = 0;
                          montoCompromiso = 0;
                          dataCompromiso[i].areaMonto = dataCompromiso[i].areaGarantia;
                          if (dataCompromiso[i].tcTipoCobertura.tipoCoberturaId == 3) {
                            calculoMonto = 'área basal ideal';
                            hayFueraBosque = true;
                            dataCompromiso[i].areaCompromiso = dataCompromiso[i].areaGarantia;
                          } else {
                            dataCompromiso[i].areaCompromiso = dataCompromiso[i].area;
                            if (porcentaje < appSettings.extraccionMinima) {
                              if (!hayFueraBosque) {
                                calculoMonto = 'área basal';
                              }
                            }
                          }
                          areaMonto = parseFloat(dataCompromiso[i].areaMonto);
                          area = area + areaMonto;
                          montoGarantia = areaMonto * montoUtilizar;
                          montoGarantia = parseFloat(montoGarantia).toFixed(2);
                          dataCompromiso[i].montoCompromiso = montoGarantia;
                          montoArea = montoArea + parseFloat(dataCompromiso[i].montoCompromiso);
                          dataCompromiso[i].metodoCalculoDesc = calculoMonto;
                        }
                        if ($scope.dataTask.ttSeguimientoTarea == null) {
                          $scope.dataTask.ttSeguimientoTarea = {};
                        }
                        $scope.dataTask.ttSeguimientoTarea.area = parseFloat(area).toFixed(4);
                        $scope.dataTask.ttSeguimientoTarea.monto = parseFloat(montoArea).toFixed(2);
                        $scope.dataTask.ttSeguimientoTarea.volumen = parseFloat(volumenTotal).toFixed(4);
                      }
                    }

                    if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
                      || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7
                      || $scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 9) {
                      montogarantiaService.listaByTipoGarantia($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId).then(function (res) {
                        if (res.status == "OK") {
                          $scope.dataMontoGarantia = res.data;
                          $scope.validarTipoGarantia();
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }

                    $scope.nombreFiador = $scope.dataTask.ttGestion.ttResumenGestion.nombreFiador;
                    if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 9) {
                      $scope.nombreFiador = $scope.dataTask.ttGestion.ttResumenGestion.rnf;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                          return;
                        }
                      }
                      if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 9) {
                        if ($scope.dataTask.ttGestion.ttResumenGestion.nombreEmpresa == null || $scope.dataTask.ttGestion.ttResumenGestion.nombreEmpresa.length <= 0) {
                          toastr.error('Debe indicar el nombre de la empresa');
                          return;
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      utilsService.generarSolicitudAval($scope.dataTask, 1, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, 1);
                      if (refresh) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.asignarExpediente = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de oficio para la solicitud de aval de garantía');
                          return;
                        }
                      }
                      if ($scope.dataTask.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 9) {
                        if ($scope.dataTask.ttGestion.ttResumenGestion.nombreEmpresa == null || $scope.dataTask.ttGestion.ttResumenGestion.nombreEmpresa.length <= 0) {
                          toastr.error('Debe indicar el nombre de la empresa');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de realizar la solicitar el AVAL de la garantía? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttGestion.rodal;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.solicitarAval(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success("La asignación se ha efectuado con éxito");
                                $scope.mostrarAsignar = false;
                                $scope.vistaPreviaAsignacion(true);
                                $scope.textoCancelar = 'Finalizar';
                              } else {
                                toastr.error("Error al realizar la asignación " + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.finalizarTarea = function () {
                      $state.go('index.tarea.list');
                    }

                  }]
              }
            }
          })
          .state('index.realizar.solicitudpoa', {
            url: '/solicitudpoa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.solicitudpoa.tpl.html',
                resolve: {
                  dataSolicitud: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.getDatosTareaSolicitudPoa($base64.decode($stateParams.tareaId));
                    }],
                  dataMontoGarantia: ['montogarantiaService', 'dataSolicitud', '$stateParams',
                    function (montogarantiaService, dataSolicitud, $stateParams) {
                      var data = dataSolicitud.data[0];
                      return montogarantiaService.listaByTipoGarantia(data.tcTipoGarantia.tipoGarantiaId);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'valortipomaderaService', 'ngDialog', 'tareaService', 'utilsService', '$filter', 'dataSolicitud', 'dataMontoGarantia',
                  function ($scope, $state, toastr, valortipomaderaService, ngDialog, tareaService, utilsService, $filter, dataSolicitud, dataMontoGarantia) {
                    if (dataSolicitud.status != 'OK') {
                      toastr.error(dataSolicitud.message);
                      $state.go('index.tarea.list');
                      return;
                    }
                    $scope.dataJuridico = [];
                    $scope.dataTecnico = [];
                    $scope.dataSolicitud = dataSolicitud.data[0];
                    $scope.dataTask = $scope.dataSolicitud.ttTarea;
                    $scope.ttGestion = $scope.dataSolicitud.ttGestion;
                    $scope.showGarantia = false;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    if ($scope.dataSolicitud.tcTipoGarantia != null) {
                      if ($scope.dataSolicitud.tcTipoGarantia.tipoGarantiaId == 1
                        || $scope.dataSolicitud.tcTipoGarantia.tipoGarantiaId == 7) {
                        $scope.showGarantia = true;
                      }
                    }
                    var montoUtilizar = 0;
                    var dataMontoGarantia = dataMontoGarantia.data;
                    for (var i = 0; i < dataMontoGarantia.length; i++) {
                      if (dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                        montoUtilizar = dataMontoGarantia[i].monto;
                        break;
                      }
                    }
                    var rodal = $scope.ttGestion.rodal;
                    var volumen = 0;
                    var areaGarantia = 0;
                    var listaSinFiltrar = [];
                    for (var i = 0; i < rodal.length; i++) {
                      areaGarantia += parseFloat(rodal[i].areaGarantia);
                      var especies = rodal[i].especies;
                      for (var j = 0; j < especies.length; j++) {
                        var especie = especies[j];
                        volumen += parseFloat(especie.volrExtraer);
                        listaSinFiltrar.push(especie.tcEspecie);
                      }
                    }
                    if (!$scope.showGarantia) {
                      $scope.dataValorMadera = [];
                      var lista = [];
                      for (var i = 0; i < listaSinFiltrar.length; i++) {
                        var especie = listaSinFiltrar[i];
                        var encontrado = false;
                        for (var j = 0; j < lista.length; j++) {
                          if (especie.especieId == lista[j].especieId) {
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          lista.push(especie);
                        }
                      }
                      var fecha = new Date();
                      var anio = fecha.getFullYear();
                      var filtro = {
                        anio: anio,
                        tcRegion: $scope.dataTask.ttGestion.tcSubregion.tcRegion,
                        especies: lista
                      }
                      valortipomaderaService.listaCostoTipoMaderaAnio(filtro).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var valor = data.valor;
                          especies = data.especies;
                          for (j = 0; j < especies.length; j++) {
                            for (i = 0; i < valor.length; i++) {
                              if (valor[i].tcTipoMadera.tipoMaderaId == especies[j].tcTipoMadera.tipoMaderaId) {
                                especies[j].tcValorTipoMadera = valor[i];
                                break;
                              }
                            }
                          }
                          $scope.dataValorMadera = especies;
                          var valor = 0, valorTurno = 0;
                          var item = null;
                          var row = null;
                          //buscar valor y calcular
                          data = $scope.ttGestion.rodal;
                          for (i = 0; i < data.length; i++) { //rodales
                            for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                              item = null;
                              for (var k = 0; k < especies.length; k++) {
                                row = especies[k];
                                if (parseInt(row.especieId) == parseInt(data[i].especies[j].tcEspecie.especieId)) {
                                  item = especies[k].tcValorTipoMadera;
                                  break;
                                }
                              }
                              if (item == null) {
                                swal('La especie ' + data[i].especies[j].tcEspecie.nombreCientifico + ' no tiene valor asignado, favor de indicar al administrador del sistema');
                                return;
                              }
                              valor = parseFloat(data[i].especies[j].troza) * parseFloat(item.troza);
                              data[i].especies[j].ttEspecieEvaluada.trozaValor = parseFloat(valor).toFixed(2);
                              valor = parseFloat(data[i].especies[j].lenia) * parseFloat(item.lenia);
                              data[i].especies[j].ttEspecieEvaluada.leniaValor = parseFloat(valor).toFixed(2);
                              valor = parseFloat(data[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(data[i].especies[j].ttEspecieEvaluada.leniaValor);
                              valor = parseFloat(valor).toFixed(2);
                              data[i].especies[j].totalValor = valor;
                              valor = valor * 0.1;
                              valorTurno += valor;
                              valor = parseFloat(valor).toFixed(2);
                              data[i].especies[j].totalPagar = valor;
                            }
                          }
                          $scope.valorTurno = parseFloat(valorTurno).toFixed(2);
                          $scope.ttGestion.rodal = data;
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    }
                    areaGarantia = parseFloat(areaGarantia).toFixed(4);
                    var montoGarantia = areaGarantia * montoUtilizar;
                    $scope.dataTask.ttSeguimientoTarea.volumen = parseFloat(volumen).toFixed(4);
                    $scope.dataTask.ttSeguimientoTarea.area = areaGarantia;
                    if ($scope.showGarantia) {
                      var monto = parseFloat(montoUtilizar) * areaGarantia;
                      $scope.dataTask.ttSeguimientoTarea.monto = parseFloat(monto).toFixed(2);
                    } else {
                      $scope.dataTask.ttSeguimientoTarea.monto = parseFloat(montoUtilizar);
                    }

                    $scope.vistaPrevia = function (fin) {
                      if (fin == null) {
                        fin = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error('Debe indicar el número de oficio');
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      var ttGestion = angular.copy($scope.ttGestion);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      data.aprobado = 1;
                      data.ttGestion = ttGestion;
                      if ($scope.showGarantia) {
                        utilsService.setEsPrevia(true);
                        utilsService.generarSolicitudAval(data, $scope.dataSolicitud.numero, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, $scope.dataSolicitud);
                      } else {
                        data.aprobado = 1;
                        var ttSolicitudPoa = $scope.dataSolicitud;
                        data.fechaFinalizacion = new Date();
                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                        var strDiaResolucion = $scope.numeroALetras(dia);
                        var strAnioResolucion = $scope.numeroALetras(anio, false);
                        ttSolicitudPoa.ttTarea = data;
                        utilsService.setEsPrevia(true);
                        utilsService.generarOficioRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (fin) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isAmendment, tmpData) {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        toastr.error(texto);
                        return;
                      } else {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error(texto);
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar, es decir que no se puede regresar la tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var ttGestion = angular.copy($scope.ttGestion);
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = 1;
                            data.tcPersonaAsignada.foto = null;
                            data.tcPersonaMonitoreo = null;
                            var ttSolicitudPoa = angular.copy($scope.dataSolicitud);
                            if ($scope.showGarantia) {
                              delete data.ttGestion;
                              delete ttSolicitudPoa.ttGestion;
                              ttSolicitudPoa.ttTarea = data;
                              tareaService.solicitarAvalPoaSiguiente(ttSolicitudPoa).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success("La asignación se ha efectuado con éxito");
                                  $scope.vistaPrevia(true);
                                } else {
                                  toastr.error("Error al realizar la asignación " + res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              ttSolicitudPoa.ttTarea = data;
                              tareaService.solicitarRequisitosPoa(ttSolicitudPoa).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPrevia(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.requisitopoa', {
            url: '/requisitopoa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.requisitopoa.tpl.html',
                resolve: {
                  dataSolicitud: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.getTareaRequisitoPoa($base64.decode($stateParams.tareaId));
                    }],
                  dataMontoGarantia: ['montogarantiaService', 'dataSolicitud', '$stateParams',
                    function (montogarantiaService, dataSolicitud, $stateParams) {
                      var data = dataSolicitud.data[0];
                      return montogarantiaService.listaByTipoGarantia(data.tcTipoGarantia.tipoGarantiaId);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', 'dataSolicitud', 'dataMontoGarantia', 'valortipomaderaService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, dataSolicitud, dataMontoGarantia, valortipomaderaService) {
                    if (dataSolicitud.status != 'OK') {
                      toastr.error(dataSolicitud.message);
                      $state.go('index.tarea.list');
                      return;
                    }
                    $scope.dataSolicitud = dataSolicitud.data[0];
                    $scope.dataTask = $scope.dataSolicitud.ttTarea;
                    $scope.ttGestion = $scope.dataSolicitud.ttGestion;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    if ($scope.dataSolicitud.tcTipoGarantia != null) {
                      if ($scope.dataSolicitud.tcTipoGarantia.tipoGarantiaId == 1
                        || $scope.dataSolicitud.tcTipoGarantia.tipoGarantiaId == 7) {
                        $scope.showGarantia = true;
                      }
                    }
                    var montoUtilizar = 0;
                    var dataMontoGarantia = dataMontoGarantia.data;
                    for (var i = 0; i < dataMontoGarantia.length; i++) {
                      if (dataMontoGarantia[i].tcTipoMonto.tipoMontoId == 1) {
                        montoUtilizar = dataMontoGarantia[i].monto;
                        break;
                      }
                    }
                    $scope.dataTask.ttSeguimientoTarea.monto = montoUtilizar;
                    $scope.dataValorMadera = [];
                    //varor de la madera en pie
                    var rodal = $scope.ttGestion.rodal;
                    var data = rodal;
                    var lista = [];
                    //buscar especie por rodal y obtener el precio a través del servicio, segun año y region
                    for (i = 0; i < data.length; i++) { //rodales
                      for (j = 0; j < data[i].especies.length; j++) {
                        lista.push(data[i].especies[j].tcEspecie);
                      }
                    }
                    var fecha = new Date();
                    var anio = fecha.getFullYear();
                    var filtro = {
                      anio: anio,
                      tcRegion: $scope.dataTask.ttGestion.tcSubregion.tcRegion,
                      especies: lista
                    }
                    valortipomaderaService.listaCostoTipoMaderaAnio(filtro).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var valor = data.valor;
                        especies = data.especies;
                        for (j = 0; j < especies.length; j++) {
                          for (i = 0; i < valor.length; i++) {
                            if (valor[i].tcTipoMadera.tipoMaderaId == especies[j].tcTipoMadera.tipoMaderaId) {
                              especies[j].tcValorTipoMadera = valor[i];
                              break;
                            }
                          }
                        }
                        $scope.dataValorMadera = especies;
                        var valor = 0, valorTurno = 0;
                        var item = null;
                        var row = null;
                        //buscar valor y calcular
                        data = $scope.ttGestion.rodal;
                        for (i = 0; i < data.length; i++) { //rodales
                          for (j = 0; j < data[i].especies.length; j++) {//especie por rodal
                            item = null;
                            for (var k = 0; k < especies.length; k++) {
                              row = especies[k];
                              if (parseInt(row.especieId) == parseInt(data[i].especies[j].tcEspecie.especieId)) {
                                item = especies[k].tcValorTipoMadera;
                                break;
                              }
                            }
                            if (item == null) {
                              swal('La especie ' + data[i].especies[j].tcEspecie.nombreCientifico + ' no tiene valor asignado, favor de indicar al administrador del sistema');
                              return;
                            }
                            valor = parseFloat(data[i].especies[j].troza) * parseFloat(item.troza);
                            data[i].especies[j].ttEspecieEvaluada.trozaValor = parseFloat(valor).toFixed(2);
                            valor = parseFloat(data[i].especies[j].lenia) * parseFloat(item.lenia);
                            data[i].especies[j].ttEspecieEvaluada.leniaValor = parseFloat(valor).toFixed(2);
                            valor = parseFloat(data[i].especies[j].ttEspecieEvaluada.trozaValor) + parseFloat(data[i].especies[j].ttEspecieEvaluada.leniaValor);
                            valor = parseFloat(valor).toFixed(2);
                            data[i].especies[j].totalValor = valor;
                            valor = valor * 0.1;
                            valorTurno += valor;
                            valor = parseFloat(valor).toFixed(2);
                            data[i].especies[j].totalPagar = valor;
                          }
                        }
                        $scope.valorTurno = parseFloat(valorTurno).toFixed(2);
                        $scope.ttGestion.rodal = data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    //fin valor de madera
                    var volumen = 0;
                    var areaGarantia = 0;
                    for (var i = 0; i < rodal.length; i++) {
                      if (rodal[i].turno == $scope.dataSolicitud.numero) {
                        areaGarantia += parseFloat(rodal[i].areaGarantia);
                        var especies = rodal[i].especies;
                        for (var j = 0; j < especies.length; j++) {
                          var especie = especies[j];
                          volumen += parseFloat(especie.volrExtraer);
                        }
                      }
                    }
                    var montoGarantia = areaGarantia * montoUtilizar;
                    $scope.dataTask.ttSeguimientoTarea.volumen = volumen;
                    $scope.dataTask.ttSeguimientoTarea.area = areaGarantia;
                    $scope.dataTask.ttSeguimientoTarea.monto = montoUtilizar;

                    $scope.vistaPreviaRequisito = function (fin) {
                      if (fin == null) {
                        fin = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error('Debe indicar el número de oficio');
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      var ttGestion = angular.copy($scope.ttGestion);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      data.fechaFinalizacion = new Date();
                      data.aprobado = 1;
                      data.ttGestion = ttGestion;
                      var ttSolicitudPoa = $scope.dataSolicitud;
                      ttSolicitudPoa.ttTarea = data;
                      var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                      var strDiaResolucion = $scope.numeroALetras(dia);
                      var strAnioResolucion = $scope.numeroALetras(anio, false);
                      utilsService.generarOficoRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (fin) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isAmendment, tmpData) {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        toastr.error(texto);
                        return;
                      } else {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error(texto);
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var ttGestion = angular.copy($scope.ttGestion);
                            delete data.ttGestion;
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.fechaFinalizacion = new Date();
                            data.aprobado = 1;
                            var ttSolicitudPoa = angular.copy($scope.dataSolicitud);
                            ttSolicitudPoa.ttGestion = ttGestion;
                            ttSolicitudPoa.ttTarea = data;
                            tareaService.solicitarRequisitosPoa(ttSolicitudPoa).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success(res.message);
                                $scope.vistaPreviaRequisito(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.srinforme', {
            url: '/srinforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.providencia.informe.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataInforme: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getProvidenciaInforme($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', '$timeout', 'catalogosService', 'dataInforme', 'dataTecnico',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, $timeout, catalogosService, dataInforme, dataTecnico) {

                    $scope.dataInforme = dataInforme.data[0];
                    $scope.dataTask = dataInforme.data[0].ttTarea;
                    $scope.admision = {};
                    $scope.textoBoton = "Vista previa";

                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }

                    $scope.dataTecnico = dataTecnico.data;

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (isNotPreview) {
                      if ($scope.mostrarAsignar) {
                        if ($scope.dataTask.tcPersonaTraslado == null || $scope.dataTask.tcPersonaJuridico == null || $scope.dataTask.ttSeguimientoTarea.providencia == null) {
                          toastr.error("Debe seleccionar a la persona que asinará la tarea e indicar el número de providencia");
                          return;
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      var dataInforme = $scope.dataInforme;
                      dataInforme.ttTarea = $scope.dataTask;
                      utilsService.generarProvidenciaInforme(dataInforme, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (isNotPreview) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.asignarExpediente = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de trasladar el informe al Técnico forestal? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttGestion.rodal;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.asignarProvidenciaInforme(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success("La asignación se ha efectuado con éxito");
                                $scope.vistaPreviaAsignacion(true);
                              } else {
                                toastr.error("Error al realizar la asignación " + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.finalizarTarea = function () {
                      $state.go('index.tarea.list');
                    }

                  }]
              }
            }
          })
          .state('index.realizar.avalinforme', {
            url: '/avalinforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.aval.informe.tpl.html',
                resolve: {
                  dataInforme: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaInforme($base64.decode($stateParams.tareaId));
                    }],
                  dataMunicipio: ['municipioService', '$stateParams',
                    function (municipioService, $stateParams) {
                      return municipioService.getPorPais(1);
                    }],
                  dataProductoTransporte: ['productotransporteService', '$stateParams',
                    function (productotransporteService, $stateParams) {
                      return productotransporteService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', '$timeout',
                  'catalogosService', 'dataInforme', 'dataMunicipio', 'dataProductoTransporte',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, $timeout,
                    catalogosService, dataInforme, dataMunicipio, dataProductoTransporte) {

                    $scope.ttInformeGestion = dataInforme.data[0];
                    $scope.verificacionIgual = 1;
                    $scope.dataTask = dataInforme.data[0].ttTarea;
                    $scope.admision = {};
                    $scope.textoBoton = "Vista previa";
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }
                    $scope.dataMunicipio = dataMunicipio.data;
                    $scope.dataProductoTransporte = dataProductoTransporte.data;
                    $scope.dataRendimiento = [
                      { factorRendimientoId: 0, factorRendimientoDesc: 'Usar los oficiales' },
                      { factorRendimientoId: 1, factorRendimientoDesc: 'Usar mi factor autorizado' }
                    ];
                    $scope.pagina = 1;
                    $scope.totalPaginas = 1;

                    $scope.dataTipoSierra = [
                      { tipoSierraId: 1, tipoSierraDesc: 'Banda' },
                      { tipoSierraId: 2, tipoSierraDesc: 'Circular' },
                      { tipoSierraId: 3, tipoSierraDesc: 'Motosierra' }
                    ];

                    $scope.dataSierra = [
                      { otraSierraId: 0, otraSierraDesc: 'Sin sierra secundaria' },
                      { otraSierraId: 1, otraSierraDesc: 'Con sierra secundaria' }
                    ];

                    $scope.nombreTrimestre = function (trimestre) {
                      switch (trimestre) {
                        case 1: return 'Primer trimestre';
                        case 2: return 'Segundo trimestre';
                        case 3: return 'Tercer trimestre';
                        case 4: return 'Cuarto trimestre';
                      }
                    }

                    $scope.tabVisible = {
                      cero: true,
                      uno: false,
                      dos: false,
                      tres: false
                    }

                    $scope.tabActive = {
                      cero: true,
                      uno: false,
                      dos: false,
                      tres: false
                    }

                    $scope.setPaginaMostar = function () {
                      var notas = $scope.ttInformeGestion.notas;
                      var totalPaginas = notas.length % 10;
                      var notasMostrar = [];
                      if (totalPaginas >= $scope.pagina) {
                        var fin = $scope.pagina * 10;
                        var inicio = fin - 10;
                        if (fin > notas.length) {
                          fin = notas.length;
                        }
                        for (var i = inicio; i < fin; i++) {
                          if (notas[i].mostrarProducto == undefined) {
                            notas[i].mostrarProducto = false;
                            notas[i].mostrarMotivo = false;
                          }
                          if (notas[i].usoId == undefined) {
                            notas[i].usoId = 1;
                          }
                          notasMostrar.push(notas[i]);
                        }
                        $scope.textoMostrando = $scope.pagina + " de " + totalPaginas;
                        $scope.totalPaginas = totalPaginas;
                        $scope.notas = notasMostrar;
                      }
                    }

                    $scope.backpage = function () {
                      if ($scope.pagina > 1) {
                        $scope.pagina--;
                        $scope.setPaginaMostar();
                      }
                    }

                    $scope.nextpage = function () {
                      if ($scope.pagina < $scope.totalPaginas) {
                        $scope.pagina++;
                        var notas = $scope.notas;
                        for (var i = 0; i < notas.length; i++) {
                          if (notas[i].usoId == 1 && (notas[i].mostrarProducto == true || notas[i].mostrarMotivo)) {
                            if (notas[i].fechaNota == null || notas[i].tcMunicipio.municipioId == null || notas[i].destino == null || notas[i].especies.length <= 0) {
                              toastr.error('Para avanzar debe indicar el uso o los datos de cada nota');
                              return;
                            }
                          }
                        }
                        $scope.setPaginaMostar();
                      }
                    }

                    var especies = $scope.ttInformeGestion.especies;
                    encontrado = false;
                    var catalogo = [];
                    for (var i = 0; i < especies.length; i++) {
                      var tcEspecie = especies[i].tcEspecie;
                      for (var k = 0; k < catalogo.length; k++) {
                        if (catalogo[k].especieId == tcEspecie.especieId) {
                          encontrado = true;
                          break;
                        }
                      }
                      if (!encontrado) {
                        catalogo.push(tcEspecie);
                      }
                    }
                    $scope.dataEspecie = catalogo;

                    var correlativoIni = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoIni);
                    var correlativoFin = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoFin);
                    var correlativoIni2 = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoIni2);
                    var correlativoFin2 = parseInt($scope.ttInformeGestion.ttPoaGestion.correlativoFin2);
                    var notas = [];
                    for (var i = correlativoIni; i <= correlativoFin; i++) {
                      var item = {
                        numero: i
                      }
                      notas.push(item);
                    }
                    if (correlativoIni != correlativoIni2) {
                      for (var i = correlativoIni2; i <= correlativoFin2; i++) {
                        var item = {
                          numero: i
                        }
                        notas.push(item);
                      }
                    }
                    if ($scope.ttInformeGestion.notas != null) {
                      var existe = $scope.ttInformeGestion.notas;
                      for (var i = 0; i < existe.length; i++) {
                        for (var j = 0; j < notas.length; j++) {
                          if (notas[j].numero == existe[i].numero) {
                            notas[j] = existe[i];
                            notas[j].verificacionIgual = 1;
                            if (notas[j].fechaNota != null) {
                              var parts = notas[j].fechaNota.toString().split('-');
                              var mydate = new Date(parts[0], parseInt(parts[1]) - 1, parts[2]);
                              notas[j].fechaNota = new Date(mydate);
                            }
                            if (existe[i].usoId == 1) {
                              existe[i].mostrarProducto = true;
                              existe[i].mostrarMotivo = false;
                            }
                            if (existe[i].usoId == 0) {
                              existe[i].motivoTitulo = "anulación";
                              existe[i].mostrarProducto = false;
                              existe[i].mostrarMotivo = true;
                            }
                            if (existe[i].usoId == 2) {
                              existe[i].motivoTitulo = "extravío";
                              existe[i].mostrarProducto = false;
                              existe[i].mostrarMotivo = true;
                            }
                          }
                        }
                      }
                    }
                    $scope.ttInformeGestion.notas = notas;
                    $scope.dataNotas = angular.copy(notas);
                    $scope.setPaginaMostar();

                    $scope.setEspecieNota = function (item, nota, producto) {
                      producto.tcEspecie = item;
                    }

                    $scope.aplicaMismosDatos = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        for (var i = 0; i < $scope.dataNotas.length; i++) {
                          if ($scope.dataNotas[i].numero == nota.numero) {
                            var tmpNota = angular.copy($scope.dataNotas[i]);
                            nota.destino = tmpNota.destino;
                            nota.fechaNota = new Date(tmpNota.fechaNota);
                            nota.mostrarMotivo = tmpNota.mostrarMotivo;
                            nota.mostrarProducto = tmpNota.mostrarProducto;
                            nota.motivo = tmpNota.motivo;
                            nota.tcMunicipio = tmpNota.tcMunicipio;
                            nota.usoId = tmpNota.usoId;
                            if (tmpNota.especies != null) {
                              var especies = tmpNota.especies;
                              nota.especies = [];
                              for (var j = 0; j < especies.length; j++) {
                                nota.especies.push(especies[j]);
                              }
                            }
                            break;
                          }
                        }
                      }
                    }

                    $scope.agregarProducto = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible agregar productos porque está indicando que aplican los datos que el solicitante o elaborador indicó');
                        return;
                      }
                      if (nota.especies == null) {
                        nota.especies = [];
                      }
                      nota.especies.push(
                        { volumenReportar: 0, volumen: 0 }
                      );
                      nota.usoId = 1;
                      nota.mostrarProducto = true;
                      nota.mostrarMotivo = false;
                    }

                    $scope.quitarProducto = function (nota, item) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible quitar productos porque está indicando que aplican los datos que el solicitante o elaborador indicó');
                        return;
                      }
                      nota.especies.splice(nota.especies.indexOf(item), 1);
                      if (nota.especies.length <= 0) {
                        nota.mostrarProducto = false;
                        nota.mostrarMotivo = false;
                      }
                    }

                    $scope.setProductoTransportado = function (item, producto) {
                      producto.tcProductoTransporte = item;
                    }

                    $scope.setVolumenProducto = function (producto, nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible cambiar los valores porque está indicando que aplican los datos que el solicitante o elaborador indicó');
                        return;
                      }
                      if (producto.tcProductoTransporte == null || producto.tcProductoTransporte.productoTransporteId == null) {
                        producto.volumen = null;
                        toastr.error('Debe indicar el producto a trasportar');
                        return;
                      }
                      producto.volumenReportar = producto.volumen;
                      var factor = 0;
                      if (producto.tcProductoTransporte.productoTransporteId == 2) {
                        if ($scope.ttInformeGestion.aplicaFactora == 1) {
                          factor = parseFloat($scope.ttInformeGestion.factorAserrada);
                          if (factor > 1) {
                            factor = factor / 100;
                          }
                          if (factor == 0) {
                            factor = parseFloat(producto.volumen);
                          } else {
                            factor = parseFloat(producto.volumen) / factor;
                          }
                          producto.volumenReportar = parseFloat(factor).toFixed(2);
                        } else {
                          if ($scope.ttInformeGestion.tipoSierraId == 1) {
                            if ($scope.ttInformeGestion.otraSierraId == 0) {
                              factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                            } else {
                              factor = parseFloat(producto.tcProductoTransporte.rendimientoConSierrab);
                            }
                          }
                          if ($scope.ttInformeGestion.tipoSierraId == 2) {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoSinSierrab);
                          }
                          if ($scope.ttInformeGestion.tipoSierraId == 3) {
                            factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                          }
                          if (factor == 0) {
                            factor = parseFloat(producto.volumen);
                          } else {
                            factor = parseFloat(producto.volumen) / factor;
                          }
                          producto.volumenReportar = parseFloat(factor).toFixed(2);
                        }
                      }
                      if (producto.tcProductoTransporte.productoTransporteId == 4) {
                        if ($scope.ttInformeGestion.aplicaFactorc == 1) {
                          factor = parseFloat($scope.ttInformeGestion.factorCarbon);
                          if (factor > 1) {
                            factor = factor / 100;
                          }
                          if (factor == 0) {
                            factor = parseFloat(producto.volumen);
                          } else {
                            factor = parseFloat(producto.volumen) / factor;
                          }
                          producto.volumenReportar = parseFloat(factor).toFixed(2);
                        } else {
                          factor = parseFloat(producto.tcProductoTransporte.rendimientoMotosierra);
                          if (factor == 0) {
                            factor = parseFloat(producto.volumen);
                          } else {
                            factor = parseFloat(producto.volumen) / factor;
                          }
                          producto.volumenReportar = parseFloat(factor).toFixed(2);
                        }
                      }
                    }

                    $scope.marcarAnulado = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible anular la nota porque está indicando que aplican los datos que el solicitante o elaborador ingresó');
                        return;
                      }
                      nota.usoId = 0;
                      nota.motivoTitulo = "anulación";
                      nota.mostrarProducto = false;
                      nota.mostrarMotivo = true;
                    }

                    $scope.marcarExtraviado = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible marcar como extraviada la nota porque está indicando que aplican los datos que el solicitante o elaborador ingresó');
                        return;
                      }
                      nota.usoId = 2;
                      nota.motivoTitulo = "extravío";
                      nota.mostrarProducto = false;
                      nota.mostrarMotivo = true;
                    }

                    $scope.marcarNoUsado = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es posible la nota como no usado, porque está indicando que aplican los datos que el solicitante o elaborador ingresó');
                        return;
                      }
                      nota.usoId = 3;
                      nota.mostrarProducto = false;
                      nota.mostrarMotivo = false;
                    }

                    $scope.limpiarProductos = function (nota) {
                      if (nota.verificacionIgual == 1) {
                        toastr.error('No es limpiar productos porque está indicando que aplican los datos que el solicitante o elaborador ingresó');
                        return;
                      }
                      if (nota.usoId == 1) {
                        nota.especies = [];
                      } else {
                        toastr.warning('No hay especies involucradas a esta nota');
                      }
                    }

                    $scope.submitFormNotas = function (isValid) {
                      isValid = true;
                      var enviar = [];
                      var notaValidada = [];
                      for (var i = 0; i < $scope.ttInformeGestion.notas.length; i++) {
                        var nota = $scope.ttInformeGestion.notas[i];
                        if (nota.verificacionIgual == 1) {
                          for (var i = 0; i < $scope.dataNotas.length; i++) {
                            if ($scope.dataNotas[i].numero == nota.numero) {
                              var tmpNota = angular.copy($scope.dataNotas[i]);
                              nota.destino = tmpNota.destino;
                              nota.fechaNota = new Date(tmpNota.fechaNota);
                              nota.mostrarMotivo = tmpNota.mostrarMotivo;
                              nota.mostrarProducto = tmpNota.mostrarProducto;
                              nota.motivo = tmpNota.motivo;
                              nota.tcMunicipio = tmpNota.tcMunicipio;
                              nota.usoId = tmpNota.usoId;
                              if (tmpNota.especies != null) {
                                var especies = tmpNota.especies;
                                nota.especies = [];
                                for (var j = 0; j < especies.length; j++) {
                                  nota.especies.push(especies[j]);
                                }
                              }
                              enviar.push(nota);
                              break;
                            }
                          }
                        } else {
                          if (nota.mostrarProducto != null) {
                            if (nota.mostrarProducto) {
                              if (nota.fechaNota == null || nota.destino == null) {
                                isValid = false;
                                break;
                              } else {
                                if (nota.tcMunicipio == null) {
                                  isValid = false;
                                  break;
                                } else {
                                  if (nota.tcMunicipio.municipioId == null) {
                                    isValid = false;
                                    break;
                                  }
                                }
                              }
                            }
                            if (nota.mostrarMotivo) {
                              if (nota.motivo == null) {
                                isValid = false;
                                break;
                              }
                            }
                            if (nota.mostrarProducto || nota.mostrarMotivo) {
                              notaValidada.push(nota);
                            }
                          }
                        }
                      }
                      if (isValid) {
                        var data = angular.copy($scope.ttInformeGestion);
                        delete data.ttGestion;
                        delete data.ttPoaGestion;
                        delete data.especies;
                        delete data.tcPersonaCrea.foto;
                        data.notas = enviar;
                        data.notaValidada = notaValidada;
                        gestionService.actualizarNotaVerificada(data).then(function (res) {
                          if (res.status == "OK") {
                            toastr.success(res.message);
                            $scope.tabActive.uno = true;
                            $scope.tabVisible.uno = true;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Asegúrese de rellenar los campos de las notas usadas');
                      }
                    }

                    $scope.onSelectGenerales = function () {
                      var especies = $scope.ttInformeGestion.especies;
                      var notas = $scope.ttInformeGestion.notas;
                      for (var i = 0; i < especies.length; i++) {
                        especies[i].lenia = 0;
                        especies[i].troza = 0;
                        especies[i].volumen = 0;
                        especies[i].volumen = 0;
                        especies[i].restoLenia = 0;
                        especies[i].restoTroza = 0;
                        especies[i].restoVolumen = 0;
                      }
                      for (var i = 0; i < especies.length; i++) {
                        for (var j = 0; j < notas.length; j++) {
                          if (notas[j].especies != null) {
                            for (var k = 0; k < notas[j].especies.length; k++) {
                              if (notas[j].especies[k].tcEspecie.especieId == especies[i].tcEspecie.especieId) {
                                if (notas[j].especies[k].tcProductoTransporte.productoTransporteId == 1
                                  || notas[j].especies[k].tcProductoTransporte.productoTransporteId == 2) {
                                  especies[i].troza = parseFloat(especies[i].troza) + parseFloat(notas[j].especies[k].volumenReportar);
                                } else {
                                  if (notas[j].especies[k].tcProductoTransporte.productoTransporteId == 3
                                    || notas[j].especies[k].tcProductoTransporte.productoTransporteId == 4) {
                                    especies[i].lenia = parseFloat(especies[i].lenia) + parseFloat(notas[j].especies[k].volumenReportar);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      var total = {
                        saldoLenia: 0,
                        saldoTroza: 0,
                        saldoVolumen: 0,
                        lenia: 0,
                        troza: 0,
                        volumen: 0,
                        restoLenia: 0,
                        restoTroza: 0,
                        restoVolumen: 0
                      };
                      for (var i = 0; i < especies.length; i++) {
                        especies[i].lenia = parseFloat(especies[i].lenia).toFixed(2);
                        especies[i].troza = parseFloat(especies[i].troza).toFixed(2);
                        especies[i].volumen = parseFloat(especies[i].troza) + parseFloat(especies[i].lenia);
                        especies[i].volumen = parseFloat(especies[i].volumen).toFixed(2);
                        especies[i].restoLenia = parseFloat(especies[i].saldoLenia) - parseFloat(especies[i].lenia);
                        especies[i].restoLenia = parseFloat(especies[i].restoLenia).toFixed(2);
                        especies[i].restoTroza = parseFloat(especies[i].saldoTroza) - parseFloat(especies[i].troza);
                        especies[i].restoTroza = parseFloat(especies[i].restoTroza).toFixed(2);
                        especies[i].restoVolumen = parseFloat(especies[i].saldoVolumen) - parseFloat(especies[i].volumen);
                        especies[i].restoVolumen = parseFloat(especies[i].restoVolumen).toFixed(2);
                        total.saldoLenia += parseFloat(especies[i].saldoLenia);
                        total.saldoTroza += parseFloat(especies[i].saldoTroza);
                        total.saldoVolumen += parseFloat(especies[i].saldoVolumen);
                        total.lenia += parseFloat(especies[i].lenia);
                        total.troza += parseFloat(especies[i].troza);
                        total.volumen += parseFloat(especies[i].volumen);
                        total.restoLenia += parseFloat(especies[i].restoLenia);
                        total.restoTroza += parseFloat(especies[i].restoTroza);
                        total.restoVolumen += parseFloat(especies[i].restoVolumen);
                      }
                      total.saldoLenia = parseFloat(total.saldoLenia).toFixed(2);
                      total.saldoTroza = parseFloat(total.saldoTroza).toFixed(2);
                      total.saldoVolumen = parseFloat(total.saldoVolumen).toFixed(2);
                      total.lenia = parseFloat(total.lenia).toFixed(2);
                      total.troza = parseFloat(total.troza).toFixed(2);
                      total.volumen = parseFloat(total.volumen).toFixed(2);
                      total.restoLenia = parseFloat(total.restoLenia).toFixed(2);
                      total.restoTroza = parseFloat(total.restoTroza).toFixed(2);
                      total.restoVolumen = parseFloat(total.restoVolumen).toFixed(2);
                      $scope.total = total;
                      $scope.setConclusiones();
                    }

                    $scope.vistaPreviaAsignacion = function (isNotPreview) {
                      if ($scope.mostrarAsignar) {
                        if ($scope.dataTask.tcPersonaTraslado == null || $scope.dataTask.tcPersonaJuridico == null || $scope.dataTask.ttSeguimientoTarea.providencia == null) {
                          toastr.error("Debe seleccionar a la persona que asinará la tarea e indicar el número de providencia");
                          return;
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      var dataInforme = $scope.dataInforme;
                      dataInforme.ttTarea = $scope.dataTask;
                      utilsService.generarProvidenciaInforme(dataInforme, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (isNotPreview) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.asignarExpediente = function () {
                      if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de trasladar el informe al Técnico forestal? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttGestion.rodal;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.asignarProvidenciaInforme(data).then(function (res) {
                              if (res.status == "OK") {
                                toastr.success("La asignación se ha efectuado con éxito");
                                $scope.vistaPreviaAsignacion(true);
                              } else {
                                toastr.error("Error al realizar la asignación " + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                    $scope.finalizarTarea = function () {
                      $state.go('index.tarea.list');
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provsuspension', {
            url: '/provsuspension',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provsuspension.tpl.html',
                resolve: {
                  dataJuridico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 6);
                    }],
                  dataSuspension: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDatosSuspension($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataSuspension', '$filter', 'dataJuridico',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataSuspension, $filter, dataJuridico) {
                    $scope.dataJuridico = dataJuridico.data;
                    $scope.dataSuspension = dataSuspension.data[0];
                    $scope.dataTask = $scope.dataSuspension.ttTarea;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }

                    $scope.verAnexoSuspension = function (item) {
                      tareaService.getAnexoSuspension(item.anexoSuspensionId);
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaAsignada = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.providencia == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.providencia.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.fechaProvidencia == null) {
                        $scope.dataTask.ttSeguimientoTarea.fechaProvidencia = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      var data = $scope.dataSuspension;
                      data.ttTarea = $scope.dataTask;
                      utilsService.generarProvidenciaTrasladoSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (refresh) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttGestion = data.ttGestion;
                              data.ttGestion = {};
                              data.ttGestion = {
                                gestionId: ttGestion.gestionId,
                                tcTipoGestion: ttGestion.tcTipoGestion,
                                estadoId: ttGestion.estadoId,
                                tcPersonaCrea: ttGestion.tcPersonaCrea,
                                tcTipoBosque: ttGestion.tcTipoBosque,
                                tcElaborador: ttGestion.tcElaborador,
                                ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                              };
                              data.ttSeguimientoTarea.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              data.tcPersonaAsignada.foto = null;
                              data.tcPersonaMonitoreo = null;
                              data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                              tareaService.asignarProvidenciaSuspension(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaAsignacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opinionsuspension', {
            url: '/opinionsuspension',
            views: {
              '': {
                templateUrl: 'app/tarea/juridico.suspension.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', 'authService', '$filter', '$stateParams', '$base64',
                    function (tareaService, authService, $filter, $stateParams, $base64) {
                      return tareaService.getConPadre($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataTarea) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    } else {
                      if ($scope.dataTask.ttSeguimientoTarea.enmienda != null) {
                        $scope.dataTask.ttSeguimientoTarea.enmienda = JSON.parse($scope.dataTask.ttSeguimientoTarea.enmienda);
                      }
                    }
                    $scope.data = {
                      approved: true,
                      amendment: false
                    }

                    $scope.setAprobar = function () {
                      if ($scope.data.approved) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.vistaPrevia = function (mover) {
                      if (mover == null) {
                        mover = false;
                      }
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if (data.ttSeguimientoTarea.codigo == null) {
                        data.ttSeguimientoTarea.codigo = '';
                      }
                      utilsService.generarOpinionJuridicaSuspension(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (mover) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTareaJuridica = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.setOpinionJuridicaSuspension(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                $scope.vistaPrevia(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                    $scope.cerrarVentana = function () {
                      $scope.classEnmienda = "btn btn-default";
                      ngDialog.close();
                    }

                  }]
              }
            }
          })
          .state('index.realizar.suspender', {
            url: '/suspender',
            views: {
              '': {
                templateUrl: 'app/tarea/subregional.suspender.tpl.html',
                resolve: {
                  dataSuspension: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDatosSuspensionDesdeHijo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataSuspension', '$filter',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataSuspension, $filter) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;
                    $scope.dataSuspension = dataSuspension.data[0];
                    $scope.dataTask = $scope.dataSuspension.ttTarea;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        seguimientoTareaId: 0,
                        fechaAdmision: new Date(),
                        numeroResolucion: null
                      }
                    }
                    $scope.data = {
                      approved: true,
                      amendment: false
                    }

                    $scope.setAprobarSuspension = function () {
                      if ($scope.data.approved) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.setEnmienda = function () {
                      if ($scope.data.amendment) {
                        $scope.dataTask.aprobado = 2;
                        $scope.dataTask.esEnmienda = 1;
                      } else {
                        $scope.dataTask.ttSeguimientoTarea.enmienda = [];
                        $scope.dataTask.esEnmienda = 0;
                        if ($scope.data.approved) {
                          $scope.dataTask.aprobado = 1;
                        } else {
                          $scope.dataTask.aprobado = 0;
                        }
                      }
                    }

                    $scope.vistaPrevia = function (mover) {
                      if (mover == null) {
                        mover = false;
                      }
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      var data = $scope.dataSuspension;
                      var tarea = angular.copy($scope.dataTask);
                      tarea.ttSeguimientoTarea.fechaProvidencia = new Date();
                      if (tarea.ttSeguimientoTarea.codigo == null) {
                        tarea.ttSeguimientoTarea.codigo = '';
                      }
                      var dia = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                      var anio = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      data.ttTarea = tarea;
                      utilsService.generarDecisionSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      $timeout(function () {
                        utilsService.generarCedulaNotificacionSuspension(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 2000);
                      if (mover) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.setDecisionSuspensionGestion(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                $scope.vistaPrevia(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                  }]
              }
            }
          })
          .state('index.realizar.reprovidencia', {
            url: '/reprovidencia',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.reprovidencia.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.get($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataEnmienda', '$filter', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataEnmienda, $filter, dataTarea) {
                    $scope.textoAccion = 'El siguiente paso es notificar al solicitante las enmiendas. ¿Quiere realizarlo ahora? Presione guardar, de lo contrario puede cancelar y regresar a la lista de tareas';
                    $scope.textoOficio = 'No. Oficio';
                    $scope.mostrarOficio = true;
                    $scope.dataJuridico = [];
                    $scope.dataTecnico = [];
                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }
                    $scope.data = {
                      fundamento: [],
                      aprobado: 2,
                      enmienda: []
                    }

                    $scope.providenciarExpediente = function () {
                      if ($scope.mostrarOficio) {
                        $scope.mostrarProvidencia = true;
                        $scope.mostrarOficio = false;
                        $scope.textoAccion = 'El siguiente paso es providenciar expediente para ratificar información';
                        $scope.textoOficio = 'No. Providencia';
                      } else {
                        $scope.mostrarProvidencia = false;
                        $scope.mostrarOficio = true;
                        $scope.textoAccion = 'El siguiente paso es notificar al solicitante las enmiendas. ¿Quiere realizarlo ahora? Presione guardar, de lo contrario puede cancelar y regresar a la lista de tareas';
                        $scope.textoOficio = 'No. Oficio';
                        $scope.dataTask.tcPersonaJuridico = null;
                        $scope.dataTask.tcPersonaTraslado = null;
                      }
                      if ($scope.dataJuridico.length <= 0) {
                        tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 6).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataJuridico = res.data;
                          } else {
                            $scope.dataJuridico = [];
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          $scope.dataJuridico = [];
                          toastr.error(error);
                        });
                      }
                      if ($scope.dataJuridico.length <= 0) {
                        tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataTecnico = res.data;
                          } else {
                            $scope.dataTecnico = [];
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          $scope.dataTecnico = [];
                          toastr.error(error);
                        });
                      }
                    }

                    //taskId: 3 = juridico, 4 = tecnico, 14 = monitoreo

                    var data = dataEnmienda.data;
                    var codigo = null;
                    for (var i = 0; i < data.length; i++) {
                      if (data[i].ttSeguimientoTarea.codigo == null) {
                        codigo = data[i].ttSeguimientoTarea.numeroResolucion;
                      } else {
                        if (data[i].tcTask.taskId == 3) {
                          codigo = data[i].ttSeguimientoTarea.numeroResolucion + '/' + data[i].ttSeguimientoTarea.codigo;
                        } else {
                          codigo = data[i].ttSeguimientoTarea.codigo;
                        }
                      }
                      var row = {
                        codigo: codigo,
                        taskId: data[i].tcTask.taskId,
                        tareaId: data[i].tareaId,
                        personaId: data[i].tcPersonaAsignada.personaId,
                        personaDesc: data[i].tcPersonaAsignada.personaDesc,
                        enmienda: []
                      };
                      enmienda = JSON.parse(data[i].ttSeguimientoTarea.enmienda);
                      for (var j = 0; j < enmienda.length; j++) {
                        var item = {
                          descripcion: enmienda[j].descripcion
                        };
                        row.enmienda.push(item);
                      }
                      $scope.data.enmienda.push(row);
                    }

                    $scope.agregarFundamento = function () {
                      $scope.data.fundamento.push({ descripcion: null });
                    }

                    $scope.borrarDetalle = function (item) {
                      $scope.data.fundamento.splice($scope.data.fundamento.indexOf(item), 1);
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.setJuridico = function (item) {
                      $scope.dataTask.tcPersonaJuridico = item;
                    }

                    $scope.vistaPrevia = function (fin) {
                      if (fin == null) {
                        fin = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      } else {
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error('Debe indicar el número de oficio');
                          return;
                        }
                      }
                      var data = angular.copy($scope.dataTask);
                      var enmienda = angular.copy($scope.data.enmienda);
                      var fundamento = angular.copy($scope.data.fundamento);
                      data.ttSeguimientoTarea.fechaProvidencia = new Date();
                      data.aprobado = $scope.data.aprobado;
                      if ($scope.mostrarOficio) {
                        enmienda.push(
                          {
                            codigo: null,
                            enmienda: fundamento
                          }
                        );
                        data.ttSeguimientoTarea.enmienda = enmienda;
                        utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                        var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        data.ttSeguimientoTarea.providencia = data.ttSeguimientoTarea.codigo;
                        data.ttSeguimientoTarea.codigo = null;
                        if (fundamento.length > 0) {
                          data.ttSeguimientoTarea.observaciones = fundamento;
                        }
                        utilsService.generarProvidenciaRatificacion(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (fin) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isAmendment, tmpData) {
                      if ($scope.dataTask.ttSeguimientoTarea.codigo == null) {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        toastr.error(texto);
                        return;
                      } else {
                        var texto = 'Debe indicar el número de oficio';
                        if ($scope.mostrarProvidencia) {
                          texto = 'Debe indicar el número de providencia';
                        }
                        if ($scope.dataTask.ttSeguimientoTarea.codigo.length < 1) {
                          toastr.error(texto);
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var enmienda = angular.copy($scope.data.enmienda);
                            var fundamento = angular.copy($scope.data.fundamento);
                            enmienda.push(
                              {
                                codigo: null,
                                enmienda: fundamento
                              }
                            );
                            var ttGestion = data.ttGestion;
                            data.ttGestion = {};
                            data.ttGestion = {
                              gestionId: ttGestion.gestionId,
                              tcTipoGestion: ttGestion.tcTipoGestion,
                              estadoId: ttGestion.estadoId,
                              tcPersonaCrea: ttGestion.tcPersonaCrea,
                              tcTipoBosque: ttGestion.tcTipoBosque,
                              tcElaborador: ttGestion.tcElaborador,
                              ttTipoPropietarioGestion: ttGestion.ttTipoPropietarioGestion
                            };
                            data.ttSeguimientoTarea.fechaProvidencia = new Date();
                            data.aprobado = $scope.data.aprobado;
                            data.tcPersonaAsignada.foto = null;
                            data.tcPersonaMonitoreo = null;
                            if ($scope.mostrarOficio) {
                              data.ttSeguimientoTarea.enmienda = JSON.stringify(enmienda);
                            } else {
                              if (data.tcPersonaTraslado != null) {
                                data.tcPersonaTraslado.foto = null;
                              }
                              if (data.tcPersonaJuridico != null) {
                                data.tcPersonaJuridico.foto = null;
                              }
                              data.ttSeguimientoTarea.observaciones = JSON.stringify(fundamento);
                            }
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            if ($scope.mostrarOficio) {
                              gestionService.get(data.ttGestion.gestionId).then(function (resp) {
                                if (resp.status == "OK") {
                                  data.ttGestion.cronograma = JSON.stringify(resp.data[0]);
                                  //enmienda con oficios
                                  tareaService.setNotificacionEnmienda(data).then(function (res) {
                                    if (res.status == "OK") {
                                      data.ttSeguimientoTarea.enmienda = enmienda;
                                      data.ttGestion = ttGestion;
                                      utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                      toastr.success(res.message);
                                      $state.go('index.tarea.list');
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              tareaService.asignarProvidenciaRatificacion(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success("La asignación se ha efectuado con éxito");
                                  $scope.vistaPrevia(true);
                                } else {
                                  toastr.error("Error al realizar la asignación " + res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provexento', {
            url: '/provexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provexento.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDatosTareaExento($base64.decode($stateParams.tareaId));
                    }],
                  dataRnf: ['catalogosService', 'dataTarea',
                    function (catalogosService, dataTarea) {
                      var data = dataTarea.data[0];
                      return catalogosService.getDatosRegistro(data.ttExento.codigo.trim());
                    }],
                  dataCategoriaExento: ['categoriaexentoService',
                    function (categoriaexentoService) {
                      return categoriaexentoService.listaActiva();
                    }],
                  dataEspecie: ['dataTarea', 'especieService',
                    function (dataTarea, especieService) {
                      var ttTarea = dataTarea.data[0];
                      return especieService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataTecnico', 'dataRnf', 'authService', 'dataCategoriaExento', 'dataEspecie',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, dataTecnico, dataRnf, authService, dataCategoriaExento, dataEspecie) {
                    $scope.dataTecnico = dataTecnico.data;
                    $scope.dataCategoriaExento = dataCategoriaExento.data;
                    $scope.dataTask = dataTarea.data[0];
                    if (dataRnf.status == "OK") {
                      $scope.dataRnf = dataRnf.data[0];
                    } else {
                      $state.go('index.tarea.exentolist');
                      swal('Error en RNF', dataRnf.message);
                      return;
                    }
                    $scope.cambioSinGuardar = false;
                    if ($scope.dataTask.ttExento.fechaVencimiento == null) {
                      if ($scope.dataRnf.fechaVencimiento != null) {
                        $scope.dataRnf.fechaVencimiento = new Date($scope.dataRnf.fechaVencimiento.toString().replace('[UTC]', ''));
                        $scope.dataTask.ttExento.fechaVencimiento = $scope.dataRnf.fechaVencimiento;
                      }
                    } else {
                      $scope.dataTask.ttExento.fechaVencimiento = new Date($scope.dataTask.ttExento.fechaVencimiento);
                      var day = $scope.dataTask.ttExento.fechaVencimiento.getDate();
                      if (day == 30) {
                        $scope.dataTask.ttExento.fechaVencimiento.setDate($scope.dataTask.ttExento.fechaVencimiento.getDate() + 1);
                      }
                    }
                    $scope.actualizarVolumen = false;
                    $scope.habilitarCero = false;
                    if ($scope.dataTask.ttExento.actualizar == 1) {
                      $scope.actualizarVolumen = true;
                    }

                    $scope.setActualizarVolumen = function () {
                      if ($scope.actualizarVolumen) {
                        $scope.dataTask.ttExento.actualizar = 1;
                      } else {
                        $scope.dataTask.ttExento.actualizar = 0;
                      }
                    }

                    $scope.dataEspecie = dataEspecie.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }
                    if ($scope.dataTask.ttExento.tcCategoriaExento == null) {
                      $scope.dataTask.ttExento.tcCategoriaExento = {};
                    }
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {};
                    }

                    var ttExento = $scope.dataTask.ttExento;
                    /*if (ttExento.rodales == null) {
                      ttExento.rodales = [];
                    }*/
                    var especies = [];
                    if (ttExento.actualizar == 1) {
                      var rodales = ttExento.rodales;
                      for (var i = 0; i < rodales.length; i++) {
                        var especiesRodal = rodales[i].especies;
                        for (var j = 0; j < especiesRodal.length; j++) {
                          var tcEspecie = especiesRodal[j].tcEspecie;
                          var encontrado = false;
                          for (var k = 0; k < especies.length; k++) {
                            if (especies[k].especieId == tcEspecie.especieId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            especies.push(tcEspecie);
                          }
                        }
                      }
                    } else {
                      ttExento.rodales = [];
                      if (ttExento.rodales.length <= 0) {
                        var rodales = [];
                        var tmp = $scope.dataRnf.rodales;
                        var haySinonimo = false;
                        var nombres = '', sinonimos = '';
                        var noEncontrados = [];
                        for (var i = 0; i < tmp.length; i++) {
                          var rodal = {
                            correlativo: tmp[i].id,
                            area: tmp[i].area,
                            volumen: tmp[i].volumen,
                            especies: []
                          }
                          for (var j = 0; j < tmp[i].especies.length; j++) {
                            var codigo = '', nombre = '';
                            if (tmp[i].especies[j].especie != null) {
                              codigo = tmp[i].especies[j].especie.trim().toUpperCase();
                            }
                            if (tmp[i].especies[j].nombre != null) {
                              nombre = tmp[i].especies[j].nombre.trim().toLowerCase();
                            }
                            var tcEspecie = $scope.getEspecieByNombreCientífico(nombre, $scope.dataEspecie);
                            if (tcEspecie == null) {
                              tcEspecie = $scope.getEspecieByCodigo(codigo, $scope.dataEspecie);
                              if (tcEspecie == null) {
                                tcEspecie = $scope.getSinonimoEspecie(nombre, $scope.dataEspecie);
                                if (tcEspecie == null) {
                                  var yaEsta = false;
                                  for (var k = 0; k < noEncontrados.length; k++) {
                                    var itemNoEncontrado = noEncontrados[k];
                                    if (itemNoEncontrado.codigo == codigo) {
                                      yaEsta = true;
                                      break;
                                    }
                                  }
                                  if (!yaEsta) {
                                    noEncontrados.push({ codigo: codigo, nombre: nombre });
                                  }
                                } else { //se encontro como sinonimo
                                  var especie = {
                                    tcEspecie: tcEspecie,
                                    volumenHectaria: 0,
                                    volumen: tmp[i].especies[j].volumen
                                  }
                                  rodal.especies.push(especie);
                                  if (haySinonimo) {
                                    sinonimos += '\n (RNF) ' + tmp[i].especies[j].nombre + ' = ' + tcEspecie.nombreCientifico;
                                  } else {
                                    sinonimos = '(RNF) ' + tmp[i].especies[j].nombre + ' = ' + tcEspecie.nombreCientifico;
                                  }
                                  haySinonimo = true;
                                }
                              } else { //se encontro con el codigo
                                var especie = {
                                  tcEspecie: tcEspecie,
                                  volumenHectaria: 0,
                                  volumen: tmp[i].especies[j].volumen
                                }
                                rodal.especies.push(especie);
                              }
                            } else { //se con el nombre cientifico
                              var especie = {
                                tcEspecie: tcEspecie,
                                volumenHectaria: 0,
                                volumen: tmp[i].especies[j].volumen
                              }
                              rodal.especies.push(especie);
                            }
                            //agregar a la lista solo si no se repite
                            if (tcEspecie != null) {
                              var encontrado = false;
                              for (var k = 0; k < especies.length; k++) {
                                if (especies[k].especieId == tcEspecie.especieId) {
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                especies.push(tcEspecie);
                              }
                            }
                          }
                          rodales.push(rodal);
                        }
                        if (noEncontrados.length > 0) {
                          for (var i = 0; i < noEncontrados.length; i++) {
                            var element = noEncontrados[i];
                            if (i == 0) {
                              nombres = element.nombre;
                            } else {
                              nombres += ', ' + element.nombre;
                            }
                          }
                          if (nombres.length <= 0) {
                            swal('Los datos de especies en el Sistema de Registro no son correctos, favor de verificar');
                          } else {
                            swal('No se ha encontrado datos de la(s) especie(s): ' + nombres + '. Busque en el catálogo el sinónimo, en dado caso no se encuentra será necesario comunicarlo al administrador para agregarlo');
                          }
                        } else {
                          if (haySinonimo) {
                            swal('Se encontraron los siguientes sinónimos:', sinonimos);
                          }
                        }
                        $scope.dataTask.ttExento.rodales = rodales;
                      } else { //ya estan cargados las especies
                        var rodales = $scope.dataTask.ttExento.rodales;
                        var rodal = {
                          correlativo: 1,
                          area: $scope.dataTask.ttExento.area,
                          especies: []
                        };
                        for (var i = 0; i < rodales.length; i++) {
                          var especiesRodal = rodales[i].especies;
                          for (var j = 0; j < especiesRodal.length; j++) {
                            var tcEspecie = especiesRodal[j].tcEspecie;
                            var encontrado = false;
                            for (var k = 0; k < especies.length; k++) {
                              if (especies[k].especieId == tcEspecie.especieId) {
                                encontrado = true;
                                break;
                              }
                            }
                            if (!encontrado) {
                              especies.push(tcEspecie);
                            }
                          }
                        }
                      }
                    }
                    $scope.especies = especies;
                    var codigo = $scope.dataTask.ttExento.codigo;
                    $scope.porRodal = true;

                    $scope.agruparEspecies = function () {
                      var rodales = $scope.dataTask.ttExento.rodales;
                      var rodal = {
                        correlativo: 1,
                        area: $scope.dataTask.ttExento.area,
                        especies: []
                      };
                      for (var i = 0; i < rodales.length; i++) {
                        var especies = rodales[i].especies;
                        for (var j = 0; j < especies.length; j++) {
                          var especie = especies[j];
                          if (parseFloat(especie.volumen) <= 0) {
                            especie.volumen = rodales[i].volumen;
                          }
                          var encontrado = false;
                          for (var k = 0; k < rodal.especies.length; k++) {
                            if (rodal.especies[k].tcEspecie.especieId == especie.tcEspecie.especieId) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            rodal.especies.push(especie);
                          }
                        }
                      }
                      var nRodales = [rodal];
                      $scope.dataTask.ttExento.rodales = nRodales;
                    }

                    $scope.borrarRodales = function () {
                      $scope.dataTask.ttExento.rodales = [];
                    }

                    $scope.agregarRodal = function () {
                      $scope.dataTask.ttExento.rodales.push(
                        {
                          correlativo: $scope.dataTask.ttExento.rodales.length + 1,
                          area: 0,
                          especies: []
                        }
                      );
                    }

                    $scope.quitarRodalExento = function (rodal) {
                      $scope.dataTask.ttExento.rodales.splice($scope.dataTask.ttExento.rodales.indexOf(rodal), 1);
                    }

                    $scope.agregarEspecies = function (rodal) {
                      rodal.especies = [];
                      var especies = $scope.especies;
                      for (var i = 0; i < especies.length; i++) {
                        rodal.especies.push(
                          {
                            tcEspecie: especies[i],
                            volumen: 0,
                            volumenHectaria: 0
                          }
                        )
                      }
                    }

                    $scope.agregarEspecie = function (rodal) {
                      rodal.especies.push(
                        {
                          tcEspecie: null,
                          volumen: 0,
                          volumenHectaria: 0
                        }
                      );
                    }

                    $scope.quitarEspecieExento = function (rodal, especie) {
                      rodal.especies.splice(rodal.especies.indexOf(especie), 1);
                    }

                    $scope.setEspecieExento = function (item, especie) {
                      if (especie.tcEspecie == null) {
                        especie.tcEspecie = {
                          especieId: item.especieId
                        };
                      }
                      especie.tcEspecie.especieDesc = item.especieDesc;
                      especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                    }

                    $scope.setVolumen = function (rodal, especie) {
                      if (rodal.enLinea == null) {
                        rodal.enLinea = 0;
                      }
                      if (rodal.enLinea == 1 || rodal.enLinea) {
                        especie.volumenHectaria = 0;
                        toastr.error('Para árboles en línea no aplica volumen por hectárea');
                        return;
                      }
                      especie.volumen = parseFloat(rodal.area) * parseFloat(especie.volumenHectaria);
                      especie.volumen = parseFloat(especie.volumen).toFixed(2);
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.setVolumenRodal = function (rodal, especie) {
                      if (parseFloat(rodal.area) == 0) {
                        toastr.error('El área del rodal o longitud de la línea no puede ser cero');
                        especie.volumen = 0;
                        return;
                      }
                      if (rodal.enLinea == null) {
                        rodal.enLinea = 0;
                      }
                      if (rodal.enLinea == 1 || rodal.enLinea) {
                        especie.volumenHectaria = 0;
                      } else {
                        especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                        especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(2);
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.verAnexoSuspension = function (item) {
                      tareaService.getAnexoSuspension(item.anexoSuspensionId);
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.setCategoriaExento = function (item) {
                      $scope.dataTask.ttExento.tcCategoriaExento = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.tcPersonaTraslado == null) {
                        toastr.error('Debe seleccionar a la técnico a asignar');
                        return;
                      }
                      try {
                        $scope.dataTask.fechaFinalizacion = new Date();
                        var dia = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia, false);
                        var strAnio = $scope.numeroALetras(anio, false);
                        var data = $scope.dataTask;
                        utilsService.setEsPrevia(!refresh);
                        utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } catch (error) { }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.anularSolicitudCargaInicial = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro de anular esta solicitud y notificar al usuario por qué? Esta acción no se podrá reversar.\n\nIngrese el motivo de la anulación",
                        type: "input",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        inputPlaceholder: "Motivo de anulación"
                      },
                        function (inputValue) {
                          if (inputValue === false) return false;
                          if (inputValue === "") {
                            toastr.error('Es necesario el motivo ya que se le notificará al usuario');
                            return false;
                          }
                          var data = angular.copy($scope.dataTask);
                          var ttExento = data.ttExento;
                          data.ttExento = {};
                          data.ttExento = {
                            exentoId: ttExento.exentoId,
                            tcTipoGestion: ttExento.tcTipoGestion,
                            estadoId: ttExento.estadoId,
                            tcPersonaCrea: ttExento.tcPersonaCrea
                          };
                          data.observaciones = inputValue;
                          tareaService.anularSolicitudCargaExento(data).then(function (res) {
                            if (res.status == "OK") {
                              toastr.success(res.message);
                              $state.go('index.tarea.exentolist');
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        });
                    }

                    $scope.providenciarCargaInicialExento = function (isValid) {
                      if (isValid) {
                        var rodales = $scope.dataTask.ttExento.rodales;
                        if (rodales == null) {
                          rodales = [];
                        }
                        var totalArea = 0;
                        var totalLongitud = 0;
                        for (var i = 0; i < rodales.length; i++) {
                          var rodal = rodales[i];
                          if (rodal.enLinea == 1) {
                            totalLongitud += parseFloat(rodal.area);
                          } else {
                            totalArea += parseFloat(rodal.area);
                          }
                        }
                        totalArea = parseFloat(totalArea).toFixed(4);
                        totalLongitud = parseFloat(totalLongitud).toFixed(2);
                        if (parseFloat($scope.dataTask.ttExento.area) != totalArea) {
                          toastr.error('La sumatoria de las áreas por rodal (' + totalArea 
                          + ') debe ser igual al área aprobada (' + $scope.dataTask.ttExento.area + '), favor de revisar');
                          return;
                        }
                        if (parseFloat($scope.dataTask.ttExento.longitud) != totalLongitud) {
                          toastr.error('La sumatoria de las longitudes de las líneas (' + totalLongitud 
                          + ') debe ser igual a la longitud aprobada (' + $scope.dataTask.ttExento.longitud + '), favor de revisar');
                          return;
                        }
                        var habilitarCero = $scope.habilitarCero;
                        if ($scope.dataTask.ttExento.actualizar == 0) {
                          for (var i = 0; i < rodales.length; i++) {
                            var especies = rodales[i].especies;
                            var hayVolumen = false, hayVolumenHectarea = false;
                            for (var j = 0; j < especies.length; j++) {
                              if (parseFloat(especies[j].volumen) > 0) {
                                hayVolumen = true;
                              }
                              if (rodales[i].enLinea == null) {
                                rodales[i].enLinea = 0;
                              }
                              if (rodales[i].enLinea || rodales[i].enLinea == 1) {
                                hayVolumenHectarea = true;
                              } else {
                                if (parseFloat(especies[j].volumenHectaria) > 0) {
                                  hayVolumenHectarea = true;
                                }
                              }
                            }
                            if ((!hayVolumen || !hayVolumenHectarea) && !habilitarCero) {
                              isValid = false;
                              toastr.error('Debe indicar los volúmenes a las especies de cada rodal');
                              break;
                            }
                          }
                        }
                      }
                      if (isValid) {
                        if ($scope.dataTask.ttExento.area <= 0 && $scope.dataTask.ttExento.longitud <= 0) {
                          toastr.error('El área y longitud del exento no puede ser menor o igual a cero');
                          return;
                        }
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttExento = angular.copy(data.ttExento);
                              var rodales = ttExento.rodales;
                              var permitirCero = 0;
                              if (habilitarCero) {
                                permitirCero = 1;
                              }
                              for (var i = 0; i < rodales.length; i++) {
                                var rodal = rodales[i];
                                if (rodal.enLinea || rodal.enLinea == 1) {
                                  rodal.enLinea = 1;
                                } else {
                                  rodal.enLinea = 0;
                                }
                                var especies = rodal.especies;
                                for (var j = 0; j < especies.length; j++) {
                                  var especie = especies[j];
                                  especie.permitirCero = permitirCero;
                                }
                              }
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea,
                                tcCategoriaExento: ttExento.tcCategoriaExento,
                                fechaVencimiento: ttExento.fechaVencimiento,
                                rodales: ttExento.rodales,
                                area: ttExento.area,
                                longitud: ttExento.longitud,
                                actualizar: ttExento.actualizar
                              };
                              data.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              data.tcPersonaAsignada.foto = null;
                              data.tcPersonaTraslado.foto = null;
                              data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                              tareaService.asignarProvidenciaExento(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaAsignacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opexento', {
            url: '/opexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.opexento.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDatosTareaExento($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService', '$timeout', 'catalogosService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService, $timeout, catalogosService) {

                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.observaciones = [];
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    $scope.opciones = {
                      revalidar: false,
                      permitirVenta: true,
                      esActualizar: false
                    }

                    if ($scope.dataTask.ttExento.actualizar == 1) {
                      $scope.opciones.permitirVenta = false;
                      $scope.opciones.esActualizar = true;
                      var rodales = $scope.dataTask.ttExento.rodales;
                      for (var i = 0; i < rodales.length; i++) {
                        var rodal = rodales[i];
                        var total = 0;
                        if (rodal.enLinea == 0) {
                          for (var j = 0; j < rodal.especies.length; j++) {
                            especie = rodal.especies[j];
                            if (especie.volumenHectaria <= 0) {
                              var volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                              volumenHectaria = parseFloat(volumenHectaria).toFixed(4);
                              especie.volumenHectaria = volumenHectaria;
                            }
                            total += parseFloat(especie.volumen);
                          }
                        } else {
                          for (var j = 0; j < rodal.especies.length; j++) {
                            especie = rodal.especies[j];
                            total += parseFloat(especie.volumen);
                          }
                        }
                        rodal.volumen = parseFloat(total).toFixed(4);
                      }
                      if (rodales.length > 1) {
                        rodales.sort((a, b) => a.correlativo - b.correlativo);
                      }
                    }

                    if ($scope.dataTask.ttExento.ventas != null) {
                      var ventas = $scope.dataTask.ttExento.ventas;
                      for (var i = 0; i < ventas.length; i++) {
                        var venta = ventas[i];
                        var informes = venta.informes;
                        if (venta.estadoId == 5) {
                          informes = null;
                        }
                        var ultimoInforme = 1;
                        if (informes != null) {
                          if (informes.length > 0) {
                            var informe = informes[0];
                            ultimoInforme = informe.correlativo;
                          }
                        }
                        if (venta.fechaAutorizacion != null) {
                          venta.fechaAutorizacion = new Date(venta.fechaAutorizacion);
                        }
                        if (venta.fechaFinalizacion != null) {
                          venta.fechaFinalizacion = new Date(venta.fechaFinalizacion);
                        }
                        if (venta.estadoId != 5) {
                          venta.saldosTmp = angular.copy(venta.saldos);
                          var saldosTmp = venta.saldosTmp;
                          for (var j = 0; j < saldosTmp.length; j++) {
                            var saldo = saldosTmp[j];
                            saldo.reserva = 0;
                          }
                          venta.ultimoInforme = ultimoInforme;
                          var saldos = venta.saldos;
                          if (saldos == null) {
                            saldos = [];
                          }
                          var saldosNuevo = [];
                          for (var j = 0; j < saldos.length; j++) {
                            var saldo = saldos[j];
                            if (parseFloat(saldo.saldo) > 0) {
                              saldo.troza = 0;
                              saldo.lenia = 0;
                              saldo.total = parseFloat(saldo.volumen) - parseFloat(saldo.saldo);
                              saldosNuevo.push(saldo);
                            }
                          }
                          venta.saldos = saldosNuevo;
                        }
                      }
                    }
                    $scope.tabVolumen = false;
                    $scope.tabTransportado = false;
                    $scope.activo = {};
                    if ($scope.dataTask.ttExento.actualizar == 1) {
                      $scope.tabVolumen = true;
                      $scope.activo = {
                        cero: true,
                        uno: false,
                        dos: false
                      }
                    } else {
                      $scope.tabTransportado = true;
                      $scope.activo = {
                        cero: false,
                        uno: true,
                        dos: false
                      }
                    }
                    $scope.dataTask.fechaFinalizacion = new Date();
                    var textoPropietario = '';
                    var tmp = $scope.dataTask.ttExento.propietarios;
                    for (var i = 0; i < tmp.length; i++) {
                      if (i == 0) {
                        textoPropietario = tmp[i].tcPersona.personaDesc;
                      } else {
                        textoPropietario += ', ' + tmp[i].tcPersona.personaDesc;
                      }
                    }
                    $scope.textoPropietario = textoPropietario;

                    var rodales = $scope.dataTask.ttExento.rodales;
                    var dataRodales = [];
                    for (var i = 0; i < rodales.length; i++) {
                      dataRodales.push(
                        {
                          correlativo: rodales[i].correlativo
                        }
                      );
                    }
                    $scope.dataRodales = dataRodales;

                    var dataEspecie = [];
                    var especies = $scope.dataTask.ttExento.especies;
                    for (var i = 0; i < especies.length; i++) {
                      dataEspecie.push(especies[i].tcEspecie);
                      if (especies[i].volumen > 0) {
                        $scope.tabTransportado = true;
                      }
                    }
                    $scope.dataEspecie = dataEspecie;

                    $scope.setEspecieTransportado = function (item, row) {
                      row.tcEspecie = item;
                    }

                    $scope.porRodal = true;

                    $scope.setVolumenHectarea = function (rodal, especie) {
                      if (rodal.enLinea == 0) {
                        var volumen = parseFloat(rodal.area) * parseFloat(especie.volumenHectaria);
                        volumen = parseFloat(volumen).toFixed(4);
                        if (volumen != especie.volumen) {
                          $scope.cambioSinGuardar = true;
                        }
                        especie.volumen = volumen;
                      } else {
                        especie.volumenHectaria = 0;
                        toastr.warning('Para arboles en línea no aplica volumen por hectárea');
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(4);
                    }

                    $scope.setVolumenRodal = function (rodal, especie) {
                      if (rodal.enLinea == 0) {
                        var volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                        volumenHectaria = parseFloat(volumenHectaria).toFixed(4);
                        if (volumenHectaria != especie.volumenHectaria) {
                          $scope.cambioSinGuardar = true;
                        }
                        especie.volumenHectaria = volumenHectaria;
                      } else {
                        especie.volumenHectaria = 0;
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(4);
                    }

                    $scope.guardarVolumen = function (isValid) {
                      if (!$scope.tabVolumen) {
                        return;
                      }
                      if (isValid) {
                        swal({
                          title: "No hay regreso de tarea",
                          text: "¿Está seguro (a) de guardar los datos, si yá agregó volumen transportado deberá actualizar o los datos no serán válidos",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttExento = data.ttExento;
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea,
                                tcCategoriaExento: ttExento.tcCategoriaExento,
                                fechaVencimiento: ttExento.fechaVencimiento,
                                rodales: ttExento.rodales
                              };
                              delete data.observaciones;
                              tareaService.actualizarVolumenExento(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.tabTransportado = true;
                                  $scope.activo.cero = false;
                                  $scope.activo.uno = true;
                                  $scope.cambioSinGuardar = false;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }

                    $scope.textoPoligono = 'Cargar polígono';

                    $scope.gridPoligono = angular.copy($scope.gridOptionsSelection);
                    $scope.gridPoligono.data = [];
                    $scope.gridPoligonoDescuento = angular.copy($scope.gridOptionsSelection);
                    $scope.gridPoligonoDescuento.data = [];
                    $scope.veces = 2;
                    $scope.visibleMapa = false;
                    $scope.mapa = {
                      centerX: 14.596240611182054,
                      centerY: -90.53100159560573
                    };
                    $scope.enLinea = false;

                    $scope.onFileSelect = function (file, id) {
                      if (file != null) {
                        var data = [];
                        if (id == 1) {
                          data = $scope.gridPoligono.data;
                        } else {
                          if ($scope.gridPoligono.data.length <= 0) {
                            toastr.error('Primero debe cargar las coordenadas del polígono');
                            return;
                          }
                          data = $scope.gridPoligonoDescuento.data;
                        }
                        var rodales = $scope.dataTask.ttExento.rodales;
                        if (data.length > 0) {
                          var poligonos = [];
                          for (var i = 0; i < data.length; i++) {
                            var row = data[i];
                            var poligono = {
                              numero: row.NO_POLIGONO,
                              coordenadas: [],
                              enLinea: 0
                            }
                            var encontrado = false;
                            for (var j = 0; j < poligonos.length; j++) {
                              if (poligonos[j].numero == row.NO_POLIGONO) {
                                encontrado = true;
                                poligono = poligonos[j];
                                break;
                              }
                            }
                            poligono.coordenadas.push({ lat: row.GTM_X, lng: row.GTM_Y });
                            if (!encontrado) {
                              poligonos.push(poligono);
                            }
                          }
                          if (id == 1) {
                            for (var j = 0; j < poligonos.length; j++) {
                              var poligono = poligonos[j];
                              encontrado = false;
                              for (let k = 0; k < rodales.length; k++) {
                                const element = rodales[k];
                                if (element.correlativo == poligono.numero) {
                                  encontrado = true;
                                  poligono.enLinea = element.enLinea == null ? 0 : element.enLinea;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                toastr.error('El polígono ' + poligono.numero + ' no coincide con el correlativo de ningún rodal, no es posible continuar');
                                return;
                              }
                            }
                          }
                          var poligono = null;
                          var enLinea = false;
                          var lineas = [];
                          var areas = [];
                          for (var i = 0; i < poligonos.length; i++) {
                            poligono = poligonos[i];
                            var coordenadasGtm = poligono.coordenadas;
                            var nuevos = utilsService.gtmToLatLong(true, 'lat', 'lng', coordenadasGtm);
                            poligono.coordenadas = nuevos;
                            poligono.coordenadasGtm = coordenadasGtm;
                            var bounds = new google.maps.LatLngBounds();
                            var polyline = new google.maps.Polyline();
                            var path = polyline.getPath();
                            var minx = 0, miny = 0, maxx = 0, maxy = 0;
                            var arregloLatLng = [];
                            for (var j = 0; j < nuevos.length; j++) {
                              var item = nuevos[j];
                              var otro = new google.maps.LatLng(item.lat, item.lng);
                              arregloLatLng.push(otro);
                              path.push(otro);
                              bounds.extend(otro);
                            }
                            var centro = bounds.getCenter();
                            poligono.centro = {};
                            poligono.centro.lat = centro.lat();
                            poligono.centro.lng = centro.lng();
                            poligono.lat = centro.lat();
                            poligono.lng = centro.lng();
                            var coordenadasImprimir = google.maps.geometry.encoding.encodePath(path);
                            poligono.encodeMapa = coordenadasImprimir;
                            if (poligono.enLinea == 1) {
                              enLinea = true;
                              lineas.push(poligono);
                            } else {
                              areas.push(poligono);
                            }
                          }
                          if (id == 1) {
                            $scope.mapa = {
                              centerX: 14.596240611182054,
                              centerY: -90.53100159560573
                            };
                            $scope.dataTask.ttExento.poligonos = poligonos;
                            $scope.mapa.lineas = lineas;
                            $scope.mapa.poligonos = areas;
                            $scope.mapa.centerX = poligono.lat;
                            $scope.mapa.centerY = poligono.lng;
                            $scope.enLinea = enLinea;
                          } else {
                            poligonos = areas;
                          }
                          var finca = $scope.dataTask.ttExento.fincas[0].tcFinca;
                          var direccionFinca = finca.direccion + ', ' + finca.tcMunicipio.municipioDesc + ', ' + finca.tcMunicipio.tcDepartamento.departamentoDesc;
                          if (lineas.length > 0) {
                            if (parseFloat($scope.dataTask.ttExento.longitud) <= 0) {
                              toastr.error('No es posible continuar debido a que la longitud del exento es menor o igual a cero, favor de verificar');
                              $scope.dataTask.ttExento.poligonos = [];
                              $scope.mapa.lineas = [];
                              $scope.mapa.mapas = [];
                              return;
                            }
                            catalogosService.getLongitudLinea(lineas).then(function (res) {
                              if (res.status == 1) {
                                var data = res.data;
                                var lineas = data.poligonos;
                                var traslapes = data.traslapes;
                                var longitudTotal = 0;
                                for (let i = 0; i < lineas.length; i++) {
                                  var element = lineas[i];
                                  element.longitud = parseFloat(element.area);
                                  longitudTotal += parseFloat(element.area);
                                }
                                longitudTotal = parseFloat(longitudTotal).toFixed(2);
                                var diff = parseFloat($scope.dataTask.ttExento.longitud) - parseFloat(longitudTotal);
                                $scope.mapa.lineas = lineas;
                                var areaPoligonos = lineas;
                                var poligonosEnviar = poligonos;
                                for (var i = 0; i < poligonosEnviar.length; i++) {
                                  var tmpPoligono = poligonosEnviar[i];
                                  for (var j = 0; j < areaPoligonos.length; j++) {
                                    const tmpPoligono2 = areaPoligonos[j];
                                    if (tmpPoligono.numero == tmpPoligono2.numero) {
                                      tmpPoligono.area = tmpPoligono2.area;
                                      tmpPoligono.areaEfectiva = tmpPoligono2.area;
                                      tmpPoligono.enLinea = true;
                                      break;
                                    }
                                  }                                  
                                  tmpPoligono.direccion = direccionFinca;
                                  tmpPoligono.propietario = textoPropietario;
                                }
                                diff = Math.abs(diff);
                                var percent = diff * 100;
                                percent = percent / parseFloat($scope.dataTask.ttExento.longitud);
                                percent = Math.abs(percent);
                                var strMensaje = '';
                                if (percent >= 5) {
                                  $scope.dataTask.numero = -1;
                                  strMensaje = 'Hay una variación mayor al 5 % de la longitud registrada';
                                }
                                $scope.mapa.lineaOriginal = $scope.dataTask.ttExento.longitud;
                                $scope.mapa.direccion = finca.direccion + ', ' + finca.tcMunicipio.municipioDesc + ', ' + finca.tcMunicipio.tcDepartamento.departamentoDesc;
                                $scope.mapa.expediente = $scope.dataTask.ttExento.expediente;
                                $scope.mapa.codigoDesc = 'No. RNF';
                                $scope.mapa.codigo = $scope.dataTask.ttExento.codigo;
                                $scope.mapa.tipoDesc = 'Exentos de Licencia Forestal';
                                $scope.mapa.modalidadDesc = 'Exentos de Licencia Forestal';
                                $scope.textoPoligono = 'Cambiar línea';
                                if (traslapes == null) {
                                  traslapes = [];
                                }
                                if (traslapes.length > 0) {
                                  if (strMensaje.length > 0) {
                                    strMensaje += '\nLa línea tiene alguna intersección con las siguientes líneas o polígonos:\n\n';
                                  }
                                  let totalMostrar = traslapes.length > 10 ? 10 : traslapes.length;
                                  for (var i = 0; i < totalMostrar; i++) {
                                    strMensaje += (i + 1) + '. ' + traslapes[i].descripcion + ': ' + traslapes[i].referencia + '. Subregión: ' + traslapes[i].tcSubregion.subregionDesc + '\n';
                                  }
                                }
                                if (strMensaje.length > 0) {
                                  swal('Advertencia', strMensaje, 'error');
                                }
                                $scope.mapa.longitudTotal = parseFloat(longitudTotal).toFixed(2);
                              } else {
                                toastr.error('Error al calcular la longitud de la línea: ' + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                          if (areas.length > 0) {
                            if (parseFloat($scope.dataTask.ttExento.area) <= 0) {
                              toastr.error('No es posible continuar debido a que el área del exento es menor o igual a cero, favor de verificar');
                              $scope.dataTask.ttExento.poligonos = [];
                              $scope.mapa.lineas = [];
                              $scope.mapa.mapas = [];
                              return;
                            }
                            catalogosService.getAreaPoligono(areas).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                var traslapes = data.traslapes;
                                areaTotal = parseFloat(res.singleValue).toFixed(4);
                                var diff = parseFloat($scope.dataTask.ttExento.area) - parseFloat(areaTotal);
                                if (id == 1) {
                                  $scope.mapa.poligonos = areas;
                                  var areaPoligonos = data.poligonos;
                                  var poligonosEnviar = poligonos;
                                  for (var i = 0; i < poligonosEnviar.length; i++) {
                                    var tmpPoligono = poligonosEnviar[i];
                                    for (var j = 0; j < areaPoligonos.length; j++) {
                                      const tmpPoligono2 = areaPoligonos[j];
                                      if (tmpPoligono.numero == tmpPoligono2.numero) {
                                        tmpPoligono.area = tmpPoligono2.area;
                                        tmpPoligono.enLinea = false;
                                        break;
                                      }
                                    }
                                    tmpPoligono.direccion = direccionFinca;
                                    tmpPoligono.propietario = textoPropietario;
                                  }
                                  diff = Math.abs(diff);
                                  var percent = diff * 100;
                                  percent = percent / parseFloat($scope.dataTask.ttExento.area);
                                  percent = Math.abs(percent);
                                  var strMensaje = '';
                                  if (percent >= 5) {
                                    $scope.dataTask.numero = -1;
                                    strMensaje = 'Hay una variación mayor al 5 % del área registrada';
                                  }
                                  $scope.mapa.areaOriginal = $scope.dataTask.ttExento.area;
                                  $scope.mapa.direccion = direccionFinca;
                                  $scope.mapa.expediente = $scope.dataTask.ttExento.expediente;
                                  $scope.mapa.codigoDesc = 'No. RNF';
                                  $scope.mapa.codigo = $scope.dataTask.ttExento.codigo;
                                  $scope.mapa.tipoDesc = 'Exentos de Licencia Forestal';
                                  $scope.mapa.modalidadDesc = 'Exentos de Licencia Forestal';
                                  $scope.mapa.areaEfectiva = diff;
                                  $scope.mapa.areaDescuento = 0;
                                  for (let i = 0; i < poligonosEnviar.length; i++) {
                                    var element = poligonosEnviar[i];
                                    element.areaEfectiva = diff;
                                  }
                                  $scope.textoPoligono = 'Cambiar polígono';
                                  if (traslapes == null) {
                                    traslapes = [];
                                  }
                                  if (traslapes.length > 0) {
                                    if (strMensaje.length > 0) {
                                      strMensaje += '\nEl polígono tiene alguna intersección con los siguiente polígonos:\n\n';
                                    }
                                    let totalMostrar = traslapes.length > 10 ? 10 : traslapes.length;
                                    for (var i = 0; i < totalMostrar; i++) {
                                      strMensaje += (i + 1) + '. ' + traslapes[i].descripcion + ': ' + traslapes[i].referencia + '. Subregión: ' + traslapes[i].tcSubregion.subregionDesc + '\n';
                                    }
                                  }
                                  if (strMensaje.length > 0) {
                                    swal('Advertencia', strMensaje, 'error');
                                  }
                                  $scope.mapa.area = parseFloat(areaTotal).toFixed(4);
                                  $scope.mapa.areaEfectiva = parseFloat(areaTotal).toFixed(4);
                                  $scope.mapa.areaDescuento = 0;
                                } else {
                                  $scope.dataTask.ttExento.descuentos = poligonos;
                                  $scope.mapa.descuentos = data.poligonos;
                                  var area = $scope.mapa.area;
                                  var areaEfectiva = area - areaTotal;
                                  diff = parseFloat($scope.dataTask.ttExento.area) - areaEfectiva;
                                  var percent = diff * 100;
                                  percent = percent / parseFloat($scope.dataTask.ttExento.area);
                                  percent = Math.abs(percent);
                                  var strMensaje = '';
                                  if (percent >= 5) {
                                    $scope.dataTask.numero = -1;
                                    swal('Advertencia', 'Hay una variación mayor al 5 % del área registrada', 'error');
                                  } else {
                                    toastr.success('Coordenadas cargadas correctamente');
                                  }
                                  $scope.mapa.areaEfectiva = parseFloat(areaEfectiva).toFixed(4);
                                  $scope.mapa.areaDescuento = parseFloat(areaTotal).toFixed(4);
                                  for (let i = 0; i < poligonosEnviar.length; i++) {
                                    var element = poligonosEnviar[i];
                                    element.areaEfectiva = $scope.mapa.areaEfectiva;
                                    element.areaDescuento = $scope.mapa.areaDescuento;
                                  }
                                }
                              } else {
                                toastr.error('Error al calcular el área del polígono: ' + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        } else {
                          if ($scope.veces > 0) {
                            $timeout(function () {
                              $scope.veces--;
                              $scope.onFileSelect(file, id);
                            }, 1000);
                          } else {
                            $scope.veces = 2;
                            $scope.visibleMapa = false;
                            swal('No se pudo cargar el archivo');
                          }
                        }
                      } else {
                        $scope.visibleMapa = false;
                        swal('No se podigo cargar el archivo, verifique que sea el formato correcto');
                      }
                    }

                    $scope.visualizarMapa = function (poligono, numero, esLinea) {
                      if (esLinea == null) {
                        esLinea = false;
                      }                      
                      if (esLinea) {
                        $scope.mapa.encodeMapa = 'path=color:0xff0000ff%7Cweight:5%7Cenc:' + poligono.encodeMapa;
                      } else {
                        $scope.mapa.encodeMapa = 'path=fillcolor:0xAA000033%7Ccolor:0xFFFFFF00%7Cenc:' + poligono.encodeMapa;
                      }
                      $scope.mapa.coordenadasGtm = poligono.coordenadasGtm;
                      $scope.mapa.numero = parseInt(numero) + 1;
                      $scope.mapa.areaPoligono = parseFloat(poligono.area).toFixed(4);
                      if (poligono.enLinea == null) {
                        poligono.enLinea = false;
                      }
                      if (poligono.enLinea) {
                        $scope.mapa.longitud = parseFloat(poligono.area).toFixed(2);
                      }
                      $scope.mapa.enLinea = poligono.enLinea;
                      /*$scope.activo.uno = false;
                      $scope.activo.dos = true;*/
                      ngDialog.open({
                        template: 'app/common/poligono.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'custom-map',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniquePoligono',
                        name: 'uniquePoligono',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {

                          }]
                      }) // fin ventana enmiendas
                    }

                    $scope.imprimirMapa = function () {
                      utilsService.openWindow('#poligono', $scope, 'Mapa');
                    };

                    $scope.verDetalleVenta = function (venta) {
                      if (venta.resolucion == null) {
                        toastr.error('Debe indicar los datos de la resolución para poder ver el detalle');
                        venta.finalizada = true;
                        venta.estadoId = 5;
                        return;
                      }
                      if (venta.resolucion.trim().length <= 0) {
                        toastr.error('Debe indicar los datos de la resolución para poder ver el detalle');
                        venta.finalizada = true;
                        venta.estadoId = 5;
                        return;
                      }
                      if (venta.saldosTmp == null) {
                        venta.saldosTmp = [];
                      }

                      if (venta.rangos == null) {
                        venta.rangos = [];
                      }

                      for (var i = 0; i < venta.rangos.length; i++) {
                        var rango = venta.rangos[i];
                        var cantidad = rango.fin - rango.inicio + 1;
                        rango.cantidad = cantidad;
                        rango.reportado = [];
                        rango.abrirMarcarRango = false;
                        for (var j = parseInt(rango.inicio); j <= parseInt(rango.fin); j++) {
                          rango.reportado.push({ usoId: -1, numero: j });
                        }
                      }

                      var informes = venta.informes;
                      if (informes == null) {
                        informes = [];
                      }
                      for (var h = 0; h < informes.length; h++) {
                        var informe = informes[h];
                        for (var i = 0; i < informe.notas.length; i++) {
                          var nota = informe.notas[i];
                          var found = false;
                          for (var j = 0; j < venta.rangos.length; j++) {
                            var rango = venta.rangos[j];
                            for (var k = 0; k < rango.reportado.length; k++) {
                              if (nota.numero == rango.reportado[k].numero) {
                                found = true;
                                rango.reportado[k].usoId = nota.usoId;
                                break;
                              }
                            }
                            if (found) {
                              break;
                            }
                          }
                        }
                      }

                      $scope.venta = venta;
                      ngDialog.open({
                        template: 'app/tarea/detalle.venta.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueVenta',
                        name: 'uniqueVenta',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {

                            $scope.dataUso = [
                              { usoId: 0, usoDesc: 'Anulado' },
                              { usoId: 1, usoDesc: 'Usado' },
                              { usoId: 2, usoDesc: 'Extraviado' },
                              { usoId: -1, usoDesc: 'No reportado' },
                            ];

                            $scope.agregarEspecieVenta = function () {
                              $scope.venta.saldosTmp.push({ tcEspecie: null, troza: 0, lenia: 0, volumen: 0 });
                            }

                            $scope.agregarTodaEspecieVenta = function () {
                              var especies = $scope.dataTask.ttExento.especies;
                              for (var i = 0; i < especies.length; i++) {
                                var especie = especies[i];
                                $scope.venta.saldosTmp.push({ tcEspecie: especie.tcEspecie, troza: 0, lenia: 0, volumen: 0 });
                              }
                            }

                            $scope.borrarEspecieVenta = function (item) {
                              $scope.venta.saldosTmp.splice($scope.venta.saldosTmp.indexOf(item), 1);
                            }

                            $scope.setEspecieDetalleVenta = function (item, especie) {
                              var data = $scope.venta.saldosTmp;
                              var conteo = 0;
                              for (var i = 0; i < data.length; i++) {
                                if (data[i].tcEspecie.especieId == item.especieId) {
                                  conteo++;
                                }
                              }
                              if (conteo > 1) {
                                especie.tcEspecie = null;
                              } else {
                                especie.tcEspecie.especieDesc = item.especieDesc;
                                especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                              }
                            }

                            $scope.setVolumenTotalVenta = function (row) {
                              if (row.troza != null && row.lenia != null) {
                                row.total = parseFloat(row.troza) + parseFloat(row.lenia);
                                row.total = parseFloat(row.total).toFixed(2);
                                row.volumen = parseFloat(row.total).toFixed(2);
                              }
                            }

                            $scope.agregarNotaVenta = function () {
                              $scope.venta.rangos.push({ inicio: 0, fin: 0, esRevalidacion: 0, cantidad: 0 });
                            }

                            $scope.borrarNotaVenta = function (item) {
                              $scope.venta.rangos.splice($scope.venta.rangos.indexOf(item), 1);
                            }

                            $scope.setCantidadRango = function (rango) {
                              if (rango.fin == null) {
                                rango.fin = 0;
                              }
                              if (rango.fin > 0) {
                                if (rango.inicio > rango.fin) {
                                  toastr.error('El rango inicial debe ser menor al final');
                                  rango.inicio = 0;
                                  return;
                                } else {
                                  var cantidad = rango.fin - rango.inicio + 1;
                                  var index = parseInt(rango.inicio);
                                  rango.cantidad = cantidad;
                                  rango.reportado = [];
                                  for (var i = 0; i < cantidad; i++) {
                                    rango.reportado.push({ usoId: -1, numero: index });
                                    index++;
                                  }
                                }
                              }
                            }

                            $scope.marcarRangoNotaVenta = function (rango) {
                              rango.abrirMarcarRango = true;
                            }

                            $scope.marcarCorrelativosRango = function (rango) {
                              if (rango.inicioMarca == null || rango.finMarca == null) {
                                toastr.error('Debe indicar un rango valido');
                                return;
                              }
                              if (parseInt(rango.inicioMarca) > parseInt(rango.finMarca)) {
                                toastr.error('Debe indicar un rango valido');
                                return;
                              }
                              if (parseInt(rango.inicioMarca) < parseInt(rango.inicio) || parseInt(rango.finMarca) > parseInt(rango.fin)) {
                                toastr.error('El rango a marcar está fuera del rango de las notas');
                                return;
                              }
                              var reportado = rango.reportado;
                              for (var i = rango.inicioMarca; i <= rango.finMarca; i++) {
                                for (var j = 0; j < reportado.length; j++) {
                                  nota = reportado[j];
                                  if (parseInt(nota.numero) == i) {
                                    nota.usoId = rango.usoMarcaId;
                                    break;
                                  }
                                }
                              }
                              rango.abrirMarcarRango = false;
                            }

                            $scope.cancelarMarcarCorrelativosRango = function (rango) {
                              rango.abrirMarcarRango = false;
                            }

                            $scope.aceptarSaldoVenta = function (isValid) {
                              if (isValid) {
                                var rangos = $scope.venta.rangos;
                                var cantidad = 0;
                                var notas = [];
                                var nAutorizado = 0;
                                for (var i = 0; i < rangos.length; i++) {
                                  var rango = rangos[i];
                                  for (var j = 0; j < rango.reportado.length; j++) {
                                    var fila = rango.reportado[j];
                                    if (parseInt(fila.usoId) > -1) {
                                      cantidad++;
                                    }
                                    notas.push({ numero: parseInt(fila.numero), usoId: fila.usoId });
                                  }
                                  nAutorizado += parseInt(rango.cantidad);
                                }
                                if ($scope.venta.ultimoInforme == null) {
                                  $scope.venta.ultimoInforme = 0;
                                }
                                var informe = {
                                  correlativo: $scope.venta.ultimoInforme,
                                  totalNotas: cantidad,
                                  notas: notas
                                };
                                $scope.venta.informes = [
                                  informe
                                ];
                                $scope.venta.autorizadoNotas = cantidad;
                                $scope.venta.cantidadNotas = nAutorizado;
                                ngDialog.close();
                              } else {
                                toastr.error('Debe rellenar todos los campos');
                              }
                            } //fin submitFormData
                          }]
                      }) // fin ventana enmiendas
                    }

                    $scope.setRevalidar = function () {
                      if ($scope.dataTask.ttExento.ventas == null) {
                        $scope.dataTask.ttExento.ventas = [];
                      }
                      var paraRevalidar = 0;
                      if ($scope.opciones.revalidar) {
                        paraRevalidar = 1;
                      }
                      for (var i = 0; i < $scope.dataTask.ttExento.ventas.length; i++) {
                        var row = $scope.dataTask.ttExento.ventas[i];
                        row.paraRevalidar = paraRevalidar;
                      }
                    }

                    $scope.agregarResolucion = function () {
                      if ($scope.dataTask.ttExento.ventas == null) {
                        $scope.dataTask.ttExento.ventas = [];
                      }
                      var paraRevalidar = 0;
                      if ($scope.opciones.revalidar) {
                        paraRevalidar = 1;
                      }
                      $scope.dataTask.ttExento.ventas.push(
                        {
                          resolucion: null,
                          rodales: [],
                          estadoId: 5,
                          finalizada: true,
                          paraRevalidar: paraRevalidar
                        }
                      );
                    }

                    $scope.setVentaFinalizada = function (venta) {
                      if (venta.finalizada) {
                        venta.estadoId = 5;
                        venta.saldosTmp = [];
                        venta.rangos = [];
                        venta.informes = [];
                        venta.paraRevalidar = 0;
                      } else {
                        venta.estadoId = 4;
                        $scope.verDetalleVenta(venta);
                        venta.paraRevalidar = 1;
                      }
                    }

                    $scope.quitarResolucion = function (venta) {
                      $scope.dataTask.ttExento.ventas.splice($scope.dataTask.ttExento.ventas.indexOf(venta), 1);
                    }

                    $scope.agregarRodal = function (venta) {
                      if (venta.resolucion == null) {
                        toastr.error('Indique primero el número de resolución de venta de notas de envío');
                        return;
                      } else {
                        if (venta.resolucion.length <= 0) {
                          toastr.error('Indique primero el número de resolución de venta de notas de envío');
                          return;
                        }
                      }
                      venta.rodales = [];
                    }

                    $scope.setCorrelativoRodal = function (item, rodal) {
                      for (var i = 0; i < $scope.dataTask.ttExento.rodales.length; i++) {
                        if ($scope.dataTask.ttExento.rodales[i].correlativo == item.correlativo) {
                          var dataEspecie = [];
                          var item = $scope.dataTask.ttExento.rodales[i];
                          for (var j = 0; j < item.especies.length; j++) {
                            dataEspecie.push(
                              item.especies[j].tcEspecie
                            );
                          }
                          rodal.area = item.area;
                          rodal.dataEspecie = dataEspecie;
                        }
                      }
                    }

                    $scope.quitarRodal = function (venta, rodal) {
                      venta.rodales.splice(venta.rodales.indexOf(rodal), 1);
                    }

                    $scope.agregarEspecieRodal = function (venta) {
                      if (venta.saldos == null) {
                        venta.saldos = [];
                      }
                      venta.saldos.push(
                        {
                          tcEspecie: null,
                          troza: 0,
                          lenia: 0,
                          total: 0
                        }
                      );
                    }

                    $scope.quitarEspecieRodal = function (venta, especie) {
                      venta.saldos.splice(venta.saldos.indexOf(especie), 1);
                    }

                    $scope.setVolumenTotal = function (row, venta) {
                      if (row.troza != null && row.lenia != null) {
                        row.total = parseFloat(row.troza) + parseFloat(row.lenia);
                        row.total = parseFloat(row.total).toFixed(2);
                        row.volumen = row.total;
                      }
                      var total = 0;
                      for (var i = 0; i < venta.saldos.length; i++) {
                        total += venta.saldos[i].total;
                      }
                      venta.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.setEspecieVenta = function (item, especie, venta) {
                      var conteo = 0;
                      for (var i = 0; i < venta.saldos.length; i++) {
                        if (venta.saldos[i].tcEspecie != null) {
                          if (venta.saldos[i].tcEspecie.especieId == item.especieId) {
                            conteo++;
                          }
                        }
                      }
                      if (conteo > 1) {
                        especie.tcEspecie.especieId = null;
                        especie.tcEspecie = null;
                        especie.saldo = 0;
                        especie.lenia = 0;
                        especie.total = 0;
                        toastr.error('No es posible repetir la especie');
                      } else {
                        especie.tcEspecie.especieDesc = item.especieDesc;
                        especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                        especie.tcEspecie.codigo = item.codigo;
                      }
                    }

                    function organizarDatosTecnicoCarga(ventas) {
                      if (ventas == null) {
                        ventas = [];
                      }
                      for (var i = 0; i < ventas.length; i++) {
                        if (ventas[i].estadoId == 4) {
                          if (ventas[i].saldos == null) {
                            ventas[i].saldos = [];
                          }
                          var saldosVenta = angular.copy(ventas[i].saldos);
                          var saldosTmp = angular.copy(ventas[i].saldosTmp);
                          for (var j = 0; j < saldosTmp.length; j++) {
                            var saldo = saldosTmp[j];
                            saldo.saldo = saldo.volumen;
                            saldo.reserva = 0;
                          }
                          for (var j = 0; j < saldosVenta.length; j++) {
                            var encontrado = false;
                            for (var k = 0; k < saldosTmp.length; k++) {
                              if (saldosVenta[j].tcEspecie.especieId == saldosTmp[k].tcEspecie.especieId) {
                                var saldo = parseFloat(saldosTmp[k].volumen) - parseFloat(saldosVenta[j].volumen);
                                saldosTmp[k].saldo = parseFloat(saldo);
                                saldosTmp[k].reserva = parseFloat(saldosVenta[j].volumen);
                                saldosTmp[k].reservaTroza = parseFloat(saldosVenta[j].troza);
                                saldosTmp[k].reservaLenia = parseFloat(saldosVenta[j].lenia);
                                saldosTmp[k].ttSaldoCargaVenta = {
                                  volumen: saldosVenta[j].volumen,
                                  troza: saldosVenta[j].troza,
                                  lenia: saldosVenta[j].lenia,
                                }
                                encontrado = true;
                                break;
                              }
                            }
                            if (!encontrado) {
                              swal('El detalle autorizado y el detalle transportado no coinciden para la resolución ' + ventas[i].resolucion);
                              return;
                            }
                          }
                          var cantidadNotas = ventas[i].cantidadNotas;
                          ventas[i].cantidadNotas = ventas[i].autorizadoNotas;
                          ventas[i].autorizadoNotas = cantidadNotas;
                          var percent = (ventas[i].cantidadNotas * 100) / ventas[i].autorizadoNotas;
                          ventas[i].porcentajeReportado = Math.round(percent);
                          var informes = ventas[i].informes;
                          if (informes == null) {
                            informes = [];
                          }
                          if (informes.length > 0) {
                            var informe = informes[0];
                            var notas = [];
                            var tmpNotas = informe.notas;
                            for (var j = 0; j < tmpNotas.length; j++) {
                              if (tmpNotas[j].usoId > -1) {
                                notas.push(tmpNotas[j]);
                              }
                            }
                            ventas[i].informes[0].notas = notas;
                          }
                          ventas[i].saldos = saldosTmp;
                        }
                      }
                      return ventas;
                    }

                    $scope.vistaPreviaDictamen = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de dictamen');
                        return;
                      }
                      if ($scope.dataTask.codigo.length <= 0) {
                        toastr.error('Debe indicar el número de dictamen');
                        return;
                      }
                      if ($scope.cambioSinGuardar) {
                        toastr.error('El volumen por cada especie ha sido modificado y no se han guardado los cambios, favor de presionar sobre el botón correspondiente');
                        return;
                      }
                      if ($scope.dataTask.ttExento.ventas != null && $scope.dataTask.esEnmienda == 0) {
                        var ventas = $scope.dataTask.ttExento.ventas;
                        for (var i = 0; i < ventas.length; i++) {
                          var venta = ventas[i];
                          if (venta.resolucion == null) {
                            toastr.error('Debe indicar el número de la resolución de venta');
                            return;
                          }
                          if (venta.fechaAutorizacion == null) {
                            toastr.error('Debe indicar la fecha de la resolución de la venta');
                            return;
                          }
                          if (venta.autorizadoNotas == null) {
                            toastr.error('Debe indicar la cantidad de notas reportadas de la resolución de la venta');
                            return;
                          }
                          if (venta.finalizada) {
                            if (venta.saldos == null) {
                              toastr.error('Para las resoluciones finalizadas debe indicar el volumen por especie transportado');
                              return;
                            } else {
                              var saldos = venta.saldos;
                              for (var j = 0; j < saldos.length; j++) {
                                var saldo = saldos[j];
                                if (saldo.tcEspecie == null) {
                                  toastr.error('Debe indicar la especie transportada');
                                  return;
                                }
                                if (saldo.total <= 0) {
                                  toastr.error('Debe indicar el volumen de troza y/o leña transportado');
                                  return;
                                }
                              }
                            }
                          }
                        }
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = angular.copy($scope.dataTask);
                      utilsService.setEsPrevia(!refresh);
                      if (data.esEnmienda == 1) {
                        utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        var ttExento = data.ttExento;
                        var ventas = ttExento.ventas;
                        ventas = organizarDatosTecnicoCarga(ventas);
                        ttExento.ventas = ventas;
                        utilsService.generarInformeCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (!isValid) {
                        toastr.error('Debe rellenar los campos obligatorios');
                      }
                      if (isValid) {
                        if ($scope.cambioSinGuardar) {
                          toastr.error('El volumen por cada especie ha sido modificado y no se han guardado los cambios, favor de presionar sobre el botón correspondiente');
                          return;
                        }
                        var tmp = $scope.dataTask.ttExento.ventas;
                        if (tmp == null) {
                          tmp = [];
                        }
                        for (var i = 0; i < tmp.length; i++) {
                          if (tmp[i].estadoId == 4) {
                            if (tmp[i].saldos != null) {
                              if (tmp[i].saldos.length > tmp[i].saldosTmp.length) {
                                swal('El detalle de la resolución ' + tmp[i].resolucion + ' no es correcta, favor de verificar');
                                return;
                              }
                            }
                          }
                        }
                        if ($scope.dataTask.ttExento.ventas != null) {
                          var ventas = $scope.dataTask.ttExento.ventas;
                          for (var i = 0; i < ventas.length; i++) {
                            var venta = ventas[i];
                            if (venta.finalizada) {
                              if (venta.saldos == null) {
                                toastr.error('Para las resoluciones finalizadas debe indicar el volumen por especie transportado');
                                return;
                              } else {
                                var saldos = venta.saldos;
                                for (var j = 0; j < saldos.length; j++) {
                                  var saldo = saldos[j];
                                  if (saldo.total <= 0) {
                                    toastr.error('Debe indicar el volumen de troza y/o leña transportado');
                                    return;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar. NO hay regreso de tarea",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              if (data.observaciones != null) {
                                data.observaciones = JSON.stringify(data.observaciones);
                              }
                              if (data.esEnmienda == 1) {
                                delete data.ttExento;
                                delete data.ventas;
                                data.aprobado = 2;
                                tareaService.enmiendaTecnicoCargaInicialExento(data).then(function (res) {
                                  if (res.status == 1) {
                                    toastr.success(res.message);
                                    $scope.vistaPreviaDictamen(true);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                var ttExento = data.ttExento;
                                var ventas = ttExento.ventas;
                                ventas = organizarDatosTecnicoCarga(ventas);
                                var paraRevalidar = 0;
                                if ($scope.opciones.revalidar) {
                                  paraRevalidar = 1;
                                }
                                for (var i = 0; i < ventas.length; i++) {
                                  ventas[i].paraRevalidar = paraRevalidar;
                                }
                                data.ttExento = {};
                                data.ttExento = {
                                  exentoId: ttExento.exentoId,
                                  tcTipoGestion: ttExento.tcTipoGestion,
                                  estadoId: ttExento.estadoId,
                                  tcPersonaCrea: ttExento.tcPersonaCrea,
                                  ventas: ventas,
                                  poligonos: ttExento.poligonos,
                                  descuentos: ttExento.descuentos
                                };
                                data.fechaProvidencia = new Date();
                                data.aprobado = 1;
                                tareaService.finalizarInformeCargaInicial(data).then(function (res) {
                                  if (res.status == "OK") {
                                    toastr.success(res.message);
                                    $scope.vistaPreviaDictamen(true);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }
                          });
                      }
                    }

                    $scope.solicitarEnmiendas = function () {
                      $scope.dataTask.esEnmienda = 1;
                    }

                    $scope.agregarObservacion = function () {
                      if ($scope.dataTask.observaciones.razon == null) {
                        $scope.dataTask.observaciones = {
                          razon: []
                        };
                      }
                      $scope.dataTask.observaciones.razon.push({ descripcion: null });
                    }

                    $scope.quitarObservacion = function (item) {
                      $scope.dataTask.observaciones.razon.splice($scope.dataTask.observaciones.razon.indexOf(item), 1);
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notiexento', {
            url: '/notiexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notiexento.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getDatosTareaExento($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    $scope.dataTask.observaciones = {
                      observaciones: [],
                      rangos: []
                    };
                    if ($scope.dataTask.esEnmienda < 0) {
                      $scope.dataTask.esEnmienda = 0;
                    }
                    $scope.tituloObservaciones = 'Observaciones';

                    $scope.esProvidencia = false;
                    $scope.titulo = '';

                    var obtenerTextoRangos = function (rangos) {
                      var primero = 0, ultimo = 0;
                      var texto = '';
                      if (rangos.length > 0) {
                        rangos.sort(function (a, b) { return a - b });
                        primero = rangos[0];
                        ultimo = primero;
                        ultimo--;
                        for (var i = 0; i < rangos.length; i++) {
                          const numero = rangos[i];
                          var diff = numero - ultimo;
                          if (diff > 1) {
                            if (texto.length <= 0) {
                              texto = primero + ' - ' + ultimo;
                            } else {
                              texto = texto + '\n' + primero + ' - ' + ultimo;
                            }
                            primero = numero;
                          }
                          ultimo = numero;
                        }
                        if (texto.length <= 0) {
                          texto = primero + ' - ' + ultimo;
                        } else {
                          texto = texto + '\n' + primero + ' - ' + ultimo;
                        }
                      }
                      return texto;
                    }
                    if ($scope.dataTask.esEnmienda == 1) {
                      var ttPadre = $scope.dataTask.ttPadre;
                      try {
                        var observaciones = JSON.parse(ttPadre.observaciones);
                        var razones = observaciones.razon;
                        $scope.dataTask.observaciones = razones;
                      } catch (error) {
                        toastr.error('Las enmiendas no están correctas, favor de revisar el dictamen del técnico');
                      }
                      $scope.dataTask.codigo = 'XXX-XXXX';
                    } else {
                      var ventas = $scope.dataTask.ttExento.ventas;
                      if (ventas != null) {
                        var rangos = [];
                        for (var i = 0; i < ventas.length; i++) {
                          var rango = {
                            usados: '',
                            anulados: '',
                            extraviados: '',
                            pendientes: ''
                          }
                          var usados = [];
                          var anulados = [];
                          var extraviados = [];
                          var venta = ventas[i];
                          var informes = venta.informes;
                          for (var j = 0; j < informes.length; j++) {
                            const informe = informes[j];
                            var notas = informe.notas;
                            for (var k = 0; k < notas.length; k++) {
                              var nota = notas[k];
                              switch (nota.usoId) {
                                case 0:
                                  anulados.push(nota.numero);
                                  break;
                                case 1:
                                  usados.push(nota.numero);
                                  break;
                                case 2:
                                  extraviados.push(nota.numero);
                                  break;
                              }
                            }
                          }
                          rango.usados = obtenerTextoRangos(usados);
                          rango.anulados = obtenerTextoRangos(anulados);
                          rango.extraviados = obtenerTextoRangos(extraviados);
                          var rangosRevalidar = venta.rangosRevalidar;
                          var textoRevalidar = '';
                          for (let j = 0; j < rangosRevalidar.length; j++) {
                            const rangoRevalidar = rangosRevalidar[j];
                            if (j == 0) {
                              textoRevalidar = rangoRevalidar.inicio + ' - ' + rangoRevalidar.fin;
                            } else {
                              textoRevalidar += '\n' + rangoRevalidar.inicio + ' - ' + rangoRevalidar.fin;
                            }
                          }
                          var textoAnulado = '';
                          if (venta.fechaFinalizacion != null) {
                            textoAnulado = '\nVence: ' + $filter('date')(new Date(venta.fechaFinalizacion), 'dd/MM/yyyy');
                          }
                          rango.pendientes = textoRevalidar;
                          rango.resolucion = venta.resolucion + textoAnulado;
                          if (rango.usados.length > 0
                            || rango.anulados.length > 0
                            || rango.extraviados.length > 0
                            || rango.pendientes.length > 0) {
                            rangos.push(rango);
                          }
                        }
                        $scope.dataTask.observaciones.rangos = rangos;
                      }
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if (refresh) {
                        tareaService.getDataExentoTareaFinalizada($scope.dataTask.tareaExentoId).then(function (res) {
                          if (res.status == 'OK') {
                            data = res.data[0];
                            var data = res.data[0];
                            if (data.observaciones != null) {
                              try {
                                data.observaciones = JSON.parse(data.observaciones);
                              } catch (error) {}
                            }
                            if (data.esEnmienda == -1) {
                              var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                              var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                              var strDia = $scope.numeroALetras(dia, false);
                              var strAnio = $scope.numeroALetras(anio, false);
                              utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                            } else {
                              if (data.esEnmienda == 1) {
                                utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                              } else {
                                var especies = data.ttExento.especies;
                                for (var i = 0; i < especies.length; i++) {
                                  especies[i].saldo = especies[i].ttEspecieSaldoCarga.saldo;
                                  especies[i].reserva = especies[i].ttEspecieSaldoCarga.reserva;
                                  especies[i].volumen = especies[i].ttEspecieSaldoCarga.volumen;
                                }
                                utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                              }
                            }
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        $state.go('index.tarea.exentolist');
                      } else {
                        if ($scope.dataTask.fechaFinalizacion == null) {
                          $scope.dataTask.fechaFinalizacion = new Date();
                        }
                        var data = angular.copy($scope.dataTask);
                        utilsService.setEsPrevia(true);
                        if (data.esEnmienda == -1) {
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);                          
                          utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          if (data.esEnmienda == 1) {
                            utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          } else {
                            utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          }
                        }
                      }
                    }

                    $scope.agregarRegistro = function () {
                      if ($scope.dataTask.observaciones == null) {
                        if ($scope.dataTask.esEnmienda == 1) {
                          $scope.dataTask.observaciones = [];
                        } else {
                          $scope.dataTask.observaciones = {
                            observaciones: [],
                            rangos: []
                          };
                        }
                      }
                      if ($scope.dataTask.esEnmienda == 1) {
                        $scope.dataTask.observaciones.push({ descripcion: null });
                      } else {
                        $scope.dataTask.observaciones.observaciones.push({ observacion: null });
                      }
                    }

                    $scope.quitarDetalle = function (detalle) {
                      if ($scope.dataTask.esEnmienda == 1) {
                        $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(detalle), 1);
                      } else {
                        $scope.dataTask.observaciones.observaciones.splice($scope.dataTask.observaciones.observaciones.indexOf(detalle), 1);
                      }
                    }

                    $scope.notificarResolucion = function () {
                      swal({
                        title: "No hay regreso de tarea",
                        text: "¿Está seguro (a) de guardar y continuar la tarea? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            var ttExento = data.ttExento;
                            data.ttExento = {};
                            data.ttExento = {
                              exentoId: ttExento.exentoId,
                              tcTipoGestion: ttExento.tcTipoGestion,
                              estadoId: ttExento.estadoId,
                              tcPersonaCrea: ttExento.tcPersonaCrea,
                              ventas: ttExento.ventas
                            };
                            data.fechaFinalizacion = new Date();                            
                            if ($scope.dataTask.esEnmienda == -1) {
                              delete data.observaciones;
                              tareaService.providenciarCargaInicial(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);                                  
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              data.aprobado = 1;                            
                              data.observaciones = JSON.stringify(data.observaciones);
                              tareaService.notificarCargaInicialExento(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.dataTask.codigo = res.singleValue;
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setTecnico = function ( item ) {
                      $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                    }

                    $scope.providenciarTecnico = function () {
                      swal({
                        title: "Confirmación",
                        text: "La tarea cambiará ¿Está seguro (a) de continuar? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.esProvidencia = true;
                            $scope.titulo = 'Providenciar';
                            $scope.dataTask.codigo = null;
                            $scope.dataTask.esEnmienda = -1;
                            if ($scope.dataTecnico == null) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provventa', {
            url: '/provventa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provventa.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaVentaExento($base64.decode($stateParams.tareaId), 2);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataTecnico', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, dataTecnico, authService) {
                    $scope.dataTecnico = dataTecnico.data;
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.tcPersonaTraslado == null) {
                        toastr.error('Debe seleccionar a la técnico a asignar');
                        return;
                      }
                      try {
                        var data = angular.copy($scope.dataTask);
                        if (data.fechaFinalizacion == null) {
                          data.fechaFinalizacion = new Date();
                        }
                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia, false);
                        var strAnio = $scope.numeroALetras(anio, false);
                        utilsService.setEsPrevia(!refresh);
                        utilsService.generarProvidenciaVentaNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } catch (error) { }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttExento = data.ttExento;
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea,
                                rodales: ttExento.rodales
                              };
                              data.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              data.tcPersonaAsignada.foto = null;
                              data.tcPersonaTraslado.foto = null;
                              data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                              tareaService.asignarProvidenciaVentaNota(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaAsignacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opventa', {
            url: '/opventa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.opventa.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaVentaExento($base64.decode($stateParams.tareaId), 1);
                    }],
                  dataTipoTransporte: ['tipotransporteService',
                    function (tipotransporteService) {
                      return tipotransporteService.listaActiva();
                    }],
                  dataTratamientoSilvicultural: ['tratamientosilviculturalService',
                    function (tratamientosilviculturalService) {
                      return tratamientosilviculturalService.listaActivaExento();
                    }],
                  dataActividadForestal: ['actividadforestalService',
                    function (actividadforestalService) {
                      return actividadforestalService.listaActiva();
                    }],
                  dataEspecie: ['dataTarea', 'especieService',
                    function (dataTarea, especieService) {
                      var ttTarea = dataTarea.data[0];
                      return especieService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', 'dataEspecie',
                  'dataTarea', '$filter', 'authService', 'dataTipoTransporte', 'dataTratamientoSilvicultural', 'Upload', 'dataActividadForestal',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, dataEspecie,
                    dataTarea, $filter, authService, dataTipoTransporte, dataTratamientoSilvicultural, Upload, dataActividadForestal) {

                    $scope.dataTask = dataTarea.data[0];
                    var ttVentaExento = $scope.dataTask.ttVentaExento;
                    $scope.esRevalidacion = false;
                    $scope.mostrarNotas = false;
                    $scope.textoComplemento = '';
                    if (ttVentaExento.paraRevalidar == 1) {
                      $scope.esRevalidacion = true;
                      $scope.textoComplemento = '(Revalidación)';
                      if (parseInt(ttVentaExento.cantidadNotas) > 0) {
                        $scope.mostrarNotas = true;
                      }
                    } else {
                      $scope.mostrarNotas = true;
                    }
                    $scope.dataTask.aprobado = 1;
                    $scope.dataTipoTransporte = dataTipoTransporte.data;
                    $scope.dataTratamientoSilvicultural = dataTratamientoSilvicultural.data;
                    $scope.dataActividadForestal = dataActividadForestal.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }
                    authService.removeLocalData('dataEspecie');
                    authService.saveLocalData('dataEspecie', dataEspecie.data);

                    $scope.classButton = "btn btn-danger";
                    $scope.dataTask.nombreArchivo = "Clic para cargar";

                    $scope.revalidar = false;

                    $scope.onFileSelect = function (file, tipoAnexoId) {
                      Upload.upload({
                        url: appSettings.urlServiceBase + 'file/upload',
                        method: 'POST',
                        file: file,
                        sendFieldsAs: 'form',
                        fields: {
                          data: []
                        }
                      }).then(function (resp) {
                        var result = resp.data;
                        if (result.status == 'OK') {
                          var item = result.data[0];
                          $scope.dataTask.rutaAnexo = item.rutaArchivo;
                          $scope.dataTask.nombreArchivo = "Cambiar";
                          $scope.classButton = "btn btn-primary";
                          $scope.visibleAbrir = true;
                        } else {
                          toastr.error(resp.data.message)
                        }
                      }, function (resp) {
                        toastr.error('Error status: ' + resp.status);
                      }, function (evt) {
                        var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                        $scope.avance = progressPercentage;
                      });
                    }

                    $scope.cerrarVentana = function () {
                      ngDialog.close();
                    }

                    $scope.subirBoleta = function () {
                      ngDialog.open({
                        template: 'app/tarea/boleta.exento.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueSubir',
                        name: 'dialogSubir',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr', 'authService',
                          function ($scope, $state, toastr, authService) {

                            $scope.gridData = angular.copy($scope.gridOptionsSelection);
                            $scope.gridData.enableFiltering = false;

                            $scope.submitFormData = function (isValid) {
                              if (isValid) {
                                var data = $scope.gridData.data;
                                var dataEspecie = authService.getLocalData('dataEspecie');
                                //obtener promedios por rodal
                                var rodales = [];
                                for (var i = 0; i < data.length; i++) {
                                  var row = data[i];
                                  var rodal = {
                                    correlativo: row.RODAL,
                                    area: row.AREA_RODAL,
                                    especies: [],
                                    parcelas: [],
                                    areaMuestreada: 0,
                                    tamanioParcela: row.TAMANIO_PARCELA == null ? 0 : row.TAMANIO_PARCELA
                                  }
                                  var encontrado = false;
                                  for (var j = 0; j < rodales.length; j++) {
                                    if (rodales[j].correlativo == rodal.correlativo) {
                                      rodal = rodales[j];
                                      encontrado = true;
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    rodales.push(rodal);
                                  }
                                  var tcEspecie = {
                                    especieId: row.ESPECIE_ID,
                                    nombreCientifico: row.NOMBRE_CIENTIFICO
                                  };
                                  var especie = {
                                    tcEspecie: tcEspecie,
                                    arbolesAutorizado: 1,
                                    dap: parseFloat(row.DAP),
                                    altura: parseFloat(row.ALTURA),
                                    volumen: parseFloat(row.VOLUMEN),
                                    areaBasal: parseFloat(row.AREA_BASAL),
                                    tipoInventarioId: 1
                                  }
                                  encontrado = false;
                                  for (var j = 0; j < rodal.especies.length; j++) {
                                    if (rodal.especies[j].tcEspecie.especieId == especie.tcEspecie.especieId) {
                                      especie = rodal.especies[j];
                                      especie.arbolesAutorizado += 1;
                                      especie.dap += parseFloat(row.DAP);
                                      especie.altura += parseFloat(row.ALTURA);
                                      especie.volumen += parseFloat(row.VOLUMEN);
                                      especie.areaBasal += parseFloat(row.AREA_BASAL);
                                      encontrado = true;
                                      break;
                                    }
                                  }
                                  if (!encontrado) {
                                    rodal.especies.push(especie);
                                  }
                                  if (row.PARCELA != null && row.TAMANIO_PARCELA != null) {
                                    especie.tipoInventarioId = 2;
                                    var parcela = {
                                      parcela: row.PARCELA,
                                      tamanioParcela: row.TAMANIO_PARCELA
                                    }
                                    encontrado = false;
                                    for (var j = 0; j < rodal.parcelas.length; j++) {
                                      if (rodal.parcelas[j].parcela == parcela.parcela) {
                                        encontrado = true;
                                        break;
                                      }
                                    }
                                    if (!encontrado) {
                                      rodal.parcelas.push(parcela);
                                    }
                                  }
                                }
                                for (var i = 0; i < rodales.length; i++) {
                                  var rodal = rodales[i];
                                  for (var j = 0; j < rodal.especies.length; j++) {
                                    var especie = rodal.especies[j];
                                    var valor = 0;
                                    if (especie.tipoInventarioId == 2) {
                                      rodal.areaMuestreada = (rodal.parcelas.length * parseFloat(rodal.tamanioParcela)) / 10000;
                                      valor = parseFloat(especie.areaBasal) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      especie.abr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(especie.volumen) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      especie.volr = parseFloat(valor).toFixed(2);
                                      valor = parseFloat(especie.arbolesAutorizado) / parseFloat(rodal.areaMuestreada);
                                      valor = valor * parseFloat(rodal.area);
                                      especie.arbolr = Math.ceil(valor);
                                    } else {
                                      especie.abr = parseFloat(especie.areaBasal).toFixed(2);
                                      especie.volr = parseFloat(especie.volumen).toFixed(2);
                                      especie.arbolr = especie.arbolesAutorizado;
                                    }
                                    valor = parseFloat(especie.altura) / especie.arbolesAutorizado;
                                    especie.alturaPromedio = parseFloat(valor).toFixed(2);
                                    valor = parseFloat(especie.dap) / especie.arbolesAutorizado;
                                    especie.dapPromedio = parseFloat(valor).toFixed(2);
                                  }
                                }
                                var venta = $scope.dataTask.ttVentaExento.rodales;
                                var strNoEncontrado = '';
                                for (var i = 0; i < venta.length; i++) {
                                  var rdSolic = venta[i];
                                  for (var j = 0; j < rodales.length; j++) {
                                    if (rdSolic.correlativo == rodales[j].correlativo) {
                                      var espVenta = rdSolic.especies;
                                      var especies = rodales[j].especies;
                                      var encontrado = false;
                                      for (var l = 0; l < especies.length; l++) {
                                        for (var k = 0; k < espVenta.length; k++) {
                                          var espSolic = espVenta[k];
                                          if (espSolic.tcEspecie.especieId == especies[l].tcEspecie.especieId) {
                                            espSolic.arbolesAutorizado = especies[l].arbolr;
                                            espSolic.dap = Math.round(especies[l].dapPromedio);
                                            espSolic.altura = especies[l].alturaPromedio;
                                            espSolic.total = especies[l].volr;
                                            encontrado = true;
                                            break;
                                          }
                                        }
                                        if (!encontrado) {
                                          strNoEncontrado += especies[l].tcEspecie.nombreCientifico;
                                        }
                                      }
                                      break;
                                    }
                                  }
                                }
                                if (strNoEncontrado.length > 0) {
                                  swal('Las siguientes especies no coinciden con la solicitud: ' + strNoEncontrado);
                                } else {
                                  toastr.success('Se han actualizado los datos de la tabla; verifique que el DAP, Altura y Total estén con nuevos valores');
                                }
                                ngDialog.close();
                              }
                            } //fin submitFormData

                          }]
                      })
                    }

                    for (var h = 0; h < $scope.dataTask.ttVentaExento.rodales.length; h++) {
                      var rodal = $scope.dataTask.ttVentaExento.rodales[h];
                      for (var i = 0; i < $scope.dataTask.ttExento.rodales.length; i++) {
                        if ($scope.dataTask.ttExento.rodales[i].correlativo == rodal.correlativo) {
                          var dataEspecie = [];
                          var item = $scope.dataTask.ttExento.rodales[i];
                          for (var j = 0; j < item.especies.length; j++) {
                            dataEspecie.push(
                              item.especies[j].tcEspecie
                            );
                          }
                          rodal.area = item.area;
                          rodal.dataEspecie = dataEspecie;
                          break;
                        }
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        var item = rodal.especies[i];
                        item.troza = item.trozaSolicitada;
                        item.lenia = item.leniaSolicitada;
                        item.total = item.totalSolicitado;
                        item.arbolesAutorizado = item.arbolesSolicitado;
                        item.tcTratamientoAprobado = item.tcTratamientoSilvicultural;
                        total += parseFloat(item.totalSolicitado);
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }
                    $scope.dataTask.ttVentaExento.autorizadoNotas = $scope.dataTask.ttVentaExento.cantidadNotas;
                    $scope.dataTask.observaciones = {
                      corresponde: 1,
                      areaProtegida: 0,
                      especieProtegida: 0,
                      razones: [],
                      observaciones: []
                    };

                    $scope.validacion = {
                      corresponde: true,
                      areaProtegida: false,
                      especieProtegida: false
                    }

                    $scope.tituloRazon = 'Liste las razones por las cuales aprueba la solicitud';
                    if ($scope.dataTask.numero == 1) {
                      $scope.dataTask.observaciones.razones = [
                        { descripcion: 'Según el Registro Nacional Forestal, el registro número ' + $scope.dataTask.ttExento.codigo + ', corresponde a una actividad Exenta de Licencia Forestal, según el Artículo número 53 de la Ley Forestal, Decreto Legislativo 101-96 inciso ', deshabilitado: false },
                        { descripcion: 'Se verificaron los datos correspondientes a: Volumen del Registro, Volumen Transportado, Volumen existente, del registro número ' + $scope.dataTask.ttExento.codigo, deshabilitado: false }
                      ];
                    } else {
                      $scope.dataTask.observaciones.razones = [
                        { descripcion: 'Según verificación de campo, el registro número ' + $scope.dataTask.ttExento.codigo + ', corresponde a una actividad Exenta de Licencia Forestal, según el Artículo número 53 de la Ley Forestal, Decreto Legislativo 101-96 inciso ', deshabilitado: false },
                        { descripcion: 'La actividad forestal con registro número ' + $scope.dataTask.ttExento.codigo + ' no se encuentra en un área protegida, por lo que recomiendo aprobar la solicitud de notas de envío.', deshabilitado: false },
                        { descripcion: 'Los árboles y el producto forestal de las especies a transportar, no corresponden a especies protegidas y no se encuentran cercanos a fuentes de agua', deshabilitado: false }
                      ];
                    }

                    $scope.setActividadForestal = function (item) {
                      $scope.dataTask.ttVentaExento.tcActividadForestal.actividadForestalDesc = item.actividadForestalDesc;
                      if ($scope.dataTask.numero == 1) {
                        $scope.dataTask.observaciones.razones = [
                          {
                            descripcion: 'Según el Registro Nacional Forestal, el registro número ' + $scope.dataTask.ttExento.codigo + ', corresponde a una actividad Exenta de Licencia Forestal, según el Artículo número 53 de la Ley Forestal, Decreto Legislativo 101-96 inciso '
                              + item.actividadForestalDesc, deshabilitado: false
                          },
                          { descripcion: 'Se verificaron los datos correspondientes a: Volumen del Registro, Volumen Transportado, Volumen existente, del registro número ' + $scope.dataTask.ttExento.codigo, deshabilitado: false }
                        ];
                      } else {
                        $scope.dataTask.observaciones.razones = [
                          {
                            descripcion: 'Según verificación de campo, el registro número ' + $scope.dataTask.ttExento.codigo
                              + ', corresponde a una actividad Exenta de Licencia Forestal, según el Artículo número 53 de la Ley Forestal, Decreto Legislativo 101-96 inciso '
                              + item.actividadForestalDesc, deshabilitado: false
                          },
                          { descripcion: 'La actividad forestal con registro número ' + $scope.dataTask.ttExento.codigo + ' no se encuentra en un área protegida, por lo que recomiendo aprobar la solicitud de notas de envío.', deshabilitado: false },
                          { descripcion: 'Los árboles y el producto forestal de las especies a transportar, no corresponden a especies protegidas y no se encuentran cercanos a fuentes de agua', deshabilitado: false }
                        ];
                      }
                    }

                    $scope.setAprobadoVenta = function (item) {
                      if (item.aprobadoId == 0) {
                        $scope.dataTask.observaciones.razones = [
                          { descripcion: 'Según verificación de campo, el registro número ' + $scope.dataTask.ttExento.codigo + ' no corresponde a una actividad exenta de licencia por las razones siguientes:', deshabilitado: false },
                          { descripcion: 'La actividad forestal con registro número ' + $scope.dataTask.ttExento.codigo + ' se encuentra en un área protegida, por lo que recomiendo denegar la solicitud de notas de envío.', deshabilitado: false },
                          { descripcion: 'Los árboles y el producto forestal de las especies a transportar, corresponden a especies protegidas', deshabilitado: false },
                          { descripcion: 'Los árboles y el producto forestal de las especies a transportar se encuentran cercanos a fuentes de agua', deshabilitado: false }
                        ];
                        $scope.tituloRazon = 'Liste las razones por las cuales no aprueba la solicitud';
                        swal('Se ha cargado una lista de posibles razones, elimine o agregue y/o amplíe según sea el caso');
                      } else {
                        $scope.dataTask.observaciones.razones = [
                          {
                            descripcion: 'Según verificación de campo, el registro número ' + $scope.dataTask.ttExento.codigo
                              + ', corresponde a una actividad Exenta de Licencia Forestal, según el Artículo número 53 de la Ley Forestal, Decreto Legislativo 101-96 inciso '
                              + $scope.dataTask.ttVentaExento.tcActividadForestal.actividadForestalDesc, deshabilitado: false
                          },
                          { descripcion: 'La actividad forestal con registro número ' + $scope.dataTask.ttExento.codigo + ' no se encuentra en un área protegida', deshabilitado: false },
                          { descripcion: 'Los árboles y el producto forestal de las especies a transportar, no corresponden a especies protegidas y no se encuentran cercanos a fuentes de agua', deshabilitado: false }
                        ];
                        $scope.tituloRazon = 'Liste las razones por las cuales aprueba la solicitud';
                      }
                    }

                    $scope.agregarRazon = function () {
                      $scope.dataTask.observaciones.razones.push({ descripcion: null, deshabilitado: false });
                    }

                    $scope.quitarRazon = function (item) {
                      $scope.dataTask.observaciones.razones.splice($scope.dataTask.observaciones.razones.indexOf(item), 1);
                    }

                    $scope.agregarObservacion = function () {
                      $scope.dataTask.observaciones.observaciones.push({ descripcion: null });
                    }

                    $scope.quitarObservacion = function (item) {
                      $scope.dataTask.observaciones.observaciones.splice($scope.dataTask.observaciones.observaciones.indexOf(item), 1);
                    }

                    $scope.setCorresponde = function () {
                      if ($scope.validacion.corresponde) {
                        $scope.dataTask.observaciones.corresponde = 1;
                      } else {
                        $scope.dataTask.observaciones.corresponde = 0;
                      }
                    }

                    $scope.setAreaProtegida = function () {
                      if ($scope.validacion.areaProtegida) {
                        $scope.dataTask.observaciones.areaProtegida = 1;
                      } else {
                        $scope.dataTask.observaciones.areaProtegida = 0;
                      }
                    }

                    $scope.setEspecieProtegida = function () {
                      if ($scope.validacion.especieProtegida) {
                        $scope.dataTask.observaciones.especieProtegida = 1;
                      } else {
                        $scope.dataTask.observaciones.especieProtegida = 0;
                      }
                    }

                    $scope.setTratamientoSilvicultural = function (item, especie) {
                      if (item.tratamientoSilviculturalId == 7) {
                        especie.porcentaje = 100;
                      }
                      especie.tcTratamientoAprobado = item;
                    }

                    $scope.dataAprobado = [
                      { aprobadoId: 0, aprobadoDesc: 'Denegar' },
                      { aprobadoId: 1, aprobadoDesc: 'Aprobar' }
                    ];

                    $scope.setAprobado = function (item) {
                      if (item.aprobadoId == 2) {
                        $scope.dataTask.esEnmienda = 1;
                      } else {
                        if (item.aprobadoId == 1) {
                          $scope.validacion.corresponde = true;
                          $scope.validacion.areaProtegida = false;
                          $scope.validacion.especieProtegida = false;
                          $scope.dataTask.observaciones.corresponde = 1;
                          $scope.dataTask.observaciones.areaProtegida = 0;
                          $scope.dataTask.observaciones.especieProtegida = 0;
                        }
                        $scope.dataTask.esEnmienda = 0;
                      }
                    }

                    var rodales = $scope.dataTask.ttExento.rodales;
                    var dataRodales = [];
                    for (var i = 0; i < rodales.length; i++) {
                      dataRodales.push(
                        {
                          correlativo: rodales[i].correlativo
                        }
                      );
                    }
                    $scope.dataRodales = dataRodales;

                    $scope.setEspecieTransportado = function (item, row) {
                      row.tcEspecie = item;
                    }

                    $scope.agregarRodal = function () {
                      $scope.dataTask.ttVentaExento.rodales.push(
                        {
                          correlativo: null,
                          area: 0,
                          especies: []
                        }
                      );
                    }

                    $scope.setCorrelativoRodal = function (item, rodal) {
                      for (var i = 0; i < $scope.dataTask.ttExento.rodales.length; i++) {
                        if ($scope.dataTask.ttExento.rodales[i].correlativo == item.correlativo) {
                          var dataEspecie = [];
                          var item = $scope.dataTask.ttExento.rodales[i];
                          for (var j = 0; j < item.especies.length; j++) {
                            dataEspecie.push(
                              item.especies[j].tcEspecie
                            );
                          }
                          rodal.area = item.area;
                          rodal.dataEspecie = dataEspecie;
                        }
                      }
                    }

                    $scope.quitarRodal = function (venta, rodal) {
                      $scope.dataTask.ttVentaExento.rodales.splice(venta.rodales.indexOf(rodal), 1);
                    }

                    $scope.agregarEspecieRodal = function (rodal) {
                      if (rodal.correlativo == null) {
                        toastr.error('Seleccione primero el correlativo del rodal');
                        return;
                      }
                      rodal.especies.push(
                        {
                          tcEspecie: null,
                          troza: 0,
                          lenia: 0,
                          total: 0
                        }
                      );
                    }

                    $scope.quitarEspecieRodal = function (rodal, especie) {
                      rodal.especies.splice(rodal.especies.indexOf(especie), 1);
                    }

                    $scope.setVolumenTotal = function (row, rodal) {
                      if (row.troza != null) {
                        row.lenia = parseFloat(row.total) - parseFloat(row.troza);
                        row.lenia = parseFloat(row.lenia).toFixed(2);
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += rodal.especies[i].total;
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.setEspecieVenta = function (item, especie) {
                      especie.tcEspecie = item;
                    }

                    $scope.vistaPreviaDictamen = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de dictamen');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de dictamen');
                          return;
                        }
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = $scope.dataTask;
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.generarInformeVentaNota(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "No hay regreso de tarea",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttExento = data.ttExento;
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea
                              };
                              data.fechaProvidencia = new Date();
                              data.tcPersonaAsignada.foto = null;
                              data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                              data.observaciones = JSON.stringify(data.observaciones);
                              tareaService.finalizarInformeVentaNota(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaDictamen(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notiventa', {
            url: '/notiventa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notiventa.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaVentaExento($base64.decode($stateParams.tareaId), 3);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTecnico = null;
                    $scope.esProvidencia = false;

                    $scope.dataTask = dataTarea.data[0];
                    var valor = parseInt($scope.dataTask.ttVentaExento.autorizadoNotas) * 15;
                    $scope.dataTask.ttVentaExento.pago = parseFloat(valor).toFixed(2);
                    $scope.dataTask.approved = true;
                    $scope.dataTask.observaciones = [];
                    $scope.titulo = '';
                    switch ($scope.dataTask.aprobado) {
                      case 0:
                        $scope.titulo = 'Notificación de Denegación de Solicitud';
                        $scope.dataTask.approved = false;
                        $scope.dataTask.aprobado = 0;
                        break;
                      case 1:
                        $scope.titulo = 'Notificación de pago de Notas de Envío';
                        break;
                      case 2:
                        $scope.titulo = 'Solicitar de informe trimestral';
                        break;
                    }
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    $scope.setAprobar = function () {
                      if ($scope.dataTask.approved) {
                        $scope.dataTask.aprobado = 1;
                        $scope.titulo = 'Notificación de pago de Notas de Envío';
                        $scope.dataTask.observaciones = null;
                      } else {
                        $scope.dataTask.aprobado = 0;
                        $scope.titulo = 'Notificación de Denegación de Solicitud';
                        $scope.dataTask.observaciones = [];
                      }
                    }

                    $scope.agregarRazon = function () {
                      $scope.dataTask.observaciones.push(
                        {
                          descripcion: null
                        }
                      );
                    }

                    $scope.quitarRazon = function (item) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(item), 1);
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = angular.copy($scope.dataTask);
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      if ($scope.esProvidencia) {
                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        utilsService.generarProvidenciaVentaNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        switch (data.aprobado) {
                          case 0:
                            var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDiaAdmision = $scope.numeroALetras(dia, false);
                            var strAnioAdmision = $scope.numeroALetras(anio, false);
                            utilsService.generarResolucionDenegacionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            break;
                          case 1:
                            utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            break;
                          case 2:
                            utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            break;
                        }
                      }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.notificarResolucion = function () {
                      var data = angular.copy($scope.dataTask);
                      data.fechaFinalizacion = new Date();
                      if (data.aprobado == 0) {
                        if (data.codigo == null || data.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de resolución');
                          return;
                        }
                        if (data.observaciones.length <= 0) {
                          toastr.error('Debe agregar las razones del por qué deniega la solicitud');
                          return;
                        }
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttPadre;
                            delete data.ttExento;
                            data.fechaFinalizacion = new Date();
                            if ($scope.esProvidencia) {
                              data.observaciones = null;
                              tareaService.regresarTareaVentaTecnico(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              if (data.aprobado == 0) {
                                data.observaciones = JSON.stringify(data.observaciones);
                              } else {
                                data.observaciones = null;
                              }
                              tareaService.notificarDictamenVenta(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.calcularTotalPago = function () {
                      var valor = parseInt($scope.dataTask.ttVentaExento.autorizadoNotas) * 15;
                      $scope.dataTask.ttVentaExento.pago = parseFloat(valor).toFixed(2);
                    }

                    $scope.providenciarTecnico = function () {
                      swal({
                        title: "Confirmación",
                        text: "La tarea cambiará ¿Está seguro (a) de continuar? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.esProvidencia = true;
                            $scope.titulo = 'Providenciar';
                            if ($scope.dataTecnico == null) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                    }

                  }]
              }
            }
          })
          .state('index.realizar.resventa', {
            url: '/resventa',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.resventa.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaVentaExento($base64.decode($stateParams.tareaId), 4);
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {


                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    var resolucionRevalidacion = angular.copy($scope.dataTask.observaciones);

                    $scope.dataTask.observaciones = {
                      resolucionRevalidacion: resolucionRevalidacion,
                      observaciones: []
                    };
                    
                    $scope.agregarRegistro = function () {
                      $scope.dataTask.observaciones.observaciones.push({ descripcion: null });
                    }

                    $scope.quitarDetalle = function (detalle) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.observaciones.indexOf(detalle), 1);
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = $scope.dataTask;
                      var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio, false);
                      dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                      anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                      var strDiaAdmision = $scope.numeroALetras(dia, false);
                      var strAnioAdmision = $scope.numeroALetras(anio, false);
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.generarResolucionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      utilsService.generarCedulaResolucionVenta(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.notificarResolucion = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "No hay regreso de tarea",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              data.fechaFinalizacion = new Date();
                              if (data.observaciones.toString().length > 0) {
                                if (data.observaciones.toString().indexOf('{')) {
                                  data.observaciones = JSON.stringify(data.observaciones);
                                }
                              }
                              tareaService.procesoResolucionVenta(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provinforme', {
            url: '/provinforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provinforme.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.obtenerTareaProvidenciaInformeVenta($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataTecnico', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, dataTecnico, authService) {
                    $scope.dataTecnico = dataTecnico.data;
                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttInformeVenta.ttVentaExento.ttExento.expediente
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {

                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.tcPersonaTraslado == null) {
                        toastr.error('Debe seleccionar a la técnico a asignar');
                        return;
                      }
                      try {
                        if ($scope.dataTask.fechaFinalizacion == null) {
                          $scope.dataTask.fechaFinalizacion = new Date();
                        }
                        var dia = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        var data = $scope.dataTask;
                        utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } catch (error) { }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              //  delete data.ttInformeVenta;
                              data.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              tareaService.asignarProvidenciaInformeVenta(data).then(function (res) {
                                if (res.status == "OK") {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaAsignacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opinforme', {
            url: '/opinforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.opinforme.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaInformeVenta($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService', 'Upload',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService, Upload) {

                    $scope.dataTask = dataTarea.data[0];
                    $scope.dataTask.aprobado = 1;
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.observaciones = {
                      balance: [],
                      razon: null
                    };
                    $scope.aprobar = true;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttInformeVenta.ttVentaExento.ttExento.expediente
                    }

                    var ttInformeVenta = $scope.dataTask.ttInformeVenta;
                    var notas = ttInformeVenta.notas;
                    var lista = ttInformeVenta.especies;
                    for (var i = 0; i < lista.length; i++) {
                      lista[i].lenia = parseFloat(lista[i].lenia).toFixed(2);
                      lista[i].troza = parseFloat(lista[i].troza).toFixed(2);
                      lista[i].leniaFinca = parseFloat(lista[i].leniaFinca).toFixed(2);
                      lista[i].trozaFinca = parseFloat(lista[i].trozaFinca).toFixed(2);
                      lista[i].volumen = parseFloat(lista[i].volumen).toFixed(2);
                    }
                    var existe = angular.copy(ttInformeVenta.ttVentaExento.ttExento.especies);
                    for (var i = 0; i < existe.length; i++) {
                      for (var j = 0; j < lista.length; j++) {
                        if (lista[j].tcEspecie.especieId == existe[i].tcEspecie.especieId) {
                          existe[i].saldo = parseFloat(existe[i].saldo) - parseFloat(lista[j].volumen);
                          break;
                        }
                      }
                    }

                    for (var i = 0; i < existe.length; i++) {
                      existe[i].saldo = parseFloat(existe[i].saldo).toFixed(2);
                      existe[i].volumen = parseFloat(existe[i].volumen).toFixed(2);
                      var reserva = parseFloat(existe[i].volumen) - parseFloat(existe[i].saldo);
                      existe[i].reserva = parseFloat(reserva).toFixed(2);
                    }
                    $scope.dataTask.observaciones.balance = existe;

                    if (ttInformeVenta.esFinal == 1) {
                      swal(
                        {
                          title: 'El informe que se está presentando es final, las notas que no se han reportado se anularán automáticamente y no se podrá revalidar ni presentar otro informe de ésta resolución',
                          text: "He leído la advertencia y estoy conciente de lo que debo realizar",
                          icon: "info",
                          type: "info",
                          confirmButtonText: "Aceptar"
                        }
                      );
                    }

                    $scope.setAprobar = function () {
                      if ($scope.aprobar) {
                        $scope.dataTask.aprobado = 1;
                        $scope.dataTask.observaciones.razon = null;
                      } else {
                        $scope.dataTask.aprobado = 0;
                        $scope.dataTask.observaciones.razon = [];
                      }
                    }

                    $scope.agregarRazon = function () {
                      $scope.dataTask.observaciones.razon.push({ descripcion: null });
                    }

                    $scope.quitarRazon = function (item) {
                      $scope.dataTask.observaciones.razon.splice($scope.dataTask.observaciones.razon.indexOf(item), 1);
                    }

                    $scope.vistaPreviaDictamen = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = angular.copy($scope.dataTask);
                      data.ttInformeVenta.conclusiones = JSON.parse(data.ttInformeVenta.conclusiones);
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                        if ($scope.dataTask.esEnmienda == 1) {
                          delete data.ttInformeVenta;
                          data.ttExento = $scope.dataTask.ttInformeVenta.ttVentaExento.ttExento;
                          utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          utilsService.generarDictamenInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                      } else {
                        tareaService.getTareaInformeVenta($scope.dataTask.tareaExentoId).then(function (res) {
                          if (res.status == "OK") {
                            data = res.data[0];
                            if (data.esEnmienda == 1) {
                              data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                              delete data.ttInformeVenta;
                              data.observaciones = JSON.parse(data.observaciones);
                              utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla)
                            } else {
                              utilsService.generarDictamenInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            }
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                        $state.go('index.tarea.exentofin');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              delete data.ttInformeVenta;
                              data.fechaProvidencia = new Date();
                              data.observaciones = JSON.stringify(data.observaciones);
                              if ($scope.dataTask.esEnmienda == 1) {
                                tareaService.solicitarEnmiendaInformeVenta(data).then(function (res) {
                                  if (res.status == 1) {
                                    toastr.success(res.message);
                                    $scope.vistaPreviaDictamen(true);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              } else {
                                tareaService.finalizarDictamenInformeVenta(data).then(function (res) {
                                  if (res.status == "OK") {
                                    toastr.success(res.message);
                                    $scope.vistaPreviaDictamen(true);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }
                          });
                      }
                    }

                    $scope.solicitarEnmiendaInforme = function (item) {
                      $scope.dataTask.esEnmienda = 1;
                      $scope.dataTask.observaciones.razon = [];
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notiinforme', {
            url: '/notiinforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notiinforme.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaInformeVentaNotificacion($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTecnico = null;
                    $scope.esProvidencia = false;

                    $scope.codigo = 'Notificación';

                    $scope.dataTask = dataTarea.data[0];
                    if ($scope.dataTask.ttPadre.aprobado == 0) {
                      $scope.dataTask.ttPadre.observaciones = JSON.parse($scope.dataTask.ttPadre.observaciones);
                      $scope.dataTask.aprobado = $scope.dataTask.ttPadre.aprobado;
                    }
                    $scope.titulo = 'Notificación sobre informe de Notas de Envío';
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttInformeVenta.ttVentaExento.ttExento.expediente
                    }

                    if ($scope.dataTask.ttInformeVenta.esFinal == 1) {
                      swal(
                        {
                          title: 'El informe que se está presentando es final, las notas que no se han reportado se anularán automáticamente y no se podrá revalidar ni presentar otro informe de la resolución de venta asociada',
                          text: "He leído la advertencia y estoy conciente de lo que debo realizar",
                          icon: "info",
                          type: "info",
                          confirmButtonText: "Aceptar"
                        }
                      );
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var data = angular.copy($scope.dataTask);
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      if ($scope.esProvidencia) {
                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                        data.ttExento.tcSubregion.tcSubregional = {
                          personaId: $scope.loginData.personaId,
                          personaDesc: $scope.loginData.usuarioDesc
                        }
                        utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        utilsService.generarNotificacionInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.notificarResolucion = function () {
                      swal({
                        title: "¿Está seguro (a) de guardar y finalizar la tarea? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        text: "",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            data.fechaFinalizacion = new Date();
                            delete data.ttPadre;
                            if ($scope.esProvidencia) {
                              delete data.ttInformeVenta;
                              tareaService.regresarTareaInformeTecnico(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              tareaService.notificarDictamenInformeVenta(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.providenciarTecnico = function () {
                      swal({
                        title: "Confirmación",
                        text: "La tarea cambiará ¿Está seguro (a) de continuar? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.esProvidencia = true;
                            $scope.codigo = 'Providencia';
                            if ($scope.dataTecnico == null) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provupdexento', {
            url: '/provupdexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provupdexento.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaSolicitudActualizacionExento($base64.decode($stateParams.tareaId));
                    }],
                  dataRnf: ['catalogosService', 'dataTarea',
                    function (catalogosService, dataTarea) {
                      var data = dataTarea.data;
                      return catalogosService.getDatosRegistro(data.ttExento.codigo.trim());
                    }],
                  dataTecnico: ['tareaService', 'authService', 'dataTarea',
                    function (tareaService, authService, dataTarea) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      var ttTarea = dataTarea.data;
                      var ttActualizacionExento = ttTarea.ttActualizacionExento;
                      if (ttActualizacionExento.tipoActualizacionId == 1) {
                        return {
                          status : 1,
                          message : 'Datos cargados',
                          data: []
                        };
                      } else {
                        return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                      }
                    }],
                  dataEspecie: ['dataTarea', 'especieService',
                    function (dataTarea, especieService) {
                      var ttTarea = dataTarea.data[0];
                      return especieService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'dataTecnico', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataRnf', 'dataEspecie', '$timeout', 'catalogosService', 'ngDialog',
                  function ($scope, $state, toastr, dataTecnico, tareaService, utilsService,
                    dataTarea, $filter, dataRnf, dataEspecie, $timeout, catalogosService, ngDialog) {

                    if (dataTarea.status != 1) {
                      toastr.error(dataTarea.message);
                      $state.go('index.tarea.exentolist');
                    }

                    if (dataRnf.status != 'OK') {
                      toastr.error('No es posible conectarse al servicio del registro debido a: ' + dataRnf.message);
                      $state.go('index.tarea.exentolist');
                    }

                    $scope.porRodal = true;
                    $scope.dataTask = dataTarea.data;
                    $scope.dataEspecie = dataEspecie.data;
                    $scope.dataTask.observaciones = [];
                    $scope.ttActualizacionExento = $scope.dataTask.ttActualizacionExento;
                    if ($scope.ttActualizacionExento.thActualizacionExento == null) {
                      $scope.ttActualizacionExento.thActualizacionExento = {
                        rodales: []
                      }
                    }
                    $scope.ttActualizacionExento.rodales = $scope.ttActualizacionExento.thActualizacionExento.rodales;
                    $scope.dataTask.approved = true;
                    $scope.dataRnf = dataRnf.data[0];
                    $scope.dataTecnico = dataTecnico.data;
                    if ($scope.ttActualizacionExento.fechaVencimiento != null) {
                      $scope.ttActualizacionExento.fechaVencimiento = new Date($scope.ttActualizacionExento.fechaVencimiento);
                    } else {
                      var fechaVencimiento = angular.copy($scope.dataRnf.fechaVencimiento);
                      fechaVencimiento = new Date(fechaVencimiento.toString().replace('[UTC]', ''));
                      $scope.ttActualizacionExento.fechaVencimiento = fechaVencimiento;
                    }
                    if ($scope.ttActualizacionExento.fechaActualizacion != null) {
                      $scope.ttActualizacionExento.fechaActualizacion = new Date($scope.ttActualizacionExento.fechaActualizacion);
                    }
                    $scope.ttActualizacionExento.ttExento = $scope.dataTask.ttExento;
                    if (parseFloat($scope.dataRnf.areaAprobada) > 0) {
                      $scope.ttActualizacionExento.area = $scope.dataRnf.areaAprobada;
                    } else {
                      $scope.ttActualizacionExento.area = $scope.ttActualizacionExento.ttExento.area;
                    }
                    if ($scope.dataRnf.longitudAprobada == null) {
                      $scope.dataRnf.longitudAprobada = 0;
                    }
                    if (parseFloat($scope.dataRnf.areaAprobada) > 0) {
                      $scope.ttActualizacionExento.longitud = $scope.dataRnf.longitudAprobada;
                    } else {
                      $scope.ttActualizacionExento.longitud = $scope.ttActualizacionExento.ttExento.longitud;
                    }
                    $scope.tipoActualizacionDesc = 'Actualización del RNF';
                    switch ($scope.ttActualizacionExento.tipoActualizacionId) {
                      case 1:
                        $scope.tipoActualizacionDesc = 'Actualización del RNF';
                        break;
                      case 2:
                        $scope.tipoActualizacionDesc = 'Actualización de volumen';
                        break;
                    }
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }
                    $scope.dataTask.aprobado = 1;

                    $scope.tituloObservaciones = 'Observaciones';

                    $scope.setAprobarActualizacion = function () {
                      if ($scope.dataTask.aprobado == 1) {
                        $scope.dataTask.aprobado = 0;
                        $scope.tituloObservaciones = 'Liste las razones por la que se deniega la solicitud';
                        $scope.dataTask.observaciones = [];
                      } else {
                        $scope.dataTask.aprobado = 1;
                        $scope.tituloObservaciones = 'Observaciones';
                        $scope.dataTask.observaciones = [];
                      }
                    }

                    $scope.agregarRazon = function () {
                      $scope.dataTask.observaciones.push(
                        {
                          descripcion: null
                        }
                      );
                    }

                    $scope.quitarRazon = function (item) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(item), 1);
                    }

                    if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                      var rodales = [];
                      var tmp = $scope.dataRnf.rodales;
                      var haySinonimo = false;
                      var nombres = '', sinonimos = '';
                      var noEncontrados = [];                      
                      for (var i = 0; i < tmp.length; i++) {
                        if (tmp[i].area == null) {
                          tmp[i].area = 0;
                        }
                        var rodal = {
                          correlativo: tmp[i].id,
                          area: tmp[i].area,
                          volumen: tmp[i].volumen,
                          especies: [],
                          enLinea: 0
                        }
                        for (var j = 0; j < tmp[i].especies.length; j++) {
                          var codigo = '', nombre = '';
                          if (tmp[i].especies[j].especie != null) {
                            codigo = tmp[i].especies[j].especie.trim().toUpperCase();
                          }
                          if (tmp[i].especies[j].nombre != null) {
                            nombre = tmp[i].especies[j].nombre.trim().toLowerCase();
                          }
                          var tcEspecie = $scope.getEspecieByNombreCientífico(nombre, $scope.dataEspecie);
                          if (tcEspecie == null) {
                            tcEspecie = $scope.getEspecieByCodigo(codigo, $scope.dataEspecie);
                            if (tcEspecie == null) {
                              tcEspecie = $scope.getSinonimoEspecie(nombre, $scope.dataEspecie);
                              if (tcEspecie == null) {
                                var yaEsta = false;
                                for (var k = 0; k < noEncontrados.length; k++) {
                                  var itemNoEncontrado = noEncontrados[k];
                                  if (itemNoEncontrado.codigo == codigo) {
                                    yaEsta = true;
                                    break;
                                  }
                                }
                                if (!yaEsta) {
                                  noEncontrados.push({ codigo: codigo, nombre: nombre });
                                }
                              } else { //se encontro como sinonimo
                                var volumenHectaria = 0;
                                if (parseFloat(rodal.area) != 0) {
                                  volumenHectaria = parseFloat(tmp[i].especies[j].volumen) / parseFloat(rodal.area);
                                  volumenHectaria = parseFloat(volumenHectaria).toFixed(4);
                                }
                                var especie = {
                                  tcEspecie: tcEspecie,
                                  volumenHectaria: volumenHectaria,
                                  volumen: tmp[i].especies[j].volumen
                                }
                                rodal.especies.push(especie);
                                if (haySinonimo) {
                                  sinonimos += '\n (RNF) ' + tmp[i].especies[j].nombre + ' = ' + tcEspecie.nombreCientifico;
                                } else {
                                  sinonimos = '(RNF) ' + tmp[i].especies[j].nombre + ' = ' + tcEspecie.nombreCientifico;
                                }
                                haySinonimo = true;
                              }
                            } else { //se encontro con el codigo
                              var volumenHectaria = 0;
                              if (parseFloat(rodal.area) != 0) {
                                volumenHectaria = parseFloat(tmp[i].especies[j].volumen) / parseFloat(rodal.area);
                                volumenHectaria = parseFloat(volumenHectaria).toFixed(4);
                              }
                              var especie = {
                                tcEspecie: tcEspecie,
                                volumenHectaria: volumenHectaria,
                                volumen: tmp[i].especies[j].volumen
                              }
                              rodal.especies.push(especie);
                            }
                          } else { //se con el nombre cientifico
                            var volumenHectaria = 0;
                            if (parseFloat(rodal.area) != 0) {
                              volumenHectaria = parseFloat(tmp[i].especies[j].volumen) / parseFloat(rodal.area);
                              volumenHectaria = parseFloat(volumenHectaria).toFixed(4);
                            }
                            var especie = {
                              tcEspecie: tcEspecie,
                              volumenHectaria: volumenHectaria,
                              volumen: tmp[i].especies[j].volumen
                            }
                            rodal.especies.push(especie);
                          }
                        }
                        rodales.push(rodal);
                      }
                      if (noEncontrados.length > 0) {
                        for (var i = 0; i < noEncontrados.length; i++) {
                          var element = noEncontrados[i];
                          if (i == 0) {
                            nombres = element.nombre;
                          } else {
                            nombres += ', ' + element.nombre;
                          }
                        }
                        if (nombres.length <= 0) {
                          swal('Los datos de especies en el Sistema de Registro no son correctos, favor de verificar');
                        } else {
                          swal('No se ha encontrado datos de la(s) especie(s): ' + nombres + '. Busque en el catálogo el sinónimo, en dado caso no se encuentra será necesario comunicarlo al administrador para agregarlo');
                        }
                      } else {
                        if (haySinonimo) {
                          swal('Se encontraron los siguientes sinónimos:', sinonimos);
                        }
                      }
                      if (rodales.length > 1) {
                        rodales.sort((a, b) => a.correlativo - b.correlativo);
                      }
                      $scope.ttActualizacionExento.rodales = rodales;
                      var ventas = $scope.ttActualizacionExento.thActualizacionExento.ventas;
                      if (ventas == null) {
                        ventas = [];
                      }
                      for (let i = 0; i < ventas.length; i++) {
                        var element = ventas[i];
                        element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                      }
                    }

                    $scope.agregarRodal = function () {
                      $scope.ttActualizacionExento.rodales.push(
                        {
                          correlativo: $scope.ttActualizacionExento.rodales.length + 1,
                          area: 0,
                          especies: []
                        }
                      );
                    }

                    $scope.borrarRodales = function () {
                      $scope.ttActualizacionExento.rodales = [];
                    }

                    $scope.quitarRodalExento = function (rodal) {
                      $scope.ttActualizacionExento.rodales.splice($scope.ttActualizacionExento.rodales.indexOf(rodal), 1);
                    }

                    $scope.agregarEspecie = function (rodal) {
                      rodal.especies.push(
                        {
                          tcEspecie: null,
                          volumen: 0
                        }
                      );
                    }

                    $scope.quitarEspecieExento = function (rodal, especie) {
                      rodal.especies.splice(rodal.especies.indexOf(especie), 1);
                    }

                    $scope.setEspecieExento = function (item, especie) {
                      especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                      especie.tcEspecie.codigo = item.codigo;
                      especie.tcEspecie.especieDesc = item.especieDesc;
                    }

                    $scope.setAreaRodalActualizacion = function (rodal) {
                      if (rodal.area == null) {
                        rodal.area = 0;
                      }
                      if (rodal.enLinea == null) {
                        rodal.enLinea = 0;
                      }
                      if (rodal.enLinea == 1) {
                        for (var i = 0; i < rodal.especies.length; i++) {
                          var especie = rodal.especies[i];
                          especie.volumenHectaria = 0;
                        }
                      } else {
                        if (parseFloat(rodal.area) == 0) {
                          for (var i = 0; i < rodal.especies.length; i++) {
                            var especie = rodal.especies[i];
                            especie.volumen = 0;
                            especie.volumenHectaria = 0;
                          }
                        } else {                        
                          for (var i = 0; i < rodal.especies.length; i++) {
                            var especie = rodal.especies[i];
                            especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                            especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(4);
                          }
                        }
                      }
                    }

                    $scope.setVolumen = function (rodal, especie) {
                      if (rodal.enLinea == null) {
                        rodal.enLinea = 0;
                      }
                      if (rodal.enLinea == 1 || rodal.enLinea) {
                        especie.volumenHectaria = 0;
                        toastr.error('Para árboles en línea no aplica volumen por hectárea');
                        return;
                      }
                      especie.volumen = parseFloat(rodal.area) * parseFloat(especie.volumenHectaria);
                      especie.volumen = parseFloat(especie.volumen).toFixed(2);
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.setVolumenRodal = function (rodal, especie) {
                      if (parseFloat(rodal.area) == 0) {
                        toastr.error('El área del rodal o longitud de la línea no puede ser cero');
                        especie.volumen = 0;
                        return;
                      }
                      if (rodal.enLinea == null) {
                        rodal.enLinea = 0;
                      }
                      if (rodal.enLinea == 1 || rodal.enLinea) {
                        especie.volumenHectaria = 0;
                      } else {
                        especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                        especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(2);
                      }
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(2);
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.setCategoriaExento = function (item) {
                      $scope.dataTask.ttExento.tcCategoriaExento = item;
                    }

                    $scope.textoPoligono = 'Cargar polígono';
                    var textoPropietario = '';
                    var ttExento = $scope.dataTask.ttExento;
                    if (ttExento.tcTipoPropietario.tipoPropietarioId == 2) {
                      textoPropietario = ttExento.ttJuridicaExento.razonSocial;
                    } else {
                      var tmp = ttExento.propietarios;
                      for (var i = 0; i < tmp.length; i++) {
                        if (i == 0) {
                          textoPropietario = tmp[i].tcPersona.personaDesc;
                        } else {
                          textoPropietario += ', ' + tmp[i].tcPersona.personaDesc;
                        }
                      }
                    }
                    $scope.textoPropietario = textoPropietario;

                    $scope.gridPoligono = angular.copy($scope.gridOptionsSelection);
                    $scope.gridPoligono.data = [];
                    $scope.gridPoligonoDescuento = angular.copy($scope.gridOptionsSelection);
                    $scope.gridPoligonoDescuento.data = [];
                    $scope.veces = 2;
                    $scope.visibleMapa = false;
                    $scope.mapa = {
                      centerX: 14.596240611182054,
                      centerY: -90.53100159560573
                    };
                    $scope.enLinea = false;

                    $scope.onFileSelect = function (file, id) {
                      if (file != null) {
                        var data = [];
                        if (id == 1) {
                          data = $scope.gridPoligono.data;
                        } else {
                          if ($scope.gridPoligono.data.length <= 0) {
                            toastr.error('Primero debe cargar las coordenadas del polígono');
                            return;
                          }
                          data = $scope.gridPoligonoDescuento.data;
                        }
                        var rodales = $scope.ttActualizacionExento.rodales;
                        if (data.length > 0) {
                          var poligonos = [];
                          for (var i = 0; i < data.length; i++) {
                            var row = data[i];
                            var poligono = {
                              numero: row.NO_POLIGONO,
                              coordenadas: [],
                              enLinea: 0
                            }
                            var encontrado = false;
                            for (var j = 0; j < poligonos.length; j++) {
                              if (poligonos[j].numero == row.NO_POLIGONO) {
                                encontrado = true;
                                poligono = poligonos[j];
                                break;
                              }
                            }
                            poligono.coordenadas.push({ lat: row.GTM_X, lng: row.GTM_Y });
                            if (!encontrado) {
                              poligonos.push(poligono);
                            }
                          }
                          for (var j = 0; j < poligonos.length; j++) {
                            encontrado = false;
                            poligono = poligonos[j];
                            for (let k = 0; k < rodales.length; k++) {
                              const element = rodales[k];
                              if (element.correlativo == poligono.numero) {
                                encontrado = true;
                                poligono.enLinea = element.enLinea == null ? 0 : element.enLinea;
                                break;
                              }
                            }
                            if (!encontrado && id == 1) {
                              toastr.error('El polígono ' + poligono.numero + ' no coincide con el correlativo de ningún rodal o línea, no es posible continuar');
                              return;
                            }
                          }
                          var poligono = null;
                          var enLinea = false;
                          var lineas = [];
                          var areas = [];
                          for (var i = 0; i < poligonos.length; i++) {
                            poligono = poligonos[i];
                            var coordenadasGtm = poligono.coordenadas;
                            var nuevos = utilsService.gtmToLatLong(true, 'lat', 'lng', coordenadasGtm);
                            poligono.coordenadas = nuevos;
                            poligono.coordenadasGtm = coordenadasGtm;
                            var bounds = new google.maps.LatLngBounds();
                            var polyline = new google.maps.Polyline();
                            var path = polyline.getPath();
                            var minx = 0, miny = 0, maxx = 0, maxy = 0;
                            var arregloLatLng = [];
                            for (var j = 0; j < nuevos.length; j++) {
                              var item = nuevos[j];
                              var otro = new google.maps.LatLng(item.lat, item.lng);
                              arregloLatLng.push(otro);
                              path.push(otro);
                              bounds.extend(otro);
                            }
                            var centro = bounds.getCenter();
                            poligono.centro = {};
                            poligono.centro.lat = centro.lat();
                            poligono.centro.lng = centro.lng();
                            poligono.lat = centro.lat();
                            poligono.lng = centro.lng();
                            var coordenadasImprimir = google.maps.geometry.encoding.encodePath(path);
                            poligono.encodeMapa = coordenadasImprimir;
                            if (poligono.enLinea == 1) {
                              enLinea = true;
                              lineas.push(poligono);
                            } else {
                              areas.push(poligono);
                            }
                          }
                          if (id == 1) {
                            $scope.mapa = {
                              centerX: 14.596240611182054,
                              centerY: -90.53100159560573
                            };
                            $scope.dataTask.ttExento.poligonos = poligonos;
                            if (enLinea) {
                              $scope.mapa.lineas = poligonos;
                            } else {
                              $scope.mapa.poligonos = poligonos;
                            }
                            $scope.mapa.centerX = poligono.lat;
                            $scope.mapa.centerY = poligono.lng;
                            $scope.enLinea = enLinea;
                          } else {
                            if (areas.length <= 0) {
                              toastr.error('No hay descuentos para árboles en línea');
                              return;
                            }
                          }
                          var finca = $scope.dataTask.ttExento.fincas[0].tcFinca;
                          var direccionFinca = finca.direccion + ', ' + finca.tcMunicipio.municipioDesc + ', ' + finca.tcMunicipio.tcDepartamento.departamentoDesc;
                          if (lineas.length > 0) {
                            if (parseFloat($scope.ttActualizacionExento.longitud) <= 0) {
                              toastr.error('No es posible continuar debido a que la longitud del exento es menor o igual a cero, favor de verificar');
                              $scope.dataTask.ttExento.poligonos = [];
                              $scope.mapa.lineas = [];
                              $scope.mapa.mapas = [];
                              return;
                            }
                            catalogosService.getLongitudLinea(lineas).then(function (res) {
                              if (res.status == 1) {
                                var data = res.data;
                                var lineas = data.poligonos;
                                var traslapes = data.traslapes;
                                var longitudTotal = 0;
                                for (let i = 0; i < lineas.length; i++) {
                                  var element = lineas[i];
                                  element.longitud = parseFloat(element.area);
                                  longitudTotal += parseFloat(element.area);
                                }
                                longitudTotal = parseFloat(longitudTotal).toFixed(2);
                                var diff = parseFloat($scope.ttActualizacionExento.longitud) - parseFloat(longitudTotal);
                                $scope.mapa.lineas = data.poligonos;
                                var areaPoligonos = data.poligonos;
                                var poligonosEnviar = $scope.dataTask.ttExento.poligonos;
                                for (var i = 0; i < poligonosEnviar.length; i++) {
                                  var tmpPoligono = poligonosEnviar[i];
                                  for (var j = 0; j < areaPoligonos.length; j++) {
                                    const tmpPoligono2 = areaPoligonos[j];
                                    if (tmpPoligono.numero == tmpPoligono2.numero) {
                                      tmpPoligono.area = tmpPoligono2.area;
                                      tmpPoligono.areaEfectiva = tmpPoligono2.area;
                                      tmpPoligono.enLinea = true;
                                      break;
                                    }
                                  }
                                  tmpPoligono.direccion = direccionFinca;
                                  tmpPoligono.propietario = textoPropietario;
                                }
                                diff = Math.abs(diff);
                                var percent = diff * 100;
                                percent = percent / parseFloat($scope.ttActualizacionExento.longitud);
                                percent = Math.abs(percent);
                                var strMensaje = '';
                                if (percent >= 5) {
                                  $scope.dataTask.numero = -1;
                                  strMensaje = 'Hay una variación mayor al 5 % de la longitud registrada';
                                }
                                $scope.mapa.lineaOriginal = $scope.ttActualizacionExento.longitud;
                                $scope.mapa.direccion = finca.direccion + ', ' + finca.tcMunicipio.municipioDesc + ', ' + finca.tcMunicipio.tcDepartamento.departamentoDesc;
                                $scope.mapa.expediente = $scope.dataTask.ttExento.expediente;
                                $scope.mapa.codigoDesc = 'No. RNF';
                                $scope.mapa.codigo = $scope.dataTask.ttExento.codigo;
                                $scope.mapa.tipoDesc = 'Exentos de Licencia Forestal';
                                $scope.mapa.modalidadDesc = 'Exentos de Licencia Forestal';
                                $scope.textoPoligono = 'Cambiar línea';
                                if (traslapes == null) {
                                  traslapes = [];
                                }
                                if (traslapes.length > 0) {
                                  if (strMensaje.length > 0) {
                                    strMensaje += '\nLa línea tiene alguna intersección con las siguientes líneas o polígonos:\n\n';
                                  }
                                  let totalMostrar = traslapes.length > 10 ? 10 : traslapes.length;
                                  for (var i = 0; i < totalMostrar; i++) {
                                    strMensaje += (i + 1) + '. ' + traslapes[i].descripcion + ': ' + traslapes[i].referencia + '. Subregión: ' + traslapes[i].tcSubregion.subregionDesc + '\n';
                                  }
                                }
                                if (strMensaje.length > 0) {
                                  toastr.error(strMensaje, 'Advertencia');
                                }
                                $scope.mapa.longitudTotal = parseFloat(longitudTotal).toFixed(2);
                                $scope.mapa.areaDescuento = 0;
                              } else {
                                toastr.error('Error al calcular la longitud de la línea: ' + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                          if (areas.length > 0) {
                            if (parseFloat($scope.ttActualizacionExento.area) <= 0) {
                              toastr.error('No es posible continuar debido a que el área del exento es menor o igual a cero, favor de verificar');
                              $scope.dataTask.ttExento.poligonos = [];
                              $scope.mapa.lineas = [];
                              $scope.mapa.mapas = [];
                              return;
                            }
                            catalogosService.getAreaPoligono(areas).then(function (res) {
                              if (res.status == "OK") {
                                var data = res.data[0];
                                var traslapes = data.traslapes;
                                areaTotal = parseFloat(res.singleValue).toFixed(4);
                                var diff = parseFloat($scope.ttActualizacionExento.area) - parseFloat(areaTotal);
                                if (id == 1) {
                                  $scope.mapa.poligonos = data.poligonos;
                                  var areaPoligonos = data.poligonos;
                                  var poligonosEnviar = $scope.dataTask.ttExento.poligonos;
                                  for (var i = 0; i < poligonosEnviar.length; i++) {
                                    var tmpPoligono = poligonosEnviar[i];
                                    for (var j = 0; j < areaPoligonos.length; j++) {
                                      const tmpPoligono2 = areaPoligonos[j];
                                      if (tmpPoligono.numero == tmpPoligono2.numero) {
                                        tmpPoligono.area = tmpPoligono2.area;
                                        tmpPoligono.enLinea = false;
                                        break;
                                      }
                                    }
                                    tmpPoligono.direccion = direccionFinca;
                                    tmpPoligono.propietario = textoPropietario;
                                  }
                                  diff = Math.abs(diff);
                                  var percent = diff * 100;
                                  percent = percent / parseFloat($scope.ttActualizacionExento.area);
                                  percent = Math.abs(percent);
                                  var strMensaje = '';
                                  if (percent >= 5) {
                                    $scope.dataTask.numero = -1;
                                    strMensaje = 'Hay una variación mayor al 5 % del área registrada';
                                  }
                                  $scope.mapa.areaOriginal = $scope.ttActualizacionExento.area;
                                  $scope.mapa.direccion = direccionFinca;
                                  $scope.mapa.expediente = $scope.dataTask.ttExento.expediente;
                                  $scope.mapa.codigoDesc = 'No. RNF';
                                  $scope.mapa.codigo = $scope.dataTask.ttExento.codigo;
                                  $scope.mapa.tipoDesc = 'Exentos de Licencia Forestal';
                                  $scope.mapa.modalidadDesc = 'Exentos de Licencia Forestal';
                                  $scope.mapa.areaEfectiva = diff;
                                  $scope.mapa.areaDescuento = 0;
                                  for (let i = 0; i < poligonosEnviar.length; i++) {
                                    var element = poligonosEnviar[i];
                                    element.areaEfectiva = diff;
                                  }
                                  $scope.textoPoligono = 'Cambiar polígono';
                                  if (traslapes == null) {
                                    traslapes = [];
                                  }
                                  if (traslapes.length > 0) {
                                    if (strMensaje.length > 0) {
                                      strMensaje += '\nEl polígono tiene alguna intersección con los siguiente polígonos:\n\n';
                                    }
                                    let totalMostrar = traslapes.length > 10 ? 10 : traslapes.length;
                                    for (var i = 0; i < totalMostrar; i++) {
                                      strMensaje += (i + 1) + '. ' + traslapes[i].descripcion + ': ' + traslapes[i].referencia + '. Subregión: ' + traslapes[i].tcSubregion.subregionDesc + '\n';
                                    }
                                  }
                                  if (strMensaje.length > 0) {
                                    swal('Advertencia', strMensaje, 'error');
                                  }
                                  $scope.mapa.area = parseFloat(areaTotal).toFixed(4);
                                  $scope.mapa.areaEfectiva = parseFloat(areaTotal).toFixed(4);
                                  $scope.mapa.areaDescuento = 0;
                                } else {
                                  $scope.dataTask.ttExento.descuentos = poligonos;
                                  $scope.mapa.descuentos = data.poligonos;
                                  var area = $scope.mapa.area;
                                  var areaEfectiva = area - areaTotal;
                                  diff = parseFloat($scope.dataTask.ttExento.area) - areaEfectiva;
                                  var percent = diff * 100;
                                  percent = percent / parseFloat($scope.dataTask.ttExento.area);
                                  percent = Math.abs(percent);
                                  var strMensaje = '';
                                  if (percent >= 5) {
                                    $scope.dataTask.numero = -1;
                                    swal('Advertencia', 'Hay una variación mayor al 5 % del área registrada', 'error');
                                  } else {
                                    toastr.success('Coordenadas cargadas correctamente');
                                  }
                                  $scope.mapa.areaEfectiva = parseFloat(areaEfectiva).toFixed(4);
                                  $scope.mapa.areaDescuento = parseFloat(areaTotal).toFixed(4);
                                  var poligonosEnviar = $scope.dataTask.ttExento.poligonos;
                                  for (let i = 0; i < poligonosEnviar.length; i++) {
                                    var element = poligonosEnviar[i];
                                    element.areaEfectiva = $scope.mapa.areaEfectiva;
                                    element.areaDescuento = $scope.mapa.areaDescuento;
                                  }
                                }
                              } else {
                                toastr.error('Error al calcular el área del polígono: ' + res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        } else {
                          if ($scope.veces > 0) {
                            $timeout(function () {
                              $scope.veces--;
                              $scope.onFileSelect(file, id);
                            }, 1000);
                          } else {
                            $scope.veces = 2;
                            $scope.visibleMapa = false;
                            swal('No se pudo cargar el archivo');
                          }
                        }
                      } else {
                        $scope.visibleMapa = false;
                        swal('No se podigo cargar el archivo, verifique que sea el formato correcto');
                      }
                    }

                    $scope.visualizarMapa = function (poligono, numero, esLinea) {
                      if (esLinea == null) {
                        esLinea = false;
                      }                      
                      if (esLinea) {
                        $scope.mapa.encodeMapa = 'path=color:0xff0000ff%7Cweight:5%7Cenc:' + poligono.encodeMapa;
                      } else {
                        $scope.mapa.encodeMapa = 'path=fillcolor:0xAA000033%7Ccolor:0xFFFFFF00%7Cenc:' + poligono.encodeMapa;
                      }
                      $scope.mapa.coordenadasGtm = poligono.coordenadasGtm;
                      $scope.mapa.numero = parseInt(numero) + 1;
                      $scope.mapa.areaPoligono = parseFloat(poligono.area).toFixed(4);
                      if (poligono.enLinea == null) {
                        poligono.enLinea = false;
                      }
                      if (poligono.enLinea) {
                        $scope.mapa.longitud = parseFloat(poligono.area).toFixed(2);
                      }
                      $scope.mapa.enLinea = poligono.enLinea;
                      ngDialog.open({
                        template: 'app/common/poligono.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'custom-map',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniquePoligono',
                        name: 'uniquePoligono',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr',
                          function ($scope, $state, toastr) {

                          }]
                      })
                    }

                    $scope.imprimirMapa = function () {
                      utilsService.openWindow('#poligono', $scope, 'Mapa');
                    };

                    $scope.generarDocumentoProvupdexento = function (refresh, data) {
                      var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia, false);
                      var strAnio = $scope.numeroALetras(anio, false);                      
                      utilsService.setEsPrevia(!refresh);
                      if (data.ttActualizacionExento.tipoActualizacionId == 2) {
                        utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      $scope.dataTask.fechaFinalizacion = new Date();
                      var data = angular.copy($scope.dataTask);
                      var ttActualizacionExento = angular.copy($scope.ttActualizacionExento);
                      if (ttActualizacionExento.tipoActualizacionId != 1) {
                        if (data.codigo == null) {
                          toastr.error('Debe indicar el número de providencia/resolución');
                          return;
                        } else {
                          if (data.codigo.length <= 0) {
                            toastr.error('Debe indicar el número de providencia/resolución');
                            return;
                          }
                        }
                        if (data.tcPersonaTraslado == null) {
                          toastr.error('Debe seleccionar al(a) técnico(a) a asignar');
                          return;
                        }
                        if (data.tcPersonaTraslado.personaId == null) {
                          toastr.error('Debe seleccionar al(a) técnico(a) a asignar');
                          return;
                        }
                      } else {
                        data.codigo = 'XXX';
                        if ($scope.dataTask.approved) {
                          if (ttActualizacionExento.fechaVencimiento == null || ttActualizacionExento.fechaActualizacion == null) {
                            toastr.error('Debe indicar la fecha de actualización del registro y la de vencimiento');
                            return;
                          }
                          var rodales = ttActualizacionExento.rodales;
                          var saldoEspecies = [];
                          for (let i = 0; i < rodales.length; i++) {
                            const rodal = rodales[i];
                            var especies = rodal.especies;
                            for (let j = 0; j < especies.length; j++) {
                              const especie = especies[j];
                              let encontrado = false;
                              for (let k = 0; k < saldoEspecies.length; k++) {
                                var saldoEspecie = saldoEspecies[k];
                                if (parseInt(saldoEspecie.tcEspecie.especieId) == parseInt(especie.tcEspecie.especieId)) {
                                  saldoEspecie.volumen += especie.volumen;
                                  encontrado = true;
                                  break;
                                }
                              }
                              if (!encontrado) {
                                var saldoEspecie = {
                                  volumen: especie.volumen,
                                  tcEspecie: especie.tcEspecie
                                }
                                saldoEspecies.push(saldoEspecie);
                              }
                            }
                          }
                          data.ttExento.rodales = rodales;
                          data.ttExento.especies = saldoEspecies;
                        } else {
                          if (data.observaciones.length <= 0) {
                            toastr.error('Debe indicar al menos una razón de la no aprobación de la actualización');
                            return;
                          }
                        }
                      }
                      data.ttActualizacionExento = ttActualizacionExento;
                      $scope.generarDocumentoProvupdexento(refresh, data);
                    }

                    $scope.providenciarActualizacionExento = function (isValid) {
                      if (isValid) {
                        if ($scope.dataTask.approved) {
                          var rodales = $scope.ttActualizacionExento.rodales;
                          if (rodales == null) {
                            rodales = [];
                          }
                          var totalArea = 0;
                          var totalLongitud = 0;
                          for (var i = 0; i < rodales.length; i++) {
                            var rodal = rodales[i];
                            if (rodal.enLinea == 1) {
                              totalLongitud += parseFloat(rodal.area);
                            } else {
                              totalArea += parseFloat(rodal.area);
                            }
                          }
                          totalArea = parseFloat(totalArea).toFixed(4);
                          totalLongitud = parseFloat(totalLongitud).toFixed(2);
                          if (parseFloat($scope.dataTask.ttActualizacionExento.area) != totalArea) {
                            toastr.error('La sumatoria de las áreas por rodal (' + totalArea 
                            + ') debe ser igual al área aprobada (' + $scope.dataTask.ttExento.area + '), favor de revisar');
                            return;
                          }
                          if (parseFloat($scope.dataTask.ttActualizacionExento.longitud) != totalLongitud) {
                            toastr.error('La sumatoria de las longitudes de las líneas (' + totalLongitud 
                            + ') debe ser igual a la longitud aprobada (' + $scope.dataTask.ttActualizacionExento.longitud + '), favor de revisar');
                            return;
                          }
                          if ($scope.ttActualizacionExento.tipoActualizacionId == 1) {
                            if ($scope.dataTask.ttExento.poligonos == null) {
                              toastr.error('Debe cargar los polígonos o líneas de la actualización');
                              return;
                            }
                            if ($scope.dataTask.ttExento.poligonos.length <= 0) {
                              toastr.error('Debe cargar los polígonos o líneas de la actualización');
                              return;
                            }
                          }
                          $scope.dataTask.aprobado = 1;
                        } else {
                          $scope.dataTask.aprobado = 0;
                        }
                      }
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              var ttActualizacionExento = angular.copy($scope.ttActualizacionExento);
                              var ttExento = data.ttExento;
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea,
                                fechaVencimiento: ttExento.fechaVencimiento
                              };
                              if ($scope.dataTask.approved) {
                                data.ttExento.poligonos = ttExento.poligonos;
                              }
                              data.fechaProvidencia = new Date();
                              delete ttActualizacionExento.ttExento;
                              data.ttActualizacionExento = ttActualizacionExento;
                              data.observaciones = JSON.stringify(data.observaciones);
                              delete ttActualizacionExento.thActualizacionExento;
                              tareaService.resolverSolicitudActualizacionExento(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  tareaService.getTareaSolicitudActualizacionExento(data.tareaExentoId).then(function (resp) {
                                    if (resp.status == 1) {
                                      data = resp.data;
                                      data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                                      if (data.aprobado == 1) {
                                        data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                                        data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                                      }
                                      var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                                      if (ventas == null) {
                                        ventas = [];
                                      }
                                      for (let i = 0; i < ventas.length; i++) {
                                        var element = ventas[i];
                                        element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                                      }
                                      data.observaciones = JSON.parse(data.observaciones);
                                      $scope.generarDocumentoProvupdexento(true, data);
                                    } else {
                                      toastr.error(resp.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                    $scope.anularSolicitudActualizacion = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro de anular esta solicitud y notificar al usuario por qué? Esta acción no se podrá reversar.\n\nIngrese el motivo de la anulación",
                        type: "input",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Aceptar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true,
                        inputPlaceholder: "Motivo de anulación"
                      },
                        function (inputValue) {
                          if (inputValue === false) return false;
                          if (inputValue === "") {
                            toastr.error('Es necesario el motivo ya que se le notificará al usuario');
                            return false;
                          }
                          var data = angular.copy($scope.dataTask);
                          var ttExento = data.ttExento;
                          data.ttExento = {};
                          data.ttExento = {
                            exentoId: ttExento.exentoId,
                            tcTipoGestion: ttExento.tcTipoGestion,
                            estadoId: ttExento.estadoId,
                            tcPersonaCrea: ttExento.tcPersonaCrea
                          };
                          data.observaciones = inputValue;
                          delete data.ttActualizacionExento;
                          tareaService.anularSolicitudActualizacionExento(data).then(function (res) {
                            if (res.status == 1) {
                              toastr.success(res.message);
                              $state.go('index.tarea.exentolist');
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.abandono', {
            url: '/abandono',
            views: {
              '': {
                templateUrl: 'app/tarea/subregional.abandono.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaArchivoAbandono($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', '$timeout', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea', '$filter',
                  function ($scope, $state, toastr, $timeout, ngDialog, tareaService, utilsService, dataTarea, $filter) {
                    $scope.classAprobar = "btn btn-default";
                    $scope.classNoAprobar = "btn btn-default";
                    $scope.classEnmienda = "btn btn-default";
                    $scope.mostrarGuardar = true;
                    var datos = dataTarea.data;
                    $scope.dataTask = datos.ttTarea;
                    $scope.datos = datos;
                    if ($scope.dataTask.ttSeguimientoTarea == null) {
                      $scope.dataTask.ttSeguimientoTarea = {
                        fechaFinalizacion: new Date(),
                        numeroResolucion: null,
                        codigo: null
                      }
                    }
                    $scope.dataTask.esEnmienda = 0;
                    $scope.dataTask.aprobado = 1;

                    $scope.vistaPrevia = function (mover) {
                      if (mover == null) {
                        mover = false;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el número de resolución");
                        return;
                      }
                      var ttTarea = angular.copy($scope.dataTask);
                      var datos = angular.copy($scope.datos);
                      datos.ttTarea = ttTarea;
                      var fecha = new Date(ttTarea.fechaFinalizacion);
                      var dia = $filter('date')(fecha, 'dd');
                      var mes = $filter('date')(fecha, 'MM');
                      var anio = $filter('date')(fecha, 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      var strMes = utilsService.getMonthName(mes);
                      var strFechaResolucion = strDia + ' de ' + strMes + ' de ' + strAnio;
                      fecha = new Date(datos.ttSolicitud.fechaRecepcion);
                      dia = $filter('date')(fecha, 'dd');
                      mes = $filter('date')(fecha, 'MM');
                      anio = $filter('date')(fecha, 'yyyy');
                      strDia = $scope.numeroALetras(dia);
                      strAnio = $scope.numeroALetras(anio);
                      strMes = utilsService.getMonthName(mes);
                      var strFechaSolicitud = strDia + ' de ' + strMes + ' de ' + strAnio;
                      fecha = new Date(datos.ttEnmienda.fechaEnmienda);
                      dia = $filter('date')(fecha, 'dd');
                      mes = $filter('date')(fecha, 'MM');
                      anio = $filter('date')(fecha, 'yyyy');
                      strDia = $scope.numeroALetras(dia);
                      strAnio = $scope.numeroALetras(anio);
                      strMes = utilsService.getMonthName(mes);
                      var strFechaEnmienda = strDia + ' de ' + strMes + ' de ' + strAnio;
                      utilsService.generarResolucionArchivoAbandono(datos, strFechaResolucion, strFechaSolicitud, strFechaEnmienda, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      $timeout(function () {
                        utilsService.generarCedulaNotificacionArchivoAbandono(datos.ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 2000);
                      if (mover) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function () {
                      var data = angular.copy($scope.data);
                      if ($scope.dataTask.aprobado == null) {
                        toastr.error("Debe seleccionar su decisión");
                        return;
                      }
                      if ($scope.dataTask.ttSeguimientoTarea.numeroResolucion == null || $scope.dataTask.ttSeguimientoTarea.numeroResolucion.length <= 0) {
                        toastr.error("Debe indicar el numero de dictamen");
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su opinión? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttGestion.fincas;
                            delete data.ttGestion.notificaciones;
                            delete data.ttGestion.personas;
                            delete data.ttGestion.representantes;
                            delete data.ttPadre;
                            data.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            tareaService.setDecisionSuspensionGestion(data).then(function (res) {
                              if (res.status == "OK") {
                                var tmp = res.data[0];
                                data = $scope.dataTask;
                                data.ttSeguimientoTarea.fechaProvidencia = tmp.fechaProvidencia;
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                $scope.vistaPrevia(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    } // fin enviar tarea

                  }]
              }
            }
          })
          .state('index.realizar.provavaluo', {
            url: '/provavaluo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provavaluo.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaProvidenciaAvaluo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataTecnico', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, dataTecnico, authService) {
                    $scope.dataTecnico = dataTecnico.data;
                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttAvaluo.expediente
                    }

                    $scope.setTecnico = function (item, esColaborador, colaborador) {
                      var colaboradores = $scope.dataTask.colaboradores;
                      if (colaboradores == null) {
                        colaboradores = [];
                      }
                      var veces = 0;
                      for (var i = 0; i < colaboradores.length; i++) {
                        var row = colaboradores[i];
                        if (row.tcPersonaAsignada.personaId == item.personaId) {
                          veces++;
                        }
                      }
                      if ($scope.dataTask.tcPersonaTraslado != null) {
                        if ($scope.dataTask.tcPersonaTraslado.personaId == item.personaId) {
                          veces++;
                        }
                      }
                      if (veces > 1) {
                        toastr.error('El técnico indicado ya se encuentra asignado, no es posible agregarlo de nuevo');
                        if (esColaborador) {
                          colaborador = {};
                        } else {
                          $scope.dataTask.tcPersonaTraslado = {};
                        }
                        return;
                      }
                      if (esColaborador) {
                        colaborador.tcPersonaAsignada.personaDesc = item.personaDesc;
                        colaborador.tcPersonaAsignada.correo = item.correo;
                        colaborador.tcPersonaAsignada.cui = item.cui;
                      } else {
                        $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                        $scope.dataTask.tcPersonaTraslado.correo = item.correo;
                        $scope.dataTask.tcPersonaTraslado.cui = item.cui;
                      }
                    }

                    $scope.agregarColaborador = function () {
                      if ($scope.dataTask.colaboradores == null) {
                        $scope.dataTask.colaboradores = [];
                      }
                      $scope.dataTask.colaboradores.push({});
                    }

                    $scope.quitarColaborador = function (item) {
                      $scope.dataTask.colaboradores.splice($scope.dataTask.colaboradores.indexOf(item), 1);
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de providencia');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length <= 0) {
                          toastr.error('Debe indicar el número de providencia');
                          return;
                        }
                      }
                      if ($scope.dataTask.tcPersonaTraslado == null) {
                        toastr.error('Debe seleccionar a la técnico a asignar');
                        return;
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      var data = angular.copy($scope.dataTask);
                      try {
                        data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                      } catch (error) {

                      }
                      utilsService.generarProvidenciaAvaluo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (refresh) {
                        $state.go('index.tarea.avaluo');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              delete data.ttAvaluo;
                              tareaService.asignarProvidenciaAvaluo(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaAsignacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opavaluo', {
            url: '/opavaluo',
            templateUrl: 'app/tarea/tarea.opavaluo.tpl.html',
            resolve: {
              dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                function (tareaService, $stateParams, authService, $base64) {
                  return tareaService.getTareaDictamenTecnicoAvaluo($base64.decode($stateParams.tareaId));
                }],
              dataEscenario: ['escenarioAvaluoService',
                function (escenarioAvaluoService) {
                  return escenarioAvaluoService.listaActiva();
                }],
              dataTipoBosque: ['tipobosqueService',
                function (tipobosqueService) {
                  return tipobosqueService.listaActiva();
                }]
            },
            controller: ['$scope', '$state', 'especieTalaIlicitaService', 'toastr', 'manualService', 'authService', 'dataEscenario', 'lesionEconomicaService', 'productotransporteService',
              'dataTarea', 'utilsService', 'cargaMaderableService', 'valortipomaderaService', 'tareaService', 'dataTipoBosque', 'costoEvitadoService', 'especieService',
              function ($scope, $state, especieTalaIlicitaService, toastr, manualService, authService, dataEscenario, lesionEconomicaService, productotransporteService,
                dataTarea, utilsService, cargaMaderableService, valortipomaderaService, tareaService, dataTipoBosque, costoEvitadoService, especieService) {

                $scope.dataEscenario = dataEscenario.data;
                $scope.dataEspecie = null;
                $scope.dataProductoTransporte = null;
                $scope.dataEspecieIlicita = null;
                $scope.dataTipoBosque = null;
                $scope.ttTarea = dataTarea.data;
                $scope.dataTipoEcosistema = [
                  { tipoEcosistemaId: true, tipoEcosistemaDesc: 'Manglar' },
                  { tipoEcosistemaId: false, tipoEcosistemaDesc: 'Otros ecosistemas' }
                ];
                $scope.dataEsMaderable = [
                  { maderable: true, maderableDesc: 'Si' },
                  { maderable: false, maderableDesc: 'No' }
                ];
                $scope.dataTipoBosqueBk = dataTipoBosque.data;
                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion == null) {
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion = {
                    tcEscenarioAvaluo: {}
                  };
                }
                if ($scope.ttTarea.ttAvaluo.ttAvaluoMaderable != null) {
                  var total = parseFloat($scope.ttTarea.ttAvaluo.ttAvaluoMaderable.costoLenia) + parseFloat($scope.ttTarea.ttAvaluo.ttAvaluoMaderable.costoTroza);
                  $scope.ttTarea.ttAvaluo.ttAvaluoMaderable.total = parseFloat(total).toFixed(2);
                }

                $scope.tabActive = {
                  uno: false,
                  dos: false,
                  tres: false
                }
                $scope.tabVisible = {
                  uno: false,
                  dos: false,
                  tres: false
                }

                $scope.descargarFormato = function () {
                  manualService.getFile(42); //ver codigo en bd
                }

                $scope.obtenerValorMadera = function (filtro) {
                  valortipomaderaService.listaCostoTipoMaderaAnio(filtro).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      var valor = data.valor;
                      var especies = data.especies;
                      for (var j = 0; j < especies.length; j++) {
                        for (var i = 0; i < valor.length; i++) {
                          if (valor[i].tcTipoMadera.tipoMaderaId == especies[j].tcTipoMadera.tipoMaderaId) {
                            especies[j].tcValorTipoMadera = valor[i];
                            break;
                          }
                        }
                      }
                      $scope.dataValorMadera = especies;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                if ($scope.ttTarea.ttAvaluo.especies != null) {
                  if ($scope.ttTarea.ttAvaluo.especies.length > 0) {
                    var rodal = $scope.ttTarea.ttAvaluo;
                    var lista = [];
                    //buscar especie para obtener el precio a través del servicio, segun año y region
                    for (var j = 0; j < rodal.especies.length; j++) {
                      lista.push(rodal.especies[j].tcEspecie);
                    }
                    var fecha = new Date();
                    var anio = fecha.getFullYear();
                    var filtro = {
                      anio: anio,
                      tcRegion: $scope.ttTarea.ttAvaluo.tcSubregion.tcRegion,
                      especies: lista
                    }
                    $scope.obtenerValorMadera(filtro);
                  }
                }

                $scope.setDataEspecie = function (data) {
                  for (var i = 0; i < data.length; i++) {
                    var row = data[i];
                    row.especieId = row.tcEspecie.especieId;
                    row.nombreCientifico = row.tcEspecie.nombreCientifico;
                  }
                  $scope.dataEspecieIlicita = data;
                  if (data.length > 0) {
                    authService.removeLocalData('dataEspecieIlicita');
                    authService.saveLocalData('dataEspecieIlicita', data);
                  } else {
                    authService.removeLocalData('dataEspecieIlicita');
                  }
                }

                $scope.data = {
                  especies: []
                }

                $scope.filtro = {};

                $scope.gridOptionsExcel = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptionsExcel.columnDefs = [
                  { field: 'no', name: 'no', displayName: 'No. Árbol' },
                  { field: 'nombre_cientifico', name: 'nombre_cientifico', displayName: 'Nombre científico' },
                  { field: 'dat', name: 'dat', displayName: 'DAT' },
                  { field: 'dap', name: 'dap', displayName: 'DAP', width: '15%' },
                  { field: 'altura', name: 'altura', displayName: 'Altura', width: '15%' },
                  { field: 'volumen', name: 'volumen', displayName: 'Volumen' }
                ];
                $scope.gridOptionsExcel.data = [];

                $scope.setEscenarioAvaluo = function (item, cambiarEcosistema) {
                  switch (item.escenarioAvaluoId) {
                    case 1:
                      if ($scope.dataEspecieIlicita == null) {
                        especieTalaIlicitaService.getListaByRegion($scope.ttTarea.ttAvaluo.tcSubregion.tcRegion.regionId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.setDataEspecie(res.data);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                      break;
                  }
                  if (cambiarEcosistema) {
                    $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.manglar = null;
                  }
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId != null) {
                  $scope.setEscenarioAvaluo($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo, false);
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.inventario != null) {
                  $scope.gridOptionsExcel.data = JSON.parse($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.inventario);
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo != null) {
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo = new Date($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo);
                }

                if ($scope.ttTarea.ttAvaluo.especies != null) {
                  if ($scope.ttTarea.ttAvaluo.especies.length > 0) {
                    var especies = $scope.ttTarea.ttAvaluo.especies;
                    for (var i = 0; i < especies.length; i++) {
                      var row = especies[i];
                      if (row.porcentajeTroza != null) {
                        var valor = parseFloat(row.valorTroza) + parseFloat(row.valorLenia);
                        row.valorTotal = parseFloat(valor).toFixed(2);
                      }
                    }
                  }
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion != null) {
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque != null) {
                    if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque.tipoBosqueId != null) {
                      if ($scope.ttTarea.ttAvaluo.costos != null) {
                        if ($scope.ttTarea.ttAvaluo.costos.length > 0) {
                          $scope.tabActive.dos = true;
                          $scope.tabVisible.uno = true;
                          $scope.tabVisible.dos = true;
                        }
                      } else {
                        $scope.tabActive.uno = true;
                        $scope.tabVisible.uno = true;
                      }
                      if ($scope.ttTarea.codigo != null) {
                        $scope.tabActive.tres = true;
                        $scope.tabVisible.uno = true;
                        $scope.tabVisible.dos = true;
                        $scope.tabVisible.tres = true;
                      }
                    }
                  }
                }

                var valor = function (json, columna) {
                  for (var key in json) {
                    if (key == columna) return json[key];
                  }
                  return null;
                }

                $scope.calcularAltura = function (especie) {
                  if (especie.especieId == null) {
                    return;
                  }
                  if (especie.dat == null) {
                    return;
                  }
                  var formula = angular.copy(especie.data.formula);
                  var variable = JSON.parse(especie.data.variable);
                  for (var i = 0; i < 5; i++) {
                    for (var j = 0; j < variable.length; j++) {
                      var row = variable[j];
                      formula = formula.replace(row.variable, especie.dat);
                    }
                  }
                  var altura = new Function('return ' + formula)();
                  especie.altura = parseFloat(altura).toFixed(2);
                  formula = angular.copy(especie.data.formulaDap);
                  variable = JSON.parse(especie.data.variableDap);
                  for (var i = 0; i < 5; i++) {
                    for (var j = 0; j < variable.length; j++) {
                      var row = variable[j];
                      formula = formula.replace(row.variable, especie.dat);
                    }
                  }
                  var dap = new Function('return ' + formula)();
                  especie.dap = parseFloat(dap).toFixed(2);
                  formula = especie.data.tcEspecie.ecuacion;
                  var variables = JSON.parse(angular.copy(especie.data.tcEspecie.formula))
                  for (j = 0; j < variables.length; j++) {
                    variable = variables[j].variable;
                    value = valor(especie, variable.toLowerCase());
                    for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                      formula = formula.replace(variable, value);
                    }
                  }
                  var volumen = new Function('return ' + formula)();
                  especie.volumen = parseFloat(volumen).toFixed(4);
                }

                $scope.setEspecieTalaIlicita = function (item, especie) {
                  especie.data = item;
                  $scope.calcularAltura(especie);
                }

                $scope.setTipoEcosistema = function (item, actualizarBosque) {
                  if (actualizarBosque) {
                    if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque != null) {
                      if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque.tipoBosqueId != null) {
                        $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque.tipoBosqueId = null;
                      }
                    }
                  }
                  var data = $scope.dataTipoBosqueBk;
                  var dataTmp = [];
                  if (item.tipoEcosistemaId) {
                    for (var i = 0; i < data.length; i++) {
                      var row = data[i];
                      if (row.tipoBosqueId == 4) {
                        dataTmp.push(row);
                      }
                    }
                  } else {
                    for (var i = 0; i < data.length; i++) {
                      var row = data[i];
                      if (row.tipoBosqueId != 4) {
                        dataTmp.push(row);
                      }
                    }
                  }
                  $scope.dataTipoBosque = dataTmp;
                }

                $scope.setTipoBosque = function (item) {
                  costoEvitadoService.getByTipoBosque(item.tipoBosqueId).then(function (res) {
                    if (res.status == 1) {
                      $scope.dataCostoEvitado = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                  lesionEconomicaService.getByTipoBosque(item.tipoBosqueId).then(function (res) {
                    if (res.status == 1) {
                      $scope.dataLesionEconomica = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId == 1) {
                    return;
                  }
                  cargaMaderableService.getByRegionAndTipoBosque($scope.ttTarea.ttAvaluo.tcSubregion.tcRegion.regionId,
                    item.tipoBosqueId).then(function (res) {
                      if (res.status == 1) {
                        $scope.dataCargaMaderable = res.data;
                      } else {
                        $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque.tipoBosqueId = null;
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  if ($scope.dataEspecie == null) {
                    especieService.listaActiva().then(function (res) {
                      if (res.status == "OK") {
                        $scope.dataEspecie = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                  if ($scope.dataProductoTransporte == null) {
                    productotransporteService.listaActiva().then(function (res) {
                      if (res.status == "OK") {
                        var dataTmp = res.data;
                        var data = [];
                        for (var i = 0; i < dataTmp.length; i++) {
                          var row = dataTmp[i];
                          if (row.productoTransporteId < 5) {
                            data.push(row);
                          }
                        }
                        $scope.dataProductoTransporte = data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.manglar != null) {
                  var item = { tipoEcosistemaId: $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.manglar };
                  $scope.setTipoEcosistema(item, false);
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque != null) {
                  var tcTipoBosque = $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque;
                  if (tcTipoBosque.tipoBosqueId != null) {
                    $scope.setTipoBosque(tcTipoBosque);
                  }
                }

                $scope.fechaAvaluo = $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo;

                if ($scope.ttTarea.observaciones != null) {
                  try {
                    var json = JSON.parse($scope.ttTarea.observaciones);
                    $scope.dataAcompaniante = json.acompaniantes;
                    $scope.dataObservacion = json.observaciones;
                  } catch (error) { }
                }

                $scope.descargarDatos = function () {
                  var xlsxData = [];
                  var xlsxHeader = ['No. Álbol', 'Nombre científico', 'DAP', 'Altura', 'Volumen (m³)'];
                  xlsxData.push(xlsxHeader);
                  var data = $scope.gridOptionsExcel.data;
                  for (var i = 0; i < data.length; i++) {
                    var xlsxRow = [];
                    xlsxRow.push(data[i].no);
                    xlsxRow.push(data[i].nombre_cientifico);
                    xlsxRow.push(data[i].dap);
                    xlsxRow.push(data[i].altura);
                    xlsxRow.push(data[i].volumen);
                    xlsxData.push(xlsxRow);
                  }
                  var filename = "data.xlsx";
                  var sheetName = "data";
                  var wb = XLSX.utils.book_new();
                  var ws = XLSX.utils.aoa_to_sheet(xlsxData);
                  XLSX.utils.book_append_sheet(wb, ws, sheetName);
                  XLSX.writeFile(wb, filename);
                }

                $scope.generarResumen = function () {
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo == null) {
                    toastr.error('Seleccione el escenario');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId == null) {
                    toastr.error('Seleccione el escenario');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.manglar == null) {
                    toastr.error('Seleccione el tipo de ecosistema');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque == null) {
                    toastr.error('Seleccione el tipo de bosque');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcTipoBosque.tipoBosqueId == null) {
                    toastr.error('Seleccione el tipo de bosque');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.gtmX == null) {
                    toastr.error('Debe indicar las coordenadas de avalúo');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.gtmY == null) {
                    toastr.error('Debe indicar las coordenadas de avalúo');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.gtmX.toString().length != 6) {
                    toastr.error('Debe indicar las coordenadas válidas de avalúo (longitud)');
                    return;
                  }
                  if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.gtmY.toString().length != 7) {
                    toastr.error('Debe indicar las coordenadas válidas de avalúo (longitud)');
                    return;
                  }
                  switch ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId) {
                    case 1:
                      if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.area == null) {
                        toastr.error('Debe indicar el área talada');
                        return;
                      }
                      var data = $scope.gridOptionsExcel.data;
                      if (data.length <= 0) {
                        toastr.error('Debe de cargar la boleta de inventario');
                        return;
                      }
                      $scope.ttTarea.ttAvaluo.especies = [];
                      var rodal = $scope.ttTarea.ttAvaluo;
                      rodal.parcelas = [];
                      for (var i = 0; i < data.length; i++) {
                        var row = data[i];
                        var tcEspecie = {
                          especieId: row.especieId,
                          nombreCientifico: row.nombre_cientifico
                        };
                        var especie = {
                          tcEspecie: tcEspecie,
                          cantidad: 1,
                          dap: parseFloat(row.dap),
                          altura: parseFloat(row.altura),
                          volumen: parseFloat(row.volumen),
                          tipoInventarioId: 1 //censo
                        }
                        encontrado = false;
                        for (var j = 0; j < rodal.especies.length; j++) {
                          if (rodal.especies[j].tcEspecie.especieId == especie.tcEspecie.especieId) {
                            especie = rodal.especies[j];
                            if (especie.cantidad == null) {
                              especie.cantidad = 1;
                            }
                            especie.cantidad += 1;
                            especie.dap += parseFloat(row.dap);
                            especie.altura += parseFloat(row.altura);
                            especie.volumen += parseFloat(row.volumen);
                            encontrado = true;
                            break;
                          }
                        }
                        if (!encontrado) {
                          rodal.especies.push(especie);
                        }
                        if (row.parcela != null && row.tamanio_parcela != null) {
                          especie.tipoInventarioId = 2; //muestreo
                          var parcela = {
                            parcela: row.parcela,
                            tamanioParcela: row.tamanio_parcela
                          }
                          rodal.tamanioParcela = parseInt(parcela.tamanioParcela);
                          encontrado = false;
                          for (var j = 0; j < rodal.parcelas.length; j++) {
                            if (rodal.parcelas[j].parcela == parcela.parcela) {
                              encontrado = true;
                              break;
                            }
                          }
                          if (!encontrado) {
                            rodal.parcelas.push(parcela);
                          }
                        }
                      }
                      for (var j = 0; j < rodal.especies.length; j++) {
                        var especie = rodal.especies[j];
                        var valor = 0;
                        if (especie.tipoInventarioId == 2) {
                          rodal.ttAvaluoInformacion.areaMuestreada = (rodal.parcelas.length * parseFloat(rodal.tamanioParcela)) / 10000;
                          valor = parseFloat(especie.volumen) / parseFloat(rodal.ttAvaluoInformacion.areaMuestreada);
                          valor = valor * parseFloat(rodal.ttAvaluoInformacion.area);
                          especie.volumenRodal = parseFloat(valor).toFixed(2);
                          valor = parseFloat(especie.cantidad) / parseFloat(rodal.ttAvaluoInformacion.areaMuestreada);
                          valor = valor * parseFloat(rodal.ttAvaluoInformacion.area);
                          especie.arboles = Math.ceil(valor);
                        } else {
                          especie.volumenRodal = parseFloat(especie.volumen).toFixed(2);
                          especie.arboles = especie.cantidad;
                        }
                        valor = parseFloat(especie.altura) / especie.cantidad;
                        especie.alturaPromedio = parseFloat(valor).toFixed(2);
                        valor = parseFloat(especie.dap) / especie.cantidad;
                        especie.dapPromedio = parseFloat(valor).toFixed(2);
                      }
                      $scope.ttTarea.ttAvaluo = rodal;
                      $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.inventario = JSON.stringify(data);
                      var lista = [];
                      //buscar especie para obtener el precio a través del servicio, segun año y region
                      for (var j = 0; j < rodal.especies.length; j++) {
                        lista.push(rodal.especies[j].tcEspecie);
                      }
                      var fecha = new Date();
                      var anio = fecha.getFullYear();
                      var filtro = {
                        anio: anio,
                        tcRegion: $scope.ttTarea.ttAvaluo.tcSubregion.tcRegion,
                        especies: lista
                      }
                      $scope.obtenerValorMadera(filtro);
                      break;
                    case 2:
                      var valorCarga = $scope.dataCargaMaderable.volumen;
                      var valorTroza = $scope.dataCargaMaderable.valorTrozaSin;
                      var valorLenia = $scope.dataCargaMaderable.valorLeniaSin;
                      var volumen = parseFloat($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.area) * parseFloat(valorCarga);
                      volumen = parseFloat(volumen).toFixed(2);
                      var porcentajeTroza = 70;
                      var porcentaje = porcentajeTroza / 100;
                      var valor = volumen * porcentaje;
                      troza = parseFloat(valor).toFixed(2);
                      porcentaje = 1 - porcentaje;
                      valor = volumen * porcentaje;
                      lenia = parseFloat(valor).toFixed(2);
                      var costoLenia = lenia * parseFloat(valorLenia);
                      costoLenia = parseFloat(costoLenia).toFixed(2);
                      var costoTroza = troza * parseFloat(valorTroza);
                      costoTroza = parseFloat(costoTroza).toFixed(2);
                      var ttAvaluoMaderable = {
                        valorCarga: valorCarga,
                        porcentajeTroza: porcentajeTroza,
                        troza: troza,
                        lenia: lenia,
                        valorTroza: valorTroza,
                        valorLenia: valorLenia,
                        costoLenia: costoLenia,
                        costoTroza: costoTroza,
                        tcCargaMaderable: $scope.dataCargaMaderable
                      };
                      var total = parseFloat(ttAvaluoMaderable.costoLenia) + parseFloat(ttAvaluoMaderable.costoTroza);
                      ttAvaluoMaderable.total = parseFloat(total).toFixed(2);
                      $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.volumen = volumen;
                      $scope.ttTarea.ttAvaluo.ttAvaluoMaderable = ttAvaluoMaderable;
                      break;
                    default:
                      break;
                  }
                  var ttTarea = angular.copy($scope.ttTarea);
                  delete ttTarea.especies;
                  tareaService.actualizarInformacionAvaluo(ttTarea).then(function (res) {
                    if (res.status == 1) {
                      $scope.tabActive.uno = true;
                      $scope.tabVisible.uno = true;
                      $scope.tabVisible.dos = false;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.setPorcentajeTroza = function (item) {
                  if (item.porcentajeTroza == null) {
                    return;
                  }
                  if (item.porcentajeTroza < 0 || item.porcentaje > 100) {
                    toastr.error('Debe indicar un porcentaje valido');
                    item.porcentaje = null;
                    return;
                  }
                  var porcentaje = item.porcentajeTroza / 100;
                  var valor = item.volumenRodal * porcentaje;
                  item.troza = parseFloat(valor).toFixed(2);
                  porcentaje = 1 - porcentaje;
                  valor = item.volumenRodal * porcentaje;
                  item.lenia = parseFloat(valor).toFixed(2);
                  var data = $scope.dataValorMadera;
                  impuesto = null;
                  for (var i = 0; i < data.length; i++) {
                    var row = data[i];
                    if (row.especieId == item.tcEspecie.especieId) {
                      impuesto = data[i].tcValorTipoMadera;
                      break;
                    }
                  }
                  if (impuesto == null) {
                    swal('La especie ' + item.tcEspecie.nombreCientifico + ' no tiene valor asignado, favor de indicar a soporte del sistema');
                    return;
                  }
                  valor = parseFloat(item.troza) * parseFloat(impuesto.troza);
                  item.valorTroza = parseFloat(valor).toFixed(2);
                  valor = parseFloat(item.lenia) * parseFloat(impuesto.lenia);
                  item.valorLenia = parseFloat(valor).toFixed(2);
                  valor = parseFloat(item.valorTroza) + parseFloat(item.valorLenia);
                  valor = parseFloat(valor).toFixed(2);
                  item.valorTotal = valor;
                }

                $scope.validarEspecies = function (incluirCostos) {
                  var especies = $scope.ttTarea.ttAvaluo.especies;
                  var valido = true;
                  var mensaje = '';
                  for (var i = 0; i < especies.length; i++) {
                    var row = especies[i];
                    if (row.tcEspecie == null) {
                      mensaje = 'Seleccione la especie';
                      valido = false;
                      break;
                    }
                    if (row.tcEspecie.especieId == null) {
                      mensaje = 'Seleccione la especie';
                      valido = false;
                      break;
                    }
                    if (row.tcProductoTransporte == null) {
                      mensaje = 'Seleccione el tipo de producto a la especie ' + row.tcEspecie.nombreCientifico;
                      valido = false;
                      break;
                    }
                    if (row.tcProductoTransporte.productoTransporteId == null) {
                      mensaje = 'Seleccione el tipo de producto a la especie ' + row.tcEspecie.nombreCientifico;
                      valido = false;
                      break;
                    }
                    if (row.maderable == null) {
                      mensaje = 'Seleccione si es maderable o no la especie ' + row.tcEspecie.nombreCientifico;
                      valido = false;
                      break;
                    }
                    if (row.volumenTransporte == null) {
                      valido = false;
                      mensaje = 'La especie ' + row.tcEspecie.nombreCientifico + ' tiene un volúmen no válido';
                      break;
                    }
                    row.volumenTransporte = parseFloat(row.volumenTransporte);
                    if (row.volumenTransporte <= 0) {
                      valido = false;
                      mensaje = 'La especie ' + row.tcEspecie.nombreCientifico + ' tiene un volúmen no válido';
                      break;
                    }
                    if (incluirCostos) {
                      if (row.volumenRodal == null) {
                        valido = false;
                        mensaje = 'Presione sobre el botón "Realizar cálculos" y verifique que el volumen de troza y leña se hayan calculado';
                        break;
                      }
                    }
                  }
                  if (!valido) {
                    toastr.error(mensaje);
                  }
                  return valido;
                }

                $scope.setEspecieAvaluo = function (item, especie) {
                  var especies = $scope.ttTarea.ttAvaluo.especies;
                  var veces = 0;
                  for (var i = 0; i < especies.length; i++) {
                    var row = especies[i];
                    if (row.tcEspecie != null) {
                      if (row.tcEspecie.especieId != null) {
                        if (row.tcEspecie.especieId == item.especieId) {
                          veces++;
                          if (veces > 1) {
                            break;
                          }
                        }
                      }
                    }
                  }
                  if (veces > 1) {
                    toastr.error('Esta especie ya fue seleccionada, no es posible continuar');
                    especie.tcEspecie.especieId = null;
                    return;
                  }
                  especie.tcEspecie.nombreCientifico = item.nombreCientifico;
                }

                $scope.setProductoTransporteAvaluo = function (item, especie) {
                  especie.tcProductoTransporte.productoTransporteDesc = item.productoTransporteDesc;
                  if (item.productoTransporteId == 1 || item.productoTransporteId == 2) {
                    especie.maderable = true;
                  } else {
                    especie.maderable = false;
                  }
                }

                $scope.setEsMaderable = function (item, especie) {
                  if (especie.tcProductoTransporte != null) {
                    if (especie.tcProductoTransporte.productoTransporteId != null) {
                      if ((especie.tcProductoTransporte.productoTransporteId == 1 || especie.tcProductoTransporte.productoTransporteId == 2) && !item.maderable) {
                        especie.maderable = null;
                        toastr.error('Para troza o madera aserrada es obligatorio indicar que es maderable');
                        return;
                      }
                    }
                  }
                }

                $scope.realizarCalculosAvaluo = function () {
                  if (!$scope.validarEspecies(false)) {
                    return;
                  }
                  var especies = $scope.ttTarea.ttAvaluo.especies;
                  var lista = [];
                  //buscar especie para obtener el precio a través del servicio, segun año y region
                  for (var j = 0; j < especies.length; j++) {
                    lista.push(especies[j].tcEspecie);
                  }
                  var fecha = new Date();
                  var anio = fecha.getFullYear();
                  var filtro = {
                    anio: anio,
                    tcRegion: $scope.ttTarea.ttAvaluo.tcSubregion.tcRegion,
                    especies: lista
                  }
                  //obtener el valor de la madera en pie
                  valortipomaderaService.listaCostoTipoMaderaAnio(filtro).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      var valor = data.valor;
                      var dataImpuesto = data.especies;
                      for (var i = 0; i < dataImpuesto.length; i++) {
                        for (var j = 0; j < valor.length; j++) {
                          if (valor[j].tcTipoMadera.tipoMaderaId == dataImpuesto[i].tcTipoMadera.tipoMaderaId) {
                            dataImpuesto[i].tcValorTipoMadera = valor[j];
                            break;
                          }
                        }
                      }
                      impuesto = null;
                      for (var i = 0; i < especies.length; i++) {
                        var item = especies[i];
                        for (var j = 0; j < dataImpuesto.length; j++) {
                          var row = dataImpuesto[j];
                          if (row.especieId == item.tcEspecie.especieId) {
                            impuesto = dataImpuesto[j].tcValorTipoMadera;
                            break;
                          }
                        }
                        if (impuesto == null) {
                          swal('La especie ' + item.tcEspecie.nombreCientifico + ' no tiene valor asignado, favor de indicar a soporte del sistema');
                          return;
                        }
                        valor = parseFloat(item.troza) * parseFloat(impuesto.troza);
                        item.valorTroza = parseFloat(valor).toFixed(2);
                        valor = parseFloat(item.lenia) * parseFloat(impuesto.lenia);
                        item.valorLenia = parseFloat(valor).toFixed(2);
                        valor = parseFloat(item.valorTroza) + parseFloat(item.valorLenia);
                        valor = parseFloat(valor).toFixed(2);
                        item.valorTotal = valor;
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                  for (var i = 0; i < especies.length; i++) {
                    var row = especies[i];
                    var valor = 0;
                    switch (row.tcProductoTransporte.productoTransporteId) {
                      case 1:
                        row.troza = row.volumenTransporte;
                        valor = (parseFloat(row.troza) * 0.2) / 0.8;
                        row.lenia = parseFloat(valor).toFixed(2);
                        valor = parseFloat(row.troza) + parseFloat(row.lenia);
                        row.volumenRodal = parseFloat(valor).toFixed(2);
                        break;
                      case 2:
                        valor = parseFloat(row.volumenTransporte) / 0.48;
                        row.troza = parseFloat(valor).toFixed(2);
                        valor = (parseFloat(row.troza) * 0.2) / 0.8;
                        row.lenia = parseFloat(valor).toFixed(2);
                        valor = parseFloat(row.troza) + parseFloat(row.lenia);
                        row.volumenRodal = parseFloat(valor).toFixed(2);
                        break;
                      case 3:
                        if (row.maderable) {
                          valor = (parseFloat(row.volumenTransporte) * 0.8) / 0.2;
                          row.troza = parseFloat(valor).toFixed(2);
                          row.lenia = parseFloat(row.volumenTransporte).toFixed(2);
                          valor = parseFloat(row.troza) + parseFloat(row.lenia);
                          row.volumenRodal = parseFloat(valor).toFixed(2);
                        } else {
                          row.troza = 0;
                          row.lenia = parseFloat(row.volumenTransporte).toFixed(2);
                          valor = parseFloat(row.troza) + parseFloat(row.lenia);
                          row.volumenRodal = parseFloat(valor).toFixed(2);
                        }
                        break;
                      case 4:
                        valor = parseFloat(row.volumenTransporte) * 2.5;
                        row.lenia = parseFloat(valor).toFixed(2);
                        if (row.maderable) {
                          valor = (parseFloat(row.lenia) * 0.8) / 0.2;
                          row.troza = parseFloat(valor).toFixed(2);
                          valor = parseFloat(row.troza) + parseFloat(row.lenia);
                          row.volumenRodal = parseFloat(valor).toFixed(2);
                        } else {
                          row.troza = 0;
                          valor = parseFloat(row.troza) + parseFloat(row.lenia);
                          row.volumenRodal = parseFloat(valor).toFixed(2);
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }

                $scope.actualizarInventarioAvaluo = function (isValid) {
                  if (!isValid) {
                    toastr.error('Debe rellenar todos los campos');
                    return;
                  }
                  switch ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.tcEscenarioAvaluo.escenarioAvaluoId) {
                    case 1:
                      var especies = $scope.ttTarea.ttAvaluo.especies;
                      var total = 0;
                      for (var i = 0; i < especies.length; i++) {
                        var row = especies[i];
                        total += parseFloat(row.volumenRodal);
                      }
                      total = parseFloat(total).toFixed(4);
                      $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.volumen = total;
                      break;
                    case 2:
                      break;
                    case 3:
                      if (!$scope.validarEspecies(false)) {
                        return;
                      }
                      var especies = $scope.ttTarea.ttAvaluo.especies;
                      var total = 0;
                      for (var i = 0; i < especies.length; i++) {
                        var row = especies[i];
                        total += parseFloat(row.volumenRodal);
                      }
                      total = parseFloat(total).toFixed(4);
                      $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.volumen = total;
                      var valorCarga = $scope.dataCargaMaderable.volumen;
                      if (parseFloat(valorCarga == 0)) {
                        toastr.error('La región a la que pertenece no tiene una carga maderable para el bosque seleccionado, favor de comunicarlo');
                        return;
                      }
                      var area = total / parseFloat(valorCarga);
                      $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.area = parseFloat(area).toFixed(4);
                      break;
                    default:
                      break;
                  }
                  var total = $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.volumen;
                  var costos = [];
                  var dataCostoEvitado = $scope.dataCostoEvitado;
                  for (var i = 0; i < dataCostoEvitado.length; i++) {
                    var row = dataCostoEvitado[i];
                    var totalPagar = parseFloat(total) * parseFloat(row.monto);
                    var item = {
                      tcTipoCosto: row.tcTipoCosto,
                      monto: row.monto,
                      total: parseFloat(totalPagar).toFixed(2)
                    }
                    costos.push(item);
                  }
                  $scope.ttTarea.ttAvaluo.costos = costos;
                  var monto = $scope.dataLesionEconomica.monto;
                  monto = parseFloat($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.area) * parseFloat(monto);
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.lesionEconomica = monto;
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.montoLesion = $scope.dataLesionEconomica.monto;
                  var ttTarea = angular.copy($scope.ttTarea);
                  tareaService.actualizarInventarioAvaluo(ttTarea).then(function (res) {
                    if (res.status == 1) {
                      $scope.tabActive.dos = true;
                      $scope.tabVisible.dos = true;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.confirmarCalculoAvaluo = function () {
                  $scope.tabActive.tres = true;
                  $scope.tabVisible.tres = true;
                }

                $scope.agregarAcompanianteAvaluo = function () {
                  if ($scope.dataAcompaniante == null) {
                    $scope.dataAcompaniante = [];
                  }
                  $scope.dataAcompaniante.push({});
                }

                $scope.agregarObservacionAvaluo = function () {
                  if ($scope.dataObservacion == null) {
                    $scope.dataObservacion = [];
                  }
                  $scope.dataObservacion.push({});
                }

                $scope.quitarObservacionAvaluo = function (item) {
                  $scope.dataObservacion.splice($scope.dataObservacion.indexOf(item), 1);
                }

                $scope.quitarAcompanianteAvaluo = function (item) {
                  $scope.dataAcompaniante.splice($scope.dataAcompaniante.indexOf(item), 1);
                }

                if ($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo == null) {
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo = new Date();
                } else {
                  $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo = new Date($scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo);
                }
                $scope.hayPeticion = false;
                $scope.dataSolicitud = [];
                if ($scope.ttTarea.ttAvaluo.detalle != null) {
                  $scope.dataSolicitud = JSON.parse($scope.ttTarea.ttAvaluo.detalle);
                  $scope.hayPeticion = true;
                }

                $scope.actualizarDictamenTecnicoAvaluo = function (isValid) {
                  if (!isValid) {
                    toastr.error('Todos los campos son obligatorios');
                    return;
                  }
                  var ttTarea = angular.copy($scope.ttTarea);
                  var json = {
                    acompaniantes: $scope.dataAcompaniante,
                    observaciones: $scope.dataObservacion
                  }
                  ttTarea.observaciones = JSON.stringify(json);
                  ttTarea.fechaFinalizacion = $scope.ttTarea.ttAvaluo.ttAvaluoInformacion.fechaAvaluo;
                  if ($scope.dataSolicitud.length > 0) {
                    ttTarea.ttAvaluo.detalle = JSON.stringify($scope.dataSolicitud);
                    delete ttTarea.ttAvaluo.ttAvaluoInformacion;
                    delete ttTarea.ttAvaluo.ttAvaluoMaderable;
                    delete ttTarea.ttAvaluo.especies;
                  } else {
                    delete ttTarea.ttAvaluo;
                  }
                  tareaService.actualizarTareaTecnicoAvaluo(ttTarea).then(function (res) {
                    if (res.status == 1) {
                      toastr.success(res.message);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.vistaPreviaDictamenAvaluo = function (finalizar) {
                  if ($scope.ttTarea.codigo == null) {
                    toastr.error('Debe indicar y guardar el número de dictamen para continuar');
                    return;
                  }
                  tareaService.getTareaDictamenTecnicoAvaluo($scope.ttTarea.tareaAvaluoId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      if (data.codigo == null) {
                        toastr.error('Debe indicar y guardar el número de dictamen para continuar');
                        return;
                      }
                      if (data.observaciones != null) {
                        try {
                          data.observaciones = JSON.parse(data.observaciones);
                        } catch (error) { }
                      } else {
                        data.observaciones = {};
                      }
                      data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                      utilsService.setEsPrevia(!finalizar);
                      utilsService.generarDictamenAvaluo(data, $scope.loginData.usuario, false, $scope.dataEmpresa, $scope.loginData.sigla);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.enviarDictamenTecnicoAvaluo = function () {
                  if ($scope.ttTarea.codigo == null) {
                    toastr.error('Debe indicar y guardar el número de dictamen para continuar');
                    return;
                  }
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro (a) de trasladar el expediente a la dirección subregional? \nEsta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        var data = angular.copy($scope.ttTarea);
                        delete data.ttAvaluo;
                        tareaService.finalizarTareaTecnicoAvaluo(data).then(function (res) {
                          if (res.status == 1) {
                            toastr.success(res.message);
                            $scope.vistaPreviaDictamenAvaluo(true);
                            $state.go('index.tarea.avaluo');
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.agregarEspecieAvaluo = function () {
                  $scope.ttTarea.ttAvaluo.especies.push({});
                }

                $scope.quitarEspecieAvaluo = function (item) {
                  $scope.ttTarea.ttAvaluo.especies.splice($scope.ttTarea.ttAvaluo.especies.indexOf(item), 1);
                }

              }]
          })
          .state('index.realizar.notiavaluo', {
            url: '/notiavaluo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notiavaluo.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaDictamenSubregionalAvaluo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.aprobado = 1;
                    $scope.titulo = 'Notificación sobre informe de Notas de Envío';
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttAvaluo.expediente
                    }

                    $scope.vistaPreviaNotificacionAvaluo = function (finalizar) {
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de dictamen para continuar');
                        return;
                      }
                      var ttTarea = $scope.dataTask;
                      var data = ttTarea.ttPadre;
                      if (data.observaciones != null) {
                        try {
                          data.observaciones = JSON.parse(data.observaciones);
                        } catch (error) { }
                      } else {
                        data.observaciones = {};
                      }
                      data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                      ttTarea.ttPadre = data;
                      utilsService.setEsPrevia(!finalizar);
                      utilsService.generarDictamenAvaluo(ttTarea, $scope.loginData.usuario, true, $scope.dataEmpresa, $scope.loginData.sigla);
                    }

                    $scope.notificarInformeAvaluo = function () {
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            data.fechaFinalizacion = new Date();
                            delete data.ttPadre;
                            tareaService.notificarDictamenSubregionalAvaluo(data).then(function (res) {
                              if (res.status == 1) {
                                toastr.success(res.message);
                                $scope.vistaPreviaNotificacionAvaluo(true);
                                $state.go('index.tarea.avaluo');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opconsumo', {
            url: '/opconsumo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.opconsumo.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaTecnicaConsumo($base64.decode($stateParams.tareaId));
                    }],
                  dataTipoBosque: ['tipobosqueService',
                    function (tipobosqueService) {
                      return tipobosqueService.listaActiva();
                    }],
                  dataTipoCostoConsumo: ['tipoCostoConsumoService',
                    function (tipoCostoConsumoService) {
                      return tipoCostoConsumoService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'dataTipoCostoConsumo',
                  'dataTarea', 'utilsService', 'tareaService', 'dataTipoBosque', 'especieService',
                  function ($scope, $state, toastr, dataTipoCostoConsumo,
                    dataTarea, utilsService, tareaService, dataTipoBosque, especieService) {

                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttConsumo.expediente
                    }

                    if ($scope.dataTask.ttConsumo.ttConsumoInformacion == null) {
                      $scope.dataTask.ttConsumo.ttConsumoInformacion = {};
                    } else {
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion != null) {
                        var fechaEvaluacion = new Date($scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion);
                        fechaEvaluacion = $scope.addDays(fechaEvaluacion, 1);
                        $scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion = fechaEvaluacion;
                      }
                    }

                    $scope.dataTipoCostoConsumo = dataTipoCostoConsumo.data;

                    var minDate = new Date($scope.dataTask.ttConsumo.fechaSolicitud);
                    var maxDate = new Date();

                    $scope.dateOptionsConsumo = {
                      formatYear: 'yy',
                      startingDay: 0,
                      format: 'dd/MM/yyyy',
                      formatDateTime: 'dd/MM/yyyy HH:mm',
                      showMeridian: false,
                      minDate: minDate,
                      maxDate: maxDate
                    };

                    $scope.tabActive = {
                      cero: false,
                      uno: false
                    }
                    $scope.tabVisible = {
                      cero: false,
                      uno: false
                    }
                    $scope.data = {
                      aprobar: true,
                      transportar: false
                    }
                    var tmp = dataTipoBosque.data;
                    var tmpBosque = [];
                    for (var i = 0; i < tmp.length; i++) {
                      var row = tmp[i];
                      if (row.tipoBosqueId != 4) {
                        tmpBosque.push(row);
                      }
                    }
                    $scope.dataTipoBosque = tmpBosque;
                    $scope.tipoBosqueId = 0;
                    if ($scope.dataTask.ttConsumo.ttConsumoInformacion.inventario == null) {
                      $scope.dataTask.ttConsumo.ttConsumoInformacion.inventario = [];
                      $scope.dataTask.ttConsumo.ttFincaConsumo.tcFinca.gtmX = null;
                      $scope.dataTask.ttConsumo.ttFincaConsumo.tcFinca.gtmY = null;
                      $scope.tabActive.cero = true;
                    } else {
                      try {
                        $scope.dataTask.ttConsumo.ttConsumoInformacion.inventario = JSON.parse($scope.dataTask.ttConsumo.ttConsumoInformacion.inventario);
                      } catch (error) {

                      }
                      $scope.tabActive.uno = true;
                      $scope.tabVisible.uno = true;
                    }
                    if ($scope.dataTask.observaciones == null) {
                      $scope.dataTask.observaciones = {
                        razones: [],
                        observaciones: [],
                        transportar: 0
                      };
                    } else {
                      try {
                        $scope.dataTask.observaciones = JSON.parse($scope.dataTask.observaciones);
                      } catch (error) {

                      }
                    }

                    if ($scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion != null) {
                      $scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion = new Date($scope.dataTask.ttConsumo.ttConsumoInformacion.fechaEvaluacion);
                    }

                    $scope.setTipoBosque = function (item, limpiar) {
                      if (limpiar == null) {
                        limpiar = true;
                      }
                      if ($scope.tipoBosqueId != item.tipoBosqueId) {
                        if (limpiar) {
                          $scope.dataTask.ttConsumo.ttConsumoInformacion.inventario = [];
                        }
                        especieService.getListaByBosque(item.tipoBosqueId).then(function (res) {
                          if (res.status == "OK") {
                            $scope.dataEspecie = res.data;
                            $scope.tipoBosqueId = item.tipoBosqueId;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    }

                    if ($scope.dataTask.ttConsumo.ttConsumoInformacion.tcTipoBosque != null) {
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.tcTipoBosque.tipoBosqueId != null) {
                        $scope.setTipoBosque($scope.dataTask.ttConsumo.ttConsumoInformacion.tcTipoBosque, false);
                      }
                    }

                    $scope.agregarEspecieConsumo = function () {
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.tcTipoBosque == null) {
                        toastr.error('Por favor elija el tipo de bosque');
                        return;
                      }
                      $scope.dataTask.ttConsumo.ttConsumoInformacion.inventario.push({});
                    }

                    $scope.quitarEspecieConsumo = function (item) {
                      $scope.dataTask.ttConsumo.ttConsumoInformacion.inventario.splice($scope.dataTask.ttConsumo.ttConsumoInformacion.inventario.indexOf(item), 1);
                    }

                    $scope.setEspecieConsumo = function (item, row) {
                      row.tcEspecie.nombreCientifico = item.nombreCientifico;
                      row.tcEspecie.ecuacion = item.ecuacion;
                      row.tcEspecie.especieDesc = item.especieDesc;
                      row.tcEspecie.formula = item.formula;
                    }

                    var valor = function (json, columna) {
                      for (var key in json) {
                        if (key == columna) return json[key];
                      }
                      return null;
                    }

                    $scope.setPorcentajeTroza = function (item) {
                      if (item.porcentajeTroza == null) {
                        return;
                      }
                      if (row.dap != null && row.altura != null) {
                        return;
                      }
                      if (item.porcentajeTroza < 0 || item.porcentaje > 100) {
                        toastr.error('Debe indicar un porcentaje valido');
                        item.porcentaje = null;
                        return;
                      }
                      var porcentaje = item.porcentajeTroza / 100;
                      var valor = item.volumen * porcentaje;
                      item.troza = parseFloat(valor).toFixed(4);
                      porcentaje = 1 - porcentaje;
                      valor = item.volumen * porcentaje;
                      item.lenia = parseFloat(valor).toFixed(4);
                    }

                    $scope.setVolumen = function (row) {
                      if (row.tcEspecie == null) {
                        toastr.error('Información inválida', 'Seleccione la especie');
                        row.dap = null;
                        row.altura = null;
                        return;
                      }
                      if (row.dap != null && row.altura != null) {
                        if (row.dap <= 0) {
                          toastr.error('Dato invalido', 'Debe indicar valor valido');
                          row.dap = null;
                          return;
                        }
                        if (row.altura <= 0) {
                          toastr.error('Dato invalido', 'Debe indicar valor valido');
                          row.altura = null;
                          return;
                        }
                        formula = row.tcEspecie.ecuacion;
                        var variables = JSON.parse(angular.copy(row.tcEspecie.formula))
                        for (j = 0; j < variables.length; j++) {
                          var variable = variables[j].variable;
                          value = valor(row, variable.toLowerCase());
                          for (var k = 0; k < 5; k++) { //reemplazar cinco veces la variable
                            formula = formula.replace(variable, value);
                          }
                        }
                        var volumen = new Function('return ' + formula)();
                        row.volumen = parseFloat(volumen).toFixed(4);
                        var areaBasal = new Function('return ((' + row.dap + '/100) * (' + row.dap + '/100)) * 0.7854')();
                        row.areaBasal = parseFloat(areaBasal).toFixed(4);
                        $scope.setPorcentajeTroza(row);
                      }
                    }

                    $scope.generarResumenEspecie = function (isValid) {
                      if (isValid) {
                        if ($scope.dataTask.ttConsumo.ttConsumoInformacion.inventario.length <= 0) {
                          toastr.error('Debe agregar al menos una especie');
                          return;
                        }
                        var ttTareaConsumo = angular.copy($scope.dataTask);
                        var inventario = ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario;
                        var especies = [];
                        for (var i = 0; i < inventario.length; i++) {
                          var row = inventario[i];
                          var encontrado = false;
                          for (var j = 0; j < especies.length; j++) {
                            var especie = especies[j];
                            if (especie.tcEspecie.especieId == row.tcEspecie.especieId) {
                              encontrado = true;
                              especie.troza = parseFloat(especie.troza) + parseFloat(row.troza);
                              especie.lenia = parseFloat(especie.lenia) + parseFloat(row.lenia);
                              especie.volumen = parseFloat(especie.volumen) + parseFloat(row.volumen);
                              especie.areaBasal = parseFloat(especie.areaBasal) + parseFloat(row.areaBasal);
                              especie.totalArboles = parseInt(especie.totalArboles) + 1;
                              break;
                            }
                          }
                          if (!encontrado) {
                            var especie = {
                              tcEspecie: row.tcEspecie,
                              troza: row.troza,
                              lenia: row.lenia,
                              volumen: row.volumen,
                              areaBasal: row.areaBasal,
                              totalArboles: 1
                            }
                            especies.push(especie);
                          }
                        }
                        var areaBasal = 0, volumen = 0, totalArboles = 0;
                        for (var i = 0; i < especies.length; i++) {
                          var row = especies[i];
                          volumen += parseFloat(row.volumen);
                          row.volumen = parseFloat(row.volumen).toFixed(4);
                          row.troza = parseFloat(row.troza).toFixed(4);
                          row.lenia = parseFloat(row.lenia).toFixed(4);
                          areaBasal += parseFloat(row.areaBasal);
                          row.areaBasal = parseFloat(row.areaBasal).toFixed(4);
                          totalArboles += parseInt(row.totalArboles);
                        }
                        volumen = parseFloat(volumen).toFixed(4);
                        if ($scope.dataTask.ttConsumo.tcPersonaConsumo.saldo < volumen) {
                          toastr.error('El propietario solo tiene ' + $scope.dataTask.ttConsumo.tcPersonaConsumo.saldo + ' (m³) de volumen autorizado, actualmente está agregando ' + volumen + '. No es posible continuar');
                          return;
                        }
                        var repoblacion = totalArboles * 10;
                        ttTareaConsumo.ttConsumo.ttConsumoInformacion.repoblacion = repoblacion;
                        ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario = JSON.stringify(ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario);
                        ttTareaConsumo.ttConsumo.especies = especies;
                        delete ttTareaConsumo.fundamento;
                        delete ttTareaConsumo.observaciones;
                        tareaService.actualizarTareaTecnicoConsumo(ttTareaConsumo).then(function (res) {
                          if (res.status == 1) {
                            $scope.tabVisible.uno = true;
                            $scope.tabActive.uno = true;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        toastr.error('Todos los campos requeridos, la fecha de evaluación no puede ser menor a la solicitud y mayor a hoy');
                      }
                    }

                    $scope.agregarRazonConsumo = function () {
                      $scope.dataTask.observaciones.razones.push({});
                    }

                    $scope.quitarRazonConsumo = function (item) {
                      $scope.dataTask.observaciones.razones.splice($scope.dataTask.observaciones.razones.indexOf(item), 1);
                    }

                    $scope.agregarObservacionConsumo = function () {
                      $scope.dataTask.observaciones.observaciones.push({});
                    }

                    $scope.quitarObservacionConsumo = function (item) {
                      $scope.dataTask.observaciones.observaciones.splice($scope.dataTask.observaciones.observaciones.indexOf(item), 1);
                    }

                    $scope.setAprobar = function () {
                      if ($scope.data.aprobar) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.setTransportar = function () {
                      if ($scope.data.transportar) {
                        $scope.dataTask.ttConsumo.ttConsumoInformacion.transportar = 1;
                      } else {
                        $scope.dataTask.ttConsumo.ttConsumoInformacion.transportar = 0;
                      }
                    }

                    var validarImpresion = function () {
                      if (!$scope.data.aprobar) {
                        if ($scope.dataTask.observaciones.razones.length <= 0) {
                          toastr.error('Debe indicar las razones de la no aprobación');
                          return false;
                        }
                      }
                      return true;
                    }

                    $scope.vistaPreviaDictamenConsumo = function (refresh) {
                      if (validarImpresion()) {
                        utilsService.setEsPrevia(true);
                        utilsService.generarInformeConsumo($scope.dataTask, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                    }

                    $scope.enviarDictamenTecnicoConsumo = function (isValid) {
                      var complemento = 'de finalizar la tarea';
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.transportar == 0) {
                        complemento = 'que el consumo no necesita transportar';
                      }
                      if (isValid) {
                        if (validarImpresion()) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro (a) " + complemento + " y continuar? \nEsta acción no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                var ttTareaConsumo = angular.copy($scope.dataTask);
                                delete ttTareaConsumo.ttConsumo.especies;
                                delete ttTareaConsumo.ttConsumo.beneficiados;
                                delete ttTareaConsumo.fundamento;
                                delete ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario;
                                ttTareaConsumo.observaciones = JSON.stringify(ttTareaConsumo.observaciones);
                                tareaService.finalizarTareaTecnicoConsumo(ttTareaConsumo).then(function (res) {
                                  if (res.status == 1) {
                                    tareaService.getTareaTecnicaConsumo(ttTareaConsumo.tareaConsumoId).then(function (res) {
                                      if (res.status == 1) {
                                        ttTareaConsumo = res.data;
                                        try {
                                          ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario = JSON.parse(ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario);
                                          ttTareaConsumo.observaciones = JSON.parse(ttTareaConsumo.observaciones);
                                        } catch (error) { }
                                        utilsService.generarInformeConsumo(ttTareaConsumo, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                        $state.go('index.tarea.consumo');
                                      } else {
                                        toastr.error(res.message);
                                      }
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            });
                        }
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.noticonsumo', {
            url: '/noticonsumo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.noticonsumo.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaResolucionConsumo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', '$timeout', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, gestionService, $timeout, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.aprobado = $scope.dataTask.ttPadre.aprobado;
                    $scope.titulo = 'Notificación sobre informe de Notas de Envío';
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttConsumo.expediente
                    }

                    $scope.data = {
                      aprobar: true
                    }

                    if ($scope.dataTask.observaciones == null) {
                      $scope.dataTask.observaciones = [];
                    }

                    if ($scope.dataTask.ttPadre.fundamento != null) {
                      try {
                        $scope.dataTask.ttPadre.fundamento = JSON.parse($scope.dataTask.ttPadre.fundamento);
                      } catch (error) { }
                    }

                    $scope.dataTask.ttConsumo.ttConsumoInformacion.diasVigencia = 30;

                    $scope.agregarRazonConsumo = function () {
                      $scope.dataTask.observaciones.push({});
                    }

                    $scope.quitarRazonConsumo = function (item) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(item), 1);
                    }

                    $scope.setAprobar = function () {
                      if ($scope.data.aprobar) {
                        $scope.dataTask.aprobado = 1;
                      } else {
                        $scope.dataTask.aprobado = 0;
                      }
                    }

                    $scope.vistaPreviaNotificacion = function (data, finalizar) {
                      var ttTarea = null;
                      if (data == null) {
                        ttTarea = $scope.dataTask;
                        ttTarea.fechaFinalizacion = new Date();
                        if (ttTarea.ttConsumo.ttConsumoInformacion.diasVigencias == null) {
                          ttTarea.ttConsumo.ttConsumoInformacion.diasVigencias = 0;
                        }
                      } else {
                        ttTarea = data;
                      }
                      var ttPadre = ttTarea.ttPadre;
                      if (ttPadre.observaciones != null) {
                        try {
                          ttPadre.observaciones = JSON.parse(ttPadre.observaciones);
                        } catch (error) { }
                      } else {
                        ttPadre.observaciones = {};
                      }
                      ttTarea.ttPadre = ttPadre;
                      var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia, false);
                      var strAnio = $scope.numeroALetras(anio, false);
                      utilsService.setEsPrevia(!finalizar);
                      utilsService.resolucionConsumoFamiliar(ttTarea, strDia, strAnio, $scope.loginData.usuario, true, $scope.dataEmpresa, $scope.loginData.sigla);
                      $timeout(function () {
                        utilsService.generarCedulaNotificacionConsumo(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                      }, 1000);
                    }

                    $scope.finalizarResolucionConsumo = function () {
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.diasVigencia == null) {
                        toastr.error('Indique los días de vigencia');
                        return;
                      }
                      if ($scope.dataTask.ttConsumo.ttConsumoInformacion.diasVigencia <= 0) {
                        toastr.error('Indique días válidos para la vigencia');
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar su resolución? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            delete data.ttPadre;
                            delete data.observaciones;
                            delete data.ttConsumo.especies;
                            delete data.ttConsumo.notificaciones;
                            tareaService.finalizarTareaResolucionConsumo(data).then(function (res) {
                              if (res.status == 1) {
                                tareaService.getTareaResolucionConsumo(data.tareaConsumoId).then(function (res) {
                                  if (res.status == 1) {
                                    $scope.vistaPreviaNotificacion(res.data, true);
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                                $state.go('index.tarea.consumo');
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                    }

                  }]
              }
            }
          })
          .state('index.realizar.provconsumo', {
            url: '/provconsumo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.provconsumo.tpl.html',
                resolve: {
                  dataTecnico: ['tareaService', 'authService', '$filter',
                    function (tareaService, authService, $filter) {
                      var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                      return tareaService.getUsuarioSubregionPerfil(loginData.subregionId, 7);
                    }],
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaSubregionalConsumo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'dataTecnico', 'authService',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, dataTecnico, authService) {

                    $scope.dataTecnico = dataTecnico.data;
                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttConsumo.expediente
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado = item;
                    }

                    $scope.vistaPreviaAsignacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if ($scope.dataTask.tcPersonaTraslado == null) {
                        toastr.error('Debe seleccionar a la técnico a asignar');
                        return;
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia, false);
                      var strAnio = $scope.numeroALetras(anio, false);
                      var data = $scope.dataTask;
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.generarProvidenciaConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.resolucionAdmisionConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (refresh) {
                        $state.go('index.tarea.consumo');
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              data.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              data.tcPersonaAsignada.foto = null;
                              data.tcPersonaTraslado.foto = null;
                              tareaService.providenciarConsumo(data).then(function (res) {
                                if (res.status == 1) {
                                  tareaService.getTareaSubregionalConsumo(data.tareaConsumoId).then(function (res) {
                                    if (res.status == 1) {
                                      try {
                                        var data = res.data;
                                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                        var strDia = $scope.numeroALetras(dia, false);
                                        var strAnio = $scope.numeroALetras(anio, false);
                                        utilsService.generarProvidenciaConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                        utilsService.resolucionAdmisionConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                      } catch (error) {

                                      }
                                      $state.go('index.tarea.consumo');
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notaconsumo', {
            url: '/:tareaId/notaconsumo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notaconsumo.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getSolicitudVentaConsumo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'ngDialog', 'tareaService', 'utilsService', 'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, ngDialog, tareaService, utilsService, dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttConsumo.expediente
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      var data = angular.copy($scope.dataTask);
                      if (data.fechaFinalizacion == null) {
                        data.fechaFinalizacion = new Date();
                        data.ttConsumoVenta.pago = data.ttConsumoVenta.notas * 15;
                      }
                      if (!refresh) {
                        utilsService.setEsPrevia(true);
                      }
                      utilsService.notificacionPagoVentaConsumo(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      if (refresh) {
                        $state.go('index.tarea.consumo');
                      }
                    }

                    $scope.enviarTareaSolicitud = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de realizar la notificación? \nEsta acción no se podrá reversar, es decir no se podrá regresar la tarea",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              data.fechaFinalizacion = new Date();
                              tareaService.notificarPagoVentaConsumo(data).then(function (res) {
                                if (res.status == 1) {
                                  tareaService.getSolicitudVentaConsumo(data.tareaConsumoId).then(function (res) {
                                    if (res.status == 1) {
                                      var data = res.data;
                                      utilsService.notificacionPagoVentaConsumo(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                      $state.go('index.tarea.consumo');
                                    } else {
                                      toastr.error(res.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.ventaconsumo', {
            url: '/ventaconsumo',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.ventaconsumo.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaSubregionalVentaConsumo($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTask = dataTarea.data;
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttConsumo.expediente
                    }

                    $scope.vistaPreviaResolucion = function () {
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      var dia = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date($scope.dataTask.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia, false);
                      var strAnio = $scope.numeroALetras(anio, false);
                      var data = $scope.dataTask;
                      utilsService.setEsPrevia(true);
                      utilsService.resolucionVentaConsumoFamiliar(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      var notas = data.ttConsumoVenta.correlativos;
                      for (var i = 0; i < notas.length; i++) {
                        var row = notas[i];
                        if (row.entregado == 1) {
                          var especies = row.especies;
                          var total = 0;
                          for (var j = 0; j < especies.length; j++) {
                            var especie = especies[j];
                            total += parseFloat(especie.total);
                          }
                          total = parseFloat(especie.total).toFixed(4);
                          var strPartesTotal = total.toString().split('.');
                          var entero = parseInt(strPartesTotal[0]);
                          var decimal = parseInt(strPartesTotal[1]);
                          var strEntero = $scope.numeroALetras(entero, true);
                          var strDecimal = $scope.numeroALetras(decimal, true);
                          var strTotal = strEntero;
                          if (decimal > 0) {
                            if (entero == 1) {
                              strTotal = 'uno';
                            }
                            strTotal = strTotal + ' punto ' + strDecimal;
                          }
                          strTotal = strTotal.toLowerCase() + ' metros cúbicos ';
                          strTotal = strTotal.substring(0, 1).toUpperCase() + strTotal.substring(1);
                          utilsService.generarNotaEnvioConsumo(data, row.numero, strTotal, $scope.dataEmpresa);
                        }
                      }
                    }

                    $scope.enviarTarea = function (isValid) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar es decir no hay regreso de tarea",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var data = angular.copy($scope.dataTask);
                              data.fechaProvidencia = new Date();
                              data.aprobado = 1;
                              tareaService.finalizarVentaConsumo(data).then(function (res) {
                                if (res.status == 1) {
                                  tareaService.getTareaSubregionalVentaConsumo(data.tareaConsumoId).then(function (res) {
                                    if (res.status == 1) {
                                      var data = res.data;
                                      var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                      var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                      var strDia = $scope.numeroALetras(dia, false);
                                      var strAnio = $scope.numeroALetras(anio, false);
                                      utilsService.resolucionVentaConsumoFamiliar(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                      var notas = data.ttConsumoVenta.correlativos;
                                      for (var i = 0; i < notas.length; i++) {
                                        var row = notas[i];
                                        if (row.entregado == 1) {
                                          var especies = row.especies;
                                          var total = 0;
                                          for (var j = 0; j < especies.length; j++) {
                                            var especie = especies[j];
                                            total += parseFloat(especie.total);
                                          }
                                          total = parseFloat(total).toFixed(4);
                                          var strPartesTotal = total.toString().split('.');
                                          var entero = parseInt(strPartesTotal[0]);
                                          var decimal = parseInt(strPartesTotal[1]);
                                          var strEntero = $scope.numeroALetras(entero, true);
                                          var strDecimal = $scope.numeroALetras(decimal, true);
                                          var strTotal = strEntero;
                                          if (decimal > 0) {
                                            if (entero == 1) {
                                              strTotal = 'uno';
                                            }
                                            strTotal = strTotal + ' punto ' + strDecimal;
                                          }
                                          strTotal = strTotal.toLowerCase() + ' metros cúbicos ';
                                          strTotal = strTotal.substring(0, 1).toUpperCase() + strTotal.substring(1);
                                          utilsService.generarNotaEnvioConsumo(data, row.numero, strTotal, $scope.dataEmpresa);
                                        }
                                      }
                                      $state.go('index.tarea.consumo');
                                    } else {
                                      toastr.error(res.message);
                                      $state.go('index.tarea.consumo');
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                    $state.go('index.tarea.consumo');
                                  });
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.enmiendainforme', {
            url: '/enmiendainforme',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.enmiendainforme.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', '$base64',
                    function (tareaService, $stateParams, $base64) {
                      return tareaService.getEnmiendaInforme($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', 'ngDialog', 'tareaService', 'utilsService', '$filter', 'dataTarea',
                  function ($scope, $state, toastr, gestionService, ngDialog, tareaService, utilsService, $filter, dataTarea) {
                    $scope.dataTask = dataTarea.data;
                    var ttPadre = $scope.dataTask.ttPadre;
                    var datos = JSON.parse(ttPadre.observaciones);
                    $scope.dataTask.observaciones = datos.razon;
                    $scope.titulo = 'Notificación de enmiendas sobre informe de notas';
                    $scope.codigo = 'Oficio';
                    $scope.esProvidencia = false;

                    $scope.agregarFundamento = function () {
                      $scope.dataTask.observaciones.push({ descripcion: null });
                    }

                    $scope.borrarDetalle = function (item) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(item), 1);
                    }

                    $scope.vistaPreviaNotificacion = function (fin) {
                      if (fin == null) {
                        fin = false;
                      }
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      } else {
                        if ($scope.dataTask.codigo.length < 1) {
                          toastr.error('Debe indicar el número de oficio');
                          return;
                        }
                      }
                      if ($scope.dataTask.fechaFinalizacion == null) {
                        $scope.dataTask.fechaFinalizacion = new Date();
                      }
                      if (!fin) {
                        utilsService.setEsPrevia(true);
                      }
                      if ($scope.esProvidencia) {
                        var data = angular.copy($scope.dataTask);
                        data.ttInformeVenta.ttVentaExento.ttExento = data.ttExento;
                        var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                        var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        data.ttExento.tcSubregion.tcSubregional = {
                          personaId: $scope.loginData.personaId,
                          personaDesc: $scope.loginData.usuarioDesc
                        }
                        utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      } else {
                        utilsService.enmiendaSubregionalExento($scope.dataTask, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (fin) {
                        $state.go('index.tarea.list');
                      }
                    }

                    $scope.enviarTarea = function (isAmendment, tmpData) {
                      if ($scope.dataTask.codigo == null) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      }
                      if ($scope.dataTask.codigo.length <= 0) {
                        toastr.error('Debe indicar el número de oficio');
                        return;
                      }
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar, no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var ttTareaExento = angular.copy($scope.dataTask);
                            delete ttTareaExento.ttExento;
                            ttTareaExento.fechaFinalizacion = new Date();
                            delete ttTareaExento.observaciones;
                            ttTareaExento.esEnmienda = -1;
                            if ($scope.esProvidencia) {
                              tareaService.regresarTareaInformeTecnico(ttTareaExento).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              ttTareaExento.esEnmienda = 1;
                              ttTareaExento.observaciones = JSON.stringify(ttTareaExento.observaciones);
                              tareaService.notificarDictamenInformeVenta(ttTareaExento).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.providenciarTecnico = function () {
                      swal({
                        title: "Confirmación",
                        text: "La tarea cambiará ¿Está seguro (a) de continuar? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.esProvidencia = true;
                            $scope.titulo = 'Providenciar';
                            $scope.codigo = 'Providencia';
                            $scope.dataTask.codigo = null;
                            $scope.dataTask.esEnmienda = -1;
                            if ($scope.dataTecnico == null) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                    }

                  }]
              }
            }
          })
          .state('index.realizar.opupdexento', {
            url: '/opupdexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.opupdexento.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaSolicitudActualizacionExento($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'tareaService', 'utilsService',
                  'dataTarea', '$filter',
                  function ($scope, $state, toastr, tareaService, utilsService,
                    dataTarea, $filter) {

                    if (dataTarea.status != 1) {
                      toastr.error(dataTarea.message);
                      $state.go('index.tarea.exentolist');
                    }

                    $scope.activo = {
                      cero: true,
                      uno: false
                    }

                    $scope.data = {
                      approved: true
                    }

                    $scope.dataTask = dataTarea.data;
                    $scope.ttActualizacionExento = $scope.dataTask.ttActualizacionExento;
                    $scope.thActualizacionExento = $scope.ttActualizacionExento.thActualizacionExento;
                    var ventas = $scope.thActualizacionExento.ventas;
                    if (ventas == null) {
                      ventas = [];
                    }
                    for (let i = 0; i < ventas.length; i++) {
                      var element = ventas[i];
                      element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                    }
                    $scope.dataTask.observaciones = [];
                    $scope.dataTask.aprobado = 1;
                    $scope.data.approved = true;
                    $scope.tipoActualizacionDesc = 'Actualización del RNF';
                    switch ($scope.ttActualizacionExento.tipoActualizacionId) {
                      case 1:
                        $scope.tipoActualizacionDesc = 'Actualización del RNF';
                        break;
                      case 2:
                        $scope.tipoActualizacionDesc = 'Actualización de volumen';
                        break;
                    }
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }
                    $scope.dataTask.aprobado = 1;

                    $scope.agregarObservacion = function () {
                      $scope.dataTask.observaciones.push(
                        {
                          descripcion: null
                        }
                      );
                    }

                    $scope.quitarObservacion = function (item) {
                      $scope.dataTask.observaciones.splice($scope.dataTask.observaciones.indexOf(item), 1);
                    }

                    $scope.porRodal = true;

                    $scope.setVolumenHectarea = function (rodal, especie) {
                      especie.volumen = parseFloat(rodal.area) * parseFloat(especie.volumenHectaria);
                      especie.volumen = parseFloat(especie.volumen).toFixed(4);
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(4);
                    }

                    $scope.setVolumenRodal = function (rodal, especie) {
                      if (parseFloat(rodal.area) == 0) {
                        toastr.error('El area del rodal no puede ser cero');
                        especie.volumen = 0;
                        return;
                      }
                      especie.volumenHectaria = parseFloat(especie.volumen) / parseFloat(rodal.area);
                      especie.volumenHectaria = parseFloat(especie.volumenHectaria).toFixed(4);
                      var total = 0;
                      for (var i = 0; i < rodal.especies.length; i++) {
                        total += parseFloat(rodal.especies[i].volumen);
                      }
                      rodal.volumen = parseFloat(total).toFixed(4);
                    }

                    $scope.guardarVolumenActualizacion = function ( isValid ) {
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar los datos de volumen? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var thActualizacionExento = angular.copy($scope.thActualizacionExento);
                              delete thActualizacionExento.ventas;
                              tareaService.tecnicoVolumenSolicitudActualizacion(thActualizacionExento).then(function (res) {
                                if (res.status == 1) {
                                  $scope.activo.uno = true;
                                  $scope.thActualizacionExento.saldoDespues = res.data;
                                  toastr.success(res.message);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      } else {
                        toastr.error('Debe rellenar todos los campos');
                      }
                    }

                    $scope.setAprobarActualizacion = function () {
                      if ($scope.data.approved) {
                        $scope.dataTask.aprobado = 1;
                        $scope.dataTask.observaciones = [];
                      } else {
                        $scope.dataTask.aprobado = 0;
                        $scope.dataTask.observaciones = [];
                      }
                    }

                    $scope.vistaPreviaDictamen = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      $scope.dataTask.fechaFinalizacion = new Date();
                      var data = angular.copy($scope.dataTask);
                      data.ttActualizacionExento = angular.copy($scope.ttActualizacionExento);
                      data.ttActualizacionExento.thActualizacionExento = angular.copy($scope.thActualizacionExento);
                      if (data.aprobado == 0) {
                        if (data.observaciones.length <= 0) {
                          toastr.error('Debe indicar al menos debe indicar una razón para no aprobar la solicitud');
                          return;
                        }
                      }
                      utilsService.setEsPrevia(!refresh);
                      utilsService.dictamenTecnicoActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.dictamenTecnicoActualizacionExento = function (isValid) {                      
                      var data = angular.copy($scope.dataTask);
                      if (isValid) {
                        if (data.aprobado == 0) {
                          if (data.observaciones.length <= 0) {
                            toastr.error('Debe indicar al menos debe indicar una razón para no aprobar la solicitud');
                            return;
                          }
                        }
                      }
                      if (isValid) {
                        swal({
                          title: "Confirmación",
                          text: "¿Está seguro (a) de guardar y asignar la tarea? \nEsta acción no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                          function (isConfirm) {
                            if (isConfirm) {
                              var ttExento = data.ttExento;
                              data.ttExento = {};
                              data.ttExento = {
                                exentoId: ttExento.exentoId,
                                tcTipoGestion: ttExento.tcTipoGestion,
                                estadoId: ttExento.estadoId,
                                tcPersonaCrea: ttExento.tcPersonaCrea,
                                fechaVencimiento: ttExento.fechaVencimiento,
                              };
                              data.fechaFinalizacion = new Date();
                              delete data.ttActualizacionExento;
                              data.observaciones = JSON.stringify(data.observaciones);
                              tareaService.dictamenTecnicoActualizacionExento(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaDictamen(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          });
                      }
                    }

                  }]
              }
            }
          })
          .state('index.realizar.notiupdexento', {
            url: '/notiupdexento',
            views: {
              '': {
                templateUrl: 'app/tarea/tarea.notiupdexento.tpl.html',
                resolve: {
                  dataTarea: ['tareaService', '$stateParams', 'authService', '$base64',
                    function (tareaService, $stateParams, authService, $base64) {
                      return tareaService.getTareaSolicitudActualizacionExento($base64.decode($stateParams.tareaId));
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'ngDialog', 'tareaService', 'utilsService',
                  'dataTarea', '$filter', 'authService',
                  function ($scope, $state, toastr, ngDialog, tareaService, utilsService,
                    dataTarea, $filter, authService) {

                    $scope.dataTecnico = null;
                    $scope.esProvidencia = false;

                    $scope.codigo = 'Notificación';

                    $scope.dataTask = dataTarea.data;
                    var ventas = $scope.dataTask.ttActualizacionExento.thActualizacionExento.ventas;
                    if (ventas == null) {
                      ventas = [];
                    }
                    for (let i = 0; i < ventas.length; i++) {
                      var element = ventas[i];
                      element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                    }
                    $scope.dataTask.observaciones = {
                      observaciones: [],
                      razones: []
                    }
                    if ($scope.dataTask.ttPadre.aprobado == 0) {
                      $scope.dataTask.ttPadre.observaciones = JSON.parse($scope.dataTask.ttPadre.observaciones);
                      $scope.dataTask.observaciones.razones = $scope.dataTask.ttPadre.observaciones;
                    }
                    $scope.dataTask.aprobado = $scope.dataTask.ttPadre.aprobado;
                    $scope.titulo = 'Notificación sobre actualización del exento';
                    $scope.dataTask.ttGestion = {
                      expediente: $scope.dataTask.ttExento.expediente
                    }

                    $scope.agregarObservacion = function () {
                      $scope.dataTask.observaciones.observaciones.push({ observacion: null });
                    }

                    $scope.quitarObservacion = function (detalle) {
                      $scope.dataTask.observaciones.observaciones.splice($scope.dataTask.observaciones.observaciones.indexOf(detalle), 1);
                    }

                    $scope.generarNotificacionActualizacion = function (data, refresh) {
                      var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                      var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                      var strDia = $scope.numeroALetras(dia);
                      var strAnio = $scope.numeroALetras(anio);
                      if ($scope.esProvidencia) {
                        utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);                        
                      } else {
                        utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                      }
                      if (refresh) {
                        $state.go('index.tarea.exentolist');
                      }
                    }

                    $scope.vistaPreviaNotificacion = function (refresh) {
                      if (refresh == null) {
                        refresh = false;
                      }
                      if (refresh) {
                        tareaService.getTareaSolicitudActualizacionExento( $scope.dataTask.tareaExentoId ).then( function ( res ) {
                          if ( res.status == 1 ) {
                            var data = res.data;
                            var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                            if (ventas == null) {
                              ventas = [];
                            }
                            for (let i = 0; i < ventas.length; i++) {
                              var element = ventas[i];
                              element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                            }
                            data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                            if (data.aprobado != 2) {
                              data.observaciones = JSON.parse(data.observaciones);
                            }
                            $scope.generarNotificacionActualizacion(data, refresh);
                          } else {
                            toastr.error( res.message );
                          }
                        }, function ( error ) {
                          toastr.error( error );
                        });
                      } else {
                        if ($scope.dataTask.fechaFinalizacion == null) {
                          $scope.dataTask.fechaFinalizacion = new Date();
                        }
                        var data = angular.copy($scope.dataTask);
                        utilsService.setEsPrevia(true);
                        $scope.generarNotificacionActualizacion(data, refresh);
                      }
                    }

                    $scope.notificarResolucion = function () {
                      swal({
                        title: "¿Está seguro (a) de guardar y finalizar la tarea? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        text: "",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            var data = angular.copy($scope.dataTask);
                            data.fechaFinalizacion = new Date();
                            delete data.ttPadre;
                            if ($scope.esProvidencia) {
                              delete data.ttActualizacionExento;
                              delete data.observaciones;
                              data.aprobado = 2;
                              tareaService.regresarActualizacionExentoTecnico(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            } else {
                              data.observaciones = JSON.stringify(data.observaciones);
                              data.fechaFinalizacion = new Date();
                              delete data.ttActualizacionExento;
                              tareaService.notificarActualizacionExento(data).then(function (res) {
                                if (res.status == 1) {
                                  toastr.success(res.message);
                                  $scope.vistaPreviaNotificacion(true);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.providenciarTecnico = function () {
                      swal({
                        title: "Confirmación",
                        text: "La tarea cambiará ¿Está seguro (a) de continuar? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            $scope.esProvidencia = true;
                            $scope.codigo = 'Providencia';
                            if ($scope.dataTecnico == null) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataTecnico = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          }
                        });
                    }

                    $scope.setTecnico = function (item) {
                      $scope.dataTask.tcPersonaTraslado.personaDesc = item.personaDesc;
                    }

                  }]
              }
            }
          })
      }
    ]
  );
