angular.module( 'app.404', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        //////////////
        // Login //
        //////////////
        .state( '404', {

          url: '/404',

          templateUrl: 'app/404/404.tpl.html',
          controller: ['$scope', '$state', '$timeout', 'toastr', '$base64', 'ngDialog',
            function ($scope, $state, $timeout, toastr, $base64, ngDialog) {

           }] //end controller
        })
    }
  ]
);
