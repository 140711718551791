angular.module('app.empresa', [
  'ui.router',
  'toastr',
  'app.empresa.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.empresa', {
          abstract: true,
          url: 'empresa',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Empresa';
            }]
        })
        .state('index.empresa.list', {
          url: '',
          templateUrl: 'app/empresa/empresa.list.tpl.html',
          resolve: {
            dataEmpresa: ['empresaService',
              function ( empresaService ){
                return empresaService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataEmpresa',
            function (  $scope,   $state,   dataEmpresa) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'empresaDesc', name: 'Empresa' },
                { name: 'estado', displayName: "Estado", field: 'estado', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataEmpresa.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ empresaId: row.empresaId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.empresa.add', {
          url: '/add',
          templateUrl: 'app/empresa/empresa.add.tpl.html',
          resolve: {
            dataEstado: ['empresaService',
              function ( empresaService ){
                return empresaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'empresaService', 'dataEstado',
            function (  $scope,   $state,   toastr,   empresaService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              if ($scope.current.stateToGo == null) {
                $scope.state = '^.list';
              } else {
                $scope.state = $scope.current.stateToGo;
              }
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  if ($scope.validar_nit($scope.data.nit)) {
                    empresaService.add( $scope.data ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        toastr.success( res.message );
                        if ($scope.current.stateToGo == null) {
                          $state.go( '^.list' );
                        } else {
                          $scope.current.empresa = $scope.data;
                          $state.go( $scope.current.stateToGo );
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  } else {
                    toastr.error('El nit no es valido');
                  }
                }
              }
            } //end function
          ]
        })
        .state('index.empresa.edit', {
          url: '/:empresaId/edit',
          templateUrl: 'app/empresa/empresa.add.tpl.html',
          resolve: {
            dataEmpresa: ['empresaService', '$stateParams',
              function ( empresaService, $stateParams ){
                return empresaService.get( $stateParams.empresaId );
              }],
            dataEstado: ['empresaService',
              function ( empresaService ){
                return empresaService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'empresaService', 'dataEmpresa', 'dataEstado',
            function (  $scope,   $state,   toastr,   empresaService,   dataEmpresa, dataEstado) {
              if ($scope.current.stateToGo == null) {
                $scope.state = '^.list';
              } else {
                $scope.state = $scope.current.stateToGo;
              }
              $scope.data = dataEmpresa.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  empresaService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      if ($scope.current.stateToGo == null) {
                        $state.go( '^.list' );
                      } else {
                        $state.go( $scope.current.stateToGo );
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
