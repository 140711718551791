angular.module('app.semilla.service', [

])

  .factory('semillaService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'semilla/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + 'semilla/marcar/enmienda', data).
          then(function (res) {
            return res.data;
          });
      },
      getDataByGestion: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "semilla/gestion/" + gestionId + "/semilla").then(function (res) {
          return res.data;
        });
      }
    }

  }]);
