angular.module('app.sistemarepoblacion', [
  'ui.router',
  'toastr',
  'app.sistemarepoblacion.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.sistemarepoblacion', {
          abstract: true,
          url: 'sistemarepoblacion',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Criterio de protección';
            }]
        })
        .state('index.sistemarepoblacion.list', {
          url: '',
          templateUrl: 'app/sistemarepoblacion/sistemarepoblacion.list.tpl.html',
          resolve: {
            dataSistemaRepoblacion: ['sistemarepoblacionService',
              function ( sistemarepoblacionService ){
                return sistemarepoblacionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataSistemaRepoblacion',
            function (  $scope,   $state,   dataSistemaRepoblacion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'sistemaRepoblacionDesc', name: 'sistemaRepoblacionDesc', displayName: 'Criterio de protección' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataSistemaRepoblacion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ sistemaRepoblacionId: row.sistemaRepoblacionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.sistemarepoblacion.add', {
          url: '/add',
          templateUrl: 'app/sistemarepoblacion/sistemarepoblacion.add.tpl.html',
          resolve: {
            dataEstado: ['sistemarepoblacionService',
              function ( sistemarepoblacionService ){
                return sistemarepoblacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'sistemarepoblacionService', 'dataEstado',
            function (  $scope,   $state,   toastr,   sistemarepoblacionService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  sistemarepoblacionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.sistemarepoblacion.edit', {
          url: '/:sistemaRepoblacionId/edit',
          templateUrl: 'app/sistemarepoblacion/sistemarepoblacion.add.tpl.html',
          resolve: {
            dataSistemaRepoblacion: ['sistemarepoblacionService', '$stateParams',
              function ( sistemarepoblacionService, $stateParams ){
                return sistemarepoblacionService.get( $stateParams.sistemaRepoblacionId );
              }],
            dataEstado: ['sistemarepoblacionService',
              function ( sistemarepoblacionService ){
                return sistemarepoblacionService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'sistemarepoblacionService', 'dataSistemaRepoblacion', 'dataEstado',
            function (  $scope,   $state,   toastr,   sistemarepoblacionService,   dataSistemaRepoblacion, dataEstado) {
              $scope.data = dataSistemaRepoblacion.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  sistemarepoblacionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
