angular.module('app.tarea.service', [

])

  .factory('tareaService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'tarea/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      listadoExento: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'tarea/listado/exento', filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/marcar/enmienda', data).
          then(function (res) {
            return res.data;
          });
      },
      listaTask: function () {
        return $http.get(appSettings.urlServiceBase + 'tarea/task/lista').then(function (res) {
          return res.data;
        });
      },
      asignarTareaSubregional: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/asignar/subregional', data).then(function (res) {
          return res.data;
        });
      },
      recibirTarea: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/recibir', data).then(function (res) {
          return res.data;
        });
      },
      recibirTareaExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/recibir', data).then(function (res) {
          return res.data;
        });
      },
      programarTareaExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/programar', data).then(function (res) {
          return res.data;
        });
      },
      finalizarTarea: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/finalizar', data).
          then(function (res) {
            return res.data;
          });
      },
      admitirExpediente: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/admitir', data).then(function (res) {
          return res.data;
        });
      },
      getDataAdmision: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/admision').then(function (res) {
          return res.data;
        });
      },
      getUsuarioSubregionPerfil: function (subregionId, perfilId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + subregionId + '/' + perfilId + '/lista/asignar').then(function (res) {
          return res.data;
        });
      },
      getDataProvidencia: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/providencia/traslado').then(function (res) {
          return res.data;
        });
      },
      asignarProvidencia: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/asignar/providencia', data).then(function (res) {
          return res.data;
        });
      },
      getDataOpinionJuridica: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/opinion/juridica').then(function (res) {
          return res.data;
        });
      },
      setOpinionJuridica: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/juridica', data).then(function (res) {
          return res.data;
        });
      },
      setOpinionJuridicaSuspension: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/suspension/juridica', data).then(function (res) {
          return res.data;
        });
      },
      getDataOpinionTecnica: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/opinion/tecnica').then(function (res) {
          return res.data;
        });
      },
      setOpinionTecnica: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/tecnica', data).then(function (res) {
          return res.data;
        });
      },
      getDataNotificacionEnmienda: function (tareaId, tareaPadreId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/padre/' + tareaPadreId + '/enmienda').then(function (res) {
          return res.data;
        });
      },
      getOficioEnmienda: function (enmiendaId) {
        return $http.get(appSettings.urlServiceBase + "tarea/data/" + enmiendaId + "/enmienda").then(function (res) {
          return res.data;
        });
      },
      setNotificacionEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/enmienda', data).then(function (res) {
          return res.data;
        });
      },
      setEnmiendaSiguienteTurno: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/enmienda/turno', data).then(function (res) {
          return res.data;
        });
      },
      getDataDictamenSubregional: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/opinion/subregional').then(function (res) {
          return res.data;
        });
      },
      setDictamenSubregional: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/subregional', data).then(function (res) {
          return res.data;
        });
      },
      getDataOpinionRegional: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/opinion/regional').then(function (res) {
          return res.data;
        });
      },
      setOpinionRegional: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/regional', data).then(function (res) {
          return res.data;
        });
      },
      actualizarTareaTecnico: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/actualizar/tecnico', data).then(function (res) {
          return res.data;
        });
      },
      getDataResolucion: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/resolucion').then(function (res) {
          return res.data;
        });
      },
      notificarResolucion: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/notificar/resolucion', data).then(function (res) {
          return res.data;
        });
      },
      notificarLicencia: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/notificar/licencia', data).then(function (res) {
          return res.data;
        });
      },
      getDataPoaSubregional: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/poa/subregional').then(function (res) {
          return res.data;
        });
      },
      setAprobarPoaSubregional: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/aprobar/poa', data).then(function (res) {
          return res.data;
        });
      },
      getCantidadNotas: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/notas').then(function (res) {
          return res.data;
        });
      },
      getGestionHistorial: function (tareaId, gestionId, subregionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/historial/' + tareaId + '/' + gestionId + '/' + subregionId + '/lista').then(function (res) {
          return res.data;
        });
      },
      getProvidenciaActiva: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/providencia/' + gestionId + '/lista').then(function (res) {
          return res.data;
        });
      },
      getEnmiendaActiva: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/enmienda/lista/activa').then(function (res) {
          return res.data;
        });
      },
      get: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/data/' + tareaId).then(function (res) {
          return res.data;
        });
      },
      getConPadre: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/data/' + tareaId + '/padre').then(function (res) {
          return res.data;
        });
      },
      setOpinionMonitoreo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/monitoreo', data).then(function (res) {
          return res.data;
        });
      },
      setOpinionMonitoreoPoaSiguiente: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/opinion/monitoreo/siguiente', data).then(function (res) {
          return res.data;
        });
      },
      getGestionDictamen: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/dictamen').then(function (res) {
          return res.data;
        });
      },
      recibirEnmiendas: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/recibir/enmienda', data).then(function (res) {
          return res.data;
        });
      },
      recibirPoa: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/recibir/poa', data).then(function (res) {
          return res.data;
        });
      },
      getDictamenTecnico: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/task').then(function (res) {
          return res.data;
        });
      },
      getDataPoa: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/poa/' + tareaId + '/data').then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaEnmienda: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/asignar/providencia/enmienda', data).then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaRatificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/asignar/providencia/ratificacion', data).then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaSuspension: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/asignar/providencia/suspension', data).then(function (res) {
          return res.data;
        });
      },
      agregarDocumento: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/agregar/documento', data).then(function (res) {
          return res.data;
        });
      },
      solicitarAval: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/solicitar/aval', data).then(function (res) {
          return res.data;
        });
      },
      solicitarAvalPoaSiguiente: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/aval/poa/siguiente', data).then(function (res) {
          return res.data;
        });
      },
      getTareaAvalConPadre: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/aval/poa').then(function (res) {
          return res.data;
        });
      },
      getTareaRequisitoPoa: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/requisito/poa').then(function (res) {
          return res.data;
        });
      },
      solicitarRequisitosPoa: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/requisito/poa', data).then(function (res) {
          return res.data;
        });
      },
      recibirInforme: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/recibir/informe', data).then(function (res) {
          return res.data;
        });
      },
      getProvidenciaInforme: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/informe').then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaInforme: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/asignar/providencia/informe', data).then(function (res) {
          return res.data;
        });
      },
      agregarSolicitudSuspension: function (data) {
        return $http.post(appSettings.urlServiceBase + "tarea/suspension/agregar", data).then(function (res) {
          return res.data;
        });
      },
      getDatosSuspension: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/suspension/desde/tarea/' + tareaId + '/datos').then(function (res) {
          return res.data;
        });
      },
      getAnexoSuspension: function (anexoSuspensionId) {
        $http.get(appSettings.urlServiceBase + 'tarea/file/suspension/' + anexoSuspensionId, { responseType: 'arraybuffer' })
          .then(function (data) {
            var file = new Blob([data.data], { type: data.headers(["content-type"]) });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          });
      },
      getDatosSuspensionDesdeHijo: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/suspension/desde/hijo/' + tareaId + '/datos').then(function (res) {
          return res.data;
        });
      },
      setDecisionSuspensionGestion: function (data) {
        return $http.post(appSettings.urlServiceBase + "tarea/suspender/gestion", data).then(function (res) {
          return res.data;
        });
      },
      reasignarTarea: function (data) {
        return $http.put(appSettings.urlServiceBase + "tarea/reasignar", data).then(function (res) {
          return res.data;
        });
      },
      cancelarEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + "tarea/cancelar/enmienda", data).then(function (res) {
          return res.data;
        });
      },
      recibirSolicitudPoa: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/recibir/solicitud/poa', data).then(function (res) {
          return res.data;
        });
      },
      getDatosTareaSolicitudPoa: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/solicitud/poa').then(function (res) {
          return res.data;
        });
      },
      habilitarTarea: function (tareaId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/' + tareaId + '/habilitar').then(function (res) {
          return res.data;
        });
      },
      cambiarAvalToDictamen: function (tareaId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/' + tareaId + '/cambiar/aval/dictamen').then(function (res) {
          return res.data;
        });
      },
      habilitarCambioArea: function (tareaId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/' + tareaId + '/habilitar/cambio/area').then(function (res) {
          return res.data;
        });
      },
      anularRecepcionEnmiendas: function (tareaId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/' + tareaId + '/anular/recepcion/enmiendas').then(function (res) {
          return res.data;
        });
      },
      trasladarTareas: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/trasladar', data).then(function (res) {
          return res.data;
        });
      },
      recibirExento: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/recibir/exento', data).then(function (res) {
          return res.data;
        });
      },
      getTareaInforme: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/informe/aval').then(function (res) {
          return res.data;
        });
      },
      getDatosTareaExento: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/exento').then(function (res) {
          return res.data;
        });
      },
      getDataExentoTareaFinalizada: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/exento/finalizado').then(function (res) {
          return res.data;
        });
      },
      anularSolicitudCargaExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/anular/solicitud/carga/exento', data).then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/asignar/providencia/exento', data).then(function (res) {
          return res.data;
        });
      },
      actualizarVolumenExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/actualizar/volumen/exento', data).then(function (res) {
          return res.data;
        });
      },
      finalizarInformeCargaInicial: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/dictamen/carga/exento', data).then(function (res) {
          return res.data;
        });
      },
      notificarCargaInicialExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/notificar/cargainicial/exento', data).then(function (res) {
          return res.data;
        });
      },
      getAutorizacionExento: function (exentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/obtener/exento/' + exentoId).then(function (res) {
          return res.data;
        });
      },
      getTareaVentaExento: function (tareaId, tipo) {
        if (tipo == null) {
          tipo = 1;
        }
        switch (tipo) {
          case 1:
            return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/tecnico/obtener/venta').then(function (res) {
              return res.data;
            });
            break;
          case 2:
            return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/venta').then(function (res) {
              return res.data;
            });
            break;
          case 3:
            return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/subregional/obtener/venta').then(function (res) {
              return res.data;
            });
            break;
          case 4:
            return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/subregional/resolucion/venta').then(function (res) {
              return res.data;
            });
            break;
        }
      },
      asignarProvidenciaVentaNota: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/asignar/providencia/venta', data).then(function (res) {
          return res.data;
        });
      },
      finalizarInformeVentaNota: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/dictamen/venta', data).then(function (res) {
          return res.data;
        });
      },
      buscarExpediente: function (expediente) {
        return $http.get(appSettings.urlServiceBase + "tarea/seguimiento/" + expediente + "/tiempo").then(function (res) {
          return res.data;
        });
      },
      recibirSolicitudVenta: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/recibir/solicitud/venta', data).then(function (res) {
          return res.data;
        });
      },
      notificarDictamenVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/dictamen/subregional/venta', data).then(function (res) {
          return res.data;
        });
      },
      obtenerNotificacionVenta: function (ventaExentoId) {
        return $http.get(appSettings.urlServiceBase + "tarea/dictamen/subregional/venta/" + ventaExentoId).then(function (res) {
          return res.data;
        });
      },
      aceptarDocumentoPago: function (data) {
        return $http.post(appSettings.urlServiceBase + 'tarea/aceptar/documento/pago', data).then(function (res) {
          return res.data;
        });
      },
      procesoResolucionVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/resolucion/venta', data).then(function (res) {
          return res.data;
        });
      },
      recibirInformeExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/recibir/informe/exento', data).
          then(function (res) {
            return res.data;
          });
      },
      obtenerTareaProvidenciaInformeVenta: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + "tarea/" + tareaId + '/obtener/informe/venta').then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaInformeVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/providencia/informe/venta', data).then(function (res) {
          return res.data;
        });
      },
      getTareaInformeVenta: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + "tarea/" + tareaId + '/tecnico/informe/venta').then(function (res) {
          return res.data;
        });
      },
      finalizarDictamenInformeVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/dictamen/informe/venta', data).then(function (res) {
          return res.data;
        });
      },
      solicitarEnmiendaInformeVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/enmienda/informe/venta', data).then(function (res) {
          return res.data;
        });
      },
      getTareaInformeVentaNotificacion: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + "tarea/" + tareaId + '/notificar/informe/venta').then(function (res) {
          return res.data;
        });
      },
      getEnmiendaInforme: function (tareaExentoId) {
        return $http.get(appSettings.urlServiceBase + "tarea/" + tareaExentoId + '/enmienda/informe/venta').then(function (res) {
          return res.data;
        });
      },
      getNotificacionInformeVenta: function (informeVentaId) {
        return $http.get(appSettings.urlServiceBase + "tarea/informe/" + informeVentaId + '/notificar/informe/venta').then(function (res) {
          return res.data;
        });
      },
      notificarDictamenInformeVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/notificar/informe/venta', data).then(function (res) {
          return res.data;
        });
      },
      getHistorialExento: function (exentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/historial/exento/' + exentoId + '/lista').then(function (res) {
          return res.data;
        });
      },
      getRavalidacionNotas: function (exentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/exento/' + exentoId + '/revalidar/notas').then(function (res) {
          return res.data;
        });
      },
      buscarPorExpediente: function (expediente) {
        return $http.get(appSettings.urlServiceBase + "tarea/buscar/expediente/" + expediente).then(function (res) {
          return res.data;
        });
      },
      buscarPorRnf: function (codigo) {
        return $http.get(appSettings.urlServiceBase + "tarea/lista/exento/" + codigo).then(function (res) {
          return res.data;
        });
      },
      getTareaActualizacionExento: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/obtener/exento').then(function (res) {
          return res.data;
        });
      },
      anularSolicitudVenta: function (data) {
        return $http.put(appSettings.urlServiceBase + 'exento/tarea/anular/solicitud/venta', data).
          then(function (res) {
            return res.data;
          });
      },
      getReporteLicenciaByFecha: function (nivel, regionId, subregionId, fechaIni, fechaFin) {
        var params = {
          nivel: nivel,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/licencia', config).then(function (res) {
          return res.data;
        });
      },
      getReporteTarea: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/tarea', config).then(function (res) {
          return res.data;
        });
      },
      getReporteTareaExento: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/exento', config).then(function (res) {
          return res.data;
        });
      },
      getTareaArchivoAbandono: function (tareaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/' + tareaId + '/archivo/abandono').then(function (res) {
          return res.data;
        });
      },
      cambiarEspecie: function (data, exentoId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/' + exentoId + '/cambiar/especie', data).
          then(function (res) {
            return res.data;
          });
      },
      getOficioAvaluo: function (avaluoId) {
        $http.get(appSettings.urlServiceBase + 'tarea/avaluo/' + avaluoId + '/oficio', { responseType: 'arraybuffer' })
          .then(function (data) {
            var file = new Blob([data.data], { type: data.headers(["content-type"]) });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          });
      },
      getMisTareasAvaluo: function (fechaIni, fechaFin, estadoId) {
        var params = {
          estadoId: estadoId,
          fechaIni: fechaIni,
          fechaFin: fechaFin
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/avaluo/por/persona', config).then(function (res) {
          return res.data;
        });
      },
      getHistorialAvaluo: function (avaluoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/historial/avaluo/' + avaluoId).then(function (res) {
          return res.data;
        });
      },
      getTareaProvidenciaAvaluo: function (tareaAvaluoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/avaluo/' + tareaAvaluoId + '/para/providencia').then(function (res) {
          return res.data;
        });
      },
      asignarProvidenciaAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/asignar/providencia', data).then(function (res) {
          return res.data;
        });
      },
      getTareaDictamenTecnicoAvaluo: function (tareaAvaluoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/avaluo/' + tareaAvaluoId + '/para/tecnico').then(function (res) {
          return res.data;
        });
      },
      actualizarInformacionAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/actualizar/informacion', data).then(function (res) {
          return res.data;
        });
      },
      actualizarInventarioAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/actualizar/inventario', data).then(function (res) {
          return res.data;
        });
      },
      actualizarTareaTecnicoAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/actualizar/dictamen', data).then(function (res) {
          return res.data;
        });
      },
      finalizarTareaTecnicoAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/finalizar/dictamen', data).then(function (res) {
          return res.data;
        });
      },
      getTareaDictamenSubregionalAvaluo: function (tareaAvaluoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/avaluo/' + tareaAvaluoId + '/para/subregional').then(function (res) {
          return res.data;
        });
      },
      notificarDictamenSubregionalAvaluo: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/avaluo/finalizar/subregional', data).then(function (res) {
          return res.data;
        });
      },
      regresarTareaExento: function (exentoId, opcionId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/' + exentoId + '/opcion/' + opcionId + '/regresar').then(function (res) {
          return res.data;
        });
      },
      regresarTareaInformeTecnico: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/regresar/tecnico/informe/notas', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      regresarTareaVentaTecnico: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/regresar/tecnico/dictamen/venta', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      reasignarTareaExento: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/reasignar', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      anularTareaDuplicada: function (tareaExentoId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/' + tareaExentoId + '/anular/duplicado').then(function (res) {
          return res.data;
        });
      },
      getGestionParaNotificacion: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/resolucion').then(function (res) {
          return res.data;
        });
      },
      recibirRequisitosTurno: function (gestionId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/gestion/' + gestionId + '/recibir/requisitos/turno').then(function (res) {
          return res.data;
        });
      },
      setLicencia: function (ttTarea) {
        return $http.put(appSettings.urlServiceBase + 'tarea/finalizar/licencia', ttTarea).then(function (res) {
          return res.data;
        });
      },
      getMisTareasConsumo: function (fechaIni, fechaFin, estadoId) {
        var params = {
          estadoId: estadoId,
          fechaIni: fechaIni,
          fechaFin: fechaFin
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/por/persona', config).then(function (res) {
          return res.data;
        });
      },
      getTareaTecnicaConsumo: function (tareaConsumoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/' + tareaConsumoId + '/tecnico').then(function (res) {
          return res.data;
        });
      },
      actualizarTareaTecnicoConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/actualizar/tecnico', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      finalizarTareaTecnicoConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/finalizar/tecnico', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      getHistorialTareasConsumo: function (consumoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/' + consumoId + '/historial/tareas').then(function (res) {
          return res.data;
        });
      },
      getTareaSubregionalConsumo: function (tareaConsumoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/' + tareaConsumoId + '/subregional').then(function (res) {
          return res.data;
        });
      },
      providenciarConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/finalizar/subregional', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      getTareaResolucionConsumo: function (tareaConsumoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/' + tareaConsumoId + '/resolucion').then(function (res) {
          return res.data;
        });
      },
      finalizarTareaResolucionConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/finalizar/resolucion', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      getResolucionConsumo: function (consumoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/resolucion/' + consumoId).then(function (res) {
          return res.data;
        });
      },
      getSolicitudVentaConsumo: function (consumoVentaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/venta/' + consumoVentaId + '/solicitud').then(function (res) {
          return res.data;
        });
      },
      notificarPagoVentaConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/venta/notificar/pago', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      getTareaSubregionalVentaConsumo: function (consumoVentaId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/consumo/venta/' + consumoVentaId + '/resolucion').then(function (res) {
          return res.data;
        });
      },
      finalizarVentaConsumo: function (ttTareaConsumo) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/venta/resolucion', ttTareaConsumo).then(function (res) {
          return res.data;
        });
      },
      asignarseTareaConsumo: function (tareaConsumoId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/consumo/' + tareaConsumoId + '/autoasignar/tarea').then(function (res) {
          return res.data;
        });
      },
      getReporteTiempo: function (anio, mes) {
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/tiempo/licencia/' + mes + '/' + anio).then(function (res) {
          return res.data;
        });
      },
      getReporteTiempoTareas: function (anio, mes) {
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/tiempo/tareas/' + mes + '/' + anio).then(function (res) {
          return res.data;
        });
      },
      generarReporteTiempo: function (anio, mes) {
        return $http.patch(appSettings.urlServiceBase + 'tarea/generar/tiempo/licencias/' + mes + '/' + anio).then(function (res) {
          return res.data;
        });
      },
      generarReporteTiempoTareas: function (anio, mes) {
        return $http.patch(appSettings.urlServiceBase + 'tarea/generar/tiempo/tareas/' + mes + '/' + anio).then(function (res) {
          return res.data;
        });
      },
      modificaTareaExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/modificar', data).then(function (res) {
          return res.data;
        });
      },
      getPoligonosExento: function (tareaExentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/exento/' + tareaExentoId + '/poligonos').
          then(function (res) {
            return res.data;
          });
      },
      enmiendaTecnicoCargaInicialExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/enmienda/tecnico/carga/exento', data).then(function (res) {
          return res.data;
        });
      },
      providenciarCargaInicial: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/providenciar/carga', data).then(function (res) {
          return res.data;
        });
      },
      habilitarSubirRequisitos: function (tareaId) {
        return $http.put(appSettings.urlServiceBase + 'tarea/' + tareaId + '/activar/requisitos/turno').then(function (res) {
          return res.data;
        });
      },
      getResolucionVentaExento: function (exentoId, ventaExentoId) {
        var params = {
          exentoId: exentoId,
          ventaExentoId: ventaExentoId
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/exento/resolucion/venta', config).
          then(function (res) {
            return res.data;
          });
      },
      getTareaSolicitudActualizacionExento: function (tareaExentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/exento/' + tareaExentoId + '/solicitud/actualizacion').
          then(function (res) {
            return res.data;
          });
      },
      resolverSolicitudActualizacionExento: function (ttTarea) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizacion/solicitud', ttTarea).then(function (res) {
          return res.data;
        });
      },
      tecnicoVolumenSolicitudActualizacion: function (thActualizacionExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizacion/volumen', thActualizacionExento).then(function (res) {
          return res.data;
        });
      },
      dictamenTecnicoActualizacionExento: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizacion/dictamen/tecnico', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      regresarActualizacionExentoTecnico: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizar/regresar/tecnico', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      notificarActualizacionExento: function (ttTareaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/actualizar/notificar', ttTareaExento).then(function (res) {
          return res.data;
        });
      },
      anularSolicitudActualizacionExento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/anular/solicitud/actualizar', data).then(function (res) {
          return res.data;
        });
      },
      resolucionSolicitudActualizacionExento: function (actualizacionExentoId) {
        return $http.get(appSettings.urlServiceBase + 'tarea/exento/actualizacion/' + actualizacionExentoId + '/resolucion').
          then(function (res) {
            return res.data;
          });
      },
      getArchivoCertificado: function (actualizacionExentoId) {
        $http.get(appSettings.urlServiceBase + 'tarea/exento/actualizacion/' + actualizacionExentoId + '/certificado', { responseType: 'arraybuffer' })
          .then(function (data) {
            var file = new Blob([data.data], { type: data.headers(["content-type"]) });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          });
      },
      detalleTiempoTareasExentoFinalizadas: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/exento/detalle', config).then(function (res) {
          return res.data;
        });
      },
      tiempoCargaInicialExento: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/exento/tiempo/carga', config).then(function (res) {
          return res.data;
        });
      },
      tiempoVentaNotaExento: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/exento/tiempo/venta', config).then(function (res) {
          return res.data;
        });
      },
      tiempoInformeNotaExento: function (tipoReporteId, regionId, subregionId, fechaIni, fechaFin, expediente) {
        var params = {
          tipoReporteId: tipoReporteId,
          regionId: regionId,
          subregionId: subregionId,
          fechaIni: fechaIni,
          fechaFin: fechaFin,
          expediente: expediente
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'tarea/reporte/exento/tiempo/informe', config).then(function (res) {
          return res.data;
        });
      },
      cambiarCantidadNotaExentos: function (ttVentaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/exento/venta/cambiar/notas', ttVentaExento).then(function (res) {
          return res.data;
        });
      },
      solicitarEnmiendaRequisitosLicencia: function (ttVentaExento) {
        return $http.put(appSettings.urlServiceBase + 'tarea/licencia/enmienda/requisitos', ttVentaExento).then(function (res) {
          return res.data;
        });
      }
    }

  }]);
