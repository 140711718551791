angular.module('app.finca.service', [

])

.factory('fincaService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'finca/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'finca/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'finca/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    addFromExento: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'finca/add/from/exento', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'finca/' + data.fincaId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( fincaId ) {
      return $http.get( appSettings.urlServiceBase + 'finca/' + fincaId).then(function ( res ) {
         return res.data;
       });
    },
    getDetalle : function( fincaId ) {
      return $http.get( appSettings.urlServiceBase + 'finca/' + fincaId + '/detalle').then(function ( res ) {
         return res.data;
       });
    },
    addFincaPersona: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'finca/add/persona', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getListaFincaPersona : function( personaId ) {
      return $http.get( appSettings.urlServiceBase + 'finca/persona/' + personaId + '/lista').then(function ( res ) {
         return res.data;
       });
    },
    getListaFincaMunicipio : function( municipioId ) {
      return $http.get( appSettings.urlServiceBase + 'finca/municipio/' + municipioId + '/lista').then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
