angular.module('app.gestion.service', [

])

  .factory('gestionService', ['$http', '$q', 'appSettings', function ($http, $q, appSettings) {

    return {
      uploadFile: function (file, type) {
        var fd = new FormData();
        fd.append('file', file, file.name);
        var deferred = $q.defer();
        return $http.post(appSettings.urlServiceBase + 'upload', fd, {
          transformRequest: angular.identity,
          headers: { 'Content-Type': undefined }
        }).then(function (res) {
          return res.data;
        });
      },
      ngUploadFile: function (file, type) {
        Upload.upload({
          url: 'upload.php',
          method: 'POST',
          file: file,
          sendFieldsAs: 'form',
          fields: {
            producto: [
              { productoId: $scope.data.productoId, productoDesc: $scope.data.productoDesc }
            ]
          }
        }).then(function (resp) {
          if (resp.data.status == 'success') {
            $scope.dataImg.urlImg = appSettings.urlBaseImg + resp.data.imagenUrl;
            $scope.data.imagenUrl = resp.data.imagenUrl;
            toastr.success(resp.data.value);
          } else {
            toastr.error(resp.data.value)
          }
        }, function (resp) {
          toastr.error('Error status: ' + resp.status);
        }, function (evt) {
          var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
          $scope.avance = progressPercentage;
        });
      },
      list: function () {
        return $http.get(appSettings.urlServiceBase + 'gestion/lista').then(function (res) {
          return res.data;
        });
      },
      listado: function (filtro) {
        return $http.post(appSettings.urlServiceBase + 'gestion/listado', filtro).then(function (res) {
          return res.data;
        });
      },
      listaActiva: function () {
        return $http.get(appSettings.urlServiceBase + 'gestion/lista/activa').then(function (res) {
          return res.data;
        });
      },
      listaPorDepartamento: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'gestion/lista/' + gestionId + '/departamento').then(function (res) {
          return res.data;
        });
      },
      listStatus: function () {
        return $http.get(appSettings.urlServiceBase + 'estado/lista/activa').then(function (res) {
          return res.data;
        });
      },
      add: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/add', data).
          then(function (res) {
            return res.data;
          });
      },
      edit: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/solicitud', data).
          then(function (res) {
            return res.data;
          });
      },
      nuevo: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/nuevo', data).
          then(function (res) {
            return res.data;
          });
      },
      setAreas: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/areas', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarAreas: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/actualizar/areas', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarArea: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/' + data.gestionId + '/actualizar/area', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarCronograma: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/actualizar/cronograma', data).
          then(function (res) {
            return res.data;
          });
      },
      get: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'gestion/' + gestionId).then(function (res) {
          return res.data;
        });
      },
      getParaEditar: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'gestion/' + gestionId + '/edit').then(function (res) {
          return res.data;
        });
      },
      aceptarSolicitud: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/aceptar', data).
          then(function (res) {
            return res.data;
          });
      },
      enviarDocumento: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/enviar/solicitud', data).
          then(function (res) {
            return res.data;
          });
      },
      rechazarSolicitud: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/rechazar', data).
          then(function (res) {
            return res.data;
          });
      },
      anularSolicitud: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/anular', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarPlanSolicitud: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/agregar/plan', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarPersona: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/persona', data).
          then(function (res) {
            return res.data;
          });
      },
      setTipoPropietario: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/tipo/propietario', data).
          then(function (res) {
            return res.data;
          });
      },
      encontrarGestion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/encontrar', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarPersona: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/quitar/persona', data).
          then(function (res) {
            return res.data;
          });
      },
      setRepresentanteLegal: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/representante/legal', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarNotificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/notificacion', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarNotificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/quitar/notificacion', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarDatosNotificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/notificacion', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarDocumentoFinca: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/finca/quitar/documento', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarFincas: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/fincas', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarFincaGestion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/finca/gestion', data).
          then(function (res) {
            return res.data;
          });
      },
      setSubregion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/subregion', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarPropietarioFinca: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/propietario/finca', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarPropietarioFinca: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/quitar/propietario/finca', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarFinca: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/quitar/finca', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarBiofisica: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/biofisica', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarUsoFinca: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/usofinca', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarUsoFinca: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/quitar/usofinca', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarCriterioProteccion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/criterioproteccion', data).
          then(function (res) {
            return res.data;
          });
      },
      quitarCriterioProteccion: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/quitar/criterioproteccion', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarAnexo: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/anexo', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarAnexoInforme: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/anexo/informe', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosResumen: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/resumen', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarPropuestaResumen: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/resumen', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosInventario: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/inventario', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarRegeneracion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/regeneracion', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarInventario: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/inventario', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarEstadistico: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/estadistico', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosLatizal: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/latizal', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosBrinzal: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/brinzal', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarClaseDesarrollo: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/clasedesarrollo', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarRodal: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/rodal', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarInventarioNoMaderable: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/inventario/nomaderable', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosDistribucion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/distribucion', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarDatosPlanificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/planificacion', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarTroza: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/actualizar/troza', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarPlanificacionNoMaderable: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/planificacion/nomaderable', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarAreaPlanificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/planificacion/area', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarRepoblacionPlanificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/planificacion/repoblacion', data).
          then(function (res) {
            return res.data;
          });
      },
      actualizarRecuperacionPlanificacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/planificacion/recuperacion', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarSistemaRepoblacion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/sistemarepoblacion', data).
          then(function (res) {
            return res.data;
          });
      },
      agregarMedidaProteccion: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/proteccion', data).
          then(function (res) {
            return res.data;
          });
      },
      getListaAnexo: function () {
        return $http.get(appSettings.urlServiceBase + 'gestion/anexo/lista').then(function (res) {
          return res.data;
        });
      },
      getListaAnexoRequisito: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/requisito/anexo/' + gestionId + '/lista').then(function (res) {
          return res.data;
        });
      },
      getListaRequisitoFiduciaria: function () {
        return $http.get(appSettings.urlServiceBase + 'gestion/requisito/fiduciaria/lista').then(function (res) {
          return res.data;
        });
      },
      getAnexo: function ( anexoGestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/file/" + anexoGestionId,
          {responseType:'arraybuffer'}
        ).then(function (res) {
          return res;
        });
      },
      getAnexoSuspension: function ( anexoGestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/file/suspension/" + anexoGestionId,
          {responseType:'arraybuffer'}
        ).then(function (res) {
          return res;
        });
      },
      getGestionAnexo: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/anexo/" + gestionId + "/lista").then(function (res) {
          return res.data;
        });
      },
      getGestionAnexoSuspension: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/anexo/suspension/" + gestionId + "/lista").then(function (res) {
          return res.data;
        });
      },
      recibirSolicitud: function (data) {
        return $http.put(appSettings.urlServiceBase + 'solicitud/recibir/expediente', data).then(function (res) {
          return res.data;
        });
      },
      getDatosRecepcion: function ( solicitudId ) {
        return $http.get(appSettings.urlServiceBase + "solicitud/" + solicitudId + "/recepcion").then(function (res) {
          return res.data;
        });
      },
      getDatosRecepcionByGestion: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "solicitud/gestion/" + gestionId + "/recepcion").then(function (res) {
          return res.data;
        });
      },
      getGestionData: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/data").then(function (res) {
          return res.data;
        });
      },
      getGestionParaTarea: function ( tareaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/tarea/" + tareaId).then(function (res) {
          return res.data;
        });
      },
      getGestionParaTareaConPadre: function ( tareaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/tarea/" + tareaId + "/padre").then(function (res) {
          return res.data;
        });
      },
      getResolucion: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/resolucion").then(function (res) {
          return res.data;
        });
      },
      moverPanelSiguiente: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/mover/panel/siguiente', data).
          then(function (res) {
            return res.data;
          });
      },
      finalizarElaboracion: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/finalizar/elaboracion', data).
          then(function (res) {
            return res.data;
          }
        );
      },
      listaEstado: function () {
        var data = [];
        data.push({estadoId : -1, estadoDesc : 'Todos'});
        data.push({estadoId : 0, estadoDesc : 'Inactivo'});
        data.push({estadoId : 1, estadoDesc : 'Activo'});
        var res = {
          status : 'OK',
          message : 'Datos cargados',
          data : data
        };
        return res;
      },
      listadoTifoliada: function (){
        var datos = []
            datos.push({estrato: 1, especie: 1, perametro:'N', diez: 1, veinte: 2, treinta: 3, cuarenta: 4, cincuenta: 5, sesenta: 6, setenta: 7, ochenta: 8, noventa: 9, total:50});
            datos.push({estrato: 1, especie: 1, perametro:'G', diez: 1, veinte: 2, treinta: 3, cuarenta: 4, cincuenta: 5, sesenta: 6, setenta: 7, ochenta: 8, noventa: 9, total:50});
          return datos;
          },
      getListadoPoa: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/subregion/poa', data).
          then(function (res) {
            return res.data;
          });
      },
      getRequisitoPoa: function (gestionId) {
        return $http.get(appSettings.urlServiceBase + 'gestion/requisito/poa/lista/' + gestionId).
          then(function (res) {
            return res.data;
          });
      },
      getRequisitoSolicitudPoa: function (solicitudPoaId) {
        return $http.get(appSettings.urlServiceBase + 'gestion/solicitud/poa/' + solicitudPoaId + '/requisito/solicitud/poa/lista').
          then(function (res) {
            return res.data;
          });
      },
      recibirPoa: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/recibir/poa', data).
          then(function (res) {
            return res.data;
          });
      },
      getListaRequisito: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/requisito/" + gestionId + "/lista").then(function (res) {
          return res.data;
        });
      },
      actualizarRodalInventario: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/inventario/planificacion', data).
          then(function (res) {
            return res.data;
          });
      },
      getAnexoGestion : function( anexoGestionId ) {
        $http.get(appSettings.urlServiceBase + 'gestion/file/' + anexoGestionId, {responseType: 'arraybuffer'})
         .then(function (data) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         });
      },
      getEnmiendaHistorial : function( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/enmienda/" + gestionId + "/lista").then(function (res) {
          return res.data;
        });
      },
      getAvanceGestion : function( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/avance").then(function (res) {
          return res.data;
        });
      },
      getListaEnmienda : function( subregionId, estadoId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/subregion/" + subregionId + "/" + estadoId + "/enmiendas").then(function (res) {
          return res.data;
        });
      },
      getListaResolucion : function( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/subregion/resolucion", filtro).then(function (res) {
          return res.data;
        });
      },
      marcarEntregaResolucion: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/resolucion/entregar', data).
          then(function (res) {
            return res.data;
          });
      },
      getGestionParaPoaTarea: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/tarea/poa").then(function (res) {
          return res.data;
        });
      },
      getGestionParaPoa: function ( tareaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/tarea/" + tareaId + "/poa").then(function (res) {
          return res.data;
        });
      },
      getGestionParaLicencia: function ( tareaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/tarea/" + tareaId + "/licencia").then(function (res) {
          return res.data;
        });
      },
      getListaSeguimiento: function ( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/seguimiento/lista", filtro).then(function (res) {
          return res.data;
        });
      },
      getPorInvolucrado: function ( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/por/involucrado", filtro).then(function (res) {
          return res.data;
        });
      },
      buscarGestionParaInforme: function ( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/encontrar/para/informe", filtro).then(function (res) {
          return res.data;
        });
      },
      getListaSuspension: function ( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/suspension/lista", filtro).then(function (res) {
          return res.data;
        });
      },
      crearInforme: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/informe/crear", item).then(function (res) {
          return res.data;
        });
      },
      obtenerDatosInforme: function ( informeGestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/informe/" + informeGestionId + "/datos").then(function (res) {
          return res.data;
        });
      },
      actualizarNotas: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/informe/actualizar/notas", item).then(function (res) {
          return res.data;
        });
      },
      actualizarNotaVerificada: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/informe/notas/verificacion", item).then(function (res) {
          return res.data;
        });
      },
      actualizarInforme: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/informe/descripcion/actualizar", item).then(function (res) {
          return res.data;
        });
      },
      actualizarEspeciesInforme: function ( data ) {
        return $http.post(appSettings.urlServiceBase + "gestion/informe/especies/actualizar", data).then(function (res) {
          return res.data;
        });
      },
      getAnexoInforme : function( anexoInformeId ) {
        $http.get(appSettings.urlServiceBase + 'gestion/file/informe/' + anexoInformeId, {responseType: 'arraybuffer'})
         .then(function (data) {
           var file = new Blob([data.data], {type: data.headers(["content-type"])});
           var fileURL = URL.createObjectURL(file);
           window.open(fileURL);
         });
      },
      quitarAnexoInforme : function( anexoInformeId ) {
        return $http.delete(appSettings.urlServiceBase + "gestion/quitar/anexo/informe/" + anexoInformeId).then(function (res) {
          return res.data;
        });
      },
      enviarInforme: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/enviar/informe", item).then(function (res) {
          return res.data;
        });
      },
      getListaInformeSubregion : function( personaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/lista/informe/" + personaId).then(function (res) {
          return res.data;
        });
      },
      getUltimoPoaGestion : function( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/ultimo/poa").then(function (res) {
          return res.data;
        });
      },
      actualizarAniosGestion: function ( data ) {
        return $http.put(appSettings.urlServiceBase + "gestion/cambiar/anio/gestion", data).then(function (res) {
          return res.data;
        });
      },
      buscarPorExpediente : function( expediente ) {
        return $http.get(appSettings.urlServiceBase + "gestion/buscar/expediente/" + expediente).then(function (res) {
          return res.data;
        });
      },
      buscarSolicitudPorExpediente : function( expediente ) {
        return $http.get(appSettings.urlServiceBase + "gestion/seguimiento/expediente/" + expediente).then(function (res) {
          return res.data;
        });
      },
      solicitarPoa: function ( item ) {
        return $http.post(appSettings.urlServiceBase + "gestion/solicitud/poa", item).then(function (res) {
          return res.data;
        });
      },
      obtenerSolicitudPoa : function( solicitudPoaId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/solicitud/poa/" + solicitudPoaId).then(function (res) {
          return res.data;
        });
      },
      listaSolicitudPoa : function( licencia ) {
        return $http.get(appSettings.urlServiceBase + "gestion/licencia/" + licencia + "/solicitud/poa/lista").then(function (res) {
          return res.data;
        });
      },
      listaSolicitudPoaPorGestion: function( gestionId ) {
        return $http.get(appSettings.urlServiceBase + "gestion/" + gestionId + "/solicitud/poa/lista").then(function (res) {
          return res.data;
        });
      },
      buscarGestionPorInvolucrado: function( filtro ) {
        return $http.post(appSettings.urlServiceBase + "gestion/lista/por/involucrado", filtro).then(function (res) {
          return res.data;
        });
      },
      agregarObservacionesEnmienda: function (data) {
        return $http.put(appSettings.urlServiceBase + 'gestion/enmienda/' + data.enmiendaId + '/observacion', data).
          then(function (res) {
            return res.data;
          });
      },
      setQueja: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/agregar/queja', data).
          then(function (res) {
            return res.data;
          });
      },
      getListaQueja: function (data) {
        return $http.post(appSettings.urlServiceBase + 'gestion/queja/lista', data).
          then(function (res) {
            return res.data;
          });
      },
      getRequisitoParaLicencia: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/' + gestionId + '/requisto/para/licencia').
          then(function (res) {
            return res.data;
          });
      },
      enviarRequisitosTurno: function ( gestionId ) {
        return $http.put(appSettings.urlServiceBase + 'gestion/' + gestionId + '/enviar/requisito/licencia').
          then(function (res) {
            return res.data;
          });
      },
      buscarLicenciaParaDevolverNota : function( licencia ) {
        var params = {
          licencia: licencia
        }
        var config = {
          params: params
        }
        return $http.get( appSettings.urlServiceBase + 'gestion/nota/buscar/licencia',  config).then(function ( res ) {
           return res.data;
         });
      },
      enviarSolicitudCambioNotas: function ( ttCambioNota ) {
        return $http.post(appSettings.urlServiceBase + 'gestion/solicitar/cambio/nota', ttCambioNota).
          then(function (res) {
            return res.data;
          });
      },
      getListaSolicitudCambioNota: function () {
        return $http.get(appSettings.urlServiceBase + 'gestion/nota/lista/cambio').
          then(function (res) {
            return res.data;
          });
      },
      listaSolicitudCambioNotaSubregion: function (estadoId) {
        var params = {
          estadoId: estadoId
        }
        var config = {
          params: params
        }
        return $http.get(appSettings.urlServiceBase + 'gestion/nota/solicitud/cambio', config).
          then(function (res) {
            return res.data;
          });
      },
      getSolicitudCambioNota: function ( cambioNotaId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/nota/cambio/' + cambioNotaId).
          then(function (res) {
            return res.data;
          });
      },
      anularSolicitudCambioNota: function ( cambioNotaId ) {
        return $http.put(appSettings.urlServiceBase + 'gestion/nota/anular/cambio/' + cambioNotaId).
          then(function (res) {
            return res.data;
          });
      },
      recibirSolicitudCambioNota: function ( ttCambioNota ) {
        return $http.put(appSettings.urlServiceBase + 'gestion/nota/recibir/cambio', ttCambioNota).
          then(function (res) {
            return res.data;
          });
      },
      finalizarSolicitudCambioNota: function ( ttCambioNota ) {
        return $http.put(appSettings.urlServiceBase + 'gestion/nota/finalizar/cambio', ttCambioNota).
          then(function (res) {
            return res.data;
          });
      },
      getConCronograma: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/' + gestionId + '/cronograma').then(function (res) {
          return res.data;
        });
      },
      enmiendaRequisitosLicencia: function ( tareaId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/tarea/' + tareaId + '/enmienda/licencia').then(function (res) {
          return res.data;
        });
      },
      listaEnmiendaRequisitosLicencia: function ( gestionId ) {
        return $http.get(appSettings.urlServiceBase + 'gestion/' + gestionId + '/enmienda/turno/licencia').then(function (res) {
          return res.data;
        });
      }
    }

  }]);
