angular.module('app.version', [
  'ui.router',
  'toastr',
  'app.version.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.version', {
          abstract: true,
          url: 'version',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Versión';
            }]
        })
        .state('index.version.list', {
          url: '',
          templateUrl: 'app/version/version.list.tpl.html',
          resolve: {
            dataVersion: ['versionService',
              function ( versionService ){
                return versionService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataVersion',
            function (  $scope,   $state,   dataVersion) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'versionDesc', name: 'versionDesc', displayName: 'Versión' },
                { field:'tcSistema.sistemaDesc', name: 'sistemaDesc', displayName: 'Sistema' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataVersion.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ versionId: row.versionId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.version.add', {
          url: '/add',
          templateUrl: 'app/version/version.add.tpl.html',
          resolve: {
            dataEstado: ['versionService',
              function ( versionService ){
                return versionService.listStatus();
              }],
            dataSistema: ['versionService',
              function ( versionService ){
                return versionService.listaActivaSistema();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'versionService', 'dataEstado', 'dataSistema',
            function (  $scope,   $state,   toastr,   versionService, dataEstado, dataSistema) {
              $scope.data = {
                tcSistema: {
                  sistemaId: null
                }
              };
              $scope.dataEstado = dataEstado.data;
              $scope.dataSistema = dataSistema.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  versionService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.version.edit', {
          url: '/:versionId/edit',
          templateUrl: 'app/version/version.add.tpl.html',
          resolve: {
            dataVersion: ['versionService', '$stateParams',
              function ( versionService, $stateParams ){
                return versionService.get( $stateParams.versionId );
              }],
            dataEstado: ['versionService',
              function ( versionService ){
                return versionService.listStatus();
              }],
            dataSistema: ['versionService',
              function ( versionService ){
                return versionService.listaActivaSistema();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'versionService', 'dataVersion', 'dataEstado', 'dataSistema',
            function (  $scope,   $state,   toastr,   versionService,   dataVersion, dataEstado, dataSistema) {
              $scope.data = dataVersion.data[0];
              $scope.dataSistema = dataSistema.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  versionService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
