angular.module('app.finca', [
  'ui.router',
  'toastr',
  'app.finca.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.finca', {
          abstract: true,
          url: 'finca',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Finca';
            }]
        })
        .state('index.finca.list', {
          url: '',
          templateUrl: 'app/finca/finca.list.tpl.html',
          resolve: {
            dataDepartamento: ['departamentoService', '$stateParams',
              function ( departamentoService, $stateParams ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'municipioService', 'dataDepartamento', 'fincaService', '$base64',
            function (  $scope,   $state, municipioService, dataDepartamento, fincaService, $base64 ) {

              $scope.dataDepartamentoFinca = dataDepartamento.data;

              $scope.direccion = {};

              $scope.gridOptionsFinca = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptionsFinca.columnDefs = [
                { field:'fincaDesc', name: 'fincaDesc', displayName: 'Finca', width: '10%' },
                { field:'direccion', name: 'direccion', displayName: 'Dirección', width: '10%' },
                { field:'este', name: 'este', displayName: 'Al este', width: '10%' },
                { field:'oeste', name: 'oeste', displayName: 'Al oeste', width: '10%' },
                { field:'norte', name: 'norte', displayName: 'Al norte', width: '10%' },
                { field:'sur', name: 'sur', displayName: 'Al sur', width: '10%' },
                { field:'gtmX', name: 'gtmX', displayName: 'GTM X', width: '5%' },
                { field:'gtmY', name: 'gtmY', displayName: 'GTM Y', width: '5%' },
                { field:'area', name: 'area', displayName: 'Área', width: '5%' },
                { field:'tcTipoPropiedad.tipoPropiedadDesc', name: 'tipoPropiedadDesc', displayName: 'Documento', width: '10%' },
                { field:'folio', name: 'folio', displayName: 'Folio', width: '5%' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptionsFinca.data = [];

              $scope.cargarListaMunicipioFinca = function (item) {
                $scope.direccion.municipioId = null;
                municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                  if (res.status == "OK") {
                    $scope.dataMunicipioFinca = res.data;
                  } else {
                    toastr.error(res.message);
                  }
                }, function (error) {
                  toastr.error(error);
                });
              }

              $scope.setMunicipioFinca = function ( item ) {
                if (item) {
                  fincaService.getListaFincaMunicipio(item.municipioId).then(function (res) {
                    if (res.status == "OK") {
                      $scope.gridOptionsFinca.data = res.data;
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }
              }

              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ fincaId: $base64.encode(row.fincaId) });
              }
              $scope.agregarRegistroFinca = function () {
                $state.go('^.add');
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.finca.add', {
          url: '/add',
          templateUrl: 'app/finca/finca.add.tpl.html',
          resolve: {
            dataEstado: ['fincaService',
              function ( fincaService ){
                return fincaService.listStatus();
              }],
            dataPais: ['paisService',
              function ( paisService ){
                return paisService.listaActiva();
              }],
            dataDepartamento: ['departamentoService',
              function ( departamentoService ){
                return departamentoService.listaPorPais(1);
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fincaService', 'dataEstado', 'dataPais', 'dataDepartamento',
            'departamentoService', 'municipioService', 'ngDialog', '$filter', 'authService', '$base64',
            function (  $scope,   $state,   toastr,   fincaService, dataEstado, dataPais,
              dataDepartamento, departamentoService, municipioService, ngDialog, $filter, authService, $base64) {
              $scope.mostrarBotones = true;
              $scope.required = true;
              $scope.municipioEmiteDesc = "Clic en el ícono";
              $scope.getStateToGo();
              if ($scope.globales.stateToGo.length > 0) {
                var valor = 0;
                if ($scope.globales.paramStateToGo != null) {
                  valor = $base64.decode($scope.globales.paramStateToGo);
                }
                if (valor > 0) {
                  $scope.cancelState = $scope.globales.stateToGo + "({gestionId : '" + $scope.globales.paramStateToGo + "'})";
                  if ($scope.globales.esExento != null) {
                    if ($scope.globales.esExento) {
                      $scope.cancelState = $scope.globales.stateToGo + "({exentoId : '" + $scope.globales.paramStateToGo + "'})";
                      $scope.required = false;
                    }
                    if ($scope.globales.esActualizacionExento) {
                      $scope.cancelState = $scope.globales.stateToGo + "({actualizacionExentoId : '" + $scope.globales.paramStateToGo + "'})";
                      $scope.required = false;
                    }
                  }
                } else {
                  $scope.cancelState = $scope.globales.stateToGo;
                }
              } else {
                $scope.cancelState = "^.list";
              }
              $scope.dataPais = dataPais.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.direccion = {
                paisId : 1,
                departamentoId : null
              };
              $scope.data = {
                tcMunicipio: {
                  municipioId: null
                }
              };

              $scope.cargarListaDepartamento = function (item) {
                $scope.direccion.departamentoId = null;
                $scope.data.municipioId = null;
                departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataDepartamento = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaMunicipio = function (item) {
                $scope.data.municipioId = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              var fincaRfn = authService.getLocalData('fincaRfn');
              if (fincaRfn != null) {
                $scope.data.areaDocumento = fincaRfn.area;
                $scope.data.area = fincaRfn.area;
                $scope.data.fincaDesc = fincaRfn.fincaDesc;
                $scope.data.direccion = fincaRfn.direccion;
                $scope.data.gtmX = fincaRfn.gtmX;
                $scope.data.gtmY = fincaRfn.gtmY;
                $scope.data.direccion = fincaRfn.direccion;
                if (fincaRfn.municipioId != null) {
                  municipioService.getHomologoMunicipio( fincaRfn.municipioId ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var item = res.data[0];
                      $scope.cargarListaMunicipio(item.tcDepartamento);
                      $scope.data.tcMunicipio = item;
                      $scope.direccion.departamentoId = item.tcDepartamento.departamentoId;
                    }
                  }, function ( error ) {

                  });
                }
              }

              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;

              $scope.setMunicipio = function (item) {
                //$scope.data.municipioDesc = item.municipioDesc;
              }

              $scope.setAreaGps = function () {
                $scope.data.area = $scope.data.areaDocumento;
              }

              $scope.buscarMunicipalidad = function () {
                $scope.municipalidad = {
                  dataPais : $scope.dataPais,
                  dataDepartamento : $scope.dataDepartamento,
                  dataMunicipio : [],
                  paisId : 1,
                  departamentoId : null,
                  municipioId : null
                }
                ngDialog.open({
                    template: 'app/finca/buscar.municipio.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr', '$base64',
                      function ($scope, $state, toastr, $base64) {

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.municipalidad.departamentoId = null;
                          $scope.municipalidad.municipioId = null;
                          departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              $scope.municipalidad.dataDepartamento = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.municipalidad.municipioId = null;
                          municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              $scope.municipalidad.dataMunicipio = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                        $scope.setMunicipio = function (item) {
                          $scope.variables.municipioEmiteDesc = item.municipioDesc;
                          $scope.data.municipioEmiteId = item.municipioId;
                        }

                      }]
                  })
              }

              $scope.submitFormMunicipalidad = function ( isValid ) {
                if (isValid) {
                  ngDialog.close();
                }
              }

              $scope.moveToState = function (stateToGo, param, fincaId) {
                if (param) {
                  $scope.globales.fincaId = fincaId;
                  if ($scope.globales.esActualizacionExento == null) {
                    $scope.globales.esActualizacionExento = false;
                  }
                  if ($scope.globales.esExento == null) {
                    $scope.globales.esExento = false;
                  }
                  if ($scope.globales.esActualizacionExento) {
                    var actualizacionId = $base64.decode(param);
                    $state.go(stateToGo, { actualizacionExentoId: $base64.encode(actualizacionId) });
                    return;
                  } else {
                    if ($scope.globales.esExento) {
                      $state.go(stateToGo, {exentoId : param});
                    } else {
                      $state.go(stateToGo, {gestionId : param});
                    }
                  }
                } else {
                  $state.go( stateToGo );
                }
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var data = angular.copy($scope.data);
                  data.direccion = $scope.capitalizeText(data.direccion);
                  data.fincaDesc = $scope.capitalizeText(data.fincaDesc);
                  data.direccion = data.direccion.replace('lugar denominado ', '');
                  data.direccion = data.direccion.replace('lugar conocido ', '');
                  data.direccion = data.direccion.replace('conocido ', '');
                  data.direccion = data.direccion.replace('denominado ', '');
                  var enviada = false;
                  if ($scope.globales.esExento != null || $scope.globales.esActualizacionExento != null) {
                    if ($scope.globales.esExento || $scope.globales.esActualizacionExento) {
                      enviada = true;
                      fincaService.addFromExento( data ).then( function ( res ) {
                        if ( res.status == "OK" ) {
                          var stateToGo = authService.getLocalData('stateToGo');
                          var paramStateToGo = authService.getLocalData('paramStateToGo');
                          if (stateToGo) {
                            $scope.removeStateToGo();
                            $scope.moveToState(stateToGo, paramStateToGo, res.data[0].fincaId);
                          } else {
                            $state.go( '^.list' );
                          }
                        } else {
                          toastr.error( res.message );
                        }
                      }, function ( error ) {
                        toastr.error( error );
                      });
                    }
                  }
                  if (!enviada) {
                    fincaService.add( data ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var stateToGo = authService.getLocalData('stateToGo');
                        var paramStateToGo = authService.getLocalData('paramStateToGo');
                        if (stateToGo) {
                          $scope.removeStateToGo();
                          $scope.moveToState(stateToGo, paramStateToGo, res.data[0].fincaId);
                        } else {
                          $state.go( '^.list' );
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                  }
                }
              }
            }]
        })
        .state('index.finca.edit', {
          url: '/:fincaId/edit',
          templateUrl: 'app/finca/finca.add.tpl.html',
          resolve: {
            dataFinca: ['fincaService', '$stateParams', '$base64',
              function ( fincaService, $stateParams, $base64 ){
                return fincaService.get( $base64.decode($stateParams.fincaId) );
              }],
            dataEstado: ['fincaService',
              function ( fincaService ){
                return fincaService.listStatus();
              }],
            dataPais: ['paisService',
              function ( paisService ){
                return paisService.listaActiva();
              }],
            dataDepartamento: ['departamentoService', 'dataFinca',
              function ( departamentoService, dataFinca ){
                return departamentoService.listaPorPais(dataFinca.data[0].tcMunicipio.tcDepartamento.tcPais.paisId);
              }],
            dataMunicipio: ['municipioService', 'dataFinca',
              function ( municipioService, dataFinca ){
                return municipioService.listaPorDepartamento(dataFinca.data[0].tcMunicipio.tcDepartamento.departamentoId);
              }],
            dataMunicipalidad: ['municipioService', 'dataFinca',
              function ( municipioService, dataFinca ){
                if (dataFinca.data[0].tcMunicipioEmite != null) {
                  return municipioService.get(dataFinca.data[0].tcMunicipioEmite.municipioId);
                } else {
                  return {
                    status : "OK",
                    message : "OK",
                    data : []
                  };
                }
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'fincaService', 'dataFinca', 'dataEstado', 'dataPais', 'dataDepartamento', 'dataMunicipio', 'dataMunicipalidad', 'departamentoService', 'municipioService', '$filter',
            function (  $scope,   $state,   toastr,   fincaService,   dataFinca, dataEstado, dataPais, dataDepartamento, dataMunicipio, dataMunicipalidad, departamentoService, municipioService, $filter) {
              $scope.mostrarBotones = true;
              $scope.data = dataFinca.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.required = true;
              $scope.municipioEmiteDesc = "Clic en el ícono";
              $scope.getStateToGo();
              if ($scope.globales.stateToGo.length > 0) {
                if ($scope.globales.paramStateToGo.length > 0) {
                  $scope.cancelState = $scope.globales.stateToGo + "({gestionId : " + $scope.globales.paramStateToGo + "})";
                } else {
                  $scope.cancelState = $scope.globales.stateToGo;
                }
              } else {
                $scope.cancelState = "^.list";
              }
              $scope.dataPais = dataPais.data;
              $scope.dataDepartamento = dataDepartamento.data;
              $scope.dataMunicipio = dataMunicipio.data;
              $scope.direccion = {
                paisId : $scope.data.tcMunicipio.tcDepartamento.tcPais.paisId,
                departamentoId : $scope.data.tcMunicipio.tcDepartamento.departamentoId
              };


              $scope.dataEstado = dataEstado.data;

              $scope.setTipoPropiedad = function (item) {
                switch (parseInt(item.tipoPropiedadId)) {
                  case 1:
                    $scope.variables = {
                      tituloNoDucumento : "Número de certificación",
                      placeHolderNoDucumento : "Número de certificación",
                      visibleNotario : false,
                      visibleMunicipioEmite : true,
                      visibleFolio : false,
                      visibleOcret : false,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                  case 2:
                    $scope.variables = {
                      tituloNoDucumento : "Número de escritura",
                      placeHolderNoDucumento : "Número de escritura",
                      visibleNotario : true,
                      visibleMunicipioEmite : false,
                      visibleFolio : false,
                      visibleOcret : false,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                  case 3:
                    $scope.variables = {
                      tituloNoDucumento : "No. de finca",
                      placeHolderNoDucumento : "No. de finca",
                      visibleNotario : false,
                      visibleMunicipioEmite : true,
                      visibleFolio : true,
                      visibleOcret : false,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                  case 4:
                    $scope.variables = {
                      tituloNoDucumento : "Número de escritura",
                      placeHolderNoDucumento : "Número de escritura",
                      visibleNotario : true,
                      visibleMunicipioEmite : false,
                      visibleFolio : false,
                      visibleOcret : true,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                  case 5:
                    $scope.variables = {
                      tituloNoDucumento : "Acta notarial",
                      placeHolderNoDucumento : "Acta notarial",
                      visibleNotario : true,
                      visibleMunicipioEmite : false,
                      visibleFolio : false,
                      visibleOcret : false,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                  default:
                    $scope.variables = {
                      tituloNoDucumento : "Número de certificación",
                      placeHolderNoDucumento : "Número de certificación",
                      visibleNotario : false,
                      visibleMunicipioEmite : true,
                      visibleFolio : false,
                      visibleOcret : false,
                      municipioEmiteDesc : "Clic en el ícono"
                    }
                    break;
                }
              }

              $scope.cargarListaDepartamento = function (item) {
                $scope.direccion.departamentoId = null;
                $scope.data.tcMunicipio = null;
                departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataDepartamento = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.cargarListaMunicipio = function (item) {
                $scope.data.tcMunicipio = null;
                municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataMunicipio = res.data;
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setMunicipio = function (item) {
                //$scope.data.municipioDesc = item.municipioDesc;
              }

              $scope.buscarMunicipalidad = function () {
                $scope.municipalidad = {
                  dataPais : $scope.dataPais,
                  dataDepartamento : $scope.dataDepartamento,
                  dataMunicipio : [],
                  paisId : 1,
                  departamentoId : null,
                  municipioId : null
                }
                ngDialog.open({
                    template: 'app/finca/buscar.municipio.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                    },
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.cargarListaDepartamento = function (item) {
                          $scope.municipalidad.departamentoId = null;
                          $scope.municipalidad.municipioId = null;
                          departamentoService.listaPorPais( item.paisId ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              $scope.municipalidad.dataDepartamento = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                        $scope.cargarListaMunicipio = function (item) {
                          $scope.municipalidad.municipioId = null;
                          municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              $scope.municipalidad.dataMunicipio = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                        $scope.setMunicipio = function (item) {
                          $scope.variables.municipioEmiteDesc = item.municipioDesc;
                          $scope.data.municipioEmiteId = item.municipioId;
                        }

                      }]
                  })
              }

              $scope.submitFormMunicipalidad = function ( isValid ) {
                if (isValid) {
                  ngDialog.close();
                }
              }

              $scope.moveToState = function (stateToGo, param, fincaId) {
                if (param.length > 0) {
                  $scope.globales.fincaId = fincaId;
                  $state.go(stateToGo, {gestionId : param});
                } else {
                  $state.go( stateToGo );
                }
              }

              $scope.setTipoPropiedad({tipoPropiedadId : $scope.data.tipoPropiedadId});

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var data = angular.copy($scope.data);
                  if (data.fechaEmision != null) {
                    data.fechaEmision = $filter('date')(new Date(data.fechaEmision),'yyyy-MM-dd');
                  }
                  if (data.fechaAval != null) {
                    data.fechaAval = $filter('date')(new Date(data.fechaAval),'yyyy-MM-dd');
                  }
                  fincaService.edit( data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success(res.message);
                      if ($scope.globales.stateToGo.length > 0) {
                        var stateToGo = $scope.globales.stateToGo;
                        var param = $scope.globales.paramStateToGo;
                        $scope.removeStateToGo();
                        $scope.moveToState(stateToGo, param, $scope.data.fincaId);
                      } else {
                        $state.go( '^.list' );
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              if (dataMunicipalidad.data.municipioId > 0) {
                $scope.variables.municipioEmiteDesc = dataMunicipalidad.data.municipioDesc + ", " + dataMunicipalidad.data.departamentoDesc;
              } else {
                $scope.variables.municipioEmiteDesc = "";
              }

            }]
        })
    }
  ]
);
