angular.module('app.actividadforestal', [
  'ui.router',
  'toastr',
  'app.actividadforestal.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.actividadforestal', {
          abstract: true,
          url: 'actividadforestal',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Actividad Forestal';
            }]
        })
        .state('index.actividadforestal.list', {
          url: '',
          templateUrl: 'app/actividadforestal/actividadforestal.list.tpl.html',
          resolve: {
            dataActividadForestal: ['actividadforestalService',
              function ( actividadforestalService ){
                return actividadforestalService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataActividadForestal',
            function (  $scope,   $state,   dataActividadForestal) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'actividadForestalDesc', name: 'actividadForestalDesc', displayName: 'Forma de la parcela' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataActividadForestal.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ actividadForestalId: row.actividadForestalId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.actividadforestal.add', {
          url: '/add',
          templateUrl: 'app/actividadforestal/actividadforestal.add.tpl.html',
          resolve: {
            dataEstado: ['actividadforestalService',
              function ( actividadforestalService ){
                return actividadforestalService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'actividadforestalService', 'dataEstado',
            function (  $scope,   $state,   toastr,   actividadforestalService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  actividadforestalService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.actividadforestal.edit', {
          url: '/:actividadForestalId/edit',
          templateUrl: 'app/actividadforestal/actividadforestal.add.tpl.html',
          resolve: {
            dataActividadForestal: ['actividadforestalService', '$stateParams',
              function ( actividadforestalService, $stateParams ){
                return actividadforestalService.get( $stateParams.actividadForestalId );
              }],
            dataEstado: ['actividadforestalService',
              function ( actividadforestalService ){
                return actividadforestalService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'actividadforestalService', 'dataActividadForestal', 'dataEstado',
            function (  $scope,   $state,   toastr,   actividadforestalService,   dataActividadForestal, dataEstado) {
              $scope.data = dataActividadForestal.data;
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  actividadforestalService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
