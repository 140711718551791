angular.module('app.producto', [
  'ui.router',
  'toastr',
  'app.producto.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.producto', {
          abstract: true,
          url: 'producto',
          template: '<div ui-view></div>',
          resolve: {

          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Producto';
            }]
        })
        .state('index.producto.list', {
          url: '',
          templateUrl: 'app/producto/producto.list.tpl.html',
          resolve: {
            dataProducto: ['productoService',
              function ( productoService ){
                return productoService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataProducto',
            function (  $scope,   $state,   dataProducto) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'productoDesc', name: 'productoDesc', displayName: 'Producto' },
                { field:'maderable', name: 'maderable', displayName: 'Es maderable' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataProducto.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ productoId: row.productoId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.producto.add', {
          url: '/add',
          templateUrl: 'app/producto/producto.add.tpl.html',
          resolve: {
            dataEstado: ['productoService',
              function ( productoService ){
                return productoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'productoService', 'dataEstado',
            function (  $scope,   $state,   toastr,   productoService, dataEstado) {
              $scope.classMaderable = 'btn-danger btn-sm glyphicon glyphicon-check';
              $scope.textoMaderable = ' No';
              $scope.setMaderable = function () {
                if ($scope.data.esMaderable == 1) {
                  $scope.data.esMaderable = 0;
                  $scope.classMaderable = 'btn-danger btn-sm glyphicon glyphicon-check';
                  $scope.textoMaderable = ' No';
                } else {
                  $scope.data.esMaderable = 1;
                  $scope.classMaderable = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                  $scope.textoMaderable = ' Si';
                }
              }
              $scope.data = {
                estadoId : 1,
                esMaderable : 0
              };
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  productoService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.producto.edit', {
          url: '/:productoId/edit',
          templateUrl: 'app/producto/producto.add.tpl.html',
          resolve: {
            dataProducto: ['productoService', '$stateParams',
              function ( productoService, $stateParams ){
                return productoService.get( $stateParams.productoId );
              }],
            dataEstado: ['productoService',
              function ( productoService ){
                return productoService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'productoService', 'dataProducto', 'dataEstado',
            function (  $scope,   $state,   toastr,   productoService,   dataProducto, dataEstado ) {
              $scope.data = dataProducto.data;
              if ($scope.data.esMaderable == 0) {
                $scope.classMaderable = 'btn-danger btn-sm glyphicon glyphicon-check';
                $scope.textoMaderable = ' No';
              } else {
                $scope.classMaderable = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                $scope.textoMaderable = ' Si';
              }
              $scope.setMaderable = function () {
                if ($scope.data.esMaderable == 1) {
                  $scope.data.esMaderable = 0;
                  $scope.classMaderable = 'btn-danger btn-sm glyphicon glyphicon-check';
                  $scope.textoMaderable = ' No';
                } else {
                  $scope.data.esMaderable = 1;
                  $scope.classMaderable = 'btn-success btn-sm glyphicon glyphicon-unchecked';
                  $scope.textoMaderable = ' Si';
                }
              }
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  productoService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
