angular.module('app.requisito.service', [

])

.factory('requisitoService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'requisito/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'requisito/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    getRequisitoPorTipo: function ( tipoGestionId ) {
      return $http.get(appSettings.urlServiceBase + "requisito/tipogestion/" + tipoGestionId + "/lista").then(function (res) {
        return res.data;
      });
    },
    getRequisitoGestion: function ( gestionId ) {
      return $http.get(appSettings.urlServiceBase + "requisito/gestion/" + gestionId + "/lista").then(function (res) {
        return res.data;
      });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'requisito/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'requisito/' + data.requisitoId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( requisitoId ) {
      return $http.get( appSettings.urlServiceBase + 'requisito/' + requisitoId).then(function ( res ) {
         return res.data;
       });
    }
  }

}]);
