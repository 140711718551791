angular.module('app.fuentesemillera.service', [

])

.factory('fuentesemilleraService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    list: function () {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lista' ).then(function ( res ) {
         return res.data;
       });
    },
    listaActiva: function () {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lista/activa' ).then(function ( res ) {
         return res.data;
       });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    add: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'fuentesemillera/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'fuentesemillera/' + data.fuenteSemilleraId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( fuenteSemilleraId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/' + fuenteSemilleraId).then(function ( res ) {
         return res.data;
       });
    },
    getListaCosecha : function( fuenteSemilleraId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/' + fuenteSemilleraId + '/cosecha').then(function ( res ) {
         return res.data;
       });
    },
    addLoteSemilla: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'fuentesemillera/lote/semilla/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getLoteSemilla : function( loteSemillaId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lote/semilla/' + loteSemillaId).then(function ( res ) {
         return res.data;
       });
    },
    getListaVentaSemilla : function( loteSemillaId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lote/semilla/' + loteSemillaId + '/venta').then(function ( res ) {
         return res.data;
       });
    },
    addVentaSemilla: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'fuentesemillera/venta/semilla/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    getVentaSemilla : function( ventaSemillaId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/venta/semilla/' + ventaSemillaId).then(function ( res ) {
         return res.data;
       });
    },
    getLotesPlantaPorVentaSemilla : function( ventaSemillaId ) {
      if (ventaSemillaId > 0) {
        return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lote/planta/venta/semilla/' + ventaSemillaId + '').then(function ( res ) {
           return res.data;
         });
      } else {
        var res = {
          status : 'OK',
          message : 'Datos cargados',
          data : []
        };
        return res;
      }
    },
    getListaVentaPlanta : function( lotePlantaId ) {
      return $http.get( appSettings.urlServiceBase + 'fuentesemillera/lote/planta/' + lotePlantaId + '/venta').then(function ( res ) {
         return res.data;
       });
    },
    getListaVentaPlantaVacio : function( ) {
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : []
      };
      return res;
    }
  }

}]);
