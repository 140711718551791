angular.module('app.tarea', [
  'ui.router',
  'toastr',
  'app.tarea.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.tarea', {
            abstract: true,
            url: 'tarea',
            template: '<div ui-view></div>',
            resolve: {
              permisoEstado: ['authService',
                function (authService) {
                  return authService.loginPermission(4);
                }],
              dataTarea: ['tareaService', 'authService', '$filter',
                function (tareaService, authService, $filter) {
                  return tareaService.listaTask();
                }]
            },
            controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService', 'dataTarea', 'tareaService', '$filter', 'exentoService',
              'consumoService', 'ngDialog', '$state', '$base64',
              function ($scope, toastr, $timeout, gestionService, utilsService, dataTarea, tareaService, $filter, exentoService,
                consumoService, ngDialog, $state, $base64) {
                $scope.module = 'Tarea';
                $scope.globales.totalPaginas = 0;
                $scope.dataTask = dataTarea.data;

                $scope.imprimirPlan = function (row) {
                  var gestionId = row.ttGestion.gestionId;
                  try {
                    gestionService.getConCronograma(gestionId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        var dataResumen = data.rodal;
                        for (i = 0; i < dataResumen.length; i++) {
                          for (j = 0; j < dataResumen[i].especies.length; j++) {
                            for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                              dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                            }
                            if (dataResumen[i].especies[j].fuste != null) {
                              dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                            }
                          }
                        }
                        //generales
                        if (data.ttInventarioGestion == undefined || data.ttInventarioGestion == null) {
                          toastr.error("La información de inventario esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.ttPlanificacionGestion == undefined || data.ttPlanificacionGestion == null) {
                          toastr.error("La información de planificación esta incompleto, favor de revisar");
                          return;
                        }
                        if (data.cronograma == undefined || data.cronograma == null) {
                          toastr.error("El cronograma esta incompleto, favor de revisar");
                          return;
                        }
                        try {
                          if (data.ttInventarioGestion.data != undefined && data.ttInventarioGestion.data != null) {
                            data.ttInventarioGestion.data = JSON.parse(data.ttInventarioGestion.data);
                          }
                          try {
                            data.ttInventarioGestion.brinzal = JSON.parse(data.ttInventarioGestion.brinzal);
                          } catch (e) {
                            data.ttInventarioGestion.brinzal = [];
                          }
                          try {
                            data.ttInventarioGestion.latizal = JSON.parse(data.ttInventarioGestion.latizal);
                          } catch (e) {
                            data.ttInventarioGestion.latizal = [];
                          }
                          data.cronograma = JSON.parse(data.cronograma);
                          toastr.info('Espere mientras se imprimen los documentos', 'Imprimiendo');
                          utilsService.generarPlan($scope.dataEmpresa, data);
                          $timeout(function () {
                            utilsService.generarSolicitudLicencia(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                            $scope.imprimirCronograma(data);
                          }, 3000);
                          if (data.ttResumenGestion.noTurnos > 1) {
                            $timeout(function () {
                              utilsService.generarPoa($scope.dataEmpresa, data, 1);
                              $timeout(function () {
                                $scope.globales.totalPaginasPoa = utilsService.getLastPagePoa();
                                var totalPaginas = $scope.globales.totalPaginasPoa;
                                utilsService.generarCronograma($scope.dataEmpresa, data, totalPaginas, 0, 1);
                              }, 3000);
                            }, 1000);
                          }
                        } catch (e) {
                          toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador. " + e.message);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } catch (e) {
                    toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador");
                  }
                }

                $scope.imprimirCronograma = function (data) {
                  $scope.globales.totalPaginas = utilsService.getLastPage();
                  if ($scope.globales.totalPaginas <= 0) {
                    toastr.error("Debe imprimir primero el plan luego el cronograma");
                  } else {
                    utilsService.generarCronograma($scope.dataEmpresa, data, $scope.globales.totalPaginas);
                  }
                }

                $scope.verDocumentoTareaLicencia = function (item, row) {
                  var dia = null;
                  var anio = null;
                  var strDia = null;
                  var strAnio = null;
                  if (item.estadoId == 1 || item.estadoId == 20) {
                    toastr.error('No es posible imprimir documentos anulados o tareas sin finalizar');
                    return;
                  }
                  switch (parseInt(item.tcTask.taskId)) {
                    case 1: //solicitud
                      if (row == null) {
                        row = item;
                      }
                      gestionService.getDatosRecepcionByGestion(row.ttGestion.gestionId).then(function (res) {
                        if (res.status == "OK") {
                          utilsService.generarConstanciaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 2:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          dia = $filter('date')(new Date(data.fechaRecepcion), 'dd');
                          anio = $filter('date')(new Date(data.fechaRecepcion), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'dd');
                          anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaAdmision), 'yyyy');
                          var strDiaAdmision = $scope.numeroALetras(dia);
                          var strAnioAdmision = $scope.numeroALetras(anio, false);
                          utilsService.generarResolucionAdmision(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          $timeout(function () {
                            utilsService.generarProvidenciaTraslado(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }, 1000);
                          $timeout(function () {
                            utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 3000);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 3:
                      tareaService.getConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          try {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          if (data.ttSeguimientoTarea.codigo == null) {
                            data.ttSeguimientoTarea.codigo = '';
                          }
                          if (parseInt(data.aprobado) == 2) {
                            utilsService.generarEnmiendaJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          } else {
                            utilsService.generarOpinionJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa)
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 4:
                      gestionService.getGestionParaTarea(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (parseInt(data.esEnmienda) == 1) {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                            utilsService.generarEnmiendaTecnica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          } else {
                            if (data.ttSeguimientoTarea.antecedente != null) {
                              data.ttSeguimientoTarea.antecedente = JSON.parse(data.ttSeguimientoTarea.antecedente);
                            }
                            data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                            data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                            utilsService.generarOpinionTecnica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 5:
                      gestionService.getGestionParaTarea(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio, false);
                          if (data.numero == -1) {
                            data.ttSeguimientoTarea.observaciones = JSON.parse(data.ttSeguimientoTarea.observaciones);
                            utilsService.generarProvidenciaRatificacion(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          } else {
                            if (parseInt(data.esEnmienda) == 1) {
                              data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                              utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                            } else {
                              data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                              if (data.observaciones != null) {
                                try {
                                  data.observaciones = JSON.parse(data.observaciones);
                                } catch (e) { }
                              }
                              utilsService.generarDictamenSubregional(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa);
                            }
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 46:
                    case 6:
                      gestionService.getGestionParaTareaConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.ttSeguimientoTarea.fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
                          if (data.esEnmienda == 1) {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                            utilsService.generarEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          } else {
                            data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                            data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                            data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                            var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                            var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                            var strDiaResolucion = $scope.numeroALetras(dia);
                            var strAnioResolucion = $scope.numeroALetras(anio, false);
                            if (data.tcTask.taskId == 6) {
                              utilsService.generarOpinionRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            } else {
                              utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            }
                            $timeout(function () {
                              utilsService.generarProvidenciaRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            }, 2000);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 47:
                    case 7:
                      tareaService.getConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var esLicencia = true;
                          if (data.tcTask.taskId == 47) {
                            esLicencia = false;
                          }
                          utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                          if (data.ttPadre.aprobado == 1) {
                            if (esLicencia) {
                              $timeout(function () {
                                utilsService.generarNotificacionLicencia(res.data[0], $scope.loginData.usuario, $scope.dataEmpresa);
                              }, 1000);
                            } else {
                              data.aprobado = 1;
                              var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                              var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                              var strDiaResolucion = $scope.numeroALetras(dia);
                              var strAnioResolucion = $scope.numeroALetras(anio, false);
                              var esPoa = false;
                              var noTurno = 1;
                              utilsService.generarOficioRequisitoPoa(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, esPoa, noTurno);
                            }
                            if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2) { //poliza de fianzas
                              $timeout(function () {
                                data.ttPadre.ttSeguimientoTarea.analisis = JSON.parse(data.ttPadre.ttSeguimientoTarea.analisis);
                                data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                var strVigencia = "";
                                var analisis = data.ttPadre.ttSeguimientoTarea.analisis;
                                var row = analisis[analisis.length - 1];
                                var vigencia = row.vigencia;
                                var strFecha = vigencia.substring(vigencia.indexOf('al') + 3);
                                var parts = strFecha.split('/');
                                var fin = new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
                                var ini = new Date(data.ttPadre.ttSeguimientoTarea.fundamento.fechaIni);
                                dia = $filter('date')(ini, 'dd');
                                anio = $filter('date')(ini, 'yyyy');
                                strVigencia = $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(ini.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                dia = $filter('date')(fin, 'dd');
                                anio = $filter('date')(fin, 'yyyy');
                                strVigencia += " al " + $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(fin.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                utilsService.generarCartaPoliza(data, strVigencia, $scope.loginData.usuario, $scope.dataEmpresa);
                              }, 1000);
                            }
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 9:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          try {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 10:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          try {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          utilsService.generarNotificacionEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 11:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          try {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 12:
                      gestionService.getGestionParaPoa(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDiaResolucion = $scope.numeroALetras(dia);
                          var strAnioResolucion = $scope.numeroALetras(anio, false);
                          data.ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(data.ttTarea.ttSeguimientoTarea.fundamento);
                          utilsService.generarPoaSubregional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          $timeout(function () {
                            utilsService.generarNotificacionRegionalPoa(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }, 1000);
                          $timeout(function () {
                            utilsService.generarCedulaNotificacionPoa(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 3000);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 14:
                      tareaService.getConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          try {
                            data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          try {
                            data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                          } catch (e) { }
                          if (data.ttSeguimientoTarea.codigo == null) {
                            data.ttSeguimientoTarea.codigo = '';
                          }
                          if (parseInt(data.aprobado) == 2 || parseInt(data.esEnmienda) == 1) {
                            utilsService.generarEnmiendaMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1);
                          } else {
                            utilsService.generarOpinionMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 15:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaTrasladoEnmienda(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          //si es fiduciaria y solicitaron enmienda
                          if ((data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1 || data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7) && data.tcPersonaMonitoreo != null) {
                            $timeout(function () {
                              utilsService.generarSolicitudAval(data, 1, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, 1);
                            }, 2000);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 16:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          utilsService.generarSolicitudAval(data, 1, $scope.loginData.usuario, $scope.dataEmpresa, null, 1);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 17:
                      tareaService.getProvidenciaInforme(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaInforme(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 19:
                      tareaService.getDatosSuspension(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var tarea = data.ttTarea;
                          dia = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaTrasladoSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 20:
                      tareaService.getConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.ttSeguimientoTarea.codigo == null) {
                            data.ttSeguimientoTarea.codigo = '';
                          }
                          utilsService.generarOpinionJuridicaSuspension(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla)
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 21:
                      tareaService.getDatosSuspensionDesdeHijo(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var tarea = data.ttTarea;
                          dia = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarDecisionSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          $timeout(function () {
                            utilsService.generarCedulaNotificacionSuspension(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }, 2000);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 25:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaReasignar(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 34:
                      tareaService.getDatosTareaSolicitudPoa(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var ttSolicitudPoa = res.data[0];
                          var ttGestion = ttSolicitudPoa.ttGestion;
                          var ttTarea = ttSolicitudPoa.ttTarea;
                          var hayFiduciaria = false;
                          if (ttSolicitudPoa.tcTipoGarantia != null) {
                            if (ttSolicitudPoa.tcTipoGarantia.tipoGarantiaId == 1
                              || ttSolicitudPoa.tcTipoGarantia.tipoGarantiaId == 7) {
                              hayFiduciaria = true;
                            }
                          }
                          ttTarea.ttGestion = ttGestion;
                          if (hayFiduciaria) {
                            utilsService.generarSolicitudAval(ttTarea, ttSolicitudPoa.numero, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, ttSolicitudPoa);
                          } else {
                            var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                            var strDiaResolucion = $scope.numeroALetras(dia);
                            var strAnioResolucion = $scope.numeroALetras(anio, false);
                            utilsService.generarOficioRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 35:
                      tareaService.getTareaAvalConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var ttSolicitudPoa = res.data[0];
                          var ttGestion = ttSolicitudPoa.ttGestion;
                          var ttTarea = ttSolicitudPoa.ttTarea;
                          ttTarea.ttGestion = ttGestion;
                          try {
                            ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                          } catch (e) { }
                          try {
                            ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(ttTarea.ttSeguimientoTarea.fundamento);
                          } catch (e) { }
                          if (ttTarea.esEnmienda == 1) {
                            utilsService.generarEnmiendaMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, ttSolicitudPoa.numero, $scope.loginData.sigla);
                          } else {
                            utilsService.generarOpinionMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, ttSolicitudPoa.numero, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 36:
                      tareaService.getTareaRequisitoPoa(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var ttSolicitudPoa = res.data[0];
                          var ttTarea = ttSolicitudPoa.ttTarea;
                          var ttGestion = ttSolicitudPoa.ttGestion;
                          ttTarea.aprobado = 1;
                          ttTarea.ttGestion = ttGestion;
                          var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                          var strDiaResolucion = $scope.numeroALetras(dia);
                          var strAnioResolucion = $scope.numeroALetras(anio, false);
                          utilsService.generarOficioRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, true, ttSolicitudPoa.numero);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 45:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          strDia = $scope.numeroALetras(dia);
                          strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaTrasladoCancelarEnmienda(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 46:
                      tareaService.get(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                          if (data.ttSeguimientoTarea.analisis != null) {
                            data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                          }
                          var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDiaResolucion = $scope.numeroALetras(dia);
                          var strAnioResolucion = $scope.numeroALetras(anio, false);
                          data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                          utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, data.tareaId);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 49:
                      if (item.esEnmienda == 1) {
                        gestionService.enmiendaRequisitosLicencia(item.tareaId).then(function (res) {
                          if (res.status == 1) {
                            var ttTarea = res.data;
                            ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                            utilsService.generarEnmiendaTurno(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      } else {
                        gestionService.getGestionParaPoa(item.tareaId).then(function (res) {
                          if (res.status == "OK") {
                            var data = res.data[0];
                            var dia = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                            var anio = $filter('date')(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                            var strDiaResolucion = $scope.numeroALetras(dia);
                            var strAnioResolucion = $scope.numeroALetras(anio, false);
                            data.ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(data.ttTarea.ttSeguimientoTarea.fundamento);
                            utilsService.oficioSolicitudLicencia(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                            $timeout(function () {
                              utilsService.generarCedulaNotificacionPoa(data, $scope.loginData.usuario, $scope.dataEmpresa);
                            }, 2000);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                      break;
                    case 50:
                      gestionService.getGestionParaLicencia(item.tareaId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          var ttTarea = data.ttTarea;
                          var dia = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                          var anio = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                          var strDiaResolucion = $scope.numeroALetras(dia, false);
                          var strAnioResolucion = $scope.numeroALetras(anio, false);
                          ttTarea.ttGestion = data.ttGestion;
                          ttTarea.ttSeguimientoTarea.fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
                          var tareaId = null;
                          utilsService.generarLicenciaForestal(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId);
                          $timeout(function () {
                            utilsService.generarProvidenciaRegional(ttTarea, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId, true);
                          }, 1000);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 51:
                      tareaService.getConPadre(item.tareaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.ttSeguimientoTarea.fechaProvidencia = new Date();
                          var esLicencia = true;
                          utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                          $timeout(function () {
                            utilsService.generarNotificacionLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa, null, esLicencia);
                          }, 500);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    default:
                      toastr.info('Opción no válida', 'No se han encontrado un opción');
                      break;
                  }
                }

                $scope.imprimirTareaExento = function (item, sigla, exentoId) {
                  if (item.estadoId == 1) {
                    toastr.error('La tarea aún está activa, se debe finalizar para descargar el documento');
                    return;
                  }
                  switch (item.tcTask.taskId) {
                    case 37:
                      if (exentoId == null) {
                        toastr.warning('Este tarea no produce ninguna documento');
                        return;
                      }
                      exentoService.get(exentoId).then(function (res) {
                        if (res.status == "OK") {
                          utilsService.generarSolicitudExento(res.data[0], $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 38:
                      toastr.warning('Este tarea no produce ninguna documento');
                      break;
                    case 39:
                      toastr.warning('Este tarea no produce ninguna documento');
                      break;
                    case 22:
                      tareaService.getDatosTareaExento(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.estadoId == 20) {
                            swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                          } else {
                            var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 23:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.observaciones != null) {
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) { }
                          }
                          if (data.esEnmienda == 1) {
                            utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          } else {
                            var ventas = data.ttExento.ventas;
                            for (var i = 0; i < ventas.length; i++) {
                              var saldos = ventas[i].saldos;
                              for (var j = 0; j < saldos.length; j++) {
                                saldos[j].lenia = saldos[j].ttSaldoCargaVenta.lenia;
                                saldos[j].troza = saldos[j].ttSaldoCargaVenta.troza;
                                saldos[j].total = saldos[j].ttSaldoCargaVenta.volumen;
                              }
                            }
                            utilsService.generarInformeCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 24:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.esEnmienda == -1) {
                            var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          } else {
                            if (data.observaciones != null) {
                              try {
                                data.observaciones = JSON.parse(data.observaciones);
                              } catch (error) {

                              }
                            }
                            if (data.esEnmienda == 1) {
                              utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                            } else {
                              var especies = data.ttExento.especies;
                              for (var i = 0; i < especies.length; i++) {
                                especies[i].saldo = especies[i].ttEspecieSaldoCarga.saldo;
                                especies[i].reserva = especies[i].ttEspecieSaldoCarga.reserva;
                                especies[i].volumen = especies[i].ttEspecieSaldoCarga.volumen;
                              }
                              utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                            }
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 26:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaVentaNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 27:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.observaciones = JSON.parse(data.observaciones);
                          utilsService.generarInformeVentaNota(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 28:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.aprobado == 0) {
                            var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDiaAdmision = $scope.numeroALetras(dia, false);
                            var strAnioAdmision = $scope.numeroALetras(anio, false);
                            utilsService.generarResolucionDenegacionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          } else {
                            utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 29:
                      tareaService.getDataExentoTareaFinalizada(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.ttPadre.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio, false);
                          dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDiaFinalizacion = $scope.numeroALetras(dia, false);
                          var strAnioFinalizacion = $scope.numeroALetras(anio, false);
                          if (data.observaciones == null) {
                            data.observaciones = '';
                          }
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) {
                            data.observaciones = {
                              resolucionRevalidacion: data.observaciones
                            }
                          }
                          utilsService.generarResolucionVenta(data, strDia, strAnio, strDiaFinalizacion, strAnioFinalizacion, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          utilsService.generarCedulaResolucionVenta(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 30:
                      tareaService.obtenerTareaProvidenciaInformeVenta(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio);
                          if (data.observaciones != null) {
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) { }
                          }
                          utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, null);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 31:
                      tareaService.getTareaInformeVenta(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.esEnmienda == 1) {
                            data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                            delete data.ttInformeVenta;
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) {
                              data.observaciones = {
                                razon: [
                                  { descripcion: data.observaciones }
                                ]
                              }
                            }
                            utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          } else {
                            data.ttInformeVenta.conclusiones = JSON.parse(data.ttInformeVenta.conclusiones);
                            data.observaciones = JSON.parse(data.observaciones);
                            utilsService.generarDictamenInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, null);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 32:
                      tareaService.getTareaInformeVentaNotificacion(item.tareaExentoId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          if (data.aprobado == 2) {
                            var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                            var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                            var strDia = $scope.numeroALetras(dia);
                            var strAnio = $scope.numeroALetras(anio);
                            data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                            utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          } else {
                            if (data.ttPadre.aprobado == 0) {
                              data.ttPadre.observaciones = JSON.parse(data.ttPadre.observaciones);
                            }
                            utilsService.generarNotificacionInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 33:
                      tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                          if (data.aprobado == -1) {
                            swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                          } else {
                            var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                            var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            data.observaciones = JSON.parse(data.observaciones);
                            if (data.aprobado == 1) {
                              data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                              data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                              var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                              if (ventas == null) {
                                ventas = [];
                              }
                              for (let i = 0; i < ventas.length; i++) {
                                var element = ventas[i];
                                element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                              }
                            }
                            if (data.ttActualizacionExento.tipoActualizacionId == 2) {
                              data.ttActualizacionExento.rodales = data.ttActualizacionExento.thActualizacionExento.rodales;
                              utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                            } else {
                              utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                            }
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 40:
                      exentoService.getInformeVenta(item.informeVentaId).then(function (res) {
                        if (res.status == "OK") {
                          var data = res.data[0];
                          data.requisito = JSON.parse(data.requisito);
                          utilsService.generarConstanciaRecepcionExento(data, true, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 63:
                      tareaService.getEnmiendaInforme(item.tareaExentoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          data.observaciones = JSON.parse(data.observaciones);
                          utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 64:
                      tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) { }
                          utilsService.dictamenTecnicoActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 65:
                      tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                          if (ventas == null) {
                            ventas = [];
                          }
                          for (let i = 0; i < ventas.length; i++) {
                            var element = ventas[i];
                            element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                          }
                          data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                          if (data.aprobado == 2) {
                            var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                            var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          } else {
                            data.observaciones = JSON.parse(data.observaciones);
                            utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 66:
                      tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                          if (data.aprobado == -1) {
                            swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                          } else {
                            var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                            var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                            var strDia = $scope.numeroALetras(dia, false);
                            var strAnio = $scope.numeroALetras(anio, false);
                            data.observaciones = JSON.parse(data.observaciones);
                            if (data.aprobado == 1) {
                              data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                              data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                              var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                              for (let i = 0; i < ventas.length; i++) {
                                var element = ventas[i];
                                element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                              }
                            }
                            utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    default:

                  }
                }

                $scope.imprimirSolicitudVenta = function (item) {
                  exentoService.getDatosVenta(item.ventaExentoId).then(function (res) {
                    if (res.status == "OK") {
                      var data = res.data[0];
                      utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirInformeTrimestral = function (item) {
                  exentoService.getInformeVenta(item.informeVentaId).then(function (response) {
                    if (response.status == 'OK') {
                      var data = response.data[0];
                      data.conclusiones = JSON.parse(data.conclusiones);
                      utilsService.generarInformeExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(response.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirTareaAvaluo = function (item) {
                  switch (item.tcTask.taskId) {
                    case 41:
                      tareaService.getTareaProvidenciaAvaluo(item.tareaAvaluoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio);
                          try {
                            data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                          } catch (error) {

                          }
                          utilsService.generarProvidenciaAvaluo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, null);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 43:
                      tareaService.getTareaDictamenTecnicoAvaluo(item.tareaAvaluoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          if (data.observaciones != null) {
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) { }
                          } else {
                            data.observaciones = {};
                          }
                          data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                          utilsService.generarDictamenAvaluo(data, $scope.loginData.usuario, false, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 44:
                      tareaService.getTareaDictamenSubregionalAvaluo(item.tareaAvaluoId).then(function (res) {
                        if (res.status == 1) {
                          var ttTarea = res.data;
                          var data = ttTarea.ttPadre;
                          if (data.observaciones != null) {
                            try {
                              data.observaciones = JSON.parse(data.observaciones);
                            } catch (error) { }
                          } else {
                            data.observaciones = {};
                          }
                          data.ttAvaluo.detalle = JSON.parse(data.ttAvaluo.detalle);
                          ttTarea.ttPadre = data;
                          utilsService.generarDictamenAvaluo(ttTarea, $scope.loginData.usuario, true, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    default:
                      break;
                  }
                }

                $scope.imprimirSolicitudConsumo = function (row) {
                  consumoService.get(row.consumoId).then(function (res) {
                    if (res.status == 1) {
                      var ttConsumo = res.data;
                      utilsService.solicitudConsumoFamiliar(ttConsumo, $scope.loginData.usuario, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.imprimirTareaConsumo = function (item, sigla) {
                  switch (item.tcTask.taskId) {
                    case 55:
                      tareaService.getTareaSubregionalConsumo(item.tareaConsumoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                          utilsService.resolucionAdmisionConsumo(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 56:
                      tareaService.getTareaTecnicaConsumo(item.tareaConsumoId).then(function (res) {
                        if (res.status == 1) {
                          ttTareaConsumo = res.data;
                          try {
                            ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario = JSON.parse(ttTareaConsumo.ttConsumo.ttConsumoInformacion.inventario);
                            ttTareaConsumo.observaciones = JSON.parse(ttTareaConsumo.observaciones);
                          } catch (error) { }
                          utilsService.generarInformeConsumo(ttTareaConsumo, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 57:
                      tareaService.getTareaResolucionConsumo(item.tareaConsumoId).then(function (res) {
                        if (res.status == 1) {
                          var ttTarea = res.data;
                          var ttPadre = ttTarea.ttPadre;
                          if (ttPadre.observaciones != null) {
                            try {
                              ttPadre.observaciones = JSON.parse(ttPadre.observaciones);
                            } catch (error) { }
                          } else {
                            ttPadre.observaciones = {};
                          }
                          ttTarea.ttPadre = ttPadre;
                          var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.resolucionConsumoFamiliar(ttTarea, strDia, strAnio, $scope.loginData.usuario, true, $scope.dataEmpresa, $scope.loginData.sigla);
                          utilsService.generarCedulaNotificacionConsumo(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    case 62:
                      tareaService.getTareaSubregionalVentaConsumo(item.tareaConsumoId).then(function (res) {
                        if (res.status == 1) {
                          var data = res.data;
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.resolucionVentaConsumoFamiliar(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                          var notas = data.ttConsumoVenta.correlativos;
                          for (var i = 0; i < notas.length; i++) {
                            var row = notas[i];
                            if (row.entregado == 1) {
                              var especies = row.especies;
                              var total = 0;
                              for (var j = 0; j < especies.length; j++) {
                                var especie = especies[j];
                                total += parseFloat(especie.total);
                              }
                              total = parseFloat(total).toFixed(4);
                              var strPartesTotal = total.toString().split('.');
                              var entero = parseInt(strPartesTotal[0]);
                              var decimal = parseInt(strPartesTotal[1]);
                              var strEntero = $scope.numeroALetras(entero, true);
                              var strDecimal = $scope.numeroALetras(decimal, true);
                              var strTotal = strEntero;
                              if (decimal > 0) {
                                if (entero == 1) {
                                  strTotal = 'uno';
                                }
                                strTotal = strTotal + ' punto ' + strDecimal;
                              }
                              strTotal = strTotal.toLowerCase() + ' metros cúbicos ';
                              strTotal = strTotal.substring(0, 1).toUpperCase() + strTotal.substring(1);
                              utilsService.generarNotaEnvioConsumo(data, row.numero, strTotal, $scope.dataEmpresa);
                            }
                          }
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                      break;
                    default:
                      toastr.info('No hay una opción de impresión para esta tarea');
                      break;
                  }
                }

                $scope.verAnexosInformeExento = function (item) {
                  if (item.fechaElaboracion == null) {
                    toastr.error('Los informes agregados en carga inicial o sin finalizar su elaboración no pueden mostrar anexos');
                    return;
                  }
                  ngDialog.open({
                    template: 'app/tarea/tarea.listaanexos.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    resolve: {

                    },
                    controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService',
                      function ($scope, $state, toastr, departamentoService, municipioService) {

                        exentoService.getListaAnexosInforme(item.informeVentaId).then(function (res) {
                          if (res.status == 1) {
                            $scope.anexos = res.data;
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });

                        $scope.verAnexoInforme = function (item) {
                          exentoService.getAnexoInforme(item.anexoInformeVentaId);
                        }
                      }]
                  })
                }

                $scope.generarSolicitudActualizacionExentoDeTarea = function (item) {
                  exentoService.getActualizacionDeTarea(item.tareaExentoId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      data.fechaSolicitud = new Date(data.fechaSolicitud);
                      utilsService.generarSolicitudActualizacionExento(data, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.saldoExento = function (item) {
                  ngDialog.open({
                    template: 'app/exento/saldo.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueMunicipio',
                    name: 'dialogMunicipio',
                    resolve: {
                      dataExento: ['exentoService',
                        function (exentoService) {
                          return exentoService.getSaldo(item.ttExento.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataExento',
                      function ($scope, $state, toastr, dataExento) {

                        $scope.usuarioInterno = true;

                        if (dataExento.status == 1) {
                          $scope.dataSaldo = dataExento.data;
                        }

                      }]
                  })
                }

                $scope.mostrarSaldo = function (row) {
                  if (($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 7) && (row.tcTask.taskId == 31 || row.tcTask.taskId == 64)) {
                    return true;
                  }
                  return false;
                }

                $scope.generarSolicitudActualizacionExento = function (item) {
                  exentoService.getActualizacion(item.actualizacionExentoId).then(function (res) {
                    if (res.status == 1) {
                      var data = res.data;
                      data.fechaSolicitud = new Date(data.fechaSolicitud);
                      utilsService.generarSolicitudActualizacionExento(data, $scope.dataEmpresa);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.resolucionSolicitudActualizacion = function (item) {
                  tareaService.resolucionSolicitudActualizacionExento(item.actualizacionExentoId).then(function (res) {
                    if (res.status == 1) {
                      if (res.status == 1) {
                        var data = res.data;
                        var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                        if (ventas == null) {
                          ventas = [];
                        }
                        for (let i = 0; i < ventas.length; i++) {
                          var element = ventas[i];
                          element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                        }
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        data.observaciones = JSON.parse(data.observaciones);
                        if (data.ttActualizacionExento.tipoActualizacionId == 1) {
                          var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                          var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          if (data.aprobado == 1) {
                            data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                            data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                          }
                          utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        } else {
                          utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.etiquetaEstadoInforme = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'Elaborando';
                    case 2: return 'Enviado';
                    case 3: return 'En trámite';
                    case 4: return 'Aprobado';
                    case 5: return 'Enmienda resuelta';
                    case 6: return 'Esperando enmienda';
                    case 99: return 'Denegado';
                    default: return 'Anulado';
                  }
                }


                $scope.etiquetaEstadoActualizacionExento = function (estadoId) {
                  switch (estadoId) {
                    case 1: return 'En edición';
                    case 2: return 'En trámite';
                    case 3: return 'Autorizado';
                    case 99: return 'Denegado';
                    default: return 'Inactivo';
                  }
                }

                $scope.mostrarReasignacionExento = function (estadoId) {
                  if (estadoId == 1 && $scope.loginData.perfilId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.habilitarReasignarTarea = function (item) {
                  item.codigo = null;
                  item.observaciones = null;
                  if (item.dataPersona == null || item.dataPersona.length <= 0) {
                    tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                      if (res.status == "OK") {
                        item.mostrarReasignacion = true;
                        item.dataPersona = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  } else {
                    item.mostrarReasignacion = true;
                    item.dataPersona = res.data;
                  }
                }

                $scope.reasignarTareaExento = function (item) {
                  if (item.codigo ) {

                  }
                  swal({
                    title: 'Reasignar tarea: ' + item.tcTask.taskDesc,
                    text: "¿Está seguro (a) de realizar ésta acción? Esta acción no se podrá reversar",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        tareaService.reasignarTareaExento(item).then(function (res) {
                          if (res.status == 1) {
                            toastr.success(res.message);
                            ngDialog.close();
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.verHistorialExento = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.exento.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getHistorialExento(item.ttExento.exentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', '$filter', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, $filter, $timeout) {
                        if (dataHistorial.status == 'OK') {
                          var tmp = dataHistorial.data[0];
                          $scope.dataTarea = tmp.tareas;
                          $scope.dataVenta = tmp.ventas;
                          $scope.dataInforme = tmp.informes;
                          $scope.dataActualizaciones = tmp.actualizaciones;
                        }

                        $scope.verDocumentoTareaExento = function (item) {
                          $scope.imprimirTareaExento(item, null, $scope.row.ttExento.exentoId);
                        }

                        $scope.verSolicitudVenta = function (item) {
                          if (item.fechaRecepcion == null) {
                            toastr.error('Esta solicitud/resolución se agregó en la carga inicial, no se puede imprimir');
                            return;
                          }
                          $scope.imprimirSolicitudVenta(item);
                        }

                        $scope.verInformeVenta = function (item) {
                          if (item.fechaElaboracion == null) {
                            toastr.error('Los informes agregados por el (la) técnico (a) en carga inicial no se pueden imprimir');
                            return;
                          }
                          $scope.imprimirInformeTrimestral(item);
                        }

                      }]
                  });
                }

                $scope.abrirPlan = function (item) {
                  $state.go('index.plan.realizar', { gestionId: $base64.encode(item.gestionId) });
                }

              }]
          })
          .state('index.tarea.list', {
            url: '',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', 'authService', '$filter',
                function (tareaService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 1,
                    personaId: loginData.personaId,
                    elaboradorId: 0
                  }
                  if (loginData.perfilId == 8 || loginData.perfilId == 6) {
                    filtro.elaboradorId = loginData.regionId;
                  }
                  return tareaService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'uiGridConstants', 'toastr', '$filter', 'tareaService', 'utilsService', 'gestionService', '$timeout', 'ngDialog', 'authService', 'Upload', '$base64',
              function ($scope, $state, dataTarea, uiGridConstants, toastr, $filter, tareaService, utilsService, gestionService, $timeout, ngDialog, authService, Upload, $base64) {
                $scope.titulo = "asignadas";
                var now = new Date();
                var begin = $scope.addDays(now, -60);
                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1,
                  personaId: $scope.loginData.personaId,
                  elaboradorId: $scope.loginData.regionId
                }

                $scope.mostrarCSV = function () {
                  if ($scope.loginData.perfilId == 7) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarRecibir = function (recibido) {
                  if (recibido == 1) {
                    return false;
                  }
                  return true;
                }

                $scope.recibirTarea = function (tarea) {
                  $scope.ttTarea = tarea;
                  $scope.ttTarea.fechaRecepcion = new Date(tarea.fechaRegistro);
                  $scope.minDate = new Date(tarea.fechaRegistro);
                  $scope.maxDate = new Date();
                  $scope.row = {
                    ruta: null,
                    nombre: null
                  };
                  $scope.adjuntadoOK = false;
                  ngDialog.open({
                    template: 'app/tarea/recibir.tarea.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {},
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.submitFormRecibir = function (isValid) {
                          if (isValid) {
                            var tmp = $scope.data;
                            $scope.ttTarea.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            $scope.ttTarea.anexo = [];
                            if ($scope.row.ruta != null) {
                              $scope.ttTarea.anexo.push($scope.row);
                            }
                            var filtro = $scope.ttTarea;
                            tareaService.recibirTarea(filtro).then(function (res) {
                              if (res.status == "OK") {
                                ngDialog.close();
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }
                      }]
                  })
                }

                $scope.onFileSelect = function (file, id) {
                  Upload.upload({
                    url: appSettings.urlServiceBase + 'file/upload',
                    method: 'POST',
                    file: file,
                    sendFieldsAs: 'form',
                    fields: {
                      data: []
                    }
                  }).then(function (resp) {
                    var result = resp.data;
                    if (result.status == 'OK') {
                      var data = result.data[0];
                      $scope.row.ruta = data.rutaArchivo;
                      $scope.row.nombre = data.nombre;
                      $scope.row.size = data.size;
                      $scope.row.extension = data.extension;
                      $scope.adjuntadoOK = true;
                    } else {
                      toastr.error(resp.data.message)
                    }
                  }, function (resp) {
                    toastr.error('Error status: ' + resp.status);
                  }, function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.avance = progressPercentage;
                  });
                }

                $scope.descargarBoletaCampo = function (id) {
                  authService.getFile(id);
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.descripcionNUG = function (row) {
                  if (row.ttGestion == null) {
                    return row.ttExento.exentoId;
                  }
                  return row.ttGestion.nug;
                }

                $scope.solicitanteDesc = function (row) {
                  if (row.ttGestion == null) {
                    return row.ttExento.tcPersonaCrea.personaDesc;
                  }
                  return row.ttGestion.tcPersonaCrea.personaDesc;
                }

                $scope.expedienteDesc = function (row) {
                  if (row.ttGestion == null) {
                    return row.ttExento.codigo;
                  }
                  return row.ttGestion.expediente;
                }

                $scope.habilitarVerPlan = function () {
                  if ($scope.loginData.perfilId == 5 || $scope.loginData.perfilId == 7 || $scope.loginData.perfilId == 8) {
                    return true;
                  }
                  return false;
                }

                $scope.habilitarAnularProvidenciaEnmienda = function (row) {
                  if (row.tcTask.taskId == 15 && $scope.loginData.perfilId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' },
                  { field: 'ttGestion.tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                  { field: 'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '12%' },
                  { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 53 && row.entity.recibido == 1" ng-click="grid.appScope.habilitarSubirRequisitos(row.entity)" title="Habilitar para que propietario pueda subir de nuevo los requisitos"> <i class="fa fa-close text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="!grid.appScope.mostrarRecibir(row.entity.recibido)" ng-click="grid.appScope.realizarTarea(row.entity)" title="Realizar tarea"> <i class="fa fa-arrow-right"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarRecibir(row.entity.recibido)" ng-click="grid.appScope.recibirTarea(row.entity)" title="Haga clic para recibir la tarea"> <i class="fa fa-download"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarCSV()" ng-click="grid.appScope.generarBoleta(row.entity)" title="Descargar boleta de inventario"> <i class="fa fa-database"></i> </button> '
                      + ' <button type="button" class="btn btn-info btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.verHistorial(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.habilitarVerPlan()" ng-click="grid.appScope.abrirPlan(row.entity.ttGestion)" title="Abrir plan de manejo"> <i class="fa fa-newspaper-o"></i> </button> '
                      + ' <button type="button" class="btn btn-defaul btn-xs" ng-show="grid.appScope.habilitarCambiarAval(row.entity)" ng-click="grid.appScope.cambiarAvalToDictamen(row.entity)" title="Cambiar tarea de AVAL a dictamen subregional"> <i class="text-danger fa fa-exchange"></i> </button> '
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.habilitarAnularProvidenciaEnmienda(row.entity)" ng-click="grid.appScope.anularProvidenciaEnmienda(row.entity)" title="Anular tarea y cancelar la recepción de enmiendas"> <i class="fa fa-close"></i> </button> '
                      + '</span></div>'
                  }
                ];
                if (dataTarea.status == 'OK') {
                  $scope.gridOptions.data = dataTarea.data;
                } else {
                  $scope.gridOptions.data = [];
                }

                $scope.habilitarSubirRequisitos = function (item) {
                  swal({
                    title: "Esta acción no se podrá reversar, es decir no se podrá regresar la tarea",
                    text: "¿Está seguro de habilitar de nuevo la carga de requisitos? Presione en confirmar si si o en cancelar si no está seguro",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        tareaService.habilitarSubirRequisitos(item.tareaId).then(function (res) {
                          if (res.status == 1) {
                            $scope.submitForm(true);
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.generarBoleta = function (row) {
                  gestionService.getGestionData(row.ttGestion.gestionId).then(function (res) { //obtener datos originales del inventario
                    if (res.status == "OK") {
                      var data = res.data[0];
                      try {
                        var data = JSON.parse(data.data);
                        var createXLSLFormatObj = [];
                        /* XLS Head Columns */
                        var xlsHeader = ["TURNO", "RODAL", "AREA_RODAL", "PARCELA", "TAMANIO_PARCELA", "NO", "DAP", "ALTURA", "NOMBRE_CIENTIFICO", "VOLUMEN", "AREA_BASAL", "CALIDAD_FUSTE", "X", "Y"];
                        /* XLS Rows Data */
                        var xlsRows = [];
                        for (var i = 0; i < data.length; i++) {
                          var item = {
                            TURNO: data[i].TURNO,
                            RODAL: data[i].RODAL,
                            AREA_RODAL: data[i].AREA_RODAL,
                            PARCELA: data[i].PARCELA,
                            TAMANIO_PARCELA: data[i].TAMANIO_PARCELA,
                            NO: data[i].NO,
                            DAP: data[i].DAP,
                            ALTURA: data[i].ALTURA,
                            NOMBRE_CIENTIFICO: data[i].NOMBRE_CIENTIFICO,
                            VOLUMEN: data[i].VOLUMEN,
                            AREA_BASAL: data[i].AREA_BASAL,
                            CALIDAD_FUSTE: data[i].CALIDAD_FUSTE,
                            X: data[i].X,
                            Y: data[i].Y
                          }
                          xlsRows.push(item);
                        }
                        createXLSLFormatObj.push(xlsHeader);
                        $.each(xlsRows, function (index, value) {
                          var innerRowData = [];
                          $("tbody").append('<tr><td>' + value.TURNO + '</td><td>' + value.RODAL + '</td><td>' + value.AREA_RODAL + '</td><td>' + value.PARCELA + '</td><td>' + value.TAMANIO_PARCELA + '</td><td>' + value.NO + '</td><td>' + value.DAP + '</td><td>' + value.ALTURA + '</td><td>' + value.NOMBRE_CIENTIFICO + '</td><td>' + value.VOLUMEN + '</td><td>' + value.AREA_BASAL + '</td><td>' + value.CALIDAD_FUSTE + '</td><td>' + value.X + '</td><td>' + value.Y + '</td></tr>');
                          $.each(value, function (ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                        });


                        /* File Name */
                        var filename = "boleta.xlsx";

                        /* Sheet Name */
                        var ws_name = "data";

                        if (typeof console !== 'undefined') console.log(new Date());
                        var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

                        /* Add worksheet to workbook */
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);

                        /* Write workbook and Download */
                        if (typeof console !== 'undefined') console.log(new Date());
                        XLSX.writeFile(wb, filename);
                        if (typeof console !== 'undefined') console.log(new Date());

                      } catch (e) {
                        toastr.error(e.message);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 1,
                      personaId: $scope.loginData.personaId
                    }
                    if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 6) {
                      filtro.elaboradorId = $scope.loginData.regionId;
                    }
                    tareaService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.ttGestion.gestionId);
                        }],
                      dataAnexoSuspension: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexoSuspension(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService', 'dataAnexoSuspension',
                      function ($scope, $state, toastr, dataAnexo, gestionService, dataAnexoSuspension) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.dataAnexoSuspension = dataAnexoSuspension.data;

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                        $scope.abrirAnexoSuspension = function (anexoSuspensionId) {
                          gestionService.getAnexoSuspension(anexoSuspensionId);
                        }

                      }]
                  });
                }

                $scope.verHistorial = function (item) {
                  if (item.ttExento != null) {
                    toastr.success('Opción solo para trámites de licencia');
                  }
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.tarea.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getGestionHistorial(item.tareaId, item.ttGestion.gestionId, item.ttGestion.tcSubregion.subregionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {

                        $scope.dataHistorial = dataHistorial.data;

                        $scope.verDocumento = function (item) {
                          $scope.verDocumentoTareaLicencia(item, $scope.row);
                        }

                      }]
                  });
                }

                $scope.habilitarCambiarAval = function (row) {
                  if (row.estadoId == 1 && row.tcTask.taskId == 16 && $scope.loginData.perfilId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.cambiarAvalToDictamen = function (item) {
                  swal({
                    title: "Esta acción no se podrá reversar, es decir no se podrá regresar la tarea",
                    text: "¿Está seguro (a) de cambiar de tarea? Presione en confirmar si si o en cancelar si no está seguro",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        tareaService.cambiarAvalToDictamen(item.tareaId).then(function (res) {
                          if (res.status == 1) {
                            $scope.submitForm(true);
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.anularProvidenciaEnmienda = function (item) {
                  swal({
                    title: "Esta acción no se podrá reversar, es decir no se podrá regresar la tarea",
                    text: "¿Está seguro (a) de cambiar anular la tarea y habilitar la enmienda? Presione en confirmar si si o en cancelar si no está seguro",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        tareaService.anularRecepcionEnmiendas(item.tareaId).then(function (res) {
                          if (res.status == 1) {
                            $scope.submitForm(true);
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.realizarTarea = function (row) {
                  switch (row.tcTask.taskId) {
                    case 53: //recibir requisitos turno licencia
                      swal({
                        title: "Confirmación",
                        text: "¿Está seguro (a) de recibir los requisitos correspondiente la licencia del turno y notificar al subregional? \nEsta acción no se podrá reversar",
                        icon: "warning",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonClass: "btn-success",
                        cancelButtonClass: "btn-danger",
                        confirmButtonText: "Confirmar",
                        cancelButtonText: "Cancelar",
                        closeOnConfirm: true,
                        closeOnCancel: true
                      },
                        function (isConfirm) {
                          if (isConfirm) {
                            tareaService.recibirRequisitosTurno(row.ttGestion.gestionId).then(function (res) {
                              if (res.status == 1) {
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        });
                      break;
                    default:
                      var stateToGo = null;
                      for (var i = 0; i < $scope.dataTask.length; i++) {
                        if ($scope.dataTask[i].taskId == row.tcTask.taskId) {
                          stateToGo = $scope.dataTask[i].stateGo;
                        }
                      }
                      if (stateToGo != null) {
                        $scope.globales.task = row;
                        authService.removeLocalData('tareaPadreId');
                        authService.removeLocalData('gestionId');
                        authService.removeLocalData('dataOriginal');
                        authService.removeLocalData('tipoInventarioId');
                        authService.removeLocalData('origenCalculoId');
                        authService.removeLocalData('esVerificacion');
                        authService.saveLocalData('tareaPadreId', row.tareaPadreId);
                        authService.saveLocalData('gestionId', row.ttGestion.gestionId);
                        $state.go(stateToGo, { tareaId: $base64.encode(row.tareaId) });
                      }
                      break;
                  }
                }

                $scope.mostrarSolicitudCambioNota = true;

                $scope.verSolicitudesCambioNota = function () {
                  ngDialog.open({
                    template: 'app/solicitud/nota.list.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataSolicitud: ['gestionService',
                        function (gestionService) {
                          return gestionService.listaSolicitudCambioNotaSubregion(2);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataSolicitud', 'gestionService',
                      function ($scope, $state, toastr, dataSolicitud, gestionService) {

                        $scope.solicitud = {};

                        $scope.deshabilitar = true;

                        $scope.textoBoton = 'Guardar';

                        $scope.etiquetaEstado = function (estadoId) {
                          switch (estadoId) {
                            case 1: return 'Enviado';
                            case 2: return 'En trámite';
                            case 3: return 'Finalizado';
                            default: return 'Anulado';
                          }
                        }

                        $scope.dataEstadoCambioNota =
                          [
                            { estadoId: 2, estadoDesc: 'En trámite' },
                            { estadoId: 3, estadoDesc: 'Finalizado' }
                          ];

                        $scope.estadoSolicitudNotaId = 2;

                        $scope.gridLista = angular.copy($scope.gridOptionsSelection);
                        $scope.gridLista.columnDefs = [
                          { field: 'cambioNotaId', name: 'cambioNotaId', displayName: 'No. Solicitud', width: '10%', align: "right" },
                          { field: 'tcSolicitante.personaDesc', name: 'personaDesc', displayName: 'Solicitante' },
                          { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha solicitud', type: 'date', width: '15%', cellFilter: 'date:\'dd/MM/yyyy\'' },
                          { field: 'cantidad', name: 'cantidad', displayName: 'Total solicitado', width: '10%' },
                          {
                            name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '15%',
                            cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                          },
                          {
                            name: 'Opciones', enableFiltering: false, width: '8%',
                            cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                              + ' <button type="button" class="btn btn-danger btn-xs" ng-show="row.entity.estadoId == 2" ng-click="grid.appScope.recibirSolicitudCambioNota(row.entity)" title="Realizar tarea"> <i class="fa fa-arrow-right"></i> </button> '
                              + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId == 3" ng-click="grid.appScope.imprimirResolucionCambioNota(row.entity)" title="Imprimir resolución"> <i class="zmdi zmdi-print text-blue"></i> </button> '
                              + '</span></div>'
                          }
                        ];

                        $scope.gridLista.data = [];
                        if (dataSolicitud.status == 1) {
                          $scope.gridLista.data = dataSolicitud.data;
                        } else {
                          toastr.error(dataSolicitud.message);
                        }

                        $scope.setEstadoCambioNota = function (item) {
                          gestionService.listaSolicitudCambioNotaSubregion(item.estadoId).then(function (res) {
                            if (res.status == 1) {
                              $scope.gridLista.data = res.data;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.cerrarVentana = function () {
                          ngDialog.close();
                        }

                        $scope.recibirSolicitudCambioNota = function (row) {
                          gestionService.getSolicitudCambioNota(row.cambioNotaId).then(function (res) {
                            if (res.status == 1) {
                              $scope.solicitud = res.data;
                              $scope.mostrarDatos = true;
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.imprimirResolucionCambioNota = function (ttCambioNota) {
                          gestionService.getSolicitudCambioNota(ttCambioNota.cambioNotaId).then(function (res) {
                            if (res.status == 1) {
                              var data = res.data;
                              data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                              var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                              var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                              var strDia = $scope.numeroALetras(dia);
                              var strAnio = $scope.numeroALetras(anio, false);
                              utilsService.generarResolucionCambioNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              $scope.cerrarVentana();
                            } else {
                              toastr.error(res.message);
                            }
                          }, function (error) {
                            toastr.error(error);
                          });
                        }

                        $scope.submitFormSolicitud = function (isConfirm) {
                          if (isConfirm) {
                            swal({
                              title: "Confirmación",
                              text: "¿Está seguro (a) de recibir la solicitud? \nEsta acción no se podrá reversar (no hay regreso de tarea), asegúrese de revisar los correlativos y resolución de venta sean correctos",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                              function (isConfirm) {
                                if (isConfirm) {
                                  var ttCambioNota = angular.copy($scope.solicitud);
                                  delete ttCambioNota.rangos;
                                  delete ttCambioNota.notas;
                                  gestionService.finalizarSolicitudCambioNota(ttCambioNota).then(function (response) {
                                    if (response.status == 1) {
                                      $scope.imprimirResolucionCambioNota(ttCambioNota);
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              });
                          }
                        }

                        $scope.cancelarRecepcion = function () {
                          $scope.solicitud = {};
                          $scope.mostrarDatos = false;
                        }

                        $scope.vistaPreviaCambioNota = function () {
                          if ($scope.solicitud.resolucion == null) {
                            toastr.error('Debe indicar el número de resolución de la sustitución');
                            return;
                          }
                          $scope.solicitud.fechaFinalizacion = new Date();
                          var dia = $filter('date')($scope.solicitud.fechaFinalizacion, 'dd');
                          var anio = $filter('date')($scope.solicitud.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.setEsPrevia(true);
                          utilsService.generarResolucionCambioNota($scope.solicitud, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }

                        $scope.anularSolicitudCambioNota = function (row) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro (a) de anular la solicitud? \nEsta acción no se podrá reversar (no hay regreso de tarea)",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                var ttCambioNota = angular.copy($scope.solicitud);
                                delete ttCambioNota.rangos;
                                gestionService.anularSolicitudCambioNota(ttCambioNota).then(function (res) {
                                  if (res.status == 1) {
                                    $scope.cerrarVentana();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            });
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.tarea.fin', {
            url: '/fin',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', 'authService', '$filter',
                function (tareaService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 30);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 2,
                    personaId: loginData.personaId,
                    elaboradorId: 0
                  }
                  if (loginData.perfilId == 8 || loginData.perfilId == 6) {
                    filtro.elaboradorId = loginData.regionId;
                  }
                  return tareaService.listado(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'uiGridConstants', 'toastr', '$filter', 'tareaService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
              function ($scope, $state, dataTarea, uiGridConstants, toastr, $filter, tareaService, utilsService, gestionService, $timeout, ngDialog) {
                $scope.titulo = "finalizadas";
                var now = new Date();
                var begin = $scope.addDays(now, -30);

                $scope.esSubregional = function () {
                  if ($scope.loginData.perfilId == 5) {
                    return true;
                  }
                  return false;
                }

                $scope.dataEstado = [
                  { estadoId: 1, estadoDesc: 'Asignado' },
                  { estadoId: 2, estadoDesc: 'Finalizado' }
                ];

                $scope.mostrarFiltroExpediente = true;

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2,
                  personaAsignadaId: $scope.loginData.personaId,
                  subregionId: $scope.loginData.subregionId,
                  perfilId: $scope.loginData.perfilId,
                  usuarioId: $scope.loginData.usuarioId
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '12%' },
                  { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Finalización', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'ttGestion.tcPlanTipoGestion.planTipoGestionDesc', name: 'planTipoGestionDesc', displayName: 'Gestión' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="zmdi zmdi-print text-blue"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verDocumento(row.entity)" title="Imprimir documento de esta tarea"> <i class="fa fa-file-pdf-o text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o text-green"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.visibleHistorial()" ng-click="grid.appScope.verHistorial(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol text-warning"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.esSubregional()" ng-click="grid.appScope.cancelarProvidencia(row.entity)" title="Cancelar tarea técnica o jurídica activa y asignar a una nueva a otra persona"> <i class="fa fa-plug text-red"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.esSubregional()" ng-click="grid.appScope.cancelarEnmienda(row.entity)" title="Cancelar enmienda notificada y providenciar"> <i class="fa fa-bomb"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-click="grid.appScope.abrirPlan(row.entity.ttGestion)" title="Abrir plan de manejo"> <i class="fa fa-newspaper-o"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataTarea.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 2,
                      personaId: $scope.loginData.personaId
                    }
                    if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 6) {
                      filtro.elaboradorId = $scope.loginData.regionId;
                    }
                    tareaService.listado(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    tareaService.buscarPorExpediente($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.visibleHistorial = function () {
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 5) {
                    return true;
                  } else {
                    return false;
                  }
                }

                $scope.verHistorial = function (item) {
                  if (item.ttExento != null) {
                    toastr.success('Opción solo para trámites de licencia');
                  }
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.tarea.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getGestionHistorial(item.tareaId, item.ttGestion.gestionId, item.ttGestion.tcSubregion.subregionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                        var data = dataHistorial.data;
                        $scope.permitirRegresar = true;
                        $scope.noHayResolucion = true;

                        var ttTareaTecnico = { tareaId: 0 };
                        var ttTareaJuridico = { tareaId: 0 };
                        for (var i = 0; i < data.length; i++) {
                          var row = data[i];
                          if (row.tcTask.taskId == 3 && row.tareaId > ttTareaJuridico.tareaId) {
                            ttTareaJuridico = row;
                          }
                          if (row.tcTask.taskId == 4 && row.tareaId > ttTareaTecnico.tareaId) {
                            ttTareaTecnico = row;
                          }
                          if ((row.tcTask.taskId == 5 && row.estadoId != 0) || (row.tcTask.taskId == 16 && row.estadoId != 0)) {
                            $scope.permitirRegresar = false;
                          }
                          if ((row.tcTask.taskId == 6 && row.estadoId != 0) || (row.tcTask.taskId == 46 && row.estadoId != 0)) {
                            $scope.noHayResolucion = false;
                          }
                          row.esUltimo = false;
                        }
                        for (var i = 0; i < data.length; i++) {
                          var row = data[i];
                          if (row.tareaId == ttTareaTecnico.tareaId) {
                            row.esUltimo = true;
                          }
                          if (row.tareaId == ttTareaJuridico.tareaId) {
                            row.esUltimo = true;
                          }
                        }
                        $scope.dataHistorial = data;

                        $scope.visibleRegresar = function (row) {
                          if (row.esUltimo && row.estadoId == 2 && row.esEnmienda == 0 && $scope.permitirRegresar && $scope.loginData.perfilId == 5) {
                            return true;
                          }
                          return false;
                        }

                        $scope.visibleCambioArea = function (row) {
                          if (row.esUltimo && row.estadoId == 2 && row.esEnmienda == 1 && $scope.noHayResolucion && $scope.loginData.perfilId == 5
                            && row.tcTask.taskId == 4 && row.modificarArea == 0) {
                            return true;
                          }
                          return false;
                        }

                        $scope.verDocumento = function (item) {
                          if (item.estadoId == 1) {
                            toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                            return;
                          }
                          if (item.estadoId == 20) {
                            toastr.error('Esta tarea está anulada, no hay documento que imprimir');
                            return;
                          }
                          $scope.verDocumentoTareaLicencia(item, $scope.row);
                        }

                        $scope.habilitarTarea = function (item) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro (a) de realizar este cambio? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                tareaService.habilitarTarea(item.tareaId).then(function (res) {
                                  if (res.status == 1) {
                                    $scope.cerrarVentana();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            });
                        }

                        $scope.habilitarCambioArea = function (item) {
                          swal({
                            title: "Confirmación",
                            text: "¿Está seguro (a) de realizar este cambio? \nEsta acción no se podrá reversar, es decir no hay regreso de tarea",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                            function (isConfirm) {
                              if (isConfirm) {
                                tareaService.habilitarCambioArea(item.tareaId).then(function (res) {
                                  if (res.status == 1) {
                                    $scope.cerrarVentana();
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            });
                        }

                      }]
                  });
                }

                $scope.setDataJuridico = function (data) {
                  $scope.dataJuridico = data;
                }

                $scope.setDataTecnico = function (data) {
                  $scope.dataTecnico = data;
                }

                $scope.providenciarPersona = function (item) {
                  if (item.cambiar) {
                    $scope.mostrarReasignar = true;
                    if (item.dataPersona == null) {
                      if (item.ttTarea.tcTask.taskId == 3) {
                        item.dataPersona = $scope.dataJuridico;
                      }
                      if (item.ttTarea.tcTask.taskId == 4) {
                        item.dataPersona = $scope.dataTecnico;
                      }
                    }
                  }
                  $scope.ttEnmienda = item;
                }

                $scope.cancelarEnmienda = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/cancelar.enmienda.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataEnmienda: ['tareaService',
                        function (tareaService) {
                          return tareaService.getEnmiendaActiva(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', 'toastr', 'dataEnmienda',
                      function ($scope, toastr, dataEnmienda) {
                        $scope.dataEnmienda = [];
                        if (dataEnmienda.status == 'OK') {
                          if (dataEnmienda.data.length > 0) {
                            $scope.dataEnmienda = dataEnmienda.data;
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 6).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.setDataJuridico(res.data);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                            if ($scope.dataTecnico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.setDataTecnico(res.data);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          } else {
                            swal({
                              title: "¡Atención!",
                              text: "No hay enmiendas técnicas y/o jurídicas activas",
                              icon: "error",
                              type: "error",
                              button: "Cerrar",
                            }, function (isConfirm) {
                              ngDialog.close();
                            });
                          }
                        }

                        $scope.providenciarEnmiendaCancelada = function () {
                          if ($scope.providencia == null || $scope.observaciones == null) {
                            swal({
                              title: "¡Datos inconsistentes!",
                              text: "Debe indicar el número de providencia y la justificación del cambio",
                              icon: "error",
                              type: "error"
                            });
                            return;
                          } else {
                            if ($scope.providencia == "" || $scope.observaciones == "") {
                              swal({
                                title: "¡Datos inconsistentes!",
                                text: "Debe indicar el número de providencia y la justificación del cambio",
                                icon: "error",
                                type: "error"
                              });
                            } else {
                              swal({
                                title: "Confirmación",
                                text: "¿Está seguro (a) de guardar la información? \nEsta acción no se podrá reversar",
                                icon: "warning",
                                type: "warning",
                                showCancelButton: true,
                                confirmButtonClass: "btn-success",
                                cancelButtonClass: "btn-danger",
                                confirmButtonText: "Confirmar",
                                cancelButtonText: "Cancelar",
                                closeOnConfirm: true,
                                closeOnCancel: true
                              },
                                function (isConfirm) {
                                  if (isConfirm) {
                                    var data = angular.copy($scope.ttEnmienda);
                                    data.ttTarea.codigo = $scope.providencia;
                                    data.ttTarea.observaciones = $scope.observaciones;
                                    tareaService.cancelarEnmienda(data).then(function (resp) {
                                      if (resp.status == 1) {
                                        ngDialog.close();
                                        var tareaId = parseFloat(resp.data);
                                        tareaService.get(tareaId).then(function (res) {
                                          if (res.status == "OK") {
                                            var data = res.data[0];
                                            dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                            anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                            strDia = $scope.numeroALetras(dia);
                                            strAnio = $scope.numeroALetras(anio, false);
                                            utilsService.generarProvidenciaTrasladoCancelarEnmienda(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                          } else {
                                            toastr.error(res.message);
                                          }
                                        }, function (error) {
                                          toastr.error(error);
                                        });
                                        $scope.submitForm(true);
                                      } else {
                                        toastr.error(resp.message);
                                      }
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  }
                                });
                            }
                          }
                        }

                      }]
                  });
                }

                $scope.dataJuridico = [];
                $scope.dataTecnico = [];
                $scope.mostrarReasignar = false;

                $scope.cambiarPersona = function (item) {
                  if (item.cambiar) {
                    $scope.mostrarReasignar = true;
                    if (item.dataPersona == null) {
                      if (item.tcTask.taskId == 3) {
                        item.dataPersona = $scope.dataJuridico;
                      }
                      if (item.tcTask.taskId == 4) {
                        item.dataPersona = $scope.dataTecnico;
                      }
                    }
                  }
                }

                $scope.cancelarProvidencia = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/reasignar.tarea.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getProvidenciaActiva(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                        $scope.dataHistorial = [];
                        if (dataHistorial.status == 'OK') {
                          if (dataHistorial.data.length > 0) {
                            $scope.dataHistorial = dataHistorial.data;
                            if ($scope.dataJuridico.length <= 0) {
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 6).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.setDataJuridico(res.data);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                              tareaService.getUsuarioSubregionPerfil($scope.loginData.subregionId, 7).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.setDataTecnico(res.data);
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }
                          } else {
                            swal({
                              title: "¡Atención!",
                              text: "No hay tareas técnicas y/o jurídicas activas para reasignar",
                              icon: "error",
                              type: "error",
                              button: "Cerrar",
                            }, function (isConfirm) {
                              ngDialog.close();
                            });
                          }
                        }

                        $scope.reasignarTarea = function () {
                          var continuar = false;
                          var tecnico = null, juridico = null;
                          for (var i = 0; i < $scope.dataHistorial.length; i++) {
                            if ($scope.dataHistorial[i].cambiar) {
                              if ($scope.dataHistorial[i].tcTask.taskId == 3) {
                                juridico = $scope.dataHistorial[i];
                              }
                              if ($scope.dataHistorial[i].tcTask.taskId == 4) {
                                tecnico = $scope.dataHistorial[i];
                              }
                              continuar = true;
                            }
                          }
                          if (continuar) {
                            if ($scope.providencia == null || $scope.observaciones == null) {
                              swal({
                                title: "¡Datos inconsistentes!",
                                text: "Debe indicar el número de providencia y la justificación del cambio",
                                icon: "error",
                                type: "error"
                              });
                              return;
                            } else {
                              if ($scope.providencia == "" || $scope.observaciones == "") {
                                swal({
                                  title: "¡Datos inconsistentes!",
                                  text: "Debe indicar el número de providencia y la justificación del cambio",
                                  icon: "error",
                                  type: "error"
                                });
                              } else {
                                var data = {
                                  providencia: $scope.providencia,
                                  observaciones: $scope.observaciones,
                                  tecnico: tecnico,
                                  juridico: juridico
                                }
                                tareaService.reasignarTarea(data).then(function (resp) {
                                  if (resp.status == "OK") {
                                    ngDialog.close();
                                    var tareaId = parseFloat(resp.singleValue);
                                    tareaService.get(tareaId).then(function (res) {
                                      if (res.status == "OK") {
                                        var data = res.data[0];
                                        dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                        anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                        strDia = $scope.numeroALetras(dia);
                                        strAnio = $scope.numeroALetras(anio, false);
                                        utilsService.generarProvidenciaTraslado(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                      } else {
                                        toastr.error(res.message);
                                      }
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                    $scope.submitForm(true);
                                  } else {
                                    toastr.error(resp.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }
                          }
                        }

                      }]
                  });
                }

                $scope.verDocumento = function (item) {
                  if (item.estadoId == 1) {
                    toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                    return;
                  }
                  if (item.estadoId == 20) {
                    toastr.error('Esta tarea está anulada, no hay documento que imprimir');
                    return;
                  }
                  $scope.verDocumentoTareaLicencia(item, $scope.row);
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/gestion/anexo.gestion.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexo(item.ttGestion.gestionId);
                        }],
                      dataAnexoSuspension: ['gestionService',
                        function (gestionService) {
                          return gestionService.getGestionAnexoSuspension(item.ttGestion.gestionId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService', 'dataAnexoSuspension',
                      function ($scope, $state, toastr, dataAnexo, gestionService, dataAnexoSuspension) {
                        $scope.dataAnexo = dataAnexo.data;
                        $scope.dataAnexoSuspension = dataAnexoSuspension.data;

                        $scope.abrirAnexo = function (anexoGestionId) {
                          gestionService.getAnexoGestion(anexoGestionId);
                        }

                        $scope.abrirAnexoSuspension = function (anexoSuspensionId) {
                          gestionService.getAnexoSuspension(anexoSuspensionId);
                        }

                      }]
                  });
                }

              }]
          })
          .state('index.tarea.exentolist', {
            url: '/exentolist',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', 'authService', '$filter',
                function (tareaService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 60);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 1,
                    personaId: loginData.personaId,
                    elaboradorId: 0
                  }
                  if (loginData.perfilId == 8 || loginData.perfilId == 6) {
                    filtro.elaboradorId = loginData.regionId;
                  }
                  return tareaService.listadoExento(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'uiGridConstants', 'toastr', '$filter', 'tareaService', 'utilsService', 'exentoService',
              '$timeout', 'ngDialog', 'authService', 'Upload', '$base64',
              function ($scope, $state, dataTarea, uiGridConstants, toastr, $filter, tareaService, utilsService, exentoService,
                $timeout, ngDialog, authService, Upload, $base64) {
                $scope.titulo = "asignadas de exentos";
                var now = new Date();
                var begin = $scope.addDays(now, -60);
                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1,
                  personaId: $scope.loginData.personaId,
                  elaboradorId: $scope.loginData.regionId
                }

                $scope.mostrarRecibir = function (recibido) {
                  if (recibido == 1) {
                    return false;
                  }
                  return true;
                }

                $scope.mostrarProgramar = function (row) {
                  if (row.recibido == 1 && row.estadoId == 1
                    && $scope.loginData.perfilId == 7 && row.tcTask.taskId == 27) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAnexo = function (row) {
                  if (row.tcTask.taskId > 28) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarActualizar = function (row) {
                  if (row.ttExento.actualizar == 1 && (row.tcTask.taskId == 22 || row.tcTask.taskId == 23)
                    && row.ttExento.boleta != null) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAnularSolicitudVenta = function (row) {
                  if (row.estadoId == 1 && row.tcTask.taskId == 26) {
                    return true;
                  }
                  return false;
                }

                $scope.recibirTarea = function (tarea) {
                  $scope.ttTarea = tarea;
                  $scope.ttTarea.fechaRecepcion = new Date(tarea.fechaRegistro);
                  $scope.minDate = new Date(tarea.fechaRegistro);
                  $scope.maxDate = new Date();
                  $scope.row = {
                    ruta: null,
                    nombre: null
                  };
                  $scope.adjuntadoOK = false;
                  ngDialog.open({
                    template: 'app/tarea/recibir.tarea.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {},
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.submitFormRecibir = function (isValid) {
                          if (isValid) {
                            var tmp = $scope.data;
                            $scope.ttTarea.tcPersonaAsignada.personaId = $scope.loginData.personaId;
                            $scope.ttTarea.anexo = [];
                            if ($scope.row.ruta != null) {
                              $scope.ttTarea.anexo.push($scope.row);
                            }
                            var filtro = $scope.ttTarea;
                            tareaService.recibirTareaExento(filtro).then(function (res) {
                              if (res.status == "OK") {
                                ngDialog.close();
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }
                      }]
                  })
                }

                $scope.programarInspeccion = function (tarea) {
                  $scope.ttTarea = tarea;
                  $scope.ttTarea.ttVentaExento = {
                    fechaInspeccion: new Date()
                  }
                  $scope.ttTarea.ttVentaExento.fechaInspeccion = new Date();
                  var fin = new Date();
                  fin = $scope.addDays(fin, 60);
                  $scope.minDate = new Date();
                  $scope.maxDate = fin;
                  ngDialog.open({
                    template: 'app/tarea/programar.tarea.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {},
                    controller: ['$scope', '$state', 'toastr',
                      function ($scope, $state, toastr) {

                        $scope.submitFormRecibir = function (isValid) {
                          if (isValid) {
                            var filtro = $scope.ttTarea;
                            tareaService.programarTareaExento(filtro).then(function (res) {
                              if (res.status == "OK") {
                                ngDialog.close();
                                $scope.submitForm(true);
                              } else {
                                toastr.error(res.message);
                              }
                            }, function (error) {
                              toastr.error(error);
                            });
                          }
                        }
                      }]
                  })
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttExento.tcPersonaCrea.personaDesc', name: 'personaSolicita', displayName: 'Solicitante' },
                  { field: 'ttExento.codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                  { field: 'ttExento.expediente', name: 'expediente', displayName: 'Expediente', width: '12%' },
                  { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="!grid.appScope.mostrarRecibir(row.entity.recibido)" ng-click="grid.appScope.realizarTarea(row.entity)" title="Realizar tarea"> <i class="fa fa-arrow-right"></i> </button> '
                      + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.mostrarRecibir(row.entity.recibido)" ng-click="grid.appScope.recibirTarea(row.entity)" title="Haga clic para recibir la tarea"> <i class="fa fa-download"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarProgramar(row.entity)" ng-click="grid.appScope.programarInspeccion(row.entity)" title="Programar inspección de campo"> <i class="fa fa-calendar-check-o"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarActualizar(row.entity)" ng-click="grid.appScope.getArchivoActualizacion(row.entity)" title="Descargar cuadro de solicitud de actualización"> <i class="fa fa-database"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAnularSolicitudVenta(row.entity)" ng-click="grid.appScope.anularSolicitudVenta(row.entity)" title="Anular solicitud de venta"> <i class="fa fa-close text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.verHistorialExento(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 33 || row.entity.tcTask.taskId == 66" ng-click="grid.appScope.generarSolicitudActualizacionExentoDeTarea(row.entity)" title="Imprimir solicitud de actualización"><i class="fa fa-file-pdf-o text-red" aria-hidden="true"></i></button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 64 || row.entity.tcTask.taskId == 33" ng-click="grid.appScope.descargarInventarioActualizacion(row.entity)" title="Descargar boleta de inventario"> <i class="fa fa-database"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarSaldo(row.entity)" ng-click="grid.appScope.saldoExento(row.entity)" title="Saldo disponible"><i class="fa fa-tree fa-lg text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 33 || row.entity.tcTask.taskId == 64 || row.entity.tcTask.taskId == 66" ng-click="grid.appScope.descargarCopiaCertificado(row.entity)" title="Descargar copia del certificado"><i class="fa fa-copy text-green" aria-hidden="true"></i></button> '
                      + '</span></div>'
                  }
                ];
                if (dataTarea.status == 'OK') {
                  $scope.gridOptions.data = dataTarea.data;
                } else {
                  $scope.gridOptions.data = [];
                }

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 1,
                      personaId: $scope.loginData.personaId
                    }
                    if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 6) {
                      filtro.elaboradorId = $scope.loginData.regionId;
                    }
                    tareaService.listadoExento(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.generarArchivoInventario = function (data) {
                  try {
                    var createXLSLFormatObj = [];
                    /* XLS Head Columns */
                    var xlsHeader = ["TURNO", "RODAL", "AREA_RODAL", "PARCELA", "TAMANIO_PARCELA", "NO", "DAP", "ALTURA", "NOMBRE_CIENTIFICO", "VOLUMEN", "AREA_BASAL", 'EN_LINEA'];
                    /* XLS Rows Data */
                    var xlsRows = [];
                    for (var i = 0; i < data.length; i++) {
                      var item = {
                        TURNO: data[i].TURNO,
                        RODAL: data[i].RODAL,
                        AREA_RODAL: data[i].AREA_RODAL,
                        PARCELA: data[i].PARCELA,
                        TAMANIO_PARCELA: data[i].TAMANIO_PARCELA,
                        NO: data[i].NO,
                        DAP: data[i].DAP,
                        ALTURA: data[i].ALTURA,
                        NOMBRE_CIENTIFICO: data[i].NOMBRE_CIENTIFICO,
                        VOLUMEN: data[i].VOLUMEN,
                        AREA_BASAL: data[i].AREA_BASAL,
                        EN_LINEA: data[i].EN_LINEA == null ? 0 : data[i].EN_LINEA
                      }
                      xlsRows.push(item);
                    }
                    createXLSLFormatObj.push(xlsHeader);
                    $.each(xlsRows, function (index, value) {
                      var innerRowData = [];
                      $("tbody").append('<tr><td>' + value.TURNO + '</td><td>' + value.RODAL + '</td><td>' + value.AREA_RODAL + '</td><td>' + value.PARCELA + '</td><td>'
                        + value.TAMANIO_PARCELA + '</td><td>' + value.NO + '</td><td>' + value.DAP + '</td><td>' + value.ALTURA + '</td><td>' + value.NOMBRE_CIENTIFICO
                        + '</td><td>' + value.VOLUMEN + '</td><td>' + value.AREA_BASAL + '</td><td>' + value.EN_LINEA == null ? 0 : value.EN_LINEA + '</td></tr>');
                      $.each(value, function (ind, val) {
                        innerRowData.push(val);
                      });
                      createXLSLFormatObj.push(innerRowData);
                    });
                    var filename = "boleta.xlsx";
                    var ws_name = "data";
                    var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
                    XLSX.utils.book_append_sheet(wb, ws, ws_name);
                    XLSX.writeFile(wb, filename);
                  } catch (e) {
                    toastr.error(e);
                  }
                }

                $scope.getArchivoActualizacion = function (row) {
                  exentoService.getInventario(row.ttExento.exentoId).then(function (res) {
                    if (res.status == 1) {
                      var data = JSON.parse(res.data);
                      $scope.generarArchivoInventario(data);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.descargarInventarioActualizacion = function (row) {
                  exentoService.getInventarioActualizacion(row.tareaExentoId).then(function (res) {
                    if (res.status == 1) {
                      var data = JSON.parse(res.data);
                      $scope.generarArchivoInventario(data);
                    } else {
                      toastr.error(res.message);
                    }
                  }, function (error) {
                    toastr.error(error);
                  });
                }

                $scope.descargarCopiaCertificado = function (row) {
                  tareaService.getArchivoCertificado(row.actualizacionExentoId);
                }

                $scope.anularSolicitudVenta = function (item) {
                  swal({
                    title: "Confirmación",
                    text: "¿Está seguro de anular esta solicitud y notificar al usuario por qué? Esta acción no se podrá reversar.\n\nIngrese el motivo de la anulación",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Motivo de anulación"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario el motivo ya que se le notificará al usuario');
                        return false;
                      }
                      item.observaciones = inputValue;
                      tareaService.anularSolicitudVenta(item).then(function (res) {
                        if (res.status == "OK") {
                          $scope.submitForm(true);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

                $scope.realizarTarea = function (row) {
                  var stateToGo = null;
                  for (var i = 0; i < $scope.dataTask.length; i++) {
                    if ($scope.dataTask[i].taskId == row.tcTask.taskId) {
                      stateToGo = $scope.dataTask[i].stateGo;
                    }
                  }
                  if (stateToGo != null) {
                    $scope.globales.task = row;
                    $scope.globales.task.ttGestion = {
                      expediente: row.ttExento.expediente
                    }
                    authService.removeLocalData('tareaPadreId');
                    authService.removeLocalData('exentoId');
                    authService.removeLocalData('dataOriginal');
                    authService.removeLocalData('tipoInventarioId');
                    authService.removeLocalData('origenCalculoId');
                    authService.removeLocalData('esVerificacion');
                    authService.saveLocalData('tareaPadreId', row.tareaPadreId);
                    authService.saveLocalData('exentoId', row.ttExento.exentoId);
                    $state.go(stateToGo, { tareaId: $base64.encode(row.tareaExentoId) });
                  }
                }

              }]
          })
          .state('index.tarea.exentofin', {
            url: '/exentofin',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', 'authService', '$filter',
                function (tareaService, authService, $filter) {
                  var loginData = authService.getLocalData('loginData' + appSettings.sistemaId);
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 30);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd 00:00:00');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd 23:59:59');
                  var filtro = {
                    fechaIni: new Date(fechaIni),
                    fechaFin: new Date(fechaFin),
                    estadoId: 2,
                    personaId: loginData.personaId,
                    elaboradorId: 0
                  }
                  if (loginData.perfilId == 8 || loginData.perfilId == 6) {
                    filtro.elaboradorId = loginData.regionId;
                  }
                  return tareaService.listadoExento(filtro);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'uiGridConstants', 'toastr', '$filter', 'tareaService', 'utilsService', 'gestionService', '$timeout', 'ngDialog',
              function ($scope, $state, dataTarea, uiGridConstants, toastr, $filter, tareaService, utilsService, gestionService, $timeout, ngDialog) {
                $scope.titulo = "finalizadas";
                var now = new Date();
                var begin = $scope.addDays(now, -30);
                $scope.dataEstado = [
                  { estadoId: 1, estadoDesc: 'Asignado' },
                  { estadoId: 2, estadoDesc: 'Finalizado' }
                ];
                $scope.mostrarFiltroExpediente = true;
                $scope.esExento = true;

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 2,
                  personaAsignadaId: $scope.loginData.personaId,
                  subregionId: $scope.loginData.subregionId,
                  perfilId: $scope.loginData.perfilId,
                  usuarioId: $scope.loginData.usuarioId
                }

                $scope.mostrar = function (estadoId) {
                  if (estadoId == 5 || estadoId == 6) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttExento.codigo', name: 'expediente', displayName: 'Código RNF', width: '12%' },
                  { field: 'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Finalización', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verDocumentoTarea(row.entity)" title="Imprimir documento de esta tarea"> <i class="fa fa-file-pdf-o text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarSaldo(row.entity)" ng-click="grid.appScope.saldoExento(row.entity)" title="Saldo disponible"><i class="fa fa-bar-chart text-success" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verHistorialExento(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol text-warning"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 23" ng-click="grid.appScope.imprimirPoligonos(row.entity)" title="Imprimir polígonos"> <i class="fa fa-map-o text-primary"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.tcTask.taskId == 28 && row.entity.aprobado == 1" ng-click="grid.appScope.cambiarCantidadNotaExento(row.entity)" title="Cambiar cantidad de notas"> <i class="fa fa-recycle text-danger"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataTarea.data;
                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd 00:00:00');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd 23:59:59');
                    var filtro = {
                      fechaIni: new Date(fechaIni),
                      fechaFin: new Date(fechaFin),
                      estadoId: 2,
                      personaId: $scope.loginData.personaId
                    }
                    if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 6) {
                      filtro.elaboradorId = $scope.loginData.regionId;
                    }
                    tareaService.listadoExento(filtro).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    tareaService.buscarPorRnf($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                        $scope.gridOptions.data = [];
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.visibleHistorial = function () {
                  if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 5) {
                    return true;
                  } else {
                    return false;
                  }
                }

                $scope.verDocumentoTarea = function (item) {
                  if (item.estadoId == 1) {
                    toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                    return;
                  }
                  if (item.estadoId == 20) {
                    toastr.error('Esta tarea está anulada por solicitud de regreso de tarea, no es posible imprimir');
                    return;
                  }
                  $scope.imprimirTareaExento(item, $scope.loginData.sigla);
                }

                $scope.mostrarRegresoTarea = function (item) {
                  if ($scope.loginData.perfilId == 5 && item.estadoId == 1) {
                    return true;
                  }
                  return false;
                }

                $scope.imprimirPoligonos = function (item) {
                  $scope.dataTask = item;
                  ngDialog.open({
                    template: 'app/tarea/poligono.list.tpl.html',
                    className: 'ngdialog-theme-default',
                    appendClassName: 'ngdialog-default',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniquePoligono',
                    name: 'dialogPoligono',
                    resolve: {
                      dataPoligono: ['tareaService',
                        function (tareaService) {
                          return tareaService.getPoligonosExento(item.tareaExentoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataPoligono',
                      function ($scope, $state, toastr, dataPoligono) {

                        if (dataPoligono.status == 1) {
                          $scope.dataPoligonos = dataPoligono.data;
                        } else {
                          toastr.error(dataPoligono.message);
                          ngDialog.close();
                        }

                        $scope.visualizarMapa = function (poligono) {
                          $scope.mapa = {};
                          if (poligono.enLinea) {
                            $scope.mapa.encodeMapa = 'path=color:0xff0000ff%7Cweight:5%7Cenc:' + poligono.encodeMapa;
                          } else {
                            $scope.mapa.encodeMapa = 'path=fillcolor:0xAA000033%7Ccolor:0xFFFFFF00%7Cenc:' + poligono.encodeMapa;
                          }
                          $scope.mapa.coordenadasGtm = poligono.coordenadasGtm;
                          $scope.mapa.numero = poligono.numero;
                          $scope.mapa.areaPoligono = parseFloat(poligono.area).toFixed(2);
                          $scope.mapa.codigoDesc = 'No. RNF';
                          $scope.mapa.codigo = poligono.descripcion;
                          $scope.mapa.expediente = poligono.expediente;
                          $scope.mapa.tipoDesc = 'Exentos de Licencia Forestal';
                          $scope.mapa.modalidadDesc = 'Exentos de Licencia Forestal';
                          $scope.mapa.areaOriginal = poligono.areaRegistro;
                          $scope.mapa.areaEfectiva = poligono.areaEfectiva;
                          $scope.mapa.areaDescuento = poligono.areaDescuento;
                          $scope.textoPropietario = poligono.propietario;
                          $scope.mapa.direccion = poligono.direccion;
                          if (poligono.enLinea == null) {
                            poligono.enLinea = false;
                          }
                          $scope.mapa.enLinea = poligono.enLinea;
                          if (poligono.enLinea) {
                            $scope.mapa.lineaOriginal = poligono.lineaRegistro;
                            $scope.mapa.longitud = poligono.area;
                          }
                          ngDialog.open({
                            template: 'app/common/poligono.tpl.html',
                            className: 'ngdialog-theme-flat',
                            appendClassName: 'custom-map',
                            closeByDocument: false,
                            closeByEscape: true,
                            closeByNavigation: true,
                            scope: $scope,
                            id: 'uniquePoligono',
                            name: 'uniquePoligono',
                            resolve: {
                            },
                            controller: ['$scope', '$state', 'toastr',
                              function ($scope, $state, toastr) {

                              }]
                          })
                        }

                        $scope.imprimirMapa = function () {
                          utilsService.openWindow('#poligono', $scope, 'Mapa');
                        };

                      }]
                  })
                }

                $scope.cambiarCantidadNotaExento = function (item) {
                  swal({
                    title: "¿Está seguro de cambiar la cantidad de notas autorizadas para el registro " + item.ttExento.codigo + "?",
                    text: " Esta acción no se podrá reversar y será su responsabilidad las modificaciones realizadas.\n\nIngrese cantidad a autorizar",
                    type: "input",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Aceptar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    inputPlaceholder: "Cantidad de notas a autorizar"
                  },
                    function (inputValue) {
                      if (inputValue === false) return false;
                      if (inputValue === "") {
                        toastr.error('Es necesario indicar la cantidad de notas');
                        return false;
                      }
                      inputValue = inputValue.trim();
                      if (isNaN(inputValue) && isNaN(parseInt(inputValue))) {
                        toastr.error('Debe indicar una cantidad válida');
                        return false;
                      }
                      var ttVentaExento = {
                        ventaExentoId: item.ventaExentoId,
                        ttTareaExento: {
                          tareaExentoId: item.tareaExentoId
                        },
                        autorizadoNotas: inputValue
                      }
                      tareaService.cambiarCantidadNotaExentos(ttVentaExento).then(function (res) {
                        if (res.status == 1) {
                          toastr.success(res.message);
                        } else {
                          toastr.error(res.message);
                        }
                      }, function (error) {
                        toastr.error(error);
                      });
                    });
                }

              }]
          })
          .state('index.tarea.avaluo', {
            url: '/avaluo',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', '$filter',
                function (tareaService, $filter) {
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 30);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd');
                  return tareaService.getMisTareasAvaluo(fechaIni, fechaFin, 1);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'authService', 'toastr', '$filter', 'tareaService', 'utilsService', '$base64', 'ngDialog',
              function ($scope, $state, dataTarea, authService, toastr, $filter, tareaService, utilsService, $base64, ngDialog) {
                $scope.titulo = "de Avalúos";
                var now = new Date();
                var begin = $scope.addDays(now, -30);

                $scope.mostrarFiltroExpediente = false;
                $scope.esExento = true;
                $scope.mostrarEstado = true;

                $scope.dataEstado = [
                  { estadoId: 1, estadoDesc: 'Asignado' },
                  { estadoId: 2, estadoDesc: 'Finalizado' }
                ];

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1
                }

                $scope.mostrarDocumento = function (estadoId) {
                  if (estadoId != 1) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttAvaluo.personaDesc', name: 'personaDesc', displayName: 'Solicitante', width: '20%' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Finalización', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="!grid.appScope.mostrarDocumento(row.entity.estadoId)" ng-click="grid.appScope.realizarTarea(row.entity)" title="Realizar tarea"> <i class="fa fa-arrow-right"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarDocumento(row.entity.estadoId)" ng-click="grid.appScope.verDocumentoTarea(row.entity)" title="Imprimir documento de esta tarea"> <i class="fa fa-file-pdf-o text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verOficioOrigen(row.entity)" title="Ver oficio de requerimiento"> <i class="fa fa-file-pdf-o text-primary"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-show="grid.appScope.visibleHistorial(row.entity.estadoId)" ng-click="grid.appScope.verHistorialAvaluo(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataTarea.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd');
                    tareaService.getMisTareasAvaluo(fechaIni, fechaFin, $scope.data.estadoId).then(function (res) {
                      if (res.status == 1) {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.verOficioOrigen = function (item) {
                  tareaService.getOficioAvaluo(item.ttAvaluo.avaluoId);
                }

                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    tareaService.buscarPorRnf($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                        $scope.gridOptions.data = [];
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.visibleHistorial = function (estadoId) {
                  if (($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 5) || estadoId == 1) {
                    return true;
                  } else {
                    return false;
                  }
                }

                $scope.verDocumentoTarea = function (item) {
                  if (item.estadoId == 1) {
                    toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                    return;
                  }
                  if (item.estadoId == 20) {
                    toastr.error('Esta tarea está anulada por solicitud de regreso de tarea, no es posible imprimir');
                    return;
                  }
                  $scope.imprimirTareaAvaluo(item, $scope.loginData.sigla);
                }

                $scope.verHistorialAvaluo = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.avaluo.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getHistorialAvaluo(item.ttAvaluo.avaluoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                        if (dataHistorial.status == 1) {
                          $scope.dataTarea = dataHistorial.data;

                          $scope.verDocumentoTareaAvaluo = function (item) {
                            if (item.estadoId == 1) {
                              toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                              return;
                            }
                            if (item.estadoId == 20) {
                              toastr.error('Esta tarea está anulada por solicitud de regreso de tarea, no es posible imprimir');
                              return;
                            }
                            $scope.imprimirTareaAvaluo(item);
                          }

                        }

                      }]
                  });
                }

                $scope.realizarTarea = function (row) {
                  var stateToGo = null;
                  for (var i = 0; i < $scope.dataTask.length; i++) {
                    if ($scope.dataTask[i].taskId == row.tcTask.taskId) {
                      stateToGo = $scope.dataTask[i].stateGo;
                    }
                  }
                  if (stateToGo != null) {
                    $scope.globales.task = row;
                    $scope.globales.task.ttGestion = {
                      expediente: row.ttAvaluo.expediente
                    }
                    authService.removeLocalData('tareaPadreId');
                    authService.removeLocalData('avaluoId');
                    authService.removeLocalData('dataOriginal');
                    authService.removeLocalData('tipoInventarioId');
                    authService.removeLocalData('origenCalculoId');
                    authService.removeLocalData('esVerificacion');
                    authService.saveLocalData('tareaPadreId', row.tareaPadreId);
                    authService.saveLocalData('avaluoId', row.ttAvaluo.avaluoId);
                    $state.go(stateToGo, { tareaId: $base64.encode(row.tareaAvaluoId) });
                  }
                }

              }]
          })
          .state('index.tarea.consumo', {
            url: '/consumo',
            templateUrl: 'app/tarea/tarea.list.tpl.html',
            resolve: {
              dataTarea: ['tareaService', '$filter',
                function (tareaService, $filter) {
                  var begin = new Date();
                  var now = new Date();
                  begin.setDate(now.getDate() - 30);
                  var fechaIni = $filter('date')(begin, 'yyyy-MM-dd');
                  var fechaFin = $filter('date')(now, 'yyyy-MM-dd');
                  return tareaService.getMisTareasConsumo(fechaIni, fechaFin, 1);
                }]
            },
            controller: ['$scope', '$state', 'dataTarea', 'authService', 'toastr', '$filter', 'tareaService', 'utilsService', '$base64', 'ngDialog', 'consumoService',
              function ($scope, $state, dataTarea, authService, toastr, $filter, tareaService, utilsService, $base64, ngDialog, consumoService) {
                $scope.titulo = "de Consumos Familiares";
                var now = new Date();
                var begin = $scope.addDays(now, -30);

                $scope.mostrarFiltroExpediente = false;
                $scope.esExento = true;
                $scope.mostrarEstado = true;

                $scope.dataEstado = [
                  { estadoId: 1, estadoDesc: 'Asignado' },
                  { estadoId: 2, estadoDesc: 'Finalizado' }
                ];

                $scope.data = {
                  fechaIni: begin,
                  fechaFin: now,
                  estadoId: 1
                }

                $scope.mostrarRealizar = function (item) {
                  if (item.estadoId == 1 && item.tcPersonaAsignada.personaId == $scope.loginData.personaId) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarAsignarse = function (item) {
                  if (item.estadoId == 1 && item.tcPersonaAsignada.personaId != $scope.loginData.personaId
                    && ($scope.loginData.perfilId == 27 || $scope.loginData.perfilId == 32)) {
                    return true;
                  }
                  return false;
                }

                $scope.mostrarDocumento = function (estadoId) {
                  if (estadoId != 1) {
                    return true;
                  }
                  return false;
                }

                $scope.gridOptions = angular.copy($scope.gridOptionsSelection);
                $scope.gridOptions.enableFiltering = true;
                $scope.gridOptions.columnDefs = [
                  { field: 'ttConsumo.tcSolicitante.personaDesc', name: 'personaDesc', displayName: 'Solicitante', width: '20%' },
                  { field: 'ttConsumo.expediente', name: 'expediente', displayName: 'Expediente', width: '10%' },
                  { field: 'fechaRegistro', name: 'fechaRegistro', displayName: 'Fecha asignación', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaVencimiento', name: 'fechaVencimiento', displayName: 'Vencimiento', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'fechaFinalizacion', name: 'fechaFinalizacion', displayName: 'Finalización', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy HH:mm\'' },
                  { field: 'tcTask.taskDesc', name: 'taskDesc', displayName: 'Tarea' },
                  {
                    name: 'Opciones', enableFiltering: false, width: '8%',
                    cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarAsignarse(row.entity)" ng-click="grid.appScope.autoasignarTarea(row.entity)" title="Asignarme ésta tarea para resolverla"><i class="fa fa-user text-danger" aria-hidden="true"></i></button>'
                      + ' <button type="button" class="btn btn-danger btn-xs" ng-show="grid.appScope.mostrarRealizar(row.entity)" ng-click="grid.appScope.realizarTarea(row.entity)" title="Realizar tarea"> <i class="fa fa-arrow-right"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarDocumento(row.entity.estadoId)" ng-click="grid.appScope.verDocumentoTarea(row.entity)" title="Imprimir documento de esta tarea"> <i class="fa fa-file-pdf-o text-danger"></i> </button> '
                      + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirSolicitudConsumo(row.entity.ttConsumo)" title="Imprimir solicitud"> <i class="fa fa-file-pdf-o text-primary"></i> </button> '
                      + ' <button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="fa fa-file-archive-o"></i> </button> '
                      + ' <button type="button" class="btn btn-warning btn-xs" ng-show="grid.appScope.visibleHistorial(row.entity.estadoId)" ng-click="grid.appScope.verHistorial(row.entity)" title="Ver historial de documentos"> <i class="fa fa-list-ol"></i> </button> '
                      + '</span></div>'
                  }
                ];
                $scope.gridOptions.data = dataTarea.data;

                $scope.submitForm = function (isValid) {
                  if (isValid) {
                    var fechaIni = $filter('date')(new Date($scope.data.fechaIni), 'yyyy-MM-dd');
                    var fechaFin = $filter('date')(new Date($scope.data.fechaFin), 'yyyy-MM-dd');
                    tareaService.getMisTareasConsumo(fechaIni, fechaFin, $scope.data.estadoId).then(function (res) {
                      if (res.status == 1) {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.autoasignarTarea = function (item) {
                  swal({
                    title: "Esta acción no se podrá reversar",
                    text: "¿Está seguro (a) de asignarse la tarea? Presione en confirmar si si o en cancelar si no está seguro",
                    icon: "warning",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonClass: "btn-success",
                    cancelButtonClass: "btn-danger",
                    confirmButtonText: "Confirmar",
                    cancelButtonText: "Cancelar",
                    closeOnConfirm: true,
                    closeOnCancel: true
                  },
                    function (isConfirm) {
                      if (isConfirm) {
                        tareaService.asignarseTareaConsumo(item.tareaConsumoId).then(function (res) {
                          if (res.status == 1) {
                            $scope.submitForm(true);
                            toastr.success(res.message);
                          } else {
                            toastr.error(res.message);
                          }
                        }, function (error) {
                          toastr.error(error);
                        });
                      }
                    });
                }

                $scope.verAnexos = function (item) {
                  ngDialog.open({
                    template: 'app/consumoFamiliar/anexo.consumo.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEditar',
                    resolve: {
                      dataAnexo: ['consumoService',
                        function (consumoService) {
                          return consumoService.getListaAnexoSolicitud(item.ttConsumo.consumoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataAnexo',
                      function ($scope, $state, toastr, dataAnexo) {

                        $scope.dataAnexo = dataAnexo.data;

                      }]
                  });
                }

                $scope.abrirAnexo = function (anexoConsumoId) {
                  consumoService.getAnexoSolicitud(anexoConsumoId);
                }

                $scope.verHistorial = function (item) {
                  $scope.row = item;
                  ngDialog.open({
                    template: 'app/tarea/historial.tarea.tpl.html',
                    className: 'ngdialog-theme-flat',
                    appendClassName: 'ngdialog-flat',
                    closeByDocument: false,
                    closeByEscape: true,
                    closeByNavigation: true,
                    scope: $scope,
                    id: 'uniqueEdit',
                    name: 'dialogEdit',
                    resolve: {
                      dataHistorial: ['tareaService',
                        function (tareaService) {
                          return tareaService.getHistorialTareasConsumo(item.ttConsumo.consumoId);
                        }]
                    },
                    controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                      function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                        $scope.dataHistorial = dataHistorial.data;

                        $scope.verDocumento = function (item) {
                          $scope.imprimirTareaConsumo(item, null);
                        }

                      }]
                  });
                }

                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
                $scope.abrirBusquedaExpediente = function () {
                  $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                }

                $scope.buscarExpediente = function () {
                  if ($scope.expediente == null || $scope.expediente.length <= 0) {
                    $scope.obligatorioExpediente = true;
                  } else {
                    tareaService.buscarPorRnf($scope.expediente.trim()).then(function (res) {
                      if (res.status == "OK") {
                        $scope.gridOptions.data = res.data;
                      } else {
                        toastr.error(res.message);
                        $scope.gridOptions.data = [];
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                  }
                }

                $scope.cerrarVentana = function () {
                  ngDialog.close();
                }

                $scope.visibleHistorial = function (estadoId) {
                  if (($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 5) || estadoId == 1) {
                    return true;
                  } else {
                    return false;
                  }
                }

                $scope.verDocumentoTarea = function (item) {
                  if (item.estadoId == 1) {
                    toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                    return;
                  }
                  if (item.estadoId == 20) {
                    toastr.error('Esta tarea está anulada por solicitud de regreso de tarea, no es posible imprimir');
                    return;
                  }
                  $scope.imprimirTareaConsumo(item, $scope.loginData.sigla);
                }

                $scope.realizarTarea = function (row) {
                  var stateToGo = null;
                  for (var i = 0; i < $scope.dataTask.length; i++) {
                    if ($scope.dataTask[i].taskId == row.tcTask.taskId) {
                      stateToGo = $scope.dataTask[i].stateGo;
                    }
                  }
                  if (stateToGo != null) {
                    $scope.globales.task = row;
                    $scope.globales.task.ttGestion = {
                      expediente: row.ttConsumo.expediente
                    }
                    authService.removeLocalData('tareaPadreId');
                    authService.removeLocalData('consumoId');
                    authService.removeLocalData('dataOriginal');
                    authService.removeLocalData('tipoInventarioId');
                    authService.removeLocalData('origenCalculoId');
                    authService.removeLocalData('esVerificacion');
                    authService.saveLocalData('tareaPadreId', row.tareaPadreId);
                    authService.saveLocalData('consumoId', row.ttConsumo.consumoId);
                    $state.go(stateToGo, { tareaId: $base64.encode(row.tareaConsumoId) });
                  }
                }

              }]
          })
      }
    ]
  );
