angular.module('app.consumo.service', [

])

.factory('consumoService', ['$http', '$q', 'appSettings',  function ( $http, $q, appSettings ) {

  return {
    listaPorPersonaFecha: function ( filtro ) {
      return $http.post( appSettings.urlServiceBase + 'consumo/persona/lista', filtro ).then(function ( res ) {
         return res.data;
       });
    },
    misConsumos: function () {
      return $http.get( appSettings.urlServiceBase + 'consumo/propio').then(function ( res ) {
        return res.data;
      });
    },
    listaPorPersona: function ( personaId, estadoId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/propio').then(function ( res ) {
        return res.data;
      });
    },
    listStatus: function () {
      var data = [];
      data.push({estadoId : 0, estadoDesc : 'Inactivo'});
      data.push({estadoId : 1, estadoDesc : 'Activo'});
      var res = {
        status : 'OK',
        message : 'Datos cargados',
        data : data
      };
      return res;
    },
    agregarPropio: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'consumo/add', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarDesdeOficina: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'consumo/add/oficina', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    edit: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/' + data.consumoId, data ).
       then(function ( res ) {
         return res.data;
       });
    },
    get : function( consumoId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/' + consumoId).then(function ( res ) {
         return res.data;
       });
    },
    agregarNotificaciones: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/notificaciones', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarBeneficiados: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/beneficiados', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarFinca: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/finca', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarProducto: function ( data ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/especies/solicitud', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarAnexoSolicitud: function ( ttAnexoConsumo ) {
      return $http.post(appSettings.urlServiceBase + 'consumo/requisito/solicitud', ttAnexoConsumo).
        then(function (res) {
          return res.data;
        });
    },
    getAnexoSolicitud : function( anexoConsumoId ) {
      $http.get(appSettings.urlServiceBase + 'consumo/anexo/' + anexoConsumoId + '/solicitud', {responseType: 'arraybuffer'})
       .then(function (data) {
         var file = new Blob([data.data], {type: data.headers(["content-type"])});
         var fileURL = URL.createObjectURL(file);
         window.open(fileURL);
       });
    },
    listaPorEntidad: function ( estadoId, fechaIni, fechaFin ) {
      var params = {
        estadoId: estadoId,
        fechaIni: fechaIni,
        fechaFin: fechaFin
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'consumo/por/entidad',  config).then(function ( res ) {
        return res.data;
      });
    },
    enviarSolicitud: function ( consumoId ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/' + consumoId + '/enviar/solicitud' ).
       then(function ( res ) {
         return res.data;
       });
    },
    getListaAnexoSolicitud : function( consumoId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/' + consumoId + '/anexo/lista').then(function ( res ) {
         return res.data;
       });
    },
    getByCui : function( cui ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/buscar/cui/' + cui).then(function ( res ) {
         return res.data;
       });
    },
    getListaVentaByConsumo : function( consumoId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/' + consumoId + '/venta/lista').then(function ( res ) {
         return res.data;
       });
    },
    getByConsumoId : function( consumoId ) {
      return $http.get( appSettings.urlServiceBase + 'consumo/' + consumoId + '/propio').then(function ( res ) {
         return res.data;
       });
    },
    agregarSolicitudVentaConsumo: function ( data ) {
      return $http.post( appSettings.urlServiceBase + 'consumo/solicitar/venta', data ).
       then(function ( res ) {
         return res.data;
       });
    },
    recibirSolicitudVentaConsumo: function ( consumoVentaId ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/recibir/venta/' + consumoVentaId ).
       then(function ( res ) {
         return res.data;
       });
    },
    agregarDocumentoPagoVenta: function ( ttConsumoVenta ) {
      return $http.put( appSettings.urlServiceBase + 'consumo/venta/agregar/pago', ttConsumoVenta ).
       then(function ( res ) {
         return res.data;
       });
    },
    getReporteByPersona: function ( tipoReporteId, regionId, subregionId, departamentoId, municipioId, fechaIni, fechaFin ) {
      var params = {
        tipoReporteId: tipoReporteId,
        regionId: regionId == null ? 0 : regionId,
        subregionId: subregionId == null ? 0 : subregionId,
        departamentoId: departamentoId == null ? 0 : departamentoId,
        municipioId: municipioId == null ? 0 : municipioId,
        fechaIni: fechaIni,
        fechaFin: fechaFin
      }
      var config = {
        params: params
      }
      return $http.get( appSettings.urlServiceBase + 'consumo/reporte/tiempo', config).then(function ( res ) {
        return res.data;
      });
    }
  }

}]);
