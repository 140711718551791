angular.module( 'app.authInterceptorService', [
  'LocalStorageModule'
])

.factory( 'authInterceptorService', ['$q', '$injector', '$location', 'localStorageService', 'appSettings', 'toastr',
  function ($q, $injector, $location, localStorageService, appSettings, toastr) {

  var authInterceptor = {};

  authInterceptor.request = function ( config ) {

    config.headers = config.headers || {};

    var tokenGuest = localStorageService.get( 'tokenGuest' );
    if (tokenGuest) {
      config.headers.Authorization = 'Bearer ' + tokenGuest;
    } else {
      var authData = localStorageService.get( 'loginData' + appSettings.sistemaId);
      if (authData) {
        config.headers.Authorization = 'Bearer ' + authData.token;
      }
    }
    return config;
  }

  authInterceptor.responseError = function ( rejection ) {
    if (rejection == undefined || rejection == null) {
      location.href = "404.html";
    } else {
      switch (rejection.status) {
        case -1:
          toastr.error('Lamentamos los inconvenientes, no existe conexión a los servicios');
          location.href = "#!/login";
          rejection.config = {};
          return $q.reject(rejection);          
        case 400:
        case 401:
          var authData = localStorageService.get( 'loginData' + appSettings.sistemaId);
          if (authData != undefined) {
            if (authData != null) {
              if (authData.useRefreshTokens) {
                $location.path( '/refresh' );
                return $q.reject(rejection);
              }
            }
          }
          if (rejection.data != undefined) {
            if (rejection.data != null) {
              toastr.error('Lo sentimos, por favor refresque el navegador y vuelva a intentar');
            }
          }
          try {
            delete rejection.data;
            delete rejection.headers;
            delete rejection.config.headers;
            delete rejection.config.url;
            delete rejection.config.paramSerializer;
            delete rejection.config.transformRequest;
            delete rejection.config.transformResponse;
            delete rejection.config.data;
            delete rejection.config.jsonpCallbackParam;
            delete rejection.xhrStatus;
            delete rejection.statusText;
          } catch (error) {}
          break;
        case 404:
          toastr.error('No se encuentra el servicio que intenta consumir, favor de reportar el inconveniente');
          break;
        case 403:
          delete rejection.data;
          delete rejection.headers;
          delete rejection.config.headers;
          delete rejection.config.url;
          delete rejection.config.paramSerializer;
          delete rejection.config.transformRequest;
          delete rejection.config.transformResponse;
          delete rejection.config.data;
          delete rejection.config.jsonpCallbackParam;
          delete rejection.xhrStatus;
          delete rejection.statusText;
          toastr.error('Su usuario no tiene los permisos para realizar esta acción, intente refrescar la página e intente de nuevo', 'Acceso denegado');
          break;
        case 406:          
          toastr.error(rejection, 'Error al realizar el proceso');
          break;
        case 500:
          toastr.error('Lamentamos los inconvenientes, estamos experimentado algunos inconvenientes. Por favor, ten un poco de paciencia y vuelve a intertarlo en otro momento', '500 Internal Server Error');
          break;
        default:
          break;
      }
      if (rejection == 'Error: "[object Object]"') {
        rejection = {
          status : 406,
          data : {
            status : "error",
            message : "406 Not Acceptable"
          }
        }
      }
    }    
    return $q.reject(rejection);
  }

  return authInterceptor;
}]);
