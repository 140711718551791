angular.module('app.menu', [
  'ui.router',
  'toastr',
  'app.menu.service'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.menu', {
          abstract: true,
          url: 'menu',
          template: '<div ui-view></div>',
          resolve: {
            permisoEstado : ['authService',
              function( authService){
                return authService.loginPermission(1001);
              }]
          },
          controller: ['$scope',
            function (  $scope) {
              $scope.module = 'Menu';
            }]
        })
        .state('index.menu.list', {
          url: '',
          templateUrl: 'app/menu/menu.list.tpl.html',
          resolve: {
            dataMenu: ['menuService',
              function ( menuService ){
                return menuService.list();
              }]
          },
          controller: ['$scope', '$state', 'dataMenu',
            function (  $scope,   $state,   dataMenu) {
              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.columnDefs = [
                { field:'menuDesc', name: 'menuDesc', displayName: 'Menu' },
                { field:'tooltip', name: 'tooltip', displayName: 'Texto de ayuda' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>' },
                { name: 'Opciones', enableFiltering: false,
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    +'<button type="button" class="btn btn-primary btn-xs" ng-click="grid.appScope.editRow(row.entity)" title="Editar">Editar</button></span></div>' }
              ];
              $scope.gridOptions.data = dataMenu.data;
              $scope.editRow = function ( row ) {
                $state.go('^.edit',{ menuId: row.menuId });
              }
              $scope.etiquetaEstado = function ( estadoId ) {
                if (estadoId == 1) {
                  return "Activo";
                }
                return "Inactivo";
              }
            }]
        })
        .state('index.menu.add', {
          url: '/add',
          templateUrl: 'app/menu/menu.add.tpl.html',
          resolve: {
            dataEstado: ['menuService',
              function ( menuService ){
                return menuService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'menuService', 'dataEstado',
            function (  $scope,   $state,   toastr,   menuService, dataEstado) {
              $scope.data = {};
              $scope.dataEstado = dataEstado.data;
              $scope.data.estadoId = 1;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  menuService.add( $scope.data ).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
        .state('index.menu.edit', {
          url: '/:menuId/edit',
          templateUrl: 'app/menu/menu.add.tpl.html',
          resolve: {
            dataMenu: ['menuService', '$stateParams',
              function ( menuService, $stateParams ){
                return menuService.get( $stateParams.menuId );
              }],
            dataEstado: ['menuService',
              function ( menuService ){
                return menuService.listStatus();
              }]
          },
          controller: ['$scope', '$state', 'toastr', 'menuService', 'dataMenu', 'dataEstado',
            function (  $scope,   $state,   toastr,   menuService,   dataMenu, dataEstado) {
              $scope.data = dataMenu.data[0];
              $scope.dataEstado = dataEstado.data;
              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  menuService.edit( $scope.data ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      toastr.success( res.message );
                      $state.go( '^.list' );
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }
            }]
        })
    }
  ]
);
