angular.module('app.seguimiento', [
  'ui.router',
  'toastr'
])

.config(
  [          '$stateProvider', '$urlRouterProvider',
    function ($stateProvider,   $urlRouterProvider) {
      $stateProvider
        .state('index.seguimiento', {
          abstract: true,
          url: 'seguimiento',
          template: '<div ui-view></div>',
          resolve: {
          },
          controller: ['$scope', 'toastr', '$timeout', 'gestionService', 'utilsService',
            function (  $scope, toastr, $timeout, gestionService, utilsService) {
              $scope.module = 'Seguimiento';
              $scope.imprimirPlan = function (row) {
                var gestionId = row.ttGestion.gestionId;
                try {
                  gestionService.getConCronograma( gestionId ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      var data = res.data[0];
                      var dataResumen = data.rodal;
                      for (i = 0; i < dataResumen.length; i++) {
                        for (j = 0; j < dataResumen[i].especies.length; j++) {
                          for (k = 0; k < dataResumen[i].especies[j].distribucion.length; k++) {
                            dataResumen[i].especies[j].distribucion[k].fuste = JSON.parse(dataResumen[i].especies[j].distribucion[k].fuste);
                          }
                          if (dataResumen[i].especies[j].fuste != null) {
                            dataResumen[i].especies[j].fuste = JSON.parse(dataResumen[i].especies[j].fuste);
                          }
                        }
                      }
                      //generales
                      if (data.ttInventarioGestion == undefined || data.ttInventarioGestion == null) {
                        toastr.error("La información de inventario esta incompleto, favor de revisar");
                        return;
                      }
                      if (data.ttPlanificacionGestion == undefined || data.ttPlanificacionGestion == null) {
                        toastr.error("La información de planificación esta incompleto, favor de revisar");
                        return;
                      }
                      if (data.cronograma == undefined || data.cronograma == null) {
                        toastr.error("El cronograma esta incompleto, favor de revisar");
                        return;
                      }
                      try {
                        if (data.ttInventarioGestion.data != undefined && data.ttInventarioGestion.data != null) {
                          data.ttInventarioGestion.data = JSON.parse(data.ttInventarioGestion.data);
                        }
                        try {
                          data.ttInventarioGestion.brinzal = JSON.parse(data.ttInventarioGestion.brinzal);
                        } catch (e) {
                          data.ttInventarioGestion.brinzal = [];
                        }
                        try {
                          data.ttInventarioGestion.latizal = JSON.parse(data.ttInventarioGestion.latizal);
                        } catch (e) {
                          data.ttInventarioGestion.latizal = [];
                        }
                        data.cronograma = JSON.parse(data.cronograma);
                        toastr.info('Espere mientras se imprimen los documentos', 'Imprimiendo');
                        utilsService.generarPlan($scope.dataEmpresa, data);
                        $timeout(function() {
                          utilsService.generarSolicitudLicencia(data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                          $scope.imprimirCronograma(data);
                        }, 3000);
                        if (data.ttResumenGestion.noTurnos > 1) {
                          $timeout(function() {
                            utilsService.generarPoa($scope.dataEmpresa, data, 1);
                            $timeout(function() {
                              $scope.globales.totalPaginasPoa = utilsService.getLastPagePoa();
                              var totalPaginas = $scope.globales.totalPaginasPoa;
                              utilsService.generarCronograma($scope.dataEmpresa, data, totalPaginas, 0, 1);
                            }, 3000);
                          }, 1000);
                        }
                      } catch (e) {
                        toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador. " + e.message);
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                } catch (e) {
                  toastr.error("El plan se encuentra incompleto, comuníquese con el elaborador");
                }
              }

              $scope.imprimirCronograma = function ( data ) {
                $scope.globales.totalPaginas = utilsService.getLastPage();
                if ($scope.globales.totalPaginas <= 0) {
                  toastr.error("Debe imprimir primero el plan luego el cronograma");
                } else {
                  utilsService.generarCronograma($scope.dataEmpresa, data, $scope.globales.totalPaginas);
                }
              }

            }]
        })
        .state('index.seguimiento.list', {
          url: '/lista',
          templateUrl: 'app/seguimiento/seguimiento.list.tpl.html',
          resolve: {
            dataSeguimiento: ['gestionService', 'authService', '$filter',
              function ( gestionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 90);
                var fechaIni = $filter( 'date' )(begin, 'yyyy-MM-dd 00:00:00' );
                var fechaFin = $filter( 'date' )(now, 'yyyy-MM-dd 23:59:59' );
                var filtro = {
                  fechaIni : new Date(fechaIni),
                  fechaFin : new Date(fechaFin),
                  estadoId : 5,
                  subregionId : loginData.subregionId,
                  elaboradorId: 1,
                  personaId : loginData.regionId
                }
                if (loginData.perfilId == 16 || loginData.perfilId == 20 || loginData.perfilId == 36) {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                } else {
                  return gestionService.getListaSeguimiento(filtro);
                }
              }],
            dataTipoGestion: ['tipogestionService',
              function ( tipogestionService ){
                return tipogestionService.listaActiva();
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataSubregion: ['subregionService', 'authService', '$filter',
              function ( subregionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                if (loginData.perfilId == 16 || loginData.perfilId == 20 || loginData.perfilId == 36) {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                } else {
                  return subregionService.listaPorRegion(loginData.regionId);
                }
              }]
          },
          controller: ['$scope', '$state', 'dataSeguimiento', 'uiGridConstants', '$filter', 'gestionService', 'toastr', '$base64',
            'ngDialog', 'tareaService', 'utilsService', 'dataTipoGestion', 'dataRegion', 'dataSubregion', 'subregionService',
            function (  $scope,   $state,   dataSeguimiento, uiGridConstants, $filter, gestionService, toastr, $base64,
            ngDialog, tareaService, utilsService, dataTipoGestion, dataRegion, dataSubregion, subregionService) {
              $scope.dataSubregion = dataSubregion.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataTipoGestion = dataTipoGestion.data;
              $scope.dataTipoGestion.unshift({tipoGestionId: 0, tipoGestionDesc: 'Todos'});
              $scope.dataSubregion.unshift({subregionId: 0, subregionDesc: 'Todos'});
              $scope.mostrarRegion = false;
              $scope.mostrarFiltroExpediente = false;
              if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 20 || $scope.loginData.perfilId == 36) {
                $scope.mostrarRegion = true;
                $scope.mostrarFiltroExpediente = true;
              }
              $scope.dataEstado = [
                {estadoId: -1, estadoDesc: 'Todos'},
                {estadoId: 5, estadoDesc: 'En trámite'},
                {estadoId: 6, estadoDesc: 'Esperando enmiendas'},
                {estadoId: 7, estadoDesc: 'Finalizado'},
                {estadoId: 8, estadoDesc: 'Cancelado'},
                {estadoId: 101, estadoDesc: 'Suspendido'}
              ];

              var now = new Date();
              var begin = $scope.addDays(now, -90);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : 5,
                elaboradorId: 1,
                subregionId: $scope.loginData.subregionId,
                personaId: $scope.loginData.regionId
              }

              $scope.etiquetaEstado = function (estadoId) {
                for (var i = 0; i < $scope.dataEstado.length; i++) {
                  if ($scope.dataEstado[i].estadoId == estadoId) {
                    return $scope.dataEstado[i].estadoDesc;
                  }
                }
                return 'Desconocido';
              }

              $scope.habilitarVerPlan = function ( estadoId ) {
                if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 20 || $scope.loginData.perfilId == 36) {
                  return true;
                }
                return false;
              }

              $scope.habilitarVerBoleta = function () {
                if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 20
                || $scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12 || $scope.loginData.perfilId == 36) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'ttGestion.nug', name: 'nug', displayName: 'NUG', width: '10%' },
                { field:'ttGestion.expediente', name: 'expediente', displayName: 'Expediente', width: '15%' },
                { field:'ttGestion.tcPersonaCrea.personaDesc', name: 'propietario', displayName: 'Propietario/Rep.' },
                { field:'ttGestion.tcTipoGestion.tipoGestionDesc', name: 'tipoGestionDesc', displayName: 'Gestión' },
                { field:'fechaRecepcion', name: 'fechaRecepcion', displayName: 'Fecha ingreso', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'ttGestion.area', name: 'area', displayName: 'Área', width: '7%' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '10%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.ttGestion.estadoId)}}</div>'
                },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                    + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.imprimirPlan(row.entity)" title="Imprimir plan"> <i class="text-blue fa fa-print"></i> </button> '
                    + ' <button type="button" class="btn btn-default btn-xs" ng-click="grid.appScope.verAnexos(row.entity)" title="Ver anexos"> <i class="text-blue fa fa-file-archive-o"></i> </button> '
                    + ' <button type="button" class="btn btn-warning btn-xs" ng-click="grid.appScope.verHistorial(row.entity)" title="Ver historial de tareas"> <i class="fa fa-list"></i> </button> '
                    + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarVerBoleta(row.entity.ttGestion.estadoId)" ng-click="grid.appScope.generarBoleta(row.entity)" title="Descargar boleta de inventario"> <i class="fa fa-database"></i> </button> '
                    + ' <button type="button" class="btn btn-success btn-xs" ng-show="grid.appScope.habilitarVerPlan()" ng-click="grid.appScope.abrirPlan(row.entity.ttGestion)" title="Abrir plan de manejo"> <i class="fa fa-newspaper-o"></i> </button> '
                    + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarVerPlan()" ng-click="grid.appScope.verUltimaTarea(row.entity.ttGestion)" title="Anular última tarea"> <i class="text-red fa fa-remove"></i> </button> '
                    + '</span></div>' }
              ];
              $scope.gridOptions.data = dataSeguimiento.data;

              $scope.generarBoleta = function (row) {
                gestionService.getGestionData( row.ttGestion.gestionId ) .then( function ( res ) { //obtener datos originales del inventario
                  if ( res.status == "OK" ) {
                    var data = res.data[0];
                    try {
                      var data = JSON.parse(data.data);
                      var createXLSLFormatObj = [];
                      /* XLS Head Columns */
                      var xlsHeader = ["TURNO", "RODAL", "AREA_RODAL", "PARCELA", "TAMANIO_PARCELA", "NO", "DAP", "ALTURA", "NOMBRE_CIENTIFICO", "VOLUMEN", "AREA_BASAL", "CALIDAD_FUSTE", "X", "Y"];
                      /* XLS Rows Data */
                      var xlsRows = [];
                      for (var i = 0; i < data.length; i++) {
                        var item = {
                          TURNO : data[i].TURNO,
                          RODAL : data[i].RODAL,
                          AREA_RODAL : data[i].AREA_RODAL,
                          PARCELA : data[i].PARCELA,
                          TAMANIO_PARCELA : data[i].TAMANIO_PARCELA,
                          NO : data[i].NO,
                          DAP : data[i].DAP,
                          ALTURA : data[i].ALTURA,
                          NOMBRE_CIENTIFICO : data[i].NOMBRE_CIENTIFICO,
                          VOLUMEN : data[i].VOLUMEN,
                          AREA_BASAL : data[i].AREA_BASAL,
                          CALIDAD_FUSTE : data[i].CALIDAD_FUSTE,
                          X : data[i].X,
                          Y : data[i].Y
                        }
                        xlsRows.push(item);
                      }
                      createXLSLFormatObj.push(xlsHeader);
                      $.each(xlsRows, function(index, value) {
                          var innerRowData = [];
                          $("tbody").append('<tr><td>' + value.TURNO + '</td><td>' + value.RODAL + '</td><td>' + value.AREA_RODAL + '</td><td>' + value.PARCELA + '</td><td>' + value.TAMANIO_PARCELA + '</td><td>' + value.NO + '</td><td>' + value.DAP + '</td><td>' + value.ALTURA + '</td><td>' + value.NOMBRE_CIENTIFICO + '</td><td>' + value.VOLUMEN + '</td><td>' + value.AREA_BASAL + '</td><td>' + value.CALIDAD_FUSTE + '</td><td>' + value.X + '</td><td>' + value.Y + '</td></tr>');
                          $.each(value, function(ind, val) {
                            innerRowData.push(val);
                          });
                          createXLSLFormatObj.push(innerRowData);
                      });


                      /* File Name */
                      var filename = "boleta.xlsx";

                      /* Sheet Name */
                      var ws_name = "data";

                      if (typeof console !== 'undefined') console.log(new Date());
                      var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);

                      /* Add worksheet to workbook */
                      XLSX.utils.book_append_sheet(wb, ws, ws_name);

                      /* Write workbook and Download */
                      if (typeof console !== 'undefined') console.log(new Date());
                      XLSX.writeFile(wb, filename);
                      if (typeof console !== 'undefined') console.log(new Date());

                    } catch (e) {
                      toastr.error(e.message);
                    }
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.setRegion = function ( item ) {
                if (item != null) {
                  subregionService.listaPorRegion(item.regionId).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.dataSubregion = res.data;
                      $scope.dataSubregion.unshift({subregionId: 0, subregionDesc: 'Todos'});
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.abrirPlan = function (item) {
                $state.go('index.plan.realizar', {gestionId : $base64.encode(item.gestionId)});
              }

              $scope.mostrarBusquedaExpediente = false;
              $scope.obligatorioExpediente = false;
              $scope.expediente = null;
              $scope.mostrarBusquedaPersona = false;
              $scope.abrirBusquedaExpediente = function () {
                $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                $scope.mostrarBusquedaPersona = false;
                $scope.obligatorioPersonaDesc = false;
              }

              $scope.abrirBusquedaPersona = function () {
                $scope.mostrarBusquedaPersona = !$scope.mostrarBusquedaPersona;
                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
              }

              $scope.buscarExpediente = function () {
                if ($scope.expediente == null || $scope.expediente.length <= 0) {
                  $scope.obligatorioExpediente = true;
                } else {
                  gestionService.buscarSolicitudPorExpediente( $scope.expediente.trim() ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                      if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                        toastr.success( res.message );
                      }
                      if (res.data.length <= 0) {
                        toastr.error('No se encontraron datos con el expediente proporcionada');
                      }
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.buscarPersona = function () {
                if ($scope.personaDesc == null || $scope.personaDesc.length <= 1) {
                  $scope.obligatorioPersonaDesc = true;
                  toastr.error('Debe indicar el nombre o apellido');
                  return;
                }
                var filtro = {
                  filtro: $scope.personaDesc
                }
                gestionService.getPorInvolucrado( filtro ) .then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataHistorial = res.data;
                    if ($scope.dataHistorial.length > 0) {
                      ngDialog.open({
                        template: 'app/seguimiento/involucrado.gestion.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr', 'tareaService',
                          function ($scope, $state, toastr, tareaService) {

                          }]
                      });
                    } else {
                      toastr.error('No se encontraron datos relacionados');
                    }
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.abrirPlanElaborando = function () {
                swal({
                  title: "Indique el NUG temporal",
                  text: "Omita el texto TMP-, indique únicamente el número temporal",
                  type: "input",
                  showCancelButton: true,
                  confirmButtonClass: "btn-success",
                  cancelButtonClass: "btn-danger",
                  confirmButtonText: "Confirmar",
                  cancelButtonText: "Cancelar",
                  closeOnConfirm: true,
                  closeOnCancel: true,
                  inputPlaceholder: "TMP-NUG"
                },
                function(inputValue) {
                  if (inputValue === false) return false;
                  if (inputValue === "") {
                    toastr.error('Es necesario el número temporal');
                    return false;
                  }
                  $state.go('index.plan.realizar', {gestionId : $base64.encode(inputValue.trim())});
                });
              }

              $scope.verAnexos = function ( item ) {
                ngDialog.open({
                  template: 'app/gestion/anexo.gestion.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEditar',
                  resolve: {
                    dataAnexo: ['gestionService',
                      function ( gestionService ){
                        return gestionService.getGestionAnexo(item.ttGestion.gestionId);
                      }],
                    dataAnexoSuspension: ['gestionService',
                      function ( gestionService ){
                        return gestionService.getGestionAnexoSuspension(item.ttGestion.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataAnexo', 'gestionService', 'dataAnexoSuspension',
                    function ($scope, $state, toastr, dataAnexo, gestionService, dataAnexoSuspension) {
                      $scope.dataAnexo = dataAnexo.data;
                      $scope.dataAnexoSuspension = dataAnexoSuspension.data;

                      $scope.abrirAnexo = function (anexoGestionId) {
                        gestionService.getAnexoGestion( anexoGestionId );
                      }

                      $scope.abrirAnexoSuspension = function (anexoSuspensionId) {
                        gestionService.getAnexoSuspension( anexoSuspensionId );
                      }

                    }]
                });
              }

              $scope.verHistorial = function ( item ) {
                $scope.row = item;
                ngDialog.open({
                  template: 'app/tarea/historial.tarea.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEdit',
                  resolve: {
                    dataHistorial: ['tareaService',
                      function ( tareaService ){
                        return tareaService.getGestionHistorial(item.solicitudId, item.ttGestion.gestionId, item.ttGestion.tcSubregion.subregionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                    function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {
                      $scope.dataHistorial = dataHistorial.data;
                      
                      $scope.verDocumento = function ( item ) {
                        var dia = null;
                        var anio = null;
                        var strDia = null;
                        var strAnio = null;
                        switch (parseInt(item.tcTask.taskId)) {
                          case 1: //solicitud
                            gestionService.getDatosRecepcionByGestion( $scope.row.ttGestion.gestionId ) .then( function ( res ) {
                              if ( res.status == "OK" ) {
                                utilsService.generarConstanciaRecepcion(res.data[0], $scope.loginData.subregionDesc, $scope.loginData.usuario, $scope.dataEmpresa);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 2:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                dia = $filter( 'date' )(new Date(data.fechaRecepcion), 'dd' );
                                anio = $filter( 'date' )(new Date(data.fechaRecepcion), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaAdmision), 'dd' );
                                anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaAdmision), 'yyyy' );
                                var strDiaAdmision = $scope.numeroALetras(dia);
                                var strAnioAdmision = $scope.numeroALetras(anio, false);
                                utilsService.generarResolucionAdmision(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                $timeout(function() {
                                  utilsService.generarProvidenciaTraslado(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                }, 1000);
                                //si es fiduciaria
                                if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1
                                  || data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7) {
                                  $timeout(function() {
                                    //utilsService.generarSolicitudAval(data, 1, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, 1);
                                  }, 2000);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 3:
                            tareaService.getConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                try {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                } catch (e) {}
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                if (parseInt(data.aprobado) == 2) {
                                   utilsService.generarEnmiendaJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarOpinionJuridica(data, $scope.loginData.usuario, $scope.dataEmpresa)
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 4:
                            gestionService.getGestionParaTarea( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                if (parseInt(item.esEnmienda) == 1) {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                  utilsService.generarEnmiendaTecnica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  if (data.ttSeguimientoTarea.antecedente != null) {
                                    data.ttSeguimientoTarea.antecedente = JSON.parse(data.ttSeguimientoTarea.antecedente);
                                  }
                                  data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                                  data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                  utilsService.generarOpinionTecnica(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 5:
                            gestionService.getGestionParaTarea( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                var dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                var anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                var strDia = $scope.numeroALetras(dia);
                                var strAnio = $scope.numeroALetras(anio, false);
                                if (parseInt(data.esEnmienda) == 1) {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                  utilsService.generarEnmiendaSubregional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                  utilsService.generarDictamenSubregional(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 6:
                            gestionService.getGestionParaTareaConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                data.ttSeguimientoTarea.fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
                                if (data.esEnmienda == 1) {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                  utilsService.generarEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                } else {
                                  data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                  data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                                  data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                  var dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                  var anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                  var strDiaResolucion = $scope.numeroALetras(dia);
                                  var strAnioResolucion = $scope.numeroALetras(anio, false);
                                  utilsService.generarOpinionRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  $timeout(function() {
                                    utilsService.generarProvidenciaRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);                                    
                                  }, 2000);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 47:
                          case 7:
                            tareaService.getConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                var esLicencia = true;
                                if (data.tcTask.taskId == 47) {
                                  esLicencia = false;
                                }
                                utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);
                                if (data.ttPadre.aprobado == 1) {
                                  if (esLicencia) {
                                    $timeout(function() {
                                      utilsService.generarNotificacionLicencia(res.data[0], $scope.loginData.usuario, $scope.dataEmpresa);
                                    }, 1000);
                                  } else {
                                    data.aprobado = 1;
                                    var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                                    var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                                    var strDiaResolucion = $scope.numeroALetras(dia);
                                    var strAnioResolucion = $scope.numeroALetras(anio, false);
                                    var esPoa = false;
                                    var noTurno = 1;
                                    utilsService.generarOficioRequisitoPoa(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, esPoa, noTurno);
                                  }
                                  if (data.ttGestion.ttResumenGestion.tcTipoGarantia != null) {
                                    if (data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 2) { //poliza de fianzas
                                      $timeout(function() {
                                          data.ttPadre.ttSeguimientoTarea.analisis = JSON.parse(data.ttPadre.ttSeguimientoTarea.analisis);
                                          data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                          var strVigencia = "";
                                          var analisis = data.ttPadre.ttSeguimientoTarea.analisis;
                                          var row = analisis[analisis.length - 1];
                                          var vigencia = row.vigencia;
                                          var strFecha = vigencia.substring(vigencia.indexOf('al') + 3);
                                          var parts = strFecha.split('/');
                                          var fin = new Date(parts[2], parseInt(parts[1]) - 1, parts[0]);
                                          var ini = new Date(data.ttPadre.ttSeguimientoTarea.fundamento.fechaIni);
                                          dia = $filter( 'date' )(ini, 'dd' );
                                          anio = $filter( 'date' )(ini, 'yyyy' );
                                          strVigencia = $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(ini.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                          dia = $filter( 'date' )(fin, 'dd' );
                                          anio = $filter( 'date' )(fin, 'yyyy' );
                                          strVigencia += " al " + $scope.numeroALetras(dia, false) + " de " + utilsService.getMonthName(fin.getMonth() + 1) + " del año " + $scope.numeroALetras(anio, false);
                                          utilsService.generarCartaPoliza(data, strVigencia, $scope.loginData.usuario, $scope.dataEmpresa);
                                      }, 1000);
                                    }
                                  }
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 9:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                try {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                } catch (e) {}
                                utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 10:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                try {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                } catch (e) {}
                                utilsService.generarNotificacionEnmiendaRegional(data, $scope.loginData.usuario, $scope.dataEmpresa);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 11:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                try {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                } catch (e) {}
                                utilsService.generarNotificacionEnmienda(data, $scope.loginData.usuario, $scope.dataEmpresa);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 12:
                            gestionService.getGestionParaPoa( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                var dia = $filter( 'date' )(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                var anio = $filter( 'date' )(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                var strDiaResolucion = $scope.numeroALetras(dia);
                                var strAnioResolucion = $scope.numeroALetras(anio, false);
                                data.ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(data.ttTarea.ttSeguimientoTarea.fundamento);
                                utilsService.generarPoaSubregional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                $timeout(function() {
                                  utilsService.generarNotificacionRegionalPoa(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                }, 1000);
                                $timeout(function() {
                                  utilsService.generarCedulaNotificacionPoa(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                }, 1000);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 14:
                            tareaService.getConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                try {
                                  data.ttSeguimientoTarea.enmienda = JSON.parse(data.ttSeguimientoTarea.enmienda);
                                } catch (e) {}
                                try {
                                  data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                } catch (e) {}
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                if (parseInt(data.aprobado) == 2) {
                                   utilsService.generarEnmiendaMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                } else {
                                  utilsService.generarOpinionMonitoreo(data, $scope.loginData.usuario, $scope.dataEmpresa, 1);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 15:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                utilsService.generarProvidenciaTrasladoEnmienda(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                //si es fiduciaria y solicitaron enmienda
                                if ((data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 1 || data.ttGestion.ttResumenGestion.tcTipoGarantia.tipoGarantiaId == 7)
                                  && data.tcPersonaMonitoreo != null) {
                                  $timeout(function() {
                                    utilsService.generarSolicitudAval(data, 1, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, 1);
                                  }, 2000);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 16:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                utilsService.generarSolicitudAval(data, 1, $scope.loginData.usuario, $scope.dataEmpresa, null, 1);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 19:
                            tareaService.getDatosSuspension( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                var tarea = data.ttTarea;
                                dia = $filter( 'date' )(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                utilsService.generarProvidenciaTrasladoSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 20:
                            tareaService.getConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                if (data.ttSeguimientoTarea.codigo == null) {
                                  data.ttSeguimientoTarea.codigo = '';
                                }
                                utilsService.generarOpinionJuridicaSuspension(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla)
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 21:
                            tareaService.getDatosSuspensionDesdeHijo( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                var tarea = data.ttTarea;
                                dia = $filter( 'date' )(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(tarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                utilsService.generarDecisionSuspension(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 25:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                utilsService.generarProvidenciaReasignar(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 34:
                            tareaService.getDatosTareaSolicitudPoa( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {                        
                                var ttSolicitudPoa = res.data[0];
                                var ttGestion = ttSolicitudPoa.ttGestion;
                                var ttTarea = ttSolicitudPoa.ttTarea;
                                var hayFiduciaria = false;
                                if (ttSolicitudPoa.tcTipoGarantia != null) {
                                  if (ttSolicitudPoa.tcTipoGarantia.tipoGarantiaId == 1
                                    || ttSolicitudPoa.tcTipoGarantia.tipoGarantiaId == 7) {
                                    hayFiduciaria = true;
                                  }
                                }
                                ttTarea.ttGestion = ttGestion;
                                if (hayFiduciaria) {
                                  utilsService.generarSolicitudAval(ttTarea, ttSolicitudPoa.numero, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, ttSolicitudPoa);
                                } else {
                                  var dia = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'dd');
                                  var anio = $filter('date')(new Date(ttTarea.fechaFinalizacion), 'yyyy');
                                  var strDiaResolucion = $scope.numeroALetras(dia);
                                  var strAnioResolucion = $scope.numeroALetras(anio, false);
                                  utilsService.generarOficioRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                }
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                            case 35:
                              tareaService.getTareaAvalConPadre( item.tareaId ).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  var ttSolicitudPoa = res.data[0];
                                  var ttGestion = ttSolicitudPoa.ttGestion;
                                  var ttTarea = ttSolicitudPoa.ttTarea;
                                  ttTarea.ttGestion = ttGestion;
                                  try {
                                    ttTarea.ttSeguimientoTarea.enmienda = JSON.parse(ttTarea.ttSeguimientoTarea.enmienda);
                                  } catch (e) {}
                                  try {
                                    ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(ttTarea.ttSeguimientoTarea.fundamento);
                                  } catch (e) {}
                                  if (ttTarea.esEnmienda == 1) {
                                    utilsService.generarEnmiendaMonitoreo(ttTarea, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  } else {
                                    utilsService.generarOpinionMonitoreo(ttSolicitudPoa, $scope.loginData.usuario, $scope.dataEmpresa, ttSolicitudPoa.numero, $scope.loginData.sigla);
                                  }
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                              break;
                          case 36:
                            tareaService.getTareaRequisitoPoa( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var ttSolicitudPoa = res.data[0];
                                var ttTarea = ttSolicitudPoa.ttTarea;
                                var ttGestion = ttSolicitudPoa.ttGestion;
                                ttTarea.aprobado = 1;
                                ttTarea.ttGestion = ttGestion;
                                var dia = $filter( 'date' )(new Date(ttTarea.fechaFinalizacion), 'dd' );
                                var anio = $filter( 'date' )(new Date(ttTarea.fechaFinalizacion), 'yyyy' );
                                var strDiaResolucion = $scope.numeroALetras(dia);
                                var strAnioResolucion = $scope.numeroALetras(anio, false);
                                utilsService.generarOficioRequisitoPoa(ttSolicitudPoa, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 45:
                            tareaService.get( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                dia = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                anio = $filter( 'date' )(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                strDia = $scope.numeroALetras(dia);
                                strAnio = $scope.numeroALetras(anio, false);
                                utilsService.generarProvidenciaTrasladoCancelarEnmienda(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 46:
                            gestionService.getGestionParaTareaConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                data.ttSeguimientoTarea.fechaProvidencia = new Date(data.ttSeguimientoTarea.fechaProvidencia);
                                data.ttSeguimientoTarea.fundamento = JSON.parse(data.ttSeguimientoTarea.fundamento);
                                if (data.ttSeguimientoTarea.analisis != null) {
                                  data.ttSeguimientoTarea.analisis = JSON.parse(data.ttSeguimientoTarea.analisis);
                                }
                                var dia = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                var anio = $filter('date')(new Date(data.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                var strDiaResolucion = $scope.numeroALetras(dia);
                                var strAnioResolucion = $scope.numeroALetras(anio, false);
                                data.ttPadre.ttSeguimientoTarea.fundamento = JSON.parse(data.ttPadre.ttSeguimientoTarea.fundamento);
                                utilsService.generarResolucionAprovechamiento(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                utilsService.generarProvidenciaRegional(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                            case 49:
                              gestionService.getGestionParaPoa( item.tareaId ).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  var data = res.data[0];
                                  var dia = $filter( 'date' )(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd' );
                                  var anio = $filter( 'date' )(new Date(data.ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy' );
                                  var strDiaResolucion = $scope.numeroALetras(dia);
                                  var strAnioResolucion = $scope.numeroALetras(anio, false);
                                  data.ttTarea.ttSeguimientoTarea.fundamento = JSON.parse(data.ttTarea.ttSeguimientoTarea.fundamento);
                                  utilsService.oficioSolicitudLicencia(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                                  $timeout(function() {
                                    utilsService.generarCedulaNotificacionPoa(data, $scope.loginData.usuario, $scope.dataEmpresa);
                                  }, 2000);
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                              break;
                          case 50:
                            gestionService.getGestionParaLicencia( item.tareaId ).then( function ( res ) {
                              if ( res.status == 1 ) {
                                var data = res.data;
                                var ttTarea = data.ttTarea;
                                var dia = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'dd');
                                var anio = $filter('date')(new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia), 'yyyy');
                                var strDiaResolucion = $scope.numeroALetras(dia, false);
                                var strAnioResolucion = $scope.numeroALetras(anio, false);
                                ttTarea.ttGestion = data.ttGestion;
                                ttTarea.ttSeguimientoTarea.fechaProvidencia = new Date(ttTarea.ttSeguimientoTarea.fechaProvidencia);
                                var tareaId = null;
                                utilsService.generarLicenciaForestal(data, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId);
                                $timeout(function () {
                                  utilsService.generarProvidenciaRegional(ttTarea, strDiaResolucion, strAnioResolucion, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla, tareaId, true);
                                }, 1000);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 51:
                            tareaService.getConPadre( item.tareaId ).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                var data = res.data[0];
                                data.ttSeguimientoTarea.fechaProvidencia = new Date();
                                var esLicencia = true;
                                utilsService.generarCedulaNotificacion(data, $scope.loginData.usuario, $scope.dataEmpresa, esLicencia);                                
                                $timeout(function() {
                                  utilsService.generarNotificacionLicencia(data, $scope.loginData.usuario, $scope.dataEmpresa, null, esLicencia);
                                }, 500);
                              } else {
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          default:
                            toastr.info('Opción no válida', 'No se han encontrado un opción');
                        }
                      }

                    }]
                });
              }

              $scope.verUltimaTarea = function ( item ) {
                $scope.row = item;
                ngDialog.open({
                  template: 'app/tarea/cancelar.tarea.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEdit',
                  resolve: {
                    dataTarea: ['tareaService',
                      function ( tareaService ){
                        return tareaService.getUltimaTarea(item.ttGestion.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                    function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {

                      $scope.dataTarea = dataTarea.data[0];

                      $scope.verDocumento = function ( item ) {

                      }

                    }]
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                  var fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                  var filtro = {
                    fechaIni : new Date(fechaIni),
                    fechaFin : new Date(fechaFin),
                    estadoId : $scope.data.estadoId,
                    subregionId : $scope.data.subregionId,
                    personaId: $scope.data.personaId,
                    elaboradorId: $scope.data.elaboradorId
                  }
                  gestionService.getListaSeguimiento(filtro).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }//submitForm

            }]
        })
        .state('index.seguimiento.exento', {
          url: '/exento',
          templateUrl: 'app/seguimiento/exento.list.tpl.html',
          resolve: {
            dataSeguimiento: ['gestionService', 'authService', '$filter',
              function ( gestionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                var begin = new Date();
                var now = new Date();
                begin.setDate(now.getDate() - 90);
                var fechaIni = $filter( 'date' )(begin, 'yyyy-MM-dd 00:00:00' );
                var fechaFin = $filter( 'date' )(now, 'yyyy-MM-dd 23:59:59' );
                var filtro = {
                  fechaIni : new Date(fechaIni),
                  fechaFin : new Date(fechaFin),
                  estadoId : 5,
                  subregionId : loginData.subregionId,
                  elaboradorId: 1,
                  personaId : loginData.regionId
                }
                if (loginData.perfilId == 16 || loginData.perfilId == 21 || loginData.perfilId == 36) {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                } else {
                  return gestionService.getListaSeguimiento(filtro);
                }
              }],
            dataRegion: ['regionService',
              function ( regionService ){
                return regionService.listaActiva();
              }],
            dataSubregion: ['subregionService', 'authService', '$filter',
              function ( subregionService, authService, $filter ){
                var loginData = authService.getLocalData( 'loginData' + appSettings.sistemaId );
                if (loginData.perfilId == 16 || loginData.perfilId == 21 || loginData.perfilId == 36) {
                  var res = {
                    status : 'OK',
                    message : 'Datos cargados',
                    data : []
                  };
                  return res;
                } else {
                  return subregionService.listaPorRegion(loginData.regionId);
                }
              }]
          },
          controller: ['$scope', '$state', 'dataSeguimiento', 'authService', '$filter', 'exentoService', 'toastr', '$base64',
            'ngDialog', 'tareaService', 'utilsService', 'dataRegion', 'dataSubregion', 'subregionService',
            function (  $scope,   $state,   dataSeguimiento, authService, $filter, exentoService, toastr, $base64,
            ngDialog, tareaService, utilsService, dataRegion, dataSubregion, subregionService) {
              $scope.dataSubregion = dataSubregion.data;
              $scope.dataRegion = dataRegion.data;
              $scope.dataSubregion.unshift({subregionId: 0, subregionDesc: 'Todos'});
              $scope.mostrarRegion = false;
              $scope.mostrarFiltroExpediente = false;
              authService.removeLocalData('dataEspecie');
              if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 21 || $scope.loginData.perfilId == 36) {
                $scope.mostrarRegion = true;
                $scope.mostrarFiltroExpediente = true;
              }
              $scope.dataEstado = [
                {estadoId: -1, estadoDesc: 'Todos'},
                {estadoId: 0, estadoDesc: 'Anulado'},
                {estadoId: 1, estadoDesc: 'Realizando solicitud'},
                {estadoId: 2, estadoDesc: 'Solicitud elaborada'},
                {estadoId: 3, estadoDesc: 'Solicitado carga inicial'},
                {estadoId: 4, estadoDesc: 'En trámite'},
                {estadoId: 5, estadoDesc: 'Aprobado'},
                {estadoId: 6, estadoDesc: 'Finalizado'}
              ];

              var now = new Date();
              var begin = $scope.addDays(now, -90);

              $scope.data = {
                fechaIni : begin,
                fechaFin : now,
                estadoId : 5,
                elaboradorId: 1,
                subregionId: $scope.loginData.subregionId,
                personaId: $scope.loginData.regionId
              }

              $scope.etiquetaEstado = function (estadoId) {
                for (var i = 0; i < $scope.dataEstado.length; i++) {
                  if ($scope.dataEstado[i].estadoId == estadoId) {
                    return $scope.dataEstado[i].estadoDesc;
                  }
                }
                return 'Desconocido';
              }

              $scope.habilitarVerSolicitud = function ( estadoId ) {
                if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 21 || $scope.loginData.perfilId == 36 && estadoId > 0) {
                  return true;
                }
                return false;
              }

              $scope.mostrarSaldo = function ( estadoId ) {
                if (estadoId == 5) {
                  return true;
                }
                return false;
              }

              $scope.mostrarMasOpciones = function () {
                if ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 21 || $scope.loginData.perfilId == 36) {
                  return true;
                }
                return false;
              }

              $scope.gridOptions = angular.copy( $scope.gridOptionsSelection );
              $scope.gridOptions.enableFiltering = true;
              $scope.gridOptions.columnDefs = [
                { field:'codigo', name: 'codigo', displayName: 'RNF', width: '10%' },
                { field:'tcPersonaCrea.personaDesc', name: 'propietario', displayName: 'Propietario/Rep.' },
                { field:'fechaIngreso', name: 'fechaIngreso', displayName: 'Fecha ingreso', width: '10%', type: 'date', cellFilter: 'date:\'dd/MM/yyyy\'' },
                { field:'area', name: 'area', displayName: 'Área', width: '10%' },
                { name: 'estadoId', displayName: "Estado", field: 'estadoId', enableFiltering: false, width: '10%',
                  cellTemplate:'<div class="ui-grid-cell-contents">{{grid.appScope.etiquetaEstado(row.entity.estadoId)}}</div>'
                },
                { name: 'Opciones', enableFiltering: false, width: '10%',
                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarSaldo(row.entity.estadoId)" ng-click="grid.appScope.saldoExento(row.entity)" title="Saldo disponible"><i class="fa fa-bar-chart text-success" aria-hidden="true"></i></button>'
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 1" ng-click="grid.appScope.imprimirFormato(row.entity)" title="Ver solicitud de carga inicial"><i class="fa fa-file-pdf-o text-danger" aria-hidden="true"></i></button>'
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.habilitarVerSolicitud(row.entity.estadoId)" ng-click="grid.appScope.abrirSolicitud(row.entity)" title="Abrir elaboración de solicitud de carga inicial"><i class="fa fa-eye text-info" aria-hidden="true"></i></button>'
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 2" ng-click="grid.appScope.verHistorialExento(row.entity)" title="Ver historial de tareas"> <i class="fa fa-list text-primary"></i> </button> '
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="row.entity.estadoId > 2" ng-click="grid.appScope.regresarTarea(row.entity)" title="Anular última tarea"> <i class="text-red fa fa-remove text-danger"></i> </button> '
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarSaldo(row.entity.estadoId)" ng-click="grid.appScope.cambiarEspecie(row.entity)" title="Cambiar de especie"><i class="fa fa-tree text-success" aria-hidden="true"></i></button>'
                  + ' <button type="button" class="btn btn-default btn-xs" ng-show="grid.appScope.mostrarMasOpciones()" ng-click="grid.appScope.masOpciones(row.entity)" title="Más opciones"> <i class="fa fa-ellipsis-h"></i> </button> '
                  + '</span></div>' }
              ];
              $scope.gridOptions.data = dataSeguimiento.data;

              $scope.setRegion = function ( item ) {
                if (item != null) {
                  subregionService.listaPorRegion(item.regionId).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.dataSubregion = res.data;
                      $scope.dataSubregion.unshift({subregionId: 0, subregionDesc: 'Todos'});
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.saldoExento = function ( item ) {
                ngDialog.open({
                  template: 'app/exento/saldo.exento.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueMunicipio',
                  name: 'dialogMunicipio',
                  resolve: {
                    dataExento: ['exentoService',
                      function ( exentoService ){
                        return exentoService.getSaldo(item.exentoId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataExento',
                    function ($scope, $state, toastr, dataExento) {

                      $scope.usuarioInterno = true;

                      if (dataExento.status == 1) {
                        $scope.dataSaldo = dataExento.data;
                      }

                    }]
                })
              }

              $scope.dataEspecie = [];

              $scope.cambiarEspecie = function ( item ) {
                $scope.dataSaldo = [];
                $scope.ttExento = item;
                ngDialog.open({
                  template: 'app/exento/especie.exento.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEspecie',
                  name: 'dialogEspecie',
                  resolve: {
                    dataExento: ['exentoService',
                      function ( exentoService ){
                        return exentoService.getSaldo(item.exentoId);
                      }],
                    dataEspecie: ['especieService', 'authService', 
                      function ( especieService, authService ){
                        var dataEspecie = authService.getLocalData('dataEspecie');
                        if (dataEspecie == null) {
                          dataEspecie = [];
                        }
                        if (dataEspecie.length > 0) {
                          return {
                            status : 'OK',
                            message : 'Datos cargados',
                            data : dataEspecie
                          }
                        }
                        return especieService.listaActiva();
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataExento', 'dataEspecie',
                    function ($scope, $state, toastr, dataExento, dataEspecie) {

                      $scope.dataEspecie = dataEspecie.data;
                      authService.saveLocalData('dataEspecie', dataEspecie.data);

                      $scope.usuarioInterno = true;

                      if (dataExento.status == 1) {
                        $scope.dataSaldo = dataExento.data;
                      }

                      $scope.submitCambiarEspecie = function ( isValid ) {
                        swal({
                          title: 'Confirmación',
                          text: "¿Está seguro (a) de modificar la información? El proceso no se podrá reversar",
                          icon: "warning",
                          type: "warning",
                          showCancelButton: true,
                          confirmButtonClass: "btn-success",
                          cancelButtonClass: "btn-danger",
                          confirmButtonText: "Confirmar",
                          cancelButtonText: "Cancelar",
                          closeOnConfirm: true,
                          closeOnCancel: true
                        },
                        function(isConfirm) {
                          if (isConfirm) {
                            var especies = $scope.dataSaldo.especies;                            
                            var cambios = [];
                            for (var i = 0; i < especies.length; i++) {
                              var row = especies[i];
                              if (row.tcEspecieNueva != null) {
                                if (row.tcEspecieNueva.especieId == row.tcEspecie.especieId) {
                                  toastr.error('No es posible continuar debido a que ha seleccionado la misma especie');
                                  return;
                                }
                                var item = {
                                  especieId: row.tcEspecie.especieId,
                                  especieNuevoId: row.tcEspecieNueva.especieId,
                                  especieExentoId: row.especieExentoId
                                }
                                cambios.push(item);
                              }
                            }
                            if (cambios.length > 0) {
                              tareaService.cambiarEspecie(cambios, $scope.ttExento.exentoId).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  toastr.success(res.message);
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            } else {
                              ngDialog.close();
                            }
                          }
                        });
                      }

                    }]
                })
              }

              $scope.imprimirFormato = function ( item ) {
                exentoService.get( item.exentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    utilsService.generarSolicitudExento(res.data[0], $scope.dataEmpresa);
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.abrirSolicitud = function (item) {
                $state.go('index.exento.edit', {exentoId : $base64.encode(item.exentoId)});
              }

              $scope.mostrarBusquedaExpediente = false;
              $scope.obligatorioExpediente = false;
              $scope.expediente = null;
              $scope.mostrarBusquedaPersona = false;
              $scope.abrirBusquedaExpediente = function () {
                $scope.mostrarBusquedaExpediente = !$scope.mostrarBusquedaExpediente;
                $scope.mostrarBusquedaPersona = false;
                $scope.obligatorioPersonaDesc = false;
              }

              $scope.abrirBusquedaPersona = function () {
                $scope.mostrarBusquedaPersona = !$scope.mostrarBusquedaPersona;
                $scope.mostrarBusquedaExpediente = false;
                $scope.obligatorioExpediente = false;
              }

              $scope.buscarRnf = function () {
                if ($scope.expediente == null || $scope.expediente.length <= 0) {
                  $scope.obligatorioExpediente = true;
                } else {
                  exentoService.buscarSolicitudPorRnf( $scope.expediente.trim() ) .then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                      if ($scope.loginData.perfilId == 8 || $scope.loginData.perfilId == 12) {
                        toastr.success( res.message );
                      }
                    } else {
                      $scope.gridOptions.data = [];
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }

              $scope.buscarPersona = function () {
                if ($scope.personaDesc == null || $scope.personaDesc.length <= 1) {
                  $scope.obligatorioPersonaDesc = true;
                  toastr.error('Debe indicar el nombre o apellido');
                  return;
                }
                var filtro = {
                  filtro: $scope.personaDesc
                }
                exentoService.getPorInvolucrado( filtro ) .then( function ( res ) {
                  if ( res.status == "OK" ) {
                    $scope.dataHistorial = res.data;
                    if ($scope.dataHistorial.length > 0) {
                      ngDialog.open({
                        template: 'app/seguimiento/involucrado.exento.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                        },
                        controller: ['$scope', '$state', 'toastr', 'tareaService',
                          function ($scope, $state, toastr, tareaService) {

                          }]
                      });
                    } else {
                      toastr.error('No se encontraron datos relacionados');
                    }
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.imprimirTareaExento = function ( item, sigla, exentoId ) {                
                switch (item.tcTask.taskId) {
                  case 37:
                    if (exentoId == null) {
                      toastr.warning('Este tarea no produce ninguna documento');
                      return;
                    }
                    exentoService.get( exentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        utilsService.generarSolicitudExento(res.data[0], $scope.dataEmpresa);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 38:
                    toastr.warning('Este tarea no produce ninguna documento');
                    break;
                  case 39:
                    toastr.warning('Este tarea no produce ninguna documento');
                    break;
                  case 22:
                    tareaService.getDatosTareaExento( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        if (data.estadoId == 20) {
                          swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                        } else {
                          var dia = $filter( 'date' )(new Date(data.fechaFinalizacion), 'dd' );
                          var anio = $filter( 'date' )(new Date(data.fechaFinalizacion), 'yyyy' );
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.generarProvidenciaTrasladoExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 23:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        var ventas = data.ttExento.ventas;
                        for (var i = 0; i < ventas.length; i++) {
                          var saldos = ventas[i].saldos;
                          for (var j = 0; j < saldos.length; j++) {
                            saldos[j].lenia = saldos[j].ttSaldoCargaVenta.lenia;
                            saldos[j].troza = saldos[j].ttSaldoCargaVenta.troza;
                            saldos[j].total = saldos[j].ttSaldoCargaVenta.volumen;
                          }
                        }
                        if (data.observaciones != null) {
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) {
                            
                          }
                        }
                        utilsService.generarInformeCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 24:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        var especies = data.ttExento.especies;
                        for (var i = 0; i < especies.length; i++) {
                          especies[i].saldo = especies[i].ttEspecieSaldoCarga.saldo;
                          especies[i].reserva = especies[i].ttEspecieSaldoCarga.reserva;
                          especies[i].volumen = especies[i].ttEspecieSaldoCarga.volumen;
                        }
                        if (data.observaciones != null) {
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) {
                            
                          }
                        }
                        utilsService.generarNotificacionCargaInicial(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 26:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        var dia = $filter( 'date' )(new Date(data.fechaFinalizacion), 'dd' );
                        var anio = $filter( 'date' )(new Date(data.fechaFinalizacion), 'yyyy' );
                        var strDia = $scope.numeroALetras(dia, false);
                        var strAnio = $scope.numeroALetras(anio, false);
                        utilsService.generarProvidenciaVentaNota(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 27:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        data.observaciones = JSON.parse(data.observaciones);
                        utilsService.generarInformeVentaNota(data, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 28:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        if (data.aprobado == 0) {
                          var dia = $filter( 'date' )(new Date(data.ttPadre.fechaFinalizacion), 'dd' );
                          var anio = $filter( 'date' )(new Date(data.ttPadre.fechaFinalizacion), 'yyyy' );
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          dia = $filter( 'date' )(new Date(data.fechaFinalizacion), 'dd' );
                          anio = $filter( 'date' )(new Date(data.fechaFinalizacion), 'yyyy' );
                          var strDiaAdmision = $scope.numeroALetras(dia, false);
                          var strAnioAdmision = $scope.numeroALetras(anio, false);
                          utilsService.generarResolucionDenegacionVenta(data, strDia, strAnio, strDiaAdmision, strAnioAdmision, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        } else {
                          utilsService.generarNotificacionPagoVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 29:
                    tareaService.getDataExentoTareaFinalizada( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        var dia = $filter( 'date' )(new Date(data.ttPadre.fechaFinalizacion), 'dd' );
                        var anio = $filter( 'date' )(new Date(data.ttPadre.fechaFinalizacion), 'yyyy' );
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio, false);
                        dia = $filter( 'date' )(new Date(data.fechaFinalizacion), 'dd' );
                        anio = $filter( 'date' )(new Date(data.fechaFinalizacion), 'yyyy' );
                        var strDiaFinalizacion = $scope.numeroALetras(dia, false);
                        var strAnioFinalizacion = $scope.numeroALetras(anio, false);
                        if (data.observaciones == null) {
                          data.observaciones = '';
                        }
                        try {
                          data.observaciones = JSON.parse(data.observaciones);
                        } catch (error) {
                          data.observaciones = {
                            resolucionRevalidacion: data.observaciones
                          }
                        }
                        utilsService.generarResolucionVenta(data, strDia, strAnio, strDiaFinalizacion, strAnioFinalizacion, $scope.loginData.usuario, $scope.dataEmpresa, sigla);
                        utilsService.generarCedulaResolucionVenta(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 30:
                    tareaService.obtenerTareaProvidenciaInformeVenta( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        var dia = $filter( 'date' )(new Date(data.fechaFinalizacion), 'dd' );
                        var anio = $filter( 'date' )(new Date(data.fechaFinalizacion), 'yyyy' );
                        var strDia = $scope.numeroALetras(dia);
                        var strAnio = $scope.numeroALetras(anio);
                        utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, null);
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 31:
                    tareaService.getTareaInformeVenta( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        if (data.esEnmienda == 1) {
                          data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                          delete data.ttInformeVenta;
                          try {
                            data.observaciones = JSON.parse(data.observaciones);
                          } catch (error) {
                            data.observaciones = {
                              razon: [
                                {descripcion: data.observaciones}
                              ]
                            }
                          }
                          utilsService.enmiendaTecnicaExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                        } else {
                          data.ttInformeVenta.conclusiones = JSON.parse(data.ttInformeVenta.conclusiones);
                          data.observaciones = JSON.parse(data.observaciones);
                          utilsService.generarDictamenInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, null);
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 32:
                    tareaService.getTareaInformeVentaNotificacion( item.tareaExentoId ).then( function ( res ) {
                      if ( res.status == "OK" ) {
                        var data = res.data[0];
                        if (data.aprobado == 2) {
                          var dia = $filter('date')(new Date(data.fechaFinalizacion), 'dd');
                          var anio = $filter('date')(new Date(data.fechaFinalizacion), 'yyyy');
                          var strDia = $scope.numeroALetras(dia);
                          var strAnio = $scope.numeroALetras(anio);
                          data.ttExento = data.ttInformeVenta.ttVentaExento.ttExento;
                          utilsService.generarProvidenciaInformeVenta(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          if (data.ttPadre.aprobado == 0) {
                            data.ttPadre.observaciones = JSON.parse(data.ttPadre.observaciones);
                          }
                          utilsService.generarNotificacionInformeVenta(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                      } else {
                        toastr.error( res.message );
                      }
                    }, function ( error ) {
                      toastr.error( error );
                    });
                    break;
                  case 33:
                    tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        if (data.aprobado == -1) {
                          swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                        } else {
                          var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                          var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          data.observaciones = JSON.parse(data.observaciones);
                          if (data.aprobado == 1) {
                            data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                            data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                            var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                            if (ventas == null) {
                              ventas = [];
                            }
                            for (let i = 0; i < ventas.length; i++) {
                              var element = ventas[i];
                              element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                            }
                          }
                          if (data.ttActualizacionExento.tipoActualizacionId == 2) {
                            data.ttActualizacionExento.rodales = data.ttActualizacionExento.thActualizacionExento.rodales;
                            utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                          } else {
                            utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                          }
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  case 40:
                    exentoService.getInformeVenta(item.informeVentaId).then(function (res) {
                      if (res.status == "OK") {
                        var data = res.data[0];
                        data.requisito = JSON.parse(data.requisito);
                        utilsService.generarConstanciaRecepcionExento(data, true, $scope.loginData.usuario, $scope.dataEmpresa);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  case 63:
                    tareaService.getEnmiendaInforme(item.tareaExentoId).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        data.observaciones = JSON.parse(data.observaciones);
                        utilsService.enmiendaSubregionalExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  case 64:
                    tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        try {
                          data.observaciones = JSON.parse(data.observaciones);
                        } catch (error) {}
                        utilsService.dictamenTecnicoActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  case 65:
                    tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                        if (ventas == null) {
                          ventas = [];
                        }
                        for (let i = 0; i < ventas.length; i++) {
                          var element = ventas[i];
                          element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                        }
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        if (data.aprobado == 2) {
                          var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                          var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          utilsService.providenciaActualizacionExento(data, strDia, strAnio, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        } else {
                          data.observaciones = JSON.parse(data.observaciones);
                          utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, $scope.loginData.sigla);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  case 66:
                    tareaService.getTareaSolicitudActualizacionExento(item.tareaExentoId).then(function (res) {
                      if (res.status == 1) {
                        var data = res.data;
                        data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                        if (data.aprobado == -1) {
                          swal('Solicitud anulada', 'Motivo: ' + data.observaciones);
                        } else {
                          var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                          var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                          var strDia = $scope.numeroALetras(dia, false);
                          var strAnio = $scope.numeroALetras(anio, false);
                          data.observaciones = JSON.parse(data.observaciones);
                          if (data.aprobado == 1) {
                            data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                            data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                            var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                            for (let i = 0; i < ventas.length; i++) {
                              var element = ventas[i];
                              element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                            }
                          }
                          utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                        }
                      } else {
                        toastr.error(res.message);
                      }
                    }, function (error) {
                      toastr.error(error);
                    });
                    break;
                  default:

                }
              }

              $scope.imprimirSolicitudVenta = function ( item ) {
                exentoService.getDatosVenta( item.ventaExentoId ).then( function ( res ) {
                  if ( res.status == "OK" ) {
                    var data = res.data[0];
                    utilsService.generarSolicitudVentaNotaExento(data, $scope.dataEmpresa);
                  } else {
                    toastr.error( res.message );
                  }
                }, function ( error ) {
                  toastr.error( error );
                });
              }

              $scope.imprimirInformeTrimestral = function (item) {
                exentoService.getInformeVenta(item.informeVentaId).then(function (response) {
                  if (response.status == 'OK') {
                    var data = response.data[0];
                    data.conclusiones = JSON.parse(data.conclusiones);
                    utilsService.generarInformeExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                  } else {
                    toastr.error(response.message);
                  }
                }, function (error) {
                  toastr.error(error);
                });
              }

              $scope.etiquetaEstadoInforme = function (estadoId) {
                switch (estadoId) {
                  case 1: return 'Elaborando';
                  case 2: return 'Enviado';
                  case 3: return 'En trámite';
                  case 4: return 'Aprobado';
                  case 5: return 'Enmienda resuelta';
                  case 6: return 'Esperando enmienda';
                  case 99: return 'Denegado';
                  default: return 'Anulado';
                }
              }


              $scope.etiquetaEstadoActualizacionExento = function (estadoId) {
                switch (estadoId) {
                  case 1: return 'En edición';
                  case 2: return 'En trámite';
                  case 3: return 'Autorizado';
                  case 99: return 'Denegado';
                  default: return 'Inactivo';
                }
              }

              $scope.generarSolicitudActualizacionExento = function (item) {
                exentoService.getActualizacion(item.actualizacionExentoId).then(function (res) {
                  if (res.status == 1) {
                    var data = res.data;
                    data.fechaSolicitud = new Date(data.fechaSolicitud);
                    utilsService.generarSolicitudActualizacionExento(data, $scope.dataEmpresa);
                  } else {
                    toastr.error(res.message);
                  }
                }, function (error) {
                  toastr.error(error);
                });
              }

              $scope.resolucionSolicitudActualizacion = function (item) {
                tareaService.resolucionSolicitudActualizacionExento(item.actualizacionExentoId).then(function (res) {
                  if (res.status == 1) {
                    if (res.status == 1) {
                      var data = res.data;
                      var ventas = data.ttActualizacionExento.thActualizacionExento.ventas;
                      if (ventas == null) {
                        ventas = [];
                      }
                      for (let i = 0; i < ventas.length; i++) {
                        var element = ventas[i];
                        element.fechaFinalizacion = $filter('date')(new Date(element.fechaFinalizacion), 'dd/MM/yyyy');
                      }
                      data.fechaFinalizacion = new Date(data.fechaFinalizacion);
                      data.observaciones = JSON.parse(data.observaciones);
                      if (data.ttActualizacionExento.tipoActualizacionId == 1) {
                        var dia = $filter('date')(data.fechaFinalizacion, 'dd');
                        var anio = $filter('date')(data.fechaFinalizacion, 'yyyy');
                        var strDia = $scope.numeroALetras(dia, false);
                        var strAnio = $scope.numeroALetras(anio, false);
                        if (data.aprobado == 1) {
                          data.ttActualizacionExento.fechaActualizacion = new Date(data.ttActualizacionExento.fechaActualizacion);
                          data.ttActualizacionExento.fechaVencimiento = new Date(data.ttActualizacionExento.fechaVencimiento);
                        }
                        utilsService.dictamenSubregionalActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa);
                      } else {
                        utilsService.notificacionActualizacionExento(data, $scope.loginData.usuario, $scope.dataEmpresa, data.tcPersonaAsignada.sigla);
                      }
                    } else {
                      toastr.error(res.message);
                    }
                  } else {
                    toastr.error(res.message);
                  }
                }, function (error) {
                  toastr.error(error);
                });
              }

              $scope.verHistorialExento = function ( item ) {
                $scope.row = item;
                ngDialog.open({
                  template: 'app/tarea/historial.exento.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEdit',
                  resolve: {
                    dataHistorial: ['tareaService',
                      function ( tareaService ){
                        return tareaService.getHistorialExento(item.exentoId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataHistorial', 
                    function ($scope, $state, toastr, dataHistorial) {
                      if (dataHistorial.status == 'OK') {
                        var tmp = dataHistorial.data[0];
                        $scope.dataTarea = tmp.tareas;
                        $scope.dataVenta = tmp.ventas;
                        $scope.dataInforme = tmp.informes;
                        $scope.dataActualizaciones = tmp.actualizaciones;


                        $scope.mostrarAnularInforme = function ( item ) {
                          if (item.tcTask.taskId == 32 && item.estadoId == 1) {
                            return true;
                          }
                          return false;
                        }

                        $scope.mostrarReasignacion = function ( estadoId ) {
                          if (estadoId == 1) {
                            return true;
                          }
                          return false;
                        }

                        $scope.mostrarAnularDuplicado = function ( estadoId ) {
                          if (estadoId == 1 && ($scope.loginData.perfilId == 16 || $scope.loginData.perfilId == 21 || $scope.loginData.perfilId == 36)) {
                            return true;
                          }
                          return false;
                        }

                        $scope.verDocumentoTareaExento = function ( item ) {
                          if (item.estadoId == 1) {
                            toastr.error('Esta tarea no se ha realizado, no hay documento que imprimir');
                            return;
                          }
                          if (item.estadoId == 20) {
                            toastr.error('Esta tarea está anulada por solicitud de regreso de tarea, no es posible imprimir');
                            return;
                          }
                          var exentoId = null;
                          if ($scope.row != null) {
                            exentoId = $scope.row.exentoId;
                          }
                          $scope.imprimirTareaExento(item, null, exentoId);
                        }
  
                        $scope.verSolicitudVenta = function ( item ) {
                          if (item.estadoId == 0) {
                            toastr.error('Esta solicitud/resolución se encuentra anulada, no se puede imprimir');
                            return;
                          }
                          if (item.fechaRecepcion == null) {
                            toastr.error('Si la solicitud/resolución se agregó en la carga inicial, se podrá imprimir hasta que la secretaria lo reciba, caso contrario no se puede imprimir');
                            return;
                          }
                          $scope.imprimirSolicitudVenta(item);
                        }

                        $scope.verInformeVenta = function (item) {
                          if (item.estadoId == 0) {
                            toastr.error('Éste informe ha sido anulado, no se puede imprimir');
                            return;
                          }
                          if (item.fechaElaboracion == null) {
                            toastr.error('Los informes agregados por el (la) técnico (a) en carga inicial no se pueden imprimir');
                            return;
                          }
                          $scope.imprimirInformeTrimestral(item);
                        }

                        $scope.regresarTareaInformeTecnico = function ( item ) {
                          swal({
                            title: 'Anular ' + item.tcTask.taskDesc,
                            text: "¿Está seguro (a) de realizar ésta acción? Esta acción no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                          function(isConfirm) {
                            if (isConfirm) {
                              tareaService.regresarTareaInformeTecnico(item.tareaExentoId).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  toastr.success( res.message );
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          });
                        }

                        $scope.anularTareaDuplicada = function ( item ) {
                          swal({
                            title: 'Anular tarea duplicada: ' + item.tcTask.taskDesc,
                            text: "¿Está seguro (a) de realizar ésta acción? Esta acción no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                          function(isConfirm) {
                            if (isConfirm) {
                              tareaService.anularTareaDuplicada(item.tareaExentoId).then( function ( res ) {
                                if ( res.status == 1 ) {
                                  toastr.success( res.message );
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          });
                        }

                        $scope.habilitarReasignarTarea = function ( item ) {
                          tareaService.getUsuarioSubregionPerfil( $scope.row.tcSubregion.subregionId, 0 ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              item.mostrarReasignacion = true;
                              item.dataPersona = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }

                        $scope.reasignarTareaExento = function ( item ) {          
                          swal({
                            title: 'Reasignar tarea: ' + item.tcTask.taskDesc,
                            text: "¿Está seguro (a) de realizar ésta acción? Esta acción no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                          function(isConfirm) {
                            if (isConfirm) {
                              tareaService.reasignarTareaExento(item).then( function ( res ) {
                                if ( res.status == 1 ) {                            
                                  toastr.success( res.message );
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          });
                        }

                      }

                    }]
                });
              }

              $scope.verUltimaTarea = function ( item ) {
                $scope.row = item;
                ngDialog.open({
                  template: 'app/tarea/cancelar.tarea.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueEdit',
                  name: 'dialogEdit',
                  resolve: {
                    dataTarea: ['tareaService',
                      function ( tareaService ){
                        return tareaService.getUltimaTarea(item.ttGestion.gestionId);
                      }]
                  },
                  controller: ['$scope', '$state', 'toastr', 'dataHistorial', 'gestionService', '$timeout',
                    function ($scope, $state, toastr, dataHistorial, gestionService, $timeout) {

                      $scope.dataTarea = dataTarea.data[0];

                      $scope.verDocumento = function ( item ) {

                      }

                    }]
                });
              }

              $scope.submitForm = function ( isValid ) {
                if ( isValid ) {
                  var fechaIni = $filter( 'date' )(new Date($scope.data.fechaIni),'yyyy-MM-dd 00:00:00' );
                  var fechaFin = $filter( 'date' )(new Date($scope.data.fechaFin),'yyyy-MM-dd 23:59:59' );
                  var filtro = {
                    fechaIni : new Date(fechaIni),
                    fechaFin : new Date(fechaFin),
                    estadoId : $scope.data.estadoId,
                    subregionId : $scope.data.subregionId,
                    personaId: $scope.data.personaId,
                    elaboradorId: $scope.data.elaboradorId
                  }
                  gestionService.getListaSeguimiento(filtro).then( function ( res ) {
                    if ( res.status == "OK" ) {
                      $scope.gridOptions.data = res.data;
                    } else {
                      toastr.error( res.message );
                    }
                  }, function ( error ) {
                    toastr.error( error );
                  });
                }
              }//submitForm

              $scope.masOpciones = function ( item ) {
                $scope.mostrar = false;
                $scope.dataOpciones = [
                  {opcionId: 1, descripcion: 'No imprime solicitud de carga, intentar corregir', mostrar: false},
                  {opcionId: 2, descripcion: 'Habilitar nueva venta por resolución vencida', mostrar: true, pantalla: 2},
                  {opcionId: 3, descripcion: 'Regresar tarea de carga inicial a subregional', mostrar: false},
                  {opcionId: 4, descripcion: 'Regresar tarea de carga inicial a técnico(a)', mostrar: false},                  
                  {opcionId: 5, descripcion: 'Actualizar vencimiento de resolución', mostrar: true, pantalla: 3},
                  {opcionId: 6, descripcion: 'Regresar tarea a usuario para que realice la solicitud de carga inicial', mostrar: false},
                  {opcionId: 7, descripcion: 'Regresar tarea de carga inicial a secretaria', mostrar: false},
                  {opcionId: 8, descripcion: 'Quitar último informe como final', mostrar: false}
                ];
                $scope.row = item;
                $scope.ttVentaExento = {};
                ngDialog.open({
                  template: 'app/seguimiento/opciones.exento.tpl.html',
                  className: 'ngdialog-theme-flat',
                  appendClassName: 'ngdialog-flat',
                  closeByDocument: false,
                  closeByEscape: true,
                  closeByNavigation: true,
                  scope: $scope,
                  id: 'uniqueMunicipio',
                  name: 'dialogMunicipio',
                  resolve: {
                  },
                  controller: ['$scope', '$state', 'toastr',
                    function ($scope, $state, toastr) {

                      $scope.reestablecer = function ( cerrarAuxiliar ) {
                        if (cerrarAuxiliar == null) {
                          cerrarAuxiliar = false;
                        }
                        $scope.pantallaUno = false;
                        $scope.pantallaDos = false;
                        $scope.pantallaTres = false;
                        $scope.pantallaCuatro = false;
                        $scope.pantallaCinco = false;
                        $scope.pantallaSeis = false;
                        if (cerrarAuxiliar) {
                          $scope.mostrar = false;
                        }
                      }

                      $scope.realizarAccion = function ( item ) {
                        $scope.opcion = item;
                        $scope.mostrar = item.mostrar;
                        $scope.reestablecer();
                        switch (item.pantalla) {
                          case 2:
                            $scope.pantallaDos = true;
                            break;
                          case 3:
                            $scope.pantallaTres = true;
                            break;
                        }
                        switch (item.opcionId) {
                          case 1:
                            swal({
                              title: item.descripcion,
                              text: "¿Está seguro (a) de realizar ésta acción? ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                exentoService.corregirErroresCargaSolicitud($scope.row.exentoId).then( function ( res ) {
                                  if ( res.status == "OK" ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          case 2:
                            exentoService.getVentaActiva($scope.row.exentoId).then( function ( res ) {
                              if ( res.status == "OK" ) {
                                $scope.ttVentaExento = res.data;
                                $scope.ttVentaExento.fechaFinalizacion = new Date($scope.ttVentaExento.fechaFinalizacion);
                              } else {
                                $scope.reestablecer(true);
                                toastr.error( res.message );
                              }
                            }, function ( error ) {
                              toastr.error( error );
                            });
                            break;
                          case 3:
                            swal({
                              title: item.descripcion,
                              text: "Considere que esto anulará todas las ventas e informes presentados ¿Está seguro de continuar? ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                var opcionId = 1; //subregional carga inicial
                                tareaService.regresarTareaExento($scope.row.exentoId, opcionId).then( function ( res ) {
                                  if ( res.status == 1 ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          case 4:
                            swal({
                              title: item.descripcion,
                              text: "Considere que esto anulará todas las ventas e informes presentados ¿Está seguro de continuar? ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                var opcionId = 2; //subregional carga inicial
                                tareaService.regresarTareaExento($scope.row.exentoId, opcionId).then( function ( res ) {
                                  if ( res.status == 1 ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          case 5:
                            $scope.ttExento = $scope.row;
                            $scope.ttExento.fechaVencimiento = new Date($scope.ttExento.fechaVencimiento);                            
                            break;
                          case 6:
                            swal({
                              title: item.descripcion,
                              text: "Considere que esto anulará todas las ventas e informes presentados y tareas ya realizadas ¿Está seguro de continuar? ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                var opcionId = 3; //carga inicial desde usuario
                                tareaService.regresarTareaExento($scope.row.exentoId, opcionId).then( function ( res ) {
                                  if ( res.status == 1 ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          case 7:
                            swal({
                              title: item.descripcion,
                              text: "Considere que esto anulará todas las ventas e informes presentados y tareas ya realizadas ¿Está seguro de continuar? ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                var opcionId = 4; //carga inicial desde usuario
                                tareaService.regresarTareaExento($scope.row.exentoId, opcionId).then( function ( res ) {
                                  if ( res.status == 1 ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          case 8:
                            swal({
                              title: item.descripcion,
                              text: "¿Está seguro (a) de realizar ésta acción? No se podrá reversar después de la confirmación ",
                              icon: "warning",
                              type: "warning",
                              showCancelButton: true,
                              confirmButtonClass: "btn-success",
                              cancelButtonClass: "btn-danger",
                              confirmButtonText: "Confirmar",
                              cancelButtonText: "Cancelar",
                              closeOnConfirm: true,
                              closeOnCancel: true
                            },
                            function(isConfirm) {
                              if (isConfirm) {
                                exentoService.quitarUltimoInformeComoFinal($scope.row.exentoId).then( function ( res ) {
                                  if ( res.status == 1 ) {
                                    toastr.success( res.message );
                                    ngDialog.close();
                                  } else {
                                    toastr.error( res.message );
                                  }
                                }, function ( error ) {
                                  toastr.error( error );
                                });
                              }
                            });
                            break;
                          default:
                            toastr.error('Esta opción no tiene acción configurada');
                            break;
                        }
                      }

                      $scope.submitPantallaDos = function( isValid ) {
                        if (isValid) {
                          swal({
                            title: 'Confirmación',
                            text: "¿Está seguro (a) de modificar la información? El proceso no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                          function(isConfirm) {
                            if (isConfirm) {
                              var ttVentaExento = $scope.ttVentaExento;
                              exentoService.actualizarFinalizacionVentaActiva(ttVentaExento).then( function ( res ) {
                                if ( res.status == "OK" ) {
                                  $scope.reestablecer(true);
                                  toastr.success( res.message );
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          });                          
                        } else {
                          toastr.error('Debe indicar los datos');
                        }
                      }

                      $scope.submitPantallaTres = function( isValid ) {
                        if (isValid) {
                          swal({
                            title: 'Confirmación',
                            text: "¿Está seguro (a) de modificar la información? El proceso no se podrá reversar",
                            icon: "warning",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonClass: "btn-success",
                            cancelButtonClass: "btn-danger",
                            confirmButtonText: "Confirmar",
                            cancelButtonText: "Cancelar",
                            closeOnConfirm: true,
                            closeOnCancel: true
                          },
                          function(isConfirm) {
                            if (isConfirm) {
                              var ttExento = $scope.ttExento;
                              exentoService.actualizarVencimientoVentaActiva(ttExento).then( function ( res ) {
                                if ( res.status == 1 ) {
                                  $scope.reestablecer(true);
                                  toastr.success( res.message );
                                  ngDialog.close();
                                } else {
                                  toastr.error( res.message );
                                }
                              }, function ( error ) {
                                toastr.error( error );
                              });
                            }
                          });                          
                        } else {
                          toastr.error('Debe indicar los datos');
                        }
                      }

                    }]
                })
              }

            }]
        })
    }
  ]
);
