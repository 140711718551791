angular.module('app.fuente', [
  'ui.router',
  'toastr',
  'app.fuentesemillera.service'
])

  .config(
    ['$stateProvider', '$urlRouterProvider',
      function ($stateProvider, $urlRouterProvider) {
        $stateProvider
          .state('index.fuente', {
            abstract: true,
            url: 'fuente/:fuenteSemilleraId',
            params: {
            },
            views: {
              '':
              {
                templateUrl: 'app/fuentesemillera/fuentesemillera.tpl.html',
                resolve: {
                  dataFuenteSemillera: ['fuentesemilleraService', '$stateParams',
                    function (fuentesemilleraService, $stateParams) {
                      var res = {
                        status : 'OK',
                        message : 'Datos cargados',
                        data : null
                      };
                      return res;
                    }],
                  dataTipoPropietario: ['tipopropietarioService', '$stateParams',
                    function (tipopropietarioService, $stateParams) {
                      return tipopropietarioService.listaActiva();
                    }],
                  dataTipoEntidad: ['tipoentidadService', '$stateParams',
                    function (tipoentidadService, $stateParams) {
                      return tipoentidadService.listaActiva();
                    }],
                  dataPais: ['paisService', '$stateParams',
                    function (paisService, $stateParams) {
                      return paisService.listaActiva();
                    }],
                  dataDepartamento: ['departamentoService', '$stateParams',
                    function (departamentoService, $stateParams) {
                      return departamentoService.listaPorPais(1);
                    }]
                },
                controller: ['$scope', '$stateParams', 'dataFuenteSemillera', 'dataTipoPropietario', 'dataTipoEntidad', '$state', 'toastr', 'utilsService', '$timeout', 'authService', 'dataDepartamento',
                  function ($scope, $stateParams, dataFuenteSemillera, dataTipoPropietario, dataTipoEntidad, $state, toastr, utilsService, $timeout, authService, dataDepartamento) {
                    $scope.removeStateToGo();
                    $scope.data = {};
                    $scope.dataDepartamentoFinca = dataDepartamento.data;
                    $scope.dataDepartamento = dataDepartamento.data;
                    $scope.module = 'Fuente semillera';
                    if ($scope.data.estadoId > 2) {
                      if ($scope.data.estadoId != 2) {
                        toastr.error('La no se encuentra activa para modificaciones', 'Acceso no autorizado');
                        $state.go('index.forbidden.show');
                        return;
                      }
                    }
                    $scope.globales.totalPaginas = 0;

                    $scope.data = {};

                    $scope.setData = function (fuenteSemilleraDesc) {
                      var fuenteSemilleraId = null;
                      if ($stateParams.fuenteSemilleraId > 0) {
                        fuenteSemilleraId = 1;
                      }
                      var rfn = $scope.data.rfn;
                      $scope.data = {
                        rfn: rfn,
                        personas: [{
                          personaGestionId: 19,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T02:34:55.000+0000",
                          tcPersona: {
                            personaId: 616,
                            personaDesc: "Melvin Rivaí Hernández Camposeco",
                            cui: 2405071341413,
                            fechaVencimiento: "2027-06-16T00:00:00.000+0000",
                            tcMunicipio: {
                              municipioId: 301,
                              municipioDesc: "Santa Cruz del Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:26.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1401
                            },
                            direccion: "0 Calle 3-39 Zona 6",
                            telefono: "58038694",
                            correo: "norberta.brito1981@gmail.com",
                            foto: null,
                            tcUsuario: null,
                            fechaNacimiento: "1981-06-07T00:00:00.000+0000",
                            tcCultura: {
                              culturaId: 3,
                              culturaDesc: "Maya",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:10:44.000+0000"
                            },
                            tcIdioma: {
                              idiomaId: 18,
                              idiomaDesc: "K’iche‘",
                              estadoId: 1,
                              fechaRegistro: "2018-10-18T14:53:00.000+0000"
                            },
                            tcEstadoCivil: {
                              estadoCivilId: 2,
                              estadoCivilDesc: "Soltero (a)",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:11:14.000+0000"
                            },
                            tcSexo: {
                              sexoId: 1,
                              sexoDesc: "Femenino",
                              estadoId: 1,
                              fechaRegistro: "2019-09-13T10:44:03.000+0000"
                            },
                            estadoId: 1,
                            fechaRegistro: "2020-05-27T21:08:01.000+0000",
                            confirmado: 1,
                            passwordTmp: null,
                            nit: "24050713",
                            fechaUltModif: null,
                            tcOcupacion: {
                              ocupacionId: 2,
                              ocupacionDesc: "Estudiante",
                              estadoId: 1,
                              fechaRegistro: "2019-08-01T16:03:04.000+0000"
                            },
                            sigla: null,
                            rfn: null,
                            rf: null,
                            tcUsuarioSubregion: null,
                            usuario: null
                          },
                          representanteLegal: 0,
                          soloRepresenta: 0
                        }],
                        representantes: [{
                          personaGestionId: 20,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T02:38:01.000+0000",
                          tcPersona: {
                            personaId: 520,
                            personaDesc: "Carlos Alberto López González",
                            cui: 2301523171401,
                            fechaVencimiento: "2026-03-29T05:00:00.000+0000",
                            tcMunicipio: {
                              municipioId: 307,
                              municipioDesc: "Chinique",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:27.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1403
                            },
                            direccion: "Zona 1",
                            telefono: "58038694",
                            correo: "carlosalberto.lopez1993@gmail.com",
                            foto: null,
                            tcUsuario: null,
                            fechaNacimiento: "1993-08-16T05:00:00.000+0000",
                            tcCultura: {
                              culturaId: 3,
                              culturaDesc: "Maya",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:10:44.000+0000"
                            },
                            tcIdioma: {
                              idiomaId: 18,
                              idiomaDesc: "K’iche‘",
                              estadoId: 1,
                              fechaRegistro: "2018-10-18T14:53:00.000+0000"
                            },
                            tcEstadoCivil: {
                              estadoCivilId: 2,
                              estadoCivilDesc: "Soltero (a)",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:11:14.000+0000"
                            },
                            tcSexo: {
                              sexoId: 2,
                              sexoDesc: "Masculino",
                              estadoId: 1,
                              fechaRegistro: "2019-09-13T10:44:03.000+0000"
                            },
                            estadoId: 1,
                            fechaRegistro: "2020-01-27T18:07:30.000+0000",
                            confirmado: 1,
                            passwordTmp: "QVg1aE83WjQreXBhcVJDMlR1ZVpUdz09",
                            nit: "23015231",
                            fechaUltModif: null,
                            tcOcupacion: {
                              ocupacionId: 2,
                              ocupacionDesc: "Estudiante",
                              estadoId: 1,
                              fechaRegistro: "2019-08-01T16:03:04.000+0000"
                            },
                            sigla: null,
                            rfn: null,
                            rf: null,
                            tcUsuarioSubregion: null,
                            usuario: null
                          },
                          representanteLegal: 1,
                          soloRepresenta: 1
                        }],
                        tcTipoPropietario: {
                          tipoPropietarioId: 1,
                          tipoPropietarioDesc: "Individual",
                          estadoId: 1,
                          fechaRegistro: "2018-09-06T14:29:57.000+0000"
                        },
                        notificaciones: [{
                          notificacionGestionId: 80,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 1,
                            tipoNotificacionDesc: "Dirección domiciliar",
                            esDireccion: 1,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 500,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:53:19.000+0000"
                          },
                          tcMunicipio: {
                            municipioId: 301,
                            municipioDesc: "Santa Cruz del Quiché",
                            estadoId: 1,
                            fechaRegistro: "2018-08-07T16:19:26.000+0000",
                            tcDepartamento: {
                              departamentoId: 21,
                              departamentoDesc: "Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T15:51:54.000+0000",
                              tcPais: {
                                paisId: 1,
                                paisDesc: "Guatemala",
                                estadoId: 1,
                                fechaRegistro: "2018-07-25T20:02:54.000+0000"
                              }
                            },
                            codigo: 1401
                          },
                          estadoId: 1,
                          notificacionGestionDesc: "Aldea El Chajbal"
                        }, {
                          notificacionGestionId: 81,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 3,
                            tipoNotificacionDesc: "Teléfono",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 8,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:35.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "51634020"
                        }, {
                          notificacionGestionId: 82,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 4,
                            tipoNotificacionDesc: "Celular",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 8,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:44.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "58038694"
                        }, {
                          notificacionGestionId: 83,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 5,
                            tipoNotificacionDesc: "Correo electrónico",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "email",
                            maxlength: 100,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:52.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "melvinrivai@gmail.com"
                        }],
                        fincas: [{
                          fincaGestionId: 19,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T03:01:27.000+0000",
                          tcFinca: {
                            fincaId: 22,
                            fincaDesc: "Rústica",
                            direccion: "El Chajbal",
                            gtmX: 429903.0,
                            gtmY: 1667643.0,
                            norte: "Gregorio Bernardo Ponsio,  línea al medio",
                            sur: "Gregorio Bernardo Ponsio,  línea al medio",
                            este: "Finca matríz, línea al medio",
                            oeste: "Bernardo López, carretera de asfalto por medio.",
                            area: 0.13,
                            notario: null,
                            numeroDocumento: 27429,
                            folio: 230,
                            libro: "111",
                            tcMunicipio: {
                              municipioId: 301,
                              municipioDesc: "Santa Cruz del Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:26.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1401
                            },
                            tcMunicipioEmite: null,
                            tcTipoPropiedad: {
                              tipoPropiedadId: 3,
                              tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                              estadoId: 1,
                              fechaRegistro: "2018-09-17T13:28:38.000+0000",
                              esPropiedad: 0
                            },
                            estadoId: 1,
                            fechaRegistro: null,
                            tcLibro: {
                              libroId: 21,
                              libroDesc: "Quiché",
                              estadoId: 1,
                              fechaRegistro: "2020-01-03T21:31:02.000+0000"
                            }
                          },
                          fechaEmision: new Date('2020-05-07T06:00:00.000+0000'),
                          notario: null,
                          tcMunicipioEmite: null,
                          numeroDocumento: 27429,
                          folio: 230,
                          libro: "111",
                          tcTipoPropiedad: {
                            tipoPropiedadId: 3,
                            tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                            estadoId: 1,
                            fechaRegistro: "2018-09-17T13:28:38.000+0000",
                            esPropiedad: 0
                          },
                          tcLibro: {
                            libroId: 21,
                            libroDesc: "Quiché",
                            estadoId: 1,
                            fechaRegistro: "2020-01-03T21:31:02.000+0000"
                          },
                          propietarios: [{
                            propietarioFincaId: 0,
                            estadoId: 1,
                            fechaRegistro: "2020-09-10T19:02:10.413+0000",
                            tcPersona: {
                              personaId: 1,
                              personaDesc: null,
                              cui: 0,
                              fechaVencimiento: null,
                              tcMunicipio: null,
                              direccion: null,
                              telefono: null,
                              correo: null,
                              foto: null,
                              tcUsuario: null,
                              fechaNacimiento: null,
                              tcCultura: null,
                              tcIdioma: null,
                              tcEstadoCivil: null,
                              tcSexo: null,
                              estadoId: 1,
                              fechaRegistro: "2020-09-10T19:02:10.413+0000",
                              confirmado: 0,
                              passwordTmp: null,
                              nit: null,
                              fechaUltModif: null,
                              tcOcupacion: null,
                              sigla: null,
                              rfn: null,
                              rf: null,
                              tcUsuarioSubregion: null,
                              usuario: null
                            }
                          }]
                        }]
                      };
                    }

                    $scope.tabs = [
                      { name: 'General', icon: 'fa fa-info-circle', link: 'index.fuente.realizar', visible: true, disabled: false },
                      { name: 'Inventario', icon: 'fa fa-bars', link: 'index.fuente.inventario', visible: true, disabled: false }
                    ];

                    $scope.dataTipoPropietario = dataTipoPropietario.data;
                    $scope.dataTipoEntidad = dataTipoEntidad.data;


                    $scope.textoImprimiendo = "Imprimir";
                    $scope.imprimiendo = false;

                    $scope.imprimirFormato = function () {
                      try {
                        $scope.imprimiendo = true;
                        $scope.textoImprimiendo = "Imprimiendo";
                        utilsService.generarPlan($scope.dataEmpresa, $scope.data);
                        $timeout(function() {
                          $scope.imprimiendo = false;
                          $scope.textoImprimiendo = "Imprimir";
                          if ($scope.data.tcSubregion.tcSubregional == null) {
                            toastr.error('El director subregional de la subregión ' + $scope.data.tcSubregion.subregionDesc + ' no se encuentra configurado, no es posible imprimir la solicitud, comuniquese con la subregión para informar el inconveniente');
                          } else {
                            utilsService.generarSolicitudLicencia($scope.data, $scope.loginData.usuarioDesc, $scope.dataEmpresa);
                          }
                        }, 3000);
                        if ($scope.data.ttResumenFuenteSemillera.noTurnos > 1) {
                          $timeout(function() {
                            utilsService.generarPoa($scope.dataEmpresa, $scope.data, 1);
                          }, 1000);
                        }
                      } catch (e) {
                        toastr.error('Error al imprimir fuente, asegúrese que el fuente está debidamente finalizado. ' + e.message);
                      }
                    }

                  }
                ]
              }
            }
          })
          .state('index.fuente.realizar', {
            url: '/realizar',
            views: {
              '': {
                templateUrl: 'app/fuentesemillera/fuentesemillera.general.tpl.html',
                resolve: {
                  dataIdioma: ['idiomaService', '$stateParams',
                    function (idiomaService, $stateParams) {
                      return idiomaService.listaActiva();
                    }],
                  dataCultura: ['culturaService', '$stateParams',
                    function (culturaService, $stateParams) {
                      return culturaService.listaActiva();
                    }],
                  dataEstadoCivil: ['estadocivilService', '$stateParams',
                    function (estadocivilService, $stateParams) {
                      return estadocivilService.listaActiva();
                    }],
                  dataTipoDocumento: ['tipodocumentoService', '$stateParams',
                    function (tipodocumentoService, $stateParams) {
                      return tipodocumentoService.listaActiva();
                    }],
                  dataTipoNotificacion: ['tiponotificacionService', '$stateParams',
                    function (tiponotificacionService, $stateParams) {
                      return tiponotificacionService.listaActiva();
                    }],
                  dataTipoPropiedad: ['tipopropiedadService',
                    function (tipopropiedadService) {
                      return tipopropiedadService.listaActiva();
                    }],
                  dataTipoRepresentacion: ['tiporepresentacionService',
                    function (tiporepresentacionService) {
                      return tiporepresentacionService.listaActiva();
                    }],
                  dataLibro: ['libroService',
                    function (libroService) {
                      return libroService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'fuentesemilleraService', 'ngDialog', 'personaService', 'dataIdioma',
                  'dataCultura', 'dataEstadoCivil', 'dataTipoDocumento', 'dataTipoNotificacion',
                  'fincaService', 'dataTipoPropiedad', 'municipioService', '$window', '$filter', 'dataTipoRepresentacion', 'dataLibro',
                  '$stateParams',
                  function ($scope, $state, toastr, fuentesemilleraService, ngDialog, personaService, dataIdioma,
                    dataCultura, dataEstadoCivil, dataTipoDocumento, dataTipoNotificacion,
                    fincaService, dataTipoPropiedad, municipioService, $window, $filter, dataTipoRepresentacion, dataLibro,
                    $stateParams) {

                    $window.scrollTo(0, 0);

                    $scope.dataTipoPropiedad = dataTipoPropiedad.data;

                    $scope.setData = function (fuenteSemilleraDesc) {
                      $scope.data = {
                        fuenteSemilleraDesc: fuenteSemilleraDesc,
                        personas: [{
                          personaGestionId: 19,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T02:34:55.000+0000",
                          tcPersona: {
                            personaId: 616,
                            personaDesc: "Melvin Rivaí Hernández Camposeco",
                            cui: 2405071341413,
                            fechaVencimiento: "2027-06-16T00:00:00.000+0000",
                            tcMunicipio: {
                              municipioId: 301,
                              municipioDesc: "Santa Cruz del Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:26.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1401
                            },
                            direccion: "0 Calle 3-39 Zona 6",
                            telefono: "58038694",
                            correo: "norberta.brito1981@gmail.com",
                            foto: null,
                            tcUsuario: null,
                            fechaNacimiento: "1981-06-07T00:00:00.000+0000",
                            tcCultura: {
                              culturaId: 3,
                              culturaDesc: "Maya",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:10:44.000+0000"
                            },
                            tcIdioma: {
                              idiomaId: 18,
                              idiomaDesc: "K’iche‘",
                              estadoId: 1,
                              fechaRegistro: "2018-10-18T14:53:00.000+0000"
                            },
                            tcEstadoCivil: {
                              estadoCivilId: 2,
                              estadoCivilDesc: "Soltero (a)",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:11:14.000+0000"
                            },
                            tcSexo: {
                              sexoId: 1,
                              sexoDesc: "Femenino",
                              estadoId: 1,
                              fechaRegistro: "2019-09-13T10:44:03.000+0000"
                            },
                            estadoId: 1,
                            fechaRegistro: "2020-05-27T21:08:01.000+0000",
                            confirmado: 1,
                            passwordTmp: null,
                            nit: "24050713",
                            fechaUltModif: null,
                            tcOcupacion: {
                              ocupacionId: 2,
                              ocupacionDesc: "Estudiante",
                              estadoId: 1,
                              fechaRegistro: "2019-08-01T16:03:04.000+0000"
                            },
                            sigla: null,
                            rfn: null,
                            rf: null,
                            tcUsuarioSubregion: null,
                            usuario: null
                          },
                          representanteLegal: 0,
                          soloRepresenta: 0
                        }],
                        representantes: [{
                          personaGestionId: 20,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T02:38:01.000+0000",
                          tcPersona: {
                            personaId: 520,
                            personaDesc: "Carlos Alberto López González",
                            cui: 2301523171401,
                            fechaVencimiento: "2026-03-29T05:00:00.000+0000",
                            tcMunicipio: {
                              municipioId: 307,
                              municipioDesc: "Chinique",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:27.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1403
                            },
                            direccion: "Zona 1",
                            telefono: "58038694",
                            correo: "carlosalberto.lopez1993@gmail.com",
                            foto: null,
                            tcUsuario: null,
                            fechaNacimiento: "1993-08-16T05:00:00.000+0000",
                            tcCultura: {
                              culturaId: 3,
                              culturaDesc: "Maya",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:10:44.000+0000"
                            },
                            tcIdioma: {
                              idiomaId: 18,
                              idiomaDesc: "K’iche‘",
                              estadoId: 1,
                              fechaRegistro: "2018-10-18T14:53:00.000+0000"
                            },
                            tcEstadoCivil: {
                              estadoCivilId: 2,
                              estadoCivilDesc: "Soltero (a)",
                              estadoId: 1,
                              fechaRegistro: "2018-08-28T20:11:14.000+0000"
                            },
                            tcSexo: {
                              sexoId: 2,
                              sexoDesc: "Masculino",
                              estadoId: 1,
                              fechaRegistro: "2019-09-13T10:44:03.000+0000"
                            },
                            estadoId: 1,
                            fechaRegistro: "2020-01-27T18:07:30.000+0000",
                            confirmado: 1,
                            passwordTmp: "QVg1aE83WjQreXBhcVJDMlR1ZVpUdz09",
                            nit: "23015231",
                            fechaUltModif: null,
                            tcOcupacion: {
                              ocupacionId: 2,
                              ocupacionDesc: "Estudiante",
                              estadoId: 1,
                              fechaRegistro: "2019-08-01T16:03:04.000+0000"
                            },
                            sigla: null,
                            rfn: null,
                            rf: null,
                            tcUsuarioSubregion: null,
                            usuario: null
                          },
                          representanteLegal: 1,
                          soloRepresenta: 1
                        }],
                        tcTipoPropietario: {
                          tipoPropietarioId: 1,
                          tipoPropietarioDesc: "Individual",
                          estadoId: 1,
                          fechaRegistro: "2018-09-06T14:29:57.000+0000"
                        },
                        notificaciones: [{
                          notificacionGestionId: 80,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 1,
                            tipoNotificacionDesc: "Dirección domiciliar",
                            esDireccion: 1,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 500,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:53:19.000+0000"
                          },
                          tcMunicipio: {
                            municipioId: 301,
                            municipioDesc: "Santa Cruz del Quiché",
                            estadoId: 1,
                            fechaRegistro: "2018-08-07T16:19:26.000+0000",
                            tcDepartamento: {
                              departamentoId: 21,
                              departamentoDesc: "Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T15:51:54.000+0000",
                              tcPais: {
                                paisId: 1,
                                paisDesc: "Guatemala",
                                estadoId: 1,
                                fechaRegistro: "2018-07-25T20:02:54.000+0000"
                              }
                            },
                            codigo: 1401
                          },
                          estadoId: 1,
                          notificacionGestionDesc: "Aldea El Chajbal"
                        }, {
                          notificacionGestionId: 81,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 3,
                            tipoNotificacionDesc: "Teléfono",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 8,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:35.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "51634020"
                        }, {
                          notificacionGestionId: 82,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 4,
                            tipoNotificacionDesc: "Celular",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "text",
                            maxlength: 8,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:44.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "58038694"
                        }, {
                          notificacionGestionId: 83,
                          fechaAsignacion: "2020-05-28T02:49:01.000+0000",
                          tcTipoNotificacion: {
                            tipoNotificacionId: 5,
                            tipoNotificacionDesc: "Correo electrónico",
                            esDireccion: 0,
                            esObligatorio: 1,
                            type: "email",
                            maxlength: 100,
                            estadoId: 1,
                            fechaRegistro: "2018-09-10T12:54:52.000+0000"
                          },
                          tcMunicipio: null,
                          estadoId: 1,
                          notificacionGestionDesc: "melvinrivai@gmail.com"
                        }],
                        fincas: [{
                          fincaGestionId: 19,
                          estadoId: 1,
                          fechaRegistro: "2020-05-28T03:01:27.000+0000",
                          tcFinca: {
                            fincaId: 22,
                            fincaDesc: "Rústica",
                            direccion: "El Chajbal",
                            gtmX: 429903.0,
                            gtmY: 1667643.0,
                            norte: "Gregorio Bernardo Ponsio,  línea al medio",
                            sur: "Gregorio Bernardo Ponsio,  línea al medio",
                            este: "Finca matríz, línea al medio",
                            oeste: "Bernardo López, carretera de asfalto por medio.",
                            area: 0.13,
                            notario: null,
                            numeroDocumento: 27429,
                            folio: 230,
                            libro: "111",
                            tcMunicipio: {
                              municipioId: 301,
                              municipioDesc: "Santa Cruz del Quiché",
                              estadoId: 1,
                              fechaRegistro: "2018-08-07T16:19:26.000+0000",
                              tcDepartamento: {
                                departamentoId: 21,
                                departamentoDesc: "Quiché",
                                estadoId: 1,
                                fechaRegistro: "2018-08-07T15:51:54.000+0000",
                                tcPais: {
                                  paisId: 1,
                                  paisDesc: "Guatemala",
                                  estadoId: 1,
                                  fechaRegistro: "2018-07-25T20:02:54.000+0000"
                                }
                              },
                              codigo: 1401
                            },
                            tcMunicipioEmite: null,
                            tcTipoPropiedad: {
                              tipoPropiedadId: 3,
                              tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                              estadoId: 1,
                              fechaRegistro: "2018-09-17T13:28:38.000+0000",
                              esPropiedad: 0
                            },
                            estadoId: 1,
                            fechaRegistro: null,
                            tcLibro: {
                              libroId: 21,
                              libroDesc: "Quiché",
                              estadoId: 1,
                              fechaRegistro: "2020-01-03T21:31:02.000+0000"
                            }
                          },
                          fechaEmision: new Date('2020-05-07T06:00:00.000+0000'),
                          notario: null,
                          tcMunicipioEmite: null,
                          numeroDocumento: 27429,
                          folio: 230,
                          libro: "111",
                          tcTipoPropiedad: {
                            tipoPropiedadId: 3,
                            tipoPropiedadDesc: "Certificación de Registro de la propiedad",
                            estadoId: 1,
                            fechaRegistro: "2018-09-17T13:28:38.000+0000",
                            esPropiedad: 0
                          },
                          tcLibro: {
                            libroId: 21,
                            libroDesc: "Quiché",
                            estadoId: 1,
                            fechaRegistro: "2020-01-03T21:31:02.000+0000"
                          },
                          propietarios: [{
                            propietarioFincaId: 0,
                            estadoId: 1,
                            fechaRegistro: "2020-09-10T19:02:10.413+0000",
                            tcPersona: {
                              personaId: 1,
                              personaDesc: null,
                              cui: 0,
                              fechaVencimiento: null,
                              tcMunicipio: null,
                              direccion: null,
                              telefono: null,
                              correo: null,
                              foto: null,
                              tcUsuario: null,
                              fechaNacimiento: null,
                              tcCultura: null,
                              tcIdioma: null,
                              tcEstadoCivil: null,
                              tcSexo: null,
                              estadoId: 1,
                              fechaRegistro: "2020-09-10T19:02:10.413+0000",
                              confirmado: 0,
                              passwordTmp: null,
                              nit: null,
                              fechaUltModif: null,
                              tcOcupacion: null,
                              sigla: null,
                              rfn: null,
                              rf: null,
                              tcUsuarioSubregion: null,
                              usuario: null
                            }
                          }]
                        }]
                      };
                    }

                    $scope.guardarTipoPropietario = function () {
                      toastr.success('Proceso existoso');
                    }

                    $scope.setPropietarios = function () {
                      if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                        var data = [
                          {
                            personaId: 1,
                            personaDesc: $scope.data.ttTipoPropietarioGestion.razonSocial
                          }
                        ];
                        $scope.dataPersona = data;
                      } else {
                        if ($scope.data.personas != undefined) {
                          if ($scope.data.personas.length > 0) {
                            var data = [];
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0)) {
                                data.push(angular.copy($scope.data.personas[i].tcPersona));
                              }
                            }
                            $scope.dataPersona = data;
                          }
                        }
                      }
                    }

                    $scope.buscarPersona = function ( tipo ) {
                      $scope.tipoPersona = tipo;
                      var continuar = true;
                      if (continuar) {
                        $scope.dataTipoBusqueda = [
                          { tipoBusquedaId: 1, tipoBusquedaDesc: 'DPI' },
                          { tipoBusquedaId: 2, tipoBusquedaDesc: 'NIT' },
                          { tipoBusquedaId: 3, tipoBusquedaDesc: 'Nombre y apellido' },
                        ]
                        $scope.busqueda = {
                          tipoBusquedaId: 3,
                          tituloBusqueda: 'Ingrese el nombre de la persona',
                          placeholderBusqueda: 'Escriba al menos un nombre y un apellido'
                        }

                        ngDialog.open({
                          template: 'app/gestion/buscar.persona.tpl.html',
                          className: 'ngdialog-theme-flat',
                          appendClassName: 'ngdialog-flat',
                          closeByDocument: false,
                          closeByEscape: true,
                          closeByNavigation: true,
                          scope: $scope,
                          id: 'uniqueEdit',
                          name: 'dialogEditar',
                          resolve: {
                          },
                          controller: ['$scope', '$state', 'toastr', 'personaService',
                            function ($scope, $state, toastr, personaService) {

                              $scope.gridPersona = angular.copy($scope.gridOptionsSelection);
                              $scope.gridPersona.columnDefs = [
                                { field: 'personaDesc', name: 'personaDesc', displayName: 'Filtrar nombre encontrado' },
                                { field: 'cui', name: 'cui', displayName: 'Identificación' },
                                { field: 'correo', name: 'correo', displayName: 'Correo' },
                                { field: 'nit', name: 'nit', displayName: 'NIT' },
                                {
                                  name: 'Seleccionar', enableFiltering: false,
                                  cellTemplate: '<div class="ui-grid-cell-contents text-center col-options"><span>'
                                    + '<button type="button" class="btn btn-danger btn-xs" ng-click="grid.appScope.setPersona(row.entity)" title="Seleccionar persona">Seleccionar</button></span></div>'
                                }
                              ];
                              $scope.gridPersona.data = [];

                              $scope.setTipoBusqueda = function (item) {
                                switch (parseInt(item.tipoBusquedaId)) {
                                  case 1:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                    $scope.busqueda.placeholderBusqueda = 'Ingrese el DPI';
                                    break;
                                  case 2:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el NIT';
                                    $scope.busqueda.placeholderBusqueda = 'Ingrese el NIT';
                                    break;
                                  case 3:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el nombre de la persona';
                                    $scope.busqueda.placeholderBusqueda = 'Escriba al menos un nombre y un apellido';
                                    break;
                                  default:
                                    $scope.busqueda.tituloBusqueda = 'Ingrese el DPI';
                                }
                              }

                              $scope.keyupFiltro = function (event) {
                                if (event.keyCode == 13) {
                                  $scope.submitBuscarPersona();
                                }
                              }

                              $scope.submitBuscarPersona = function () {
                                var isValid = true;
                                if ($scope.busqueda.filtro == undefined || $scope.busqueda.filtro == null || $scope.busqueda.tipoBusquedaId == undefined || $scope.busqueda.tipoBusquedaId == null) {
                                  isValid = null;
                                  toastr.error('Seleccion el tipo de busqueda e indique el filtro');
                                }
                                if (isValid) {
                                  personaService.busquedaFiltro($scope.busqueda).then(function (response) {
                                    if (response.status == 'OK') {
                                      toastr.success(response.message);
                                      $scope.gridPersona.data = response.data;
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              }

                              $scope.setPersona = function (item) {
                                var soloRepresenta = 1;
                                if ($scope.tipoPersona == 0) {
                                  for (var i = 0; i < $scope.data.personas.length; i++) {
                                    if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                      ngDialog.close();
                                      return;
                                    }
                                  }
                                  soloRepresenta = 0;
                                } else {
                                  for (var i = 0; i < $scope.data.representantes.length; i++) {
                                    if ($scope.data.representantes[i].tcPersona.personaId == item.personaId) {
                                      ngDialog.close();
                                      return;
                                    }
                                  }
                                  for (var i = 0; i < $scope.data.personas.length; i++) {
                                    if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                      soloRepresenta = 0;
                                      break;
                                    }
                                  }
                                }
                                if (item.tcCultura == null || item.tcIdioma == null || item.tcEstadoCivil == null || item.tcSexo == null) {
                                  $scope.panel.editarPersona = true;
                                  $scope.persona = item;
                                  toastr.error('Es necesario completar información de la persona seleccionada, hágalo en la parte inferior de esta pantalla');
                                } else {
                                  $scope.data.personas.push(item);
                                }
                              }

                              $scope.setIdioma = function (item) {

                              }

                              $scope.setSexo = function (item) {

                              }

                              $scope.setEstadoCivil = function (item) {

                              }

                              $scope.setCultura = function (item) {

                              }

                              $scope.submitEditarPersona = function (isValid) {
                                if (isValid) {
                                  var persona = $scope.persona;
                                  personaService.edit(persona).then(function (response) {
                                    if (response.status == 'OK') {
                                      var tmp = $scope.data;
                                      var data = {
                                        personaGestionId: 0,
                                        tcPersona: persona,
                                        ttGestion: {
                                          gestionId: tmp.gestionId,
                                          tcTipoGestion: tmp.tcTipoGestion,
                                          estadoId : tmp.estadoId,
                                          tcPersonaCrea: tmp.tcPersonaCrea,
                                          tcTipoBosque: tmp.tcTipoBosque,
                                          tcElaborador: tmp.tcElaborador,
                                          ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                          personaAnulaId: $scope.loginData.personaId
                                        },
                                        representanteLegal: 0,
                                        soloRepresenta: 0
                                      }
                                    } else {
                                      toastr.error(response.message);
                                    }
                                  }, function (error) {
                                    toastr.error(error);
                                  });
                                }
                              }

                            }]
                        })
                      }
                    }

                    $scope.setPropietarios = function () {
                      if ($scope.data.tcTipoPropietario.tipoPropietarioId == 2) {
                        var data = [
                          {
                            personaId: 1,
                            personaDesc: $scope.data.razonSocial
                          }
                        ];
                        $scope.dataPersona = data;
                      } else {
                        if ($scope.data.personas != undefined) {
                          if ($scope.data.personas.length > 0) {
                            var data = [];
                            for (var i = 0; i < $scope.data.personas.length; i++) {
                              if ($scope.data.personas[i].representanteLegal == 0 || ($scope.data.personas[i].representanteLegal == 1 && $scope.data.personas[i].soloRepresenta == 0)) {
                                data.push(angular.copy($scope.data.personas[i].tcPersona));
                              }
                            }
                            $scope.dataPersona = data;
                          }
                        }
                      }
                    }

                    $scope.dataLibro = dataLibro.data;

                    if ($stateParams.fuenteSemilleraId > 0) {
                      $scope.data.fuenteSemilleraDesc = "FS-1193";
                      $scope.setData("FS-1193");
                      $scope.setPropietarios();
                    }

                    $scope.buscarCodigoFuente = function () {
                      var fuenteSemilleraDesc = $scope.data.fuenteSemilleraDesc;
                      $scope.setData(fuenteSemilleraDesc);
                      $scope.setPropietarios();
                    }

                    $scope.setLibroFinca = function (item, finca) {
                      finca.tcLibro = item;
                      finca.tcMunicipio = null;
                    }

                    $scope.setDepartamentoFinca = function (item, finca, limpiar) {
                      if (limpiar == undefined) {
                        limpiar = true;
                      }
                      if (limpiar) {
                        finca.tcMunicipio = null;
                      }
                      if (finca.tcTipoPropiedad != null) {
                        if (finca.tcTipoPropiedad.tipoPropiedadId != 3) {
                          finca.tcLibro = null;
                          municipioService.listaPorDepartamento( item.departamentoId ).then( function ( res ) {
                            if ( res.status == "OK" ) {
                              finca.dataMunicipio = res.data;
                            } else {
                              toastr.error( res.message );
                            }
                          }, function ( error ) {
                            toastr.error( error );
                          });
                        }
                      }
                    }

                    //cargar municipio de las fincas ya cargadas
                    if ($scope.data.fincas != null) {
                      var encontrado = false;
                      for (var i = 0; i < $scope.data.fincas.length; i++) {
                        if ($scope.data.fincas[i].tcMunicipioEmite != null) {
                          $scope.setDepartamentoFinca({departamentoId : $scope.data.fincas[i].tcMunicipioEmite.tcDepartamento.departamentoId}, $scope.data.fincas[i], false);
                        } else {
                          $scope.data.fincas[i].tcMunicipioEmite = {
                            municipioId : null,
                            tcDepartamento: {
                              departamentoId: null
                            }
                          }
                        }
                        if ($scope.data.fincas[i].propietarios == null) {
                          $scope.data.fincas[i].propietarios = [];
                        }
                        $scope.data.fincas[i].personas = [];
                        for (var j = 0; j < $scope.data.fincas[i].propietarios.length; j++) {
                          var tcPersona = $scope.data.fincas[i].propietarios[j].tcPersona;
                          $scope.data.fincas[i].personas.push(tcPersona);
                        }
                        $scope.data.fincas[i].fechaEmision = new Date($scope.data.fincas[i].fechaEmision);
                      }
                    }

                    $scope.agregarPersona = function (tipo) {
                      $scope.tipoPersona = tipo;
                      $scope.tituloPersona = "Agregar datos de la persona";
                      delete $scope.persona;
                      ngDialog.open({
                        template: 'app/persona/persona.add.tpl.html',
                        className: 'ngdialog-theme-flat',
                        appendClassName: 'ngdialog-flat',
                        closeByDocument: false,
                        closeByEscape: true,
                        closeByNavigation: true,
                        scope: $scope,
                        id: 'uniqueEdit',
                        name: 'dialogEditar',
                        resolve: {
                          dataOcupacion: ['ocupacionService',
                            function ( ocupacionService ){
                              return ocupacionService.listaActiva();
                            }]
                        },
                        controller: ['$scope', '$state', 'toastr', 'departamentoService', 'municipioService', '$filter', 'Upload', 'dataOcupacion',
                          function ($scope, $state, toastr, departamentoService, municipioService, $filter, Upload, dataOcupacion) {
                            $scope.dataOcupacion = dataOcupacion.data;
                            $scope.persona = {
                              estadoId: 1
                            }
                            $scope.direccion = {
                              paisId: 1
                            }

                            $scope.cargarListaDepartamento = function (item) {
                              $scope.direccion.departamentoId = null;
                              $scope.persona.municipioId = null;
                              departamentoService.listaPorPais(item.paisId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataDepartamento = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.cargarListaMunicipio = function (item) {
                              $scope.persona.municipioId = null;
                              municipioService.listaPorDepartamento(item.departamentoId).then(function (res) {
                                if (res.status == "OK") {
                                  $scope.dataMunicipio = res.data;
                                } else {
                                  toastr.error(res.message);
                                }
                              }, function (error) {
                                toastr.error(error);
                              });
                            }

                            $scope.onFileSelect = function (file, tipoAnexoId) {
                              Upload.upload({
                                url: appSettings.urlServiceBase + 'file/base64/imagen',
                                method: 'POST',
                                file: file,
                                sendFieldsAs: 'form',
                                fields: {
                                  data: []
                                }
                              }).then(function (resp) {
                                var data = resp.data;
                                if (data.status == 'OK') {
                                  var obj = data.data[0];
                                  $scope.image = obj.data;
                                  $scope.persona.foto = obj.data;
                                } else {
                                  toastr.error(resp.data.message)
                                }
                              }, function (resp) {
                                toastr.error('Error status: ' + resp.status);
                              }, function (evt) {
                                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                $scope.avance = progressPercentage;
                              });
                            }

                            $scope.submitFormPersona = function (isValid) {
                              if (isValid) {
                                if ($scope.persona.foto == null) {
                                  /*isValid = false;
                                  toastr.error('Debe indicar una foto');*/
                                  $scope.validoFoto = 1;
                                }
                                if ($scope.validoFoto == 0 && isValid) {
                                  isValid = false;
                                  toastr.error('La imagen seleccionada tiene una resolución demasiado grande, debe utilizar una más pequeña');
                                }
                              }
                              if (isValid) {
                                var item = $scope.persona;
                                personaService.add(item).then(function (res) {
                                  if (res.status == 'OK') {
                                    item = res.data[0];
                                    var soloRepresenta = 1;
                                    if ($scope.tipoPersona == 0) {
                                      soloRepresenta = 0;
                                    } else {
                                      for (var i = 0; i < $scope.data.personas.length; i++) {
                                        if ($scope.data.personas[i].tcPersona.personaId == item.personaId) {
                                          soloRepresenta = 0;
                                          break;
                                        }
                                      }
                                    }
                                    var tmp = $scope.data;
                                    var data = {
                                      personaGestionId: 0,
                                      tcPersona: item,
                                      ttGestion: {
                                        gestionId: tmp.gestionId,
                                        tcTipoGestion: tmp.tcTipoGestion,
                                        estadoId : tmp.estadoId,
                                        tcPersonaCrea: tmp.tcPersonaCrea,
                                        tcTipoBosque: tmp.tcTipoBosque,
                                        tcElaborador: tmp.tcElaborador,
                                        ttTipoPropietarioGestion: tmp.ttTipoPropietarioGestion,
                                        personaAnulaId: $scope.loginData.personaId
                                      },
                                      representanteLegal: $scope.tipoPersona,
                                      soloRepresenta: soloRepresenta
                                    }
                                    gestionService.agregarPersona(data).then(function (response) {
                                      if (response.status == 'OK') {
                                        registro = response.data[0];
                                        persona = registro.tcPersona;
                                        registro.tcPersona = item;
                                        if (parseInt($scope.tipoPersona) == 1) {
                                          $scope.data.representantes.push(registro);
                                        } else {
                                          item.class = 'btn-default btn-sm glyphicon glyphicon-unchecked';
                                          $scope.data.personas.push(registro);
                                          $scope.setPropietarios();
                                        }
                                        toastr.success(res.message);
                                      } else {
                                        toastr.error(response.message);
                                      }
                                      ngDialog.close();
                                    }, function (error) {
                                      toastr.error(error);
                                    });
                                  } else {
                                    toastr.error(res.message);
                                  }
                                }, function (error) {
                                  toastr.error(error);
                                });
                              }
                            }

                            $scope.setIdioma = function (item) {
                              $scope.persona.tcIdioma.idiomaDesc = item.idiomaDesc;
                            }

                            $scope.setSexo = function (item) {
                              $scope.persona.tcSexo.sexoDesc = item.sexoDesc;
                            }

                            $scope.setEstadoCivil = function (item) {
                              $scope.persona.tcEstadoCivil.estadoCivilDesc = item.estadoCivilDesc;
                            }

                            $scope.setCultura = function (item) {
                              $scope.persona.tcCultura.culturaDesc = item.culturaDesc;
                            }

                          }]
                      })
                    }


                  }]
              }
            }
          })
          .state('index.fuente.inventario', {
            url: '/inventario',
            views: {
              '': {
                templateUrl: 'app/fuentesemillera/fuentesemillera.inventario.tpl.html',
                resolve: {
                  dataTipoInventario: ['tipoinventarioService',
                    function (tipoinventarioService) {
                      return tipoinventarioService.listaActiva();
                    }],
                  dataTipoMuestreo: ['tipomuestreoService',
                    function (tipomuestreoService) {
                      return tipomuestreoService.listaActiva();
                    }],
                  dataFormaParcela: ['formaparcelaService',
                    function (formaparcelaService) {
                      return formaparcelaService.listaActiva();
                    }],
                  dataUnidadMedida: ['unidadmedidaService',
                    function (unidadmedidaService) {
                      return unidadmedidaService.listaActiva();
                    }]
                },
                controller: ['$scope', '$state', 'toastr', 'gestionService', '$window', 'dataTipoInventario',
                  'dataTipoMuestreo', 'dataFormaParcela', 'dataUnidadMedida',
                  function ($scope, $state, toastr, gestionService, $window, dataTipoInventario,
                    dataTipoMuestreo, dataFormaParcela, dataUnidadMedida) {
                    $window.scrollTo(0, 0);

                    $scope.setData("FS-1193");

                    $scope.dataSanidad = [
                      {sanidadBosqueId: 1, sanidadBosqueDesc: 'No presenta plagas ni enfermedades'},
                      {sanidadBosqueId: 2, sanidadBosqueDesc: 'Presencia plagas o enfermedades'}
                    ];

                    $scope.dataGradoIntervencion = [
                      {gradoIntervencionId: 1, gradoIntervencionDesc: 'Intervenido'},
                      {gradoIntervencionId: 2, gradoIntervencionDesc: 'No intervenido'}
                    ];

                    $scope.dataTipoInventario = dataTipoInventario.data;
                    $scope.dataTipoMuestreo = dataTipoMuestreo.data;
                    $scope.dataFormaParcela = dataFormaParcela.data;
                    $scope.dataUnidadMedida = dataUnidadMedida.data;
                    $scope.dataTipoBosque = [
                      {tipoBosqueId: 1, tipoBosqueDesc: 'Natural'},
                      {tipoBosqueId: 2, tipoBosqueDesc: 'Plantacion'}
                    ];
                    $scope.dataOrigenSemilla = [
                      {origenSemillaId: 1, origenSemillaDesc: 'America'},
                      {origenSemillaId: 1, origenSemillaDesc: 'Africa'},
                      {origenSemillaId: 1, origenSemillaDesc: 'Europa'},
                      {origenSemillaId: 1, origenSemillaDesc: 'Oceania'},
                      {origenSemillaId: 1, origenSemillaDesc: 'Asia'},
                      {origenSemillaId: 2, origenSemillaDesc: 'India'}
                    ];

                    $scope.gridOptionsExcel = angular.copy($scope.gridOptionsSelection);
                    $scope.gridOptionsExcel.columnDefs = [
                      { field: 'TURNO', name: 'TURNO', displayName: 'TURNO' },
                      { field: 'RODAL', name: 'RODAL', displayName: 'RODAL' },
                      { field: 'NO', name: 'NO', displayName: 'NO' },
                      { field: 'DAP', name: 'DAP', displayName: 'DAP', width: '15%' },
                      { field: 'ALTURA', name: 'ALTURA', displayName: 'ALTURA', width: '15%' },
                      { field: 'NOMBRE_CIENTIFICO', name: 'NOMBRE_CIENTIFICO', displayName: 'NOMBRE_CIENTIFICO', width: '20%' },
                      { field: 'PARCELA', name: 'PARCELA', displayName: 'PARCELA' },
                      { field: 'CLASE', name: 'CLASE', displayName: 'CLASE' },
                      { field: 'GTM_X', name: 'GTM_X', displayName: 'GTM_X' },
                      { field: 'GTM_Y', name: 'GTM_Y', displayName: 'GTM_Y' }
                    ];
                    //$scope.gridOptionsExcel.enableFiltering = false;
                    if ($scope.data.ttInventarioFuente != undefined) {
                      if ($scope.data.ttInventarioFuente.data != null) {
                        $scope.gridOptionsExcel.data = $scope.data.ttInventarioGestion.data;
                      }
                    }

                    $scope.setTipoInventarioEncabezado = function ( item ) {
                      if (item.tipoInventarioId == 1) {
                        $scope.mostrarParaTipoInventario = false;
                      } else {
                        $scope.mostrarParaTipoInventario = true;
                      }
                    }

                    $scope.validarInformacionInventario = function (isValid) {
                      if (isValid) {
                        var data = $scope.gridOptionsExcel.data;
                        if (data.length > 0) {
                          var dataRodal = [];
                          var tcTipoInventario = $scope.data.tcInventarioFuente.tcTipoInventario;
                          for (var i = 0; i < data.length; i++) {
                            if ($scope.data.tcInventarioFuente.tcTipoInventario.tipoInventarioId != 3) {
                              if (data[i].PARCELA != null && data[i].TAMANIO_PARCELA != null) {
                                tcTipoInventario.tipoInventarioId = 2;
                              } else {
                                tcTipoInventario.tipoInventarioId = 1;
                              }
                            }
                            var encontrado = false;
                            var especie = {
                              especieRodalId: 0,
                              tcTipoInventario: tcTipoInventario,
                              tcEspecie :
                              {
                                especieId: data[i].ESPECIE_ID,
                                nombreCientifico: data[i].NOMBRE_CIENTIFICO
                              },
                              arbolr: 1,
                              dapPromedio: data[i].DAP,
                              alturaPromedio: data[i].ALTURA,
                              claseUno: 0,
                              claseDos: 0,
                              claseTres: 0,
                              claseUnoHa: 0,
                              claseDosHa: 0,
                              claseTresHa: 0,
                              arbolh: 1
                            };
                            var rodal = {
                              ttRodalFuenteId: 0,
                              correlativo: data[i].RODAL,
                              area: data[i].AREA_RODAL,
                              edad: null,
                              especies: [
                                especie
                              ]
                            };
                            for (var j = 0; j < dataRodal.length; j++) {
                              if (dataRodal[j].correlativo == data[i].RODAL) {
                                encontrado = true;
                                rodal = dataRodal[j];
                                break;
                              }
                            }
                            if (encontrado) {
                              encontrado = false;
                              for (var k = 0; k < rodal.especies.length; k++) {
                                if (rodal.especies[k].tcEspecie.especieId == data[i].ESPECIE_ID) {
                                  encontrado = true;
                                  especie = rodal.especies[k];
                                  especie.arbolr++;
                                  especie.dapPromedio = parseFloat(especie.dapPromedio) + parseFloat(data[i].DAP);
                                  especie.alturaPromedio = parseFloat(especie.alturaPromedio) + parseFloat(data[i].ALTURA);
                                  break;
                                }
                              }
                              if (data[i].CLASE == 1) {
                                especie.claseUno++;
                              }
                              if (data[i].CLASE == 2) {
                                especie.claseDos++;
                              }
                              if (data[i].CLASE == 3) {
                                especie.claseTres++;
                              }
                              especie.arbolh++;
                              if (!encontrado) {
                                if (data[i].PARCELA == null) {
                                  tcTipoInventario = {tipoInventarioId: 1, tipoInventarioId: 'Censo'};
                                } else {
                                  tcTipoInventario = {tipoInventarioId: 2, tipoInventarioId: 'Muestreo'};
                                }
                                especie.tcTipoInventario = tcTipoInventario;
                                rodal.especies.push(especie);
                              }
                            } else {
                              if (data[i].PARCELA == null) {
                                tcTipoInventario = {tipoInventarioId: 1, tipoInventarioId: 'Censo'};
                              } else {
                                tcTipoInventario = {tipoInventarioId: 2, tipoInventarioId: 'Muestreo'};
                              }
                              rodal.especies[0].tcTipoInventario = tcTipoInventario;
                              if (data[i].CLASE == 1) {
                                rodal.especies[0].claseUno++;
                              }
                              if (data[i].CLASE == 2) {
                                rodal.especies[0].claseDos++;
                              }
                              if (data[i].CLASE == 3) {
                                rodal.especies[0].claseTres++;
                              }
                              dataRodal.push(rodal);
                            }
                          }
                          for (var i = 0; i < dataRodal.length; i++) {
                            for (var j = 0; j < dataRodal[i].especies.length; j++) {
                              dataRodal[i].especies[j].dapPromedio = parseFloat(dataRodal[i].especies[j].dapPromedio) / parseInt(dataRodal[i].especies[j].arbolr);
                              dataRodal[i].especies[j].alturaPromedio = parseFloat(dataRodal[i].especies[j].alturaPromedio) / parseInt(dataRodal[i].especies[j].arbolr);
                              dataRodal[i].especies[j].alturaPromedio = parseFloat(dataRodal[i].especies[j].alturaPromedio).toFixed(2);
                              dataRodal[i].especies[j].dapPromedio = parseFloat(dataRodal[i].especies[j].dapPromedio).toFixed(2);
                            }
                          }
                          $scope.data.rodal = dataRodal;
                        } else {

                        }
                      } else {
                        toastr.error('Debe indicar los datos obligatorios');
                      }
                    }

                    $scope.calcularHectareaEspecie = function ( rodal ) {
                      if (rodal.area != null) {
                        for (var i = 0; i < rodal.especies.length; i++) {
                          if (rodal.especies[i].tcTipoInventario.tipoInventarioId == 1) {
                            rodal.especies[i].arbolh = parseInt(rodal.especies[i].arbolr) / rodal.area;
                            rodal.especies[i].claseUnoHa = parseInt(rodal.especies[i].claseUno) / rodal.area;
                            rodal.especies[i].claseDosHa = parseInt(rodal.especies[i].claseDos) / rodal.area;
                            rodal.especies[i].claseTresHa = parseInt(rodal.especies[i].claseTres) / rodal.area;

                            rodal.especies[i].arbolh = parseFloat(rodal.especies[i].arbolh).toFixed(2);
                            rodal.especies[i].claseUnoHa = parseFloat(rodal.especies[i].claseUnoHa).toFixed(2);
                            rodal.especies[i].claseDosHa = parseFloat(rodal.especies[i].claseDosHa).toFixed(2);
                            rodal.especies[i].claseTresHa = parseFloat(rodal.especies[i].claseTresHa).toFixed(2);
                          } else {
                            rodal.especies[i].arbolh = parseInt(rodal.especies[i].arbolr) / rodal.especies[i].area;
                            rodal.especies[i].claseUnoHa = parseInt(rodal.especies[i].claseUno) / rodal.especies[i].area;
                            rodal.especies[i].claseDosHa = parseInt(rodal.especies[i].claseDosHa) / rodal.especies[i].area;
                            rodal.especies[i].claseTresHa = parseInt(rodal.especies[i].claseTresHa) / rodal.especies[i].area;
                          }
                        }
                      }
                    }

                    $scope.agregarRodal = function () {
                      var rodal = {
                        ttRodalFuenteId: 0,
                        correlativo: null,
                        area: null,
                        edad: null,
                        especies: []
                      };
                      if ($scope.data.rodal == null) {
                        $scope.data.rodal = [];
                      }
                      $scope.data.rodal.push(rodal);
                    }

                    $scope.agregarEspecie = function ( rodal ) {
                      var especie = {
                        especieRodalId: 0,
                        tcTipoInventario: null,
                        tcEspecie :
                        {
                          especieId: 0,
                          nombreCientifico: null
                        },
                        arbolr: 1,
                        dapPromedio: null,
                        alturaPromedio: null,
                        claseUno: null,
                        claseDos: null,
                        claseTres: null,
                        claseUnoHa: null,
                        claseDosHa: null,
                        claseTresHa: null,
                        arbolh: 1
                      };
                      if (rodal.especies == null) {
                        rodal.especies = [];
                      }
                      rodal.especies.push(especie);
                    }

                  }]
              }
            }
          })
      }
    ]
  );
